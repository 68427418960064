import forge from 'node-forge';

export const HMAC = async (key: string, message: string) => {
  if (key && message) {
    const hmac = forge.hmac.create();
    hmac.start('sha256', key);
    hmac.update(message);
    return hmac.digest().toHex();
  }
  return null;
};

export function assertNever(value: any, noThrow?: boolean): any {
  if (noThrow) {
    return value;
  }

  throw new Error(
    `Unhandled discriminated union member: ${JSON.stringify(value)}`,
  );
}

export const getUrlWithoutHttpPrefixAndWWW = (url: string) => {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
};

export const getUrlWithoutWWWPrefix = (url: string) => {
  return url.replace(/^(?:www\.)?/i, '');
};

export const removeTrailingSlash = (inputString: string) => {
  // Use a regular expression to remove the trailing "/"
  return inputString.replace(/\/$/, '');
};

export const getHrefWithoutQueryParams = (url: string) => {
  return removeTrailingSlash(
    getUrlWithoutHttpPrefixAndWWW(url).split('?')[0].split('#')[0],
  );
};

export const getResolvedPathName = () => {
  return window.location.pathname === '/' ? '' : window.location.pathname;
};

export const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
};

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isShadeOfWhiteOrGrey = (color: string): boolean => {
  const hexToRgb = (hex: string) => {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  const getBrightness = ({ r, g, b }: { r: number; g: number; b: number }) => {
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const rgb = hexToRgb(color);
  if (!rgb) {
    return false;
  }

  const maxDiff = Math.max(rgb.r, rgb.g, rgb.b) - Math.min(rgb.r, rgb.g, rgb.b);
  const brightnessThreshold = 200;
  const colorDiffThreshold = 30;
  const brightness = getBrightness(rgb);
  return brightness > brightnessThreshold && maxDiff < colorDiffThreshold;
};

export const calculatePercentage = (
  currentValue: number,
  totalValue: number,
): string => {
  const percentage = (currentValue / totalValue) * 100;
  return percentage.toString().split('.').length > 1
    ? percentage.toFixed(1)
    : percentage.toString();
};
