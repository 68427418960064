import React, { useMemo } from 'react';
import styled from 'styled-components';
import { capitalizeFirstLetter } from '@equally-ai-front/common/src/helpers';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { ProgressBar } from './progress-bar';

const roundPercentage = (number: number): string => {
  if (number % 1 === 0) {
    return number.toString();
  }

  const decimalPart = number % 1;
  if (decimalPart <= 0.5) {
    return number.toFixed(1);
  }
  return (Math.floor(number * 10) / 10).toString();
};

const calculatePercentage = (
  currentValue: number,
  totalValue: number,
): string => {
  const percentage = (currentValue / totalValue) * 100;
  return roundPercentage(percentage);
};

const getIssueProgressBgColor = (index: number): string => {
  const colors = ['#454284', '#4A5284', '#596391', '#6B739D', '#7C7BA8'];
  return colors[index % colors.length];
};

interface IssueProgress {
  label: string;
  percentage: string;
  bgColor: string;
}

interface IssuesProgressBarsProps {
  scanIssuesGroupingByElement: any[];
  issues: Issue[];
}

export const IssuesProgressBars = (props: IssuesProgressBarsProps) => {
  const { scanIssuesGroupingByElement, issues: allIssues } = props;
  const issuesByElementProgress: IssueProgress[] = useMemo(() => {
    return scanIssuesGroupingByElement.map(
      ([issuesElementGroupName, issues], index) => ({
        label: capitalizeFirstLetter(issuesElementGroupName),
        percentage: `${calculatePercentage(issues.length, allIssues.length)}%`,
        bgColor: getIssueProgressBgColor(index),
      }),
    );
  }, [scanIssuesGroupingByElement, allIssues]);

  return (
    <Wrapper>
      {issuesByElementProgress.map((issueInfo, index) => (
        <ProgressBar key={`${issueInfo.label}-${index}`} {...issueInfo} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 285px;

  @media screen and (min-width: 1300px) {
    max-width: 570px;
  }

  @media (pointer: none), (pointer: coarse) {
    max-width: 100%;
  }
`;
