import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../RootState';
import { initialState } from './index';

const selector = (state: RootState) =>
  state?.scanRecommendations || initialState;

export const selectScanRecommendations = createSelector(
  [selector],
  (recommendation) => recommendation.scanRecommendations,
);

export const selectMessage = createSelector(
  [selector],
  (recommendation) => recommendation.message,
);

export const selectLoadingScanRecommendations = createSelector(
  [selector],
  (recommendation) => recommendation.loading,
);

export const selectScanRecommendationsError = createSelector(
  [selector],
  (recommendation) => recommendation.error,
);

export const selectConfidenceLevel = createSelector(
  [selector],
  (recommendation) => recommendation.confidenceLevel,
);

export const selectStartTour = createSelector(
  [selector],
  (recommendation) => recommendation.startTour,
);

export const selectProductTourIndex = createSelector(
  [selector],
  (recommendation) => recommendation.productTourIndex,
);

export const selectDismissedIssues = createSelector(
  [selector],
  (recommendation) => recommendation.dismissedIssues,
);

export const selectManualRecommendations = createSelector(
  [selector],
  (recommendation) => recommendation.manualRecommendations,
);

export const selectScanStepReached = createSelector(
  [selector],
  (recommendation) => recommendation.scanStepReached,
);

export const selectDomainPages = createSelector(
  [selector],
  (recommendation) => recommendation.domainPages,
);

export const selectDashboardActiveUrlIdx = createSelector(
  [selector],
  (recommendation) => recommendation.dashboardActiveUrlIdx,
);

export const selectorActions = {
  selectScanRecommendations,
  selectLoadingScanRecommendations,
  selectScanRecommendationsError,
  selectConfidenceLevel,
  selectStartTour,
  selectProductTourIndex,
  selectDismissedIssues,
  selectManualRecommendations,
  selectScanStepReached,
  selectDomainPages,
  selectDashboardActiveUrlIdx,
};
