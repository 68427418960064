import React from 'react';
import styled from 'styled-components';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import {
  AuditComplianceInfo,
  AuditComplianceType,
} from './audit-compliance-info';

interface AccessilitySummaryProps {
  report: ReportData;
  loading: boolean;
  scanImageUrl: string;
  isWidgetAppeared: boolean;
  auditComplianceType: AuditComplianceType;
}

export const AccessilitySummary = (props: AccessilitySummaryProps) => {
  const { report, loading, scanImageUrl, auditComplianceType } = props;

  return (
    <Container>
      <AuditComplianceInfo
        loading={loading}
        issues={report.list.all}
        scanImageUrl={scanImageUrl}
        auditComplianceType={auditComplianceType}
      />
    </Container>
  );
};

const Container = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
