import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Edit from '../../assets/svg/edit-icon.svg';
import Like from '../../assets/svg/thumbs-up.svg';
import LikeFilled from '../../assets/svg/thumbs-up-filled.svg';
import CopyIcon from '../../assets/svg/copy.svg';
import CheckMarkIcon from '../../assets/svg/check-mark.svg';
import { EditPromptForm } from './EditPromptForm';
import { CodeBlock, CodeButtonProp } from '../code-block/CodeBlock';
import { TextSolution } from './TextSolution';
import { DevtoolsSolution } from '../../utils/devToolsTypeConfig';
import { selectUser } from '../../redux/slice/selectors';
import { selectDevDomain } from '../../redux/settings-slice/selectors';
import { getAssetReactAppUrl } from '../../utils';
import {
  LIKE_ICON_TOOLTIP_MESSAGE,
  EDIT_PROMPT_TOOLTIP_MESSAGE,
} from '../../utils/constants';
import {
  FeedbackSuggestionApiPayload,
  GptApiPayload,
  GptType,
  PromptFeedbackEnum,
} from '../../types/chat-gpt';

export interface CodeInfo {
  selector: string;
  principle: string;
  context: string;
}

export type GetGptSuggestionType = (
  gptType: GptType,
  gptDetails: GptApiPayload,
) => void;

interface GptSolutionsProps {
  gptFix: string;
  gptType: GptType;
  getGptSuggestion: GetGptSuggestionType;
  gptLoading: boolean;
  usedPrompt: string;
  devToolsSolution: DevtoolsSolution | null;
  isExtension?: boolean;
  codeInfo?: CodeInfo;
  savePrompt: (prompt: string, gptType: GptType) => void;
  sendFeedback: (feedback: FeedbackSuggestionApiPayload) => void;
}

export const GptSolutions = (props: GptSolutionsProps) => {
  const {
    gptFix,
    gptType,
    getGptSuggestion,
    gptLoading,
    usedPrompt,
    devToolsSolution,
    isExtension,
    codeInfo,
    savePrompt,
    sendFeedback,
  } = props;
  const user = useSelector(selectUser);
  const devDomain = useSelector(selectDevDomain);
  const [isCodeSnippetCopied, setIsCodeSnippetCopied] =
    useState<boolean>(false);
  const [likedState, setLikedState] = useState<boolean>(false);
  const [showEditPromptForm, setShowEditPromptForm] = useState(false);
  const toggleEditPromptForm = () => setShowEditPromptForm(!showEditPromptForm);
  const closeEditPromptForm = () => setShowEditPromptForm(false);

  const currentAuditedPageName = localStorage.getItem(
    'currentAuditedPage',
  ) as string;
  const { hostname, pathname } = window.location;
  const pageName = currentAuditedPageName ? currentAuditedPageName : pathname;
  const domainName =
    devDomain.url !== '' ? `https://${devDomain.url}` : `https://${hostname}`;

  const codeInfoToUse = devToolsSolution ? devToolsSolution : codeInfo;
  const codeInfoForGpt = {
    page_name: pageName,
    page_selector: codeInfoToUse?.selector ?? '',
    context: codeInfoToUse?.context ?? '',
    principle: codeInfoToUse?.principle ?? '',
    domain: `https://${domainName}`,
  };

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const copyCodeSnippet = () => {
    copyTextToClipboard(gptFix)
      .then(() => {
        setIsCodeSnippetCopied(true);
        setTimeout(() => {
          setIsCodeSnippetCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const treeBasedGptPayload = useMemo(
    () => ({
      prompt: usedPrompt,
      WebsiteType: devDomain.platform,
      ElementSelector: devToolsSolution?.selector ?? codeInfo?.selector ?? '',
      UserId: user.user_id,
      PromptFeedback: PromptFeedbackEnum.like,
    }),
    [
      devDomain.platform,
      devToolsSolution?.selector,
      codeInfo?.selector,
      usedPrompt,
      user.user_id,
    ],
  );

  const likeSolution = () => {
    let payload = {
      ...treeBasedGptPayload,
    };

    if (!likedState) {
      payload = {
        ...treeBasedGptPayload,
        PromptFeedback: PromptFeedbackEnum.like,
      };
      setLikedState(true);
    } else {
      payload = {
        ...treeBasedGptPayload,
        PromptFeedback: PromptFeedbackEnum.like,
      };
      setLikedState(false);
    }

    sendFeedback(payload);
  };

  const editPrompt = (newPrompt: string) => {
    const gptDetails = {
      ...codeInfoForGpt,
    };

    savePrompt(newPrompt, gptType);

    switch (gptType) {
      case 'magic':
        getGptSuggestion('magic', {
          ...gptDetails,
          context_type: 'magic',
          extra_details: {
            platform: '',
            has_elementor: false,
            has_visual_editor: false,
            template: '',
            language: devDomain.language,
          },
          prompt: newPrompt,
        });
        break;
      case 'creative':
        getGptSuggestion('creative', {
          ...gptDetails,
          context_type: 'creative',
          extra_details: {
            platform: '',
            has_elementor: false,
            has_visual_editor: false,
            template: '',
            language: '',
          },
          prompt: newPrompt,
        });
        break;
      case 'guide':
        getGptSuggestion('guide', {
          ...gptDetails,
          context_type: 'guide',
          extra_details: {
            platform: devDomain.platform,
            has_elementor: devDomain.has_elementor,
            has_visual_editor: devDomain.has_visual_editor,
            template: devDomain.template,
            language: '',
          },
          prompt: newPrompt,
        });
        break;
      case 'explain':
        getGptSuggestion('explain', {
          ...gptDetails,
          context_type: 'explain',
          extra_details: {
            platform: '',
            has_elementor: false,
            has_visual_editor: false,
            template: '',
            language: '',
          },
          prompt: newPrompt,
        });
        break;
      case 'screen-reader':
        getGptSuggestion('explain', {
          ...gptDetails,
          context_type: 'screen-reader',
          extra_details: {
            platform: '',
            has_elementor: false,
            has_visual_editor: false,
            template: '',
            language: '',
          },
          prompt: newPrompt,
        });
        break;
      default:
        break;
    }
  };

  const returnGptSolutionId = (type: string): string => {
    let id = '';

    if (type === 'magic') {
      id = 'magicSolutionId';
    } else if (type === 'creative') {
      id = 'creativeSolutionId';
    } else if (type === 'guide') {
      id = 'guideSolutionId';
    } else if (type === 'explain') {
      id = 'explainSolutionId';
    }

    return id;
  };

  const copyIconProps: CodeButtonProp = {
    action: copyCodeSnippet,
    iconSrc: getAssetReactAppUrl(CopyIcon),
    isCopied: isCodeSnippetCopied,
    checkMarkIcon: getAssetReactAppUrl(CheckMarkIcon),
  };

  useEffect(() => {
    if (!gptLoading) {
      closeEditPromptForm();
    }
  }, [gptLoading]);

  return (
    <Wrapper id={returnGptSolutionId(gptType)}>
      {gptType === 'creative' ||
      gptType === 'magic' ||
      gptType === 'screen-reader' ? (
        showEditPromptForm ? (
          <EditPromptForm
            gptLoading={gptLoading}
            editPrompt={editPrompt}
            usedPrompt={usedPrompt}
            toggleEditPromptForm={toggleEditPromptForm}
            isExtension={isExtension}
          />
        ) : (
          <CodeBlock
            codeDescriptionTitle=""
            headerIcon1={{
              action: likeSolution,
              iconSrc: getAssetReactAppUrl(likedState ? LikeFilled : Like),
              isActive: likedState,
              disable: gptLoading,
              toolTipMsg: LIKE_ICON_TOOLTIP_MESSAGE,
            }}
            headerIcon2={{
              action: toggleEditPromptForm,
              iconSrc: getAssetReactAppUrl(Edit),
              isActive: false,
              disable: gptLoading,
              toolTipMsg: EDIT_PROMPT_TOOLTIP_MESSAGE,
            }}
            codeContent={
              <GptResultItem
                isEmpty={gptFix === ''}
                showMarginBottom={false}
                isLast={false}
                isExtension={isExtension}
              >
                {gptFix}
              </GptResultItem>
            }
            copyIcon={copyIconProps}
            isExtension={isExtension}
          />
        )
      ) : (
        (gptType === 'explain' || gptType === 'guide') &&
        (showEditPromptForm ? (
          <EditPromptForm
            gptLoading={gptLoading}
            editPrompt={editPrompt}
            usedPrompt={usedPrompt}
            toggleEditPromptForm={toggleEditPromptForm}
            isExtension={isExtension}
          />
        ) : (
          <TextSolution
            titleHeader=""
            headerAction1={{
              isActive: likedState,
              action: likeSolution,
              iconSrc: getAssetReactAppUrl(likedState ? LikeFilled : Like),
            }}
            headerAction2={{
              isActive: false,
              action: toggleEditPromptForm,
              iconSrc: getAssetReactAppUrl(Edit),
            }}
            gptFix={gptFix}
          />
        ))
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 5%;
`;

const GptResultItem = styled.code<{
  isEmpty: boolean;
  isLast: boolean;
  showMarginBottom: boolean;
  isExtension?: boolean;
}>`
  margin-bottom: ${(p) =>
    p.isExtension || p.isEmpty || p.isLast
      ? '0'
      : p.showMarginBottom
        ? '5%'
        : ''};
  display: block;
  width: 300px;
`;

export const LikeIcon = styled.img<{ isactive: string }>`
  width: 20px;
  margin-right: 15px;
  cursor: pointer;
  height: 25px;

  path {
    fill: ${(props) => (props.isactive === 'true' ? '#C2F4D7' : '#FFFFFF')};
  }
`;

export const EditIcon = styled.img`
  width: 20px;
  cursor: pointer;
  height: 25px;
`;
