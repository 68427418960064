import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { AdminDomain, DevDomain, Domain } from '../../types/domains';
import { RootState } from '../RootState';

export interface DomainsState {
  domains: Domain[];
  devDomains: DevDomain[];
  currentDomain?: Domain;
  currentDevDomain: DevDomain;
  widgetConfigMap?: object;
  currentAdminDomain?: AdminDomain;
}

const initialState: DomainsState = {
  domains: [],
  devDomains: [],
  currentDomain: undefined,
  currentDevDomain: {
    active: false,
    expires: '',
    id: 0,
    business_id: 0,
    url: '',
    name: '',
    platform: '',
    has_elementor: false,
    has_visual_editor: false,
    template: '',
    language: '',
    plan_id: undefined,
  },
  widgetConfigMap: {},
  currentAdminDomain: undefined,
};

const domainsSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    setDomains: (state, action: PayloadAction<Domain[]>) => {
      state.domains = action.payload;
    },
    setCurrentDomain: (state, action: PayloadAction<Domain | undefined>) => {
      state.currentDomain = action.payload;
    },
    setDevDomains: (state, action: PayloadAction<DevDomain[]>) => {
      state.devDomains = action.payload;
    },
    setCurrentDevDomain: (state, action: PayloadAction<DevDomain>) => {
      state.currentDevDomain = action.payload;
    },
    setCurrentAdminDomain: (state, action: PayloadAction<AdminDomain>) => {
      state.currentAdminDomain = action.payload;
    },
    setWidgetConfigMap: (state, action: PayloadAction<object>) => {
      state.widgetConfigMap = action.payload;
    },
  },
});

const selectDomainState = (state: RootState) => state?.domains || initialState;

export const selectDomains = createSelector(
  [selectDomainState],
  (domainState) => domainState.domains,
);

export const selectCurrentDomain = createSelector(
  [selectDomainState],
  (domainState) => domainState.currentDomain,
);

export const selectDevDomains = createSelector(
  [selectDomainState],
  (domainState) => domainState.devDomains,
);

export const selectCurrentDevDomain = createSelector(
  [selectDomainState],
  (domainState) => domainState.currentDevDomain,
);

export const selectCurrentAdminDomain = createSelector(
  [selectDomainState],
  (domainState) => domainState.currentAdminDomain,
);

export const selectWidgetConfigMap = createSelector(
  [selectDomainState],
  (domainState) => domainState.widgetConfigMap,
);

export const useDomainsSlice = () => {
  useInjectReducer({ key: domainsSlice.name, reducer: domainsSlice.reducer });
  const domains = useSelector(selectDomains);
  const currentDomain = useSelector(selectCurrentDomain);
  const devDomains = useSelector(selectDevDomains);
  const currentDevDomain = useSelector(selectCurrentDevDomain);
  const widgetConfigMap = useSelector(selectWidgetConfigMap);
  const currentAdminDomain = useSelector(selectCurrentAdminDomain);

  return {
    actions: domainsSlice.actions,
    domains,
    currentDomain,
    devDomains,
    currentDevDomain,
    widgetConfigMap,
    currentAdminDomain,
  };
};
export const domainsActions = domainsSlice.actions;
export default domainsSlice.reducer;
