import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  AccessibilityAccordion,
  useAccessibilityAccordion,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Divider as MuiDivider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ScanReportIssueDetails } from '@equally-ai/audit-ui/src/components/scan-report/scan-report-issue-details';
import { setApiSuccessMessage } from '../../store/actions';
import { TaskIssue } from '@equally-ai-front/common/src/types/business';

interface JiraIssuesProps {
  selectedIssues: TaskIssue[];
  selectedDevToolsIssues: DevtoolsSolution[];
}

export const JiraIssues = (props: JiraIssuesProps) => {
  const { selectedIssues, selectedDevToolsIssues } = props;
  const dispatch = useDispatch();
  const [isScanReportItemVisible, setIsScanReportItemVisible] = useState(false);
  const [activeItemId, setActiveItemId] = useState('');
  const { accordionState, handleAccordionToggle } = useAccessibilityAccordion();

  const toggleSectionItem = (id: string) => {
    if (isScanReportItemVisible && activeItemId === id) {
      setIsScanReportItemVisible(false);
    } else {
      setIsScanReportItemVisible(true);
      setActiveItemId(id);
    }
  };

  const setSuccessMessage = (message: string) => {
    dispatch(setApiSuccessMessage(message));
  };

  const handleToggleAccordion = (id: string) => {
    toggleSectionItem(id);
    handleAccordionToggle(id);
  };

  return (
    <Wrapper>
      {selectedIssues.map((selectedIssue, index) => {
        const devToolsIssue = selectedDevToolsIssues.find(
          ({ id }) => id === selectedIssue.id,
        );

        if (!devToolsIssue) return <></>;
        return (
          <Container key={`${devToolsIssue.id}-${index}`}>
            <AccessibilityAccordion
              title={devToolsIssue.title}
              accordionId={devToolsIssue.id.toString()}
              accordionState={accordionState}
              handleToggleAccordion={() =>
                handleToggleAccordion(devToolsIssue.id.toString())
              }
              accordionHeader={
                <HeaderWrapper>
                  <TitleAndNumber>
                    <HeaderNumber>{index + 1}.</HeaderNumber>
                    <HeaderText>{devToolsIssue.selector}</HeaderText>
                  </TitleAndNumber>
                  <ArrowIconWrapper>
                    <ArrowIcon
                      src="/chevron-down-black.svg"
                      alt="arrow down icon"
                      shouldrotate={accordionState[devToolsIssue.id]}
                    />
                  </ArrowIconWrapper>
                </HeaderWrapper>
              }
            >
              <Divider />
              <ScanReportIssueDetails
                issueId={devToolsIssue.id.toString()}
                activeItemId={activeItemId}
                devToolsSolution={devToolsIssue}
                setSuccessMessage={setSuccessMessage}
                showSelectorBox
              />
            </AccessibilityAccordion>
          </Container>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 10px;
`;

const Container = styled.div`
  overflow: hidden;
  border: 2px solid #e4e7eb;
  border-radius: 8px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  > div > h3 {
    padding: 6px 20px;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
    align-items: initial;
  }
`;

const TitleAndNumber = styled.div`
  display: flex;
  width: 100%;
  max-width: 90%;
  align-items: center;

  @media (pointer: none), (pointer: coarse) {
    max-width: 100%;
    margin-bottom: 1.5vh;
  }
`;

const HeaderNumber = styled.p`
  font-weight: 600;
  font-size: 15px;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  max-width: 90%;
  margin-left: 12px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (pointer: none), (pointer: coarse) {
    font-size: 12px;
    margin-left: 16px;
  }
`;

const ArrowIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 410px) {
    width: 37px;
  }
`;

interface ArrowIconProps {
  shouldrotate: boolean;
}

const ArrowIcon = styled.img<ArrowIconProps>`
  transform: ${(props) => (props.shouldrotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

const Divider = styled(MuiDivider)`
  height: 1px;
`;
