import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../redesign/Controls';
import { InfoContent, MainHeader } from '../flowy-components/components/common';

interface CreateScanStepPlaceholderProps {
  isAuthenticationStep: boolean;
  handleAddNewStep: (
    isAuthenticationStep: boolean,
    handleAccordionToggle: (accordionId: string) => void,
  ) => void;
  handleAccordionToggle: (accordionId: string) => void;
}

export const CreateScanStepPlaceholder = (
  props: CreateScanStepPlaceholderProps,
) => {
  const { isAuthenticationStep, handleAddNewStep, handleAccordionToggle } =
    props;

  return (
    <Wrapper>
      <IconWrapper>
        <IconWrapperBg />
        <UnionIcon src="/union.svg" alt="" />
      </IconWrapper>
      <StepName>
        {isAuthenticationStep ? 'Authentication' : 'New'} Step
      </StepName>
      {isAuthenticationStep && (
        <StepInfo>Create an authentication step to bypass login forms</StepInfo>
      )}
      <ButtonWrapper>
        <CreateStepBtn
          aria-label="Add a new scan step"
          onClick={() =>
            handleAddNewStep(isAuthenticationStep, handleAccordionToggle)
          }
        >
          Create step
        </CreateStepBtn>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 40px;
`;

const IconWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
`;

const IconWrapperBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center top,
    rgba(245, 245, 246, 1) 0%,
    rgba(245, 245, 246, 0) 70%
  );
`;

const UnionIcon = styled.img`
  position: relative;
  width: 50%;
  height: 50%;
  display: flex;
  margin: 20px auto 0;
  object-fit: cover;
  z-index: 1;
`;

const StepName = styled(MainHeader)`
  font-size: 16px;
  margin: -35px 0 15px 0;
  font-weight: 600;
`;

const StepInfo = styled(InfoContent)`
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 10, 20, 1);
  width: 60%;
  text-align: center;
  line-height: 25px;

  @media screen and (min-width: 1200px) {
    width: 40%;
  }

  @media screen and (min-width: 1500px) {
    width: 35%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  max-width: 30%;
`;

const CreateStepBtn = styled(PrimaryButton)`
  padding: 8px 49px;
  background-color: #000000;
  color: #ffffff;
  font-weight: 400;
  font-size: 17px;
  width: 100%;
  height: 50px;
  border-radius: 15px;

  &:hover {
    background-color: #000000;
    color: #ffffff;
    opacity: 0.9;
  }
`;
