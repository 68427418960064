import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AllPagesScans } from './all-pages-scans';
import { IssuesGroup } from '../types';
import { IssueType } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { ScanData } from '@equally-ai-front/common/src/types/scans';
import { IssuesContainer } from './issues-container';
import { NewScanInfo } from '@equally-ai-front/common/src/types/issues';

interface MainIssuesProps {
  reportType: IssuesGroup;
  severity: IssueType;
  // wcagReport: ReportData;
  devDomain: DevDomain;
  onAudit: (url: string, cb?: () => void, scanDetails?: NewScanInfo) => void;
  currentScan: ScanData | undefined;
  successMessage: string;
  setSuccessMessage: (message: string) => void;
  history: any[];
}

export const MainIssues = ({
  onAudit,
  successMessage,
  setSuccessMessage,
}: MainIssuesProps) => {
  return (
    <Routes>
      <Route path={'/*'} element={<AllPagesScans onAudit={onAudit} />} />
      <Route
        path={'issues/:scanId?'}
        element={
          <IssuesContainer
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
          />
        }
      />
    </Routes>
  );
};
