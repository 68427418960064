import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface MuiCheckboxProps {
  isChecked: boolean;
  checkboxName: string;
  handleCheckboxChange: (
    evt:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLButtonElement>,
  ) => void;
  label?: string;
  labelFontSize?: string;
  checkedColor?: string;
  borderColor?: string;
}

export type MuiCheckboxRef = HTMLButtonElement | null;

export const MuiCheckbox = forwardRef<MuiCheckboxRef, MuiCheckboxProps>(
  (props: MuiCheckboxProps, ref) => {
    const {
      isChecked,
      checkboxName,
      handleCheckboxChange,
      label,
      labelFontSize,
      checkedColor,
      borderColor,
    } = props;
    const theme = createTheme({
      components: {
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: borderColor ?? '#D3DCE6',
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              fontSize: labelFontSize ?? '',
            },
          },
        },
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isChecked}
              name={checkboxName}
              onChange={handleCheckboxChange}
              onKeyDown={(evt: React.KeyboardEvent<HTMLButtonElement>) => {
                if (evt.key === 'Enter' || evt.key === 'Space') {
                  handleCheckboxChange(evt);
                }
              }}
              ref={ref}
              checkedIcon={
                <CheckedSquare
                  checkedcolor={checkedColor}
                  bordercolor={borderColor}
                >
                  <div className="inner-div"></div>
                </CheckedSquare>
              }
            />
          }
          label={label}
        />
      </ThemeProvider>
    );
  },
);

const CheckedSquare = styled.div<{
  checkedcolor?: string;
  bordercolor?: string;
}>`
  width: 1em;
  height: 1em;
  display: flex;
  border: 2.5px solid ${(p) => (p.bordercolor ? p.bordercolor : '#d3dce6')};
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  border-radius: 3px;

  .inner-div {
    background-color: ${(p) => (p.checkedcolor ? p.checkedcolor : '#50ab62')};
    width: 0.4em;
    height: 0.4em;
  }
`;
