import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ALL_PLANS_OPTION,
  INACTIVE_PLANS_OPTION,
} from '../../../common/magicValues';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { PrimaryButton, PrimaryMobileButton } from '../../redesign/Controls';
import { HeadingTypography } from '../heading';
import LoadingView from '../LoadingView';
import PlanDetails from './PlanDetails';
import { usePlans } from '../../../providers/plans-context';
import { useNavigate } from 'react-router-dom';
import {
  showPlans,
  isStripePlan,
  isPlansAvailableWithCredits,
} from '@equally-ai/cart-checkout/src/utils/plans.utils';
import { OutlinedButton } from '../ActionButton';

type PlansAndRecordsType = 'widget' | 'developer';

interface PlansAndRecordsProps {
  type: PlansAndRecordsType;
  heading: string;
  selectedPlan: string;
  searchValue: string;
  handleSearch: (query: string) => void;
  showActionButton: boolean;
  attachPlanButtonLabel: string;
  handleAttachPlanClick: () => void;
  handleActionButton: () => void;
  children: React.ReactNode;
  onChoosePlan: (productType: string) => void;
}

export const PlansAndRecords = ({
  type,
  heading,
  selectedPlan,
  showActionButton,
  attachPlanButtonLabel,
  handleAttachPlanClick,
  handleActionButton,
  children,
  onChoosePlan,
}: PlansAndRecordsProps) => {
  const { activePlans: plansData } = usePlans();
  const isLoading = useSelector((state: any) => state.plans.loading);
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  const getSelectedPlan = () =>
    (plansData || []).find((plan: any) => plan.id === selectedPlan);

  const getActionButton = () => {
    return isMobile ? PrimaryMobileButton : PrimaryButton;
  };
  const ActionButton = getActionButton();
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  // const hasActiveStripeSubscription = useMemo(() => {
  //   if (!plansData) return false;
  //   return (
  //     plansData?.length > 0 &&
  //     plansData?.filter((plan) => isStripePlan(plan.provider_id)).length > 0
  //   );
  // }, [plansData]);

  const navigateToSubscriptionsPage = () => {
    navigate(`/${currentBusiness?.slug}/business-settings/plans`);
  };

  let creditsCTA;
  if (
    !isPlansAvailableWithCredits(plansData || []) &&
    !showPlans(plansData || [])
  ) {
    creditsCTA = (
      <Button onClick={navigateToSubscriptionsPage}>Buy a license</Button>
    );
  } else if (showPlans(plansData || [])) {
    creditsCTA = (
      <Button onClick={() => onChoosePlan('widget')}>Choose a plan</Button>
    );
  }
  return (
    <>
      <div className="header-manage-websites widget-container-header">
        <div className="header-manage-websites-text widget-container-header-text">
          <HeadingTypography>{heading}</HeadingTypography>
        </div>
        <div>
          <ButtonsContainer>
            {creditsCTA}
            {showActionButton && attachPlanButtonLabel !== '' && (
              <ActionButton
                variant="outlined"
                onClick={handleAttachPlanClick}
                style={{
                  borderRadius: '8px',
                  border: '2px solid #454284',
                  color: '#454284',
                }}
              >
                {attachPlanButtonLabel}
              </ActionButton>
            )}
            <ActionButton
              variant="contained"
              color="primary"
              onClick={handleActionButton}
              aria-haspopup="dialog"
              style={{ minHeight: '50px' }}
            >
              + Add new website
            </ActionButton>
          </ButtonsContainer>
        </div>
      </div>

      <LoadingView open={isLoading} />

      {selectedPlan !== ALL_PLANS_OPTION &&
        selectedPlan !== INACTIVE_PLANS_OPTION && (
          <PlanDetails plan={getSelectedPlan()} type={type} />
        )}
      {children}
    </>
  );
};

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '10px',

  '& > button': {
    padding: '6px 16px 6px 12px',

    [theme.breakpoints.down('xs')]: {
      height: '36px',
      padding: '6px 12px',
      fontSize: '15px',
    },
  },

  [theme.breakpoints.down('xs')]: {
    justifyContent: 'flex-start',
  },
}));

const Button = styled(OutlinedButton)`
  text-transform: none;
  border-radius: 8px;
  min-height: 50px;
`;
