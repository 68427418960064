import { TextField } from '@mui/material';
import React from 'react';

interface AriaProps {
  'aria-label'?: string;
  'aria-errormessage'?: string;
  'aria-invalid'?: 'false' | 'true' | 'grammar' | 'spelling';
}
interface GenericInputProps {
  value: string;
  onChange: (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  ariaProps: AriaProps;
  [key: string]: any;
  error?: boolean;
  helperText?: string;
}

export const GenericInput: React.FC<GenericInputProps> = ({
  onChange,
  value,
  ariaProps,
  error,
  helperText,
  ...otherProps
}) => {
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      value={value}
      onChange={onChange}
      inputProps={{ ...ariaProps }}
      helperText={helperText}
      error={error}
      FormHelperTextProps={{ id: ariaProps['aria-errormessage'] }}
      {...otherProps}
    />
  );
};
