import React, { useState } from 'react';
import styled from '@emotion/styled';
import { styled as MuiStyled } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  Dialog,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MainText, SubText } from './table/table';
import { Button } from './tabs/documents-tab';
import { templateToDocumentInfoMap } from '@equally-ai-front/common/src/redux/compliance-documents-slice/compliance-documents';

interface DomainOption {
  label: string;
  id: string;
}

interface SubmitDocumentPopupProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  domains: any[];
  handleSubmitDocument: (payload: {
    domain: string;
    url: string;
    template_id: number;
  }) => Promise<{
    isSuccess: boolean;
    error: any;
  }>;
  domain: string;
  setDomain: React.Dispatch<React.SetStateAction<string>>;
}

export const SubmitDocumentPopup = (props: SubmitDocumentPopupProps) => {
  const {
    isOpen,
    setIsOpen,
    domains,
    handleSubmitDocument,
    domain,
    setDomain,
  } = props;
  const [declarationLink, setDeclarationLink] = useState('');
  const [templateType, setTemplateType] = useState<number | undefined>(
    undefined,
  );

  const domainsOptions: DomainOption[] = domains.map((domain) => ({
    label: domain?.url || '',
    id: domain?.id || '',
  }));

  const templateTypesOptions: DomainOption[] = Object.keys(
    templateToDocumentInfoMap,
  ).map((templateId) => {
    const template = templateToDocumentInfoMap[parseInt(templateId)];
    return {
      label: template?.country || '',
      id: templateId || '',
    };
  });

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const handleDeclarationLinkChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDeclarationLink(evt.target.value);
  };

  const handleSubmit = async () => {
    if (!templateType) {
      return;
    }
    const { isSuccess, error } = await handleSubmitDocument({
      domain,
      url: declarationLink,
      template_id: templateType,
    });

    if (isSuccess && !error) {
      setDomain('');
      setDeclarationLink('');
      handleClosePopup();
    }
  };

  return (
    <Wrapper open={isOpen} onClose={handleClosePopup} fullScreen={false}>
      <Box display="flex" flexDirection="column" gap="16px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <MainText fontSize="20px" fontWeight={700}>
            Submit for Approval
          </MainText>
          <CloseButton aria-label="close" onClick={handleClosePopup}>
            <CloseIcon />
          </CloseButton>
        </Box>
        <Box display="flex" flexDirection="column" gap="16px">
          <SubText fontSize="15px" fontWeight={500}>
            By submitting this for approval, you confirm you have added the
            statement according to the template requirements. Please provide the
            statement link below to proceed with the approval.
          </SubText>
          <StyledAutoComplete
            disablePortal
            id="domains-list"
            options={domainsOptions}
            value={domainsOptions.find((opt) => opt.label === domain) || null}
            onChange={(_, value) => {
              const newValue = value as unknown as DomainOption;
              setDomain(newValue?.label || '');
            }}
            renderInput={(params) => (
              <StyledTextField {...params} label="Domain" />
            )}
          />

          <Box>
            <StyledAutoComplete
              disablePortal
              id="tempaltes-types"
              options={templateTypesOptions}
              value={
                templateTypesOptions.find(
                  (opt) => parseInt(opt.id) === templateType,
                ) || null
              }
              onChange={(_, value) => {
                const newValue = value as unknown as DomainOption;
                setTemplateType(parseInt(newValue.id));
              }}
              renderInput={(params) => (
                <StyledTextField {...params} label="Type" />
              )}
            />
          </Box>
          <Box>
            <Input
              placeholder="Enter declaration link"
              name="declarationLink"
              value={declarationLink}
              onChange={handleDeclarationLinkChange}
              type="text"
              variant="outlined"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              onClick={handleSubmit}
              disabled={!domain || !declarationLink}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Dialog)`
  & .MuiDialog-paper {
    width: 100%;
    max-width: 512px;
    border-radius: 8px;
    padding: 24px;

    @media (pointer: none), (pointer: coarse) {
      max-width: 95%;
      max-height: 93vh;
      justify-content: center;
    }

    @media only screen and (max-width: 600px) {
      margin: 0;
    }
  }
`;

const CloseButton = styled(IconButton)`
  &:focus {
    border: 2px solid #3e3c77;
  }
`;

const Input = MuiStyled(TextField)(({ theme }) => ({
  width: '100%',

  ['.MuiOutlinedInput-root']: {
    width: '100%',
    height: '45px',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
  },

  fieldset: {
    borderColor: '#E2E8F0',
  },
}));

const StyledAutoComplete = MuiStyled(Autocomplete)(() => ({
  width: '100%',

  fieldset: {
    borderColor: '#E2E8F0',
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    height: '45px',
  },
  '& .MuiInputBase-input': {
    paddingTop: '12px',
  },
  '& .MuiInputLabel-root': {
    top: '-5px',
  },
  '& .MuiInputLabel-shrink': {
    top: '0px',
  },
});
