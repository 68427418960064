import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

export type RadioInput = {
  id: string;
  label: string;
  value: string;
};

interface MuiRadioInputProps {
  radioList: RadioInput[];
  radioInputName: string;
  radioInputValue: string;
  handleRadioInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  borderColor?: string;
  checkedColor?: string;
  isDisabled: boolean;
}

export const MuiRadioInput = (props: MuiRadioInputProps) => {
  const {
    radioList,
    isDisabled,
    radioInputName,
    radioInputValue,
    handleRadioInputChange,
    className,
    borderColor,
    checkedColor,
  } = props;

  const theme = createTheme({
    components: {
      MuiFormGroup: {
        styleOverrides: {
          root: {
            flexDirection: 'row',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: borderColor ?? '#D3DCE6',

            '&.Mui-checked': {
              color: checkedColor ?? '#50ab62',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '& .MuiFormControlLabel-label': {
              fontSize: 13,
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <RadioGroup
        value={radioInputValue}
        name={radioInputName}
        onChange={handleRadioInputChange}
        className={className}
      >
        {radioList.map(({ id, label, value }) => (
          <FormControlLabel
            disabled={isDisabled}
            key={id}
            value={value}
            control={<Radio />}
            label={label}
          />
        ))}
      </RadioGroup>
    </ThemeProvider>
  );
};
