import styled from '@emotion/styled';
import {
  Button,
  Divider as MuiDivider,
  Paper,
  Typography,
  Tabs as MuiTabs,
  Tab as MuiTab,
} from '@mui/material';
import React from 'react';

import { ReactComponent as ChevronDownIcon } from '../../img/ChevronDown.svg';

// normal 70px/36px 110%/140%
export const TypographyH1 = ({ ...props }) => (
  <Typography component="h1" variant="h1" {...props} />
);

// normal 60px/34px 120%/140%
export const TypographyH2 = ({ ...props }) => (
  <Typography component="h2" variant="h2" {...props} />
);

// normal 40px/32px 120%/140%
export const TypographyH3 = ({ ...props }) => (
  <Typography component="h3" variant="h3" {...props} />
);

// normal 34px/28px 120%/140%
export const TypographyH4 = ({ ...props }) => (
  <Typography component="h4" variant="h4" {...props} />
);

// 300 24px/20px 140%
export const TypographyH5 = ({ ...props }) => (
  <Typography component="h5" variant="h5" {...props} />
);

// font-size: 20px/18px 155%/150%;
export const TypographyH6 = ({ ...props }) => (
  <Typography component="h6" variant="h6" {...props} />
);

// 300 24px 140%
export const TypographySubtitle = styled(TypographyH5, {
  shouldForwardProp: (prop) => prop !== 'light',
})`
  color: ${({ theme, light }) =>
    light ? theme.palette.on.lightMedium : theme.palette.on.darkMedium};
`;

// font-size: 16px;
export const TypographySubtitle1 = ({ ...props }) => (
  <Typography variant="subtitle1" {...props} />
);

// font-size: 14px;
export const TypographySubtitle2 = ({ ...props }) => (
  <Typography variant="subtitle2" {...props} />
);

// font-size: 20px;
export const TypographyText1 = ({ ...props }) => (
  <Typography variant="h6" component="span" {...props} />
);

//  font-size: 18px/16px 150%;
export const TypographyText2 = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;

export const CenterBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: 12px 24px;
  font-weight: 500;
  border-radius: 12px;
  font-size: 16px;
  line-height: 150%;
`;

export const Divider = styled(MuiDivider)`
  background: #e4e7eb;
  border-radius: 4px;
`;

export const ThinContent = styled(TypographyText1)`
  width: 600px;
  color: ${({ theme }) => theme.palette.on.darkMedium};
`;

/** Cards */

export const BlackCard = styled(Paper)`
  background: ${({ theme }) => theme.palette.on.darkHigh};
  color: ${({ theme }) => theme.palette.on.lightHigh};

  box-shadow:
    0px 15px 25px rgba(0, 18, 38, 0.15),
    0px 5px 10px rgba(0, 18, 38, 0.05);
  border-radius: 16px;
`;

export const OutlineCard = styled(Paper)`
  background: ${({ theme }) => theme.palette.on.lightHigh};
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 2px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 16px;
`;

export const OutlineGreenCard = styled(Paper)`
  background: #ecfcf2;
  color: #093c2b;

  border: 2px solid rgba(9, 60, 43, 0.32);
  box-sizing: border-box;
  border-radius: 12px;
`;

export const IconCircle = styled(CenterBlock)`
  border-radius: 50%;
  background: #f5f7fa;
  color: ${({ theme }) => theme.palette.on.darkHigh};

  padding: 16px;

  width: 96px;
  height: 96px;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

/** Circle around icon */
export const IconCircleContainer = styled(IconCircle)`
  width: 64px;
  height: 64px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 96px;
    height: 96px;
  }
`;

/** Backgrounds */

export const Background = styled.div`
  position: relative;
  ::after {
    content: '';
    background: #f5f7fa;
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${({ top }) => (top ? 'auto' : '0')};
    top: ${({ top }) => (top ? '0' : 'auto')};
    z-index: -1;
    height: ${({ size }) => (size ? `${size}` : '100%')};
  }
`;

export const ArrowIcon = styled(ChevronDownIcon, {
  shouldForwardProp: (prop) => prop !== 'rotate',
})`
  transform: ${({ rotate }) => (rotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

export const FitImage = styled.img`
  width: 100%;
  max-width: fit-content;
`;

export const FitImageRounded = styled(FitImage)`
  border-radius: 16px;
`;

export const ORText = styled.div`
  color: ${({ theme }) => theme.palette.on.darkLow};
  margin: 16px;
  text-align: center;

  font-size: 12px;
  line-height: 150%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 14px;
    line-height: 150%;
  }
`;

export const IconButton = styled(Button)`
  border: 2px solid;
  border-color: rgba(255, 255, 255, 0.32);

  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;

  width: auto;
  height: auto;
  min-width: auto;

  color: inherit;

  & .MuiButton-label {
    color: inherit;
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const AbsoluteCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
