import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DomainPageScans } from './domain-page-scans';
import { NewScanInfo } from '@equally-ai-front/common/src/types/issues';
import { GroupPageScans } from './group-page-scans';
import { PageAndGroupScanTable } from './pages-and-group-scans';

interface AllPagesScansProps {
  onAudit: (url: string, cb?: () => void, scanDetails?: NewScanInfo) => void;
}
export const AllPagesScans = (props: AllPagesScansProps) => {
  const { onAudit } = props;

  return (
    <Routes>
      <Route index element={<PageAndGroupScanTable onAudit={onAudit} />} />
      <Route path={'page'} element={<DomainPageScans onAudit={onAudit} />} />
      <Route path={'group'} element={<GroupPageScans />} />
    </Routes>
  );
};
