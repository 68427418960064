import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import MainPlanView from '../../components/main-plan-view/main-plan-view';
import { BillingHistory } from '../../components/payments-history-table/payments-history-table';
import { PaymentsService } from '@equally-ai/cart-checkout/src/api/payments';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useAnalytics } from '@equally-ai-front/common';
import { availablePlans } from '@equally-ai/cart-checkout/src/utils/plans.utils';

interface NewPlansContainerProps {
  plans: any;
}
export const NewPlansContainer = (props: NewPlansContainerProps) => {
  const { plans } = props;
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const analytics = useAnalytics();
  const [subscriptionPayments, setSubscriptionPayments] = useState([]);
  const [sessionUrl, setSessionUrl] = useState<string | null>(null);
  useEffect(() => {
    if (!currentBusiness) {
      return;
    }
    const getPayments = async () => {
      const response = await PaymentsService.getBusiness(currentBusiness.id);
      const { isSuccess, data } = response;
      if (!isSuccess) {
        return;
      }
      const { payments, planSessionUrl } = data;
      setSubscriptionPayments(payments);
      setSessionUrl(planSessionUrl);
    };
    void getPayments();
  }, [currentBusiness]);

  const handleManagePlanClick = () => {
    if (!sessionUrl) {
      return;
    }
    analytics.track('Manage Plan Clicked', { businessId: currentBusiness.id });
    window.open(sessionUrl, '_blank');
  };
  const newestPlan = useMemo(() => {
    const activePlan = availablePlans(plans)?.[0];
    return (
      activePlan ||
      plans.sort((a: any, b: any) => a.created_at - b.created_at)[0]
    );
  }, [plans]);
  return (
    <>
      <Divider />
      {
        <MainPlanView
          onClick={sessionUrl ? handleManagePlanClick : undefined}
          key={newestPlan.id}
          name={newestPlan.plan_template?.name}
          credits={newestPlan.quantity}
          domainSize={newestPlan.plan_template?.domain_size}
          expirationDate={newestPlan.expiration}
          durationUnit={newestPlan.plan_template.duration_unit}
          price={newestPlan.plan_template.price}
        />
      }
      <Divider />
      <Box style={{ margin: '1vh 0' }} />
      <SectionHeader variant="h2">Billing history</SectionHeader>
      <BillingHistory payments={subscriptionPayments} />
    </>
  );
};

const SectionHeader = styled(Typography)`
  margin: 1vh 0 2vh 0;
  font-size: 18px;
  font-weight: 500;
`;
