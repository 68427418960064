import { Scan } from '@equally-ai-front/common/src/types/issues';
import { ScanStatus } from '@equally-ai-front/common/src/lib/scans';
import { ScanGroupSummary } from '../containers/dashboard-container/components/scan-table/scans-table';
import { ScanGroup } from '@equally-ai-front/common/src/redux/historySlice/types';

export const isScanLoading = (scan: Scan) => {
  return (
    [ScanStatus.pending, ScanStatus.processed].includes(scan.status) &&
    scan.created_at > new Date(Date.now() - 2 * 60 * 1000).toISOString()
  );
};

export const getPendingScans = (scans: Scan[]) => {
  return scans.filter((scan) => scan.status === ScanStatus.pending);
};

export const getGroupScansById = (scansHistory: Scan[], groupId: number) => {
  return scansHistory.filter((scan) => scan.group_id === groupId);
};

export const getScanGroupSummaryList = (
  history: Scan[],
  scanGroups: Record<string, ScanGroup>,
) => {
  const groupIdToGroupSummary: Record<number, ScanGroupSummary> = {};
  const groupIdToScans: Record<number, Scan[]> = {};

  for (const scan of history) {
    if (scan.group_id) {
      if (!groupIdToScans[scan.group_id]) {
        groupIdToScans[scan.group_id] = [];
      }
      groupIdToScans[scan.group_id].push(scan);
    }
  }

  if (Object.entries(groupIdToScans).length === 0) {
    return [];
  }
  for (const scanGroup of Object.values(scanGroups)) {
    const allFailed = (groupIdToScans[scanGroup.id] || []).every(
      (scan) => scan.status === ScanStatus.failed,
    );
    groupIdToGroupSummary[scanGroup.id] = {
      ...scanGroup,
      scans: groupIdToScans[scanGroup.id],
    };
    if (allFailed) {
      groupIdToGroupSummary[scanGroup.id]['status'] = ScanStatus.failed;
    }
  }
  return Object.values(groupIdToGroupSummary);
};
