import { getScansTotals } from '@equally-ai-front/common/src/lib';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { TotalScansData } from '@equally-ai-front/common/src/types/scans';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
import { CarouselSlide } from '../../components/carousel-slide';
import { MOBILE_PX } from '../../utils/constants';

interface IssuesInfoProps {
  label: string;
  value: number;
}

interface IssuesInfoSlideProps {
  totalScansData: TotalScansData | null;
}

const calculateIssuesInfo = (
  totalScansData: TotalScansData | null,
  issues: Issue[],
) => {
  const dismissedIssues = issues.filter(
    (issue) => issue.status === 'dismissed',
  ).length;

  if (!totalScansData) {
    return [
      'Total Issues',
      'Critical',
      'Potential',
      'Best Practice',
      'Manual',
      'Dismissed',
      'Pages Scanned',
    ].map((label) => ({ label, value: 0 }));
  }

  const {
    totalIssues,
    totalNotices,
    totalManual,
    totalUsability,
    totalPageScanned,
    totalCritical,
  } = getScansTotals(totalScansData);

  return [
    {
      label: 'Total Issues',
      value: totalIssues,
    },
    { label: 'Critical', value: totalCritical },
    { label: 'Potential', value: totalUsability },
    { label: 'Best Practice', value: totalNotices },
    { label: 'Manual', value: totalManual },
    { label: 'Dismissed', value: dismissedIssues },
    { label: 'Pages Scanned', value: totalPageScanned },
  ];
};

export const IssuesInfoSlide = ({ totalScansData }: IssuesInfoSlideProps) => {
  const { allIssues } = useAppSelector((state) => state.issues);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const issuesInfo: IssuesInfoProps[] = useMemo(() => {
    return calculateIssuesInfo(totalScansData, allIssues);
  }, [totalScansData, allIssues]);

  return (
    <Wrapper>
      <CarouselSlide
        activeSlideIndex={activeSlideIndex}
        setActiveSlideIndex={setActiveSlideIndex}
      >
        {issuesInfo.map(({ value, label }) => (
          <CarouselItem key={label}>
            <CarouselHeader>{label}</CarouselHeader>
            <CarouselText>{value}</CarouselText>
          </CarouselItem>
        ))}
      </CarouselSlide>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media screen and (max-width: ${MOBILE_PX}px) {
    margin: 40px 0 10px 0;
  }
`;

const CarouselItem = styled.div`
  width: 180px;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-right: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CarouselHeader = styled.h3`
  color: #9ea6b4;
  font-size: 15px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 0;
`;

const CarouselText = styled.p`
  font-size: 38px;
  color: #000a14;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 0;
`;
