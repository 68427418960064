import React, { CSSProperties, useCallback } from 'react';
import { styled } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';

interface DatePickerFieldProps {
  value: number;
  onChange: (arg: any) => void;
  disabled?: boolean;
  style?: CSSProperties;
  placeholder?: string;
}

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  borderBottom: '1px solid #000000',

  '& .MuiInput-root': {
    '& .MuiInput-input': {
      cursor: 'pointer',
    },
  },

  '& .MuiInput-underline:before': {
    display: 'none',
  },
  '& .MuiInput-underline:after': {
    display: 'none',
  },
}));

export const DatePickerField = ({
  value,
  onChange,
  disabled,
  style,
  placeholder,
}: DatePickerFieldProps) => {
  const selectedDate = value ? DateTime.fromSeconds(value) : null;
  const onDateChange = useCallback(
    (date: unknown) => {
      const seconds = date instanceof DateTime ? date.toSeconds() : date;
      onChange(seconds);
    },
    [onChange],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <StyledDatePicker
        disabled={disabled}
        value={selectedDate}
        onChange={onDateChange}
        sx={style}
        disableMaskedInput
        mask="__/__/____"
        renderInput={(props) => <TextField {...props} />}
      />
    </LocalizationProvider>
  );
};
