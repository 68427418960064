export const ActionOptions = [
  {
    value: 'click-an-element',
    label: 'Click an element',
  },
  {
    value: 'set-field',
    label: 'Set field',
  },
  {
    value: 'delete',
    label: 'Clear field',
  },
  {
    value: 'click-checkbox-uncheck',
    label: 'Uncheck field',
  },
  {
    value: 'click-checkbox-check',
    label: 'Check field',
  },
  {
    value: 'wait-for-url-to-be',
    label: 'Wait for url to be',
  },
  {
    value: 'navigate-to',
    label: 'Navigate to',
  },
  {
    value: 'wait-for-element',
    label: 'Wait for element',
  },
];

export const setFieldComponents = [
  {
    value: 'input',
    label: 'Input',
  },
  {
    value: 'local-storage',
    label: 'Local Storage',
  },
  {
    value: 'cookies',
    label: 'Cookies',
  },
];

export const elementComponents = [
  {
    value: 'button',
    label: 'Button',
  },
  {
    value: 'input',
    label: 'Input',
  },
  {
    value: 'checkbox',
    label: 'Checkbox',
  },
  {
    value: 'radiobutton',
    label: 'Radio button',
  },
];

export const setFieldActions = ['set-field', 'local-storage', 'cookies'];

export const elementComponentTypes = [
  'button',
  'input',
  'checkbox',
  'radiobutton',
];
