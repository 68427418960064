import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { buildAuditURL } from '../../../common/buildURL';
import useWillMount from '../../../hooks/useWillMount';

import { buildWCAGReport } from '../helpers/templates';

import useAuditAPI from './useAuditAPI';

export const AuditContext = createContext();

export default function AuditProvider({ children }) {
  const navigate = useNavigate();
  const [auditURLStatus, setAuditURLStatus] = useState({});
  const [report, setReport] = useState(null);
  const [auditInProgress, setAuditInProgress] = useState(false);
  const lastAuditURL = useRef();

  const { performFetch, performCancel, ...auditProps } = useAuditAPI();
  const auditCount = useRef();

  /** Audit Count */
  useWillMount(() => {
    const count = parseInt(Storage.auditCount, 10);
    auditCount.current = Number.isInteger(count) ? count : 0;
  });

  const increaseCount = useCallback(() => {
    const count = parseInt(Storage.auditCount, 10);
    const newCount = Number.isInteger(count) ? count + 1 : 1;
    Storage.auditCount = newCount;
    auditCount.current = newCount;
  }, []);

  /** - */

  const addVisitedAuditStatus = useCallback((url, status) => {
    setAuditURLStatus((data) => ({
      ...data,
      [url]: status,
    }));
  }, []);

  /** - */

  const performAudit = useCallback(
    async (url) => {
      setAuditInProgress(true);
      /** If Current URL */
      if (lastAuditURL.current === url) {
        setAuditInProgress(false);
        return;
      }

      /** Change URL */
      // const newAuditURL = buildAuditURL(url);
      // navigate(newAuditURL);

      /** Cancel previous audit */
      performCancel();

      const { data, error } = await performFetch(url);

      if (!error) {
        const auditReport = buildWCAGReport(url, data);

        setReport(auditReport);

        /** Increase audits count */
        increaseCount();

        /** Save URL Status */
        addVisitedAuditStatus(url, auditReport.status);

        /** Save URL */
        lastAuditURL.current = url;
      }

      setAuditInProgress(false);
    },
    [
      addVisitedAuditStatus,
      navigate,
      increaseCount,
      performCancel,
      performFetch,
    ],
  );

  return (
    <AuditContext.Provider
      value={{
        performAudit,
        auditURLStatus,
        report,
        auditInProgress,
        ...auditProps,
      }}
    >
      {children}
    </AuditContext.Provider>
  );
}

AuditProvider.propTypes = {
  children: PropTypes.any,
};

AuditProvider.defaultProps = {
  children: null,
};

export const useAudit = () => useContext(AuditContext);

export const withAudit = (WrappedComponent) => {
  // eslint-disable-next-line react/prop-types
  return ({ children, ...props }) => {
    return (
      <AuditProvider>
        <WrappedComponent {...props}>{children}</WrappedComponent>
      </AuditProvider>
    );
  };
};
