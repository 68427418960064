import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React from 'react';

export const EmailNotifications = () => {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <div className="my-plan-tab-panel-header-text">Notifications</div>
      <FormGroup column>
        <FormControlLabel
          control={
            <Switch
              checked={state.checkedA}
              onChange={handleChange}
              name="checkedA"
              color="primary"
            />
          }
          label="Compliance Change"
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.checkedB}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="News and Updates"
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.checkedC}
              onChange={handleChange}
              name="checkedC"
              color="primary"
            />
          }
          label="Billing"
        />
      </FormGroup>
    </div>
  );
};
