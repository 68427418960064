import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { PrimaryButton } from './Controls';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

export const PersonalDetails = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const user = useSelector((state) => state.user.user);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',

        '@media screen and (max-width: 800px)': {
          maxWidth: '100%',
        },
      }}
    >
      <div className="header-page-text">Personal details</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '30px',
        }}
      >
        <TextField
          label="Name"
          defaultValue={user.name}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          className={classes.input}
        />
        <TextField
          className={classes.input}
          label="Email"
          disabled
          value={user.email}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
        <PrimaryButton
          color="primary"
          style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
          variant="contained"
        >
          Save Personal Details
        </PrimaryButton>
      </Box>
    </Box>
  );
};
