import styled from '@emotion/styled';
import { Box, Button, Grid, Input } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setApiError, setApiSuccessMessage } from '../../../store/actions';
import { PageContent, StyledLink, Wrapper } from './styled';
import { useAuth } from '../../../providers/auth-context';
import { TypographyH2, TypographyH3 } from '../../../components/common/styled';
import { GenericInput } from '../../../components/accessibility-components/generic-input';
import { AuthUtils } from '@equally-ai/auth-service';

export const EmailVerification = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const verificationCode = searchParams.get('code');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verifyEmail } = useAuth();

  useEffect(() => {
    if (!email || !verificationCode) {
      return;
    }
    void handleVerifyEmail(email, verificationCode);
  }, [verificationCode, email]);

  const handleVerifyEmail = async (email: string, code: string) => {
    let isSuccess = false;
    try {
      isSuccess = await verifyEmail(email, code);
    } catch (error) {
      // @ts-ignore
      dispatch(setApiError(error.message));
      navigate('/login');
      return;
    }
    if (isSuccess) {
      dispatch(setApiSuccessMessage('Email verified successfully!'));
      navigate('/login');
    }
  };
  const handleCodeChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!email) {
      return;
    }
    const value = e.target.value;
    if (value.length !== 6) {
      return;
    }
    let isSuccess;
    await handleVerifyEmail(email, value);
  };
  const handleResendVerificationEmail = async () => {
    if (!email) {
      return;
    }
    const response = await AuthUtils.resendVerificationEmail(email);

    if (!response) {
      dispatch(setApiError('Oops!, unable to resend email'));
      return;
    }

    dispatch(setApiSuccessMessage('Veriftication email has been resent!'));
  };
  return (
    <Wrapper alignItems="center">
      <PageContent>
        <Box>
          <Title>Please verify your email</Title>
          <Box>
            <Text style={{ fontWeight: 600 }}>
              {`We have sent an email to:`}
            </Text>
            <Text>{email}</Text>
            {/*{email && <EmailLink href={`mailto:${email}`}>{}</EmailLink>}*/}
            <Box mt="20px" />
            <SubText>
              Click the link in the email to verify your email address, or
              submit the code below:
            </SubText>
            <Box mt="20px" />
            {/*<Input onChange={handleCodeChange}></Input>*/}
            <GenericInput
              required
              aria-required
              InputLabelProps={{ shrink: true }}
              label="Verification Code"
              variant="outlined"
              fullWidth
              ariaProps={{}}
              onChange={handleCodeChange}
            />
            <Box>
              <Box mt="20px" />
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <img
                  style={{ height: '15px', marginRight: '3px' }}
                  src="/info-icon.svg"
                  alt=""
                />
                <SubText>Didn't receive the email?</SubText>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <SubText
                  style={{
                    marginRight: '3px',
                    marginBottom: '3px',
                    fontSize: '13px',
                  }}
                >
                  Check your spam folder or{' '}
                  <ClickHereButton onClick={handleResendVerificationEmail}>
                    click here
                  </ClickHereButton>{' '}
                  to resend the verification email.
                </SubText>
              </Box>
            </Box>
            <Box mt="30px" />
            <SubText>Need help?</SubText>
            <Link href="mailto:support@equally.ai">Contact us</Link>
          </Box>
        </Box>
      </PageContent>
    </Wrapper>
  );
};

const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

const Text = styled(TypographyH2)`
  font-weight: 300;
  font-size: 20px;
  line-height: 155%;
`;
const SubText = styled(TypographyH3)`
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
`;

const Link = styled(StyledLink)`
  border-bottom-color: rgba(9, 60, 43, 0.32);
  word-wrap: break-word;
`;

const ClickHereButton = styled(Button)`
  padding: 0;
  text-decoration: underline;
  text-transform: none;
  font-size: 14px;
  &:hover {
    background-color: transparent;
  }
`;

const EmailLink = styled(StyledLink)`
  display: inline;
`;
