import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// import settingsSaga from './saga';
import { DevDomain, FetchScanSettingsArg, SettingsState, Tags } from './types';

export const initialState: SettingsState = {
  settings: [],
  loading: false,
  error: '',
  message: '',
  loadingDevDomain: false,
  devDomain: {
    active: false,
    expiration: '',
    expires: '',
    id: 0,
    url: '',
    name: '',
    platform: '',
    has_elementor: false,
    has_visual_editor: false,
    template: '',
    language: '',
  },
  projectTrackingTags: [],
  pipeLineTags: [],
  platformTags: [],
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },

    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    getScanSettings(state, action: PayloadAction<FetchScanSettingsArg>) {
      state.loading = true;
    },

    getScanSettingsSuccessful(state, action: PayloadAction<any>) {
      state.settings = action.payload ?? [];
    },

    createScanSettings(state, action: PayloadAction<any>) {
      state.loading = true;
    },

    updateScanSettings(state, action: PayloadAction<any>) {
      state.loading = true;
    },

    createScanSettingsSuccessful(state, action: PayloadAction<any>) {
      state.message = action.payload ?? '';
    },

    updateScanSettingsSuccessful(state, action: PayloadAction<any>) {
      state.message = action.payload ?? '';
    },

    registerScan(state, action: PayloadAction<any>) {},

    getDevDomains(state) {
      state.loadingDevDomain = true;
    },

    updateDevDomain(state, action: PayloadAction<any>) {
      state.loadingDevDomain = true;
    },

    setDevDomain(state, action: PayloadAction<DevDomain>) {
      state.devDomain = action.payload;
    },

    setLoadingDevDomain(state, action: PayloadAction<boolean>) {
      state.loadingDevDomain = action.payload;
    },

    setProjectTrackingTags(state, action: PayloadAction<Tags[]>) {
      state.projectTrackingTags = action.payload;
    },

    setPipeLineTags(state, action: PayloadAction<Tags[]>) {
      state.pipeLineTags = action.payload;
    },

    setPlatformTags(state, action: PayloadAction<Tags[]>) {
      state.platformTags = action.payload;
    },
  },
});

export const { actions: settingsActions } = slice;
// export const useSettingsSlice = () => {
//   useInjectReducer({ key: slice.name, reducer: slice.reducer });
//   useInjectSaga({ key: slice.name, saga: settingsSaga });
//   return { actions: slice.actions };
// };
export const settings = slice.reducer;
