import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { Box, SelectChangeEvent } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  ScanSettingStepAction,
  ScanSettingSteps,
} from '@equally-ai-front/common/src/types/audit-settings';
import {
  ActionOptions,
  elementComponents,
  setFieldActions,
  setFieldComponents,
} from '../flowy-components/constants';
import { ActionSelectorInputs } from './components/action-selector-inputs';
import { ScanStepContainer } from './components/common';
import {
  ArrowBox,
  ArrowIcon,
  Header,
  HeaderText,
  TitleAndIcon,
} from './scan-step';
import { MuiSelect } from './scan-step-action-select';

interface ScanStepActionProps {
  index: number;
  step: ScanSettingSteps;
  action: ScanSettingStepAction;
  setSteps: Dispatch<SetStateAction<ScanSettingSteps[]>>;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
}

export const ScanStepAction = ({
  index,
  action,
  step,
  setSteps,
  accordionState,
  handleAccordionToggle,
}: ScanStepActionProps) => {
  const handleRemoveAction = (stepId: string, actionIndex: number) => {
    setSteps((prevSteps) =>
      prevSteps.map((currentStep) => {
        if (currentStep.id !== stepId) {
          return currentStep;
        }

        const filteredActions = currentStep.actions.filter(
          (_, idx) => idx !== actionIndex,
        );
        return {
          ...currentStep,
          actions: filteredActions,
        };
      }),
    );
  };

  const handleChange = (
    evt: SelectChangeEvent | React.ChangeEvent<HTMLInputElement>,
    stepId: string,
    actionIndex: number,
  ) => {
    evt.preventDefault();
    const { name, value } = evt.target;

    setSteps((prevSteps) =>
      prevSteps.map((currentStep) => {
        if (currentStep.id !== stepId) {
          return currentStep;
        }

        const updatedActions = currentStep.actions.map(
          (currentStepAction, idx) => {
            if (idx !== actionIndex) {
              return currentStepAction;
            }

            const updatedAction = { ...currentStepAction };

            if (name === 'operation') {
              updatedAction.key = '';
              updatedAction.value = '';
              updatedAction.type = '';
            }

            if (name === 'type' && action.operation.includes('checkbox')) {
              updatedAction.value =
                action.operation === 'click-checkbox-check' ? 'true' : 'false';
            }

            if (name === 'key') {
              updatedAction.error = '';
            }

            return {
              ...updatedAction,
              [name]: value,
            };
          },
        );

        return {
          ...currentStep,
          actions: updatedActions,
        };
      }),
    );
  };

  const showInputFields = () => {
    let FinalElement = <></>;

    switch (action.type) {
      case 'button':
      case 'radiobutton':
      case 'checkbox':
        FinalElement = (
          <>
            <Input
              name="key"
              className="selector"
              type="text"
              onChange={(evt) => handleChange(evt, step.id, index)}
              placeholder="Enter css selector"
              value={action.key}
              aria-label="field for component selector"
              required
            />
            <ErrorSpan>{action.error}</ErrorSpan>
          </>
        );
        break;
      case 'input':
        FinalElement = (
          <ActionSelectorInputs
            handleChange={handleChange}
            stepId={step.id}
            index={index}
            action={action}
            firstInput={{
              className: 'selector',
              placeHolder: 'Enter css selector',
              ariaLabel: 'field for component selector',
            }}
            secondInput={
              setFieldActions.includes(action.operation)
                ? {
                    className: '',
                    placeHolder: 'Enter the value of the component',
                    ariaLabel: "field for component's value",
                  }
                : undefined
            }
          />
        );
        break;
      case 'url':
        FinalElement = (
          <Input
            aria-label="field for url component's value"
            name="value"
            type="text"
            onChange={(evt) => handleChange(evt, step.id, index)}
            placeholder="Enter the value of the url"
            value={action.value}
            required
          />
        );
        break;
      case 'local-storage':
      case 'cookies':
        FinalElement = (
          <ActionSelectorInputs
            handleChange={handleChange}
            stepId={step.id}
            index={index}
            action={action}
            firstInput={{
              className: '',
              placeHolder: 'Enter the key for the storage',
              ariaLabel: "field for component's key",
            }}
            secondInput={
              action.operation !== 'delete'
                ? {
                    className: '',
                    placeHolder: 'Enter the value to be stored',
                    ariaLabel: "field for component's value",
                  }
                : undefined
            }
          />
        );
        break;
      default:
        break;
    }

    return <>{FinalElement}</>;
  };

  const showComponentType = () => {
    let FinalElement = <></>;

    switch (action.operation) {
      case 'set-field':
      case 'delete':
        FinalElement = (
          <SelectDiv>
            <MuiSelect
              selectLabel="Select the component type"
              options={setFieldComponents}
              value={action.type || ''}
              name="type"
              required
              handleChange={handleChange}
              stepId={step.id}
              index={index}
              fullWidth
            />
          </SelectDiv>
        );
        break;
      case 'click-an-element':
      case 'wait-for-element':
        FinalElement = (
          <SelectDiv>
            <MuiSelect
              selectLabel="Select the element type"
              options={elementComponents}
              value={action.type || ''}
              name="type"
              required
              handleChange={handleChange}
              stepId={step.id}
              index={index}
              fullWidth
            />
          </SelectDiv>
        );
        break;
      case 'click-checkbox-uncheck':
      case 'click-checkbox-check':
        FinalElement = (
          <SelectDiv>
            <MuiSelect
              selectLabel="Select the component type"
              options={[
                {
                  value: 'checkbox',
                  label: 'Checkbox',
                },
              ]}
              value={action.type || ''}
              name="type"
              required
              handleChange={handleChange}
              stepId={step.id}
              index={index}
              fullWidth
            />
          </SelectDiv>
        );
        break;
      case 'wait-for-url-to-be':
      case 'navigate-to':
        FinalElement = (
          <SelectDiv>
            <MuiSelect
              selectLabel="Select the component type"
              options={[
                {
                  value: 'url',
                  label: 'Url',
                },
              ]}
              value={action.type || ''}
              name="type"
              required
              handleChange={handleChange}
              stepId={step.id}
              index={index}
              fullWidth
            />
          </SelectDiv>
        );
        break;
      default:
        break;
    }

    return <>{FinalElement}</>;
  };

  const accordionId = `${index}-action`;

  return (
    <ScanStepActionContainer>
      <AccessibilityAccordion
        title={step.stepName}
        accordionId={accordionId}
        accordionState={accordionState}
        handleToggleAccordion={() => handleAccordionToggle(accordionId)}
        accordionHeader={
          <Header>
            <TitleAndIcon>
              <ArrowBox>
                <ArrowIcon
                  src="/chevron-down-black.svg"
                  alt="arrow down icon"
                  shouldRotate={accordionState[accordionId]}
                />
              </ArrowBox>
              <Box ml={'12px'} />
              <HeaderText>Action&nbsp;{index + 1}</HeaderText>
            </TitleAndIcon>
            <Button
              onClick={() => handleRemoveAction(step.id, index)}
              type="button"
            >
              Remove
            </Button>
          </Header>
        }
      >
        <StepDiv>
          <SelectDiv>
            <MuiSelect
              selectLabel="Select an action"
              options={ActionOptions}
              value={action.operation}
              name="operation"
              required
              handleChange={handleChange}
              stepId={step.id}
              index={index}
              fullWidth
            />
          </SelectDiv>
          {showComponentType()}
          {showInputFields()}
        </StepDiv>
      </AccessibilityAccordion>
    </ScanStepActionContainer>
  );
};

const ScanStepActionContainer = styled(ScanStepContainer)`
  background-color: #f5f7fa;
`;

const StepDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;
`;

const SelectDiv = styled.div`
  margin-top: 5%;
`;

export const Button = styled.button`
  border: 2px solid #cbd2d9;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  color: #000a14;
  padding: 6px 16px;
  border-radius: 8px;
  cursor: pointer;
`;

export const Input = styled.input`
  border: none;
  background-color: #ffffff;
  height: 48px;
  border: 2px solid #e4e7eb;
  border-radius: 8px;
  display: block;
  margin-top: 5%;
  padding: 12px;

  &:focus {
    border: 2px solid #e4e7eb;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
`;

export const ErrorSpan = styled.span`
  color: #ff9999;
`;
