import styled from '@emotion/styled';

export const FieldContainer = styled.div`
  margin: 1vh 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  min-width: 100px;
`;
export const CardDescription = styled.div`
  margin: 1vh 0;
  display: flex;
  flex-direction: row;
`;
