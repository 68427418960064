import styled from '@emotion/styled';
import { AccordionState } from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import {
  DevtoolsSolution,
  ReportData,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { ScanReportSectionItem } from '../scan-report/scan-report-section-item';

interface IssueReportItemDetailsProps {
  title: string;
  description: any[];
  noticeIssuesRelatedToRules: DevtoolsSolution[];
  warningIssuesRelatedToRules: DevtoolsSolution[];
  criticalIssuesRelatedToRules: DevtoolsSolution[];
  wcagReport: ReportData;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
  devDomain?: DevDomain;
  setSuccessMessage: (message: string) => void;
  selectedIssuesId: Record<number, boolean>;
  setSelectedIssuesId: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
}

export const IssueReportItemDetails = (props: IssueReportItemDetailsProps) => {
  const {
    title,
    description,
    noticeIssuesRelatedToRules,
    warningIssuesRelatedToRules,
    criticalIssuesRelatedToRules,
    wcagReport,
    accordionState,
    handleAccordionToggle,
    devDomain,
    setSuccessMessage,
    selectedIssuesId,
    setSelectedIssuesId,
  } = props;
  const [isScanReportItemVisible, setIsScanReportItemVisible] = useState(false);
  const [activeItemId, setActiveItemId] = useState('');
  const toggleSectionItem = (id: string) => {
    if (isScanReportItemVisible && activeItemId === id) {
      setIsScanReportItemVisible(false);
    } else {
      setIsScanReportItemVisible(true);
      setActiveItemId(id);
    }
  };

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Body>
        {description.map((desc) => (
          <React.Fragment key={desc?.id}>
            {desc?.paragraphs &&
              desc?.paragraphs.map((paragraph: any) => (
                <BodyP key={paragraph.id}>{paragraph.text}</BodyP>
              ))}
            {desc?.list && (
              <BodyUl>
                {desc?.list.map((li: any) => (
                  <BodyLi key={li.id}>
                    {li.pre && <Pre>{`${li.pre}: `}</Pre>}
                    {li.text}
                  </BodyLi>
                ))}
              </BodyUl>
            )}
          </React.Fragment>
        ))}
      </Body>
      {criticalIssuesRelatedToRules.length > 0 && (
        <>
          <Title className="mt-10">Critical Issues</Title>
          <IssueWrapper>
            {criticalIssuesRelatedToRules.map((issue, idx) => (
              <ScanReportSectionItem
                title={issue.selector ? issue.selector : issue.title}
                accordionId={issue.id.toString()}
                accordionState={accordionState}
                handleAccordionToggle={handleAccordionToggle}
                level={issue.level}
                sectionItemId={issue.id.toString()}
                activeItemId={activeItemId}
                sectionItem={issue}
                identifier={idx.toString()}
                wcagReport={wcagReport}
                issueIndex={idx}
                issueType="all"
                devDomain={devDomain}
                toggleSectionItem={toggleSectionItem}
                setSuccessMessage={setSuccessMessage}
                selectedIssuesId={selectedIssuesId}
                setSelectedIssuesId={setSelectedIssuesId}
              />
            ))}
          </IssueWrapper>
        </>
      )}
      {warningIssuesRelatedToRules.length > 0 && (
        <>
          <Title className="mt-10">Potential Issues</Title>
          <IssueWrapper>
            {warningIssuesRelatedToRules.map((issue, idx) => (
              <ScanReportSectionItem
                title={issue.selector ? issue.selector : issue.title}
                accordionId={issue.id.toString()}
                accordionState={accordionState}
                handleAccordionToggle={handleAccordionToggle}
                level={issue.level}
                sectionItemId={issue.id.toString()}
                activeItemId={activeItemId}
                sectionItem={issue}
                identifier={idx.toString()}
                wcagReport={wcagReport}
                issueIndex={idx}
                issueType="all"
                devDomain={devDomain}
                toggleSectionItem={toggleSectionItem}
                setSuccessMessage={setSuccessMessage}
                selectedIssuesId={selectedIssuesId}
                setSelectedIssuesId={setSelectedIssuesId}
              />
            ))}
          </IssueWrapper>
        </>
      )}
      {noticeIssuesRelatedToRules.length > 0 && (
        <>
          <Title className="mt-10">Best Practice</Title>
          <IssueWrapper>
            {noticeIssuesRelatedToRules.map((issue, idx) => (
              <ScanReportSectionItem
                title={issue.selector ? issue.selector : issue.title}
                accordionId={issue.id.toString()}
                accordionState={accordionState}
                handleAccordionToggle={handleAccordionToggle}
                level={issue.level}
                sectionItemId={issue.id.toString()}
                activeItemId={activeItemId}
                sectionItem={issue}
                identifier={idx.toString()}
                wcagReport={wcagReport}
                issueIndex={idx}
                issueType="all"
                devDomain={devDomain}
                toggleSectionItem={toggleSectionItem}
                setSuccessMessage={setSuccessMessage}
                selectedIssuesId={selectedIssuesId}
                setSelectedIssuesId={setSelectedIssuesId}
              />
            ))}
          </IssueWrapper>
        </>
      )}
    </Container>
  );
};

/** Styled Components */
const Container = styled.div`
  margin: 16px;

  .mt-10 {
    margin-top: 20px;
  }

  @media screen and (min-width: 960px) {
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 48px;
  }

  @media screen and (max-width: 768px) {
    margin: 20px;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
`;

const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`;

const BodyUl = styled.ul`
  list-style-type: '・';
`;

const BodyLi = styled.li`
  padding: 6px 0;
`;

const BodyP = styled.p``;

const Pre = styled.span`
  font-style: italic;
`;

const IssueWrapper = styled(Paper)`
  border: 1px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  margin: 10px 0 30px 0;

  > div {
    border-bottom: 1px solid #e4e7eb;
  }
`;
