import { Box, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { styled, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { SearchOutlined } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ALL_PLANS_OPTION,
  INACTIVE_PLANS_OPTION,
} from '../../../common/magicValues';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { PrimaryButton, PrimaryMobileButton } from '../../redesign/Controls';
import Container from '../Container';
import { HeadingTypography } from '../heading';
import LoadingView from '../LoadingView';
import PlanDetails from './PlanDetails';
import { usePlans } from '../../../providers/plans-context';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

type PlansAndRecordsType = 'widget' | 'developer';

interface PlansAndRecordsProps {
  type: PlansAndRecordsType;
  heading: string;
  selectedPlan: string;
  searchValue: string;
  handleSearch: (query: string) => void;
  showActionButton: boolean;
  attachPlanButtonLabel: string;
  handleAttachPlanClick: () => void;
  handleActionButton: () => void;
  children: React.ReactNode;
}

export const PlansAndRecords = ({
  type,
  heading,
  selectedPlan,
  searchValue,
  handleSearch,
  showActionButton,
  attachPlanButtonLabel,
  handleAttachPlanClick,
  handleActionButton,
  children,
}: PlansAndRecordsProps) => {
  const classes = useStyles();
  const { activePlans: plansData } = usePlans();
  const isLoading = useSelector((state: any) => state.plans.loading);

  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  const getSelectedPlan = () =>
    plansData.find((plan: any) => plan.id === selectedPlan);

  const getActionButton = () => {
    return isMobile ? PrimaryMobileButton : PrimaryButton;
  };
  const ActionButton = getActionButton();

  return (
    <Container>
      <div className="header-manage-websites">
        <div className="header-manage-websites-text">
          <HeadingTypography>{heading}</HeadingTypography>
        </div>
        <div>
          <ButtonsContainer>
            <ActionButton
              variant="contained"
              color="primary"
              onClick={handleActionButton}
              aria-haspopup="dialog"
            >
              + Add new website
            </ActionButton>
            {showActionButton && attachPlanButtonLabel !== '' && (
              <ActionButton
                variant="outlined"
                onClick={handleAttachPlanClick}
                style={{
                  borderRadius: '8px',
                  border: '2px solid #454284',
                  color: '#454284',
                }}
              >
                {attachPlanButtonLabel}
              </ActionButton>
            )}
            <>
              {handleSearch && (
                <StyledFormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="search-input"
                    style={{ visibility: 'hidden', display: 'none' }}
                  >
                    Search
                  </InputLabel>
                  <StyledInput
                    id="search-input"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(evt) => handleSearch(evt.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchOutlined
                          style={{
                            fill: '#000A14D9',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </StyledFormControl>
              )}
            </>
          </ButtonsContainer>
        </div>
      </div>

      <LoadingView open={isLoading} />

      <Box mt="30px" />
      {selectedPlan !== ALL_PLANS_OPTION &&
        selectedPlan !== INACTIVE_PLANS_OPTION && (
          <PlanDetails plan={getSelectedPlan()} type={type} />
        )}
      {children}
    </Container>
  );
};

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '10px',

  '& > button': {
    padding: '6px 16px 6px 12px',

    [theme.breakpoints.down('xs')]: {
      height: '36px',
      padding: '6px 12px',
      fontSize: '15px',
    },
  },

  [theme.breakpoints.down('xs')]: {
    justifyContent: 'flex-start',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    margin: '3px 0',
  },

  'label + .MuiInput-formControl': {
    marginTop: 0,
  },
}));

const StyledInput = styled(OutlinedInput)((props: { theme: Theme }) => ({
  '&.MuiOutlinedInput-root': {
    border: '1px solid #d8d8d8',
    borderRadius: '8px',
    padding: '6px 16px 6px 12px',
    transition: 'border 0.3s ease-in-out',
  },

  '& .MuiOutlinedInput-input': {
    padding: '6px 0 7px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',

    '&:hover': {
      border: 'none',
    },
  },

  '& .MuiInputBase-input::placeholder, & .MuiInputBase-input::-moz-placeholder, & .MuiInputBase-input::-webkit-input-placeholder':
    {
      color: '#000a14',
      opacity: 1,
      fontSize: '14px',
    },

  '&::before, &::after': {
    content: 'none',
  },

  '&:focus-within': {
    border: `1.5px solid ${props.theme.palette.primary.main}`,
  },

  [props.theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}));
