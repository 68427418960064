import React from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

interface Payment {
  id: string;
  business_id: string;
  external_id: string;
  subscription_external_id: string;
  invoice_url: string;
  amount: number;
  currency: string;
  status: string;
  created_at: Date;
}

enum PaymentStatus {
  FAILED = 'failed',
  PAID = 'paid',
}

interface Column {
  key: string;
  label: string;
  format?: (payment: Payment) => React.ReactNode;
}

interface BillingHistoryProps {
  payments: Payment[];
}

const formatDate = (date: Date) =>
  new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date(date));

const formatAmount = (amount: number, currency: string) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amount);

const columns: Column[] = [
  {
    key: 'invoice',
    label: 'Invoice',
    format: (payment) => (
      <Typography variant="body2">
        Basic Plan – {formatDate(payment.created_at)}
      </Typography>
    ),
  },
  {
    key: 'amount',
    label: 'Amount',
    format: (payment) => (
      <Typography variant="body2">
        {formatAmount(payment.amount, payment.currency)}
      </Typography>
    ),
  },
  {
    key: 'created_at',
    label: 'Date',
    format: (payment) => (
      <Typography variant="body2">{formatDate(payment.created_at)}</Typography>
    ),
  },
  {
    key: 'status',
    label: 'Status',
    format: (payment) => {
      const isPaid = payment.status === PaymentStatus.PAID;
      return (
        <Typography
          variant="body2"
          color={isPaid ? 'success.main' : 'error.main'}
        >
          {isPaid ? 'Paid' : 'Failed'}
        </Typography>
      );
    },
  },
  {
    key: 'actions',
    label: 'Actions',
    format: (payment) => (
      <Button
        variant="text"
        disabled={!payment.invoice_url}
        href={payment.invoice_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </Button>
    ),
  },
];
export const BillingHistory: React.FC<BillingHistoryProps> = ({ payments }) => {
  return (
    <Box>
      {/*<Box display="flex" justifyContent="flex-end" mb={2}>*/}
      {/*  <Button variant="outlined">Download all</Button>*/}
      {/*</Box>*/}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.key}>{col.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {payments.map((payment) => (
              <TableRow key={payment.id}>
                {columns.map((col) => (
                  <TableCell key={col.key}>
                    {
                      col.format
                        ? col.format(payment)
                        : (payment as any)[col.key] // fallback if no formatter
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
