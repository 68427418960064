import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../const';
import { Box, CircularProgress, Typography } from '@mui/material';

interface StripeCheckoutProps {
  clientSecret: string;
}
export const StripeCheckout = ({
  clientSecret,
}: StripeCheckoutProps): JSX.Element => {
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [stripeError, setStripeError] = useState('');

  const handleError = () =>
    setStripeError(
      'Stripe failed to load. Please refresh the page or try using incognito mode to purchase a subscription.',
    );

  useEffect(() => {
    const loadAndSetStripe = async (key: string | undefined) => {
      if (!key) {
        throw new Error('Stripe publishable key is not set');
      }

      const MAX_RETRIES = 5;
      let attempts = 0;

      return new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          attempts++;
          try {
            const stripe = await loadStripe(key);
            setStripePromise(stripe);
            clearInterval(intervalId);
            resolve(true);
          } catch (error) {
            if (attempts >= MAX_RETRIES) {
              console.log('Could not load Stripe.');
              clearInterval(intervalId);
              setStripePromise(null);
              handleError();
              reject(false);
            }
          }
        }, 1000);
      });
    };

    void loadAndSetStripe(STRIPE_PUBLISHABLE_KEY);
  }, []);

  const options = {
    clientSecret,
  };

  if (!stripePromise) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        {stripeError ? (
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            sx={{ fontSize: '18px', textAlign: 'center' }}
          >
            {stripeError}
          </Typography>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <Box mb={2}>
        <EmbeddedCheckout />
      </Box>
    </EmbeddedCheckoutProvider>
  );
};
