import React from 'react';
import styled from 'styled-components';
import { LikeIcon, EditIcon } from './GptSolutions';

export interface HeaderIconProp {
  action: any;
  isActive: boolean;
  iconSrc: string;
  disable?: boolean;
}

interface TextSolutionProps {
  titleHeader: string;
  headerAction1: HeaderIconProp;
  headerAction2: HeaderIconProp;
  gptFix: string;
}

export const TextSolution = (props: TextSolutionProps) => {
  const { headerAction1, headerAction2, gptFix } = props;

  return (
    <>
      <Header>
        <ActionsDiv>
          <LikeIcon
            isactive={headerAction1.isActive.toString()}
            onClick={headerAction1.action}
            src={headerAction1.iconSrc}
            alt=""
          />
          <EditIcon
            onClick={headerAction2.action}
            src={headerAction2.iconSrc}
            alt=""
          />
        </ActionsDiv>
      </Header>
      <GptResult>
        <GptBlock>
          <GptResultItem>{gptFix}</GptResultItem>
        </GptBlock>
      </GptResult>
    </>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3%;
  margin-right: 15px;
`;

const ActionsDiv = styled.div`
  display: flex;
  align-items: center;
`;

const GptResult = styled.div`
  background-color: transparent;
  color: #000a14;
  font-size: 13px;
  margin: 2.5% 0;
  margin-top: 0;
  border-radius: 13px;
  width: 100%;
`;

const GptResultItem = styled.pre`
  display: block;
`;

const GptBlock = styled.div`
  width: fit-content;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  font-family: monospace;
  overflow-x: auto;
  max-width: 100%;
  background-color: #000a14;
  color: #ffffff;
  padding: 20px 10px 10px;
  border-radius: 10px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }
`;
