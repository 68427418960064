import axios from 'axios';
import { ApiResponse } from '../utils/axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';

class PlansService {
  static async getPlans(
    businessId: number,
    type: string,
  ): Promise<ApiResponse<any>> {
    let result;
    let error;

    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/ml/plans/${businessId}?type=${type || ''}`,
      );
    } catch (err: any) {
      console.error('Error fetching plans', err?.response?.data?.message);
      error = err;
    }

    const { data } = result || {};
    return {
      data: data?.message || [],
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async cancelPlan(subscriptionId: number): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.patch(
        HTTP_CLIENT_ENDPOINT + '/api/v1/admin/a/ml/subscriptions',
        { subscription_id: subscriptionId },
      );
    } catch (err: any) {
      console.error(
        'Error cancelling subscription',
        err?.response?.data?.message,
      );
      error = err;
    }
    const { data } = result || {};
    return {
      data: data?.message,
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async attachMultipleDomainsToPlan(value: {
    type: string;
    domainIDs: number[];
    businessID: string;
    planID: string;
  }): Promise<ApiResponse<any>> {
    let result;
    let error;
    const payload = {
      type: value.type,
      domain_ids: value.domainIDs,
      active: true,
      description: '',
    };

    const endpoint = `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${value.businessID}/ml/plans/${value.planID}/attach-domain-ids`;

    try {
      result = await axios.put(endpoint, payload);
    } catch (err: any) {
      let errorMessage = 'Something went wrong!';
      if (err?.response?.data?.message) {
        const messageArray = err?.response?.data?.message.split('=');
        errorMessage = messageArray[messageArray.length - 1].trim();
      }
      console.error(errorMessage);
      error = errorMessage;
    }

    const data = result?.data?.message || '';
    return {
      isSuccess: result?.data?.code === 200,
      data,
      error,
    };
  }

  static async detachDomainFromPlan(value: {
    businessID: string;
    websiteID: string;
  }): Promise<ApiResponse<any>> {
    let result;
    let error;
    const endpoint = `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${value.businessID}/${value.websiteID}/detach`;

    try {
      result = await axios.get(endpoint);
    } catch (err: any) {
      let errorMessage = 'Something went wrong!';
      if (err?.response?.data?.message) {
        const messageArray = err?.response?.data?.message.split('=');
        errorMessage = messageArray[messageArray.length - 1].trim();
      }
      console.error(errorMessage);
      error = errorMessage;
    }

    const data = result?.data?.message || '';
    return {
      isSuccess: result?.data?.code === 200,
      data,
      error,
    };
  }
}

export default PlansService;
