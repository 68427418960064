import React from 'react';
import styled from 'styled-components';

interface AccessibilityIssueDetailsProps {
  issueDescriptionId: string;
  issueDescriptionContent: JSX.Element;
  howToFixContent: JSX.Element;
  relatedWCAGLink: string;
  extendedAudit: boolean;
  isExtension?: boolean;
  hideHowToFix?: boolean;
}

export const AccessibilityIssueDetails = (
  props: AccessibilityIssueDetailsProps,
) => {
  const {
    issueDescriptionId,
    issueDescriptionContent,
    howToFixContent,
    isExtension,
    hideHowToFix,
    relatedWCAGLink,
    extendedAudit,
  } = props;

  return (
    <Wrapper>
      <SectionDiv
        isFirst
        isExtension={isExtension}
        extendedAudit={extendedAudit}
      >
        <TitleHeader id={issueDescriptionId}>Requirements</TitleHeader>
        <>{issueDescriptionContent}</>
        <WcagRule href={relatedWCAGLink} target="_blank">
          {relatedWCAGLink}
        </WcagRule>
      </SectionDiv>
      {!hideHowToFix && (
        <SectionDiv
          isExtensionHowToFix={isExtension}
          extendedAudit={extendedAudit}
        >
          <TitleHeader isExtensionHowToFix={isExtension}>
            How To Fix
          </TitleHeader>
          <>{howToFixContent}</>
        </SectionDiv>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 32px;
  position: relative;
`;

const SectionDiv = styled.div<{
  isFirst?: boolean;
  isExtension?: boolean;
  isExtensionHowToFix?: boolean;
  extendedAudit: boolean;
}>`
  padding-top: ${(p) =>
    p.isExtensionHowToFix ? '18px' : p.isExtension ? '0' : '16px'};
  margin-bottom: 0;

  ${(p) =>
    p.extendedAudit
      ? `&:after {
    content: '';
    border-bottom: 1.5px solid #e4e7eb;
    display: block;
    width: 100%;
  }`
      : ''}
`;

const TitleHeader = styled.h5<{
  isExtension?: boolean;
  isExtensionHowToFix?: boolean;
}>`
  font-weight: 500;
  font-size: ${(p) => (p.isExtension ? '15px' : '14px')};
  color: rgba(0, 10, 20, 0.85);
  margin-bottom: 1vh;
  margin-top: ${(p) => (p.isExtension ? '0' : '')};
  ${(p) =>
    p.isExtensionHowToFix &&
    `
    margin: 0;
  `}

  @media screen and (max-width: 650px) {
    margin-bottom: ${(p) => (p.isExtensionHowToFix ? '' : '2vh')};
  }
`;

const WcagRule = styled.a`
  font-weight: 500;
  color: rgba(0, 10, 20, 0.85);
  font-size: 12px;
  text-decoration: none;
  border-bottom: 2px solid #c2f4d7;
  cursor: pointer;
  margin-top: 20px;
  width: fit-content;
  display: block;
  margin-bottom: 3vh;

  @media screen and (max-width: 650px) {
    word-wrap: break-word;
    width: 100%;
  }
`;
