import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { scrollToTop, ScrollToTopOnMount } from '../common/ScrollToTop';

import AriaErrorView from './AriaErrorView';
import AriaProgressView from './AriaProgressView';
import AuditReport from './AuditReport';

import { withAudit } from './hooks/useAudit';
import useQueryAuditURL from './hooks/useQueryAuditURL';
import { useDispatch, useSelector } from 'react-redux';
import { setApiError } from '../../store/actions';
import { useAutomaticScan } from '@equally-ai-front/common/src/hooks/use-automatic-scan';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { buildDevtoolsReport } from '@equally-ai-front/common/src/helpers/templates';

const Aria = () => {
  const [currentAuditURL] = useQueryAuditURL();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const [issues, setIssues] = useState<Issue[]>([]);
  const dispatch = useDispatch();
  const onAuditError = useCallback(
    (error: string) => {
      dispatch(setApiError(error));
    },
    [dispatch],
  );
  const { performAudit, isLoading: loading } = useAutomaticScan({
    onError: onAuditError,
  });

  const report = useMemo(() => {
    if (issues.length === 0) {
      return {
        reportGroups: {
          basic: [],
          advanced: [],
          potential: [],
          all: [],
        },
        list: {
          basic: [],
          advanced: [],
          potential: [],
          all: [],
        },
        reportList: [],
        pdfReport: [],
        websiteUrl: '',
        websitePath: '',
        percentages: {
          basic: 0,
          advanced: 0,
          potential: 0,
        },
      };
    }
    return buildDevtoolsReport(issues?.[0]?.domain_url || '', issues);
  }, [issues]);

  const onAudit = useCallback(
    (url: string, cb?: (url: string, currentIssues: Issue[]) => void) => {
      if (!currentBusiness) {
        return;
      }

      async function auditPerform() {
        const { issues: currentIssues } = await performAudit(
          url,
          currentBusiness.id,
          0,
        );
        setIssues(currentIssues);
        cb && cb(url, currentIssues);
        scrollToTop();
      }

      void auditPerform();
    },
    [performAudit, currentBusiness],
  );

  return (
    <>
      <ScrollToTopOnMount />
      <AuditReport handleAudit={onAudit} loading={loading} report={report} />
      <AriaProgressView />
      <AriaErrorView />
    </>
  );
};

export default withAudit(Aria);
