import { AxiosRequestConfig } from 'axios';
import { HistoryData } from '../../utils/devToolsTypeConfig';
import { HTTP_CLIENT_REDIRECT } from '../../utils/magicValues';
import { isPlainObject } from 'lodash';
import { nanoid } from 'nanoid';
import {
  CustomAttributesConfig,
  DynamicElementsConfig,
  ElementRelationshipConfig,
  LandmarkConfig,
  MoreRolesConfig,
  TabIndexConfig,
} from './types';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import moment from 'moment';

export enum WidgetConfigurations {
  IMPROVED_NAVIGATION = 'tabIndex',
  META_DATA = 'metadata',
  LANDMARKS = 'landmarks',
  MORE_ROLES = 'moreRoles',
  ELEMENTS_RELATIONSHIP = 'elementsRelationships',
  DYNAMIC_ELEMENTS = 'dynamicElements',
  MENU_AND_SUBMENU = 'menuSubmenu',
  CUSTOM_ATTRIBUTES = 'customAttributes',
}

export type AdminWidgetConfigType =
  | WidgetConfigurations.IMPROVED_NAVIGATION
  | WidgetConfigurations.META_DATA
  | WidgetConfigurations.LANDMARKS
  | WidgetConfigurations.MORE_ROLES
  | WidgetConfigurations.ELEMENTS_RELATIONSHIP
  | WidgetConfigurations.DYNAMIC_ELEMENTS
  | WidgetConfigurations.MENU_AND_SUBMENU
  | WidgetConfigurations.CUSTOM_ATTRIBUTES;

export * from './types';

export interface UrlScheme {
  url: string;
  pathname: string;
}

export interface GenerateRouteParams {
  domainID: string;
}

const redirectUrl = HTTP_CLIENT_REDIRECT ?? '';

export const getURLDetails = (urlString: string) => {
  try {
    const url = new URL(urlString);
    return {
      hostname: url.hostname,
      pathname: url.pathname,
    };
  } catch {
    return {
      hostname: null,
      pathname: null,
    };
  }
};

export const delay = (t: number) =>
  new Promise((resolve) => setTimeout(resolve, t));

const extractNumberFromIssuesArray = (
  url: string,
  typeOfIssue: 'numOfLowIssues' | 'numofMediumIssues' | 'numOfHighIssues',
  data: HistoryData[],
) => {
  const dataLabels = {
    numOfLowIssues: 'Potential',
    numofMediumIssues: 'Advanced',
    numOfHighIssues: 'Critical',
  };

  const numArray = data
    .filter((history) => history.url === url)
    .map((history) => history[typeOfIssue]);

  return {
    name: dataLabels[typeOfIssue],
    data: numArray,
  };
};

export const sortChartHistoryData = (data: HistoryData[]) => {
  const allURLs = data.map((history) => history.url);
  const uniqueUrls = [...Array.from(new Set(allURLs))];
  const chartData = [];
  for (let i = 0; i < uniqueUrls.length; i++) {
    const url = uniqueUrls[i];

    const lowIssues = extractNumberFromIssuesArray(url, 'numOfLowIssues', data);
    const meduimIssues = extractNumberFromIssuesArray(
      url,
      'numofMediumIssues',
      data,
    );
    const highIssues = extractNumberFromIssuesArray(
      url,
      'numOfHighIssues',
      data,
    );

    chartData.push({
      url,
      data: [lowIssues, meduimIssues, highIssues],
      dates: data
        .filter((history) => history.url === url)
        .map((history) => history.date),
    });
  }

  return chartData;
};

export const redirectToLogin = () => {
  localStorage.removeItem('websiteDomain');
  localStorage.removeItem('devToolsToken');
  localStorage.removeItem('frontendPlatform');
  setTimeout(() => {
    window.location.href = redirectUrl;
  }, 2000);
};

export const requestAdminPanelEndpointConfig = (accessToken?: string) => {
  const config: AxiosRequestConfig = {
    responseType: 'json',
    transitional: {
      silentJSONParsing: false,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: function (status: number) {
      return status >= 200 && status < 300;
    },
  };
  if (accessToken && config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};

export const requestComplianceManagerEndpointConfig = (token: string) => {
  const config: AxiosRequestConfig = {
    responseType: 'json',
    transitional: {
      silentJSONParsing: false,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function (status: number) {
      return status >= 200 && status < 300;
    },
  };

  return config;
};

export const generateAdminDomainConfigRoute = (
  domainId: string,
  widgetConfigType: AdminWidgetConfigType,
) => {
  switch (widgetConfigType) {
    case WidgetConfigurations.IMPROVED_NAVIGATION:
      return `domain/${domainId}/tabindex`;
    case WidgetConfigurations.META_DATA:
      return `domain/${domainId}/metadata`;
    case WidgetConfigurations.LANDMARKS:
      return `domain/${domainId}/landmarks`;
    case WidgetConfigurations.MORE_ROLES:
      return `domain/${domainId}/roles`;
    case WidgetConfigurations.ELEMENTS_RELATIONSHIP:
      return `domain/${domainId}/relations`;
    case WidgetConfigurations.DYNAMIC_ELEMENTS:
      return `domain/${domainId}/dynamic`;
    case WidgetConfigurations.MENU_AND_SUBMENU:
      return `domain/${domainId}/submenu`;
    case WidgetConfigurations.CUSTOM_ATTRIBUTES:
      return `domain/${domainId}/customattributes`;
    default:
      return '';
  }
};

export const formatUTCDate = (utcDate: string): string => {
  const date = new Date(utcDate);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate;
};

export const formatDate = (timeStamp: any) => {
  return moment.unix(timeStamp).format('DD/MM/YYYY');
};

export const isXPathSelectorValid: Yup.TestFunction<
  string | undefined,
  AnyObject
> = (selector: string | undefined) => {
  try {
    if (selector) {
      document.querySelector(selector);
      return true;
    }
  } catch (e) {
    // something here to check WHY it's invalid
  }
  return false;
};

export const injectObjectID = <T>(object: T): T | null => {
  if (!isPlainObject(object)) {
    return null;
  }

  return {
    id: nanoid(),
    ...object,
  };
};

export const stripObjectID = <T>(object: T): T | null => {
  if (!isPlainObject(object)) {
    return null;
  }

  const obj = { ...object };
  delete (obj as any).id;
  return obj;
};

type ValuesType =
  | TabIndexConfig
  | LandmarkConfig
  | ElementRelationshipConfig
  | DynamicElementsConfig
  | MoreRolesConfig
  | CustomAttributesConfig;

export const editItem = (
  state: any[],
  elementId: string,
  newValues: ValuesType | { [key: string]: string },
) =>
  state.map((item) => {
    if (item.id === elementId) {
      return { ...item, ...newValues };
    }
    return item;
  });

export const findItem = (state: any[], elementId: string) =>
  state.find((item) => item.id === elementId);

export const deleteItem = (state: any[], elementId: string) =>
  (state = state.filter((item) => item.id !== elementId));

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
