import { HostTempScreenState } from './screen-slice';

export const mergeAttributes = (
  target: Record<string, any>,
  source: Record<string, any>,
) => {
  for (const key in source) {
    if (typeof source[key] === 'object' && source[key] !== null) {
      if (!target[key]) {
        target[key] = {};
      }
      mergeAttributes(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
};

export const updateHostTempScreenState = (
  hostTempScreenState: Record<string, HostTempScreenState>,
  payload: Record<string, HostTempScreenState>,
) => {
  const newTempState: Record<string, HostTempScreenState> = {
    ...hostTempScreenState,
  };

  // if (Object.keys(payload).length < Object.keys(hostTempScreenState).length) {
  //   return payload;
  // } else {
  //   newTempState = { ...hostTempScreenState };
  // }

  // Object.keys(hostTempScreenState).forEach((selector) => {
  //   if (!newTempState[selector]) {
  //     return;
  //   }
  //   newTempState[selector] = hostTempScreenState[selector];
  // });

  Object.values(payload as Record<string, HostTempScreenState>).forEach(
    (element) => {
      const existingKey = hostTempScreenState[element.selector];

      if (existingKey) {
        mergeAttributes(existingKey.attributes, element.attributes);

        const updatedKey = {
          ...existingKey,
          attributes: {
            ...existingKey.attributes,
          },
        };

        newTempState[element.selector] = updatedKey;
      } else {
        newTempState[element.selector] = {
          selector: element.selector,
          tagName: element.tagName,
          attributes: { ...element.attributes },
        };
      }
    },
  );
  return newTempState;
};
