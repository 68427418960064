import styled from '@emotion/styled';
import { TypographyH1, TypographyH6 } from '../../../components/common/styled';

export const SubHeaderTypography = styled(TypographyH6)`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-right: 5px;
  font-weight: 400;
  opacity: 0.8;
`;

export const HeadingTypography = styled(TypographyH1)`
  font-size: 32px;
  margin-right: 5px;
  font-weight: 500;
`;

export const StepContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1vh 0;
  align-items: flex-start;
`;
export const SubHeadersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1vh 0;
`;
export const StepContent = styled.div`
  padding: 2vh 1vw;
  width: 100%;
`;
