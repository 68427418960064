import React from 'react';

import { useAudit } from '../hooks/useAudit';
import ProgressView from '../ProgressView';

const AriaProgressView = () => {
  const { loading, progress } = useAudit();
  return <ProgressView open={loading} value={progress} />;
};

export default AriaProgressView;
