import styled from '@emotion/styled';
import React from 'react';
import { FilterCondition } from '../types';
import { formatDate } from '../../../helpers';

interface TooltipTitleContainerProp {
  displayName: string;
  filterType: string;
  value: string;
  value2: string | undefined;
  filterCondition: FilterCondition | undefined;
  key: string;
}

export const TooltipTitleContainer = (prop: TooltipTitleContainerProp) => {
  const { displayName, value, value2, filterCondition, key, filterType } = prop;
  return (
    <Wrapper key={key}>
      <FilterTag>
        <TagLabel>{displayName}:</TagLabel>
        <TagValue>{filterType === 'date' ? formatDate(value) : value}</TagValue>
        {value2 && (
          <TagValue style={{ marginLeft: '4px' }}>
            &nbsp;and&nbsp;
            {filterType === 'date' ? formatDate(value2) : value2}
          </TagValue>
        )}
      </FilterTag>
      <FilterTag>
        <TagLabel>Operator:</TagLabel>
        <TagValue>
          {filterCondition && filterCondition.split('_').join(' ')}
        </TagValue>
      </FilterTag>
    </Wrapper>
  );
};
const Wrapper = styled.p`
  color: #fff;
  font-size: 12px;
  margin: 0;
  flex: 1;
`;
const FilterTag = styled.span`
  margin: 0;
  display: block;
`;
const TagLabel = styled.span`
  font-weight: 500;
  font-size: 13px;
`;
const TagValue = styled.span``;
