import { MuiInput } from '@equally-ai-front/common/src/components/custom-mui-input/MuiInput';
import { MuiSelect } from '@equally-ai-front/common/src/components/custom-mui-select/MuiSelect';
import { DatePickerField } from '@equally-ai-front/common/src/components/filters/date-picker-field';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled as MuiStyled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import React from 'react';
import styled from 'styled-components';
import { DAYS, WEEK_OR_MONTH } from '../../utils/constants';
import { PreferenceCompProps } from './preferences-page';

interface ScheduledAuditDetailsProps extends PreferenceCompProps {}

const formatNextScheduleDate = (date: string) => {
  const inputDate = new Date(date);
  const formattedDate = inputDate.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  return formattedDate;
};

const datePickerStyle = {
  border: '1px solid rgb(228, 231, 235)',
  padding: '1.5px 10px',
  borderRadius: '4px',
  backgroundColor: '#ffffff',
  cursor: 'pointer',
};

export const ScheduledAuditDetails = ({
  websiteSettings,
  handleWebsiteSettingsChange,
  setWebsiteSettings,
}: ScheduledAuditDetailsProps) => {
  return (
    <Wrapper>
      <Title variant="h2">Schedule Automatic Scans</Title>
      <AuditDetailsContainer>
        <AuditFrequency>
          <ScheduleAuditInfo>
            <InfoOutlinedIcon sx={{ fontSize: 12 }} />
            <ScheduleInfoText>
              Schedule routine scans for enhanced accessibility compliance.
            </ScheduleInfoText>
          </ScheduleAuditInfo>
          <AuditFrequencyContainer>
            <InputDiv>
              <MuiInput
                label="Every"
                value={websiteSettings.ritual}
                handleChange={handleWebsiteSettingsChange}
                name="ritual"
                type="number"
                required
              />
            </InputDiv>
            <WeekMonthInputDiv>
              <MuiSelect
                selectLabel="Week/Month"
                options={WEEK_OR_MONTH}
                value={websiteSettings.period}
                name="period"
                handleChange={handleWebsiteSettingsChange}
                fullWidth
                required
              />
            </WeekMonthInputDiv>
            <ExtraText>on</ExtraText>
            <DayInputDiv>
              <MuiSelect
                selectLabel="Day"
                options={DAYS}
                value={websiteSettings.dayOfWeek}
                name="dayOfWeek"
                handleChange={handleWebsiteSettingsChange}
                fullWidth
                required
              />
            </DayInputDiv>
          </AuditFrequencyContainer>
          <div>
            <FormControlLabel
              sx={{ marginLeft: 0, marginTop: '10px' }}
              control={
                <MuiSwitch
                  checked={websiteSettings.isScheduledScanActive}
                  onChange={handleWebsiteSettingsChange}
                  name="isScheduledScanActive"
                />
              }
              label="Activate Scheduled Scan"
              labelPlacement="start"
              required
            />
          </div>
          {websiteSettings.nextScheduleScanAt !== '' && (
            <NextReportLabel>
              Next report:
              {formatNextScheduleDate(websiteSettings.nextScheduleScanAt)}
            </NextReportLabel>
          )}
          <MuiDivider />
        </AuditFrequency>
      </AuditDetailsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Title = MuiStyled(Typography)({
  '&.MuiTypography-h2': {
    fontSize: 16,
    fontWeight: 500,
  },
});

const MuiDivider = MuiStyled(Divider)({
  '&.MuiDivider-fullWidth': {
    borderBottomWidth: '1.5px',
  },
});

const MuiSwitch = MuiStyled(Switch)({
  '& .MuiSwitch-track': {
    backgroundColor: '#a5a9ae',
  },
});

const AuditDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  @media screen and (max-width: 400px) {
    margin-top: 15px;
  }
`;

const ScheduleAuditInfo = styled.p`
  font-size: 12px;
  color: rgba(0, 10, 20, 0.85);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const ScheduleInfoText = styled.span`
  line-height: 20px;
  margin-left: 3px;
`;

const AuditFrequency = styled.div`
  width: 100%;
`;

const ExtraText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgba(0, 10, 20, 0.85);
  display: inline-block;
  margin: 0 15px;
`;

const AuditFrequencyContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputDiv = styled.div`
  width: 97px;
  margin-right: 15px;
`;

const WeekMonthInputDiv = styled.div`
  width: 140px;
`;

const DayInputDiv = styled.div`
  width: 118px;
`;

const LastScheduleDateDiv = styled.div`
  width: 252px;
  margin-top: 20px;
`;

const NextReportLabel = styled.span`
  display: inline-block;
  margin-top: 5px;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: rgba(0, 10, 20, 0.65);
  margin-bottom: 20px;
`;
