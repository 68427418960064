import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';

class PreferencesAPI {
  static async saveWebsiteSettings(payload: {
    scanSchedulePayload: any;
    domainId: string;
    businessId: string;
  }) {
    let result;
    let error: any;

    try {
      result = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${payload.businessId}/website-settings/${payload.domainId}`,
        { ...payload.scanSchedulePayload },
      );
    } catch (err) {
      const apiError: any = err;
      console.error('Error saving website settings', err);
      error =
        apiError?.response?.data?.message || 'Error saving website settings.';
    }

    const { data } = result || {};
    return {
      data: data?.data || null,
      isSuccess: data?.success,
      error,
    };
  }

  static async getWebsiteSettings({
    domainId,
    businessId,
  }: {
    domainId: string;
    businessId: string;
  }) {
    let result;
    let error: any;

    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/website-settings/${domainId}`,
      );
    } catch (err) {
      const apiError: any = err;
      console.error('Error getting website settings', err);
      error =
        apiError?.response?.data?.message || 'Error getting website settings.';
    }

    const { data } = result || {};
    return {
      data: data?.data || null,
      isSuccess: data?.code === 200,
      error,
    };
  }
}

export { PreferencesAPI };
