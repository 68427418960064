import { AuthActionTypes, AuthAction, AuthState } from '../index';

export const authReducerInitialState: AuthState = {
  isAuthenticated: false,
  user: null,
  isAdmin: false,
};

export const createReducer = (
  state: AuthState,
  action: AuthAction,
): AuthState => {
  const { type, payload } = action;

  switch (type) {
    case AuthActionTypes.AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
      };

    case AuthActionTypes.USER_DETAIL:
      return {
        ...state,
        user: payload.user,
      };

    case AuthActionTypes.IS_ADMIN:
      return {
        ...state,
        isAdmin: payload.isAdmin,
      };

    default:
      return state;
  }
};
