import React from 'react';
import styled from 'styled-components';
// import { formatDate } from '../../../lib/date';
import { Close } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { TooltipTitleContainer } from './tooltip-title-container';
import { ItemFilter } from '../types';
import { formatDate } from '../../../helpers';

interface RecordFilterContainerProp {
  filters: Record<string, ItemFilter>;
  removeFilterType: (key: string) => void;
  allowAdditionalInfo?: boolean;
}

export const RecordFilterContainer = (prop: RecordFilterContainerProp) => {
  const { filters, removeFilterType, allowAdditionalInfo } = prop;
  return (
    <Wrapper>
      {Object.values(filters).length > 0 && (
        <SelectedFilterWrapper>
          {Object.values(filters).map(
            ({
              key,
              displayName,
              filterType,
              filterCondition,
              value,
              value2,
            }) => (
              <SelectedFilterTag key={key}>
                <FilterTag>
                  <Tooltip
                    title={
                      <TooltipTitleContainer
                        key={key}
                        {...{
                          displayName,
                          value,
                          value2,
                          filterType,
                          filterCondition,
                        }}
                      />
                    }
                    arrow
                  >
                    <div>
                      {allowAdditionalInfo && (
                        <span>
                          {displayName}{' '}
                          {filterCondition?.split('_').join(' ').toLowerCase()}:{' '}
                        </span>
                      )}
                      {filterType === 'date' ? formatDate(value) : value}
                      {value2 && (
                        <span>
                          {' '}
                          -{' '}
                          {filterType === 'date' ? formatDate(value2) : value2}
                        </span>
                      )}
                    </div>
                  </Tooltip>
                </FilterTag>
                <CloseButton
                  aria-label="Remove filter"
                  onClick={() => removeFilterType(key)}
                >
                  <RemoveTag />
                </CloseButton>
              </SelectedFilterTag>
            ),
          )}
        </SelectedFilterWrapper>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  overflow-y: auto;
  position: relative;
  min-height: 60px;
`;
const SelectedFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid rgb(228, 231, 235);
  border-radius: 8px;
  padding: 5px;
  margin: 10px 0;
  background-color: #9e9e9e3b;
`;
const SelectedFilterTag = styled.div`
  border-radius: 12px;
  color: #000a14;
  margin: 0;
  position: relative;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
  border: 2px solid #454284;
  background: #45428442;
  min-width: 45px;
  overflow: hidden;
  margin: 0 4px;
`;
const FilterTag = styled.span`
  display: block;
  margin: 0 auto;
  font-weight: 500;
  padding: 0 5px;
`;

const CloseButton = styled(IconButton)`
  padding: 4px;
  background: #454284;
  min-height: 100%;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background: #203ab03b;
  }

  &:hover svg {
    fill: #000a14 !important;
  }
`;
const RemoveTag = styled(Close)`
  fill: #ffffff;
  height: 13px;
  width: 13px;
`;
