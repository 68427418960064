import { useMemo } from 'react';
import useNotification, { NOTIFICATION_TYPE } from './useNotification';

const useMessage = () => {
  const [notify] = useNotification();
  return useMemo(
    () => ({
      error: (message) => {
        notify(message, NOTIFICATION_TYPE.ERROR);
      },
      success: (message) => {
        notify(message, NOTIFICATION_TYPE.SUCCESS);
      },
      info: (message) => {
        notify(message, NOTIFICATION_TYPE.INFO);
      },
      warning: (message) => {
        notify(message, NOTIFICATION_TYPE.WARNING);
      },
    }),
    [notify],
  );
};

export default useMessage;
