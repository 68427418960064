import styled from '@emotion/styled';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Teams } from '../../components/redesign/Teams';
import PlansContainer from '../plans-container';

export const BusinessSettingsRoutes = () => {
  return (
    <PageContainer>
      {/* <NavigationBar /> */}
      <Routes>
        <Route index element={<Teams />} />
        <Route path={`team`} element={<Teams />} />
        <Route path={`plans`} element={<PlansContainer />} />
      </Routes>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
`;
