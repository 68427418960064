import { getAssetReactAppUrl } from '../../../utils';
import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, ListItemIcon, MenuItem, styled } from '@mui/material';
import { QuickMenuItemsEnum } from '../../../components/expandables/types';
import { GptType } from '../../../types/chat-gpt';

export interface AccessibilityMenuItemProps {
  name: GptType | QuickMenuItemsEnum | string;
  hasIcon?: boolean;
  hasSeparator?: boolean;
  isDivider?: boolean;
  isActive?: boolean;
  icon?: string;
  handleClick: (selector?: any) => void;
  label: string;
  linkTo?: string;
  forwardedRef: (el: HTMLLIElement | null) => void;
  handleKeyUp?: (event: React.KeyboardEvent<HTMLElement>) => void;
  classes?: string;
  openInNewWindow?: boolean;
}

export const AccessibilityMenuItem = (props: AccessibilityMenuItemProps) => {
  const {
    hasIcon,
    hasSeparator,
    isDivider,
    icon,
    handleClick,
    isActive,
    label,
    linkTo,
    handleKeyUp,
    classes,
    openInNewWindow,
  } = props;

  let { forwardedRef } = props;

  return (
    <>
      {isDivider ? (
        <Divider className={classes} sx={{ borderColor: '#686868' }} />
      ) : (
        label && (
          <CustomMenuItem
            onKeyUp={handleKeyUp}
            disabled={isActive}
            active={isActive ? 'true' : ''}
            role="menuitem"
            tabIndex={0}
            onClick={handleClick}
            ref={(el) => forwardedRef(el)}
          >
            {hasIcon === true && (
              <ListItemIcon>
                <img src={getAssetReactAppUrl(icon || '')} alt={label} />
              </ListItemIcon>
            )}
            {linkTo ? (
              <Link
                to={linkTo}
                tabIndex={-1}
                style={{
                  color: '#000a14',
                  textDecoration: 'none',
                  width: '100%',
                  fontWeight: isActive ? 'bolder' : '400',
                }}
                className={classes}
                target={openInNewWindow ? '_blank' : undefined}
              >
                {label}
              </Link>
            ) : (
              <>{label}</>
            )}
          </CustomMenuItem>
        )
      )}
      {hasSeparator ? <p className="separator"></p> : <></>}
    </>
  );
};

const CustomMenuItem = styled(MenuItem)<{ active?: string }>`
  font-weight: 400;
  color: ${({ active }) => (active ? '#686868' : '#000a14')};

  &:focus-visible {
    outline: 1px solid #015fcc;
    outline-style: auto;
  }
`;
