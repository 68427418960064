import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { JiraSettingsForm } from './jira-settings-form';
import { CreateJiraSettingsHeader } from './create-jira-settings-header';
import { LoadingView } from '../../create-jira-task/loading-view';
import { AppDispatch, RootState } from '../../../store';
import {
  FormFieldValidationErrors,
  JiraSettingsFormFields,
} from '../jira-integration-settings';
import { getJiraSettings } from '@equally-ai-front/common/src/redux/business-slice/business';
import { setApiError } from '../../../store/actions';
import { JiraSettings } from '@equally-ai-front/common/src/types/business';

export const CreateJiraSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentBusiness = useSelector(
    (state: RootState) => state.business.currentBusiness,
  );
  const isLoading = useSelector((state: RootState) => state.business.loading);
  const jiraSettings = useSelector(
    (state: RootState) => state.business.jiraSettings,
  );
  const [jiraFormFields, setJiraFormFields] = useState<JiraSettingsFormFields>({
    userEmail: '',
    apiKey: '',
    boardUrl: '',
    isActive: false,
  });
  const [isInputsDisabled, setIsInputsDisabled] = useState(false);
  const [formFieldErrors, setFormFieldErrors] =
    useState<FormFieldValidationErrors>({});

  const handleDisableInputs = (data: JiraSettings) => {
    setJiraFormFields({ ...data, apiKey: 'XXX-XXX-XXX-XXX' });
    setIsInputsDisabled(true);
    setFormFieldErrors({ userEmail: '', boardUrl: '', apiKey: '' });
  };

  const fetchJiraSettings = useCallback(async () => {
    if (!currentBusiness.id) return;
    const { payload } = await dispatch(
      getJiraSettings({ businessId: currentBusiness.id }),
    );
    if (!payload) return;

    const { isSuccess, error, data } = payload;
    if (!isSuccess && error) {
      dispatch(setApiError(error));
      return;
    }

    if (!data) return;
    data.isActive ? handleDisableInputs(data) : setJiraFormFields(data);
  }, [dispatch, currentBusiness]);

  useEffect(() => {
    if (jiraSettings) return;
    fetchJiraSettings();
  }, [fetchJiraSettings, jiraSettings]);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <JiraSettingsFormWrapper>
      <JiraSettingsForm
        jiraFormFields={jiraFormFields}
        setJiraFormFields={setJiraFormFields}
        formFieldErrors={formFieldErrors}
        setFormFieldErrors={setFormFieldErrors}
        currentBusiness={currentBusiness}
        isInputsDisabled={isInputsDisabled}
        setIsInputsDisabled={setIsInputsDisabled}
        handleDisableInputs={handleDisableInputs}
        header={<CreateJiraSettingsHeader />}
      />
    </JiraSettingsFormWrapper>
  );
};

const JiraSettingsFormWrapper = styled.div`
  padding: 20px 0;
  width: 600px;
  overflow: hidden;

  > div {
    padding: 0 50px;
  }
`;
