import styled from 'styled-components';
import { MOBILE_PX } from '../utils/constants';

export const Title = styled.h1`
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  color: #000a14;
  margin-bottom: 10px;
`;

export const CodeBlock = styled.span`
  color: #ffffff;
  background-color: #000000;
  border-radius: 12px;
  display: inline-block;
  padding: 10px;
  text-align: initial;
`;

export const TableText = styled.span`
  display: inline-block;
  text-align: initial;
`;

export const SearchInputWithIcon = styled.div`
  position: relative;

  input {
    border: none;
    height: 100%;
    padding: 10px;
    width: 100%;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    }
  }

  img {
    position: absolute;
  }
`;

export const Card = styled.div`
  box-shadow: 0px 4px 17px 0px #08256417;
  border-radius: 10px;
  padding: 10px;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  margin: 0;
`;

export const Button = styled.button`
  border: none;
  padding: 6px 16px;
  border-radius: 8px;
  height: 36px;
  cursor: pointer;
  background-color: #c2f4d7;
  color: #213235;

  &:hover {
    background-color: #3bc17d;
    color: #ffffff;
  }
`;

export const CustomMenuWrapper = styled.div`
  @media screen and (min-width: ${MOBILE_PX}px) {
    display: none;
  }

  margin-left: 20px;

  button {
    margin-top: 0;
  }

  @media screen and (max-width: 300px) {
    margin-left: 0;
  }
`;

export const NoCodeChip = styled.p`
  font-weight: 500;
  font-size: 13px;
  background-color: #f5f7fa;
  color: #50565f;
  border-radius: 8px;
  padding: 5px 8px;
  width: fit-content;
`;
