import {
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  GET_INACTIVE_SUBSCRIPTIONS_SUCCESS,
} from '../actionTypes';

const initialState = {
  activeSubscriptions: [],
  inactiveSubscriptions: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_SUBSCRIPTIONS_SUCCESS:
      return { ...state, activeSubscriptions: action.value };
    case GET_INACTIVE_SUBSCRIPTIONS_SUCCESS:
      return { ...state, inactiveSubscriptions: action.value };
    case ADD_TRANSACTION_REQUEST:
      return { ...state, loading: true };
    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default: {
      return state;
    }
  }
}
