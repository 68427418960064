import { OverviewAPI } from '@equally-ai-front/common/src/api/overview-api';
import {
  ScanData,
  TotalScansData,
} from '@equally-ai-front/common/src/types/scans';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';
import { convertKeysFromSnakeCaseToCamelCase } from '../../utils/helpers';

interface OverviewState {
  totalScansData: TotalScansData | null;
  websiteIds: number[];
  loading: boolean;
}

const initialState: OverviewState = {
  totalScansData: null,
  loading: false,
  websiteIds: [],
};

type GetScansPayload = { businessId: number; websiteIds: number[] };

export const fetchTotalScans = createAsyncThunk(
  'overview/get-total-scans',
  async (
    payload: GetScansPayload,
  ): Promise<ApiResponse<{ scans: TotalScansData; websiteIds: number[] }>> => {
    let result;
    let apiError;
    const { businessId, websiteIds } = payload;

    try {
      result = await OverviewAPI.getTotalScans(businessId, websiteIds);
    } catch (err) {
      console.error(err);
      apiError = "Couldn't get total scans";
    }

    const { data, error, isSuccess } = result || {};
    return {
      data: {
        scans: data || [],
        websiteIds: websiteIds || [],
      },
      error: error || apiError,
      isSuccess: isSuccess || false,
    };
  },
);

const formatScansData = (scansData: ScanData[]) => {
  return scansData?.map((scan: ScanData) => ({
    ...scan,
    manualScans:
      scan.type === 'Manual' ? scan.error + scan.warning + scan.notice : 0,
  }));
};

export const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotalScans.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchTotalScans.fulfilled, (state, action) => {
        if (!action.payload.data) {
          return { ...state };
        }

        const { scans, websiteIds } = action.payload.data;
        const formattedData = convertKeysFromSnakeCaseToCamelCase(scans);

        const totalScans =
          formattedData && Object.entries(formattedData.scans).length > 0
            ? formattedData
            : null;

        return {
          ...state,
          totalScansData: totalScans,
          websiteIds: websiteIds || [],
          loading: false,
        };
      })
      .addCase(fetchTotalScans.rejected, (state) => {
        return { ...state, loading: false };
      });
  },
});

export const selectOverviewData = (state: RootState) => state.overview;
export default overviewSlice.reducer;
