import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import React, { useState } from 'react';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
import useWindowSize from '../../hooks/useWindowSize';
import { MOBILE_PX } from '../../utils/constants';
import IssuesPageMobile from './issues-page-mobile';
import IssuesTable from './issues-table';
import { IWebsiteInfo } from '../overview-page/website-list';

export const IssuesPage = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < MOBILE_PX;
  const { loading } = useAppSelector((state: any) => state.issues);
  const { loading: loadingScans } = useAppSelector(
    (state: any) => state.overview,
  );
  const [selectedWebsites, setSelectedWebsites] = useState<IWebsiteInfo[]>([]);

  return (
    <>
      {isMobile ? (
        <IssuesPageMobile
          selectedWebsites={selectedWebsites}
          setSelectedWebsites={setSelectedWebsites}
        />
      ) : (
        <IssuesTable
          selectedWebsites={selectedWebsites}
          setSelectedWebsites={setSelectedWebsites}
        />
      )}
      <LoadingView
        open={loading || loadingScans}
        loadingText="Fetching all issues..."
        zIndex="9000"
      />
    </>
  );
};

export default IssuesPage;
