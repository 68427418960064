import axios from 'axios';
import APIClient, { ApiResponse } from '../utils/axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';
import { WidgetConfigurations } from '../helpers';
import { AuditStatuses } from '../types/audit';
import { ComplianceService } from './compliance';
import graphqlClient from '../utils/graphql.util';

interface CheckComplianceProps {
  urlToScan: string;
  businessId: number;
  websiteId?: number;
}
interface CheckComplianceGroupProps {
  urls: string[];
  businessId: number;
  websiteId?: number;
  scanName?: string;
  target?: string;
}

interface ResolveComplianceProps {
  businessId: number;
  requestIds: string[];
  urlToScan: string;
  websiteId?: number;
}

interface ResolveComplianceAdminProps {
  requestIds: string[];
  urlToScan: string;
}

interface AddIssuePayloadProps {
  domainName: string;
  page_name: string;
  wcagCriteria: string;
  severity: string;
  issueDescription: string;
  cssSelector: string;
  recommendation: string;
  component: string;
}

export interface updateAuditIssuePayload {
  id: number;
  selector: string;
  domain_url: string;
  status: AuditStatuses;
}

export interface updateMultipleIssuePayload {
  website_id: number;
  issue_ids: number[];
  status: AuditStatuses;
}

interface AddManualAuditPayloadProps {
  website_id: number;
  domain: string;
  page_name: string;
  wcag_criteria: string;
  severity: string;
  issue_description: string;
  css_selector: string;
  recommendation: string;
  component: string;
}

export type AttributeRecommendation = {
  attributes?: Record<
    WidgetConfigurations,
    Record<string, string | number | boolean>
  >;
  text?: string;
};

class Audit {
  static generateCancelToken = () => axios.CancelToken.source().token;

  /** onProgress value 0 - 100 */
  static async checkCompliance({
    urlToScan,
    websiteId,
    businessId,
  }: CheckComplianceProps) {
    let isSuccess = false;
    let data = [];

    try {
      if (websiteId) {
        // @ts-ignore
        const result = await axios.post(
          `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance`,
          {
            url: urlToScan,
            website_id: websiteId,
          },
        );
        if (result.status !== 201) {
          return { data: [], isSuccess: false };
        }
        const issuesResponse = await ComplianceService.export(
          websiteId,
          businessId,
          urlToScan,
          result.data.id,
        );

        data = issuesResponse.data as any;
        isSuccess = issuesResponse.isSuccess;
      } else {
        // @ts-ignore
        const result = await axios.post(
          `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/u/compliance`,
          { url: urlToScan },
        );
        data = result.data as any;
        isSuccess = result.status === 200;
      }

      return { data: data, isSuccess: isSuccess };
    } catch (error) {
      return { data: [], isSuccess: false };
    }
  }

  static async checkAsyncCompliance({
    urlToScan,
    websiteId,
    businessId,
  }: CheckComplianceProps) {
    let isSuccess = false;
    let data = [];

    try {
      if (websiteId) {
        // @ts-ignore
        const result = await axios.post(
          `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance/async`,
          {
            url: urlToScan,
            website_id: websiteId,
          },
        );

        if (result.status !== 200) {
          return { data: [], isSuccess: false };
        }

        data = result.data.data as any;
        isSuccess = result.status === 200;
      } else {
        // @ts-ignore
        const result = await axios.post(
          `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/u/compliance`,
          { url: urlToScan },
        );
        data = result.data.data as any;
        isSuccess = result.status === 200;
      }

      return { data: data, isSuccess: isSuccess };
    } catch (error) {
      return { data: [], isSuccess: false };
    }
  }
  static async checkAsyncGroupCompliance({
    urls,
    websiteId,
    businessId,
    scanName,
    target,
  }: CheckComplianceGroupProps) {
    let isSuccess = false;
    let data = [];

    try {
      // @ts-ignore
      const result = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance/async/group`,
        {
          urls: urls,
          website_id: websiteId,
          wcag_target: target,
          group_name: scanName,
        },
      );

      if (result.status !== 200) {
        return { data: [], isSuccess: false };
      }

      data = result.data.data as any;
      isSuccess = result.status === 200;

      return { data: data, isSuccess: isSuccess };
    } catch (error) {
      return { data: [], isSuccess: false };
    }
  }

  static async resolveCompliance({
    businessId,
    requestIds,
    urlToScan,
    websiteId,
  }: ResolveComplianceProps) {
    let isSuccess = false;
    let data = [];

    try {
      if (websiteId) {
        // @ts-ignore
        const result = await axios.post(
          `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance/resolve`,
          { request_ids: requestIds },
        );

        if (result.status !== 200) {
          return { data: [], isSuccess: false };
        }

        data = result.data.data as any;
        isSuccess = result.status === 200;
      } else {
        const [requestId] = requestIds;
        const result = await axios.post(
          `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/u/compliance/resolve`,
          { request_id: requestId, url: urlToScan },
        );

        if (result.status !== 200) {
          return { data: [], isSuccess: false };
        }

        data = result.data.data as any;
        isSuccess = result.status === 200;
      }

      return { data: data, isSuccess: isSuccess };
    } catch (error) {
      return { data: [], isSuccess: false };
    }
  }

  static async addIssue(payload: AddIssuePayloadProps) {
    const response = await APIClient.post(
      'api/adminpanel/a/scan-histories/issues',
      payload,
    );
    return response;
  }

  static async getDomainPages(
    domainId: number,
    businessId: number,
    url: string,
  ) {
    let response;
    let error;
    try {
      response = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance/pages/${domainId}?domain_url=${url}`,
      );
    } catch (err) {
      error = err;
    }
    const { data, status } = response || {};

    return {
      data: data?.data,
      isSuccess: status === 200,
      error: error,
    };
  }

  static async getWebsitesUrls(url: string): Promise<ApiResponse<any>> {
    const query = `
      query GetWebsitesUrls($payload: LambdaRequest!) {
        getWebsitesUrls(payload: $payload) {
          data
          statusCode
        }
      }
    `;

    const result = await graphqlClient.execute({
      functionName: 'getWebsitesUrls',
      query,
      payload: {
        data: JSON.stringify({ url }),
        type: 'get',
      },
    });

    const { data, isSuccess } = result;
    return {
      data,
      isSuccess,
      error: 'Unable to get domain pages, try again later.',
    };
  }

  static async addManualAuditIssue(
    payload: AddManualAuditPayloadProps,
    businessId: number,
  ) {
    let response;
    let error;
    try {
      response = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance/audits/manual`,
        payload,
      );
    } catch (err) {
      error = err;
    }
    const { data, status } = response || {};

    return {
      data: data?.message,
      isSuccess: status === 200,
      error: error,
    };
  }

  static async updateManualAuditIssue(
    businessId: number,
    payload: updateAuditIssuePayload,
  ) {
    let response;
    let error;
    try {
      response = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance/dismissed-issues`,
        payload,
      );
    } catch (err) {
      error = err;
    }
    const { data, status } = response || {};

    return {
      data: data?.message,
      isSuccess: status === 200,
      error: error,
    };
  }

  static async updateMultipleIssueStatus(
    businessId: number,
    payload: updateMultipleIssuePayload,
  ) {
    let response;
    let error;
    try {
      response = await axios.put(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/scans/issues`,
        payload,
      );
    } catch (err) {
      error = err;
    }
    const { data, status } = response || {};

    return {
      data: data?.message,
      isSuccess: status === 200,
      error: error,
    };
  }

  static async getManualAuditIssues(domainId: number, businessId: number) {
    let response;
    let error;
    try {
      response = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance/audits/${domainId}`,
      );
    } catch (err) {
      error = err;
    }
    const { data, status } = response || {};

    return {
      data: data?.data || [], //data?.message,
      isSuccess: status === 200,
      error: data?.message || error,
    };
  }

  static async checkComplianceAdmin(urlToScan: string) {
    let isSuccess = false;
    let data = [];

    try {
      const result = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/adminpanel/a/compliance`,
        { url: urlToScan },
      );
      data = result.data as any;
      isSuccess = result.status === 200;

      return { data: data, isSuccess: isSuccess };
    } catch (error) {
      return { data: [], isSuccess: false };
    }
  }

  static async resolveComplianceAdmin({
    requestIds,
    urlToScan,
  }: ResolveComplianceAdminProps) {
    let isSuccess = false;
    let data = [];

    try {
      const [requestId] = requestIds;
      const result = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/adminpanel/a/compliance/resolve`,
        { request_id: requestId, url: urlToScan },
      );

      if (result.status !== 200) {
        return { data: [], isSuccess: false };
      }

      data = result.data.data as any;
      isSuccess = result.status === 200;

      return { data: data, isSuccess: isSuccess };
    } catch (error) {
      return { data: [], isSuccess: false };
    }
  }
}

export default Audit;
