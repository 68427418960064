import { CreateSuggestedFix } from '@equally-ai-front/common/src/components/create-suggested-fix/CreateSuggestedFix';
import { CssSuggestedFix } from '@equally-ai-front/common/src/components/css-suggested-fix/CssSuggestedFix';
import {
  DevtoolsSolution,
  SuggestionAndOriginalCodeTemplate,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RenderingExampleContents } from './rendering-example-contents';
import CheckMarkIcon from '../../assets/check-mark.svg';
import CopyIcon from '../../assets/copy.svg';
import {
  AFFECTED_CODE_COPIED_SUCCESS_TEXT,
  // CSS_CODE_COPIED_SUCCESS_TEXT,
  SUGGESTED_CODE_COPIED_SUCCESS_TEXT,
} from '@equally-ai-front/common/src/utils/constants';
import CopyButton from '@equally-ai-front/common/src/components/copy-button';

export const TIME_OUT_COPY_FUNCTION: number = 3000;

interface ScanReportIssueDetailsProps {
  devToolsSolution: DevtoolsSolution;
  activeItemId: string;
  issueId: string;
  setSuccessMessage: (message: string) => void;
  showSelectorBox: boolean;
}

const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand('copy', true, text);
};

export const ScanReportIssueDetails = ({
  devToolsSolution,
  activeItemId,
  issueId,
  setSuccessMessage,
  showSelectorBox,
}: ScanReportIssueDetailsProps) => {
  const {
    selector,
    context,
    suggestedCodeFixTemplate,
    exampleCode,
    issueDescription,
    howToFixTheIssue,
    relatedWCAGLink,
    message,
  } = devToolsSolution;

  const [allFlowyContent, setAllFlowyContent] = useState<string>('');
  const exampleCodeRef = useRef<HTMLDivElement>(null);
  const codeSectionDivRef = useRef<HTMLDivElement>(null);

  const getSuggestedCodeAsText = (
    suggestedCodeTemplate: SuggestionAndOriginalCodeTemplate,
    extra1: string,
    extra2: string,
  ): string => {
    let textToCopy: string = '';

    if (
      suggestedCodeTemplate.extraBeginningElementSuggestion &&
      !suggestedCodeFixTemplate?.isExtraElementAfterBeginningTag
    ) {
      textToCopy += suggestedCodeTemplate.extraBeginningElementSuggestion;
    }

    if (suggestedCodeTemplate.beginningCodeTagSuggestion) {
      textToCopy += suggestedCodeTemplate.beginningCodeTagSuggestion;
    } else {
      textToCopy += suggestedCodeTemplate.beginningCodeTag;
    }

    if (suggestedCodeTemplate.attributeSuggestion) {
      textToCopy += ` ${suggestedCodeTemplate.attributeSuggestion}`;
    }

    if (extra1 && extra2) {
      textToCopy +=
        extra1 + suggestedCodeTemplate.extraElementAfterTag + extra2;
    } else {
      textToCopy += suggestedCodeTemplate.originalContent;
    }

    if (suggestedCodeTemplate.contentSuggestion) {
      textToCopy += suggestedCodeTemplate.contentSuggestion;
    }

    if (suggestedCodeTemplate.endingCodeTagSuggestion) {
      textToCopy += suggestedCodeTemplate.endingCodeTagSuggestion;
    } else {
      textToCopy += suggestedCodeTemplate.endingCodeTag;
    }

    return textToCopy;
  };

  const copyContent = async (value: string, successText: string) => {
    try {
      await copyTextToClipboard(value);
      setSuccessMessage(successText);
    } catch {
      setSuccessMessage('Failed to copy');
    }
  };

  const copySuggestedCode = async (
    suggestedCodeTemplate: SuggestionAndOriginalCodeTemplate,
    codeTemplateBeginningTag: string,
    codeTemplateEndingTag: string,
  ) => {
    const textToCopy = getSuggestedCodeAsText(
      suggestedCodeTemplate,
      codeTemplateBeginningTag,
      codeTemplateEndingTag,
    );
    await copyContent(textToCopy, SUGGESTED_CODE_COPIED_SUCCESS_TEXT);
  };

  useEffect(() => {
    if (
      activeItemId === issueId &&
      howToFixTheIssue.length > 0 &&
      !allFlowyContent
    ) {
      let allContent: string = ``;
      allContent += `
      Issue Description
      ${issueDescription}
    `;

      let allInstructions: string = `
      How to fix
    `;

      const list = [message && message, ...howToFixTheIssue];

      list.forEach((affectedCode, index) => {
        const codeNumberInList = index + 1;
        allInstructions += `
        (${codeNumberInList}) ${affectedCode}
      `;
      });

      const selectorContent = `
      Selector
      ${selector}
    `;

      allContent += `
      ${allInstructions}

      ${relatedWCAGLink}

      ${selector ? selectorContent : ''}

      ${exampleCode.length > 0 ? exampleCodeRef.current?.innerText : ''}

      ${context ? codeSectionDivRef.current?.innerText : ''}
    `;

      setAllFlowyContent(allContent);
    }
  }, [
    issueId,
    activeItemId,
    howToFixTheIssue.length,
    allFlowyContent,
    howToFixTheIssue,
    issueDescription,
    message,
    selector,
    relatedWCAGLink,
    exampleCode.length,
    context,
  ]);

  return (
    <Wrapper>
      <IssueDetailsDiv>
        {showSelectorBox && (
          <ContentDiv>
            <TitleHeader>Selector</TitleHeader>
            <SelectorContent>
              {selector || "Couldn't find associated selector"}
            </SelectorContent>
          </ContentDiv>
        )}
        {exampleCode.length > 0 && (
          <ExampleCodeDiv ref={exampleCodeRef}>
            <TitleHeader>Code Example</TitleHeader>
            <RenderingExampleContents exampleArray={exampleCode} />
          </ExampleCodeDiv>
        )}
        {context && (
          <CodeSectionDiv ref={codeSectionDivRef}>
            <>
              <ContentDiv>
                <CodeHeader>Affected Code</CodeHeader>
                <CodeBlock>
                  <CodeContent>{context}</CodeContent>
                  <CopyButton
                    ariaLabel={'Copy affected code'}
                    iconSrc={CopyIcon}
                    checkMarkIcon={CheckMarkIcon}
                    altText={'copy icon'}
                    action={() =>
                      copyContent(context, AFFECTED_CODE_COPIED_SUCCESS_TEXT)
                    }
                  />
                </CodeBlock>
              </ContentDiv>
              <ContentDiv>
                <div>
                  {suggestedCodeFixTemplate ? (
                    <CreateSuggestedFix
                      contextOriginalCode={context}
                      templateForFix={suggestedCodeFixTemplate}
                      copySuggestedCode={copySuggestedCode}
                      codeDescriptionTitle="Suggested Code Fix"
                      headerIcon1={null}
                      headerIcon2={null}
                      copyIcon={{
                        action: () => {},
                        iconSrc: CopyIcon,
                        checkMarkIcon: CheckMarkIcon,
                      }}
                    />
                  ) : (
                    message.includes('Recommendation:') && (
                      <CssSuggestedFix
                        recommendation={message}
                        selector={selector}
                        copyCssCodeFix={copyContent}
                        codeDescriptionTitle="Suggested Code Fix"
                        headerIcon1={null}
                        headerIcon2={null}
                        copyIcon={{
                          action: () => {},
                          iconSrc: CopyIcon,
                          checkMarkIcon: CheckMarkIcon,
                        }}
                      />
                    )
                  )}
                </div>
              </ContentDiv>
            </>
          </CodeSectionDiv>
        )}
      </IssueDetailsDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f5f7fa;
`;

const IssueDetailsDiv = styled.div`
  padding: 16px 32px;

  @media (pointer: none), (pointer: coarse) {
    padding: 16px;
  }
`;

const ExampleCodeDiv = styled.div`
  margin-bottom: 4vh;
`;

const TitleHeader = styled.h5`
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 10, 20, 0.85);
  margin-bottom: 1.5vh;
`;

const ContentDiv = styled.div`
  margin-bottom: 4vh;
`;

const CodeSectionDiv = styled.div``;

const SelectorContent = styled.div`
  font-size: 13px;
  color: #ffffff;
  background-color: #000a14;
  padding: 10px;
  border-radius: 10px;
  overflow-y: auto;

  /* width */

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }
`;

const CodeHeader = styled.h5`
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 10, 20, 0.85);
  margin-bottom: 1.5vh;
`;

const CodeBlock = styled.div`
  background-color: #000a14;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const CodeContent = styled.div`
  color: #ffffff;
  width: 88%;
  word-wrap: break-word;
`;
