import { CustomCircularProgress } from '@equally-ai-front/common/src/components/custom-circular-progress';
import { IssuesLinearProgress } from '@equally-ai-front/common/src/components/issues-linear-progress';
import {
  AccessibilityReport,
  getAccessibilityReport,
  accessibilityReport as DEFAULT_ACCESSIBILITY_REPORT,
} from '@equally-ai-front/common/src/lib';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
import { Card, Centered, HeaderTitle } from '../../components/css-components';

export const ComplianceScoreChartSection = () => {
  const { allIssues } = useAppSelector((state) => state.issues);
  const { totalScansData } = useAppSelector((state) => state.overview);
  const [accessibilityReport, setAccessibilityReport] =
    useState<AccessibilityReport>(DEFAULT_ACCESSIBILITY_REPORT);

  // const wcagALevelComplianceChecks = {
  //   passed: 0,
  //   total: 0,
  // };
  // const wcagAALevelComplianceChecks = {
  //   passed: 0,
  //   total: 0,
  // };

  useEffect(() => {
    if (allIssues.length === 0 || !totalScansData || !totalScansData.scans) {
      setAccessibilityReport(DEFAULT_ACCESSIBILITY_REPORT);
      return;
    }

    const report = getAccessibilityReport(allIssues, totalScansData.scans);
    setAccessibilityReport(report);
  }, [allIssues, totalScansData]);

  return (
    <Wrapper>
      <ComplianceCard>
        <CustomHeaderTitle>Overall Score Accessibility</CustomHeaderTitle>
        <ScoreChartWrapper>
          <CustomCircularProgress
            value={accessibilityReport.overallScore}
            text={`${accessibilityReport.overallScore}%`}
            textSize="18px"
            textColor="#0D1526"
            trailColor="#C4C4C4"
            pathColor="#4AD493"
            squarePath
          />
        </ScoreChartWrapper>
      </ComplianceCard>
      <ComplianceCard>
        <CustomHeaderTitle>Level A Compliance</CustomHeaderTitle>
        {/* <SubTitle>
          {wcagALevelComplianceChecks.passed} of
          {wcagALevelComplianceChecks.total} checks passed
        </SubTitle> */}
        <ComplianceChartWrapper>
          <CustomCircularProgress
            value={accessibilityReport.levelA.score}
            text={`${accessibilityReport.levelA.score}%`}
            textSize="16px"
            textColor="#0D1526"
            trailColor="#BDBDBD"
            pathColor="#8EDBB4"
            showHalfCircle
          />
        </ComplianceChartWrapper>
        <IssuesLinearProgressWrapper>
          <IssuesLinearProgress
            totalIssues={accessibilityReport.levelA.total}
            resolvedIssues={accessibilityReport.levelA.done}
          />
        </IssuesLinearProgressWrapper>
      </ComplianceCard>
      <ComplianceCard>
        <CustomHeaderTitle>Level AA Compliance</CustomHeaderTitle>
        {/* <SubTitle>
          {wcagAALevelComplianceChecks.passed} of
          {wcagAALevelComplianceChecks.total} checks passed
        </SubTitle> */}
        <ComplianceChartWrapper>
          <CustomCircularProgress
            value={accessibilityReport.levelAA.score}
            text={`${accessibilityReport.levelAA.score}%`}
            textSize="16px"
            textColor="#0D1526"
            trailColor="#BDBDBD"
            pathColor="#8EDBB4"
            showHalfCircle
          />
        </ComplianceChartWrapper>
        <IssuesLinearProgressWrapper>
          <IssuesLinearProgress
            totalIssues={accessibilityReport.levelAA.total}
            resolvedIssues={accessibilityReport.levelAA.done}
          />
        </IssuesLinearProgressWrapper>
      </ComplianceCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 0;
  }
`;

const ComplianceCard = styled(Card)`
  width: 100%;
  max-width: 32%;
  height: 240px;
  padding: 17px;
  position: relative;

  @media screen and (max-width: 768px) {
    max-width: 48%;
    margin-top: 30px;
  }

  @media screen and (max-width: 450px) {
    max-width: 100%;
  }
`;

const CustomHeaderTitle = styled(HeaderTitle)`
  @media screen and (min-width: 1150px) {
    white-space: nowrap;
  }
`;

const ScoreChartWrapper = styled(Centered)`
  width: 65%;
  margin: auto;
  margin-top: 20px;

  svg {
    height: 160px;
  }
`;

// const SubTitle = styled.p`
//   color: #9ea6b4;
//   font-size: 13px;
//   margin: 20px 0px 15px 0;
// `;

const ComplianceChartWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  svg {
    height: 160px;
  }
`;

const IssuesLinearProgressWrapper = styled.div`
  position: absolute;
  bottom: 5%;
`;
