import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from 'react';
import { CardDescription, FieldContainer } from '../components/common';
import { serviceFields } from '../consts';
import { OrderCard } from '../order-card/order-card';

interface ServiceAccordionProps {
  name: string;
  remainedCredits: number;
  description: string;
  credits: number;
  services: any[];
}

export const ServiceAccordion = (props: ServiceAccordionProps) => {
  const { name, remainedCredits, description, credits, services } = props;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <HeaderContainer>
          <HeaderActionsContainer>
            <Typography variant="h6">
              {credits ? `${name} - (${remainedCredits}/${credits})` : name}
            </Typography>
          </HeaderActionsContainer>

          <Typography variant="body1">{description}</Typography>
        </HeaderContainer>
      </AccordionSummary>
      <AccordionDetails>
        <CardDescription>
          {Object.keys(serviceFields).map((key) => {
            return (
              <FieldContainer key={`card-field-header-${key}`}>
                <Typography
                  variant={'button'}
                  style={{ fontSize: '12px', opacity: 0.8 }}
                  children={serviceFields[key].fieldName}
                />
              </FieldContainer>
            );
          })}
        </CardDescription>

        {services.map((service) => {
          return (
            <CardContainer>
              <OrderCard service={service} />
            </CardContainer>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardContainer = styled.div`
  margin: 1vh 0;
`;
const HeaderActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

// const Header = styled.h2``;
