import React from 'react';
import { ListItemText, MenuItem } from '@mui/material';

interface FilterOptionsProps {
  filteredOptions: any[];
  handleOptionClick: (filterKey: string | undefined) => void;
}

export const FilterOptions = (props: FilterOptionsProps) => {
  const { filteredOptions, handleOptionClick } = props;
  return (
    <>
      {filteredOptions.map((option) => (
        <MenuItem key={option.key} value={option.key} style={menuItemStyles}>
          <ListItemText
            primary={option.displayName}
            sx={{ width: '100%' }}
            onClick={() => handleOptionClick(option.key)}
          />
        </MenuItem>
      ))}
    </>
  );
};

const menuItemStyles = {
  paddingTop: '6px',
  paddingBottom: '6px',
  paddingLeft: '16px',
  paddingRight: '16px',
  display: 'flex',
  width: '100%',
};
