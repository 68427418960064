import { Box } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled as MuiStyled } from '@mui/material/styles';
import TriangleIcon from '../assets/svg/triangle.svg';
import { getAssetReactAppUrl } from '../utils';
import React from 'react';

import styled, { keyframes } from 'styled-components';

interface LoaderProps {
  open: boolean;
  loadingText?: string;
  zIndex?: string;
}

const BorderLinearProgress = MuiStyled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  width: `min(512px, 70vw)`,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#928fc67e',
    height: '6px',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#454284',
  },
}));

export const LoadingView = ({ open, loadingText, zIndex }: LoaderProps) => {
  if (!open) {
    return null;
  }
  return (
    <PresentationBG zIndex={zIndex}>
      <PresentationContent>
        <AnimatedIcon
          src={getAssetReactAppUrl(TriangleIcon)}
          alt=""
          role="presentation"
          aria-hidden
        />
        <BorderLinearProgress variant="indeterminate" />
        <Box mt="16px" />
        <CenterBlock>
          <Description>
            {loadingText ? loadingText : 'Fetching your results'}
          </Description>
        </CenterBlock>
      </PresentationContent>
    </PresentationBG>
  );
};

const Rotate = keyframes`
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
`;

const AnimatedIcon = styled.img`
  animation: ${Rotate} 0.8s ease-in-out alternate infinite;
  max-width: 40px;
  margin-bottom: 10px;
`;

const PresentationBG = styled.div<{ zIndex?: string }>`
  position: fixed;
  z-index: ${(p) => (p.zIndex ? p.zIndex : '1300')};
  inset: 0px;
  backdrop-filter: blur(5px);
  height: 100%;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
`;

const PresentationContent = styled.div`
  position: relative;
  max-width: 100vh;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Description = styled.span`
  color: #000a14;
  font-weight: 300px;
`;

const CenterBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
