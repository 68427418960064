import React from 'react';
import moment from 'moment';
import { Link, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { capitalizeFirstLetter } from '@equally-ai-front/common/src/utils/helpers';
import IssuesPng from '../../../assets/issues.png';
import GreenCheck from '../../../assets/green-check-circle.png';
import PartiallyCompliant from '../../../assets/partially-compliant.png';
import FullyCompliant from '../../../assets/fully-compliant.png';
import Logo from '../../../assets/logo-black.png';
import LogoWhite from '../../../assets/logo-white.png';
import { ACCESSIBILITY_MAX_SCORE } from '../../../utils/constants';
import { CustomReportFields } from '../../exports/export';
import { IssuesCategoriesCount } from '@equally-ai-front/common/src/lib';

interface PdfReportIssuesSummaryProps {
  pdfReport: DevtoolsSolution[];
  issuesCategoriesCount: IssuesCategoriesCount;
  accessibilityScore: number;
  devDomainName: string;
  customReportFields: CustomReportFields | null;
  isColorShadeOfWhiteOrGrey: boolean;
}

export const IssuesSummary = (props: PdfReportIssuesSummaryProps) => {
  const {
    pdfReport,
    issuesCategoriesCount,
    accessibilityScore,
    devDomainName,
    customReportFields,
    isColorShadeOfWhiteOrGrey,
  } = props;
  const isWebPageCompliant = accessibilityScore > ACCESSIBILITY_MAX_SCORE;
  const [{ domain_url, created_at }] = pdfReport;
  const primaryColor = customReportFields?.themeColor || '#222a66';

  const styles = StyleSheet.create({
    issueSummaryHeader: {
      backgroundColor: primaryColor,
      width: '100%',
      height: 135,
    },
    pdfReportDateWrapper: {
      height: 52,
      display: 'flex',
      justifyContent: 'center',
    },
    pdfReportDate: {
      color: isColorShadeOfWhiteOrGrey ? '#000000' : '#ffffff',
      fontSize: 10,
      marginLeft: '2.5%',
      fontWeight: 'bold',
    },
    issueSummaryContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 10px 0 10px',
      width: '100%',
      maxWidth: '95%',
      margin: 'auto',
      borderRadius: 15,
      marginTop: '-83px',
      backgroundColor: isColorShadeOfWhiteOrGrey ? '#000000' : '#ffffff',
    },
    fullNameWrapper: {
      justifyContent: 'center',
      margin: '0 5px 0',
    },
    fullName: {
      fontSize: 24,
      fontWeight: 'bold',
      color: primaryColor,
      textAlign: 'center',
      margin: 0,
    },
    equallyLogo: { width: '15%', margin: '10px 0' },
    logoSection: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoWrapper: {
      margin: '10px 0',
      overflow: 'hidden',
      width: 100,
      height: 100,
      position: 'relative',
    },
    logo: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    pdfReportTitle: {
      marginBottom: 30,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pdfReportTitleText: {
      color: primaryColor,
      fontSize: 15,
      marginRight: 5,
    },
    pdfLink: {
      textDecoration: 'none',
      color: primaryColor,
      fontWeight: 'bold',
    },
    wcagText: {
      color: primaryColor,
      fontSize: 13,
      marginBottom: 20,
      textAlign: 'center',
    },
    nonCompliantTextWrapper: {
      fontSize: 11,
      color: primaryColor,
      width: '100%',
      maxWidth: '70%',
      margin: 'auto',
      textAlign: 'center',
    },
    nonCompliantPageUrl: {
      textDecoration: 'none',
      color: primaryColor,
      fontWeight: 'medium',
      fontSize: 16,
    },
    nonCompliantText: {
      color: primaryColor,
      fontSize: 11,
    },
    issueSummaryContent: {
      width: '100%',
      maxWidth: '98%',
      margin: '30px auto',
    },
    issueSummaryInformationAndCountWrapper: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    issueSummaryInformationWrapper: {
      width: '100%',
      maxWidth: '70%',
      height: 110,
    },
    issueSummaryInformation: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: isWebPageCompliant ? 30 : 0,
    },
    issueSummaryInformationImageWrapper: {
      width: 40,
      height: 40,
      backgroundColor: isWebPageCompliant ? 'transparent' : '#e4e7eb',
      borderRadius: '50%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      marginTop: isWebPageCompliant ? 30 : 0,
    },
    issueSummaryInformationImage: { width: 22, height: 22 },
    issueSummaryInformationTextWrapper: {
      width: '100%',
      maxWidth: '80%',
      margin: 0,
      marginRight: isWebPageCompliant ? 15 : 5,
    },
    issueSummaryInformationTextInner: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      fontSize: 11,
      lineHeight: '1.3px',
      position: 'relative',
      color: isColorShadeOfWhiteOrGrey ? '#ffffff' : '#000a14',
    },
    issueSummaryImportantText: {
      fontWeight: 'bold',
      position: 'absolute',
      width: '100%',
      left: isWebPageCompliant ? 0 : 135,
      top: isWebPageCompliant ? 15 : '14.5px',
    },
    issueSummaryCountWrapper: {
      width: '100%',
      maxWidth: '27%',
      height: 110,
    },
    issueSummaryCountLabel: {
      fontSize: 15,
      color: isColorShadeOfWhiteOrGrey ? '#ffffff' : '#000a14',
      margin: '4px 0 14px 0',
      fontWeight: 'bold',
    },
    issueSummaryCount: {
      fontSize: 38,
      color: isColorShadeOfWhiteOrGrey ? '#ffffff' : '#000a14',
      fontWeight: 'ultrabold',
      textAlign: 'center',
    },
    issuesSeverityAndCategory: {
      width: '100%',
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
      height: 265,
      paddingBottom: 7,
    },
    issuesSeverityWrapper: { width: '100%', maxWidth: '37%' },
    issuesSeverityAndCategoryTitle: {
      fontSize: 13,
      color: isColorShadeOfWhiteOrGrey ? '#ffffff' : '#000a14',
      fontWeight: 'bold',
    },
    issuesSeverityContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 50,
    },
    issuesSeverityContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 150,
      height: 28,
      borderRadius: 7,
      padding: '4px 10px',
    },
    issuesSeverity: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    issuesSeverityLabel: {
      fontSize: 11,
      marginRight: 4,
      color: '#000a14',
      fontWeight: 'bold',
      width: 85,
    },
    issuesSeverityValue: {
      fontSize: 11,
      fontWeight: 'bold',
    },
    separator: {
      backgroundColor: '#e4e7eb',
      width: 1,
      height: 205,
      marginTop: 30,
    },
    issuesCategoryWrapper: { width: '100%', maxWidth: '63%', paddingLeft: 20 },
    issuesCategoriesCountWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: 15,
    },
    issuesCategoryCountWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '30%',
      marginBottom: 15,
    },
    issuesCategoryCountView: {
      width: 30,
      height: 20,
      borderRadius: 5,
      border: '1.5px solid #e4e7eb',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    issuesCategoryCount: {
      color: isColorShadeOfWhiteOrGrey ? '#ffffff' : '#000a14',
      fontWeight: 'bold',
      fontSize: 10,
    },
    issuesCategory: {
      color: primaryColor,
      fontSize: 11,
      marginLeft: 5,
    },
    equallyAi: {
      color: primaryColor,
      fontSize: 15,
      marginBottom: 30,
      textAlign: 'center',
    },
    complianceWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 10,
    },
    complianceIcon: {
      width: 35,
      height: 35,
    },
    complianceText: {
      fontSize: 24,
      fontWeight: 'bold',
      color: primaryColor,
      marginLeft: 5,
    },
    urlTitle: {
      fontSize: 11,
      textAlign: 'center',
    },
    box: {
      border: '1.5px solid #e4e7eb',
      borderRadius: 10,
      padding: 14,
    },
    dmSansFont: {
      fontFamily: 'DM Sans',
    },
    criticalIssueBg: {
      backgroundColor: '#FF9999',
    },
    criticalColor: { color: '#661F20' },
    potentialBg: {
      backgroundColor: '#F2DF87',
      margin: '10px 0 10px 0',
    },
    potentialColor: { color: '#6F321B' },
    bestPracticeBg: { backgroundColor: '#AEEAD3' },
    bestPracticeColor: { color: '#1E5643' },
  });

  const getIssuesTypeCount = () => {
    const types = ['notice', 'warning', 'error'];
    const counts: { notice: number; warning: number; error: number } = {
      notice: 0,
      warning: 0,
      error: 0,
    };

    pdfReport.forEach((item) => {
      if (types.includes(item.type)) {
        counts[item.type as 'warning' | 'notice' | 'error']++;
      }
    });
    return counts;
  };
  const { warning, notice, error } = getIssuesTypeCount();

  const scannedUrl =
    domain_url !== 'header' && domain_url !== 'footer'
      ? domain_url
      : devDomainName;

  return (
    <View>
      <IssueSummaryHeader styles={styles}>
        <View style={styles.pdfReportDateWrapper}>
          <Text style={[styles.dmSansFont, styles.pdfReportDate]}>
            {moment(created_at).format('ddd MMM D, YYYY')}
          </Text>
        </View>
      </IssueSummaryHeader>
      <View style={[styles.dmSansFont, styles.issueSummaryContentWrapper]}>
        {customReportFields && (
          <View>
            <View style={styles.fullNameWrapper}>
              <Text style={styles.fullName}>{customReportFields.fullName}</Text>
            </View>
            <View style={styles.logoSection}>
              {customReportFields.logo ? (
                <View style={styles.logoWrapper}>
                  <Image style={styles.logo} src={customReportFields.logo} />
                </View>
              ) : (
                <Image
                  style={styles.equallyLogo}
                  src={isColorShadeOfWhiteOrGrey ? LogoWhite : Logo}
                />
              )}
            </View>
          </View>
        )}
        <View style={styles.pdfReportTitle}>
          <Text style={styles.pdfReportTitleText}>Scan results for</Text>
          <Link
            src={`https://${scannedUrl}`}
            style={[styles.dmSansFont, styles.pdfLink]}
          >
            {scannedUrl}
          </Link>
        </View>
        {isWebPageCompliant ? (
          <View style={styles.complianceWrapper}>
            <Image style={styles.complianceIcon} src={FullyCompliant} />
            <Text style={styles.complianceText}>Compliant</Text>
          </View>
        ) : (
          <View style={styles.complianceWrapper}>
            <Image style={styles.complianceIcon} src={PartiallyCompliant} />
            <Text style={styles.complianceText}>Compliance Alert</Text>
          </View>
        )}
        <View>
          <Text style={styles.wcagText}>WCAG2.1 Level AA Success Criteria</Text>
        </View>
        {!isWebPageCompliant && (
          <View style={styles.nonCompliantTextWrapper}>
            <Text>
              <Link
                src={`https://${scannedUrl}`}
                style={[styles.dmSansFont, styles.nonCompliantPageUrl]}
              >
                {scannedUrl}
              </Link>
              &nbsp;
              <Text style={[styles.dmSansFont, styles.nonCompliantText]}>
                isn't ADA compliant. Improve accessibility, become inclusive and
                meet the Web Content Accessibility Guidelines (WCAG).
              </Text>
            </Text>
          </View>
        )}
        <View style={styles.issueSummaryContent}>
          <View style={styles.issueSummaryInformationAndCountWrapper}>
            <View style={[styles.box, styles.issueSummaryInformationWrapper]}>
              <View style={styles.issueSummaryInformation}>
                <View style={styles.issueSummaryInformationImageWrapper}>
                  <Image
                    style={styles.issueSummaryInformationImage}
                    src={isWebPageCompliant ? GreenCheck : IssuesPng}
                  />
                </View>
                <View style={styles.issueSummaryInformationTextWrapper}>
                  <View style={styles.issueSummaryInformationTextInner}>
                    <Text>
                      {isWebPageCompliant
                        ? 'Great job! This page is largely accessible.'
                        : "It's seems like your website isn't inclusive or accessible to people with disabilities."}
                    </Text>
                    &nbsp;
                    <Text style={styles.issueSummaryImportantText}>
                      {isWebPageCompliant
                        ? 'Here are a few minor improvements to enhance it further.'
                        : "Let's change that!"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.box, styles.issueSummaryCountWrapper]}>
              <Text style={styles.issueSummaryCountLabel}>Issues on page</Text>
              <Text style={styles.issueSummaryCount}>{pdfReport.length}</Text>
            </View>
          </View>

          <View style={[styles.box, styles.issuesSeverityAndCategory]}>
            <View style={styles.issuesSeverityWrapper}>
              <Text style={styles.issuesSeverityAndCategoryTitle}>
                Issue severity
              </Text>
              <View style={styles.issuesSeverityContentWrapper}>
                <View
                  style={[styles.issuesSeverityContent, styles.criticalIssueBg]}
                >
                  <View style={styles.issuesSeverity}>
                    <Text style={styles.issuesSeverityLabel}>Critical</Text>
                    <Text
                      style={[styles.issuesSeverityValue, styles.criticalColor]}
                    >
                      {error}
                    </Text>
                  </View>
                </View>
                <View
                  style={[styles.issuesSeverityContent, styles.potentialBg]}
                >
                  <View style={styles.issuesSeverity}>
                    <Text style={styles.issuesSeverityLabel}>Potential</Text>
                    <Text
                      style={[
                        styles.issuesSeverityValue,
                        styles.potentialColor,
                      ]}
                    >
                      {warning}
                    </Text>
                  </View>
                </View>
                <View
                  style={[styles.issuesSeverityContent, styles.bestPracticeBg]}
                >
                  <View style={styles.issuesSeverity}>
                    <Text style={styles.issuesSeverityLabel}>
                      Best Practice
                    </Text>
                    <Text
                      style={[
                        styles.issuesSeverityValue,
                        styles.bestPracticeColor,
                      ]}
                    >
                      {notice}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.separator}></View>
            <View style={styles.issuesCategoryWrapper}>
              <Text style={[styles.issuesSeverityAndCategoryTitle]}>
                Issues detected by category
              </Text>
              <View style={styles.issuesCategoriesCountWrapper}>
                {issuesCategoriesCount.map(([category, count]) => (
                  <View
                    key={`${category}-${count}`}
                    style={styles.issuesCategoryCountWrapper}
                  >
                    <View style={styles.issuesCategoryCountView}>
                      <Text style={styles.issuesCategoryCount}>{count}</Text>
                    </View>
                    <Text style={styles.issuesCategory}>
                      {capitalizeFirstLetter(category)}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const IssueSummaryHeader = ({ children, styles }: any) => {
  return <View style={styles.issueSummaryHeader}>{children}</View>;
};
