import {
  CodeExampleContent,
  SuggestedCodeFixTemplate,
} from './devToolsTypeConfig';

export const AUDIT_STATUS_SUCCESS = 'success';
export const AUDIT_STATUS_WARNING = 'warning';
export const AUDIT_STATUS_ERROR = 'error';

export const PA11Y_TYPE_NOTICE = 'notice';
export const PA11Y_TYPE_WARNING = 'warning';
export const PA11Y_TYPE_ERROR = 'error';

export const AUDIT_STATUS_ALL = [
  AUDIT_STATUS_SUCCESS,
  AUDIT_STATUS_WARNING,
  AUDIT_STATUS_ERROR,
];

export const TYPE_CODE_ERROR = 1;
export const TYPE_CODE_NOTICE = 3;
export const TYPE_CODE_WARNING = 2;

export const TOAST_AUTO_CLOSE: number = 3000;

export const QUERY_PARAM_AUDIT = 'audit';
export const FIELD_AUDIT_URL = 'audit';

export const DEFAULT_ERR0R_MESSAGE =
  "We're currently facing a high volume of requests. Please try again later.";

export const BASIC_CATEGORY_TOOLTIP_MESSAGE =
  'This category includes critical issues that violate accessibility guidelines and must be fixed immediately.';

export const ADVANCED_CATEGORY_TOOLTIP_MESSAGE =
  "This category includes advanced issues that could potentially turn into more serious issues if left unchecked. An example of this type of issue is an 'img' element with a 'null' attribute, which may not necessarily be an error but is still something that should be addressed promptly to prevent further issues from arising.";

export const POTENTIAL_CATEGORY_TOOLTIP_MESSAGE =
  'This category includes general accessibility messages about specific elements in the application. These messages are not necessarily errors, but rather serve as a notification for developers to be aware of certain considerations when it comes to the accessibility of these elements. For example, this category may include a message about ensuring that the title element accurately describes the content of the document. As a developer, it is important to consider these messages and make any necessary updates to ensure that the application is as accessible as possible for all users.';

export const CONFIRMATION_CATEGORY_TOOLTIP_MESSAGE =
  'This category includes accessibility issues that have been manually identified and tested by our team. We provide recommendations on how to fix these issues in order to improve the accessibility of your website.';

export const CATEGORY_NUMBER_TOOLTIP_MESSAGE = 'The total number of';

export const HIGH_IMPACT_MESSAGE =
  'Critical errors that violate accessibility guidelines.';

export const MEDIUM_IMPACT_MESSAGE =
  'This category includes warning issues that, if ignored, could lead to non-compliance with accessibility guidelines.';

export const LOW_IMPACT_MESSAGE =
  'This category includes issues that could potentially result in the application failing to meet certain accessibility guidelines.';

export const ADD_PAGE_TEMPLATE_TOOLTIP_MESSAGE =
  "Add a specific page to be scanned by us by typing the page name here e.g 'pricing'.";

export const MAGIC_TOOLTIP_MESSAGE = 'Find a better solution';

export const GUIDE_TOOLTIP_MESSAGE = 'Step-by-step guide';

export const CREATIVE_TOOLTIP_MESSAGE = 'Identify and add missing attributes';

export const EXPLAIN_TOOLTIP_MESSAGE = 'Clear explanation of issue';

export const NO_GPT_SOLUTION =
  "We couldn't find a better suggestion. The suggestion below is the best one.";

export const COPY_ICON_TOOLTIP_MESSAGE = 'Copy instructions and issue details';

export const DISMISS_ICON_TOOLTIP_MESSAGE = 'Dismiss issue';

export const LIKE_ICON_TOOLTIP_MESSAGE = 'Click to like our suggestion';

export const EDIT_PROMPT_TOOLTIP_MESSAGE = 'Edit prompt';

export const SUGGESTED_CODE_COPIED_SUCCESS_TEXT =
  'Suggested code successfully copied to clipboard!';

export const AFFECTED_CODE_COPIED_SUCCESS_TEXT =
  'Affected code successfully copied to clipboard!';

export const CSS_CODE_COPIED_SUCCESS_TEXT =
  'Css code fix has been successfully copied to clipboard!';

export const codeElements: CodeExampleContent[] = [
  {
    openingTagName: 'a',
    attributes: [
      {
        key: 'href',
        value: 'www.equally.ai',
      },
    ],
    content: 'Just an example',
    closingTag: 'a',
  },
  {
    openingTagName: 'a',
    attributes: [
      {
        key: 'href',
        value: 'www.equally.ai',
      },
    ],
    content: [
      {
        openingTagName: 'img',
        attributes: [
          {
            key: 'src',
            value: 'img_logo.png',
          },
          {
            key: 'alt',
            value: 'example',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    closingTag: 'a',
  },
  {
    openingTagName: 'div',
    attributes: [
      {
        key: 'role',
        value: 'button',
      },
      {
        key: 'aria-label',
        value: 'something clickable',
      },
    ],
    content: [
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'text',
          },
          {
            key: 'id',
            value: 'example',
          },
        ],
        content: '',
        closingTag: null,
      },
      {
        openingTagName: 'button',
        attributes: [
          {
            key: 'type',
            value: 'submit',
          },
        ],
        content: 'Click Me!',
        closingTag: 'button',
      },
    ],
    closingTag: 'div',
  },
  {
    openingTagName: 'p',
    attributes: [],
    content: [
      {
        openingTagName: '',
        attributes: [],
        content: "We're bringing a new feature to your doorsteps",
        closingTag: '',
      },
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: 'hellothere.com',
          },
        ],
        content: 'Read more...',
        closingTag: 'a',
      },
    ],
    closingTag: 'p',
  },
  {
    openingTagName: 'div',
    attributes: [],
    content: [
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'loginPassWord',
          },
        ],
        content: 'Enter your password (required)',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'password',
          },
          {
            key: 'required',
            value: 'true',
          },
          {
            key: 'id',
            value: 'loginPassWord',
          },
          {
            key: 'value',
            value: '',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    closingTag: 'div',
  },
  {
    openingTagName: 'form',
    attributes: [
      {
        key: 'action',
        value: 'www.abcd.com/subscribe',
      },
      {
        key: 'method',
        value: 'post',
      },
    ],
    content: [
      {
        openingTagName: 'p',
        attributes: [],
        content: 'Enter your email to subscribe',
        closingTag: 'p',
      },
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'email',
          },
        ],
        content: 'Enter email:',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'email',
          },
          {
            key: 'id',
            value: 'email',
          },
          {
            key: 'name',
            value: 'email',
          },
        ],
        content: '',
        closingTag: null,
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'submit',
          },
          {
            key: 'value',
            value: 'Subscribe',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    closingTag: 'form',
  },
  {
    openingTagName: 'form',
    attributes: [
      {
        key: 'action',
        value: '...',
      },
    ],
    content: [
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'color',
          },
        ],
        content: 'What is your favorite color?',
        closingTag: 'label',
      },
      {
        openingTagName: 'select',
        attributes: [
          {
            key: 'id',
            value: 'color',
          },
        ],
        content: [
          {
            openingTagName: 'optgroup',
            attributes: [
              {
                key: 'label',
                value: 'Primary',
              },
            ],
            content: [
              {
                openingTagName: 'option',
                attributes: [
                  {
                    key: 'value',
                    value: '1',
                  },
                ],
                content: 'Red',
                closingTag: 'option',
              },
              {
                openingTagName: 'option',
                attributes: [
                  {
                    key: 'value',
                    value: '2',
                  },
                ],
                content: 'Blue',
                closingTag: 'option',
              },
              {
                openingTagName: 'option',
                attributes: [
                  {
                    key: 'value',
                    value: '3',
                  },
                ],
                content: 'Yellow',
                closingTag: 'option',
              },
            ],
            closingTag: 'optgroup',
          },
        ],
        closingTag: 'select',
      },
    ],
    closingTag: 'form',
  },
  {
    openingTagName: 'html',
    attributes: [],
    content: [
      {
        openingTagName: 'head',
        attributes: [],
        content: [
          {
            openingTagName: 'title',
            attributes: [],
            content: 'Accessiblity',
            closingTag: 'title',
          },
        ],
        closingTag: 'head',
      },
      {
        openingTagName: 'body',
        attributes: [],
        content: [
          {
            openingTagName: 'h1',
            attributes: [],
            content: 'Accessiblity techniques',
            closingTag: 'h1',
          },
          {
            openingTagName: '',
            attributes: [],
            content: '...some text here...',
            closingTag: '',
          },
          {
            openingTagName: 'h2',
            attributes: [],
            content: 'Add alt to images',
            closingTag: 'h2',
          },
          {
            openingTagName: '',
            attributes: [],
            content: '...some text here...',
            closingTag: '',
          },
          {
            openingTagName: 'section',
            attributes: [],
            content: [
              {
                openingTagName: 'h2',
                attributes: [],
                content: 'Contact us',
                closingTag: 'h2',
              },
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
            ],
            closingTag: 'section',
          },
        ],
        closingTag: 'body',
      },
    ],
    closingTag: 'html',
  },
  {
    openingTagName: 'ul',
    attributes: [],
    content: [
      {
        openingTagName: 'li',
        attributes: [],
        content: 'Number 1',
        closingTag: 'li',
      },
      {
        openingTagName: 'li',
        attributes: [],
        content: 'Number 2',
        closingTag: 'li',
      },
    ],
    closingTag: 'ul',
  },
  {
    openingTagName: 'html',
    attributes: [],
    content: [
      {
        openingTagName: 'head',
        attributes: [],
        content: [
          {
            openingTagName: 'title',
            attributes: [],
            content: 'Accessiblity',
            closingTag: 'title',
          },
          {
            openingTagName: 'style',
            attributes: [],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: '#paragraphElem { text-align: center; }',
                closingTag: '',
              },
              {
                openingTagName: '',
                attributes: [],
                content: 'img { float: right; }',
                closingTag: '',
              },
            ],
            closingTag: 'style',
          },
        ],
        closingTag: 'head',
      },
      {
        openingTagName: 'body',
        attributes: [],
        content: [
          {
            openingTagName: '',
            attributes: [],
            content: '...some text here...',
            closingTag: '',
          },
        ],
        closingTag: 'body',
      },
    ],
    closingTag: 'html',
  },
  {
    openingTagName: 'table',
    attributes: [
      {
        key: 'border',
        value: '1',
      },
    ],
    content: [
      {
        openingTagName: 'tr',
        attributes: [],
        content: [
          {
            openingTagName: 'td',
            attributes: [],
            content: '',
            closingTag: 'td',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'scope',
                value: 'col',
              },
            ],
            content: 'Name',
            closingTag: 'th',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'scope',
                value: 'col',
              },
            ],
            content: 'Phone',
            closingTag: 'th',
          },
        ],
        closingTag: 'tr',
      },
      {
        openingTagName: 'tr',
        attributes: [],
        content: [
          {
            openingTagName: 'td',
            attributes: [],
            content: '1.',
            closingTag: 'td',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'scope',
                value: 'row',
              },
            ],
            content: 'Barry Allen',
            closingTag: 'th',
          },
          {
            openingTagName: 'td',
            attributes: [],
            content: '234-567',
            closingTag: 'td',
          },
        ],
        closingTag: 'tr',
      },
    ],
    closingTag: 'table',
  },
  {
    openingTagName: 'table',
    attributes: [],
    content: [
      {
        openingTagName: 'tr',
        attributes: [],
        content: [
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'rowspan',
                value: '2',
              },
              {
                key: 'id',
                value: 'h',
              },
            ],
            content: 'Homework',
            closingTag: 'th',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'colspan',
                value: '3',
              },
              {
                key: 'id',
                value: 'e',
              },
            ],
            content: 'Exams',
            closingTag: 'th',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'colspan',
                value: '3',
              },
              {
                key: 'id',
                value: 'p',
              },
            ],
            content: 'Projects',
            closingTag: 'th',
          },
        ],
        closingTag: 'tr',
      },
      {
        openingTagName: 'tr',
        attributes: [],
        content: [
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'headers',
                value: 'e',
              },
              {
                key: 'id',
                value: 'e1',
              },
            ],
            content: '1',
            closingTag: 'th',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'headers',
                value: 'e',
              },
              {
                key: 'id',
                value: 'e2',
              },
            ],
            content: '2',
            closingTag: 'th',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'headers',
                value: 'e',
              },
              {
                key: 'id',
                value: 'ef',
              },
            ],
            content: 'Final',
            closingTag: 'th',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'headers',
                value: 'p',
              },
              {
                key: 'id',
                value: 'p1',
              },
            ],
            content: '1',
            closingTag: 'th',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'headers',
                value: 'p',
              },
              {
                key: 'id',
                value: 'p2',
              },
            ],
            content: '2',
            closingTag: 'th',
          },
          {
            openingTagName: 'th',
            attributes: [
              {
                key: 'headers',
                value: 'p',
              },
              {
                key: 'id',
                value: 'pf',
              },
            ],
            content: 'Final',
            closingTag: 'th',
          },
        ],
        closingTag: 'tr',
      },
      {
        openingTagName: 'tr',
        attributes: [],
        content: [
          {
            openingTagName: 'td',
            attributes: [
              {
                key: 'headers',
                value: 'h',
              },
            ],
            content: '15%',
            closingTag: 'td',
          },
          {
            openingTagName: 'td',
            attributes: [
              {
                key: 'headers',
                value: 'e e1',
              },
            ],
            content: '20%',
            closingTag: 'td',
          },
          {
            openingTagName: 'td',
            attributes: [
              {
                key: 'headers',
                value: 'e e2',
              },
            ],
            content: '16%',
            closingTag: 'td',
          },
          {
            openingTagName: 'td',
            attributes: [
              {
                key: 'headers',
                value: 'e ef',
              },
            ],
            content: '60%',
            closingTag: 'td',
          },
          {
            openingTagName: 'td',
            attributes: [
              {
                key: 'headers',
                value: 'p p1',
              },
            ],
            content: '29%',
            closingTag: 'td',
          },
          {
            openingTagName: 'td',
            attributes: [
              {
                key: 'headers',
                value: 'p p2',
              },
            ],
            content: '25%',
            closingTag: 'td',
          },
          {
            openingTagName: 'td',
            attributes: [
              {
                key: 'headers',
                value: 'p pf',
              },
            ],
            content: '15%',
            closingTag: 'td',
          },
        ],
        closingTag: 'tr',
      },
    ],
    closingTag: 'table',
  },
  {
    openingTagName: 'html',
    attributes: [],
    content: [
      {
        openingTagName: 'head',
        attributes: [],
        content: [
          {
            openingTagName: 'title',
            attributes: [],
            content: 'The world wide web page.',
            closingTag: 'title',
          },
        ],
        closingTag: 'head',
      },
      {
        openingTagName: 'body',
        attributes: [],
        content: '...',
        closingTag: 'body',
      },
    ],
    closingTag: 'html',
  },
  {
    openingTagName: 'table',
    attributes: [],
    content: [
      {
        openingTagName: 'tr',
        attributes: [],
        content: [
          {
            openingTagName: 'th',
            attributes: [],
            content: 'First name',
            closingTag: 'th',
          },
          {
            openingTagName: 'td',
            attributes: [],
            content: [
              {
                openingTagName: 'input',
                attributes: [
                  {
                    key: 'type',
                    value: 'text',
                  },
                  {
                    key: 'title',
                    value: 'First name of groom',
                  },
                  {
                    key: 'tabindex',
                    value: '1',
                  },
                ],
                content: '',
                closingTag: null,
              },
            ],
            closingTag: 'td',
          },
          {
            openingTagName: 'td',
            attributes: [],
            content: [
              {
                openingTagName: 'input',
                attributes: [
                  {
                    key: 'type',
                    value: 'text',
                  },
                  {
                    key: 'title',
                    value: 'First name of bride',
                  },
                  {
                    key: 'tabindex',
                    value: '3',
                  },
                ],
                content: '',
                closingTag: null,
              },
            ],
            closingTag: 'td',
          },
        ],
        closingTag: 'tr',
      },
      {
        openingTagName: 'tr',
        attributes: [],
        content: [
          {
            openingTagName: 'th',
            attributes: [],
            content: 'Last name',
            closingTag: 'th',
          },
          {
            openingTagName: 'td',
            attributes: [],
            content: [
              {
                openingTagName: 'input',
                attributes: [
                  {
                    key: 'type',
                    value: 'text',
                  },
                  {
                    key: 'title',
                    value: 'Last name of groom',
                  },
                  {
                    key: 'tabindex',
                    value: '2',
                  },
                ],
                content: '',
                closingTag: null,
              },
            ],
            closingTag: 'td',
          },
          {
            openingTagName: 'td',
            attributes: [],
            content: [
              {
                openingTagName: 'input',
                attributes: [
                  {
                    key: 'type',
                    value: 'text',
                  },
                  {
                    key: 'title',
                    value: 'Last name of bride',
                  },
                  {
                    key: 'tabindex',
                    value: '4',
                  },
                ],
                content: '',
                closingTag: null,
              },
            ],
            closingTag: 'td',
          },
        ],
        closingTag: 'tr',
      },
    ],
    closingTag: 'table',
  },
];

// export const templateObj1: SuggestedCodeFixTemplate = {
//   hasTag: false,
//   hasAttribute: true,
//   hasContent: false,
//   content: "",
//   attributeKey: "content",
//   attributeValue: "user-scalable=yes",
//   tagValue: "",
// };
export const templateObj1: SuggestedCodeFixTemplate = {
  hasTag: false,
  hasAttribute: true,
  hasContent: true,
  content: "<span class='sr-only'>Link destination</span>",
  attributeKey: 'href',
  attributeValue: 'www.abcd.com',
  tagValue: '',
};
// export const templateObj1: SuggestedCodeFixTemplate = {
//   hasTag: false,
//   hasAttribute: false,
//   hasContent: true,
//   content: "",
//   attributeKey: "",
//   attributeValue: "",
//   tagValue: "",
// };

export const templateObj2: SuggestedCodeFixTemplate = {
  hasTag: false,
  hasAttribute: true,
  hasContent: false,
  hasExtraElement: true,
  removeAttribute: true,
  attributeNameToRemove: 'name',
  extraElementContent: "<label for='element-id'>Form Input Description</label>",
  content: '',
  attributeKey: 'type',
  attributeValue: 'number',
  tagValue: '',
};

export const templateObj3: SuggestedCodeFixTemplate = {
  hasTag: true,
  hasAttribute: true,
  hasContent: false,
  removeAttribute: true,
  attributeNameToRemove: 'scope',
  content: '',
  attributeKey: 'type',
  attributeValue: 'button',
  tagValue: 'button',
};

// export const context1: string = `<a class="logo_link" href="/"><svg width="32" height="32" vie...</a>`;
export const context1: string =
  '<a href="google.com" class="systemLines" onclick="if (!window.__cfRLUnblockHandlers) return false; return false">see all lines</a>';
// export const context1: string = '<meta name="viewport" />';
export const context2: string = `<input name="login_user" type="email" maxlength="96" id="login_user" class="form-control2 required" placeholder="Email@g.com">`;
// export const context3: string = `<div id="loading-overlay" class="loading-overlay" style="display: none;"></div>`;
export const context3: string =
  '<div class="col-lg-7 no-margin" scope="my-scope yeah baby">\n<div class="switchLottery" tit...</div>';

export const dummyAttributeValue: string = 'example-attribute';

export const dummyContentText: string = 'Example content';
export const dummyHeadingContent: string = 'Descriptive heading content';

export const dummyImgSrc: string = 'img_logo.png';

export const dummyImgAlt: string = 'alt description of the image';
export const dummyAppletAltFix: string = 'alt description of the applet';

export const dummyHrefLink: string = 'www.abcd.com';

export const dummyHrefContent: string = 'The english alphabets.';

export const dummyHeaderAttributeValue: string = 'header value';
export const dummyTableSummaryValue: string = 'descriptive table summary';
export const dummyTableCaptionValue: string = 'descriptive table caption';
export const dummyDescriptiveText: string = 'descriptive text';

export const dummyId: string = 'exampleID';
export const dummyForValue: string = 'example-for-value';

export const dummyTitleValue: string = 'example title';
export const dummyTitleValue2: string = 'descriptive title';

export const targetBlankFix: string = '(opens new window)';

export const dummySrForLinkDestination: string =
  "<span class='sr-only'>Link destination</span>";
export const dummyAriaLabel: string = 'descriptive label here';
export const dummyInputValue: string = 'Some value...';
export const dummyGeneralValue: string = '12...';
export const dummyAutoCompleteValue: string = 'given-name';
export const dummyAutoCompletePassword: string = 'given-password';
export const dummyAutoCompleteUrl: string = 'given-url';
export const dummyAutoCompleteTelephone: string = '123-456';
export const dummyAutoCompleteAmount: string = '123456';
export const dummyAutoCompleteMonth: string = 'july 2022';
export const dummyAutoCompleteDate: string = '3rd july 2022';
export const dummyMapNameId: string = 'map1';
export const dummyMapCoords: string = '0,2,45,50';
export const dummyMapHref: string = 'library.html';
export const dummyMapAlt: string = 'library';
export const dummyAppletCode: string = 'chess.class';
export const dummyWidth: string = '250';
export const dummyHeight: string = '250';
export const dummyAppletAlt: string = 'chess game';
export const dummyAppletContent: string = 'chess game';
export const dummyGermanContent: string =
  'Da dachte der Herr daran, ihn aus dem Futter zu schaffen, aber der Esel merkte, daß kein guter Wind wehte, lief fort und machte sich auf den Weg nach Bremen: dort, meinte er, könnte er ja Stadtmusikant werden.';

export const DUMMY_CODE_STRING_1: string = `<div>
  <h3>If the anchor tag is used to skip to a main content, check that the following are true:</h3>
  <ul>
    <li>Check that a link is the first focusable control on the Web page.</li>
    <li>Check that the description of the link communicates that it links to the main content.</li>
    <li>Check that the link is either always visible or visible when it has keyboard focus.</li>
    <li>Check that activating the link moves the focus to the main content.</li>
    <li>Check that after activating the link, the keyboard focus has moved to the main content.</li>
  </ul>
  </div>`;

export const DUMMY_CODE_STRING_2: string = `<div><h1>Go there</h1><p>If the anchor tag is used with an image for descriptive purposes then use the title attribute for anchor tags e.g <strong>title=bouncing ball</strong> this helps with identifying the purpose of the link</p></div>`;

export const recommendations = [
  {
    settingName: 'tabindex',
    settingValue:
      '[{"index": 0, "selector": "center h2"}, {"index": 0, "selector": "table p"}, {"index": 0, "selector": "a[href*=\\"login\\"]"}, {"index": 0, "selector": "center>p"}, {"index": 0, "selector": "a[href*=\\"terms\\"]"}, {"index": 0, "selector": "a[href*=\\"www\\"]"}, {"index": 0, "selector": "input[name=\\"user\\"]"}, {"index": 0, "selector": "input[value=\\"LOGIN\\"]"}, {"index": 0, "selector": "b>p"}, {"index": 0, "selector": "[color=\\"C00000\\"]"}]',
  },
  {
    settingName: 'landmarks',
    settingValue: '{}',
  },
  {
    settingName: 'metadata',
    settingValue: '{}',
  },
  {
    settingName: 'roles',
    settingValue: '{}',
  },
  {
    settingName: 'relations',
    settingValue: '{}',
  },
  {
    settingName: 'dynamic',
    settingValue: '{}',
  },
  {
    settingName: 'submenu',
    settingValue: '{}',
  },
  {
    settingName: 'customattributes',
    settingValue: '{}',
  },
];
