import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { Issue, Scan } from '@equally-ai-front/common/src/types/issues';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { AuditReportView } from './audit-report-view';
import { AuditInputForm } from './audit-input-form';
import { AuditViewHeader } from './audit-view-header';
import DesktopAuditReportViewSkeleton from '../../assets/desktop-audit-report-view-skeleton.svg';
import MobileAuditReportViewSkeleton from '../../assets/mobile-audit-report-view-skeleton.svg';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { AuditComplianceType } from '../accessibility-summary/audit-compliance-info';
// import { AuditReportHeader } from './audit-report-header';
import { ACCESSIBILITY_MAX_SCORE } from '../../utils/constants';
import { ScanTotals } from '@equally-ai-front/common/src/types/scans';
import { calculateAccessibilityScore } from '@equally-ai-front/common/src/lib';
import { AuditReportSummary } from './audit-report-summary';

interface AuditViewProps {
  handleAudit: (
    url: string,
    cb?: (url: string, issues: Issue[]) => void,
  ) => void;
  loading: boolean;
  isWidgetAppeared: boolean;
  report: ReportData;
  scanImageUrl: string;
  currentScan: Scan | null;
  onStartFreeTrialClick: () => void;
  isAdmin?: boolean;
}

export const AuditView = (props: AuditViewProps) => {
  const {
    handleAudit,
    loading,
    report,
    isWidgetAppeared,
    scanImageUrl,
    // currentScan,
    onStartFreeTrialClick,
  } = props;
  const isMD = useMediaQuery('@media screen and (max-width: 1080px)');
  // const [isInputAllowed, setIsInputAllowed] = useState(false);
  const [auditUrl, setAuditUrl] = useState('');
  const [loadingAuditReport, setLoadingAuditReport] = useState(false);
  const [auditComplianceType, setAuditComplianceType] =
    useState<AuditComplianceType>('high-risk');

  const handleAuditUrlChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAuditUrl(evt.target.value);
  };

  const onAudit = async (url: string) => {
    setLoadingAuditReport(true);
    await handleAudit(url);
  };

  const accessibilityScore = useMemo(() => {
    const scanTotals: ScanTotals = {
      totalErrors: report.list.basic.length,
      totalNotices: report.list.potential.length,
      totalWarnings: report.list.advanced.length,
    };

    return calculateAccessibilityScore([scanTotals]) || 0;
  }, [report.list]);

  // const handleShowScanInput = () => {
  //   setIsInputAllowed(false);
  // };

  // useEffect(() => {
  //   if (currentScan?.status === 'SUCCESS') {
  //     setIsInputAllowed(true);
  //   }
  // }, [currentScan]);

  useEffect(() => {
    let auditComplianceTypeValue: AuditComplianceType;
    if (isWidgetAppeared && accessibilityScore < ACCESSIBILITY_MAX_SCORE) {
      auditComplianceTypeValue = 'in-progress';
    } else if (
      isWidgetAppeared &&
      accessibilityScore > ACCESSIBILITY_MAX_SCORE
    ) {
      auditComplianceTypeValue = 'low-risk';
    } else {
      auditComplianceTypeValue = 'high-risk';
    }
    setAuditComplianceType(auditComplianceTypeValue);
  }, [accessibilityScore, isWidgetAppeared]);

  return (
    <>
      <Grid
        container
        spacing={3}
        wrap="nowrap"
        style={{
          width: '100%',
          overflow: 'hidden',
          marginLeft: 0,
        }}
      >
        <Grid
          item
          sm={12}
          md
          style={{ width: '100%', overflow: 'hidden', paddingLeft: 0 }}
        >
          <LayoutMain>
            <AuditViewWrapper
              $auditsuccessful={!!(report.websiteUrl && !loading)}
              $auditcompliancetype={auditComplianceType}
            >
              {/* {isInputAllowed && currentScan && !loadingAuditReport ? (
                <AuditReportHeader
                  currentScan={currentScan}
                  handleShowScanInput={handleShowScanInput}
                />
              ) : (
                <AuditInputForm
                  onAudit={onAudit}
                  label={'Url'}
                  loading={loading}
                  handleAuditUrlChange={handleAuditUrlChange}
                  auditUrl={auditUrl}
                />
              )} */}
              <AuditInputForm
                onAudit={onAudit}
                label={'Url'}
                loading={loading}
                handleAuditUrlChange={handleAuditUrlChange}
                auditUrl={auditUrl}
              />
              <>
                <Box mt={isMD ? '32px' : '20px'} />
                <AuditViewHeader
                  isWidgetAppeared={isWidgetAppeared}
                  report={report}
                  loading={loading}
                  scanImageUrl={scanImageUrl}
                  auditComplianceType={auditComplianceType}
                />
              </>
            </AuditViewWrapper>
            {!loading && report.websiteUrl && (
              <AuditReportSummary
                report={report}
                auditComplianceType={auditComplianceType}
                onStartFreeTrialClick={onStartFreeTrialClick}
              />
            )}

            {loading && (
              <AuditReportViewSkeletonWrapper className="skeleton skeleton-text">
                <AuditReportViewSkeletonImg
                  src={
                    isMD
                      ? MobileAuditReportViewSkeleton
                      : DesktopAuditReportViewSkeleton
                  }
                  alt=""
                />
              </AuditReportViewSkeletonWrapper>
            )}

            {!loading && report && auditComplianceType === 'high-risk' && (
              <AuditReportView
                auditReport={report}
                url={report.websiteUrl || ''}
                loadingAuditReport={loadingAuditReport}
                setLoadingAuditReport={setLoadingAuditReport}
              />
            )}
          </LayoutMain>
        </Grid>
      </Grid>
    </>
  );
};

const LayoutMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuditViewWrapper = styled.div<{
  $auditsuccessful: boolean;
  $auditcompliancetype: AuditComplianceType;
}>`
  background-color: #f5f7fa;
  border-radius: 16px;
  padding: 30px 0 20px 0;
  width: 100%;
  max-width: 912px;
  height: ${(props) =>
    props.$auditcompliancetype !== 'high-risk' || !props.$auditsuccessful
      ? 'fit-content'
      : '342px'};
  position: relative;

  @media screen and (max-width: 1080px) {
    max-width: 100%;
    height: ${(props) =>
      props.$auditcompliancetype !== 'high-risk' || !props.$auditsuccessful
        ? 'fit-content'
        : '590px'};
    padding: 30px 0 30px 0;
  }

  @media screen and (max-width: 805px) {
    height: ${(props) =>
      props.$auditcompliancetype !== 'high-risk' || !props.$auditsuccessful
        ? 'fit-content'
        : '630px'};
  }
`;

const AuditReportViewSkeletonWrapper = styled.div`
  margin-top: 5vh;
  width: 100%;
  max-width: 912px;

  @media screen and (max-width: 1080px) {
    max-width: 100%;
  }
`;

const AuditReportViewSkeletonImg = styled.img`
  width: 100%;
  height: auto;
`;
