import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = (props) =>
  makeStyles(() => ({
    item: {
      marginBottom: '16px',
    },
    heading: {
      color: 'white',
      fontSize: '16px',
      padding: '8px 16px',
      background: props.color,
    },
    text: {
      padding: '8px 16px',
      fontSize: '14px',
      fontWeight: 400,
      color: 'black',
    },
    element: {
      padding: '8px 16px',
      fontSize: '14px',
      wordBreak: 'break-all',
      color: 'black',
    },
  }));

export const ComplianceCard = ({ code, message, context, color }) => {
  const classes = useStyles({ color })();
  return (
    <Paper variant="outlined" square className={classes.item}>
      <Typography
        variant="h4"
        component="p"
        color="primary"
        className={classes.heading}
      >
        {code}
      </Typography>
      <Typography variant="h6" component="p" className={classes.text}>
        {message}
      </Typography>
      <Typography variant="h6" component="p" className={classes.element}>
        {context}
      </Typography>
    </Paper>
  );
};

ComplianceCard.propTypes = {
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  context: PropTypes.string,
};

ComplianceCard.defaultProps = {
  context: '',
};
