import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { FormikHelpers, useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PROMO_URL } from '../../../common/magicValues';
import { GenericInput } from '../../../components/accessibility-components/generic-input';
import { signupFormValidationSchema as validationSchema } from '../../../lib/validators';
import { GoogleAuthButton } from './google-auth-button';
import { PasswordChecks } from './password-checks';
import {
  ActionButton,
  BottomSection,
  Heading,
  ORText,
  PageContent,
  StyledLink,
  TermsCheckbox,
  Wrapper,
} from './styled';
import { SignupFormValues, useAuth } from '../../../providers/auth-context';

const INITIAL_VALUE = {
  email: '',
  password: '',
  phoneNumber: '',
  businessName: '',
  name: '',
  checked: false,
};

export const SignupForm = (): JSX.Element => {
  const [isPasswordFocused, setPasswordFocus] = useState(false);
  const dispatch = useDispatch();
  const { signUpUser } = useAuth();

  const onSubmit = useCallback(
    async (
      values: SignupFormValues,
      { setSubmitting }: FormikHelpers<SignupFormValues>,
    ) => {
      setSubmitting(true);
      try {
        await signUpUser(values);
      } catch (error) {
        setSubmitting(false);
      }
    },
    [dispatch],
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const doPasswordIsFocused = () => {
    setPasswordFocus(true);
  };
  const doPasswordIsNotFocused = () => {
    setPasswordFocus(false);
  };

  const disabled =
    formik.isSubmitting || (formik.submitCount > 0 && !formik.isValid);

  const passwordError = formik.errors.password;

  const showPasswordChecker = !!passwordError && isPasswordFocused;

  return (
    <Wrapper>
      <PageContent>
        <Heading>Create an account</Heading>
        <Box mt="8px" />
        <GoogleAuthButton label="Sign up with Google" />
        <ORText>OR</ORText>

        <form>
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="email"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email ? formik.errors.email : ''}
            style={{ height: '28px', marginBottom: '18px' }}
          />
          <Box mt="34px" />
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="name"
            name="name"
            label="Full Name"
            type="text"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name ? formik.errors.name : ''}
            style={{ height: '28px', marginBottom: '18px' }}
          />
          <Box mt="34px" />
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="businessName"
            name="businessName"
            label="Bussiness Name"
            type="text"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.businessName}
            onChange={formik.handleChange}
            error={formik.touched.businessName && !!formik.errors.businessName}
            helperText={
              formik.touched.businessName ? formik.errors.businessName : ''
            }
            style={{
              height: '28px',
              marginBottom: '18px',
            }}
          />
          <Box mt="34px" />
          <GenericInput
            InputLabelProps={{ shrink: true }}
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number (optional)"
            type="text"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
            helperText={
              formik.touched.phoneNumber ? formik.errors.phoneNumber : ''
            }
            style={{ height: '28px', marginBottom: '10px' }}
          />
          <Box mt="34px" />
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="password"
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password ? formik.errors.password : ''}
            onFocus={doPasswordIsFocused}
            onBlur={doPasswordIsNotFocused}
            style={{ height: '28px', marginBottom: '18px' }}
          />
          {showPasswordChecker && (
            <>
              <Box mt="24px" />
              <PasswordChecks password={formik.values.password} />
            </>
          )}
          <Box mt="16px" />
          <FormControl
            error={formik.touched.checked && Boolean(formik.errors.checked)}
          >
            <FormControlLabel
              label={
                <BottomSection>
                  I’ve read and accept the&nbsp;
                  <StyledLink to={`${PROMO_URL}/terms`} target="_blank">
                    Terms & Conditions
                  </StyledLink>
                </BottomSection>
              }
              control={
                <TermsCheckbox
                  disableRipple
                  name="checked"
                  color="primary"
                  icon={
                    <CheckBoxOutlineBlankIcon
                      fontSize="large"
                      style={{ color: '#E4E7EB' }}
                    />
                  }
                  checkedIcon={<CheckBoxIcon fontSize="large" />}
                  checked={formik.values.checked}
                  onChange={formik.handleChange}
                />
              }
            />
            {formik.touched.checked && formik.errors.checked && (
              <FormHelperText>
                {formik.touched.checked && formik.errors.checked}
              </FormHelperText>
            )}
          </FormControl>
        </form>

        <Box mt="12px" />

        <ActionButton onClick={formik.submitForm} disabled={disabled}>
          Create an account with email
        </ActionButton>
        <Box mt="16px" />
        <BottomSection>
          Already have an account?&nbsp;
          <StyledLink to="../login">Login</StyledLink>
        </BottomSection>
      </PageContent>
    </Wrapper>
  );
};
