import React, { useEffect, useMemo, useState } from 'react';
import { styled as MuiStyled } from '@mui/material/styles';
import { Box, MenuItem } from '@mui/material';
import {
  SelectWrapper,
  StyledSelect,
  TableHeader,
  Title,
  Wrapper,
} from './document-templates-tab';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { generateDomainsComplianceDocument } from '../../utils/documents';
import { Table } from '../table/table';
import { DocumentRow } from '../table/document-row';
import { ContainedButton } from '../../../../components/common/ActionButton';
import { SubmitDocumentPopup } from '../submit-document-popup';
import {
  FilterCondition,
  ItemFilter,
} from '@equally-ai-front/common/src/components/filters/types';
import { complianceDocumentsTableFilterFields } from '../table/consts';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import {
  getComplianceDocuments,
  submitComplianceDocument,
} from '@equally-ai-front/common/src/redux/compliance-documents-slice/compliance-documents';
import { useAppDispatch } from '../../../../hooks/use-app-dispach-selector';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { setApiError, setApiSuccessMessage } from '../../../../store/actions';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import Confetti from 'react-confetti';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useConfetti } from '../../../../hooks/use-confetti';
import { COMPLIANCE_DOCUMENTS_QUERY_KEY } from '../../consts';
import { ComplianceDocument } from '@equally-ai-front/common/src/types/compliance-document';

const DEFAULT_SELECTED_DOMAIN = {
  id: 'all',
  url: 'All Domains',
};

export const DocumentsTab = () => {
  const dispatch = useAppDispatch();
  const analytics = useAnalytics();
  const { domains, currentBusiness } = useSelector((state: RootState) => ({
    domains: state.userProducts.domains,
    currentBusiness: state.business.currentBusiness,
  }));
  const queryClient = useQueryClient();
  const {
    data: complianceDocuments,
    isPending: isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: [COMPLIANCE_DOCUMENTS_QUERY_KEY, currentBusiness?.slug],
    queryFn: () => getComplianceDocuments(currentBusiness!.slug),
    enabled: !!currentBusiness?.slug, // only runs when slug exists
  });

  const [isSubmitDocumentPopupOpen, setIsSubmitDocumentPopupOpen] =
    useState(false);

  const [domain, setDomain] = useState('');
  const {
    isConfettiActive,
    setIsConfettiActive,
    confettiX,
    isConfettiUsed,
    confettiWidth,
  } = useConfetti();

  const allDocuments = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return generateDomainsComplianceDocument({
      domains: domains || [],
      complianceDocs: complianceDocuments || [],
    });
  }, [domains, complianceDocuments, isLoading]);

  const {
    visibleData: visibleDocuments,
    filters,
    removeFilterType,
    addFilter,
  } = useFilter({
    filterOptions: complianceDocumentsTableFilterFields,
    data: allDocuments,
  });

  const handleFilterSelect = (filter: ItemFilter) => {
    const { key, displayName, filterType, value, filterCondition } = filter;
    if (value === 'All Domains') {
      removeFilterType(key);
      return;
    }
    addFilter({
      key,
      displayName,
      value,
      filterType,
      filterCondition,
    });
  };

  const handleOpenSubmitDocumentPopup = () => {
    analytics.track('Submit Document For Approval Clicked', {
      business_id: currentBusiness.id,
    });
    setIsSubmitDocumentPopupOpen(true);
  };

  const handleOpenSubmitPopup = (payload: {
    defaultSelectedDomain: string;
  }) => {
    const { defaultSelectedDomain } = payload;
    setDomain(defaultSelectedDomain);
    handleOpenSubmitDocumentPopup();
  };

  const handleStatusUpdate = (payload: {
    status: string;
    domain: string;
    templateId: number;
    url: string;
  }) => {
    const { status, domain, templateId, url } = payload;
    analytics.track('Compliance Document Updated', {
      business_id: currentBusiness.id,
      status,
      domain,
      url,
      template_id: templateId,
    });
  };

  const { mutateAsync: handleSubmitDocument, isPending: isLoadingNewDocument } =
    useMutation({
      mutationFn: ({
        domain,
        url,
        template_id,
      }: {
        domain: string;
        url: string;
        template_id: number;
      }) =>
        submitComplianceDocument({
          domain,
          url,
          template_id,
          slug: currentBusiness!.slug,
        }),
      onSuccess: (data, variables) => {
        dispatch(setApiSuccessMessage('Document submitted successfully!'));
        analytics.track('New Compliance Document Created', {
          business_id: currentBusiness.id,
          template_id: data.template_id,
          url: variables.url,
          domain: variables.domain,
        });

        if (!isConfettiUsed.current) {
          setIsConfettiActive(true);
          isConfettiUsed.current = true;
          setTimeout(() => {
            setIsConfettiActive(false);
          }, 5000);
        }

        // @ts-ignore
        queryClient.invalidateQueries([
          COMPLIANCE_DOCUMENTS_QUERY_KEY,
          currentBusiness.slug,
        ]);
        setIsSubmitDocumentPopupOpen(false);
        queryClient.setQueryData(
          [COMPLIANCE_DOCUMENTS_QUERY_KEY, currentBusiness.slug],
          (oldDocuments: ComplianceDocument[]) => {
            if (!oldDocuments) return [data];

            const filteredDocuments = oldDocuments.filter(
              ({ id }) => data.id !== id,
            );
            return [data, ...filteredDocuments];
          },
        );
      },
      onError: (error: Error) => {
        dispatch(setApiError(error));
      },
    });

  useEffect(() => {
    if (isError && error) {
      dispatch(setApiError(error));
    }
  }, [isError, error]);

  useEffect(() => {
    analytics.page({
      name: 'Compliance Documents Page',
    });
  }, []);

  return (
    <Wrapper>
      {isConfettiActive && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={100}
          gravity={0.2}
          wind={0}
          recycle={false}
          tweenDuration={200}
          confettiSource={{
            x: confettiX,
            y: 100,
            w: confettiWidth,
            h: 10,
          }}
          drawShape={(ctx) => {
            ctx.fillRect(0, 0, 8, 12);
          }}
        />
      )}
      <TableHeader>
        <TitleAndDomainSelect>
          <Title>Documents</Title>
          <SelectWrapper>
            <StyledSelect
              labelId="domain"
              id="domain"
              value={filters?.domain?.value || 'All Domains'}
              name="domain"
              required
              onChange={(evt) =>
                handleFilterSelect({
                  key: 'domain',
                  displayName: 'Domain',
                  value: evt.target.value,
                  filterType: 'string',
                  filterCondition: FilterCondition.EqualTo,
                })
              }
            >
              {[DEFAULT_SELECTED_DOMAIN, ...(domains || [])].map(
                (domain, idx) => (
                  <MenuItem key={`${domain?.id}-${idx}`} value={domain?.url}>
                    {domain?.url}
                  </MenuItem>
                ),
              )}
            </StyledSelect>
          </SelectWrapper>
        </TitleAndDomainSelect>
        <Box>
          <Button onClick={handleOpenSubmitDocumentPopup}>
            Submit for Approval
          </Button>
        </Box>
      </TableHeader>
      <Table complianceDocuments={visibleDocuments}>
        {visibleDocuments.map((domainComplianceDoc, idx) => (
          <DocumentRow
            key={`${domainComplianceDoc.title}-${idx}`}
            complianceDocument={domainComplianceDoc}
            handleSubmitDocument={handleSubmitDocument}
            handleOpenSubmitPopup={handleOpenSubmitPopup}
            handleStatusUpdate={handleStatusUpdate}
          />
        ))}
      </Table>
      <SubmitDocumentPopup
        isOpen={isSubmitDocumentPopupOpen}
        setIsOpen={setIsSubmitDocumentPopupOpen}
        domain={domain}
        setDomain={setDomain}
        domains={domains || []}
        handleSubmitDocument={handleSubmitDocument}
      />
      <LoadingView
        open={isLoading || isLoadingNewDocument}
        loadingText="Loading..."
        zIndex="1400"
      />
    </Wrapper>
  );
};

const TitleAndDomainSelect = MuiStyled(Box)`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const Button = MuiStyled(ContainedButton)`
    text-transform: none;
    border-radius: 8px;
    height: 45px;
    max-width: 180px;
`;
