import React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';
import { ExportFormProps, FieldProps, autocompleteFields } from '../export';

interface ColumnReportFormProps {
  formik: FormikProps<ExportFormProps>;
}

export const ColumnReportForm = (props: ColumnReportFormProps) => {
  const { formik } = props;

  const capitalizeKey = (key: string) => {
    if (!key) {
      return key;
    }
    return key.charAt(0).toUpperCase() + key.slice(1);
  };

  return (
    <div>
      {autocompleteFields.map((field: FieldProps) => (
        <ContainerWrapper key={field.key + '-container'}>
          <SubHeadline key={field.key + '-headline'}>{field.label}</SubHeadline>
          <Autocomplete
            multiple
            id={field.key}
            key={field.key}
            options={field.options as FieldProps[]}
            getOptionLabel={(option) => option.label}
            disableCloseOnSelect
            value={formik.values[field.key].map((value: string) => {
              const options = (field.options as FieldProps[]) || [];
              return options.find((opt: FieldProps) => opt.key === value);
            })}
            onChange={(event, newValue) => {
              const selectedKeys = newValue.map(
                (option: FieldProps) => option.key,
              );
              formik.setFieldValue(field.key, selectedKeys);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder={capitalizeKey(field.key)} />
            )}
            sx={{
              marginBottom: '18px',
              '& .MuiChip-label': {
                paddingLeft: '10px',
                paddingRight: '10px',
              },
              '& .MuiAutocomplete-tag': {
                margin: '1px 1px',
                fontSize: '12px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
          />
        </ContainerWrapper>
      ))}
    </div>
  );
};

const ContainerWrapper = styled.div``;

const SubHeadline = styled(Typography)`
  font-size: 12px;
  display: flex;
  flex-flow: row nowrap;
  line-height: 13px;
  font-weight: 500;
  align-items: center;
  opacity: 0.7;
  margin-bottom: 5px;
`;
