import {
  // COMPLIANCE_DOCUMENT_TEMPLATES,
  templateToDocumentInfoMap,
} from '@equally-ai-front/common/src/redux/compliance-documents-slice/compliance-documents';
import { ComplianceDocument } from '@equally-ai-front/common/src/types/compliance-document';

export const generateDomainsComplianceDocument = (payload: {
  domains: any[];
  complianceDocs: ComplianceDocument[];
}): ComplianceDocument[] => {
  const { /*  domains, */ complianceDocs } = payload;
  const complianceDocuments: ComplianceDocument[] = [];
  // const accessibilityStatementDocument = COMPLIANCE_DOCUMENT_TEMPLATES.find(
  //   (doc) => doc.template_id === 4,
  // ) as ComplianceDocument;

  for (const complianceDoc of complianceDocs) {
    const complianceDocument = {
      ...complianceDoc,
      ...templateToDocumentInfoMap[complianceDoc.template_id],
      link: complianceDoc?.data?.url || '',
    };
    complianceDocuments.push(complianceDocument);
  }

  // for (const domain of domains) {
  //   const domainComplianceDocument: ComplianceDocument = {
  //     ...accessibilityStatementDocument,
  //     id: domain?.id,
  //     business_id: domain?.business_id,
  //     domain: domain?.url,
  //     status: 'default',
  //   };
  //   complianceDocuments.push(domainComplianceDocument);
  // }

  return complianceDocuments.sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  );
};
