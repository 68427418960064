import React from 'react';
import styled from 'styled-components';
import { DevtoolsSolution } from '../../utils/devToolsTypeConfig';
import { GptSolutions } from './GptSolutions';
import {
  FeedbackSuggestionApiPayload,
  GptApiPayload,
  GptResponse,
  GptType,
} from '../../types/chat-gpt';

export interface CodeInfo {
  selector: string;
  principle: string;
  context: string;
}

interface SavedGptSuggestionsProps {
  suggestions: GptResponse[];
  suggestionsTitle: string;
  devToolsSolution: DevtoolsSolution | null;
  loadingGpt: boolean;
  gptType: GptType;
  getGptSuggestion: (gptType: GptType, gptDetails: GptApiPayload) => void;
  isExtension?: boolean;
  codeInfo?: CodeInfo;
  savePrompt: (prompt: string, gptType: GptType) => void;
  usedPrompt: string;
  sendFeedback: (feedback: FeedbackSuggestionApiPayload) => void;
}

export const SavedGptSuggestions = ({
  suggestions,
  suggestionsTitle,
  devToolsSolution,
  gptType,
  getGptSuggestion,
  loadingGpt,
  isExtension,
  codeInfo,
  savePrompt,
  usedPrompt,
  sendFeedback,
}: SavedGptSuggestionsProps) => {
  return (
    <Wrapper
      hasSuggestions={Object.values(suggestions).length > 0}
      isExtension={Boolean(isExtension)}
    >
      {suggestions.length > 0 && (
        <SectionDiv isExtension={isExtension}>
          <TitleHeader isExtension={isExtension}>
            {suggestionsTitle}
          </TitleHeader>
          <SectionDivInner>
            {suggestions.map((result, idx) => (
              <GptSolutions
                key={idx}
                gptType={result.gptType}
                gptFix={result.suggestion}
                devToolsSolution={devToolsSolution}
                gptLoading={loadingGpt}
                getGptSuggestion={getGptSuggestion}
                isExtension={isExtension}
                codeInfo={codeInfo}
                savePrompt={savePrompt}
                usedPrompt={usedPrompt}
                sendFeedback={sendFeedback}
              />
            ))}
          </SectionDivInner>
        </SectionDiv>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ hasSuggestions: boolean; isExtension: boolean }>`
  padding: ${(p) =>
    p.isExtension ? '' : p.hasSuggestions ? '16px 32px 0' : ''};

  ${(p) =>
    p.hasSuggestions && !p.isExtension
      ? `
  &:after {
    content: '';
    border-bottom: 1.5px solid #e4e7eb;
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
  }
  `
      : ''}
`;

const SectionDiv = styled.div<{ isExtension?: boolean }>`
  margin-bottom: ${(p) => (p.isExtension ? '' : '6%')};
  padding: ${(p) => (p.isExtension ? '0' : '10px 0')};
`;

const SectionDivInner = styled.div`
  overflow: auto;

  /* width */

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }
`;

const TitleHeader = styled.h5<{ isExtension?: boolean }>`
  font-weight: 500;
  font-size: ${(p) => (p.isExtension ? '15px' : '14px')};
  color: rgba(0, 10, 20, 0.85);
  margin-bottom: 2%;

  @media screen and (max-width: 650px) {
    margin-bottom: 2%;
  }
`;
