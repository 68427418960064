import { useEffect, useState } from 'react';
import { CelloService } from '../api/cello';
import { CELLO_PRODUCT_ID } from '../common/magicValues';

const useCello = ({
  user,
  businessId,
}: {
  user: Record<string, any>;
  businessId: number;
}) => {
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!user || !businessId) {
      return;
    }
    void getToken();
  }, [user, businessId]);
  const getToken = async () => {
    if (!user || !businessId) {
      return;
    }

    const { data: token, isSuccess } = await CelloService.getToken({
      businessId,
    });
    if (!isSuccess) {
      return;
    }
    setToken(token as string);
  };

  const getUCC = async (): Promise<string | undefined> => {
    (window as any).CelloAttribution =
      (window as any).CelloAttribution ||
      function (t: string, ...o) {
        if ('getReferral' === t)
          throw new Error(
            'getReferral is not supported in this context. Use getUcc instead.',
          );
        let e, n;
        const i = new Promise((t, o) => {
          (e = t), (n = o);
        });
        return (
          ((window as any).CelloAttributionCmd =
            (window as any).CelloAttributionCmd || []),
          (window as any).CelloAttributionCmd.push({
            command: t,
            args: o,
            resolve: e,
            reject: n,
          }),
          i
        );
      };

    return await (window as any).CelloAttribution('getUcc');
  };

  const signUp = async ({ slug }: { slug: string }) => {
    if (!slug) {
      return;
    }

    const celloUcc = await getUCC();

    if (!celloUcc) {
      return;
    }
    void CelloService.signUp({
      slug,
      celloUcc,
    }).catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    if (!user || !token) {
      return;
    }
    init();
  }, [token, user]);

  const init = () => {
    const { name, email } = user;
    const [firstName, lastName] = name.split(' ');

    window.cello = window.cello || { cmd: [] };
    window.cello.cmd.push((cello: any) =>
      cello.boot({
        productId: CELLO_PRODUCT_ID,
        token: token,
        productUserDetails: {
          email,
          firstName,
          lastName: lastName || '',
          fullName: name,
        },
      }),
    );
  };

  return { getToken, signUp };
};

export default useCello;
