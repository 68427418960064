import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { formatUTCDate } from '@equally-ai-front/common/src/helpers/utils';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { IssueSeverityEnumLookup } from '@equally-ai-front/common/src/lib/issues';
import {
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/styles';
import React, { /* MouseEvent, */ useState } from 'react';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
// import ActionsButton from '../../components/actions-button';
import {
  CodeBlock,
  NoCodeChip,
  TableText,
} from '../../components/css-components';
import { TableActionPopover } from '../../components/table-action-popover';
import { MOBILE_PX, TABLE_PAGE_LIMIT } from '../../utils/constants';

export const IssuesStack = () => {
  const { allIssues } = useAppSelector((state) => state.issues);
  const { pageCount, currentData, handlePageChange } = usePagination(
    [...allIssues].sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    ),
    TABLE_PAGE_LIMIT,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // const handleOpenActionPopover = (event: MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleCloseActionPopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        marginY={2}
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={2}
      >
        {currentData.length > 0 ? (
          currentData.map((row) => (
            <TableContainer component={Paper} key={row.id}>
              <Table aria-label="issues table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="left">
                      URL
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.domain_url}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="left">
                      Source
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.source}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="left">
                      Selector
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.selector}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="left">
                      Priority
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {IssueSeverityEnumLookup[row.type]}
                    </StyledTableCell>
                  </StyledTableRow>
                  {/* <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="left">
                      Snippet
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{ display: 'flex' }}>
                      {row.context ? (
                        <CodeBlock>{row.context}</CodeBlock>
                      ) : (
                        <NoCodeChip style={{ textAlign: 'right' }}>
                          N/A
                        </NoCodeChip>
                      )}
                    </StyledTableCell>
                  </StyledTableRow> */}
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="left">
                      Description
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TableText>{row.message}</TableText>
                    </StyledTableCell>
                  </StyledTableRow>
                  <TableRow>
                    <StyledTableCell align="left" colSpan={2}>
                      {formatUTCDate(row.updated_at)}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">
                      <ActionsButton handleClick={handleOpenActionPopover} />
                    </StyledTableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ))
        ) : (
          <TableContainer>
            <StyledTableRow>
              <StyledTableCell width={MOBILE_PX} align="center">
                No Data Available
              </StyledTableCell>
            </StyledTableRow>
          </TableContainer>
        )}
        <TableActionPopover
          anchorEl={anchorEl}
          handleClose={handleCloseActionPopover}
        />
      </Stack>
      <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
    </>
  );
};

// TODO: Use true to design colors and sizes from theme
const StyledTableCell = styled(TableCell)({
  '&.MuiTableCell-head': {
    backgroundColor: '#ffffff',
    color: '#000a14',
  },

  '&.MuiTableCell-body': {
    fontsize: '14px',
  },
});

const StyledTableRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
});

export default IssuesStack;
