import React, { useRef } from 'react';
import { Popover, Box } from '@mui/material';
import { DynamicMenuItemProps } from '../../expandables/menus/types';
import { AccessibilityMenuItem } from './accessibility-menu-item';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface AccessibilityMenuProps {
  anchorEl: HTMLElement | null;
  container?: Element | (() => Element | null) | null;
  handleClose: () => void;
  menuItems: DynamicMenuItemProps[];
  selector?: string;
  children?: React.ReactNode;
  menuTopPosition?: string;
}

export const AccessibilityMenu = (props: AccessibilityMenuProps) => {
  const {
    anchorEl,
    container,
    handleClose,
    menuItems,
    selector,
    children,
    menuTopPosition,
  } = props;
  const menuItemsRefs = useRef<Array<HTMLLIElement | null>>([]);
  let isLastItem = false;
  const lastIndex = menuItemsRefs.current.length - 1;
  const navigate = useNavigate();

  const handleKeyUp = (
    event: React.KeyboardEvent<HTMLElement>,
    index: number,
  ) => {
    const { key, shiftKey } = event;

    if (key === 'Escape') {
      handleClose();
      return;
    }

    if (key !== 'Tab') {
      return;
    }

    if (menuItemsRefs.current.length === 0) {
      return;
    }

    if (!menuItemsRefs.current[index]) {
      return;
    }

    if (!shiftKey && key === 'Tab' && index === lastIndex) {
      isLastItem = true;
    }

    if (shiftKey && key === 'Tab' && isLastItem) {
      isLastItem = false;
    }

    if (!shiftKey && key === 'Tab' && index !== lastIndex && isLastItem) {
      handleClose();
      return;
    }

    if ((shiftKey && index < 0) || (!shiftKey && index < 0)) {
      return;
    }

    menuItemsRefs.current[index]?.focus();
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    handleClose();
  };

  const handleMenuItemClick = (
    action: (selector: string) => void,
    linkTo?: string,
  ) => {
    return () => {
      if (linkTo) {
        navigate(linkTo);
      }
      action(selector || '');
      handleClose();
    };
  };

  const menuItemGroupWithSection = menuItems.filter(
    (menuData) =>
      menuData && menuData.section !== undefined && menuData.section !== null,
  );
  const menuItemsWithoutSection = menuItems.filter(
    (menuData) =>
      !menuData || menuData.section === undefined || menuData.section === null,
  );

  return (
    <StyledPopover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      container={container}
      sx={{ zIndex: 30000 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disablePortal
      onMouseLeave={handleMouseLeave}
      onClose={handleClose}
      aria-expanded={Boolean(anchorEl)}
      role="region"
      id="menu"
      top={menuTopPosition}
    >
      {children}
      {menuItemGroupWithSection.length > 0 && (
        <Box
          sx={
            menuItemGroupWithSection[0].style &&
            menuItemGroupWithSection[0].style
          }
        >
          {menuItemGroupWithSection.map(
            (menuData: DynamicMenuItemProps, index: number) => (
              <AccessibilityMenuItem
                forwardedRef={(el) => (menuItemsRefs.current[index] = el)}
                key={`menu-item-${menuData.name}-${index}`}
                handleKeyUp={(event: React.KeyboardEvent<HTMLElement>) =>
                  handleKeyUp(event, index)
                }
                handleClick={handleMenuItemClick(
                  menuData.handleClicked,
                  menuData.linkTo,
                )}
                isActive={menuData.isActive}
                {...menuData}
              />
            ),
          )}
        </Box>
      )}
      {menuItemsWithoutSection.length > 0 &&
        menuItemsWithoutSection.map(
          (menuData: DynamicMenuItemProps, index: number) => {
            const refIndex = index + menuItemGroupWithSection.length;
            return (
              <AccessibilityMenuItem
                forwardedRef={(el) => (menuItemsRefs.current[refIndex] = el)}
                key={`menu-item-${menuData.name}-${refIndex}`}
                handleKeyUp={(event: React.KeyboardEvent<HTMLElement>) =>
                  handleKeyUp(event, refIndex)
                }
                handleClick={handleMenuItemClick(
                  menuData.handleClicked,
                  menuData.linkTo,
                )}
                {...menuData}
              />
            );
          },
        )}
    </StyledPopover>
  );
};

interface StyleProps {
  top?: string;
}

const StyledPopover = styled(Popover)<StyleProps>`
  & .MuiPaper-root {
    ${(props) =>
      props.top &&
      css`
        top: ${props.top} !important;
      `}
  }
`;
