import { Box, Button, Drawer, List, ListItem } from '@mui/material';
import { CloseOutlined, MenuRounded } from '@mui/icons-material';
import Proptypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { currentBusinessUpdated } from '@equally-ai-front/common/src/redux/business-slice/business';
import { Divider } from '@mui/material';
import styled from 'styled-components';
import { navigateToLoginURL } from '../../common/navigate';
import Logo from '../../img/light-logo.png';
import { isUserPermitted } from '../../lib/permissions';
import { useAuth } from '../../providers/auth-context';
import { ComplianceManagerMenu } from '../compliance-manager/components/compliance-manager-menu';
import { getHasActiveDevDomains } from '../../utils/domain.utils';

export const MobileNavigation = ({ isAuthenticated }) => {
  const personalDetails = useSelector(
    (state) => state.personalDetails.personalDetails,
  );
  const domains = useSelector((state) => state.userProducts.domains);

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );
  const businesses = useSelector((state) => state.business.businesses);
  // const calendlyCall = (e) => {
  //   e.preventDefault();
  //   window.showCalendly();
  //   return false;
  // };
  const { logout } = useAuth();
  const hasActiveDevDomains = getHasActiveDevDomains(domains);

  const handleBusinessClick = (business) => {
    dispatch(currentBusinessUpdated(business));
    handleClose();
  };

  const businessSection = (businesses || []).map((business, index) => (
    <Link
      key={`${business?.slug}-${index}`}
      onClick={() => handleBusinessClick(business)}
      to={`../${currentBusiness?.slug}`}
    >
      {business.name}
    </Link>
  ));

  if (!isAuthenticated) {
    return (
      <Header>
        <HeaderLogo src="/logo.svg" alt="Equally AI logo" />
      </Header>
    );
  }

  return (
    <>
      <Header>
        <HeaderLogo src="/logo.svg" alt="Equally AI logo" />

        <StyledButton
          onClick={() => setOpen(!open)}
          aria-label={`${
            open ? 'Close Navigation Menu' : 'Open Navigation Menu'
          }`}
        >
          {!open ? <MenuRounded /> : <CloseOutlined />}
        </StyledButton>
      </Header>
      <StyledDrawer anchor="top" open={open} onClose={handleClose}>
        <StyledList>
          <ListItem>
            <MenuLink to="/">My Websites</MenuLink>
          </ListItem>
          <ListItem>
            {isUserPermitted(
              currentBusiness?.id,
              ['view.compliance_page'],
              personalDetails,
            ) &&
              hasActiveDevDomains && (
                <MenuLink
                  to={`/${currentBusiness?.slug}/compliance-manager/overview`}
                >
                  Flowy
                </MenuLink>
              )}
          </ListItem>
          <ListItem>
            <MenuLink to={`/${currentBusiness?.slug}/audit`}>Audit</MenuLink>
          </ListItem>
          {/* <ListItem button>
            {isUserPermitted(
              currentBusiness?.id,
              ['view.compliance_page'],
              personalDetails,
            ) && (
              <>
                <ComplianceManagerMenu handleCloseMenu={handleClose} />
                <Box mb={7} />
              </>
            )}
          </ListItem> */}
          <Line />
          {businesses && (
            <>
              <StyledListItem>
                <span>Business</span>
              </StyledListItem>
              <ListItem>
                <BusinessList>{businessSection}</BusinessList>
              </ListItem>
              <Line />
            </>
          )}
          <ListItem>
            <Link
              to="/settings"
              className="mobile-header-navigation-popover-item"
              onClick={handleClose}
            >
              Settings
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={`/${currentBusiness?.slug}/settings`}
              className={`mobile-header-navigation-popover-item ${
                isUserPermitted(
                  currentBusiness?.id,
                  ['view.business_settings'],
                  personalDetails,
                )
                  ? ''
                  : 'd-none'
              }`}
              onClick={handleClose}
            >
              Business Settings
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={`/${currentBusiness?.slug}/business-settings/plans/#pricing`}
              className={`mobile-header-navigation-popover-item ${
                isUserPermitted(
                  currentBusiness?.id,
                  ['view.business_settings'],
                  personalDetails,
                )
                  ? ''
                  : 'd-none'
              }`}
              onClick={handleClose}
            >
              Billing and Payments
            </Link>
          </ListItem>
          <ListItem>
            <Link
              onClick={handleClose}
              to={process.env.REACT_APP_HTTP_SUPPORT_ENDPOINT}
              target="_blank"
              className="mobile-header-navigation-popover-item"
            >
              Support
            </Link>
          </ListItem>
          <ListItem>
            <button
              type="button"
              onClick={logout}
              className="mobile-header-navigation-popover-item mobile-header-navigation-popover-button"
            >
              Logout
            </button>
          </ListItem>
        </StyledList>
      </StyledDrawer>
    </>
  );
};

MobileNavigation.propTypes = {
  isAuthenticated: Proptypes.bool.isRequired,
};

const Header = styled.div`
  box-sizing: border-box;
  background: #000a14;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`;

const HeaderLogo = styled.img`
  height: 24px;
`;

const HeaderLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  text-transform: none;
  padding: 6px 16px;
  margin-right: auto;
`;

const StyledButton = styled(Button)`
  border-radius: 3px;
  border: 1px solid #fff;
  height: 34px;
  min-width: 34px;
  color: #fff;
`;

const Line = styled(Divider)`
  border-color: rgba(255, 255, 255, 0.4) !important;
  width: 95%;
  margin: 0 auto !important;
`;

const StyledDrawer = styled(Drawer)`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(80px);
  /* border-top: 1px solid #eee; */
  z-index: 4000000 !important;

  & .MuiDrawer-paper {
    width: 100%;
    height: 60%;
    max-height: 80%;
    background: #000a14;
    border-radius: 0;
    margin: 0 auto;
  }
`;

const MenuLink = styled(HeaderLink)`
  padding: 0;
`;

const StyledList = styled(List)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  background-color: #000a14;
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;

  & > span {
    font-size: 12px;
    color: #eee;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

const BusinessList = styled.ul`
  flex-direction: column;
  display: flex;
  justify-content: initial;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
  background-color: #000a14;
  width: 100%;
  padding: 0;

  & > a {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
    margin-bottom: 10px;
  }
`;
