import { TabContent } from '@equally-ai-front/common/src/components/accessibility-issue-tab-container/AccessibilityIssueTabContainer';
// import {
//   StyledTab,
//   StyledTabs,
// } from '@equally-ai-front/common/src/components/accessibility-issue-tab-container/Tabs';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { styled as muiStyled } from '@mui/material/styles';
import React, {
  Fragment,
  MouseEvent,
  // SyntheticEvent,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { ReactComponent as DownArrow } from '../../assets/svg/chevron-bottom.svg';
import { GroupList } from './group-list';
import { IWebsiteInfo, WebsiteList } from './website-list';

interface WebsiteGroupProps {
  selectedWebsites: IWebsiteInfo[];
  setSelectedWebsites: React.Dispatch<React.SetStateAction<IWebsiteInfo[]>>;
}

export const WebsiteGroup = (props: WebsiteGroupProps) => {
  const { selectedWebsites, setSelectedWebsites } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [tabValue /* , setTabValue */] = useState<number>(0);
  const open = Boolean(anchorEl);
  const id = open ? 'website-group-popover' : undefined;

  // const handleTabChange = (evt: SyntheticEvent, newValue: number) => {
  //   setTabValue(newValue);
  // };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabsContent: TabContent[] = useMemo(() => {
    return [
      {
        tabNumber: 0,
        element: (
          <WebsiteList
            handleClose={handleClose}
            selectedWebsites={selectedWebsites}
            setSelectedWebsites={setSelectedWebsites}
          />
        ),
      },
      {
        tabNumber: 1,
        element: <GroupList />,
      },
    ];
  }, [selectedWebsites]);

  return (
    <Wrapper>
      <WebsiteGroupBtn
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <span>Select Website</span>
        <DownArrow />
      </WebsiteGroupBtn>
      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverWrapper>
          {/* <StyledTabs value={tabValue}>
            <StyledTab
              label={
                <StyledTabLabelWrapper
                  onClick={(evt: SyntheticEvent) => handleTabChange(evt, 0)}
                >
                  Websites
                </StyledTabLabelWrapper>
              }
              width="50%"
            />
            <StyledTab
              label={
                <StyledTabLabelWrapper
                  onClick={(evt: SyntheticEvent) => handleTabChange(evt, 1)}
                >
                  Groups
                </StyledTabLabelWrapper>
              }
              width="50%"
            />
          </StyledTabs> */}
          <MainContent>
            <TabPanel>
              {tabsContent.map(
                ({ tabNumber, element }) =>
                  tabNumber === tabValue && (
                    <Fragment key={tabNumber}>{element}</Fragment>
                  ),
              )}
            </TabPanel>
          </MainContent>
        </PopoverWrapper>
      </CustomPopover>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: fit-content;
`;

const WebsiteGroupBtn = muiStyled(Button)`
    background-color: #f5f7fa;
    color: #000A14;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    border: 1px solid #CBD2D9;
    border-radius: 4px;
    text-transform: capitalize;
    height: 36px;
    box-shadow: none;

    &:hover {
        background-color: rgba(255, 255, 255, 0.12);
    }

    span {
        display: inline-block;
        margin-right: 10px;
    }
`;

// const StyledTabLabelWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   padding-bottom: 4px;
//   text-transform: capitalize;
// `;

const CustomPopover = muiStyled(Popover)`
  .MuiPopover-paper {
    max-height: 350px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #ffffff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dee2e6; /* 121212 */
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background-color: #b0acae;
    }
  }
`;

const PopoverWrapper = styled.div`
  background-color: #ffffff;
  box-shadow:
    0px 0px 1px rgba(10, 31, 68, 0.08),
    0px 3px 4px rgba(10, 31, 68, 0.1);
  border-radius: 4px;
  padding: 10px;

  > div:first-of-type {
    min-height: fit-content;
  }

  > div > div > .MuiTabs-flexContainer {
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  button {
    min-height: fit-content;
  }
`;

const MainContent = styled.div`
  width: 100%;
`;

const TabPanel = styled.div`
  width: 100%;
  font-size: 0.875rem;
`;
