import React from 'react';
import { ReactComponent as ActionsIcon } from '../../img/icons/actions.svg';
import { ReactComponent as OverviewIcon } from '../../img/icons/overview-icon.svg';
import { ReactComponent as IssuesIcon } from '../../img/icons/issues.svg';
import { ReactComponent as SettingsAccessibilityIcon } from '../../img/icons/settings_accessibility.svg';
import { ReactComponent as BusinessSettingsIcon } from '@equally-ai-front/common/src/assets/svg/business-settings-200.svg';
import { ReactComponent as BillPaymentIcon } from '../../img/card.svg';
import { ReactComponent as SettingsIcon } from '../../img/icons/settings-icon.svg';
import { Business } from '@equally-ai-front/common/src/types/business';
import { isUserPermitted } from '../../lib/permissions';
import { PersonalDetails } from '@equally-ai-front/common/dist/src/redux/slice/types';
import { ReactComponent as WebsitesIcon } from '../../img/icons/websites-icon.svg';
import { ReactComponent as InstallationIcon } from '../../img/icons/code-icon.svg';
import { ReactComponent as FlowyIcon } from '../../img/icons/flowy-icon.svg';
import { ReactComponent as AuditIcon } from '../../img/icons/audit-icon.svg';

interface NavBarLinkItem {
  text: string;
  linkTo: string;
  icon?: React.ReactNode;
}
interface NavBarLink extends NavBarLinkItem {
  icon: React.ReactNode;
  disableMainRoute?: boolean;
  subRoutes?: NavBarLinkItem[];
}

const getFlowyLinks = (business: Business, devDomainId: string) => [
  {
    text: 'Overview',
    linkTo: `/${business.slug}/developers/${devDomainId}/flowy/overview`,
    icon: <OverviewIcon />,
  },
  {
    text: 'Scans',
    linkTo: `/${business.slug}/developers/${devDomainId}/flowy/scans`,
    icon: <IssuesIcon />,
  },
  {
    text: 'Actions',
    linkTo: `/${business.slug}/developers/${devDomainId}/flowy/actions`,
    icon: <ActionsIcon />,
  },
  {
    text: 'Settings',
    linkTo: `/${business.slug}/developers/${devDomainId}/flowy/settings`,
    icon: <SettingsAccessibilityIcon />,
  },
  {
    icon: <FlowyIcon />,
    text: 'Compliance',
    linkTo: `/${business.slug}/compliance-manager/overview`,
  },
];

const getComplianceManagerLinks = (business: Business) => [
  {
    text: 'Overview',
    linkTo: `/${business.slug}/compliance-manager/overview`,
    icon: <OverviewIcon />,
  },
  {
    text: 'Issues',
    linkTo: `/${business.slug}/compliance-manager/issues`,
    icon: <IssuesIcon />,
  },
  {
    text: 'Settings',
    linkTo: `/${business.slug}/compliance-manager/settings/schedule-scans`,
    icon: <SettingsAccessibilityIcon />,
  },
];

const getAppLinks = (
  personalDetails: PersonalDetails,
  hasActiveDevDomains: boolean,
  business?: Business,
): NavBarLink[] => {
  const getBusinessLinks = (business: Business) => {
    return [
      {
        icon: <WebsitesIcon />,
        text: 'Websites',
        linkTo: `/${business.slug}`,
      },
      {
        icon: <InstallationIcon />,
        text: 'Installation',
        linkTo: `/${business.slug}/installation`,
      },
      ...(isUserPermitted(
        business.id,
        ['view.compliance_page'],
        personalDetails,
      ) && hasActiveDevDomains
        ? [
            {
              icon: <FlowyIcon />,
              text: 'Compliance',
              linkTo: `/${business.slug}/compliance-manager/overview`,
            },
          ]
        : []),
      {
        icon: <AuditIcon />,
        text: 'Audit',
        linkTo: `/${business.slug}/audit`,
      },
      {
        icon: <BusinessSettingsIcon />,
        text: 'Business Settings',
        linkTo: `/${business.slug}/business-settings`,
      },
      {
        icon: <BillPaymentIcon />,
        text: 'Billing & Payments',
        linkTo: `/${business.slug}/business-settings/plans?show_plan=${encodeURIComponent('true')}`,
      },
    ];
  };
  return [
    ...(!business ? [] : getBusinessLinks(business)),
    {
      icon: <SettingsIcon />,
      text: 'Settings',
      linkTo: '#',
      disableMainRoute: true,
      subRoutes: [
        {
          text: 'Personal Details',
          linkTo: `/${business?.slug}/settings`,
        },
        {
          text: 'Create new business',
          linkTo: '/on-boarding',
        },
        {
          text: 'Password Reset',
          linkTo: `/${business?.slug}/settings/password-reset`,
        },
      ],
    },
  ];
};

export {
  getFlowyLinks,
  getComplianceManagerLinks,
  getAppLinks,
  NavBarLinkItem,
  NavBarLink,
};
