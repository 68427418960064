import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import authSaga from './saga';
import { AuthState } from './types';

export const initialState: AuthState = {
  user: {},
  websiteDomain: '',
  personalDetails: {
    email: '',
    name: '',
    newAccount: false,
    specialDomain: false,
    testAccount: false,
  },
  loading: false,
  error: '',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },

    setWebsiteDomain(state, action: PayloadAction<string>) {
      state.websiteDomain = action.payload;
      localStorage.setItem('websiteDomain', action.payload);
    },

    resetUser(state) {
      state.user = {};
    },

    getPersonalDetails(state, action: PayloadAction<string>) {
      state.loading = true;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    setPersonalDetails(state, action: PayloadAction<any>) {
      state.personalDetails = action.payload;
    },
  },
});

export const { actions: authActions } = slice;
export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};
