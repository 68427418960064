import { Slide, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setApiError, setApiSuccessMessage } from '../store/actions';

export const Notifications = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.navigation.error);
  const successMessage = useSelector(
    (state) => state.navigation.successMessage,
  );
  const autoHide = useSelector((state) => state.navigation.autoHide);
  const errorVisible = errorMessage !== '';
  const successMessageVisible = successMessage !== '';

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setApiError(''));
  };

  const handleCloseSuccessMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setApiSuccessMessage(''));
  };

  // const handleChange = (event, newValue) => {
  //   dispatch(changeTab(newValue));
  // };

  return (
    <div>
      <Snackbar
        open={errorVisible}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
        onClose={handleCloseError}
      >
        <ErrorAlert
          onClose={handleCloseError}
          variant="filled"
          severity="error"
        >
          {errorMessage}
        </ErrorAlert>
      </Snackbar>
      <Snackbar
        open={successMessageVisible}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={autoHide ? 6000 : null}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
        onClose={handleCloseSuccessMessage}
      >
        <StyledAlert
          onClose={handleCloseSuccessMessage}
          variant="filled"
          severity="success"
        >
          {successMessage}
        </StyledAlert>
      </Snackbar>
    </div>
  );
};

const StyledAlert = styled(MuiAlert)`
  padding: 6px 16px;
  min-width: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #eefbf3;
  border: 2px solid #c2f4d7;
  color: #333;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  transform: translateX(0);
  opacity: 1;
  border-radius: 8px;
  margin-bottom: 20px;
  font-weight: 300;
`;

const ErrorAlert = styled(StyledAlert)`
  background: #fcedec;
  border-color: #c8abab;
`;
