import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { getAssetReactAppUrl } from '../../utils';

interface ButtonWithIconProps {
  iconSrc: string;
  iconAltText: string;
  isClicked?: boolean;
  backgroundColor: string;
  borderStyle: string;
  buttonText?: string;
  borderRadius?: string;
  height?: string;
  flexDirection?: string;
  justifyContent?: string;
  iconWidth?: string;
  hoverColor?: string;
  onClickAction: any;
  isDisabled?: boolean;
  showRightBorder?: boolean;
  isGptBtn?: boolean;
  showLoadingSpinner?: boolean;
  padding?: string;
  style?: any;
  invertIconOnHover?: boolean;
  invertIcon?: boolean;
  textHover?: string;
  id?: string;
  [key: string]: string | boolean | any | undefined;
}

export const ButtonWithIcon = React.forwardRef<
  HTMLButtonElement,
  ButtonWithIconProps
>((props, ref) => {
  const {
    buttonText,
    iconSrc,
    iconAltText,
    backgroundColor,
    borderStyle,
    flexDirection,
    justifyContent,
    isClicked,
    iconWidth,
    onClickAction,
    isDisabled,
    borderRadius,
    height,
    showRightBorder,
    hoverColor,
    isGptBtn,
    showLoadingSpinner,
    padding,
    style,
    invertIconOnHover,
    invertIcon,
    textHover,
    id,
    ...otherProps
  } = props;

  return (
    <Button
      id={id}
      disabled={isDisabled}
      bgColor={backgroundColor}
      borderStyle={borderStyle}
      onClick={onClickAction}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      borderRadius={borderRadius}
      height={height}
      isClicked={isClicked}
      showRightBorder={showRightBorder}
      hoverColor={hoverColor}
      isGptBtn={isGptBtn}
      padding={padding}
      style={style}
      ref={ref}
      invertIconOnHover={invertIconOnHover}
      invertIcon={invertIcon}
      textHoverColor={textHover}
      {...otherProps}
    >
      {showLoadingSpinner ? (
        <CircularProgress size={15} style={{ color: 'rgb(30, 86, 67)' }} />
      ) : (
        <>
          <ButtonIcon
            src={getAssetReactAppUrl(iconSrc)}
            flexDirection={flexDirection}
            iconWidth={iconWidth}
            alt={iconAltText}
            iconSrc={iconSrc}
          />
          {buttonText && (
            <ButtonText flexDirection={flexDirection} bgColor={backgroundColor}>
              {buttonText}
            </ButtonText>
          )}
        </>
      )}
    </Button>
  );
});

const Button = styled.button<{
  bgColor: string;
  borderStyle: string;
  isClicked?: boolean;
  flexDirection?: string;
  justifyContent?: string;
  borderRadius?: string;
  hoverColor?: string;
  height?: string;
  showRightBorder?: boolean;
  isGptBtn?: boolean;
  padding?: string;
  invertIconOnHover?: boolean;
  invertIcon?: boolean;
  textHoverColor?: string;
}>`
  border: ${(props) => props.borderStyle};
  padding: ${(props) => props.padding ?? ' 6px 16px 6px 12px'};
  opacity: ${({ isClicked }) => (isClicked ? 0.5 : 1)};
  border-color: ${({ isClicked }) => (isClicked ? 'blue' : 'none')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '8px')};
  display: flex;
  align-items: center;
  height: ${({ height }) => (height ? height : '36px')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) =>
    props.disabled && props.isGptBtn ? '1' : props.disabled ? '0.4' : ''};
  background-color: ${(props) => props.bgColor};
  flex-direction: ${(props) =>
    props.flexDirection === 'row-reverse' ? props.flexDirection : 'row'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : ''};

  &:hover {
    background-color: ${(props) =>
      props.hoverColor
        ? props.hoverColor
        : props.borderStyle !== 'none'
          ? ''
          : '#454284'};
    border-color: ${(props) =>
      props.disabled ? '' : props.borderStyle !== 'none' && '#454284'};
    color: #fff;
    border-color: ${(props) =>
      props.hoverColor
        ? props.hoverColor
        : props.borderStyle !== 'none'
          ? ''
          : '#454284'};
  }

  & > img {
    filter: ${(props) => (props.invertIcon ? 'invert()' : 'unset')};
  }

  &:hover > img {
    filter: ${(props) => (props.invertIconOnHover ? 'invert()' : 'unset')};
  }

  &:hover span {
    color: ${(props) =>
      props.textHoverColor
        ? props.textHoverColor
        : props.hoverColor === '#454284'
          ? '#fff'
          : '#000a14'};
  }
`;

const ButtonIcon = styled.img<{
  flexDirection?: string;
  iconWidth?: string;
  iconSrc: string;
  hoverColor?: string;
}>`
  width: ${({ iconWidth }) => (iconWidth ? iconWidth : 'fit-content')};
  margin-left: ${(props) =>
    props.flexDirection === 'row-reverse' ? '5px' : '0'};
  display: ${(p) => (p.iconSrc === '' ? 'none' : '')};
`;

const ButtonText = styled.span<{ flexDirection?: string; bgColor?: string }>`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => (props.bgColor === '#454284' ? '#fff' : '#000a14')};
  margin-left: ${(props) =>
    props.flexDirection === 'row-reverse' ? '0' : '5px'};
`;
