import React from 'react';
import styled from 'styled-components';

export const Header = () => {
  return (
    <Wrapper>
      <HeaderText1>
        Add new task manager integration to manage the lifecycle of your issues.
      </HeaderText1>
      <HeaderText2>
        Automatically create and resolve issue, for better issue tracking.
      </HeaderText2>
      <HeaderText3>Jira integration</HeaderText3>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderText1 = styled.h1`
  font-weight: 400;
  font-size: 19px;
  line-height: 120%;
  color: #000a14;
  margin-bottom: 11px;
`;

const HeaderText2 = styled.h2`
  font-weight: 300;
  font-size: 17px;
  line-height: 120%;
  color: #000a14;
`;

const HeaderText3 = styled.h3`
  font-weight: 300;
  font-size: 15px;
  line-height: 120%;
  color: #000a14;
  margin-top: 30px;
`;
