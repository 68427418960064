import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditSettingsAPI } from '../../api/audit-settings';
import { AuditSettingsState } from './types';
import {
  AuditSettings,
  AuditSettingsPayload,
} from '../../types/audit-settings';

const initialState: AuditSettingsState = {
  auditSettings: null,
  loading: false,
  error: '',
  message: '',
};

export const getAuditSettings = createAsyncThunk(
  `auditSettings/get-settings`,
  async (payload: { businessId: number; websiteId: number }) => {
    const { websiteId, businessId } = payload;
    const { data, isSuccess, error } = await AuditSettingsAPI.getSettings(
      businessId,
      websiteId,
    );

    return { data, isSuccess, error };
  },
);

export const saveAuditSettings = createAsyncThunk(
  `auditSettings/save-settings`,
  async (payload: {
    businessId: number;
    payloadData: AuditSettingsPayload;
  }) => {
    const { data, isSuccess, error } =
      await AuditSettingsAPI.saveAuditSettings(payload);

    return { data, isSuccess, error };
  },
);

const slice = createSlice({
  name: 'auditSettings',
  initialState,
  reducers: {
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },

    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setAuditSettings(state, action: PayloadAction<AuditSettings | null>) {
      state.auditSettings = action.payload;
    },
  },
  extraReducers: {
    [getAuditSettings.pending.type]: (state) => {
      state.loading = true;
    },
    [getAuditSettings.fulfilled.type]: (state, action) => {
      state.auditSettings = action.payload.data;
      state.loading = false;
    },
    [getAuditSettings.rejected.type]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    [saveAuditSettings.pending.type]: (state) => {
      state.loading = true;
    },
    [saveAuditSettings.fulfilled.type]: (state, action) => {
      state.auditSettings = action.payload.data;
      state.loading = false;
    },
    [saveAuditSettings.rejected.type]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
  },
});

export const { setMessage, setError, setLoading, setAuditSettings } =
  slice.actions;
export default slice.reducer;
