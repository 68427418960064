import React from 'react';
import { MainText, SubText, TableRow } from './table';
import { Box, Typography } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import styled from '@emotion/styled';
import {
  ComplianceDocument,
  ComplianceDocumentStatus,
  ComplianceDocumentStatusEnum,
} from '@equally-ai-front/common/src/types/compliance-document';
import moment from 'moment';
import { ContainedButton } from '../../../../components/common/ActionButton';
import { statusIconColorMap } from '@equally-ai-front/common/src/components/compliance-documents/consts';
import { templateToDocumentInfoMap } from '@equally-ai-front/common/dist/src/redux/compliance-documents-slice/compliance-documents';

interface DocumentRowProps {
  complianceDocument: ComplianceDocument;
  handleSubmitDocument: (payload: {
    domain: string;
    template_id: number;
    status: string;
    id: string;
  }) => Promise<{
    isSuccess: boolean;
    error: any;
  }>;
  handleOpenSubmitPopup: (payload: { defaultSelectedDomain: string }) => void;
  handleStatusUpdate: (payload: {
    status: string;
    domain: string;
    templateId: number;
    url: string;
  }) => void;
}
export const DocumentRow = (props: DocumentRowProps) => {
  const {
    complianceDocument,
    handleSubmitDocument,
    handleOpenSubmitPopup,
    handleStatusUpdate,
  } = props;
  const documentStatusIcon = statusIconColorMap[complianceDocument.status].icon;
  const documentStatusColor =
    statusIconColorMap[complianceDocument.status].color;
  const documentStatusText = statusIconColorMap[complianceDocument.status].text;

  const handleUpdateDocumentStatus = async () => {
    const { status, template_id, data, domain } = complianceDocument;
    if (status !== 'declined') {
      return;
    }
    await handleSubmitDocument({
      domain: complianceDocument.domain,
      status: ComplianceDocumentStatusEnum.PENDING,
      template_id: complianceDocument.template_id,
      id: complianceDocument.id,
    });
    handleStatusUpdate({
      status,
      domain,
      templateId: template_id,
      url: data.url,
    });
  };

  const handleSubmit = () => {
    const { domain } = complianceDocument;
    handleOpenSubmitPopup({ defaultSelectedDomain: domain });
  };

  return (
    <TableRow>
      <Box display="flex" flexDirection="column">
        <MainText fontSize="18px">
          {templateToDocumentInfoMap[complianceDocument.template_id].title}
        </MainText>
        <MainText fontSize="16px">
          {
            templateToDocumentInfoMap[complianceDocument.template_id]
              .description
          }
        </MainText>
        <SubText fontSize="14px">
          Last updated:&nbsp;
          {moment(complianceDocument.updated_at).format('DD/MM/YYYY')}
        </SubText>
        <SubText fontSize="14px">
          Domain:&nbsp;{complianceDocument?.domain || ''}
        </SubText>
      </Box>
      <DocumentLinkStatusWrapper>
        {complianceDocument.status === 'default' ? (
          <Button onClick={handleSubmit}>
            <>{documentStatusIcon}</>
            <>{documentStatusText}</>
          </Button>
        ) : (
          <DocumentStatus
            status={complianceDocument.status}
            onClick={handleUpdateDocumentStatus}
          >
            <>{documentStatusIcon}</>
            <Typography fontSize="14px" color={documentStatusColor}>
              {documentStatusText}
            </Typography>
          </DocumentStatus>
        )}
      </DocumentLinkStatusWrapper>
    </TableRow>
  );
};

const DocumentLinkStatusWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: 600px) {
    align-items: flex-start;
    gap: 15px;
    flex-direction: column-reverse;
  }
`;

const DocumentStatus = styled(Box)<{
  status: ComplianceDocumentStatus;
}>`
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  border-radius: 6px;
  gap: 7px;
  cursor: ${(props) => (props.status === 'declined' ? 'pointer' : '')};
`;

const Button = MuiStyled(ContainedButton)`
  text-transform: none;
  height: 35px;
  padding: 6px 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 7px;
`;
