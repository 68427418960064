import * as Yup from 'yup';
import { FIELD_AUDIT_URL } from '../contants';

const DomainRegEx =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,12}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const URLValidator = Yup.string()
  .trim()
  .test(
    'url',
    'Enter valid website URL',
    (value) => !!value && DomainRegEx.test(value),
  );

const validationSchema = Yup.object().shape({
  [FIELD_AUDIT_URL]: URLValidator.required('Website URL is required'),
});

export default validationSchema;
