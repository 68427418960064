import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';

class OverviewAPI {
  static async getTotalScans(businessId: number, websiteIds: number[]) {
    let result;
    let error;

    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/scans?website_ids=${websiteIds.join(
          ',',
        )}`,
      );
    } catch (err) {
      const apiError: any = err;
      console.error('Error fetching scans', err);
      error = apiError;
    }

    const { data } = result || {};
    return {
      data: data?.data || null,
      isSuccess: data?.code === 200,
      error: error?.response?.data?.message || 'Error fetching all scans.',
    };
  }

  static async getAllDevDomainScans(
    businessId: number,
    websiteId: number,
    pageUrl?: string,
  ) {
    let result;
    let error;
    const urlParam = pageUrl ? `?page_url=${pageUrl}` : '';
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/scans/website/${websiteId}${urlParam}`,
      );
    } catch (err) {
      console.error('Error getting scan', err);
      error = err;
    }

    const { data } = result || {};

    const sortedData = (data?.data || []).sort((scanA: any, scanB: any) => {
      return +new Date(scanB.updated_at) - +new Date(scanA.updated_at);
    });
    return {
      data: sortedData,
      isSuccess: data?.code === 200,
      // @ts-ignore
      error: error?.response?.data?.message || 'Error fetching all scans.',
    };
  }
}

export { OverviewAPI };
