import React from 'react';
import styled from 'styled-components';

interface SignalBarIconProps {
  isActive: boolean;
  handleClick: () => void;
}

export const SignalBarIcon = (props: SignalBarIconProps) => {
  const { isActive, handleClick } = props;

  return (
    <Wrapper onClick={handleClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={`${isActive ? '#454284' : '#e8eaed'}`}
      >
        <path d="M200-160v-240h120v240H200Zm240 0v-440h120v440H440Zm240 0v-640h120v640H680Z" />
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  width: 49%;
  height: 24px;
  margin-right: 5px;
`;
