// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  animation: skeleton-loading 0.5s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  border-radius: 0.125rem;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./../audit-ui/src/index.css"],"names":[],"mappings":"AAAA;EACE,0DAA0D;AAC5D;;AAEA;EACE,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".skeleton {\n  animation: skeleton-loading 0.5s linear infinite alternate;\n}\n\n.skeleton-text {\n  width: 100%;\n  border-radius: 0.125rem;\n}\n\n@keyframes skeleton-loading {\n  0% {\n    opacity: 0.5;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
