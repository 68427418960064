import { ButtonWithIcon } from '@equally-ai-front/common/src/components/button/ButtonWithIcon';
import { CustomToolTip } from '@equally-ai-front/common/src/components/custom-tooltip/CustomToolTip';
import { removeSlashAtUrlEnd } from '@equally-ai-front/common/src/lib/domains';
import { scanRecommendationsActions } from '@equally-ai-front/common/src/redux/developer-slice';
import {
  selectLoadingScanRecommendations,
  selectMessage,
  selectProductTourIndex,
} from '@equally-ai-front/common/src/redux/developer-slice/selector';
import { scanHistory } from '@equally-ai-front/common/src/redux/historySlice';
import {
  GptApiPayload,
  GptType,
} from '@equally-ai-front/common/src/types/chat-gpt';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import {
  COPY_ICON_TOOLTIP_MESSAGE,
  DISMISS_ICON_TOOLTIP_MESSAGE,
} from '@equally-ai-front/common/src/utils/constants';
import {
  // DevtoolsGroup,
  DevtoolsSolution,
  IssueType,
  ReportData,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { TIME_OUT_COPY_FUNCTION } from '@equally-ai/audit-ui/src/components/scan-report/scan-report-issue-details';
import Zoom from '@mui/material/Zoom';
import React, { RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setApiError, setApiSuccessMessage } from '../../store/actions';
import { IssuesAPI } from '../compliance-manager/store/api/issues-api';
import { GptActions } from './gpt-actions';
import { ButtonWithIconDiv } from './styled-components';

interface ItemDetailsHeaderProps {
  wcagReport: ReportData;
  devToolsSolution: DevtoolsSolution;
  issueType: IssueType;
  issueDivSectionRef: RefObject<HTMLDivElement>;
  gptButtonsId: string;
  copyButtonId: string;
  dismissButtonId: string;
  getGptSuggestion: (gptType: GptType, gptDetails: GptApiPayload) => void;
  devDomain?: DevDomain;
}

export const ItemDetailsHeader = (props: ItemDetailsHeaderProps) => {
  const {
    // wcagReport,
    devToolsSolution,
    // issueType,
    issueDivSectionRef,
    gptButtonsId,
    copyButtonId,
    dismissButtonId,
    getGptSuggestion,
    devDomain,
  } = props;
  const dispatch = useDispatch();

  const loadingScan = useSelector(selectLoadingScanRecommendations);
  const messageFromApi = useSelector(selectMessage);
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const issues = useSelector((state: any) => state.scanHistory.issues);
  const [ongoingGptType, setOngoingGptType] = useState<GptType | ''>('');
  const currentAuditedPageName =
    localStorage.getItem('currentAuditedPage') || '';

  const loadingGpt = useSelector((state: any) => state.dashboard.isGptLoading);
  const productTourIndex = useSelector(selectProductTourIndex);
  // const websiteDomain = localStorage.getItem('websiteDomain') || '';
  const [isInstructionsCopied, setIsInstructionsCopied] =
    useState<boolean>(false);

  const handleDismissIssue = async () => {
    if (!currentBusiness) {
      return;
    }

    dispatch(scanRecommendationsActions.setLoading(true));
    const { isSuccess, error, data } = await IssuesAPI.dismissIssue({
      selector: devToolsSolution.selector,
      domainUrl: removeSlashAtUrlEnd(devToolsSolution.domain_url),
      businessId: currentBusiness.id,
      issueId: devToolsSolution.id,
    });

    if (!isSuccess) {
      dispatch(setApiError(error));
      dispatch(scanRecommendationsActions.setLoading(false));
      return;
    }

    const updatedIssues = issues.map((issue: Issue) =>
      issue.id === data.id ? data : issue,
    );
    dispatch(scanHistory.setIssues(updatedIssues));
    dispatch(setApiSuccessMessage('Issue successfully dismissed'));
    dispatch(scanRecommendationsActions.setLoading(false));
  };

  // const removeIssueFromList = useCallback(
  //   (message: string) => {
  //     const issueGroupList: WcagReportListT | [] =
  //       wcagReport.reportGroups[issueType];
  //     const filteredIssue: WcagReportListT | [] = issueGroupList.map(
  //       (issueGroup) => {
  //         const list: DevtoolsSolution[] = issueGroup[1];
  //         const filteredList: DevtoolsSolution[] = list.filter(
  //           (item) => item.id !== devToolsSolution.id,
  //         );

  //         return [issueGroup[0], filteredList];
  //       },
  //     );

  //     const filteredReportGroups: DevtoolsGroup = {
  //       ...wcagReport.reportGroups,
  //       [issueType]: filteredIssue,
  //     };

  //     const filteredList = wcagReport.list[issueType].filter(
  //       (list) => list.id !== devToolsSolution.id,
  //     );
  //     const modifiedListGroup = {
  //       ...wcagReport.list,
  //       [issueType]: filteredList,
  //     };

  //     setWcagReport({
  //       ...wcagReport,
  //       list: modifiedListGroup,
  //       reportGroups: filteredReportGroups,
  //     });
  //   },
  //   [devToolsSolution.id, issueType, setWcagReport, wcagReport],
  // );

  useEffect(() => {
    if (messageFromApi === 'Issue dismissed!') {
      dispatch(setApiSuccessMessage(messageFromApi));
      dispatch(scanRecommendationsActions.setMessage(''));
      // removeIssueFromList(messageFromApi);
    }
  }, [dispatch, messageFromApi]);

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  };

  const copyInstructions = () => {
    copyTextToClipboard(issueDivSectionRef.current?.innerText ?? '')
      .then(() => {
        // If successful, update the isCopied state value
        setIsInstructionsCopied(true);
        setTimeout(() => {
          setIsInstructionsCopied(false);
        }, TIME_OUT_COPY_FUNCTION);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isInstructionsCopied) {
      dispatch(setApiSuccessMessage('Copied!'));
    }
  }, [isInstructionsCopied, dispatch]);

  useEffect(() => {
    if (ongoingGptType !== '' && !loadingGpt) {
      setOngoingGptType('');
    }
  }, [loadingGpt, ongoingGptType]);

  return (
    <Wrapper>
      <ButtonDiv>
        {devDomain && (
          <GptActions
            currentAuditedPageName={currentAuditedPageName}
            devToolsSolution={devToolsSolution}
            getGptSuggestion={getGptSuggestion}
            setOngoingGptType={setOngoingGptType}
            devDomain={devDomain}
            gptButtonsId={gptButtonsId}
            loadingGpt={loadingGpt}
            ongoingGptType={ongoingGptType}
            productTourIndex={productTourIndex}
          />
        )}
        <IssueActionButtonWrapper>
          <CustomToolTip
            title={COPY_ICON_TOOLTIP_MESSAGE}
            placement="top"
            TransitionComponent={Zoom}
            arrow
          >
            <ButtonWithIconDiv id={copyButtonId}>
              <ButtonWithIcon
                buttonText="Copy"
                iconSrc="/copy-icon.svg"
                iconAltText="copy icon"
                iconWidth="16px"
                backgroundColor="transparent"
                hoverColor="#ecfcf2"
                borderRadius="8px"
                height="30px"
                borderStyle="1.5px solid #cbd2d9"
                onClickAction={copyInstructions}
              />
            </ButtonWithIconDiv>
          </CustomToolTip>
          <CustomToolTip
            title={DISMISS_ICON_TOOLTIP_MESSAGE}
            placement="top"
            TransitionComponent={Zoom}
            arrow
          >
            <ButtonWithIconDiv id={dismissButtonId}>
              <ButtonWithIcon
                buttonText="Dismiss"
                iconSrc="/close-icon.svg"
                iconAltText="close icon"
                iconWidth="16px"
                backgroundColor="transparent"
                hoverColor="#ecfcf2"
                borderRadius="8px"
                height="30px"
                borderStyle="1.5px solid #cbd2d9"
                onClickAction={handleDismissIssue}
                showLoadingSpinner={loadingScan}
              />
            </ButtonWithIconDiv>
          </CustomToolTip>
        </IssueActionButtonWrapper>
      </ButtonDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 16px 12px;
  position: relative;
  background-color: #ffffff;

  &:after {
    content: '';
    border-bottom: 1.5px solid #e4e7eb;
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`;

const IssueActionButtonWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 560px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    justify-content: space-evenly;
  }

  button {
    @media screen and (max-width: 1110px) and (min-width: 900px) {
      height: 27px;
      padding: 0 10px 0 8px;
      border-radius: 8px;
      border-width: 1.5px;
    }

    @media screen and (max-width: 768px) {
      padding: 0 10px;
      height: 35px;
      border-radius: 9px;
      border-width: 1.5px;
    }

    @media screen and (max-width: 560px) {
      width: 100%;
      justify-content: center;
    }

    img {
      @media screen and (max-width: 1110px) and (min-width: 900px) {
        width: 13px;
      }

      @media screen and (max-width: 768px) {
        width: 21px;
      }
    }

    .btn-with-icon-text {
      font-size: 12px;

      @media screen and (max-width: 1110px) and (min-width: 900px) {
        font-size: 10px;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }

      @media screen and (max-width: 460px) {
        font-size: 10px;
      }
    }
  }
`;
