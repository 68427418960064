import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import {
  getIssueGroupName,
  IssueSeverityEnumLookup,
} from '@equally-ai-front/common/src/lib/issues';
import { groupAccessibilityIssuesByTitle } from '@equally-ai-front/common/src/lib';
import { CustomReportFields } from '../../exports/export';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { IssueColumnsTable } from './issue-columns-table';

interface PdfReportTableBodyProps {
  pdfReport: DevtoolsSolution[];
  customReportFields: CustomReportFields | null;
  isColorShadeOfWhiteOrGrey: boolean;
  columns: string[];
}

const MAX_LENGTH = 100;
const splitLongText = (text: string, chunkSize = MAX_LENGTH): string[] => {
  const result = [];
  for (let i = 0; i < text.length; i += chunkSize) {
    result.push(text.slice(i, i + chunkSize));
  }
  return result;
};

export const TableBody = (props: PdfReportTableBodyProps) => {
  const { pdfReport, customReportFields, isColorShadeOfWhiteOrGrey, columns } =
    props;
  const issuesGroupedByTitle = groupAccessibilityIssuesByTitle(pdfReport);

  const borderColor = '#e3e8fb';
  const generalColor = customReportFields?.themeColor || '#222a66';
  const styles = StyleSheet.create({
    tableRow: {
      flexDirection: 'column',
      borderBottomColor: borderColor,
      borderBottomWidth: '1.5px',
      fontWeight: 'normal',
      fontFamily: 'DM Sans',
      fontSize: 10,
      paddingTop: 20,
      color: '#000',
      width: '100%',
    },
    tableRowContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      minHeight: 20,
      maxHeight: 40,
      paddingBottom: 5,
      fontSize: 11,
    },
    number: {
      width: '10%',
      textAlign: 'center',
      color: generalColor,
    },
    issueGroup: {
      width: '25%',
      textAlign: 'left',
      color: generalColor,
      paddingRight: 10,
    },
    description: {
      width: '45%',
      textAlign: 'left',
      color: generalColor,
      paddingRight: 10,
    },
    issues: {
      width: '10%',
      textAlign: 'center',
      color: generalColor,
    },
    priority: {
      width: '10%',
      textAlign: 'center',
      color: generalColor,
    },
    codeSnippetWrapper: {
      margin: '20px 0 0 10%',
      width: '100%',
      maxWidth: '90%',
    },
    codeSnippetTitle: {
      color: isColorShadeOfWhiteOrGrey ? '#ffffff' : '#181818',
      fontSize: 11,
      fontWeight: 'normal',
    },
    codeSnippetContent: { marginTop: 10 },
    codeBlock: {
      width: '100%',
      maxWidth: '95%',
      padding: 10,
      borderRadius: 8,
      backgroundColor: '#181818',
      marginBottom: 10,
      marginRight: '5%',
    },
    codeSnippet: {
      color: '#d8d8d8',
      width: '100%',
      fontSize: 11,
      fontWeight: 'normal',
    },
    selectorText: {
      color: 'black',
      width: '100%',
      fontSize: 11,
      fontWeight: 'normal',
    },
  });

  return (
    <>
      {Object.entries(issuesGroupedByTitle).map(
        ([groupDescription, issues], index) => {
          const [{ type, groupLabel }] = issues;
          return (
            <View style={styles.tableRow} key={`${groupDescription}-${index}`}>
              <View style={styles.tableRowContent}>
                <Text style={styles.number}>{index + 1}</Text>
                <Text style={styles.issueGroup}>
                  {getIssueGroupName(groupLabel)}
                </Text>
                <Text style={styles.description}>{groupDescription}</Text>
                <Text style={styles.issues}>{issues.length}</Text>
                <Text style={styles.priority}>
                  {IssueSeverityEnumLookup[type]}
                </Text>
              </View>
              {issues.length > 0 && issues[0].context && (
                <View style={styles.codeSnippetWrapper}>
                  <Text style={styles.codeSnippetTitle}>Code Snippet</Text>
                  <View style={styles.codeSnippetContent}>
                    {issues.map(({ id, context, selector }, index) => (
                      <View key={id} minPresenceAhead={60}>
                        {/*{selector && (*/}
                        {/*  <Text*/}
                        {/*    key={`${id}-selector-${index}`}*/}
                        {/*    style={styles.selectorText}*/}
                        {/*  >*/}
                        {/*    {selector}*/}
                        {/*  </Text>*/}
                        {/*)}*/}
                        {context && (
                          <View
                            key={`${id}-code-block-${index}`}
                            style={styles.codeBlock}
                          >
                            <Text style={styles.codeSnippet}>{selector}</Text>
                            <Text style={styles.codeSnippet}>
                              -------------------------------------
                            </Text>
                            {splitLongText(context).map((text, idx) => (
                              <Text
                                key={`${idx}-code-block-text-${index}`}
                                style={styles.codeSnippet}
                              >
                                {text}
                              </Text>
                            ))}
                          </View>
                        )}
                      </View>
                    ))}
                  </View>
                </View>
              )}
              {columns.length > 0 && (
                <IssueColumnsTable
                  issues={issues}
                  columns={columns}
                  isColorShadeOfWhiteOrGrey={isColorShadeOfWhiteOrGrey}
                />
              )}
            </View>
          );
        },
      )}
    </>
  );
};
