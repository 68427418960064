import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ComplianceService } from '../api';
import { Issue, Scan } from '../types/issues';
import { ScanType } from '../types/scans';
import { PreferencesAPI } from '../api/preferences-api';

interface UseRecommendationsProps {
  scan: Scan | undefined;
  issues: Issue[];
  handleRecommendationCallback: (issues: Issue[]) => void;
}

export interface ScanRecommendationsResult {
  status: string;
  issues_recommendations: Record<string, any>;
  isRecommendationLoading: boolean;
}

export const useRecommendations = (props: UseRecommendationsProps) => {
  const { scan, issues, handleRecommendationCallback } = props;
  const dispatch = useDispatch();
  const scanRecommendationIdRef = useRef<number | undefined>(undefined);
  // const scanRecommendationIdRef = useRef<number | undefined>(undefined);
  const scanRecommendationFailures = useRef<number>(0);
  const scanRecommendationPending = useRef<number>(0);
  const [websiteSettings, setWebsiteSettings] = useState<any | undefined>(
    undefined,
  );
  const [scanRecommendationsResult, setScanRecommendationsResult] = useState<
    ScanRecommendationsResult | undefined
  >(undefined);

  const clearScanRecommendationsResult = () => {
    setScanRecommendationsResult(undefined);
    setWebsiteSettings(undefined);
    scanRecommendationFailures.current = 0;
    scanRecommendationPending.current = 0;
    scanRecommendationIdRef.current = undefined;
  };
  useEffect(() => {
    return () => clearScanRecommendationsResult();
  }, [setScanRecommendationsResult]);

  const fetchWebsiteSettings = useCallback(async () => {
    if (!scan) {
      return;
    }
    const response = await PreferencesAPI.getWebsiteSettings({
      domainId: scan.website_id.toString(),
      businessId: scan.business_id.toString(),
    });
    const { data, isSuccess } = response;
    if (isSuccess && data) {
      setWebsiteSettings(data);
    }
  }, [scan]);

  useEffect(() => {
    if (!scan || !scan.website_id) {
      return;
    }
    void fetchWebsiteSettings();
  }, [scan]);

  const getRecommendations = useCallback(
    async (scan: Scan, issues: Issue[], cb?: () => void) => {
      if (scanRecommendationIdRef.current === undefined) {
        return;
      }

      const response = await ComplianceService.getScanRecommendations(
        scan.business_id,
        scan.id,
      );
      let { data, isSuccess } = response;

      if (!data && isSuccess) {
        setScanRecommendationsResult({
          status: 'SUCCESS',
          issues_recommendations: {},
          isRecommendationLoading: false,
        });
        return;
      }

      if (!data || !isSuccess) {
        scanRecommendationFailures.current += 1;
        if (scanRecommendationFailures.current > 5) {
          setScanRecommendationsResult({
            status: 'FAILED',
            issues_recommendations: {},
            isRecommendationLoading: false,
          });
        }
        return;
      }

      if (data) {
        const issueRecommendations = data.issues_recommendations;

        if (data.status === 'SUCCESS' || data.status === 'PENDING') {
          const newIssues = issues.map((issue: Issue) => ({
            ...issue,
            recommendation: JSON.stringify({
              attributes:
                issueRecommendations[`${issue.selector} ${issue.code}`],
            }),
          }));
          if (data.status === 'PENDING') {
            handleRecommendationCallback(newIssues);
            scanRecommendationPending.current += 1;
            setScanRecommendationsResult({
              status: data.status,
              issues_recommendations: issueRecommendations,
              isRecommendationLoading: true,
            });
          } else if (data.status === 'SUCCESS') {
            handleRecommendationCallback(newIssues);
            setScanRecommendationsResult({
              status: data.status,
              issues_recommendations: issueRecommendations,
              isRecommendationLoading: false,
            });
          }
        }

        if (data.status === 'FAILED' || data.status === 'NOT-FOUND') {
          setScanRecommendationsResult({
            status: 'FAILED',
            issues_recommendations: {},
            isRecommendationLoading: false,
          });
          cb && cb();
          return;
        }
      }

      if (
        scanRecommendationFailures.current > 5 ||
        scanRecommendationPending.current > 10
      ) {
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 8000));
      await getRecommendations(scan, issues, cb);
    },
    [
      scanRecommendationIdRef,
      scanRecommendationFailures,
      scan,
      issues,
      dispatch,
    ],
  );
  // useEffect(() => {
  //   if (
  //     scan &&
  //     scan.id &&
  //     scanRecommendationIdRef.current &&
  //     scanRecommendationIdRef.current === scan.id
  //   ) {
  //     return;
  //   }
  //   clearScanRecommendationsResult();
  // }, [scan]);
  const executeRecommendations = useCallback(async () => {
    if (!scan || issues.length === 0) {
      return;
    }
    await getRecommendations(scan, issues);
  }, [scan, issues, getRecommendations]);
  useEffect(() => {
    if (
      (scanRecommendationsResult &&
        scanRecommendationsResult.isRecommendationLoading) ||
      !scan?.website_id ||
      !websiteSettings ||
      !websiteSettings.allow_scan_recommendations
    ) {
      return;
    }

    if (scan.type === ScanType.manual) {
      return;
    }

    if (scanRecommendationIdRef.current === scan?.id || issues.length === 0) {
      return;
    }

    scanRecommendationIdRef.current = scan.id;
    setScanRecommendationsResult({
      status: 'PENDING',
      issues_recommendations: {},
      isRecommendationLoading: true,
    });
    void executeRecommendations();
  }, [
    scan,
    issues,
    dispatch,
    websiteSettings,
    scanRecommendationIdRef,
    scanRecommendationsResult,
  ]);

  return {
    scanRecommendationsResult,
    executeRecommendations,
  };
};
