import { PreferencesAPI } from '@equally-ai-front/common/src/api/preferences-api';
import { MuiSelect } from '@equally-ai-front/common/src/components/custom-mui-select/MuiSelect';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { getWidgetDomains } from '@equally-ai-front/common/src/redux/user-products-slice/user-products';
import { IssueSeverity } from '@equally-ai-front/common/src/types/issues';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  HeaderColorType,
  StickyHeader,
} from '../../../../components/sticky-header/sticky-header';
import { setApiError, setApiSuccessMessage } from '../../../../store/actions';
import { Title } from '../../components/css-components';
import { Notification } from './notification';
import { ScheduledAuditDetails } from './scheduled-audit-details';

export interface NotificationSettings {
  email: Record<IssueSeverity, string>;
  browser: Record<IssueSeverity, string>;
  email_recipients: string[];
}

export type WebsiteSettings = {
  ritual: number | string;
  period: string;
  dayOfWeek: string;
  isScheduledScanActive: boolean;
  nextScheduleScanAt: string;
  lastScheduledAt: string;
  websiteId: number | undefined;
  notificationSettings: NotificationSettings;
};

export interface PreferenceCompProps {
  handleWebsiteSettingsChange: (
    evt:
      | SelectChangeEvent
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  websiteSettings: WebsiteSettings;
  setWebsiteSettings: Dispatch<SetStateAction<WebsiteSettings>>;
}

const formatUnixTimeToIsoTime = (unixTime: string | number): string => {
  const milliseconds = Number(unixTime) * 1000;
  const date = new Date(milliseconds);
  return date.toISOString();
};

const DEFAULT_WEBSITE_SETTINGS = {
  period: '',
  ritual: '',
  dayOfWeek: '',
  isScheduledScanActive: false,
  nextScheduleScanAt: '',
  lastScheduledAt: '',
  websiteId: undefined,
  notificationSettings: {
    email: { error: 'never', warning: 'never', notice: 'never' },
    browser: { error: 'never', warning: 'never', notice: 'never' },
    email_recipients: [],
  },
};

export const PreferencesPage = () => {
  const dispatch = useDispatch();
  const { domains } = useSelector((state: any) => ({
    domains: state.userProducts.domains,
  }));
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const [domainId, setDomainId] = useState('');
  const [websiteSettings, setWebsiteSettings] = useState<WebsiteSettings>({
    ...DEFAULT_WEBSITE_SETTINGS,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }

    // @ts-ignore
    dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
  }, [dispatch, currentBusiness]);

  const handleWebsiteSettingsChange = (
    evt:
      | SelectChangeEvent
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = evt.target;

    setWebsiteSettings({
      ...websiteSettings,
      [name]:
        'type' in evt.target &&
        'checked' in evt.target &&
        evt.target.type === 'checkbox'
          ? evt.target.checked
          : value,
    });
  };

  const handleSaveWebsiteSettings = async (
    notificationSettings?: NotificationSettings,
  ) => {
    if (!currentBusiness || domainId === '') {
      return;
    }

    setIsLoading(true);
    const { period, ritual, dayOfWeek, isScheduledScanActive } =
      websiteSettings;

    const scanSchedulePayload: any = {
      scan_scheduling: {
        period,
        ritual: Number(ritual),
        day_of_week: dayOfWeek,
      },
      is_scheduled_scan_active: isScheduledScanActive,
      // last_scheduled_at: formatUnixTimeToIsoTime(lastScheduledAt),
    };
    if (notificationSettings) {
      scanSchedulePayload.notification_settings = notificationSettings;
    }
    const { data, error, isSuccess } = await PreferencesAPI.saveWebsiteSettings(
      {
        scanSchedulePayload,
        domainId,
        businessId: currentBusiness?.id,
      },
    );

    if (isSuccess) {
      dispatch(setApiSuccessMessage('Website settings updated!'));
      setWebsiteSettings({
        isScheduledScanActive: data.is_scheduled_scan_active,
        period: data.scan_scheduling.period,
        ritual: data.scan_scheduling.ritual,
        dayOfWeek: data.scan_scheduling.day_of_week,
        nextScheduleScanAt: data.next_scheduled_scan_at,
        lastScheduledAt: data.last_scheduled_at,
        websiteId: data.website_id,
        notificationSettings: data.notification_settings,
      });
    } else {
      dispatch(setApiError(error));
    }
    setIsLoading(false);
  };

  const getWebsiteSettings = useCallback(
    async (businessId: string) => {
      setIsLoading(true);
      const { data, error, isSuccess } =
        await PreferencesAPI.getWebsiteSettings({
          domainId,
          businessId,
        });

      if (isSuccess && data) {
        setWebsiteSettings({
          isScheduledScanActive: data.is_scheduled_scan_active,
          period: data.scan_scheduling.period,
          ritual: data.scan_scheduling.ritual,
          dayOfWeek: data.scan_scheduling.day_of_week,
          nextScheduleScanAt: data.next_scheduled_scan_at,
          lastScheduledAt: data.last_scheduled_at,
          websiteId: data.website_id,
          notificationSettings: data.notification_settings,
        });
      } else if (error) {
        dispatch(setApiError(error));
        setWebsiteSettings(DEFAULT_WEBSITE_SETTINGS);
      } else if (!data) {
        setWebsiteSettings(DEFAULT_WEBSITE_SETTINGS);
      }
      setIsLoading(false);
    },
    [dispatch, domainId],
  );

  const domainsList = useMemo(() => {
    const domainOptions: { value: string; label: string }[] = [
      {
        value: '-1',
        label: 'Business default',
      },
    ];

    if (domains?.length === 0) {
      return domainOptions;
    }

    domains
      ?.filter((mainDomain: any) => mainDomain.allow_user_integration)
      .forEach((domain: any) => {
        domainOptions.push({
          value: domain?.id,
          label: domain?.url,
        });
      });

    if (domainOptions.length > 1) {
      setDomainId(domainOptions[1].value);
    }
    return domainOptions;
  }, [domains]);

  useEffect(() => {
    if (!currentBusiness || domainId === '') {
      return;
    }

    void getWebsiteSettings(currentBusiness?.id);
  }, [currentBusiness, getWebsiteSettings, domainId]);

  return (
    <>
      <ContentWrapper>
        <Header>
          <TitleWrapper>
            <SettingsTitle>Schedule scans</SettingsTitle>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSaveWebsiteSettings(undefined)}
              type="submit"
            >
              Save Changes
            </Button>
          </TitleWrapper>
          {websiteSettings.websiteId === -1 && domainId !== '-1' && (
            <StickyHeaderWrapper>
              <StickyHeader
                variant={HeaderColorType.SUCCESS}
                title="Website doesn’t have a specific configuration and using business defaults."
              />
            </StickyHeaderWrapper>
          )}
          <DomainSelectWrapper>
            <MuiSelect
              selectLabel="Select Domain"
              options={domainsList}
              value={domainId}
              handleChange={(evt) => setDomainId(evt.target.value)}
              name="domainId"
              fullWidth
              required
            />
          </DomainSelectWrapper>
        </Header>
        <>
          <ScheduledAuditDetails
            websiteSettings={websiteSettings}
            handleWebsiteSettingsChange={handleWebsiteSettingsChange}
            setWebsiteSettings={setWebsiteSettings}
          />
          <Notification
            websiteSettings={websiteSettings}
            onSave={handleSaveWebsiteSettings}
          />
        </>
      </ContentWrapper>
      <LoadingView open={isLoading} loadingText="Loading website settings!" />
    </>
  );
};

const Header = styled.div`
  position: relative;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const SettingsTitle = styled(Title)`
  margin-bottom: 40px;
  margin-top: -4.5px;

  @media (pointer: none), (pointer: coarse) {
    margin-bottom: 20px;
  }
`;

const DomainSelectWrapper = styled.div`
  margin-bottom: 20px;
  width: 252px;
`;

const ContentWrapper = styled.div`
  margin-bottom: 80px;

  @media (pointer: none), (pointer: coarse) {
    margin-top: 40px;
  }

  th,
  td {
    border-right: 1px solid rgba(224, 224, 224, 1);

    &:last-child {
      border-right: none;
    }
  }

  th {
    border-top: none;
  }
`;

const StickyHeaderWrapper = styled.div`
  margin-bottom: 30px;

  > div {
    height: 60px;
    border-radius: 10px;

    @media screen and (max-width: 610px) {
      height: auto;
    }
  }
`;
