import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import validationSchema from './validationSchema';

import { FIELD_AUDIT_URL } from '../contants';

import FormTextfield, { FormTextfieldLight } from '../../common/FormTextfield';

const getInitialValues = (auditURL) => ({
  [FIELD_AUDIT_URL]: auditURL ?? '',
});

const AuditForm = forwardRef(
  ({ lightTheme, initAuditURL, onAudit, ...props }, ref) => {
    const TextFieldComponent = lightTheme ? FormTextfield : FormTextfieldLight;

    const onSubmit = useCallback(
      (values) => {
        const url = values[FIELD_AUDIT_URL];
        const isStartWithHTTP = url.startsWith('http');
        const auditURL = isStartWithHTTP ? url : `http://${url}`;
        onAudit?.(auditURL);
      },
      [onAudit],
    );

    const formik = useFormik({
      initialValues: getInitialValues(initAuditURL),
      validationSchema: validationSchema,
      onSubmit: onSubmit,
    });

    useImperativeHandle(ref, () => ({
      submitForm: formik.submitForm,
    }));

    return (
      <form>
        <TextFieldComponent
          required
          aria-required
          InputLabelProps={{ shrink: true }}
          id="url"
          name={FIELD_AUDIT_URL}
          label="Website URL"
          placeholder="http://example.com"
          type="url"
          variant="outlined"
          fullWidth
          value={formik.values[FIELD_AUDIT_URL]}
          onChange={formik.handleChange}
          error={
            formik.touched[FIELD_AUDIT_URL] &&
            Boolean(formik.errors[FIELD_AUDIT_URL])
          }
          helperText={
            formik.touched[FIELD_AUDIT_URL] && formik.errors[FIELD_AUDIT_URL]
          }
          {...props}
        />
      </form>
    );
  },
);

AuditForm.propTypes = {
  lightTheme: PropTypes.bool,
  initAuditURL: PropTypes.string,
  onAudit: PropTypes.func,
};

AuditForm.defaultProps = {
  lightTheme: false,
  initAuditURL: null,
  onAudit: null,
};

export default AuditForm;
