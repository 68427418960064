import React from 'react';
import { useScrollToTopOnMount } from '../hooks';
import { AuditReport } from '../components/aria-audit/audit-report';
import { NotificationProvider } from '../context/notifications-context';
import '../index.css';
import { Issue, Scan } from '@equally-ai-front/common/src/types/issues';

interface AriaProps {
  allowNonBusiness?: boolean;
  getIssues: (scan: Scan) => Promise<Issue[]>;
  onStartFreeTrialClick: () => void;
  createNotification: (text: string, variant: 'error' | 'success') => void;
  performAudit: (
    url: string,
    businessId: number,
    websiteId?: number,
  ) => Promise<{ scan: any; isSuccess: boolean }>;
  loading: boolean;
  scans: Scan[];
  validateBeforeAudit?: () => boolean;
  validateBeforeAuditCallback?: () => void;
  isAdmin?: boolean;
}

export const AriaContainer = (props: AriaProps) => {
  const {
    allowNonBusiness,
    onStartFreeTrialClick,
    createNotification,
    performAudit,
    loading,
    scans,
    validateBeforeAudit,
    validateBeforeAuditCallback,
    getIssues,
    isAdmin,
  } = props;

  useScrollToTopOnMount();

  return (
    <NotificationProvider createNotification={createNotification}>
      <AuditReport
        performAudit={performAudit}
        isLoading={loading}
        getIssues={getIssues}
        scans={scans}
        allowNonBusiness={allowNonBusiness}
        onStartFreeTrialClick={onStartFreeTrialClick}
        validateBeforeAudit={validateBeforeAudit}
        validateBeforeAuditCallback={validateBeforeAuditCallback}
        isAdmin={isAdmin}
      />
    </NotificationProvider>
  );
};
