import { getBusinesses } from '@equally-ai-front/common/src/redux/business-slice/business';
import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from '../providers/auth-context';
import { AuthContainer } from './auth-container/auth-container';
import { BusinessRedirect } from './business-redirect';
import { BusinessFormContainer } from './on-boarding-container/on-boarding-container';
import { ProtectedRoute } from './protected-route';
import { SettingsRedirect } from './settings-redirect';
import { getWidgetDomains } from '@equally-ai-front/common/src/redux/user-products-slice/user-products';

export const AppRedirect = () => {
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const businesses = useSelector((state: any) => state.business.businesses);
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));

  const dispatch = useDispatch();

  const { isAuthenticated, verifyAuthentication } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const isVerifiedAuthenticationRef = useRef(false);

  useEffect(() => {
    if (isVerifiedAuthenticationRef.current) {
      return;
    }
    const verifyAuth = async () => {
      await verifyAuthentication();
      isVerifiedAuthenticationRef.current = true;
      setIsLoading(false);
    };

    void verifyAuth();
  }, [verifyAuthentication, isVerifiedAuthenticationRef]);

  useEffect(() => {
    if (!isAuthenticated || !personalDetails || businesses) {
      return;
    }

    // @ts-ignore
    dispatch(getBusinesses());
  }, [businesses, dispatch, personalDetails, isAuthenticated]);

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }
    // @ts-ignore
    dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
  }, [currentBusiness, dispatch]);

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '60vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {isAuthenticated ? undefined : <AuthContainer />}

          <Routes>
            {businesses && personalDetails?.id && (
              <Route
                path={`:businessSlug?/*`}
                element={
                  <ProtectedRoute>
                    <BusinessRedirect />
                  </ProtectedRoute>
                }
              />
            )}
            <Route
              path={`settings/*`}
              element={
                <ProtectedRoute>
                  <SettingsRedirect />
                </ProtectedRoute>
              }
            />
            <Route
              path={`on-boarding/*`}
              element={
                <ProtectedRoute>
                  <BusinessFormContainer />
                </ProtectedRoute>
              }
            />
          </Routes>
        </>
      )}
    </>
  );
};
