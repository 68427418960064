import React from 'react';

import styled from '@emotion/styled';
import { isNullOrUndefined } from '@equally-ai-front/common/src';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { isUserPermitted } from '../../../../lib/permissions';
import {
  TypographyH1,
  TypographyH2,
  TypographyH3,
} from '../../../common/styled';
import { ActionButton, FormContainer } from './styled';
import { useBookADemo } from '@equally-ai-front/common/src/hooks/use-book-a-demo';

interface SelectProductProps {
  selectedProduct: undefined | string;
  onSelectProduct: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitType: () => void;
}
const COLUMNS = [
  {
    name: 'widget',
    label: 'Accessibility Widget',
    description: 'Effortless website accessibility.',
  },
  {
    name: 'flowy',
    label: 'Flowy',
    description:
      'Control and customise your accessibility using our dashboard and extension.',
  },
  {
    name: 'service',
    label: 'Services',
    description: 'V-PAT, Audit, PDFs, and more',
  },
];

export const SelectProduct = ({
  selectedProduct,
  onSelectProduct,
  onSubmitType,
}: SelectProductProps) => {
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const { handleBookDemo } = useBookADemo();

  const isUserPermittedToAddDevDomain = isUserPermitted(
    currentBusiness.id,
    ['write.flowy_page'],
    personalDetails,
  );
  const columns = COLUMNS.map((column, index) => {
    return (
      <BoxContainer key={`box-container-${column.name}-${index}`}>
        <StyledControlLabel
          key={column.name}
          value={column.name}
          control={<StyledRadio inputProps={{ tabIndex: -1 }} />}
          tabIndex={0}
          label={
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection={'column'}
              gridGap="3px"
            >
              <BoxHeader>{column.label}</BoxHeader>
              <BoxSubHeader>{column.description}</BoxSubHeader>
              {!isUserPermittedToAddDevDomain && (
                <ComingSoonChip>Coming soon 🎉</ComingSoonChip>
              )}
            </Box>
          }
        />
      </BoxContainer>
    );
  });

  return (
    <StyledContainer>
      <StyledHeader>
        <MainHeader>Choose your accessibility solution</MainHeader>
      </StyledHeader>
      <ContentContainer>
        <StyledRadioGroup
          aria-label="select a solution"
          name="product type"
          value={selectedProduct}
          onChange={onSelectProduct}
        >
          {columns}
        </StyledRadioGroup>
      </ContentContainer>
      {selectedProduct !== 'service' ? (
        <StyledButton
          onClick={onSubmitType}
          disabled={isNullOrUndefined(selectedProduct)}
        >
          Next
        </StyledButton>
      ) : (
        <StyledButton
          onClick={() =>
            handleBookDemo({
              bookADemoLink: 'https://calendly.com/equally-ai/solutions',
              productType: 'widget',
              source: 'user panel',
              businessId: currentBusiness?.id,
            })
          }
        >
          Schedule a meeting
        </StyledButton>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(FormContainer)`
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: center;
  }
`;

const StyledHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 20px;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  // padding: 20px;
  // border-radius: 8px;
  // border: 1px solid #eee;
  max-width: 550px;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 0 auto 20px;
    flex-wrap: nowrap;
    min-height: auto;
  }
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
`;
const StyledControlLabel = styled(StyledFormControlLabel)`
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 15px;
  height: 100px;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

const StyledRadio = styled(Radio)`
  padding: 3px 0 0;
  margin-right: 9px;
  & input:checked + div .MuiSvgIcon-root {
    fill: #454284;
  }
`;

const StyledButton = styled(ActionButton)`
  width: 90%;
  text-align: center;
  font-size: 17px;

  &:not(:disabled) {
    background: #454284;
    color: #fff;
  }

  &:disabled {
    color: #000a14;
  }
`;

const StyledLink = styled(RouterLink)`
  width: 90%;
  display: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  height: 48px;
  font-size: 16px;
  line-height: 150%;
  text-transform: none;
  border-radius: 8px;

  &:not(:disabled) {
    background: #454284;
    color: #fff;
  }
`;

const ComingSoonChip = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 8px;
  color: #fff;
  background-color: #454284;
  position: absolute;
  right: 12px;
  top: 12px;
`;

const MainHeader = styled(TypographyH1)`
  font-size: 24px;
`;
const BoxHeader = styled(TypographyH2)`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.15px;
`;
const BoxSubHeader = styled(TypographyH3)`
  font-weight: 300;
  font-size: 16px;
  color: #282828;
`;

const ContentContainer = styled(Box)`
  //border-radius: 8px;
  //border: 1px solid #eee;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const BoxContainer = styled(Box)`
  margin: 5px 0;
  width: 100%;
  max-width: 90%;
  display: flex;
  justify-content: center;
`;
