import { getIssuesCategoriesCount } from '@equally-ai-front/common/src/lib';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { capitalizeFirstLetter } from '@equally-ai-front/common/src/utils/helpers';
import { Box, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import {
  IssuesCategoriesCountWrapper,
  IssuesCategoryCountWrapper,
  IssuesCategoryCountView,
  IssuesCategoryCount,
  IssuesCategory,
} from './styles';

interface IssuesSummaryByCategoryProps {
  report: ReportData;
  title: string;
}

export const IssuesSummaryByCategory = (
  props: IssuesSummaryByCategoryProps,
) => {
  const { report, title } = props;
  const issuesCategoriesCount = getIssuesCategoriesCount(
    report.reportGroups.all,
  );

  return (
    <Wrapper>
      <Title variant="h3">{title}</Title>
      <IssuesCategoryWrapper>
        <IssuesCategoriesCountWrapper>
          {issuesCategoriesCount.map(([category, count]) => (
            <IssuesCategoryCountWrapper>
              <IssuesCategoryCountView>
                <IssuesCategoryCount variant="body1">
                  {count}
                </IssuesCategoryCount>
              </IssuesCategoryCountView>
              <IssuesCategory variant="body1">
                {capitalizeFirstLetter(category)}
              </IssuesCategory>
            </IssuesCategoryCountWrapper>
          ))}
        </IssuesCategoriesCountWrapper>
      </IssuesCategoryWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 537px;
  height: 352px;

  @media screen and (max-width: 650px) {
    width: 100%;
    height: fit-content;
  }
`;

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: #000a14d9;
  width: 335px;
  height: 36px;

  @media screen and (max-width: 890px) {
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }
`;

const IssuesCategoryWrapper = styled(Box)`
  margin-top: 20px;
`;
