import { SET_PERSONAL_DETAILS } from '../actionTypes';

const initialState = {
  personalDetails: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PERSONAL_DETAILS:
      return { ...state, personalDetails: action.value };
    default: {
      return state;
    }
  }
}
