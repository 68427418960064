import {
  removeLeadingSlash,
  removeSlashAtUrlEnd,
} from '@equally-ai-front/common/src/lib';
import { NewScanInfo, Scan } from '@equally-ai-front/common/src/types/issues';
import { getUrlWithoutHttpPrefixAndWWW } from '@equally-ai-front/common/src/utils/helpers';
import React, { useCallback } from 'react';
import { isScanLoading } from '../lib/scans';
import { RootState } from '../store';
import { setApiError, setApiSuccessMessage } from '../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useAnalytics } from '@equally-ai-front/common/src';
import { useAutomaticScan } from '@equally-ai-front/common/src/hooks/use-automatic-scan';
import { getScanHistory } from '@equally-ai-front/common/src/redux/historySlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { settingsActions } from '@equally-ai-front/common/src/redux/settings-slice';

interface UseAuditReturnData {
  scans: Scan[];
  onAudit: (
    urlPathname: string,
    cb?: () => void,
    scanDetails?: NewScanInfo,
  ) => void;
}
export const useAudit = (): UseAuditReturnData => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { currentBusiness, scannedUrls, currentDevDomain } = useSelector(
    (state: RootState) => ({
      currentBusiness: state.business.currentBusiness,
      scannedUrls: state.dashboard.scannedUrls,
      currentDevDomain: state.dashboard.currentDevDomain,
    }),
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const pageUrl = searchParams.get('page_url');

  const onAuditError = (message: string) => {
    dispatch(setApiError(message));
  };

  const { performAudit, scans } = useAutomaticScan({
    onError: onAuditError,
  });

  const onAudit = useCallback(
    (urlPathname: string, cb?: () => void, scanDetails?: NewScanInfo) => {
      if (!currentDevDomain || !currentBusiness) {
        return;
      }
      const executeAudit = async () => {
        const inputUrls = urlPathname
          .split(';')
          .map((url) => url.trim())
          .filter((url) => url.length > 0);
        if (inputUrls.length === 0) {
          return;
        }

        const urlsToScan = [];
        for (const inputUrl of inputUrls) {
          const newUrl = removeSlashAtUrlEnd(
            `${currentDevDomain.name}/${removeLeadingSlash(inputUrl)}`,
          );
          const isDomainPathCurrentlyScanning = scannedUrls.find((scan) => {
            const { domain } = scan;
            return (
              domain === getUrlWithoutHttpPrefixAndWWW(newUrl) &&
              isScanLoading(scan)
            );
          });
          if (!isDomainPathCurrentlyScanning) {
            urlsToScan.push(newUrl);
          }
          analytics.track('Add Page Template Clicked', { url: newUrl });
          if (
            !newUrl.toLowerCase().includes(currentDevDomain.name.toLowerCase())
          ) {
            analytics.track('Flowy Report Requested', {
              url: newUrl,
              status: 'failed',
              reason: 'You are unauthorized to scan this domain',
            });
            dispatch(setApiError('You are unauthorized to scan this domain'));
            return;
          }
        }

        const { isSuccess } = await performAudit(
          urlsToScan.join(';'),
          currentBusiness.id,
          currentDevDomain.id,
          scanDetails,
        );
        if (!isSuccess) {
          dispatch(setApiError('Failed to perform audit'));
          return;
        }

        dispatch(setApiSuccessMessage('Audit has been initiated'));
        dispatch(
          // @ts-ignore
          getScanHistory({
            businessId: currentBusiness.id,
            websiteIds: [currentDevDomain.id],
          }),
        );
        cb && cb();
        if (pageUrl) {
          navigate(
            `/${currentBusiness.slug}/developers/${currentDevDomain.id}/flowy/scans`,
          );
        }
      };
      void executeAudit();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      performAudit,
      settingsActions,
      dispatch,
      currentBusiness,
      currentDevDomain,
      location,
      scannedUrls,
      pageUrl,
    ],
  );

  return { scans, onAudit };
};
