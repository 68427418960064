import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

interface DomainInstallationNextStepsOption {
  label: string;
  value: string;
  handleClick: () => void;
}

interface DomainInstallationNextStepProps {
  handleInviteTeamMember: () => void;
  domainInstallationNextStepOptions: DomainInstallationNextStepsOption[];
}

export const DomainInstallationNextStep = (
  props: DomainInstallationNextStepProps,
) => {
  const { handleInviteTeamMember, domainInstallationNextStepOptions } = props;
  const [open, setOpen] = useState(false);
  const [domainInstallationNextStep, setDomainInstallationNextStep] = useState({
    label: 'Invite Team Member',
    value: 'invite-team-member',
    handleClick: handleInviteTeamMember,
  });
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (evt: Event) => {
    if (
      anchorRef.current &&
      evt.target instanceof HTMLElement &&
      anchorRef.current.contains(evt.target)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleSelectDomainInstallationNextStep = (
    option: DomainInstallationNextStepsOption,
  ) => {
    setDomainInstallationNextStep(option);
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Domain installation next steps split button"
        sx={{
          borderWidth: 2,
          marginTop: '15px',
          justifyContent: 'flex-end',

          '& .MuiButtonGroup-grouped': {
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: '#454284',

            '&:hover': {
              borderWidth: 2,
              borderStyle: 'solid',
              backgroundColor: 'transparent',
              borderColor: '#454284',
            },
          },
        }}
      >
        <Button
          onClick={domainInstallationNextStep.handleClick}
          sx={{
            whiteSpace: 'nowrap',
            padding: '5px 10px',
            textTransform: 'capitalize',
          }}
        >
          {domainInstallationNextStep.label}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select domain installation next step"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{ borderLeft: 'none' }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'right bottom' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {domainInstallationNextStepOptions.map(
                    (domainInstallationNextStepOption) => {
                      const { value, label } = domainInstallationNextStepOption;

                      return (
                        <MenuItem
                          key={value}
                          selected={domainInstallationNextStep.value === value}
                          onClick={() => {
                            handleSelectDomainInstallationNextStep(
                              domainInstallationNextStepOption,
                            );
                          }}
                        >
                          {label}
                        </MenuItem>
                      );
                    },
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
