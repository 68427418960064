import { Box, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../assets/check-bg-transparent.svg';
import { ReactComponent as CurvedArrowIcon } from '../../assets/blue-curved-arrow.svg';

const whatCustomersGet: string[] = [
  'Solutions for WCAG, ADA, Section 508',
  'Certified attorney accessibility statement',
  'Notifications for critical source code issues',
  'Unlimited pages',
  'Routine compliance checks',
  'Accessibility statement badge',
];

export const WhatCustomersGet = () => {
  return (
    <Wrapper>
      <Header>
        <Title variant="h3">All Customers Get</Title>
        <CurvedArrowWrapper>
          <CurvedArrowIcon />
        </CurvedArrowWrapper>
      </Header>

      <WhatCustomersGetWrapper>
        {whatCustomersGet.map((text, tidx) => (
          <WhatCustomersGetContent key={`${text}-${tidx}`}>
            <CheckIconWrapper>
              <CheckIcon />
            </CheckIconWrapper>
            <WhatCustomersGetText variant="body1">{text}</WhatCustomersGetText>
          </WhatCustomersGetContent>
        ))}
      </WhatCustomersGetWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1224px;
  margin: 75px auto;

  @media screen and (max-width: 1300px) {
    max-width: 85%;
  }

  @media screen and (max-width: 1130px) {
    max-width: 95%;
  }
`;

const Header = styled(Box)`
  position: relative;
  width: fit-content;
  margin: auto;
`;

const Title = styled(Typography)`
  width: fit-content;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  height: 48px;
  color: #000a14;
  margin: auto;

  @media screen and (max-width: 590px) {
    font-size: 30px;
    line-height: 45px;
  }

  @media screen and (max-width: 450px) {
    font-size: 25px;
    line-height: 37.5px;
  }
`;

const CurvedArrowWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: -105px;

  @media screen and (max-width: 590px) {
    transform: scale(0.8);
    top: -10px;
    right: -100px;
  }

  @media screen and (max-width: 450px) {
    transform: scale(0.6);
    top: -20px;
    right: -85px;
  }
`;

const WhatCustomersGetWrapper = styled(Box)`
  background-color: #f5f7fa;
  border-radius: 16px;
  padding: 48px 96px;
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 1080px) {
    padding: 48px;
  }

  @media screen and (max-width: 590px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 5px;
  }
`;

const WhatCustomersGetContent = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 45%;

  &:nth-last-child(1),
  &:nth-last-child(2) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &:nth-last-child(2) {
      margin-bottom: 24px;
    }
  }
`;

const CheckIconWrapper = styled(Box)``;

const WhatCustomersGetText = styled(Typography)`
  margin-left: 16px;
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000a14d9;
`;
