import {
  ADD_DEV_DOMAIN,
  ADD_DEV_DOMAIN_SUCCESS,
  ADD_DOMAIN,
  ADD_DOMAIN_KEY,
  ADD_DOMAIN_KEY_SUCCESS,
  ADD_DOMAIN_SUCCESS,
  ADD_DOMAIN_TO_PLANS_REQUEST,
  ADD_DOMAIN_TO_PLANS_SUCCESS,
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  API_ERROR,
  API_SUCCESS,
  CANCEL_PLAN,
  CANCEL_SUBSCRIPTION,
  CLEAR_COMPLIANCE,
  DELETE_DOMAIN,
  DELETE_DOMAIN_KEY,
  DELETE_DOMAIN_KEY_SUCCESS,
  DELETE_DOMAIN_SUCCESS,
  EDIT_DOMAIN,
  EDIT_DOMAIN_KEY,
  EDIT_DOMAIN_KEY_SUCCESS,
  EDIT_DOMAIN_SUCCESS,
  GET_ACCESS,
  GET_ACCESS_SUCCESS,
  GET_ACTIVE_PLANS,
  GET_ACTIVE_PLANS_SUCCESS,
  GET_ACTIVE_SUBSCRIPTIONS,
  GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  GET_ALL_INVITES_SUCCESS,
  GET_ALL_PLANS,
  GET_ALL_PLANS_SUCCESS,
  GET_API_SAMPLE_CODE,
  GET_API_SAMPLE_CODE_REDESIGN,
  GET_API_SAMPLE_CODE_SUCCESS,
  GET_APPEARANCE,
  GET_APPEARANCE_SUCCESS,
  GET_BILLING_PROFILE_REQUEST,
  GET_BILLING_PROFILE_SUCCESS,
  GET_DEV_DOMAINS,
  GET_DEV_DOMAINS_SUCCESS,
  GET_DOMAINS,
  GET_DOMAINS_SUCCESS,
  GET_DOMAIN_KEYS,
  GET_DOMAIN_KEYS_SUCCESS,
  GET_INACTIVE_PLANS,
  GET_INACTIVE_PLANS_SUCCESS,
  GET_INACTIVE_SUBSCRIPTIONS,
  GET_INACTIVE_SUBSCRIPTIONS_SUCCESS,
  GET_MORE_DOMAINS,
  GET_PLANS,
  GET_PRICES_REQUEST,
  GET_PRICES_SUCCESS,
  RESET_DOMAIN_KEYS,
  SEND_INVITE,
  SEND_INVITE_SUCCESS,
  SET_PERSONAL_DETAILS,
  SET_TOKEN,
  TAB_CHANGED,
  UPDATE_ACCESS,
  UPDATE_APPEARANCE,
  UPDATE_BILLING_PROFILE_REQUEST,
  UPDATE_BILLING_PROFILE_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  VERIFY_COMPLIANCE,
  VERIFY_COMPLIANCE_LOADING,
  VERIFY_COMPLIANCE_SUCCESS,
  VERIFY_INSTALLATION_SUCCESS,
} from './actionTypes';

export const clearCompliance = (value) => {
  return {
    type: CLEAR_COMPLIANCE,
    value,
  };
};

export const verifyCompliance = (value) => {
  return {
    type: VERIFY_COMPLIANCE,
    value,
  };
};

export const verifyComplianceLoading = () => {
  return {
    type: VERIFY_COMPLIANCE_LOADING,
  };
};

export const verifyComplianceSuccess = (value) => {
  return {
    type: VERIFY_COMPLIANCE_SUCCESS,
    value,
  };
};

export const verifyInstallationSuccess = (value) => {
  return {
    type: VERIFY_INSTALLATION_SUCCESS,
    value,
  };
};

export const getSampleCode = (domain, key, businessId) => {
  return {
    type: GET_API_SAMPLE_CODE,
    domain,
    key,
    businessId,
  };
};

export const getSampleCodeRedesign = (value, businessId) => {
  return {
    type: GET_API_SAMPLE_CODE_REDESIGN,
    value,
    businessId,
  };
};

export const getSampleCodeSuccess = (value) => {
  return {
    type: GET_API_SAMPLE_CODE_SUCCESS,
    value,
  };
};

export const changeTab = (value) => {
  return {
    type: TAB_CHANGED,
    value,
  };
};

export const setToken = (value) => {
  return {
    type: SET_TOKEN,
    value,
  };
};

export const getDomains = (value) => {
  return {
    type: GET_DOMAINS,
    value,
  };
};

export const getMoreDomains = (value) => {
  return {
    type: GET_MORE_DOMAINS,
    value,
  };
};

export const getActiveSubs = () => {
  return {
    type: GET_ACTIVE_SUBSCRIPTIONS,
  };
};

export const cancelSub = (value) => {
  return {
    type: CANCEL_SUBSCRIPTION,
    value,
  };
};

export const getActiveSubsSuccess = (value) => {
  return {
    type: GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
    value,
  };
};

export const getInactiveSubs = () => {
  return {
    type: GET_INACTIVE_SUBSCRIPTIONS,
  };
};

export const getInactiveSubsSuccess = (value) => {
  return {
    type: GET_INACTIVE_SUBSCRIPTIONS_SUCCESS,
    value,
  };
};

export const addSub = (value) => {
  return {
    type: ADD_SUBSCRIPTION,
    value,
  };
};

export const addSubSuccess = (value) => {
  return {
    type: ADD_SUBSCRIPTION_SUCCESS,
    value,
  };
};

export const updateSub = (value) => {
  return {
    type: UPDATE_SUBSCRIPTION,
    value,
  };
};

export const updateSubSuccess = (value) => {
  return {
    type: UPDATE_SUBSCRIPTION_SUCCESS,
    value,
  };
};

export const getDomainsSuccess = (value) => {
  return {
    type: GET_DOMAINS_SUCCESS,
    value,
  };
};

export const createDomain = (value, callback, businessId) => {
  return {
    type: ADD_DOMAIN,
    value,
    callback,
    businessId,
  };
};

export const createDomainSuccess = (value) => {
  return {
    type: ADD_DOMAIN_SUCCESS,
    value,
  };
};

export const setApiError = (value) => {
  return {
    type: API_ERROR,
    value,
  };
};

export const setApiSuccessMessage = (value, autoHide) => {
  return {
    type: API_SUCCESS,
    value,
    autoHide,
  };
};

export const editDomain = (value) => {
  return {
    type: EDIT_DOMAIN,
    value,
  };
};

export const editDomainSuccess = (value) => {
  return {
    type: EDIT_DOMAIN_SUCCESS,
    value,
  };
};

export const deleteDomain = (value) => {
  return {
    type: DELETE_DOMAIN,
    value,
  };
};

export const deleteDomainSuccess = (value) => {
  return {
    type: DELETE_DOMAIN_SUCCESS,
    value,
  };
};

export const getDomainKeys = (value, businessId) => {
  return {
    type: GET_DOMAIN_KEYS,
    value,
    businessId,
  };
};

export const getDomainKeyssSuccess = (value) => {
  return {
    type: GET_DOMAIN_KEYS_SUCCESS,
    value,
  };
};

export const createDomainKey = (domain, key) => {
  return {
    type: ADD_DOMAIN_KEY,
    domain,
    key,
  };
};

export const createDomainKeySuccess = (value) => {
  return {
    type: ADD_DOMAIN_KEY_SUCCESS,
    value,
  };
};

export const editDomainKey = (value) => {
  return {
    type: EDIT_DOMAIN_KEY,
    value,
  };
};

export const editDomainKeySuccess = (value) => {
  return {
    type: EDIT_DOMAIN_KEY_SUCCESS,
    value,
  };
};

export const deleteDomainKey = (domain, key) => {
  return {
    type: DELETE_DOMAIN_KEY,
    domain,
    key,
  };
};

export const deleteDomainKeySuccess = (value) => {
  return {
    type: DELETE_DOMAIN_KEY_SUCCESS,
    value,
  };
};
export const resetDomainKey = () => {
  return {
    type: RESET_DOMAIN_KEYS,
  };
};

export const getPrices = (value) => {
  return {
    type: GET_PRICES_REQUEST,
    value,
  };
};

export const getPricesSuccess = (value) => {
  return {
    type: GET_PRICES_SUCCESS,
    value,
  };
};

export const addTransaction = (value) => {
  return {
    type: ADD_TRANSACTION_REQUEST,
    value,
  };
};

export const addTransactionSuccess = (value) => {
  return {
    type: ADD_TRANSACTION_SUCCESS,
    value,
  };
};

export const getBillingProfile = () => {
  return {
    type: GET_BILLING_PROFILE_REQUEST,
  };
};

export const getBillingProfileSuccess = (value) => {
  return {
    type: GET_BILLING_PROFILE_SUCCESS,
    value,
  };
};

export const updateBillingProfile = (value) => {
  return {
    type: UPDATE_BILLING_PROFILE_REQUEST,
    value,
  };
};

export const updateBillingProfileSuccess = (value) => {
  return {
    type: UPDATE_BILLING_PROFILE_SUCCESS,
    value,
  };
};

export const getAppearance = (value) => {
  return {
    type: GET_APPEARANCE,
    value,
  };
};

export const getAppearanceSuccess = (value) => {
  return {
    type: GET_APPEARANCE_SUCCESS,
    value,
  };
};

export const updateAppearance = (businessId, domain, value, successMessage) => {
  return {
    type: UPDATE_APPEARANCE,
    businessId,
    domain,
    value,
    successMessage,
  };
};

export const getAccess = (value) => {
  return {
    type: GET_ACCESS,
    value,
  };
};

export const getAccessSuccess = (value) => {
  return {
    type: GET_ACCESS_SUCCESS,
    value,
  };
};

export const updateAccess = (domain, value) => {
  return {
    type: UPDATE_ACCESS,
    domain,
    value,
  };
};

export const sendInvite = (userInfo) => {
  return {
    type: SEND_INVITE,
    userInfo,
  };
};

export const sendInviteSuccess = (value) => {
  return {
    type: SEND_INVITE_SUCCESS,
    value,
  };
};

export const getAllInvitesSuccess = (value) => {
  return {
    type: GET_ALL_INVITES_SUCCESS,
    value,
  };
};

export const getDevDomains = (businessId) => {
  return {
    type: GET_DEV_DOMAINS,
    businessId,
  };
};

export const getDevDomainsSuccess = (value) => {
  return {
    type: GET_DEV_DOMAINS_SUCCESS,
    value,
  };
};

export const createDevDomain = (value) => {
  return {
    type: ADD_DEV_DOMAIN,
    value,
  };
};

export const setDevDomainMsg = (value) => {
  return {
    type: ADD_DEV_DOMAIN_SUCCESS,
    value,
  };
};

export const setPersonalDetails = (value) => {
  return {
    type: SET_PERSONAL_DETAILS,
    value,
  };
};

export const getPlans = () => {
  return {
    type: GET_PLANS,
  };
};

export const getAllPlans = (businessId) => {
  return {
    type: GET_ALL_PLANS,
    businessId,
  };
};

export const getAllPlansSuccess = (value) => {
  return {
    type: GET_ALL_PLANS_SUCCESS,
    value,
  };
};

export const getActivePlans = (value) => {
  return {
    type: GET_ACTIVE_PLANS,
    value,
  };
};

export const getActivePlansSuccess = (value) => {
  return {
    type: GET_ACTIVE_PLANS_SUCCESS,
    value,
  };
};

export const getInactivePlans = (businessId) => {
  return {
    type: GET_INACTIVE_PLANS,
    businessId,
  };
};

export const getInactivePlansSuccess = (value) => {
  return {
    type: GET_INACTIVE_PLANS_SUCCESS,
    value,
  };
};

export const addDomainsToPlans = (value) => {
  return {
    type: ADD_DOMAIN_TO_PLANS_REQUEST,
    value,
  };
};

export const addDomainsToPlansSuccess = () => {
  return {
    type: ADD_DOMAIN_TO_PLANS_SUCCESS,
  };
};

export const cancelPlan = (value) => {
  return {
    type: CANCEL_PLAN,
    value,
  };
};
