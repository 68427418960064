import { getIssueGroupName } from '@equally-ai-front/common/src/lib';
import { ScanType } from '@equally-ai-front/common/src/types/scans';
import React, { useRef, useState } from 'react';
import { CreateScanButton } from '../../../containers/dashboard-container/components/create-scan-button';
import { Scan } from '@equally-ai-front/common/src/types/issues';
import { EmptyManualScans } from '../../../containers/dashboard-container/components/empty-manual-scans';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { sortScans } from '@equally-ai-front/common/src/lib/scans';
import {
  CustomLiText,
  DomainCustomLi,
  DomainCustomUL,
  PageListTitle,
  PagesListContainer,
} from '../common/pages-list-container';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/styles';
import { OutlinedInput } from '@mui/material';
import { PrimaryButton } from '../../redesign/Controls';
import { useScroll } from '@equally-ai-front/common/src/contexts/scroll-provider';
import { capitalizeFirstLetter } from '@equally-ai-front/common';
import { useAudit } from '../../../hooks/use-audit';
import { formatDomainInputUrl } from '../../../utils/domain.utils';

interface FlowyViewProps {
  isOnMobile: boolean;
}
const getScanHistoryListItemColor = (
  error: number,
  warning: number,
): string => {
  let color = '#AEEAD3';

  if (error > 0) {
    color = '#FF9999';
  } else if (error === 0 && warning > 0) {
    color = '#f2df87';
  }
  return color;
};

export const FlowyView = (props: FlowyViewProps) => {
  const { isOnMobile } = props;
  const navigate = useNavigate();
  const { scrollToSection } = useScroll();
  const searchParams = new URLSearchParams(location.search);
  const pageUrl = searchParams.get('page_url');
  const groupScanId = searchParams.get('group_scan_id');
  const { onAudit } = useAudit();
  const currentBusiness = useSelector(
    (state: RootState) => state.business.currentBusiness,
  );
  const getPathName = (scannedUrl: string): string => {
    if (!devDomain) return '';
    if (!scannedUrl.includes(devDomain.name)) {
      return capitalizeFirstLetter(scannedUrl);
    }
    return new URL(`https://${scannedUrl}`).pathname.replace(
      new RegExp(`^${devDomain.name}`),
      '',
    );
  };
  const [showUrlPathnameInput, setShowUrlPathnameInput] = useState(false);
  const [urlPathname, setUrlPathname] = useState('');

  const createScanButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleToggleUrlPathnameInput = () => {
    setShowUrlPathnameInput(!showUrlPathnameInput);
  };
  const handleAddNewScan = () => {
    if (!createScanButtonRef.current) return;
    createScanButtonRef.current.click();
  };

  const handleUrlPathnameChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!devDomain) return;
    const value = formatDomainInputUrl(devDomain, evt.target.value);
    setUrlPathname(value);
  };

  const { domainId: devDomainId } = useParams();
  const performAudit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!devDomain) return;
    onAudit(urlPathname, () => {
      handleToggleUrlPathnameInput();
      setUrlPathname('');

      // do this navigation if i'm not on the flowy overview route
      navigate(
        `/${currentBusiness?.slug}/developers/${devDomain.id}/flowy/scans`,
      );
    });
  };
  const navigateToScanPage = (url: string, scanId: number) => {
    // if (isOnMobile && closeDialog) {
    //   closeDialog();
    // }
    if (['header', 'footer'].includes(url)) {
      navigate(`scans/issues/${scanId}`);
    } else {
      navigate(
        `/${currentBusiness?.slug}/developers/${devDomainId}/flowy/scans/page?page_url=${encodeURIComponent(url)}`,
      );
    }
  };
  const { history, scannedUrls, visibleWcagReport, devDomain } = useSelector(
    (state: RootState) => ({
      history: state.scanHistory.history,
      scannedUrls: state.dashboard.scannedUrls,
      visibleWcagReport: state.scanHistory.visibleWcagReport,
      devDomain: state.dashboard.currentDevDomain,
    }),
  );
  const navigateToGroupPage = (id: number) => {
    // if (isOnMobile && closeDialog) {
    //   closeDialog();
    // }
    navigate(
      `/${currentBusiness?.slug}/developers/${devDomainId}/flowy/scans/group?group_scan_id=${id}`,
    );
  };
  const scanGroups = useSelector((state: any) => state.scanHistory.scanGroups);
  const filterScanHistoryByType = (type: ScanType): Scan[] => {
    const uniqueScansUrls: Record<string, Scan> = {};

    for (const scan of sortScans([...scannedUrls, ...history])) {
      const newScanDate = new Date(scan.created_at);
      const existingScanDate = new Date(uniqueScansUrls[scan.url]?.created_at);

      if (
        (!uniqueScansUrls[scan.url] && scan.type === type) ||
        newScanDate > existingScanDate
      ) {
        uniqueScansUrls[scan.url] = scan;
      }
    }
    let scans = [];
    const mainPageScan: Scan | undefined =
      uniqueScansUrls[devDomain?.name || 0];
    const footerScan: Scan | undefined = uniqueScansUrls.footer;
    const headerScan: Scan | undefined = uniqueScansUrls.header;

    for (const template of [mainPageScan, headerScan, footerScan]) {
      if (template) {
        scans.push(template);
        delete uniqueScansUrls[template.url];
      }
    }

    if (scans.length > 0) {
      scans = [...scans, ...Object.values(uniqueScansUrls)];
    } else {
      scans = [...Object.values(uniqueScansUrls)];
    }
    return scans;
  };
  return (
    <Wrapper>
      {devDomain && (
        <CreateScanButtonWrapper>
          <CreateScanButton onAudit={onAudit} devDomain={devDomain} />
        </CreateScanButtonWrapper>
      )}
      {visibleWcagReport && visibleWcagReport.reportGroups.all.length > 0 && (
        <PagesListContainer ismobile={isOnMobile ? isOnMobile.toString() : ''}>
          <PageListTitle>Issues Groups</PageListTitle>
          <DomainCustomUL
            isWebsiteUrlPresent
            ismobile={isOnMobile ? isOnMobile.toString() : ''}
            role="list"
          >
            {visibleWcagReport.reportGroups.all.map(
              ([groupTitle, _], vwridx) => {
                const issueGroupTitle = getIssueGroupName(groupTitle);

                return (
                  <DomainCustomLi
                    role="listitem"
                    tabIndex={0}
                    key={`visible-wcag-report-${vwridx}`}
                    ismobile={isOnMobile ? isOnMobile.toString() : ''}
                  >
                    <IssueHeaderLink
                      onClick={() => {
                        scrollToSection(issueGroupTitle);
                      }}
                    >
                      {issueGroupTitle}
                    </IssueHeaderLink>
                  </DomainCustomLi>
                );
              },
            )}
          </DomainCustomUL>
        </PagesListContainer>
      )}
      {Object.values(scanGroups).length > 0 && (
        <PagesListContainer ismobile={isOnMobile ? isOnMobile.toString() : ''}>
          <PageListTitle>Group</PageListTitle>
          <DomainCustomUL
            isWebsiteUrlPresent
            ismobile={isOnMobile ? isOnMobile.toString() : ''}
            role="list"
          >
            {Object.values(scanGroups).map((scanGroup: any, sgidx) => {
              const { name, id } = scanGroup;
              return (
                <DomainCustomLi
                  role="listitem"
                  tabIndex={0}
                  key={`scan-group-${sgidx}`}
                  ismobile={isOnMobile ? isOnMobile.toString() : ''}
                  onClick={() => {
                    navigateToGroupPage(id);
                  }}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter' || evt.key === ' ') {
                      navigateToGroupPage(id);
                    }
                  }}
                >
                  <CustomLiText isActive={id === groupScanId}>
                    {name}
                  </CustomLiText>
                </DomainCustomLi>
              );
            })}
          </DomainCustomUL>
        </PagesListContainer>
      )}
      <PagesListContainer ismobile={isOnMobile ? isOnMobile.toString() : ''}>
        <DomainScanTypesWrapper>
          {history.length > 0 &&
            [ScanType.automated, ScanType.manual].map((type) => (
              <React.Fragment key={type}>
                <ScanHistoryTitleWrapper>
                  <PageListTitle>{type}</PageListTitle>
                  {type === ScanType.automated && (
                    <>
                      <CustomLiIcon
                        role="button"
                        tabIndex={0}
                        onClick={handleAddNewScan}
                        onKeyDown={(evt) => {
                          if (evt.key === 'Enter' || evt.key === ' ') {
                            handleAddNewScan();
                          }
                        }}
                        src={
                          showUrlPathnameInput
                            ? '/minus-symbol.svg'
                            : '/add-symbol.svg'
                        }
                        alt=""
                      />
                      <>
                        {devDomain && (
                          <ScanButtonPlusIconWrapper>
                            <CreateScanButton
                              onAudit={onAudit}
                              devDomain={devDomain}
                              ref={createScanButtonRef}
                            />
                          </ScanButtonPlusIconWrapper>
                        )}
                      </>
                    </>
                  )}
                </ScanHistoryTitleWrapper>
                <DomainCustomUL
                  isWebsiteUrlPresent
                  ismobile={isOnMobile ? isOnMobile.toString() : ''}
                  role="list"
                >
                  {showUrlPathnameInput && type === ScanType.automated && (
                    <DomainCustomLi
                      role="listitem"
                      bulletColor="#808080"
                      ismobile={isOnMobile ? isOnMobile.toString() : ''}
                      editing="true"
                    >
                      <>
                        <UrlInputForm onSubmit={performAudit}>
                          <MuiInput
                            type="text"
                            value={urlPathname}
                            onChange={handleUrlPathnameChange}
                            autoFocus
                          />
                          <ScanButton
                            aria-label="Scan url"
                            color="primary"
                            variant="contained"
                            type="submit"
                          >
                            Scan
                          </ScanButton>
                        </UrlInputForm>
                      </>
                    </DomainCustomLi>
                  )}

                  {filterScanHistoryByType(type).map(
                    ({ url, id, error, warning }: Scan) => {
                      const pathname = getPathName(url);

                      return (
                        <DomainCustomLi
                          role="listitem"
                          tabIndex={0}
                          key={id}
                          bulletColor={getScanHistoryListItemColor(
                            error,
                            warning,
                          )}
                          ismobile={isOnMobile ? isOnMobile.toString() : ''}
                          onClick={() => {
                            navigateToScanPage(url, id);
                          }}
                          onKeyDown={(evt) => {
                            if (evt.key === 'Enter' || evt.key === ' ') {
                              navigateToScanPage(url, id);
                            }
                          }}
                        >
                          <CustomLiText isActive={url === pageUrl}>
                            {pathname === '/' ? 'Home' : pathname}
                          </CustomLiText>
                        </DomainCustomLi>
                      );
                    },
                  )}
                </DomainCustomUL>
              </React.Fragment>
            ))}
        </DomainScanTypesWrapper>
      </PagesListContainer>
      {history.length > 0 &&
        filterScanHistoryByType(ScanType.manual).length === 0 && (
          <EmptyManualScans />
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DomainScanTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const IssueHeaderLink = styled.span`
  color: #000a14;
  word-wrap: break-word;
  font-size: 15px;
  vertical-align: text-bottom;
  margin-left: -3px;
`;

const ScanHistoryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomLiIcon = styled.img`
  cursor: pointer;
`;

const MuiInput = MuiStyled(OutlinedInput)({
  '&.MuiOutlinedInput-root': {
    width: '100%',
    maxWidth: 'calc(100% - 70px)',
  },
  '&.MuiOutlinedInput-root input': {
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: 15,
  },
  '& fieldset': {
    border: 'none',
  },
  '&:hover fieldset': {
    border: 'none',
  },
});

const UrlInputForm = styled.form`
  display: flex;
  justify-content: space-between;
`;

const ScanButton = styled(PrimaryButton)`
  height: 32px;
  width: 64px;
`;

const ScanButtonPlusIconWrapper = styled.div`
  display: none;
`;

const CreateScanButtonWrapper = styled.div`
  width: 100%;
  > button {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    max-width: 80%;
    margin: auto;
  }
`;
