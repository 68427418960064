import { CodeExampleContent } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { generateUniqueId } from '@equally-ai-front/common/src/lib';
import React from 'react';
import styled from 'styled-components';

interface RenderingExampleContentsProps {
  exampleArray: CodeExampleContent[];
}

interface WrapperProps {
  isNewCodeBlock?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  padding: 10px;
  border-radius: 10px;
  height: fit-content;
  display: ${(props) => (props.isNewCodeBlock ? 'block' : 'inline-block')};
  color: #fff;
  background-color: #000a14;
  scrollbar-color: #a8bbcf rgba(0, 0, 0, 0);
  scrollbar-width: thin;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Divider = styled.div`
  margin-top: 5%;
`;

const MainCode = styled.code`
  color: #f8f8f2;
  display: block;
  overflow-wrap: break-word;
`;

const CodeTag = styled.span`
  color: #f92672;
  font-weight: bold;
`;
const AttriubteKey = styled.span`
  color: #f92672;
`;
const AttriubteValue = styled.span`
  color: #e6db74;
`;

const CodeExample = ({
  openingTagName,
  attributes,
  content,
  closingTag,
}: CodeExampleContent) => {
  return (
    <Wrapper isNewCodeBlock>
      <MainCode>
        {openingTagName !== '' && <>&lt;</>}
        <CodeTag>{openingTagName}</CodeTag>
        {attributes.length > 0 &&
          attributes.map((attr: any) => (
            <span key={generateUniqueId()}>
              &nbsp;
              <AttriubteKey>{attr.key}</AttriubteKey>=
              <AttriubteValue>
                &quot;
                {attr.value}
                &quot;
              </AttriubteValue>
            </span>
          ))}
        {closingTag && closingTag !== '' && <span>&gt;</span>}
        {typeof content === 'string' && content !== '' ? (
          <span>{content}</span>
        ) : (
          Array.isArray(content) &&
          content.length > 0 &&
          content.map((codeContent: CodeExampleContent) => (
            <CodeExample key={generateUniqueId()} {...codeContent} />
          ))
        )}
        {closingTag ? (
          <span>
            &lt;/<CodeTag>{closingTag}</CodeTag>&gt;
          </span>
        ) : (
          closingTag !== '' && <span>&gt;</span>
        )}
      </MainCode>
    </Wrapper>
  );
};

export const RenderingExampleContents: React.FC<
  RenderingExampleContentsProps
> = ({ exampleArray }) => {
  return (
    <div>
      <CodeExamplesWrapper>
        {exampleArray.map((elem, idx) => (
          <React.Fragment key={generateUniqueId()}>
            <Wrapper>
              <MainCode>
                {elem.openingTagName !== '' && <>&lt;</>}
                <CodeTag>{elem.openingTagName}</CodeTag>
                {elem.attributes.length > 0 &&
                  elem.attributes.map((attr: any) => (
                    <span key={generateUniqueId()}>
                      &nbsp;
                      <AttriubteKey>{attr.key}</AttriubteKey>=
                      <AttriubteValue>
                        &quot;
                        {attr.value}
                        &quot;
                      </AttriubteValue>
                    </span>
                  ))}
                {elem.closingTag && elem.closingTag !== '' && <span>&gt;</span>}
                {typeof elem.content === 'string' && elem.content !== '' ? (
                  <span>{elem.content}</span>
                ) : (
                  Array.isArray(elem.content) &&
                  elem.content.length > 0 &&
                  elem.content.map((content) => (
                    <CodeExample key={generateUniqueId()} {...content} />
                  ))
                )}
                {elem.closingTag ? (
                  <span>
                    &lt;/<CodeTag>{elem.closingTag}</CodeTag>&gt;
                  </span>
                ) : (
                  elem.closingTag !== '' && <span>&gt;</span>
                )}
              </MainCode>
            </Wrapper>
            {exampleArray.length - 1 < idx && <Divider />}
          </React.Fragment>
        ))}
      </CodeExamplesWrapper>
    </div>
  );
};

const CodeExamplesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
