export const HTTP_CLIENT_ENDPOINT =
  process.env.REACT_APP_HTTP_CLIENT_ENDPOINT || '';
export const REACT_APP_TYPE = process.env.REACT_APP_REACT_APP_TYPE;
export const USER_PANEL_AUTH_URL = process.env.REACT_APP_USER_PANEL_AUTH_URL;
export const PROMO_POST_INSTALL_URL =
  process.env.REACT_APP_PROMO_POST_INSTALL_URL;
// 'http://localhost:3003/install-extension'
export const ADMIN_HTTP_CLIENT_ENDPOINT = `${process.env.REACT_APP_HTTP_CLIENT_ENDPOINT}/api/v1/adminpanel/a/`;
export const HTTP_CLIENT_REDIRECT = process.env.REACT_APP_HTTP_CLIENT_REDIRECT;
export const HTTP_GPT_ENDPOINT = process.env.REACT_APP_HTTP_GPT_ENDPOINT;
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY || '';
export const INTERCOM_WEB_IDENTIFICATION_KEY =
  process.env.REACT_APP_INTERCOM_WEB_IDENTIFICATION_KEY;

export const API_COMPLIANCE_ENDPOINT = '/api/v1/admin/a/compliance';
export const QUERY_PARAM_AUDIT = 'audit';

export const API_SCAN_HISTORY_ENDPOINT = '/api/v1/admin/a/scan-histories';

export const API_PERSONAL_DETAILS = '/api/v1/admin/a/personal-details';

export const HTTP_CLIENT_REDIRECT_DASHBOARD = 'https://' + USER_PANEL_AUTH_URL;

export const EQUALLY_AI_API_KEY =
  process.env.REACT_APP_EQUALLY_AI_API_KEY || '';
export const EQUALLY_AI_WIDGET_URL =
  process.env.REACT_APP_EQUALLY_AI_WIDGET_URL || '';
export const HTTP_CLIENT_DOMAIN =
  process.env.REACT_APP_HTTP_CLIENT_DOMAIN || '';

export const GRAPH_QL_ENDPOINT = process.env.REACT_APP_GRAPH_QL_ENDPOINT || '';
export const GRAPH_QL_API_KEY = process.env.REACT_APP_GRAPH_QL_API_KEY || '';
export const HTTP_FIREBASE_CONFIG_API_KEY =
  process.env.REACT_APP_FIREBASE_CONFIG_API_KEY || '';
export const HTTP_GOOGLE_API = process.env.REACT_APP_HTTP_GOOGLE_API || '';
