import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { Button, CircularProgress, Popover } from '@mui/material';

import { useDispatch } from 'react-redux';
import { setApiSuccessMessage } from '../../store/actions';
import { IconButton, PrimaryButton } from './Controls';
import CopyIcon from '../../img/copy-btn.png';
import { ArrowDropDown } from '@mui/icons-material';

interface CopyWidgetKeyAndCodeProps {
  code: string;
  apiKey: string;
  domain: string;
}

const CopyWidgetKeyAndCode = ({
  code,
  apiKey,
  domain,
}: CopyWidgetKeyAndCodeProps) => {
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  const [copyMode, setCopyMode] = useState<'code' | 'apiKey'>('code');

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const currentTarget: Element = event.currentTarget as Element;

    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'select-option-menu' : undefined;

  const handleSelectCopyOption = (option: 'code' | 'apiKey') => {
    setCopyMode(option);
    handleClose();
  };

  const copyCodeToClipboard = (content: string) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(content);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = code;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      textArea.remove();
    }

    const successText =
      copyMode === 'apiKey' ? 'API key copied!' : 'HTML code copied!';

    dispatch(setApiSuccessMessage(successText));

    if (domain) {
      analytics.track('Installation Pop Up Option Clicked', {
        option: 'copy-code-to-clipboard',
        url: domain,
        id: '',
      });
    }
  };

  const handleCopyToClipboard = () => {
    copyCodeToClipboard(copyMode === 'code' ? code : apiKey);
  };

  return (
    <div className="add-website-fields">
      <div className="copy-website-code-container">
        <div className="copy-website-code">
          {code && (
            <div
              className="copy-website-code-text"
              style={{ textAlign: 'center' }}
            >
              {copyMode === 'code' ? code : apiKey}
            </div>
          )}
          {!code && (
            <CenterBlock style={{ width: '100%' }}>
              <CircularProgress />
            </CenterBlock>
          )}
        </div>
        <div style={{ width: '100%', position: 'relative' }}>
          {copyMode === 'code' ? (
            <PrimaryButton
              color="primary"
              variant="contained"
              onClick={handleCopyToClipboard}
              style={{ width: '100%', borderRadius: '0 0 8px 8px' }}
            >
              <div className="copy-code-button-container">
                <img
                  className="copy-website-code-ico-btn"
                  alt="copy code"
                  src={CopyIcon}
                />
                <div>Copy HTML code</div>
              </div>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              color="primary"
              variant="contained"
              onClick={handleCopyToClipboard}
              style={{ width: '100%', borderRadius: '0 0 8px 8px' }}
            >
              <div className="copy-code-button-container">
                <img
                  className="copy-website-code-ico-btn"
                  alt="copy code"
                  src={CopyIcon}
                />
                <div>Copy API key</div>
              </div>
            </PrimaryButton>
          )}
          <StyledIconButton
            aria-describedby={id}
            onClick={handleClick}
            aria-label="Copy options"
          >
            <ArrowDropDown />
          </StyledIconButton>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PopoverContainer>
            <Button
              variant="text"
              color="primary"
              onClick={() => handleSelectCopyOption('code')}
              disabled={copyMode === 'code'}
            >
              Copy HTML Code
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => handleSelectCopyOption('apiKey')}
              disabled={copyMode === 'apiKey'}
            >
              Copy API key
            </Button>
          </PopoverContainer>
        </Popover>
      </div>
    </div>
  );
};

export default CopyWidgetKeyAndCode;

const CenterBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  border: none;
  right: 5px;
  top: 4px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  z-index: 9999;
  color: #fff;

  &:hover {
    background: #fff;
    color: #454284;
  }
`;

const PopoverContainer = styled.div`
  margin: 10px 15px;
  display: flex;
  flex-direction: column;

  & button {
    text-transform: initial;
    justify-content: flex-start;
  }
`;
