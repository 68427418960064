import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';
import React from 'react';
import { PAYPAY_CLIENT_ID } from '../common/magicValues';

const initialOptions = {
  'client-id': PAYPAY_CLIENT_ID,
  currency: 'USD',
  components: 'buttons',
  vault: true,
};

const PaypalProvider = ({ children }) => {
  return (
    <PayPalScriptProvider options={initialOptions}>
      {children}
    </PayPalScriptProvider>
  );
};

PaypalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaypalProvider;
