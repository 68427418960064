import qs from 'query-string';

import {
  HTTP_CLIENT_REDIRECT,
  QUERY_PARAM_AUDIT,
  QUERY_PARAM_PROMO,
} from '../magicValues';

export const getURLQuery = () => qs.parse(window.location.search);

export const buildURL = ({ url, searchParams = {} }) => {
  return qs.stringifyUrl({
    url,
    query: searchParams,
  });
};

export const buildAuditURL = (auditURL) => {
  const parsedQuery = getURLQuery();
  return buildURL({
    url: '/audit',
    searchParams: {
      ...parsedQuery,
      [QUERY_PARAM_AUDIT]: auditURL,
    },
  });
};

export const buildLoginURL = () => {
  const parsedQuery = getURLQuery();
  return buildURL({
    url: HTTP_CLIENT_REDIRECT,
    searchParams: {
      // Maintain promo param
      [QUERY_PARAM_PROMO]: parsedQuery[QUERY_PARAM_PROMO],
    },
  });
};

export const buildVerifyEmailURL = (email) => {
  const parsedQuery = getURLQuery();
  return buildURL({
    url: `${HTTP_CLIENT_REDIRECT}/email_verification`,
    searchParams: {
      ...parsedQuery,
      email: email,
    },
  });
};

export const extractVerificationCodeFromUrl = () => {
  const parsedQuery = getURLQuery();
  return parsedQuery.oobCode;
};
