import { NavBarLinkItem } from '../consts';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import styled from '@emotion/styled';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface NavMenuItemProps {
  text: string;
  linkTo: string;
  icon: React.ReactNode;
  disableMainRoute?: boolean;
  subRoutes?: NavBarLinkItem[];
  index: number;
  isActive: boolean;
  handleDrawerClose: () => void;
  isMobileOpen: boolean;
  className?: string | undefined;
  isButton?: boolean;
}
export const NavMenuItem = ({
  text,
  linkTo,
  icon,
  isMobileOpen,
  disableMainRoute,
  subRoutes,
  index,
  isActive,
  handleDrawerClose,
  className,
  isButton,
}: NavMenuItemProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <ListItem
      key={index}
      disablePadding
      sx={{ minHeight: '35px', flexDirection: 'column' }}
    >
      <ListItemButton
        className={className}
        component={subRoutes || isButton ? 'button' : Link}
        {...(!subRoutes && !isButton ? { to: linkTo } : {})}
        sx={{
          gap: '10px',
          height: '35px',
          width: '100%',
          padding: `${isMobileOpen ? '10px 30px' : '0 8px'}`,
          backgroundColor: `${isActive ? '#F5F5F5' : ''}`,
          borderRadius: `${isActive ? '4px' : ''}`,
          color: '#000A14D9',
          textDecoration: 'none',
          flexShrink: 0,
        }}
        onClick={(
          evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
        ) => {
          if (subRoutes) {
            handleClick();
            return;
          }
          if (disableMainRoute) {
            evt.preventDefault();
          }
          handleDrawerClose();
        }}
        onKeyDown={(evt: React.KeyboardEvent<HTMLElement>) => {
          if (evt.key === ' ') {
            evt.preventDefault();
            if (!subRoutes) {
              navigate(linkTo);
              return;
            }
            handleClick();
          }
        }}
      >
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <ListItemText
          primary={<MenuItemText>{text}</MenuItemText>}
          sx={{ margin: 0 }}
        />
        {subRoutes && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {subRoutes && (
        <MuiCollapse in={open} timeout="auto">
          <List
            component="div"
            disablePadding
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              marginTop: '5px',
              width: '-webkit-fill-available',
            }}
          >
            {subRoutes.map(
              (
                {
                  linkTo: subRouteLinkTo,
                  text: subRouteText,
                  icon: subRouteIcon,
                },
                index,
              ) => {
                const isSubRouteActive =
                  location.pathname === subRouteLinkTo.split('?')[0];

                return (
                  <ListItemButton
                    component={Link}
                    key={index}
                    to={subRouteLinkTo}
                    style={{
                      color: '#000A14D9',
                      textDecoration: 'none',
                      width: '100%',
                      backgroundColor: `${isSubRouteActive ? '#F5F5F5' : ''}`,
                      borderRadius: `${isSubRouteActive ? '4px' : ''}`,
                      height: '35px',
                      flexShrink: 0,
                      gap: '10px',
                      padding: `${isMobileOpen ? '10px 30px' : '0px'}`,
                    }}
                    onClick={() => {
                      navigate(subRouteLinkTo);
                      handleDrawerClose();
                    }}
                    onKeyDown={(evt: React.KeyboardEvent<HTMLElement>) => {
                      if (evt.key === ' ') {
                        evt.preventDefault();
                        navigate(subRouteLinkTo);
                        handleDrawerClose();
                      }
                    }}
                  >
                    {subRouteIcon && (
                      <ListItemIcon>{subRouteIcon}</ListItemIcon>
                    )}
                    <ListItemText
                      primary={
                        <SubMenuItemText>{subRouteText}</SubMenuItemText>
                      }
                      sx={{ margin: 0 }}
                    />
                  </ListItemButton>
                );
              },
            )}
          </List>

          {/*{subRoutes && (*/}
          {/*  <Box*/}
          {/*    sx={{*/}
          {/*      display: 'flex',*/}
          {/*      flexDirection: 'column',*/}
          {/*      gap: '10px',*/}
          {/*      marginTop: '5px',*/}
          {/*      marginLeft: '27px',*/}
          {/*      width: '-webkit-fill-available',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    */}
          {/*  </Box>*/}
          {/*)}*/}
        </MuiCollapse>
      )}
    </ListItem>
  );
};

const MenuItemText = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 500;
`;

const SubMenuItemText = styled(Typography)`
  font-size: 0.9rem;
`;

const MuiCollapse = styled(Collapse)`
  margin-left: -5px;

  @media screen and (max-width: 800px) {
    margin-left: -45px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
