import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

export const CustomAccordion = styled(Accordion)`
  box-shadow: none;
  border-radius: 8px !important;
  border: 2px solid #e0e0e0;
  margin-bottom: 9px;

  & .MuiAccordionSummary-content {
    margin: 0;
  }

  &:before {
    display: none;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

export const CustomAccordionDetails = styled(AccordionDetails)`
  @media (pointer: none), (pointer: coarse) {
    justify-content: center;
  }
`;
export const DetailsContainer = styled.div`
  width: 100%;
`;
