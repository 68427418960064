import { ScanType } from './scans';

export interface Issue {
  id: number;
  user_id: number;
  website_id: number;
  scan_id: number;
  code: string;
  type: string;
  type_code: number;
  message: string;
  context: string;
  selector: string;
  domain_url: string;
  source: string;
  status: string;
  recommendation: string;
  business_id: number;
  created_at: string;
  updated_at: string;
  component: string | null;
}

export interface Scan {
  id: number;
  user_id: number;
  website_id: number;
  business_id: number;
  domain: string;
  url: string;
  request_id: string;
  type: ScanType.automated | ScanType.manual;
  notice: number;
  warning: number;
  error: number;
  raw_data: string;
  status: string;
  scanner_type: string;
  created_at: string;
  updated_at: string;
  scan_image_url?: string;
  is_widget_appeared?: boolean;
  group_id: number | null;
}

export enum IssueStatusEnum {
  Pending = 'pending',
  Resolved = 'resolved',
  Dismissed = 'dismissed',
}

export type IssueStatuses = keyof typeof IssueStatusEnum;

export enum IssueSeverityEnum {
  Low = 'notice',
  Medium = 'warning',
  High = 'error',
}

export type IssueSeverity = 'notice' | 'warning' | 'error';

export const IssueSeverityTitle: Record<IssueSeverityEnum, string> = {
  [IssueSeverityEnum.Low]: 'Best Practice',
  [IssueSeverityEnum.Medium]: 'Potential',
  [IssueSeverityEnum.High]: 'Critical',
};

export type NewScanInfo = {
  target: string;
  scan_name: string;
};
