import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import PlansService from '../../api/plans-service';
import { PlansState } from './types';

export const initialState: PlansState = {
  loading: false,
};
export const cancelPlan = createAsyncThunk(
  `newPlans/cancelPlan`,
  async (actions: any, { rejectWithValue }) => {
    const { subscriptionId } = actions;
    const { error, data, isSuccess } =
      await PlansService.cancelPlan(subscriptionId);
    if (isSuccess) {
      return { data, isSuccess, error };
    } else {
      return rejectWithValue({ data, isSuccess, error });
    }
  },
);

const slice = createSlice({
  name: 'newPlans',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [cancelPlan.pending.type]: (state) => {
      state.loading = true;
    },
    [cancelPlan.fulfilled.type]: (state, action) => {
      state.loading = false;
    },
    [cancelPlan.rejected.type]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setLoading } = slice.actions;
export default slice.reducer;
