import React from 'react';
import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const CustomToolTip = styled(({ ...props }: TooltipProps) => (
  <Tooltip {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000A14',
    color: '#FFFFFF',
    maxWidth: 220,
    fontWeight: 300,
    fontSize: 12,
  },
}));
