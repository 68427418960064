import React from 'react';
import { Box, OutlinedInput } from '@mui/material';
import { ItemFilter } from './types';
import { DatePickerField } from './date-picker-field';
import styled from 'styled-components';

interface InputFieldsProps {
  option: ItemFilter;
  filter: ItemFilter;
  handleFilterValueChange: (key: string, value: string) => void;
  handleFilterSecondValueChange: (key: string, value: string) => void;
}

export const InputFields = ({
  option,
  filter,
  handleFilterValueChange,
  handleFilterSecondValueChange,
}: InputFieldsProps) => {
  return (
    <InputsContainer>
      <OutlinedInput
        style={{ width: '100%' }}
        onChange={(evt) => {
          handleFilterValueChange(option.key, evt.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key !== 'Escape') {
            e.stopPropagation();
          }
        }}
        name={option.key}
        value={filter.value || ''}
      />
      {option?.filterCondition === 'between' && (
        <OutlinedInput
          style={{ width: '100%' }}
          onChange={(evt) => {
            handleFilterSecondValueChange(option.key, evt.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          }}
          name={option.key}
          value={filter.value2 || ''}
        />
      )}
    </InputsContainer>
  );
};

export const DateInputFields = ({
  option,
  filter,
  handleFilterValueChange,
  handleFilterSecondValueChange,
}: InputFieldsProps) => {
  return (
    <InputsContainer>
      <DatePickerField
        value={Number(filter.value) || 0}
        onChange={(value) => handleFilterValueChange(option.key, value)}
        style={datePickerStyle}
        placeholder="Date Picker"
      />
      {option?.filterCondition === 'between' && (
        <DatePickerField
          value={Number(filter.value2) || 0}
          onChange={(value) => handleFilterSecondValueChange(option.key, value)}
          style={datePickerStyle}
          placeholder="Date Picker"
        />
      )}
    </InputsContainer>
  );
};

const datePickerStyle = {
  border: '1px solid rgb(228, 231, 235)',
  padding: '1.5px 10px',
  borderRadius: '4px',
  backgroundColor: '#ffffff',
  cursor: 'pointer',
};

const InputsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
