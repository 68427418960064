import React, { useEffect, useCallback } from 'react';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { getJiraSettings } from '@equally-ai-front/common/src/redux/business-slice/business';
import { AccessiblePopUp } from '@equally-ai-front/common/src/components/accessible-components/popup/AccessiblePopUp';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { setApiError } from '../../store/actions';
import { CreateJiraSettings } from '../jira-integration-settings/components/create-jira-settings';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { styled as MuiStyled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { JiraTaskForm } from './jira-task-form';

export interface SelectOption {
  label: string;
  value: string;
}

type ColumnKey =
  | 'id'
  | 'domain_url'
  | 'type'
  | 'source'
  | 'status'
  | 'selector'
  | 'message'
  | 'code'
  | 'recommendation';
export interface ColumnOption {
  label: string;
  key: ColumnKey;
}

export type IssueCreationType = 'create_issues' | 'group_issues';
export interface JiraTaskFormFields {
  columns: ColumnOption[];
  projectId: string;
  projectIssueTypeId: string;
  issueCreationType: IssueCreationType;
}

const columns: ColumnOption[] = [
  {
    label: 'URL',
    key: 'domain_url',
  },
  {
    label: 'Severity',
    key: 'type',
  },
  {
    label: 'Type',
    key: 'source',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Selector',
    key: 'selector',
  },
  {
    label: 'Description',
    key: 'message',
  },
  {
    label: 'WCAG',
    key: 'code',
  },
  {
    label: 'Recommendation',
    key: 'recommendation',
  },
];

interface CreateJiraTaskPopupProps {
  isOpen: boolean;
  handleClose: () => void;
  issues: Issue[];
  issuesDevToolsSolution: DevtoolsSolution[];
  selectedIssuesId: Record<number, boolean>;
}

export const CreateJiraTaskPopup = (props: CreateJiraTaskPopupProps) => {
  const {
    isOpen,
    handleClose,
    issues,
    issuesDevToolsSolution,
    selectedIssuesId,
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const currentBusiness = useSelector(
    (state: RootState) => state.business.currentBusiness,
  );
  const jiraSettings = useSelector(
    (state: RootState) => state.business.jiraSettings,
  );

  const fetchJiraSettings = useCallback(async () => {
    if (!currentBusiness.id) return;
    const { payload } = await dispatch(
      getJiraSettings({ businessId: currentBusiness.id }),
    );
    if (!payload) return;

    const { isSuccess, error } = payload;
    if (!isSuccess && error) {
      dispatch(setApiError(error));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusiness.id]);

  useEffect(() => {
    fetchJiraSettings();
  }, [fetchJiraSettings]);

  return (
    <AccessiblePopUp
      isOpen={isOpen}
      handleToggle={handleClose}
      dialogPaperStyles={{ height: '85vh', overflow: 'hidden' }}
    >
      {jiraSettings?.boardUrl ? (
        <JiraTaskForm
          columns={columns}
          handleClose={handleClose}
          issues={issues}
          issuesDevToolsSolution={issuesDevToolsSolution}
          selectedIssuesId={selectedIssuesId}
        />
      ) : (
        <CreateJiraSettings />
      )}
    </AccessiblePopUp>
  );
};
