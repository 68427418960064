import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import {
  capitalizeFirstLetter,
  formatUTCDate,
  WidgetConfigurations,
} from '@equally-ai-front/common/src/helpers/utils';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { RecordFilterContainer } from '@equally-ai-front/common/src/components/filters/record-filter/record-filter-container';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { MultiFilterSelect } from '@equally-ai-front/common/src/components/filters/multi-filter-select';

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MUITable from '@mui/material/Table';
import React, { /* MouseEvent, */ useMemo, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
// import ActionsButton from '../../components/actions-button';
import {
  isRecommendationEmpty,
  IssueSeverityEnumLookup,
  parseIssueRecommendation,
} from '@equally-ai-front/common/src/lib/issues';
import {
  CodeBlock,
  NoCodeChip,
  TableText,
  Title,
} from '../../components/css-components';
import { TableActionPopover } from '../../components/table-action-popover';
import { TABLE_PAGE_LIMIT } from '../../utils/constants';
import { TableSortLabel } from '@mui/material';
import { FilterField } from '@equally-ai-front/common/src/components/filters/types';
import { WebsiteGroup } from '../overview-page/website-group';
import { tableContainerClasses } from '@mui/material/TableContainer';
import { IWebsiteInfo } from '../overview-page/website-list';
import { AttributesChip } from '@equally-ai-front/common/src/components/attribute-chip/attributes-chip';

const itemFields: Record<string, FilterField> = {
  name: {
    key: 'domain_url',
    displayName: 'URL',
    filterType: 'string',
  },
  date: {
    key: 'updated_at',
    displayName: 'Date',
    filterType: 'date',
  },
  priority: {
    key: 'severity',
    displayName: 'Priority',
    filterType: 'string',
  },
  source: {
    key: 'source',
    displayName: 'Source',
    filterType: 'string',
  },
};

const getRecommendationAttributes = (
  attributes: Record<string, { [key: string]: string }>,
) => (
  <>
    {Object.entries(attributes).map(([_, object]) =>
      Object.entries(object).map(([key, value]) => (
        <AttributesChip
          key={`attribute-${key}`}
          attribute={`${capitalizeFirstLetter(key.replace('-', ' '))}: ${value}`}
          value={value}
        />
      )),
    )}
  </>
);

const getRecommendationContent = (
  recommendation: Record<
    string,
    Record<WidgetConfigurations, Record<string, any>>
  > | null,
) => {
  if (!recommendation) return <>N/A</>;

  const { attributes, text } = recommendation;
  return (
    <>
      {attributes ? (
        <ChipContainer>{getRecommendationAttributes(attributes)}</ChipContainer>
      ) : text ? (
        <>{text}</>
      ) : (
        'N/A'
      )}
    </>
  );
};

interface IssuesTableProps {
  selectedWebsites: IWebsiteInfo[];
  setSelectedWebsites: React.Dispatch<React.SetStateAction<IWebsiteInfo[]>>;
}

export const IssuesTable = (props: IssuesTableProps) => {
  const { selectedWebsites, setSelectedWebsites } = props;
  const { allIssues } = useAppSelector((state) => state.issues);

  const [orderBy, setOrderBy] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const issuesWithSeverity = useMemo(
    () =>
      allIssues.map((issue) => ({
        ...issue,
        severity: IssueSeverityEnumLookup[issue.type],
      })),
    [allIssues],
  );

  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm: filterSearchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({ filterOptions: itemFields, data: issuesWithSeverity });
  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const { pageCount, currentData, handlePageChange, handleSort, sortOptions } =
    usePagination(
      [...visibleData].sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
      ),
      TABLE_PAGE_LIMIT,
    );

  const handleRequestSort = (property: string) => {
    setOrderBy(property);
    handleSort(property);
  };

  const handleCloseActionPopover = () => {
    setAnchorEl(null);
  };

  const sortOrder = Object.values(sortOptions)[0] ?? 'desc';

  return (
    <>
      <HeaderWrapper>
        <CustomTitle>Issues</CustomTitle>
        <WebsiteGroup
          selectedWebsites={selectedWebsites}
          setSelectedWebsites={setSelectedWebsites}
        />

        {allIssues && allIssues.length > TABLE_PAGE_LIMIT && (
          <div style={{ marginLeft: 'auto' }}>
            <MultiFilterSelect
              filterValueOptions={filterValueOptions}
              handleFilterValueChange={handleFilterValueChange}
              handleFilterSecondValueChange={handleFilterSecondValueChange}
              handleFilterOperatorChange={handleFilterOperatorChange}
              searchTerm={filterSearchTerm}
              addFilter={addFilter}
              isFilterFulfilled={isFilterFulfilled}
              setSearchTerm={setSearchTerm}
              handleOptionClick={handleOptionClick}
              handleSearchInputChange={handleSearchInputChange}
              options={filteredOptions}
              currentFilter={newFilter}
            />
          </div>
        )}
      </HeaderWrapper>
      <Wrapper>
        <TableContainer>
          {allIssues && allIssues.length > TABLE_PAGE_LIMIT && (
            <RecordFilterContainer
              filters={filters}
              removeFilterType={removeFilterType}
              allowAdditionalInfo
            />
          )}
          <MUITable
            className="table"
            sx={{ minWidth: 650 }}
            aria-label="issues table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sortDirection={orderBy === 'domain_url' ? sortOrder : false}
                >
                  <TableSortLabel
                    active={orderBy === 'domain_url'}
                    direction={orderBy === 'domain_url' ? sortOrder : 'desc'}
                    onClick={() => handleRequestSort('domain_url')}
                  >
                    URL
                  </TableSortLabel>
                </TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Selector</TableCell>
                <TableCell
                  sortDirection={orderBy === 'type' ? sortOrder : false}
                >
                  <TableSortLabel
                    active={orderBy === 'type'}
                    direction={orderBy === 'type' ? sortOrder : 'desc'}
                    onClick={() => handleRequestSort('type')}
                  >
                    Priority
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>Snippet</TableCell> */}
                <TableCell>Description</TableCell>
                <TableCell>Recommendation</TableCell>
                <TableCell
                  sortDirection={orderBy === 'updated_at' ? sortOrder : false}
                >
                  <TableSortLabel
                    active={orderBy === 'updated_at'}
                    direction={orderBy === 'updated_at' ? sortOrder : 'desc'}
                    onClick={() => handleRequestSort('updated_at')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.length > 0 ? (
                currentData.map((row) => {
                  let parsedRecommendation: Record<
                    string,
                    Record<WidgetConfigurations, Record<string, any>>
                  > | null = null;
                  const isRecommendationsEmpty = isRecommendationEmpty(
                    row.recommendation,
                  );

                  if (!isRecommendationsEmpty) {
                    parsedRecommendation = parseIssueRecommendation(
                      row.recommendation,
                    );
                  }

                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: '0' },
                      }}
                    >
                      <TableCell align="left" className="td">
                        {row.domain_url}
                      </TableCell>
                      <TableCell align="left" className="td">
                        <ScanHistoryTitle>{row.source}</ScanHistoryTitle>
                      </TableCell>
                      <TableCell align="left" className="td">
                        <AttributesChip
                          key={`column-${row.id}`}
                          attribute={row.selector}
                          value={row.selector}
                          maxWidth={'200px'}
                        />
                      </TableCell>
                      <TableCell className="td">{row.severity}</TableCell>
                      {/* <TableCell>
                        {row.context ? (
                          <IssueSnippet>
                            <CodeBlock>{row.context}</CodeBlock>
                          </IssueSnippet>x
                        ) : (
                          <NoCodeChip>N/A</NoCodeChip>
                        )}
                      </TableCell> */}
                      <TableCell align="left">
                        <TableText>{row.message}</TableText>
                      </TableCell>
                      <TableCell align="left">
                        {getRecommendationContent(parsedRecommendation)}
                      </TableCell>
                      <TableCell align="left" className="td" scope="row">
                        {formatUTCDate(row.updated_at)}
                      </TableCell>
                      {/* <TableCell className="td">
                      <ActionsButton handleClick={handleOpenActionPopover} />
                    </TableCell> */}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="no-data">
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
              <TableActionPopover
                anchorEl={anchorEl}
                handleClose={handleCloseActionPopover}
              />
            </TableBody>
          </MUITable>
        </TableContainer>
        <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
      </Wrapper>
    </>
  );
};

export default IssuesTable;

const Wrapper = styled.div`
  width: 100%;
  border-radius: inherit;
  margin-top: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;

  .${tableContainerClasses.root} {
    overflow: auto hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &:hover {
      scrollbar-width: auto;
      -ms-overflow-style: auto;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }

  .table {
    width: 100%;
    max-width: 100%;

    thead {
      border-top: none !important;
      border-bottom: 2px solid #f4f3fe !important;

      th {
        vertical-align: bottom;
        border-bottom: none;
        border-top: none;
        color: rgba(0, 0, 0, 0.87);
        font-weight: bold;
        font-size: 14px;
        border-right: 2px solid #f5f7fa;

        &:last-child {
          border-right: none;
        }
      }
    }

    td {
      padding: 0.75rem;
      vertical-align: top;
    }

    tbody {
      td {
        border-top: 1px solid #f4f3fe;
        color: rgba(0, 0, 0, 0.87);
        border-right: 2px solid #f5f7fa;

        &:last-child {
          border-right: none;
        }
      }

      tr:last-child {
        border-bottom: none !important;

        td {
          border-bottom: none !important;
        }

        .no-data {
          border-bottom: 2px solid #f4f3fe !important;
        }
      }
    }

    //th,
    //.td {
    //  white-space: nowrap;
    //}
  }
`;

const IssueSnippet = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  word-wrap: break-word;
`;

const HeaderWrapper = styled.div`
  display: flex;
  margin: 0;
`;

const CustomTitle = styled(Title)`
  margin: 0 30px 0 0;
`;

const ChipContainer = styled.div`
  padding: 10px 0 3%;
  display: flex;
  flex-wrap: wrap;
`;

const ScanHistoryTitle = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  background-color: #f1f3f5;
  color: #50565f;
  border-radius: 8px;
  padding: 5px 8px;
  //width: fit-content;
`;

const StyledAttributesChip = styled(AttributesChip)`
  max-width: 200px;
`;
