import { parseIssueRecommendation } from '@equally-ai-front/common/src/lib';
import {
  HostTempScreenState,
  publishConfig,
  tempScreenStateUpdated,
} from '@equally-ai-front/common/src/redux/screen-slice/screen-slice';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setApiError, setApiSuccessMessage } from '../store/actions';

type UseQuickFix = {
  addToConfig: (items: DevtoolsSolution[]) => void;
};

interface useQuickFixProps {
  domainId: string | undefined;
}
function useQuickFix({ domainId }: useQuickFixProps): UseQuickFix {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const handleSuccessMessage = useCallback(
    (payload: any) => {
      const { isSuccess, duplicate } = payload;

      if (isSuccess) {
        dispatch(setApiSuccessMessage('Ta-da! Your changes has been saved!'));
        return;
      }

      if (!isSuccess && duplicate) {
        dispatch(
          setApiError(
            `Your attribute configuration is duplicated.\nSeletor: ${duplicate?.selector}\nAttribute: ${duplicate?.attribute}`,
          ),
        );
        return;
      }

      dispatch(setApiError('Unable to process your request'));
    },
    [dispatch],
  );

  const addToConfig = useCallback(
    async (recommendationItems: DevtoolsSolution[]) => {
      if (!domainId) {
        return;
      }
      const tempStateItems: Record<string, HostTempScreenState> = {};

      recommendationItems.forEach((item) => {
        const parsedRecommendation = parseIssueRecommendation(
          item.recommendation,
        );

        if (!parsedRecommendation) {
          return;
        }
        tempStateItems[item.selector] = {
          selector: item.selector,
          tagName: '',
          attributes: parsedRecommendation.attributes,
        };
      });

      if (Object.keys(tempStateItems).length === 0) {
        return;
      }

      dispatch(tempScreenStateUpdated(tempStateItems));

      const response = await dispatch(
        publishConfig({
          domainID: domainId,
          isAdmin: false,
        }),
      );

      handleSuccessMessage(response.payload);
    },
    [dispatch, domainId, handleSuccessMessage],
  );

  return {
    addToConfig,
  };
}

export default useQuickFix;
