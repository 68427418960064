import BussinessSettingsIcon from '@equally-ai-front/common/src/assets/svg/business-settings-200.svg';
import LogoutIcon from '@equally-ai-front/common/src/assets/svg/logout-symbol-2.svg';
import PersonalSettingsIcon from '@equally-ai-front/common/src/assets/svg/peronal-settings-200.svg';
import SupportIcon from '@equally-ai-front/common/src/assets/svg/support-symbol-200.svg';
import { AccessibilityMenu } from '@equally-ai-front/common/src/components/accessible-components/menu/accessibility-menu';
import { DynamicMenuItemProps } from '@equally-ai-front/common/src/components/expandables/menus/types';
import { currentBusinessUpdated } from '@equally-ai-front/common/src/redux/business-slice/business';
import { Business } from '@equally-ai-front/common/src/types/business';
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isUserPermitted } from '../lib/permissions';
import { useAuth } from '../providers/auth-context';
import { RootState } from '../store';
import { BusinessMenuSearch } from './business-menu-search';
import BillingIcon from '../img/card.svg';

interface BusinessMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  handleClose: () => void;
}

export const BusinessMenu = (props: BusinessMenuProps) => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const businesses = useSelector((state: any) => state.business.businesses);
  const [businessSearchPhrase, setBusinessSearchPhrase] = useState<
    string | undefined
  >(undefined);
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const personalDetails = useSelector(
    (state: RootState) => state.personalDetails.personalDetails,
  );

  const { anchorEl, handleClose } = props;

  const handleBusinessClick = (business: Business) => {
    dispatch(currentBusinessUpdated(business));
    setBusinessSearchPhrase(undefined);
    handleClose();
  };

  const handleBusinessSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const newValue =
      value.toLowerCase() === '' ? undefined : value.toLowerCase();
    setBusinessSearchPhrase(newValue);
  };

  const currentBusinesses = useMemo(() => {
    if (businessSearchPhrase === undefined) {
      return businesses;
    }
    if (businessSearchPhrase) {
      return businesses.filter(
        (business: Business) =>
          business.name.toLowerCase().includes(businessSearchPhrase) ||
          business.id.toString().toLowerCase().includes(businessSearchPhrase),
      );
    }
  }, [businessSearchPhrase, businesses]);

  useEffect(() => {
    if (!anchorEl) {
      setBusinessSearchPhrase(undefined);
    }
  }, [anchorEl]);

  const businessListSection: DynamicMenuItemProps[] = (currentBusinesses || [])
    .map((business: Business, index: number) => {
      return {
        name: business.slug,
        label: business.name,
        section: 1,
        isActive: business?.id === currentBusiness?.id,
        style: {
          maxHeight: '280px',
          overflowY: 'scroll',
        },
        linkTo: `../${business.slug}`,
        classes: 'header-navigation-popover-item header-popover-item',
        handleClicked: () => handleBusinessClick(business),
      };
    })
    .sort(
      (a: DynamicMenuItemProps, b: DynamicMenuItemProps) =>
        (b.isActive ? 1 : 0) - (a.isActive ? 1 : 0),
    );

  const getDivider = (): DynamicMenuItemProps => {
    return {
      name: 'divider',
      label: '',
      isDivider: true,
      classes: `header-navigation-divider`,
      handleClicked: () => {},
    };
  };

  const isUserAuthorized = isUserPermitted(
    currentBusiness?.id,
    ['view.business_settings'],
    personalDetails,
  );
  const businessSettingSection: DynamicMenuItemProps[] = [
    isUserAuthorized
      ? {
          name: 'business-settings',
          label: 'Business Settings',
          linkTo: `/${currentBusiness?.slug}/settings`,
          handleClicked: handleClose,
          classes: `header-navigation-popover-item header-popover-item ${
            isUserAuthorized ? '' : 'd-none'
          }`,
          icon: BussinessSettingsIcon,
          hasIcon: true,
        }
      : ({} as DynamicMenuItemProps),
    isUserAuthorized ? getDivider() : ({} as DynamicMenuItemProps),
    isUserAuthorized
      ? {
          name: 'billing-and-payment',
          label: 'Billing & Payments',
          linkTo: `/${currentBusiness?.slug}/settings/plans?show_plan=${encodeURIComponent('true')}`,
          handleClicked: handleClose,
          classes: `header-navigation-popover-item header-popover-item ${
            isUserAuthorized ? '' : 'd-none'
          }`,
          icon: BillingIcon,
          hasIcon: true,
        }
      : ({} as DynamicMenuItemProps),
  ];

  const open = Boolean(anchorEl);

  const profileSettingSection: DynamicMenuItemProps[] = [
    {
      name: 'settings',
      label: 'Settings',
      linkTo: `/settings`,
      icon: PersonalSettingsIcon,
      hasIcon: true,
      handleClicked: handleClose,
      classes: `header-navigation-popover-item header-popover-item`,
    },
    {
      name: 'support',
      label: 'Support',
      icon: SupportIcon,
      hasIcon: true,
      linkTo: process.env.REACT_APP_HTTP_SUPPORT_ENDPOINT,
      handleClicked: handleClose,
      classes: `header-navigation-popover-item header-popover-item`,
      openInNewWindow: true,
    },
    {
      name: 'logout',
      label: 'Logout',
      linkTo: '#',
      icon: LogoutIcon,
      hasIcon: true,
      handleClicked: logout,
      classes: `header-navigation-popover-item header-popover-item`,
    },
  ];

  const profileMenuItems: DynamicMenuItemProps[] = [
    ...businessListSection,
    getDivider(),
    ...businessSettingSection,
    ...profileSettingSection,
  ];

  const searchBar = businesses && businesses.length > 5 && (
    <BusinessMenuSearch
      businesses={businesses}
      handleBusinessCallback={handleBusinessSearch}
    />
  );

  return (
    <AccessibilityMenuWrapper>
      <AccessibilityMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        menuItems={profileMenuItems}
      >
        {searchBar}
      </AccessibilityMenu>
    </AccessibilityMenuWrapper>
  );
};

const AccessibilityMenuWrapper = styled.div`
  position: absolute;
  top: 77px;
`;

const MenuButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 9999px;
  padding: 5px;

  &:focus-visible {
    outline: solid 3px #b8b5f6;
    outline-offset: 5px;
    outline-style: inset;
  }

  &:hover {
    background: #454284;
  }
`;
