/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable camelcase */
import styled from '@emotion/styled';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { getWidgetDomains } from '@equally-ai-front/common/src/redux/user-products-slice/user-products';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Extension from '../../../img/puzzle.png';
// import Settings from '../../../img/settings.png';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomiseAppearance from '../../redesign/CustomiseAppearance';
import { ModalPanel } from '../../redesign/ModalPanel';
// import Code from '../../../img/code.png';
import CodeIcon from '@mui/icons-material/Code';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import {
  FREE_TRIAL_MESSAGE,
  PLAN_EXPIRY_MESSAGE,
  FLOWY_FREE_TRIAL_MESSAGE,
  FLOWY_PLAN_EXPIRY_MESSAGE,
} from '../../../common/magicValues';
import { formatExpiration, formatDate } from '../../../lib/date';
import { setApiError, setApiSuccessMessage } from '../../../store/actions';
import { InstallExtensionContent } from '../../install-extension-step/components/install-extention-content';
import SiteSettings from '../../redesign/SiteSettings';
import { ContainedButton, OutlinedButton } from '../ActionButton';
import {
  ErrorPillSmall,
  SuccessPillSmall,
  TypographySmall,
  WarningPillSmall,
} from '../Pill';
import { Box, CircularProgress, Grid } from '@mui/material';
import { Notification, NotificationType } from './components/notification';
import DomainsAPI from '@equally-ai-front/common/src/api/domains';
import {
  CustomAccordionLayout,
  EmptyAccordionLayout,
  EmptyCustomAccordionLayoutProps,
} from './components/custom-accordion-layout';
import { StatementView, StatementViewProps } from './components/statement-view';
import { withStyles } from '@mui/styles';
import { PrimaryButton } from '../../redesign/Controls';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import { usePlans } from '../../../providers/plans-context';
import { Link, useNavigate } from 'react-router-dom';
import {
  findAvailablePlan,
  isDomainWithoutPlan,
  isPlansAvailableWithCredits,
  isStripePlan,
  showPlans,
} from '@equally-ai/cart-checkout/src/utils/plans.utils';
import PlansService from '@equally-ai-front/common/src/api/plans-service';
import useAppearanceState from '../../../hooks/use-widget-config-state';
import { RootState } from '../../../store';

interface CustomizeAppearanceProps {
  domain: string;
  setCustomAppearanceOpened: (value: boolean) => void;
  appearance: any;
  handleSaveAppearance: (appearance: any) => void;
  updateAppearance: (path: string | Record<string, any>, value: string) => void;
}

interface SiteSettingsProps {
  closeSettings: () => void;
  domain: string;
  appearance: any;
  handleSaveAppearance: (appearance: any) => void;
  updateAppearance: (path: string | Record<string, any>, value: string) => void;
}

interface InstallExtensionContentProps {}

interface DomainInstallationCodeProps {
  domain: string;
  code: string;
  handleInviteTeamMember: () => void;
}

interface InitCustomAccordionLayoutProps {
  makeComponent: <T>(props: T) => ReactNode;
  header: string;
  validateFallback?: any;
  image: ReactNode;
  validate: (props: any) => boolean;
}

const ACCORDION_COMPONENTS: Record<string, InitCustomAccordionLayoutProps> = {
  installation: {
    // @ts-ignore
    makeComponent: (props: DomainInstallationCodeProps) => null,
    image: (
      // @ts-ignore
      <CodeIcon
        className="website-card-header-edit-btn-ico"
        alt=""
        // src={Code}
        role="presentation"
      />
    ),
    header: 'Installation',
    validate: ({ planId }: { planId: number | undefined }) => false,
    validateFallback: (
      props: StatementViewProps & EmptyCustomAccordionLayoutProps,
    ) => {
      return (
        <EmptyAccordionLayout
          {...props}
          ctaButton={
            <ViewInstallationLink to="installation">View</ViewInstallationLink>
          }
        />
      );
    },
  },
  'install-extension': {
    // @ts-ignore
    makeComponent: (props: InstallExtensionContentProps) => (
      <InstallExtensionContent {...props} />
    ),
    validate: ({ showInstallExtension }: { showInstallExtension: boolean }) =>
      showInstallExtension,
    image: (
      <img
        className="website-card-header-edit-btn-ico"
        alt="install extension"
        src={Extension}
        role="presentation"
      />
    ),
    header: 'Install Our Chrome Extension',
  },
  'custom-appearance': {
    // @ts-ignore
    makeComponent: (props: CustomizeAppearanceProps) => (
      <CustomiseAppearance {...props} />
    ),
    image: (
      // @ts-ignore
      <PaletteOutlinedIcon
        className="website-card-header-edit-btn-ico"
        alt=""
        // src={Paint}
        role="presentation"
      />
    ),
    header: 'Customize',
  },
  settings: {
    // @ts-ignore
    makeComponent: (props: SiteSettingsProps) => <SiteSettings {...props} />,
    image: (
      // @ts-ignore
      <SettingsIcon
        className="website-card-header-edit-btn-ico"
        alt=""
        // src={Settings}
      />
    ),
    header: 'Settings',
  },
  statement: {
    // @ts-ignore
    makeComponent: (
      props: StatementViewProps & EmptyCustomAccordionLayoutProps,
    ) => {
      const { planId } = props;
      return !planId || planId === 0 ? (
        <EmptyAccordionLayout {...props} />
      ) : (
        <StatementView {...props} />
      );
    },
    validate: ({ planId }: { planId: number | undefined }) =>
      planId !== undefined && planId !== 0,
    validateFallback: (
      props: StatementViewProps & EmptyCustomAccordionLayoutProps,
    ) => {
      return (
        <EmptyAccordionLayout
          {...props}
          ctaButton={
            <UpgradeButton
              onClick={() => {
                if (props.hasActiveStripeSubscription) {
                  props.navigateToSubscriptionsPage();
                  return;
                }
                props.onChoosePlan(props.type);
              }}
            >
              Upgrade
            </UpgradeButton>
          }
        />
      );
    },
    header: 'Accessibility Certification',
    image: (
      // @ts-ignore
      <CardMembershipIcon
        style={{ marginTop: '3px' }}
        className="website-card-header-edit-btn-ico"
        alt=""
        role="presentation"
      />
    ),
  },
};

interface ManageWidgetCardProps {
  domain: any;
  open: boolean;
  handleClose: () => void;
  showInstallExtension: boolean;
  isNewDomain: boolean;
  onChoosePlan: (productType: string) => void;
  handleDomainUpdated: (domain: string) => void;
}
const NotificationComponent = ({
  isExpired,
  isWithoutPlan,
  domain,
}: {
  isExpired: boolean;
  isWithoutPlan: boolean;
  domain: any;
}) => {
  // Determine the notification message and type based on conditions
  let notificationMessage = '';
  let notificationType: NotificationType | null = null;

  if (isExpired) {
    notificationMessage =
      domain.type === 'flowy' ? FLOWY_PLAN_EXPIRY_MESSAGE : PLAN_EXPIRY_MESSAGE;
    notificationType = NotificationType.Error;
  } else if (!isExpired && isWithoutPlan) {
    notificationMessage =
      domain.type === 'flowy' ? FLOWY_FREE_TRIAL_MESSAGE : FREE_TRIAL_MESSAGE;
    notificationType = NotificationType.Warning;
  }

  // Render the component
  return (
    <>
      {notificationMessage && notificationType && (
        <Notification message={notificationMessage} type={notificationType} />
      )}
    </>
  );
};

const ManageWidgetCard = ({
  domain,
  open,
  handleClose,
  showInstallExtension,
  isNewDomain,
  onChoosePlan,
  handleDomainUpdated,
}: ManageWidgetCardProps) => {
  const [currentActiveOption, setCurrentActiveOption] = useState<string | null>(
    null,
  );

  const { currentBusiness } = useSelector((state: RootState) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const { activePlans: plansData, getActivePlans } = usePlans();

  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const onChange = useCallback(
    (option: string, trigger = 'click') => {
      if (currentActiveOption === option) {
        setCurrentActiveOption(null);
        return;
      }

      setCurrentActiveOption(option);
      analytics.track('Website Settings Option Clicked', {
        option: option,
        url: domain.url,
        id: domain.id,
        trigger: trigger,
      });
    },
    [analytics, currentActiveOption, domain.id, domain.url],
  );

  const {
    appearanceState: appearance,
    handleSaveAppearance,
    updateAppearance,
    isLoading,
  } = useAppearanceState({
    domainName: domain.url,
    slug: currentBusiness.slug,
  });

  const hasActiveStripeSubscription = useMemo(() => {
    if (!plansData) return false;
    return (
      plansData?.length > 0 &&
      plansData?.filter((plan) => isStripePlan(plan.provider_id)).length > 0
    );
  }, [plansData]);

  const navigateToSubscriptionsPage = () => {
    navigate(`/${currentBusiness?.slug}/business-settings/plans`);
  };

  const getComponentProps = useCallback(
    (key: string) => {
      switch (key) {
        case 'installation':
          return {};
        case 'custom-appearance':
          return {
            domain: domain.url,
            handleSaveAppearance,
            setCustomAppearanceOpened: () => onChange('custom-appearance'),
            appearance,
            updateAppearance,
          };
        case 'settings':
          return {
            domain: domain.url,
            closeSettings: () => onChange('settings'),
            appearance,
            handleSaveAppearance,
            updateAppearance,
          };
        case 'install-extension':
          return { showInstallExtension };
        case 'statement':
          return {
            planId: domain.plan_id,
            name: domain.url,
            defaultLanguage:
              appearance.widgetConfigSiteAdditional.defaultLanguage,
            statementLink:
              appearance.widgetConfigSiteAdditional.accessibilityStatementLink,
            onChoosePlan: onChoosePlan,
            type: domain.type,
            hasActiveStripeSubscription,
            navigateToSubscriptionsPage,
          };
        default:
          return {};
      }
    },
    [
      domain.url,
      domain.plan_id,
      handleSaveAppearance,
      appearance,
      showInstallExtension,
      onChange,
      onChoosePlan,
      domain.type,
    ],
  );

  const views: ReactNode[] = [];
  Object.keys(ACCORDION_COMPONENTS).forEach((key) => {
    const componentProps = getComponentProps(key);
    const { makeComponent, validateFallback, header, image, validate } =
      ACCORDION_COMPONENTS[key];
    let Component;
    if (validate && !validate(componentProps)) {
      if (!validateFallback) {
        return;
      }
      Component = validateFallback({ ...componentProps, image, header });
      views.push(Component);
      return;
    }

    Component = makeComponent(componentProps);
    views.push(
      <CustomAccordionLayout
        type={key}
        isExpanded={currentActiveOption === key}
        onChange={() => onChange(key)}
        header={header}
        image={image}
        Component={Component}
      />,
    );
  });

  const handleCloseModal = () => {
    handleClose();
  };

  const onActivateLicense = async () => {
    const availablePlan = findAvailablePlan(plansData || [], domain.type);

    if (!availablePlan) {
      return;
    }

    const response = await PlansService.attachMultipleDomainsToPlan({
      planID: availablePlan.id,
      businessID: currentBusiness.id,
      type: domain.type,
      domainIDs: [domain.id],
    });

    if (response.isSuccess) {
      dispatch(setApiSuccessMessage(response.data));

      analytics.track('License Attached', {
        domain_id: domain.id,
        business_id: currentBusiness.id,
        plan_id: availablePlan.id,
        url: domain.url,
      });
      // @ts-ignore
      // dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
      // getActivePlans('');
      handleDomainUpdated(domain.url);
    } else {
      dispatch(setApiError(response.error));
    }
  };

  useEffect(() => {
    if (isNewDomain) {
      onChange('installation', 'new-domain');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewDomain]);

  const { plan_template } =
    (plansData || []).find((plan: any) => plan?.id === domain.plan_id) || {};

  const isExpired = formatExpiration(domain.expires) === 'Expired';

  const formattedDate = formatDate(domain.expires);

  let creditsCTA;
  if (
    isPlansAvailableWithCredits(plansData || []) &&
    isDomainWithoutPlan(domain)
  ) {
    creditsCTA = (
      <OutlinedButton disabled={domain.plan_id} onClick={onActivateLicense}>
        Activate license
      </OutlinedButton>
    );
  } else if (
    isDomainWithoutPlan(domain) &&
    !isPlansAvailableWithCredits(plansData || []) &&
    !showPlans(plansData || [])
  ) {
    creditsCTA = (
      <ContainedButton
        onClick={() => {
          if (hasActiveStripeSubscription) {
            navigateToSubscriptionsPage();
            return;
          }
          onChoosePlan(domain.type);
        }}
      >
        Buy a license
      </ContainedButton>
    );
  } else if (showPlans(plansData || [])) {
    creditsCTA = (
      <ContainedButton onClick={() => onChoosePlan(domain.type)}>
        Choose a plan
      </ContainedButton>
    );
  }
  return (
    <>
      <ModalPanel open={open} handleClose={handleCloseModal} title={domain.url}>
        <ButtonContainer>{creditsCTA}</ButtonContainer>
        <NotificationComponent
          domain={domain}
          isExpired={isExpired}
          isWithoutPlan={isDomainWithoutPlan(domain)}
        />
        <Grid container alignItems="center" justifyContent="center">
          <GridItem item md={4} xs={4}>
            <Subtitle>Status</Subtitle>
            {domain.active ? (
              <SuccessPill>Active</SuccessPill>
            ) : (
              <ErrorPill>Inactive</ErrorPill>
            )}
          </GridItem>
          <GridItem item md={4} xs={4}>
            <Subtitle>Plan</Subtitle>
            {plan_template?.name ? (
              <SuccessPill>{plan_template.name}</SuccessPill>
            ) : (
              <WarningPill>Trial</WarningPill>
            )}
          </GridItem>
          <GridItem item md={4} xs={4}>
            <Subtitle>Expiration</Subtitle>

            {!isExpired ? (
              <SuccessPill>{formattedDate}</SuccessPill>
            ) : (
              <WarningPill>{formattedDate}</WarningPill>
            )}
          </GridItem>
        </Grid>
        <Box mb="18px" />
        <div className="website-card-controls">
          {isLoading ? (
            <CircularProgress
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
              }}
            />
          ) : (
            <div style={{ minHeight: '25vh', width: '100%' }}>{views}</div>
          )}
        </div>
      </ModalPanel>
    </>
  );
};

export default ManageWidgetCard;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  &:not(:empty) {
    margin-bottom: 20px;
  }

  & > button,
  & > a {
    text-transform: initial;
    padding: 6px 16px 6px 12px;
    height: 34px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border-radius: 8px;
  }
`;

const Subtitle = styled(TypographySmall)`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 3px;
`;

const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessPill = styled(SuccessPillSmall)`
  min-width: 60px;
  text-align: center;

  @media screen and (max-width: 600px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
`;

const WarningPill = styled(WarningPillSmall)`
  min-width: 60px;
  text-align: center;

  @media screen and (max-width: 600px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
`;

const ErrorPill = styled(ErrorPillSmall)`
  min-width: 60px;
  text-align: center;

  @media screen and (max-width: 600px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
`;
export const UpgradeButton = withStyles((theme) => {
  return {
    root: {
      height: '33px',
      fontSize: '13px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.on.lightHigh,
      border: '1px solid transparent',
      '&:hover': {
        color: theme.palette.on.darkHigh,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  };
})(PrimaryButton);

export const ViewInstallationLink = styled(Link)`
  border-radius: 8px;
  padding: 6px 8px;
  height: 33px;
  font-size: 13px;
  background-color: #454284;
  color: #ffffff;
  border: 1px solid transparent;

  &:hover {
    color: #000a14;
    background-color: #ffffff;
    border: 1px solid #454284;
  }
`;
