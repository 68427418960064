import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/index';

interface Scope {
  type: 'Manual' | 'Automated';
  issuesCount: number;
}

interface LastFix {
  date: string;
  author: string;
}
interface ProgressRecord {
  id: string;
  last_audit: string;
  scope: Scope;
  score: number;
  last_fix: LastFix;
}

const initialState: ProgressRecord[] = [
  {
    id: '1',
    last_audit: 'Feb 27, 2023 4:40pm',
    scope: {
      type: 'Manual',
      issuesCount: 1069,
    },
    score: 87,
    last_fix: {
      date: 'Apr 27, 2023 3:49am',
      author: 'Sarah Bright',
    },
  },
  {
    id: '2',
    last_audit: 'Feb 27, 2023 4:40pm',
    scope: {
      type: 'Manual',
      issuesCount: 1069,
    },
    score: 87,
    last_fix: {
      date: 'Apr 27, 2023 3:49am',
      author: 'Sarah Bright',
    },
  },
  {
    id: '3',
    last_audit: 'Feb 27, 2023 4:40pm',
    scope: {
      type: 'Manual',
      issuesCount: 1069,
    },
    score: 87,
    last_fix: {
      date: 'Apr 27, 2023 3:49am',
      author: 'Sarah Bright',
    },
  },
];

export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    addIssue: (state, action: PayloadAction<ProgressRecord>) => {
      return [...state, action.payload];
    },
    addIssues: (state, action: PayloadAction<ProgressRecord[]>) => {
      return [...state, ...action.payload];
    },
    // TODO: add reducers for deleting, fixing issues, etc
  },
});

export const { addIssues, addIssue } = progressSlice.actions;

export const selectProgressData = (state: RootState) => state.issues;

export default progressSlice.reducer;
