import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { BusinessGptKey } from '../../../../components/gpt-key/business-gpt-key';
import { JiraIntegrationSettings } from '../../../../components/jira-integration-settings/jira-integration-settings';
import { isUserPermitted } from '../../../../lib/permissions';
import { PreferencesPage } from '../preferences-page/preferences-page';

export const SettingsPage = () => {
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const { settingsType } = useParams();

  return (
    <Wrapper>
      <LinksWrapper>
        {isUserPermitted(
          currentBusiness?.id,
          ['view.compliance_settings'],
          personalDetails,
        ) && (
          <StyledLink
            className="flowy-dashboard-link"
            isactive={(settingsType === 'schedule-scans').toString()}
            to={`/${currentBusiness?.slug}/compliance-manager/settings/schedule-scans`}
          >
            Schedule scans
          </StyledLink>
        )}
        <StyledLink
          className="flowy-dashboard-link"
          isactive={(settingsType === 'gpt-key').toString()}
          to={`/${currentBusiness?.slug}/compliance-manager/settings/gpt-key`}
        >
          GPT key
        </StyledLink>
        {isUserPermitted(
          currentBusiness?.id,
          ['view.jira_settings', 'write.jira_settings'],
          personalDetails,
        ) && (
          <StyledLink
            className="flowy-dashboard-link"
            isactive={(settingsType === 'task-management').toString()}
            to={`/${currentBusiness?.slug}/compliance-manager/settings/task-management`}
          >
            Task management
          </StyledLink>
        )}
      </LinksWrapper>
      <SettingsTabPage>
        {settingsType === 'schedule-scans' &&
        isUserPermitted(
          currentBusiness?.id,
          ['view.compliance_settings'],
          personalDetails,
        ) ? (
          <PreferencesPage />
        ) : settingsType === 'gpt-key' ? (
          <BusinessGptKey />
        ) : (
          settingsType === 'task-management' &&
          isUserPermitted(
            currentBusiness?.id,
            ['view.jira_settings', 'write.jira_settings'],
            personalDetails,
          ) && (
            <>
              <Title>Task management</Title>
              <JiraIntegrationSettings />
            </>
          )
        )}
      </SettingsTabPage>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const SettingsTabPage = styled.div`
  margin-top: 30px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  color: #000a14;
  margin-bottom: 10px;
  margin-top: 0;
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media (pointer: none), (pointer: coarse) {
    margin-left: 40px;
  }
`;

const StyledLink = styled(NavLink)<{ isactive: string }>`
  width: fit-content;
  color: #454284;
  font-size: 15px;
  opacity: ${(props) => (props.isactive === 'true' ? '1' : '0.8')};
  font-weight: ${(props) => (props.isactive === 'true' ? '600' : '400')};
  display: inline-block;
  margin-right: 20px;
`;
