import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { htmlEntities } from '../../utils/htmlEntities';
import { parseHtmlEntityFromString } from '../../utils';
import {
  CodeBlock,
  CodeButtonProp,
  HeaderIconProp,
} from '../code-block/CodeBlock';
import { CSS_CODE_COPIED_SUCCESS_TEXT } from '../../utils/constants';

interface CssSuggestedFixProps {
  recommendation: string;
  selector: string;
  copyCssCodeFix: (codeFix: string, successText: string) => void;
  modifyCSS?: (codeFix: string) => void;
  codeDescriptionTitle: string;
  headerIcon1: HeaderIconProp | null;
  headerIcon2: HeaderIconProp | null;
  copyIcon: CodeButtonProp;
  codeIcon?: CodeButtonProp;
}

interface CssTemplateType {
  attributeName: string;
  attributeValue: string;
}

export const CssSuggestedFix: React.FC<CssSuggestedFixProps> = ({
  recommendation,
  selector,
  copyCssCodeFix,
  modifyCSS,
  codeDescriptionTitle,
  headerIcon1,
  headerIcon2,
  copyIcon,
  codeIcon,
}) => {
  const [cssCodeFix, setCssCodeFix] = useState<string>('');
  const [cssTemplate, setCssTemplate] = useState<CssTemplateType>({
    attributeName: '',
    attributeValue: '',
  });
  // const isSuggestedCodeCopied = false;
  const lessThanSymbol = parseHtmlEntityFromString(htmlEntities.lessThan);
  const greaterThanSymbol = parseHtmlEntityFromString(htmlEntities.greaterThan);
  const leftCurlyBracket = parseHtmlEntityFromString(
    htmlEntities.leftCurlyBracket,
  );
  const rightCurlyBracket = parseHtmlEntityFromString(
    htmlEntities.rightCurlyBracket,
  );

  const getCssSuggestedFix = useCallback(
    (recommendation: string) => {
      const recommendationList = recommendation.split('Recommendation:');
      const cssRecommendationFromList = recommendationList[1].trim();
      let cssRecommendation: string = '';
      const colourChangeCss: string = 'change text colour to';
      const backgroundChangeCss: string = 'change background to';
      const CssSuggestedFixTemplate = {
        attributeName: '',
        attributeValue: '',
      };

      if (cssRecommendationFromList.includes(colourChangeCss)) {
        cssRecommendation = cssRecommendationFromList
          .replace(colourChangeCss, 'color:')
          .replace('.', ';');
        CssSuggestedFixTemplate.attributeName = 'color';
        const value = cssRecommendation.split('color: ')[1];
        CssSuggestedFixTemplate.attributeName = 'color';
        CssSuggestedFixTemplate.attributeValue = value;
      } else if (cssRecommendationFromList.includes(backgroundChangeCss)) {
        cssRecommendation = cssRecommendationFromList
          .replace(backgroundChangeCss, 'background-color:')
          .replace('.', ';');
        CssSuggestedFixTemplate.attributeName = 'background-color';
        const value = cssRecommendation.split('background-color: ')[1];
        CssSuggestedFixTemplate.attributeName = 'background-color';
        CssSuggestedFixTemplate.attributeValue = value;
      }

      const codeFix = `${selector} ${leftCurlyBracket} ${cssTemplate.attributeName}: ${cssTemplate.attributeValue} ${rightCurlyBracket}`;
      // console.log(cssRecommendation, "cssRecommendation");
      // console.log(CssSuggestedFixTemplate, "CssSuggestedFixTemplate");
      setCssTemplate(CssSuggestedFixTemplate);
      setCssCodeFix(codeFix);
    },
    [
      cssTemplate.attributeName,
      cssTemplate.attributeValue,
      leftCurlyBracket,
      rightCurlyBracket,
      selector,
    ],
  );

  copyIcon.action = () =>
    copyCssCodeFix(cssCodeFix, CSS_CODE_COPIED_SUCCESS_TEXT);
  if (codeIcon) {
    codeIcon.action = () => {
      if (modifyCSS) {
        modifyCSS(cssCodeFix);
      }
    };
  }

  useEffect(() => {
    if (recommendation) {
      getCssSuggestedFix(recommendation);
    }
  }, [getCssSuggestedFix, recommendation]);

  return (
    <Wrapper>
      <CodeBlock
        codeDescriptionTitle={codeDescriptionTitle}
        codeContent={
          <>
            <StyleTag>
              <code>
                {lessThanSymbol}style
                {greaterThanSymbol}
              </code>
            </StyleTag>
            {cssTemplate.attributeName !== '' && cssTemplate.attributeValue && (
              <CssBlock>
                {selector} &nbsp; {leftCurlyBracket}
                <CssRule>
                  <span>{cssTemplate.attributeName}</span>
                  <span>:</span>
                  <span> &nbsp; {cssTemplate.attributeValue}</span>
                </CssRule>
                {rightCurlyBracket}
              </CssBlock>
            )}
            <StyleTag>
              <code>
                {lessThanSymbol}/style
                {greaterThanSymbol}
              </code>
            </StyleTag>
          </>
        }
        copyIcon={copyIcon}
        codeIcon={codeIcon}
        headerIcon1={headerIcon1}
        headerIcon2={headerIcon2}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const StyleTag = styled.span`
  display: block;
  color: #aeead3;
`;

const CssRule = styled.span`
  display: block;
  margin-left: 5%;
`;

const CssBlock = styled.span`
  display: block;
  margin-left: 5%;
`;
