import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

import { useCart } from '../../../../providers/cart-provider';
import { CheckoutState } from './summary/summary';
import { useDispatch } from 'react-redux';
import { setApiError, setApiSuccessMessage } from '../../../../store/actions';
import { usePlans } from '../../../../providers/plans-context';
import PlansService from '@equally-ai-front/common/src/api/plans-service';
import { trackConversion } from '@equally-ai-front/common/src/utils/tapfiliate.util';
import { getWidgetDomains } from '@equally-ai-front/common/src/redux/user-products-slice/user-products';
import { deleteFromCookies } from '../../../../utils/cookie.utils';
import { ENV } from '../../../../common/magicValues';

interface PaypalButtonProps {
  planId: string;
  checkout: CheckoutState;
  closeModal: () => void;
  businessId: number;
  domainId?: number;
}

const PaypalButton = ({
  planId,
  checkout,
  closeModal,
  businessId,
  domainId,
}: PaypalButtonProps) => {
  const [{ isPending }] = usePayPalScriptReducer();
  const dispatch = useDispatch();
  const { getActivePlans, getPlansInInterval } = usePlans();

  const {
    store: { cart },
    actions: { clearCart, onPaymentApproved },
  } = useCart();

  const handleSuccess = async (subscription: any) => {
    const activePlans = await getActivePlans('');

    // eslint-disable-next-line array-callback-return,consistent-return
    const currentPlan = activePlans.find((plan) => {
      if (plan.subscription_id === subscription.id) {
        return plan;
      }
    });

    if (!currentPlan.is_active) {
      await getPlansInInterval();
    }

    if (!currentPlan || !domainId) {
      return;
    }

    await PlansService.attachMultipleDomainsToPlan({
      planID: currentPlan.id,
      businessID: businessId.toString(),
      type: currentPlan.plan_type,
      domainIDs: [domainId],
    });
    // @ts-ignore
    dispatch(getWidgetDomains({ businessId: businessId }));
  };
  const onApprove = async (data: any) => {
    if (checkout.checkoutID) {
      ENV === 'production' &&
        trackConversion(
          checkout.total || 0,
          data.subscriptionID || data.orderID,
        );
      await onPaymentApproved(
        data.subscriptionID || data.orderID,
        checkout.checkoutID,
        String(cart?.user_id),
        businessId,
        handleSuccess,
        (error: string) => dispatch(setApiError(error)),
      );
      deleteFromCookies('tapfiliate_ref_code', true);
      deleteFromCookies('special_code_offer', true);
      dispatch(setApiSuccessMessage('Payment Successful'));
    }
    await clearCart();
    closeModal();
  };

  const createSubscription = async (
    data: Record<string, unknown>,
    actions: any,
  ) => {
    if (checkout.isServiceOnly) {
      return checkout.planID;
    }

    return actions.subscription.create({
      plan_id: planId,
    });
  };

  return (
    <>
      {isPending && <span>Is loading</span>}
      <PayPalButtons
        createSubscription={
          checkout.isServiceOnly ? undefined : createSubscription
        }
        createOrder={checkout.isServiceOnly ? createSubscription : undefined}
        style={{ layout: 'vertical' }}
        onApprove={onApprove}
      />
    </>
  );
};

export default PaypalButton;
