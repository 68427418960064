import styled from '@emotion/styled';
import { PrimaryButton } from '../../redesign/Controls';

export const ProjectDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  border: 2px solid #e4e7eb;
  border-radius: 12px;
  padding: 12px 32px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 16px;

    > h3 {
      margin: 0;
    }
  }

  > h3 {
    font-size: 24px;
    font-weight: 500;
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

export const PaymentDetails = styled.div`
  border: 2px solid #e4e7eb;
  border-radius: 12px;

  margin-top: 20px;

  > h5 {
    font-size: 16px;
    margin: 5px;
    font-weight: 400;
    padding: 12px;
    padding-bottom: 0;
  }
`;

export const GroupContainer = styled.div`
  padding: 5px 12px;
  display: flex;
  justify-content: center;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const NumberInput = styled.input`
  font-size: 24px;
  width: 75px;
  height: 65px;
  text-align: center;
  margin: 0 15px;
  border: none;
`;

export const ButtonContainer = styled.div`
  padding: 12px;

  > button {
    border: none;
    width: 100%;
    padding: 6px 16px;
  }
`;

export const ControlButton = styled(PrimaryButton)`
  height: 60px;
  width: 60px;
  border-radius: 99999px;
  background-color: rgba(0, 0, 0, 0.04);

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 2px solid #e4e7eb;
  padding: 12px;

  & > .cancel {
    background-color: #ec4f4b;
    color: #fff;
  }
`;
