import React from 'react';
import styled from '@emotion/styled';
import { Box, useMediaQuery } from '@mui/material';

import { ReactComponent as BenefitsArrow } from '../../../img/benefit-arrow.svg';
import { TypographyH3 } from '../../../components/common/styled';
import CheckmarkListGrid from '../../../components/common/checkmark-grid';
import { CheckmarkItem } from '../../../components/common/checkmark-item';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PlanTemplateItem } from '@equally-ai/cart-checkout';

interface BenefitsProps {
  priceTemplates: PlanTemplateItem[];
}

const ULTIMATE_BENEFITS = [
  'Compliance dashboard',
  'Dedicated account manager',
  'Multiple subdomains',
  'White Label',
  '24hr support SLA',
  '$10k pledge',
];

const GENERAL_BENEFITS = [
  'Remediation for WCAG, ADA, EAA',
  'Manual validation',
  'Certified attorney accessibility statement',
  'Routine compliance checks',
  'Notifications for critical source code issues',
  '$5k pledge',
];

const Benefits = ({ priceTemplates }: BenefitsProps) => {
  const isWide = useMediaQuery('(min-width: 600px)');

  // const getBenefits = () => {
  //   const masterItem = priceTemplates.find((item) => {
  //     const itemFeatures = item.features;
  //     let hasMasterFeatures = false;
  //     try {
  //       const details = JSON.parse(itemFeatures[0].replaceAll("'", '"'));
  //       hasMasterFeatures = details?.is_features_master || false;
  //     } catch (error) {
  //       hasMasterFeatures = false;
  //     }
  //
  //     return hasMasterFeatures;
  //   });
  //
  //   const featArray = [...(masterItem?.features || [])];
  //   featArray.splice(0, 1);
  //   return featArray;
  // };

  return (
    <Container>
      <ContentWrapper>
        <TypographyH3>
          <TitleArrow>
            All Customers Get
            {isWide && (
              <Arrow>
                <BenefitsArrow />
              </Arrow>
            )}
          </TitleArrow>
        </TypographyH3>
      </ContentWrapper>
      <Box mt="10px" />
      <CheckmarkListGrid>
        {GENERAL_BENEFITS.map((benefit) => (
          <CheckmarkItem key={benefit}>{benefit}</CheckmarkItem>
        ))}
      </CheckmarkListGrid>

      <Box>
        <Box display="flex" justifyContent="center">
          <TypographyH3>
            <TitleArrow>Ultimate Plan</TitleArrow>
          </TypographyH3>
        </Box>

        <CheckmarkListGrid>
          {ULTIMATE_BENEFITS.map((benefit) => (
            <CheckmarkItem key={benefit}>{benefit}</CheckmarkItem>
          ))}
        </CheckmarkListGrid>
      </Box>
    </Container>
  );
};

export default Benefits;

/** Styled Components */

const Container = styled.div`
  padding-bottom: 16px;
  margin-bottom: 32px;
  padding: 0 24px;
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (max-width: 600px) {
    margin-bottom: 16px;
    padding: 0;
    max-height: 100%;
    overflow-y: hidden;
  }
`;

const TitleArrow = styled.span`
  position: relative;
  font-size: 28px;
`;

const Arrow = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 806px;
  text-align: center;
`;
