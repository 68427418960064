import {
  Business,
  NewBusinessWithMember,
  TaskIssue,
  TaskAdditionalInfo,
  DomainKeysAndWidgetUrl,
} from '../types/business';
import { Service } from '../types/services';
import { ApiResponse } from '../utils/axios';
import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';
import graphqlClient from '../utils/graphql.util';

class BusinessAPI {
  static async getBusinesses(): Promise<ApiResponse<Business[]>> {
    let result;
    let error;
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/business`,
      );
    } catch (err: any) {
      console.error('Error getting business', err);
      error = err;
    }
    const { data } = result || {};
    return {
      data: (data?.message || []) as Business[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }
  static async getTeamMembers(
    business_id: string,
  ): Promise<ApiResponse<any[]>> {
    let result;
    let error;
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/team-members/${business_id}`,
      );
    } catch (err: any) {
      console.error('Error getting user team members', err);
      error = err;
    }
    const { data, status } = result || {};
    return {
      data: (data?.message || []) as any[],
      isSuccess: status === 200,
      error: error,
    };
  }
  static async getInvitations(businessId: string): Promise<ApiResponse<any[]>> {
    let result;
    let error;
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/invitations`,
      );
    } catch (err: any) {
      console.error('Error getting user team members', err);
      error = err;
    }
    const { data, status } = result || {};
    return {
      data: (data?.message || []) as any[],
      isSuccess: status === 200,
      error: error,
    };
  }
  static async createBusiness(
    businessInfo: NewBusinessWithMember,
  ): Promise<ApiResponse<Business>> {
    let result;
    let error;
    try {
      result = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/business/create`,
        businessInfo,
      );
    } catch (err: any) {
      console.error('Error creating business', err);
      error = err?.response?.message;
    }
    const { data } = result || {};
    return {
      data: data?.message as Business,
      isSuccess: data?.code === 200,
      error: error,
    };
  }
  static async deleteTeamMember(
    businessId: number,
    id: number,
  ): Promise<ApiResponse<Service[]>> {
    let result;
    let error;
    try {
      result = await axios.delete(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/team-members/${id}`,
      );
    } catch (err: any) {
      console.error('Error deleting team member', err);
      error = err;
    }
    const { data } = result || {};
    return {
      data: (data?.message || []) as Service[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }
  static async addTeamMember(newTeamMember: {
    email: string;
    role: string;
    businessId: number;
  }): Promise<ApiResponse<Service[]>> {
    let result;
    let error;
    try {
      result = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${newTeamMember.businessId}/invitations`,
        {
          email: newTeamMember.email,
          role: newTeamMember.role,
          business_id: newTeamMember.businessId,
        },
      );
    } catch (err: any) {
      console.error('Error adding team member', err);
      error =
        err?.response?.data?.message ||
        'Sorry, your request could not be processed!';
    }
    const { data } = result || {};
    return {
      data: (data?.message || []) as Service[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }
  static async deleteInvitation(
    businessId: number,
    invitationId: number,
  ): Promise<ApiResponse<string>> {
    let result;
    let error;
    try {
      result = await axios.delete(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/invitations/${invitationId}`,
      );
    } catch (err: any) {
      console.error('Error deleting invitation', err);
      error = err.response?.data?.message;
    }
    const { data } = result || {};
    return {
      data: data?.message || '',
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getGPTKey(businessId: number): Promise<ApiResponse<boolean>> {
    let result;
    let error;
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/gpt-key`,
      );
    } catch (err: any) {
      console.error('Error getting business', err);
      error = err;
    }
    const { data } = result || {};
    console.log(data);
    return {
      data: data?.data?.is_exists || false,
      isSuccess: data?.code === 200,
      error: error,
    };
  }
  static async createGPTKey(
    businessId: number,
    key: string,
  ): Promise<ApiResponse<Business[]>> {
    let result;
    let error;
    try {
      result = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/gpt-key`,
        {
          key,
        },
      );
    } catch (err: any) {
      console.error('Error getting business', err);
      error = err;
    }
    const { data } = result || {};
    return {
      data: (data?.message || []) as Business[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getJiraSettings(businessId: number): Promise<ApiResponse<any>> {
    const query = `query GetTaskManagementRequest($payload: GetTaskManagementRequest!) {
        taskManagementHandler(payload: $payload) {
          data
          statusCode
          message
        }
      }`;

    const result = await graphqlClient.execute({
      functionName: 'taskManagementHandler',
      query,
      payload: { businessId, type: 'get' },
    });

    const { data, isSuccess, message } = result;
    return {
      data,
      isSuccess,
      error: message,
    };
  }

  static async createJiraSettings(
    businessId: number,
    payload: {
      apiKey: string;
      userEmail: string;
      boardUrl: string;
    },
  ): Promise<ApiResponse<any>> {
    const { apiKey, userEmail, boardUrl } = payload;
    const query = `
      mutation CreateTaskManagementRequest($payload: CreateTaskManagementRequest!) {
        createTaskManagementConnection(payload: $payload) {
          data
          statusCode
          message
        }
      }
    `;

    const result = await graphqlClient.execute({
      functionName: 'createTaskManagementConnection',
      query,
      payload: {
        provider: 'jira',
        type: 'create',
        connectionConfig: {
          apiKey,
          userEmail,
          boardUrl: boardUrl.replace(/^(https?:\/\/)?(www\.)?/, ''),
        },
        businessId,
      },
    });

    const { data, isSuccess, message } = result;
    return {
      data,
      isSuccess,
      error: message,
    };
  }

  static async createJiraTasks(
    businessId: number,
    issues: TaskIssue[],
    tasksAdditionalInfo: TaskAdditionalInfo[],
  ): Promise<ApiResponse<any>> {
    const query = `
      mutation CreateTaskManagement($payload: CreateTaskManagementRequest!) {
        createTaskManagementConnection(payload: $payload) {
          data
          statusCode
          message
        }
      }
    `;

    const result = await graphqlClient.execute({
      functionName: 'createTaskManagementConnection',
      query,
      payload: {
        businessId,
        provider: 'jira',
        issues,
        tasksAdditionalInfo,
        type: 'create-tasks',
      },
    });

    const { data, isSuccess, message } = result;
    return {
      data,
      isSuccess,
      error: message,
    };
  }

  static async getJiraTasks(businessId: number): Promise<ApiResponse<any>> {
    const query = `
      query GetTaskManagementRequest($payload: GetTaskManagementRequest!) {
        taskManagementHandler(payload: $payload) {
          data
          statusCode
          message
        }
      }
    `;

    const result = await graphqlClient.execute({
      functionName: 'taskManagementHandler',
      query,
      payload: {
        businessId,
        type: 'get-tasks',
        provider: 'jira',
      },
    });

    const { data, isSuccess, message } = result;
    return {
      data,
      isSuccess,
      error: message,
    };
  }

  static async getDomainKeys(
    businessId: number,
  ): Promise<ApiResponse<DomainKeysAndWidgetUrl>> {
    let result;
    let error;
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/domainkeys`,
      );
    } catch (err: any) {
      console.error('Error getting user team members', err);
      error = err;
    }
    const { data, status } = result || {};
    return {
      data: (data?.data || {}) as DomainKeysAndWidgetUrl,
      isSuccess: status === 200,
      error: error,
    };
  }
}
export default BusinessAPI;
