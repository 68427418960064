import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { getScanHistory } from '@equally-ai-front/common/src/redux/historySlice';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import {
  DashboardContainer,
  getCurrentDevDomain,
} from '../../containers/dashboard-container/dashboard-container';
import { formatExpiration } from '../../lib/date';
import { getDevDomains, setApiError } from '../../store/actions';
import { setCurrentDevDomain } from '../../store/dashboard-slice/dashboard';

export const Developers = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );
  const devDomains = useSelector((state) => state.domains.devDomains);
  const devDomain = useSelector((state) => state.dashboard.currentDevDomain);
  const { domainId } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    if (!currentBusiness) {
      return;
    }
    dispatch(getDevDomains(currentBusiness.id));
  }, [dispatch, currentBusiness]);

  useEffect(() => {
    if (!domainId || !devDomains) {
      return;
    }
    const currentDevDomain = getCurrentDevDomain(devDomains, domainId);
    dispatch(setCurrentDevDomain(currentDevDomain));
  }, [devDomains, dispatch, domainId]);

  useEffect(() => {
    analytics.page({
      name: 'Developers Page',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DashboardContainer />;
};
