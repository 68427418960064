import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from './components/header';
import { JiraSettingsForm } from './components/jira-settings-form';
import styled from 'styled-components';
import { getJiraSettings } from '@equally-ai-front/common/src/redux/business-slice/business';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { RootState, AppDispatch } from '../../store';
import { setApiError } from '../../store/actions';
import { JiraSettings } from '@equally-ai-front/common/src/types/business';

export type FormFieldValidationErrors = Record<string, string>;
export type FormFieldName = 'userEmail' | 'apiKey' | 'boardUrl';
export interface JiraSettingsFormFields {
  apiKey: string;
  userEmail: string;
  boardUrl: string;
  isActive: boolean;
}

export const JiraIntegrationSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentBusiness = useSelector(
    (state: RootState) => state.business.currentBusiness,
  );
  const isLoading = useSelector((state: RootState) => state.business.loading);
  const [jiraFormFields, setJiraFormFields] = useState<JiraSettingsFormFields>({
    userEmail: '',
    apiKey: '',
    boardUrl: '',
    isActive: false,
  });
  const [isInputsDisabled, setIsInputsDisabled] = useState(false);
  const [formFieldErrors, setFormFieldErrors] =
    useState<FormFieldValidationErrors>({});

  const handleDisableInputs = (data: JiraSettings) => {
    setJiraFormFields({ ...data, apiKey: 'XXX-XXX-XXX-XXX' });
    setIsInputsDisabled(true);
    setFormFieldErrors({ userEmail: '', boardUrl: '', apiKey: '' });
  };

  const fetchJiraSettings = useCallback(async () => {
    if (!currentBusiness.id) return;
    const { payload } = await dispatch(
      getJiraSettings({ businessId: currentBusiness.id }),
    );
    if (!payload) return;

    const { isSuccess, error, data } = payload;
    if (!isSuccess && error) {
      dispatch(setApiError(error));
      return;
    }

    if (!data) return;
    data.isActive ? handleDisableInputs(data) : setJiraFormFields(data);
  }, [dispatch, currentBusiness]);

  useEffect(() => {
    fetchJiraSettings();
  }, [fetchJiraSettings]);

  return (
    <Wrapper>
      <JiraSettingsForm
        jiraFormFields={jiraFormFields}
        setJiraFormFields={setJiraFormFields}
        formFieldErrors={formFieldErrors}
        setFormFieldErrors={setFormFieldErrors}
        currentBusiness={currentBusiness}
        isInputsDisabled={isInputsDisabled}
        setIsInputsDisabled={setIsInputsDisabled}
        handleDisableInputs={handleDisableInputs}
        header={<Header />}
      />
      <LoadingView open={isLoading} loadingText="Getting your jira settings" />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
