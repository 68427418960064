import { CustomToolTip } from '@equally-ai-front/common/src/components/custom-tooltip/CustomToolTip';
import Zoom from '@mui/material/Zoom';
import React from 'react';
import styled from 'styled-components';

interface ImpactLevelBadgeProps {
  impactNumber: number;
  iconSrc: string;
  alt: string;
  backgroundColor: string;
  color: string;
  toolTipMessage: string;
  padding?: string;
  customStyle?: React.CSSProperties | undefined;
  children?: React.ReactNode;
}

export const ImpactLevelBadge: React.FC<ImpactLevelBadgeProps> = ({
  impactNumber,
  iconSrc,
  backgroundColor,
  color,
  toolTipMessage,
  padding,
  customStyle,
  alt,
  children,
}) => {
  return (
    <CustomToolTip
      title={toolTipMessage}
      placement="top"
      TransitionComponent={Zoom}
      arrow
    >
      <Wrapper bgColor={backgroundColor} style={customStyle} padding={padding}>
        <InfoIcon src={iconSrc} alt={alt} />
        <InfoContainer>
          {children}
          <Percentage color={color}>{impactNumber}</Percentage>
        </InfoContainer>
      </Wrapper>
    </CustomToolTip>
  );
};

interface WrapperProps {
  bgColor: string;
  padding?: string;
}

const Wrapper = styled.div<WrapperProps>`
  padding: ${(props) => (props.padding ? props.padding : '4px 8px 4px 12px')};
  width: 100%;
  margin-bottom: 10px;
  height: 35px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${(props) => props.bgColor};
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    height: 26px;
  }

  @media screen and (max-width: 768px) {
    height: 35px;
    justify-content: center;
  }
`;

interface PercentageProps {
  color: string;
}

const Percentage = styled.span<PercentageProps>`
  display: inline-block;
  margin-right: 1px;
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1000px) {
    font-size: 12px;
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const InfoIcon = styled.img`
  width: 18px;
  display: inline-block;
  margin-left: 1px;
  margin-right: 2px;

  @media screen and (max-width: 1000px) {
    width: fit-content;
  }

  @media screen and (max-width: 768px) {
    width: 24px;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
