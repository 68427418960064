import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

import { useCart } from '../../../../providers/cart-provider';
import { ContainedButton as Button } from '../../../../components/common/ActionButton';

interface PriceCardButtonProps {
  id: number;
  isSelected: boolean;
  duration: string;
  setConfirmationModal: (value: boolean) => void;
  type: string;
  ctaLabel: string;
}

export const PriceCardButton = ({
  id,
  isSelected,
  duration,
  setConfirmationModal,
  type,
  ctaLabel,
}: PriceCardButtonProps) => {
  const {
    actions,
    store: { cart, isLoading },
  } = useCart();

  const cartItems = cart?.cart_items || [];

  const selectedItem = cartItems.filter(
    (item: any) => item.plan_template_id === id,
  );
  let selectedItemCartID: number;

  if (selectedItem) {
    selectedItemCartID = selectedItem[0]?.id;
  }

  const inCart = Boolean(selectedItem?.length);
  const loadingRef = useRef(false);

  useEffect(() => {
    if (!isLoading) {
      loadingRef.current = false;
    }
  }, [isLoading]);

  const handleCheckout = async () => {
    loadingRef.current = true;

    await actions.handleCheckout();

    loadingRef.current = false;
  };

  const handleAddToCart = async () => {
    loadingRef.current = true;
    const cartContainsOnlyServiceItem = cart?.cart_items.every(
      (item) => item.plan_template.product_type === 'service',
    );
    if (
      !cart?.cart_items ||
      cart?.cart_items?.length === 0 ||
      type === 'service' ||
      cartContainsOnlyServiceItem
    ) {
      await actions.addToCart(id, duration);
      await handleCheckout();
      return;
    }

    const isSameDuration = cart?.cart_items.every((item) => {
      const isService = item.plan_template.product_type === 'service';
      if (isService) {
        return isService;
      }

      return item.plan_template.duration_unit === duration;
    });

    if (isSameDuration) {
      await actions.addToCart(id, duration);
      await handleCheckout();
      return;
    }

    sessionStorage.setItem('equally_ca-temp', JSON.stringify({ id, duration }));
    return setConfirmationModal(true);
  };

  if (!inCart) {
    return (
      <>
        <ContainedButton onClick={handleAddToCart} disabled={isLoading}>
          {ctaLabel}
        </ContainedButton>
        {loadingRef.current && isLoading && <CircularProgress />}
      </>
    );
  }

  return (
    <ContainedButton
      onClick={() => actions.removeFromCart(selectedItemCartID)}
      fullWidth
      disabled={isLoading}
    >
      Remove
    </ContainedButton>
  );
};

// const Quantity = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//
//   & > button {
//     margin-top: -10px;
//     border-radius: 100%;
//     min-width: 30px !important;
//     max-width: 30px !important;
//     height: 30px;
//     padding: 5px !important;
//     font-size: 16px;
//     margin: 0 10px;
//   }
// `;

const ContainedButton = styled(Button)`
  text-transform: initial;
  width: fit-content;

  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;
