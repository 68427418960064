import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton } from '@mui/material';
import styled from 'styled-components';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface AccessiblePopUpProps {
  isOpen: boolean;
  handleToggle: (evt: any) => void;
  dialogPaperStyles?: Record<string, string>;
  children: React.ReactNode;
}

export const AccessiblePopUp = (props: AccessiblePopUpProps) => {
  const { isOpen, handleToggle, dialogPaperStyles, children } = props;
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleToggle}
      fullScreen={isMobile}
      $dialogpaperstyles={dialogPaperStyles}
    >
      <CloseButton
        aria-label="close"
        onClick={handleToggle}
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </CloseButton>
      <>{children}</>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)<{
  $dialogpaperstyles?: Record<string, string>;
}>`
  & .MuiDialog-paper {
    max-width: 900px;
    ${(props) => props.$dialogpaperstyles || ''};

    @media (pointer: none), (pointer: coarse) {
      max-width: 95%;
      max-height: 93vh;
      border-radius: 8px;
      justify-content: center;
    }
  }
`;

const CloseButton = styled(IconButton)`
  &:focus {
    border: 2px solid #3e3c77;
  }
`;
