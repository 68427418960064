import styled from 'styled-components';

export const ScanStepContainer = styled.div`
  overflow: hidden;
  border: 2px solid #e4e7eb;
  border-radius: 14px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;
