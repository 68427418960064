import styled from '@emotion/styled';
import React from 'react';

interface CaseProps {
  children: React.ReactNode;
  strike: boolean;
}
export const Case = ({ children, strike }: CaseProps) => {
  return (
    <CaseContent strike={strike}>
      • <CaseText strike={strike}>{children}</CaseText>
    </CaseContent>
  );
};

/* Styled Components */

const CaseContent = styled('span', {
  shouldForwardProp: (prop) => prop !== 'strike',
})`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  color: ${({ theme }) => theme.palette.on.darkLow};
  opacity: ${({ strike }) => (strike ? 0.48 : 1)};
`;

const CaseText = styled('span', {
  shouldForwardProp: (prop) => prop !== 'strike',
})`
  text-decoration: ${({ strike }) => (strike ? 'line-through' : 'none')};
`;
