import { useCallback, useState } from 'react';
import { FilterCondition, ItemFilter } from '../types';

interface UseAddNewFilterProps {
  allFilterOptions: Record<string, ItemFilter>;
  visibleData: any[];
}

export const useAddNewFilter = (props: UseAddNewFilterProps) => {
  const { allFilterOptions, visibleData } = props;
  const [newFilter, setNewFilter] = useState<ItemFilter | undefined>();
  const [filterValueOptions, setFilterValueOptions] = useState<any[]>([]);

  const handleOptionClick = (filterKey: string | undefined) => {
    if (!filterKey) {
      setNewFilter(undefined);
      setFilterValueOptions([]);
      return;
    }

    const filterOption = allFilterOptions[filterKey];
    const newFilterValueOptions =
      filterOption.filterType === 'boolean'
        ? ['True', 'False']
        : [...new Set(visibleData.map((item) => item[filterOption.key]))];

    setNewFilter({ ...filterOption });
    setFilterValueOptions([...newFilterValueOptions]);
  };

  const isFilterFulfilled = useCallback(() => {
    if (!newFilter?.key) {
      return false;
    }

    if (newFilter.isPreset) {
      return true;
    }

    if (newFilter.filterCondition === FilterCondition.Between) {
      return newFilter.value2 !== null && newFilter.value2 !== undefined;
    }

    return newFilter.value !== null && newFilter.value !== undefined;
  }, [newFilter]);

  const handleFilterValueChange = useCallback(
    (key: string, value: string) => {
      if (!newFilter) {
        return;
      }

      const filter = { ...newFilter };
      filter.value = value;
      setNewFilter(filter);
    },
    [allFilterOptions, newFilter],
  );

  const handleFilterSecondValueChange = useCallback(
    (key: string, value2: string) => {
      if (!newFilter) {
        return;
      }

      const filter = { ...newFilter };
      filter.value2 = value2;
      setNewFilter(filter);
    },
    [allFilterOptions, newFilter],
  );
  const handleFilterOperatorChange = useCallback(
    (
      operator: string,
      filterValue?: string | number,
      isSatisfied?: (val: any) => boolean,
      isPreset?: boolean,
    ) => {
      if (!newFilter) {
        return;
      }
      const filter = { ...newFilter };
      filter.filterCondition = operator as FilterCondition;
      filter.value = filterValue;
      filter.value2 = undefined;
      filter.isSatisfied = isSatisfied;
      filter.isPreset = isPreset;
      setNewFilter(filter);
    },
    [allFilterOptions, newFilter],
  );
  return {
    handleOptionClick,
    isFilterFulfilled,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    handleFilterOperatorChange,
    newFilter,
    filterValueOptions,
  };
};
