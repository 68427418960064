import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import { ColumnOption, IssueCreationType } from './popup';
import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import {
  HeaderWrapper,
  ArrowIconWrapper,
  Divider,
  HeaderText,
} from './jira-tasks';
import { JiraTaskIssue } from './jira-task-issue';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import {
  TaskIssue,
  TaskAdditionalInfo,
} from '@equally-ai-front/common/src/types/business';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { OutlinedInput } from '@mui/material';

interface JiraTaskStepperProps {
  columns: ColumnOption[];
  issueCreationType: IssueCreationType;
  selectedIssues: TaskIssue[];
  selectedDevToolsIssues: DevtoolsSolution[];
  accordionState: AccordionState;
  handleToggleAccordion: (id: string) => void;
  isGrouped: boolean;
  tasksAdditionalInfo: TaskAdditionalInfo[];
  setTasksAdditionalInfo: React.Dispatch<
    React.SetStateAction<TaskAdditionalInfo[]>
  >;
}

export const JiraTaskStepper = (props: JiraTaskStepperProps) => {
  const {
    columns,
    issueCreationType,
    selectedIssues,
    selectedDevToolsIssues,
    accordionState,
    handleToggleAccordion,
    isGrouped,
    tasksAdditionalInfo,
    setTasksAdditionalInfo,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = selectedIssues.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const currentIssue = selectedIssues[activeStep];
  const devToolsIssue = selectedDevToolsIssues.find(
    ({ id }) => id === currentIssue.id,
  );
  const taskAdditionalInfo = tasksAdditionalInfo[activeStep];

  const handleChangeTaskAdditionalInfo = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = evt.target;
    setTasksAdditionalInfo((prev) =>
      prev.map((current) => {
        if (current.issueGroupId !== activeStep) return current;
        return { ...current, [name]: value };
      }),
    );
  };

  if (!devToolsIssue) return <></>;

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        {maxSteps > 1 && (
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
              </Button>
            }
          />
        )}
        <div>
          <Container key={currentIssue.id}>
            <HeaderText
              onClick={(evt) => evt.stopPropagation()}
              style={{ width: '100%' }}
            >
              <JiraTaskInput
                id="summary"
                name="summary"
                value={taskAdditionalInfo.summary}
                type="text"
                onChange={handleChangeTaskAdditionalInfo}
                onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                  if (evt.code === 'Space') {
                    evt.preventDefault();
                    setTasksAdditionalInfo((prev) =>
                      prev.map((current) => {
                        if (current.issueGroupId !== activeStep) return current;
                        return {
                          ...current,
                          summary: current.summary + ' ',
                        };
                      }),
                    );
                  }
                }}
                placeholder="Enter task name"
              />
            </HeaderText>
            <Divider />
            <JiraTaskIssue
              index={activeStep}
              columns={columns}
              issueCreationType={issueCreationType}
              devToolsIssue={devToolsIssue}
              tasksAdditionalInfo={tasksAdditionalInfo}
              setTasksAdditionalInfo={setTasksAdditionalInfo}
              isGrouped={isGrouped}
            />
          </Container>
        </div>
      </Box>
    </div>
  );
};

const Container = styled.div`
  overflow: hidden;
  border: 2px solid #e4e7eb;
  border-radius: 8px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  > div > h3 {
    padding: 12px 15px;
  }
`;

const ArrowIcon = styled.img<{ shouldrotate: boolean }>`
  width: 25px;
  height: 20px;
  transform: ${(props) => (props.shouldrotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

const JiraTaskInput = MuiStyled(OutlinedInput)({
  '&.MuiOutlinedInput-root': {
    width: '100%',
  },
  '&.MuiOutlinedInput-root input': {
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: 15,
    fontWeight: 500,
  },
  '& fieldset': {
    border: 'none',
  },
  '&:hover fieldset': {
    border: 'none',
  },
});
