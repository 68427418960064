// import LinearProgress from "@mui/material/LinearProgress";
import React from 'react';
import styled from 'styled-components';

interface MuiLinearProgressProps {
  resolvedIssues: number;
  totalIssues: number;
  textFontSize?: number;
}

export const IssuesLinearProgress = ({
  resolvedIssues,
  totalIssues,
  textFontSize,
}: MuiLinearProgressProps) => {
  // const [progress, setProgress] = React.useState<number>(0);

  // React.useEffect(() => {
  //   const progressPercentage = (resolvedIssues / totalIssues) * 100;
  //   setProgress(progressPercentage);
  // }, [totalIssues, resolvedIssues]);

  return (
    <Wrapper>
      <TotalIssues>
        <TotalIssuesLabel fontSize={textFontSize}>
          Total Issues:
        </TotalIssuesLabel>
        <TotalIssuesNum fontSize={textFontSize}>{totalIssues}</TotalIssuesNum>
      </TotalIssues>
      {/* <LinearProgress
        variant="determinate"
        className="linear-progress"
        value={progress}
      /> */}
      {/* <IssuesLabelWrapper>
        <IssuesLabel fontSize={textFontSize}>
          {resolvedIssues}&nbsp;done
        </IssuesLabel>
        <IssuesLabel fontSize={textFontSize}>
          {totalIssues - resolvedIssues}&nbsp;to fix
        </IssuesLabel>
      </IssuesLabelWrapper> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .linear-progress {
    background-color: #f2f2f2;

    span {
      background-color: #4ad493;
    }
  }
`;

const TotalIssues = styled.p`
  color: #000a14;
  margin-bottom: 10px;
`;

const TotalIssuesLabel = styled.span<{ fontSize?: number }>`
  font-weight: 300;
  display: inline-block;
  margin-right: 2px;
  font-size: ${(p) => (p.fontSize ? `${p.fontSize}px` : '15px')};
`;

const TotalIssuesNum = styled.span<{ fontSize?: number }>`
  font-weight: 600;
  font-size: ${(p) => (p.fontSize ? `${p.fontSize + 3}px` : '18px')};
`;

// const IssuesLabelWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 5px;
// `;

// const IssuesLabel = styled.p<{ fontSize?: number }>`
//   color: #9ea6b4;
//   margin: 0;
//   font-size: ${(p) => (p.fontSize ? `${p.fontSize}px` : "")};
// `;
