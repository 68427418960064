import moment from 'moment';

export const hasFreeTrialPassed = (dateString) => {
  const currentDate = new Date();
  const date = new Date(dateString);
  const timeDiff = date - currentDate; // Get the time difference in milliseconds
  const fourteenDaysInMilliseconds = 14 * 24 * 60 * 60 * 1000;

  return timeDiff > fourteenDaysInMilliseconds;
};

export const getDaysLeft = (expirationDate) => {
  const currentDate = new Date();
  const expiration = new Date(expirationDate);

  const timeDiff = expiration.getTime() - currentDate.getTime();

  const daysRemaining = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));
  return daysRemaining;
};

export const formatExpiration = (expirationDate) => {
  const daysRemaining = getDaysLeft(expirationDate);

  if (daysRemaining <= 0) {
    return 'Expired';
  }
  const monthsRemaining = Math.ceil(daysRemaining / 30);

  if (monthsRemaining >= 2) {
    return `${monthsRemaining} months`;
  }

  return `${daysRemaining} day${daysRemaining > 1 ? 's' : ''}`;
};

export const formatDate = (date) => {
  const longMonths = ['September', 'October', 'November', 'December'];

  const monthName = moment(date).format('MMMM');

  if (longMonths.includes(monthName)) {
    return moment(date).format('DD MMM YYYY');
  }

  return moment(date).format('DD MMMM YYYY');
};

export const getTrialEndDate = (trialDuration, trialUnit) => {
  const now = moment();

  // Calculate the trial end date based on the duration and unit
  let trialEndDate;
  if (trialUnit === 'DAY') {
    trialEndDate = now.add(trialDuration, 'days');
  } else if (trialUnit === 'MONTH') {
    trialEndDate = now.add(trialDuration, 'months');
  } else {
    throw new Error('Invalid trial unit. Use "DAY" or "MONTH".');
  }

  return trialEndDate.format('DD/MM/YYYY');
};
