import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDomainToPlan } from '../../../common/helpers';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import {
  getActivePlans,
  getDevDomains,
  getDomains,
  setApiError,
  setApiSuccessMessage,
} from '../../../store/actions';
import { PrimaryButton } from '../../redesign/Controls';
import { DrawerPanel } from '../../redesign/DrawerPanel';
import { ModalPanel } from '../../redesign/ModalPanel';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 60,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  select: {
    marginRight: '16px',
  },
  button: {
    width: '100%',
    marginBottom: '10px',
  },
}));

export const mapFunctionToType = {
  service: () => console.log(''),
  widget: getDomains(),
  developer: getDevDomains(),
};

const AddDomainModal = ({ open, handleClose, planID, type }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const dispatch = useDispatch();
  const [domain, setDomain] = useState('');
  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );

  const Modal = isMobile ? DrawerPanel : ModalPanel;

  const handleSubmit = async () => {
    if (domain) {
      try {
        await addDomainToPlan({ planID, type, domains: [domain] });
        dispatch(setApiSuccessMessage('Website Added Successfully'));
        setDomain('');
        dispatch(mapFunctionToType[type]);
        dispatch(getActivePlans({ type, businessId: currentBusiness.id }));
        handleClose();
      } catch (error) {
        setDomain('');
        dispatch(setApiError('Something went wrong!'));
      }
    }
  };

  const onCloseModal = () => {
    setDomain('');
    handleClose();
  };

  return (
    <Modal open={open} handleClose={onCloseModal} title="Add Website">
      <div className="add-website-fields">
        <TextField
          label="Website URL"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={domain}
          onChange={(e) => {
            setDomain(e.target.value);
          }}
          className={classes.input}
        />
      </div>

      <PrimaryButton
        color="primary"
        variant="contained"
        onClick={handleSubmit}
        className={classes.button}
      >
        Add Website
      </PrimaryButton>
    </Modal>
  );
};

AddDomainModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  planID: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default AddDomainModal;
