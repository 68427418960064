import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  createContext,
  useRef,
} from 'react';
import PlansService from '@equally-ai-front/common/src/api/plans-service';

interface PlanContextType {
  allPlans: any[];
  activePlans: any[];
  getActivePlans: (type: string) => Promise<any[]>;
  getPlansInInterval: () => Promise<void>;
}

const PlanContext = createContext<PlanContextType>({
  allPlans: [],
  activePlans: [],
  getActivePlans: async () => [],
  getPlansInInterval: async () => {},
});

interface PlanProviderProps {
  children: React.ReactNode;
  businessId?: number;
}

const PlansProvider = ({ children, businessId }: PlanProviderProps) => {
  const [allPlans, setPlans] = useState<any[]>([]);
  const [activePlans, setActivePlans] = useState<any[]>([]);
  const checkPlansIntervalRef = useRef<NodeJS.Timer>();
  const intervalCountRef = useRef(0);

  const getActivePlans = useCallback(
    async (type: string): Promise<any[]> => {
      if (!businessId) {
        return [];
      }
      const { data, error, isSuccess } = await PlansService.getPlans(
        businessId,
        type,
      );

      if (!isSuccess) {
        console.error('Failed to fetch plans', error);
      }
      setActivePlans(data);
      return data;
    },
    [businessId],
  );

  const checkPlansInInterval = useCallback(async () => {
    const data = await getActivePlans('');

    if (
      data.every((plan: any) => plan.is_active) ||
      intervalCountRef.current >= 5
    ) {
      clearInterval(checkPlansIntervalRef.current);
      intervalCountRef.current = 0;
      return;
    }

    intervalCountRef.current++;
  }, [getActivePlans]);

  const getPlansInInterval = useCallback(async () => {
    clearInterval(checkPlansIntervalRef.current);

    checkPlansIntervalRef.current = setInterval(async () => {
      await checkPlansInInterval();
    }, 1000);
  }, [checkPlansInInterval]);

  useEffect(() => {
    if (!businessId) {
      return;
    }
    void getActivePlans('');
  }, [getActivePlans, businessId]);

  return (
    <PlanContext.Provider
      value={{ allPlans, activePlans, getActivePlans, getPlansInInterval }}
    >
      {children}
    </PlanContext.Provider>
  );
};

const usePlans = () => {
  const context = useContext(PlanContext);
  if (context === undefined) {
    throw new Error('usePlans must be used within a PlanProvider');
  }
  return context;
};

export { usePlans, PlansProvider };
