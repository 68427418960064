import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { setApiSuccessMessage } from '../../../store/actions';
import { ScanStepsContainer } from '../../scan-steps-container/scan-steps-container';
import { DomainInformationSettings } from './domain-information-settings';
import { RootState } from '../../../store';
import { MainIssues } from './main-issues';
import { OverviewContainer } from '../../overview-container/overview-container';
import { NewScanInfo } from '@equally-ai-front/common/src/types/issues';

/* const getTagName = (context: string): string => {
  const parser = new DOMParser();
  const doc: Document = parser.parseFromString(context, 'text/html');
  let bodyContent: HTMLCollection;

  if (doc.head.children[0]) {
    bodyContent = doc.head.children;
  } else if (doc.body.children[0]) {
    bodyContent = doc.body.children;
  } else {
    bodyContent = doc.children;
  }
  const tagName: string = bodyContent[0].tagName.toLowerCase();
  return tagName;
}; */

interface DashboardContentProps {
  wcagReport: ReportData;
  devDomain: DevDomain;
  onAudit: (url: string, cb?: () => void, scanDetails?: NewScanInfo) => void;
  showFreeTrialBanner: boolean;
  isOnboardingDashboard: boolean;
}

export const DashboardContent = (props: DashboardContentProps) => {
  const { devDomain, onAudit, showFreeTrialBanner, isOnboardingDashboard } =
    props;
  const { severityFilter, reportType, currentScan } = useSelector(
    (state: any) => ({
      currentReportUrl: state.dashboard.currentReportUrl,
      severityFilter: state.dashboard.severityFilter,
      currentIssueStatus: state.dashboard.currentIssueStatus,
      currentScan: state.dashboard.currentScan,
      reportType: state.dashboard.reportType,
    }),
  );

  const dispatch = useDispatch();
  const { history } = useSelector((state: RootState) => ({
    history: state.scanHistory.history,
  }));

  const successMessage = useSelector(
    (state: any) => state.navigation.successMessage,
  );

  const setSuccessMessage = (message: string) => {
    dispatch(setApiSuccessMessage(message));
  };

  return (
    <Wrapper isFreeTrialBannerOpen={showFreeTrialBanner}>
      <Routes>
        <Route
          path="overview"
          element={
            <OverviewContainer devDomain={devDomain} onAudit={onAudit} />
          }
        />
        <Route
          path={'scans/*'}
          element={
            <MainContent>
              <MainIssues
                reportType={reportType}
                severity={severityFilter}
                devDomain={devDomain}
                onAudit={onAudit}
                currentScan={currentScan}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                history={history}
              />
            </MainContent>
          }
        />
        {/* <Route
            path="settings"
            element={<ScanSettingsForm devDomain={devDomain} />}
          /> */}
        <Route
          path="actions"
          element={<ScanStepsContainer devDomain={devDomain} />}
        />
        <Route
          path="settings"
          element={
            <DomainInformationSettings
              devDomain={devDomain}
              isOnboardingDashboard={isOnboardingDashboard}
            />
          }
        />
      </Routes>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isFreeTrialBannerOpen: boolean }>`
  width: 100%;
  height: ${(p) => (p.isFreeTrialBannerOpen ? 'calc(100% - 60px)' : '100%')};

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
`;

const MainContent = styled.div`
  height: 100%;
`;
