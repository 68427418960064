import { getElementAttributes } from '../../lib';
import { isNullOrUndefined } from '../../utils/helpers';
import {
  CustomElementTypes,
  FilterCondition,
  FilterOperatorOptions,
} from './types';

export const stringFilterOptions: FilterOperatorOptions[] = [
  {
    value: FilterCondition.NotEqualTo,
    label: 'Not Equal To',
  },
  {
    value: FilterCondition.EqualTo,
    label: 'Equal To',
  },
  {
    value: FilterCondition.Contains,
    label: 'Contains',
  },
  {
    value: FilterCondition.DoesNotContain,
    label: 'Does Not Contain',
  },
  {
    value: FilterCondition.Exists,
    label: 'Exists',
  },
  {
    value: FilterCondition.DoesNotExist,
    label: 'Does Not Exist',
  },
];

export const booleanFilterOptions: FilterOperatorOptions[] = [
  {
    value: FilterCondition.NotEqualTo,
    label: 'Not Equal To',
  },
  {
    value: FilterCondition.EqualTo,
    label: 'Equal To',
  },
];

export const numberFilterOptions: FilterOperatorOptions[] = [
  {
    value: FilterCondition.EqualTo,
    label: 'Equal To',
  },
  {
    value: FilterCondition.NotEqualTo,
    label: 'Not Equal To',
  },
  {
    value: FilterCondition.GreaterThan,
    label: 'Greater Than',
  },
  {
    value: FilterCondition.SmallerThan,
    label: 'Smaller Than',
  },
  {
    value: FilterCondition.Between,
    label: 'Between',
  },
];

export const dateFilterOptions: FilterOperatorOptions[] = [
  {
    value: FilterCondition.Before,
    label: 'Before',
  },
  {
    value: FilterCondition.After,
    label: 'After',
  },
  {
    value: FilterCondition.Between,
    label: 'Between',
  },
  {
    value: FilterCondition.Exists,
    label: 'Exists',
  },
  {
    value: FilterCondition.DoesNotExist,
    label: 'Does Not Exist',
  },
];

export const imageElementFilterCondition: FilterOperatorOptions[] = [
  {
    value: FilterCondition.Exists,
    label: 'Complete alt attribute',
    filterValue: 'alt',
    isSatisfied: (obj: any) => !isNullOrUndefined(obj.alt) && obj.alt !== '',
    isPreset: true,
  },
  {
    value: FilterCondition.EqualToPreset,
    label: 'Empty alt',
    filterValue: 'empty alt',
    isSatisfied: (obj: any) => obj.alt === '',
    isPreset: true,
  },
  {
    value: FilterCondition.DoesNotExist,
    label: 'Missing alt attribute',
    filterValue: 'missing alt',
    isSatisfied: (obj: any) => {
      if (obj.elementType !== CustomElementTypes.Images) {
        return false;
      }

      return isNullOrUndefined(obj.alt);
    },
    isPreset: true,
  },
];

export const headingsElementFilterCondition: FilterOperatorOptions[] = [
  {
    value: FilterCondition.EqualTo,
    label: 'Tabindex is 0',
    filterValue: 0,
    isPreset: true,
  },
  {
    value: FilterCondition.EqualToPreset,
    label: 'Tabindex is -1',
    filterValue: -1,
    isPreset: true,
  },
  {
    value: FilterCondition.GreaterThan,
    label: 'Tabindex is greater than 0',
    filterValue: 0,
    isPreset: true,
  },
];

export const buttonElementFilterCondition: FilterOperatorOptions[] = [
  {
    value: FilterCondition.DoesNotExist,
    label: 'Has no accessible name',
    filterValue: 'Missing Attributes',
    isSatisfied: (obj: any) =>
      getElementAttributes(obj.originalElement).length === 0,
    isPreset: true,
  },
  {
    value: FilterCondition.Exists,
    label: 'Has empty aria attributes',
    filterValue: 'Empty Attributes',
    isSatisfied: (obj: any) =>
      getElementAttributes(obj.originalElement)
        .filter((attribute) => attribute.attr.startsWith('aria'))
        .some((attr) => attr.value === ''),
  },
];

export const inputElementsElementFilterCondition: FilterOperatorOptions[] = [
  {
    value: FilterCondition.EqualToPreset,
    label: 'Tabindex is -1',
    filterValue: -1,
    isPreset: true,
  },
  {
    value: FilterCondition.GreaterThan,
    label: 'Tabindex is greater than 0',
    filterValue: 0,
    isPreset: true,
  },
];

export const linkElementsElementFilterCondition: FilterOperatorOptions[] = [
  {
    value: FilterCondition.EqualToPreset,
    label: 'Opens in a new window',
    isSatisfied: (obj) =>
      !isNullOrUndefined(obj.attributes.target) &&
      obj.attributes.target === '_blank',
    filterValue: '_blank',
    isPreset: true,
  },
  {
    value: FilterCondition.GreaterThan,
    label: 'Has Negative Tabindex',
    isSatisfied: (obj) =>
      !isNullOrUndefined(obj.attributes.tabindex) &&
      obj.attributes.tabindex === '-1',
    filterValue: '-1',
    isPreset: true,
  },
  {
    value: FilterCondition.DoesNotExist,
    label: 'Has No Href',
    isSatisfied: (obj) => {
      if (obj.elementType !== CustomElementTypes.Links) {
        return true;
      }

      return isNullOrUndefined(obj.attributes.href);
    },
    isPreset: true,
  },
  {
    value: FilterCondition.Contains,
    label: 'Has no context',
    isSatisfied: (obj) => {
      if (obj.elementType !== CustomElementTypes.Links) {
        return true;
      }

      const text =
        obj.originalElement.innerText + (obj.attributes['aria-label'] || '');
      const nonContextualText = [
        'click here',
        'more',
        'read more',
        'view more',
        'view',
        'see here',
      ];
      return nonContextualText.includes(text.trim().toLowerCase());
    },
    filterValue: 'more',
    isPreset: true,
  },
  {
    value: FilterCondition.Exists,
    label: 'Has no accessible name',
    isSatisfied: (obj) => {
      if (obj.elementType !== CustomElementTypes.Links) {
        return true;
      }

      const text =
        obj.originalElement.innerHTML +
        (obj.attributes['aria-label'] || '') +
        (obj.attributes['title'] || '');
      const relationElId = obj.attributes['aria-labelledby'];

      if (!relationElId) {
        return text === '';
      }

      const relationElText = document.querySelector(relationElId)?.innerText;

      return !relationElText || relationElText === '';
    },
    filterValue: '',
    isPreset: true,
  },
];

const iframeElementFilterCondition: FilterOperatorOptions[] = [
  {
    value: FilterCondition.DoesNotExist,
    label: 'Has no title',
    filterValue: 'title',
    isSatisfied: (obj) => {
      if (obj.elementType !== CustomElementTypes.Iframes) {
        return true;
      }

      return !isNullOrUndefined(obj.attributes['title']);
    },
    isPreset: true,
  },
];

export const CustomElementsFilterCondition: Record<
  string,
  FilterOperatorOptions[]
> = {
  images: imageElementFilterCondition,
  headings: headingsElementFilterCondition,
  buttons: buttonElementFilterCondition,
  links: linkElementsElementFilterCondition,
  inputs: inputElementsElementFilterCondition,
  iframes: iframeElementFilterCondition,
};
