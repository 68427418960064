import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

interface TableHeaderProps {
  isColorShadeOfWhiteOrGrey: boolean;
}
export const TableHeader = (props: TableHeaderProps) => {
  const { isColorShadeOfWhiteOrGrey } = props;
  const borderColor = '#e3e8fb';
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      borderBottomWidth: 2,
      borderTop: 'none',
      alignItems: 'center',
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: 12,
      height: 50,
      color: isColorShadeOfWhiteOrGrey ? '#ffffff' : '#222529',
      fontFamily: 'DM Sans',
    },
    number: {
      width: '10%',
      textAlign: 'center',
    },
    issueGroupName: {
      width: '25%',
      textAlign: 'left',
    },
    description: {
      width: '45%',
      textAlign: 'left',
    },
    issues: {
      width: '10%',
      textAlign: 'center',
    },
    priority: {
      width: '10%',
      textAlign: 'center',
    },
  });

  return (
    <View style={styles.container}>
      <Text style={styles.number}>#</Text>
      <Text style={styles.issueGroupName}>Issue Group</Text>
      <Text style={styles.description}>Description</Text>
      <Text style={styles.issues}>Issues</Text>
      <Text style={styles.priority}>Priority</Text>
    </View>
  );
};
