import React, { useState, useMemo } from 'react';
import { StepOneFormFields } from './step-one-form-fields';
import { StepTwoFormFields } from './step-two-form-fields';
import { ColumnOption, JiraTaskFormFields, SelectOption } from './popup';
import {
  TaskIssue,
  TaskAdditionalInfo,
} from '@equally-ai-front/common/src/types/business';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { createJiraTasks } from '@equally-ai-front/common/src/redux/business-slice/business';
import { capitalizeFirstLetter } from '@equally-ai-front/common/src';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { RootState, AppDispatch } from '../../store';
import { setApiError, setApiSuccessMessage } from '../../store/actions';
import { LoadingView } from './loading-view';

const validateTaskAdditionalInfo = (data: TaskAdditionalInfo[]): boolean => {
  let isValid = false;
  for (const additionalInfo of data) {
    if (!additionalInfo.summary) {
      isValid = false;
      return isValid;
    }
    isValid = true;
  }
  return isValid;
};

interface JiraTaskFormProps {
  columns: ColumnOption[];
  handleClose: () => void;
  issues: Issue[];
  issuesDevToolsSolution: DevtoolsSolution[];
  selectedIssuesId: Record<number, boolean>;
}

export const JiraTaskForm = (props: JiraTaskFormProps) => {
  const {
    columns,
    handleClose,
    issues,
    issuesDevToolsSolution,
    selectedIssuesId,
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.business.loading);
  const currentBusiness = useSelector(
    (state: RootState) => state.business.currentBusiness,
  );
  const jiraSettings = useSelector(
    (state: RootState) => state.business.jiraSettings,
  );
  const [formFields, setFormFields] = useState<JiraTaskFormFields>({
    columns,
    projectId: '',
    projectIssueTypeId: '',
    issueCreationType: 'create_issues',
  });
  const [tasksAdditionalInfo, setTasksAdditionalInfo] = useState<
    TaskAdditionalInfo[]
  >([]);
  const [activeStep, setActiveStep] = useState(0);

  const projectOptions: SelectOption[] = useMemo(() => {
    if (!jiraSettings) return [];
    return jiraSettings.projects.map(({ id, name }) => ({
      label: name.includes('_')
        ? capitalizeFirstLetter(name.replace('_', ' '))
        : name,
      value: id,
    }));
  }, [jiraSettings]);

  const issueTypeOptions: SelectOption[] = useMemo(() => {
    if (!jiraSettings || !formFields.projectId) return [];
    const filteredIssueTypeOptions = jiraSettings.projectIssueTypes
      .filter(
        ({ projectId, name }) =>
          projectId === formFields.projectId &&
          name.replace('-', '').toLowerCase() !== 'subtask',
      )
      .map(({ issueTypeId, name }) => ({
        label: name,
        value: issueTypeId,
      }));
    return filteredIssueTypeOptions;
  }, [jiraSettings, formFields.projectId]);

  const selectedIssues: TaskIssue[] = useMemo(() => {
    const { issueCreationType } = formFields;
    if (!Object.keys(selectedIssuesId).length) return [];

    const mapIssue = (
      {
        id,
        context,
        selector,
        code,
        message,
        domain_url: url,
        type,
        // recommendation,
        status,
      }: Issue,
      index = 0,
    ) => ({
      id,
      context,
      selector,
      code,
      message,
      url,
      severity: type,
      type,
      // recommendation,
      status,
      description: message,
      task_issue_group_id: issueCreationType === 'create_issues' ? index : 0,
    });
    const filteredIssues = issues.filter(({ id }) => selectedIssuesId[id]);
    return filteredIssues.map(mapIssue);
  }, [formFields, issues, selectedIssuesId]);

  const selectedDevToolsIssues: DevtoolsSolution[] = useMemo(() => {
    if (Object.entries(selectedIssuesId).length === 0) return [];
    return issuesDevToolsSolution.filter(({ id }) => selectedIssuesId[id]);
  }, [issuesDevToolsSolution, selectedIssuesId]);

  const handleNextStep = () => {
    const { projectId, projectIssueTypeId, issueCreationType } = formFields;
    if (!projectId || !projectIssueTypeId || selectedIssues.length === 0) {
      dispatch(
        setApiError(
          !projectId
            ? 'Please select a project, before proceeding'
            : 'Please select an issue type before proceeding',
        ),
      );
      return;
    }

    const taskIssueGroupId = 0;
    let additionalTaskInfos: TaskAdditionalInfo[] = [];
    switch (issueCreationType) {
      case 'create_issues':
        additionalTaskInfos = selectedIssues.map((selectedIssue, index) => {
          const additonalTaskInfo = tasksAdditionalInfo[index];
          return {
            issueGroupId: selectedIssue.task_issue_group_id,
            projectId,
            issueTypeId: projectIssueTypeId,
            summary: additonalTaskInfo?.summary || '',
            description: additonalTaskInfo?.description || '',
            moreInfo: additonalTaskInfo?.moreInfo || '',
          };
        });
        break;
      case 'group_issues':
        additionalTaskInfos = [
          {
            issueGroupId: taskIssueGroupId,
            projectId,
            issueTypeId: projectIssueTypeId,
            summary: tasksAdditionalInfo[0]?.summary || '',
            description: tasksAdditionalInfo[0]?.description || '',
            moreInfo: tasksAdditionalInfo[0]?.moreInfo || '',
          },
        ];
        break;
      default:
        additionalTaskInfos = [];
        break;
    }
    setTasksAdditionalInfo(additionalTaskInfos);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleFormFieldChange = (
    evt:
      | SelectChangeEvent
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = evt.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const handleFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const isValid = validateTaskAdditionalInfo(tasksAdditionalInfo);
    if (!isValid) {
      dispatch(setApiError('Task name is missing'));
      return;
    }
    if (!currentBusiness.id) return;
    const { payload } = await dispatch(
      createJiraTasks({
        businessId: currentBusiness.id,
        issues: selectedIssues,
        tasksAdditionalInfo,
      }),
    );
    if (!payload) return;

    const { isSuccess, error } = payload;
    if (!isSuccess && error) {
      dispatch(setApiError(error));
      return;
    }
    dispatch(setApiSuccessMessage('Jira task created successfully'));
    handleClose();
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Wrapper>
      <Header>
        <Headline>Create Jira Issues</Headline>
      </Header>
      <form onSubmit={handleFormSubmit}>
        {activeStep === 0 ? (
          <StepOneFormFields
            columns={columns}
            projectOptions={projectOptions}
            issueTypeOptions={issueTypeOptions}
            formFields={formFields}
            handleFormFieldChange={handleFormFieldChange}
            setFormFields={setFormFields}
            selectedIssues={selectedIssues}
            selectedDevToolsIssues={selectedDevToolsIssues}
            handleNextStep={handleNextStep}
          />
        ) : (
          activeStep === 1 && (
            <StepTwoFormFields
              selectedIssues={selectedIssues}
              selectedDevToolsIssues={selectedDevToolsIssues}
              formFields={formFields}
              handlePrevStep={handlePrevStep}
              tasksAdditionalInfo={tasksAdditionalInfo}
              setTasksAdditionalInfo={setTasksAdditionalInfo}
            />
          )
        )}
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media screen and (min-width: 1300px) {
    max-width: 100%;
  }

  border-radius: 12px;
  width: auto;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;

  @media screen and (max-width: 468px) {
    padding: 20px 10px;
    max-width: 350px;
  }
`;

const Header = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  margin-bottom: 30px;
  margin-top: 15px;
`;

const Headline = styled(Typography)`
  font-weight: bolder;
  font-size: 20px;
  text-align: center;
`;
