/* eslint-disable camelcase */
import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useAddDomainFormActions from '../../../../hooks/use-add-domain-form-actions';
import { PrimaryMobileButton } from '../../../redesign/Controls';
import StartFreeTrial from '../../../redesign/FreeTrialModal/StartFreeTrialModal';
import UpgradePlan from '../../UpgradePlan/UpgradePlan';

const PlanDetails = ({ plan, type }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

  const { quantity, expiration, plan_template, total_price } = plan;
  const expirationDate = moment(expiration).format('DD MMMM YYYY');
  const [freeTrialModal, setFreeTrialModal] = useState(false);
  const domains = useSelector((state) => state.domains.domains);

  const handleDomainSuccess = (domain) => {
    setFreeTrialModal(false);
  };
  const { addDomains, domainsToAdd, handleNewDomainChange } =
    useAddDomainFormActions();

  const handleAddDomains = async () => {
    await addDomains(type, undefined, undefined, handleDomainSuccess);
  };
  return (
    <>
      <Container>
        <Alert
          style={{ backgroundColor: '#4AD493', color: '#000' }}
          variant="filled"
          severity="success"
          action={
            <>
              <AddDomainButton onClick={() => setFreeTrialModal(!openAddModal)}>
                <Plus role="presentation">+</Plus> Add Website
              </AddDomainButton>
              <UpgradeButton
                color="primary"
                onClick={() => setOpenUpgradeModal(true)}
              >
                Upgrade Plan
              </UpgradeButton>
            </>
          }
        >
          Active Plan: <br /> {plan_template.name}, ${total_price}, Credits -{' '}
          {quantity}, {plan?.renew_active ? 'Renews on' : 'Expires on'} -{' '}
          {expirationDate}
        </Alert>
      </Container>

      {/* <AddDomainModal */}
      {/*  open={openAddModal} */}
      {/*  handleClose={() => setOpenAddModal(false)} */}
      {/*  planID={plan.id} */}
      {/*  type={type} */}
      {/* /> */}
      {freeTrialModal && (
        <StartFreeTrial
          addDomains={handleAddDomains}
          domainsToAdd={domainsToAdd}
          handleClose={() => setFreeTrialModal(false)}
          open={freeTrialModal}
          handleInputChange={handleNewDomainChange}
          domains={domains}
          type={type}
        />
      )}
      <UpgradePlan
        open={openUpgradeModal}
        handleClose={() => setOpenUpgradeModal(false)}
        plan={plan}
        type="Widget"
      />
    </>
  );
};

PlanDetails.propTypes = {
  plan: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default PlanDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  overflow: hidden;
  width: 100%;
`;

const AddDomainButton = styled(PrimaryMobileButton)`
  /* color: #4ad493; */
  /* color: #fff; */
  color: #000a14;
  margin-right: 8px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 12px;
  }
`;

const UpgradeButton = styled(PrimaryMobileButton)`
  color: #fff;
  /* color: #000a14; */

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 12px;
  }
`;

const Plus = styled.span`
  margin-right: 5px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;
