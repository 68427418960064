import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import SearchSvg from '../../assets/svg/search.svg';
import { SearchInputWithIcon } from '../../components/css-components';
import { MuiCheckbox } from '../../components/mui-checkbox';

export const GroupList = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setSearchValue(value);
  };
  const [groups, setGroups] = useState<
    { id: number; isChecked: boolean; value: string }[]
  >([
    {
      id: 0,
      isChecked: false,
      value: 'All Groups',
    },
    {
      id: 1,
      isChecked: false,
      value: 'Group 1',
    },
    {
      id: 2,
      isChecked: false,
      value: 'Group 2',
    },
    {
      id: 3,
      isChecked: false,
      value: 'Group 3',
    },
    {
      id: 4,
      isChecked: false,
      value: 'Group 4',
    },
  ]);

  const handleCheckboxChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name } = evt.target;
    const updatedGroups = groups.map((group) =>
      group.value === name
        ? {
            ...group,
            isChecked: !group.isChecked,
          }
        : group,
    );
    setGroups(updatedGroups);
  };

  return (
    <Wrapper>
      <SearchInputWithIconWrapper>
        <Input
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={handleInputChange}
        />
        <SearchIcon src={SearchSvg} alt="search icon" />
      </SearchInputWithIconWrapper>
      <GroupsWrapper>
        {groups.map(({ id, isChecked, value }) => (
          <Group key={id}>
            <MuiCheckbox
              isChecked={isChecked}
              checkboxName={value}
              handleCheckboxChange={handleCheckboxChange}
            />
            <GroupText>{value}</GroupText>
          </Group>
        ))}
      </GroupsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 16px;
`;

const SearchInputWithIconWrapper = styled(SearchInputWithIcon)`
  border: 1px solid #cbd2d9;
  border-radius: 8px;
  height: 30px;
`;

const Input = styled.input`
  background-color: #f5f7fa;
`;

const SearchIcon = styled.img`
  top: 6px;
  right: 15px;
`;

const GroupsWrapper = styled.div`
  margin-top: 10px;
`;

const Group = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 0;
  }
`;

const GroupText = styled.p`
  color: #000a14;
  font-weight: 300;
`;
