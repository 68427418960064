const StyleConstants = {
  // Nav
  COLOR_BACKGROUND: '#000A14',
  COLOR_TEXT_INACTIVE: 'rgba(255, 255, 255, 0.65)',
  COLOR_TEXT_ACTIVE: '#FFFFFF',
  COLOR_BACKGROUND_ACTIVE: 'rgba(255, 255, 255, 0.12)',

  // Backgrounds and general
  COLOR_ERROR_1: '#FF9999',
  COLOR_ERROR_2: '#661F20',
  COLOR_SUCCESS_1: '#AEEAD3',
  COLOR_SUCCESS_2: '#1E5643',
  COLOR_SUCCESS_3: '#4AD493',
  COLOR_SUCCESS_4: '#C2F4D7',
  COLOR_SUCCESS_5: '#99D3FF',
  COLOR_SUCCESS_HOVER: '#98eabe',
  COLOR_WARNING_1: '#F2DF87',
  COLOR_WARNING_2: '#6F321B',
  COLOR_GREY_1: '#E4E7EB',
  COLOR_GREY_2: 'rgba(0, 10, 20, 0.65)',
  COLOR_GREY_3: '#CBD2D9',
  COLOR_GREY_4: '#f5f7fa',
  COLOR_GREY_5: 'rgba(255, 255, 255, 0.32)',

  // Texts
  COLOR_TEXT_GREY_1: 'rgba(0, 10, 20, 0.85)',
  COLOR_TEXT_GREY_2: 'rgba(0, 10, 20, 0.65)',
  COLOR_TEXT_DARK_1: '#000A14',

  COLOR_SCROLLBAR_THUMB: '#dee2e6',
  COLOR_SCROLLBAR_THUMB_HOVER: '#b0acae',

  COLOR_LIGHT_GREEN: '#B6ECD8',
  COLOR_LIGHT_RED: '#FCB1B1',
};

export default StyleConstants;
