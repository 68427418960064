import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ContainerProps {
  children: ReactNode;
}

export const BodyContainer = ({ children }: ContainerProps) => {
  return (
    <Wrapper>
      <ContainerDiv>{children}</ContainerDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: -64px;
  padding: 0;
  height: calc(100vh - 80px);
`;

const ContainerDiv = styled.div`
  //width: calc(86% + 15px);
  margin: auto;

  @media screen and (max-width: 900px) {
    width: calc(96% + 15px);
  }
`;
