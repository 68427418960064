import React, { useState } from 'react';
import { Box, Input, Typography, IconButton } from '@mui/material';
import { inputBaseClasses } from '@mui/material/InputBase';
import { ReactComponent as MenuIcon } from '../../img/icons/menu-icon.svg';
import { useSelector } from 'react-redux';
import { styled as MuiStyled } from '@mui/material/styles';
import { ReactComponent as EquallyAILogo } from '../../img/icons/logo-black.svg';
import { ReactComponent as SearchIcon } from '../../img/icons/search.svg';
import { ReactComponent as NotificationBellIcon } from '../../img/icons/notification-bell.svg';
import { RootState } from '../../store';
import { BusinessMenu } from '../business-menu';
import { NavLink, useLocation } from 'react-router-dom';
import { useWebsitesTable } from '../../providers/websites-table-context';

const getUserInitials = (userName: string): string => {
  const [firstName, lastName] = userName.split(' ');
  return `${firstName.charAt(0)}${(lastName || ' ').charAt(0)}`;
};

interface TopBarProps {
  handleDrawerOpen: () => void;
  isAuthenticated: boolean;
}

export const TopBar = (props: TopBarProps) => {
  const { handleDrawerOpen, isAuthenticated } = props;
  const { searchTerm, handleSearch } = useWebsitesTable();
  const personalDetails = useSelector(
    (state: RootState) => state.personalDetails.personalDetails,
  );
  const currentBusiness = useSelector(
    (state: RootState) => state.business.currentBusiness,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { pathname } = useLocation();
  const unAuthNavLink = pathname.includes('login') ? '/signup' : '/login';
  const authNavLink = `/${currentBusiness?.slug}`;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(evt.currentTarget);
  };

  return (
    <Wrapper>
      <LogoWrapper
        sx={{
          display: `${isAuthenticated ? 'none' : ''}`,
          '@media (min-width:800px)': {
            display: 'block',
          },
        }}
      >
        <NavLink to={`${isAuthenticated ? authNavLink : unAuthNavLink}`}>
          <EquallyAILogo />
        </NavLink>
      </LogoWrapper>
      {isAuthenticated && (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{
              display: 'none',
              '@media (max-width:800px)': {
                display: 'flex',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          {pathname === `/${currentBusiness?.slug}` && (
            <SearchInputWrapper>
              <SearchIcon aria-hidden="true" />
              <SearchInput
                placeholder="Search"
                value={searchTerm}
                onChange={(evt) => handleSearch(evt.target.value)}
                aria-label="Search websites"
              />
            </SearchInputWrapper>
          )}
          <UserNotification>
            {/* <NotificationBellWrapper>
              <NotificationBell />
              <NotificationCount>3</NotificationCount>
            </NotificationBellWrapper> */}
            <Box
              aria-label="User Profile"
              aria-expanded={Boolean(anchorEl)}
              component="button"
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
                padding: '0',
                marginLeft: '28px',
                width: '100%',
                maxWidth: '200px',
                height: '45px',

                '&:focus-visible': {
                  outline: 'solid 2px #1738F5',
                  outlineOffset: '5px',
                  outlineStyle: 'inset',
                  outlineWidth: '3px',
                },
              }}
              onClick={handleClick}
            >
              <Initials>
                {personalDetails?.name && getUserInitials(personalDetails.name)}
              </Initials>
              <UserNameEmailWrapper
                sx={{
                  display: 'none',
                  '@media (min-width:800px)': {
                    display: 'block',
                    width: '100%',
                    maxWidth: '150px',
                    height: '100%',
                  },
                }}
              >
                <UserName>{personalDetails && personalDetails.name}</UserName>
                <UserEmail>
                  {personalDetails && personalDetails.email}
                </UserEmail>
              </UserNameEmailWrapper>
            </Box>
          </UserNotification>
        </>
      )}
      <BusinessMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClose={handleClose}
      />
    </Wrapper>
  );
};

const Wrapper = MuiStyled(Box)(() => ({
  backgroundColor: '#FFFFFF',
  height: '80px',
  position: 'fixed',
  top: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 30px',
  zIndex: '1300',

  '@media screen and (max-width: 800px)': {
    gap: '16px',
    padding: '17px 24px',
    height: '70px',
  },
}));

const LogoWrapper = MuiStyled(Box)(() => ({
  width: '124.74px',
  height: '32px',

  '& svg': {
    width: '100%',
    height: 'auto',
  },
}));

const SearchInputWrapper = MuiStyled(Box)(() => ({
  display: 'flex',
  height: '40px',
  backgroundColor: '#F1F2F3',
  borderRadius: '4px',
  padding: '10px 20px',
  alignItems: 'center',
  gap: '6px',
  width: '100%',
  maxWidth: '48.6%',

  '@media screen and (max-width: 800px)': {
    height: '36px',
    border: '1px solid #D8D8D8',
    backgroundColor: 'transparent',
    maxWidth: '65%',
    borderRadius: '8px',
  },
}));

const SearchInput = MuiStyled(Input)(() => ({
  color: '#000000',
  fontSize: '16px',

  [`&.${inputBaseClasses.root}`]: {
    width: '100%',

    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },

    '&:hover:not(.Mui-disabled):after': {
      borderBottom: 'none',
    },

    '&::before': {
      borderBottom: 'none',
    },

    '&::after': {
      borderBottom: 'none',
    },
  },

  '::placeholder': {
    color: '#606265',
  },
}));

const UserNotification = MuiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',

  '@media screen and (max-width: 800px)': {
    gap: '16px',
  },
}));

const NotificationBellWrapper = MuiStyled(Box)(() => ({
  position: 'relative',
}));

const NotificationBell = MuiStyled(NotificationBellIcon)(() => ({}));

const NotificationCount = MuiStyled(Typography)(() => ({
  position: 'absolute',
  top: '0',
  width: '14px',
  height: '14px',
  backgroundColor: '#447AFF',
  color: '#FFFFFF',
  fontSize: '10px',
  fontWeight: '500',
  lineHeight: '15px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  right: '0',
  cursor: 'pointer',
}));

const Initials = MuiStyled(Box)(() => ({
  marginRight: '10px',
  backgroundColor: '#E4E5E7',
  color: '#9A9DA2',
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 9px',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@media screen and (max-width: 800px)': {
    margin: 0,
  },
}));

const UserNameEmailWrapper = MuiStyled(Box)(() => ({}));

const UserName = MuiStyled(Typography)(() => ({
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#000A14',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  height: '24px',
}));

const UserEmail = MuiStyled(Typography)(() => ({
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#A3A6A8',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  height: '24px',
}));
