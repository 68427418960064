import { Chip, Typography } from '@mui/material';
import React from 'react';

interface StatusTagProps {
  text: ServicesStatusesEnum;
}

export enum ServicesStatusesEnum {
  PENDING = 'pending',
  REVIEW = 'review',
  DONE = 'done',
}

const STATUS_COLORS = {
  [ServicesStatusesEnum.PENDING]: '#FEFCBF',
  [ServicesStatusesEnum.REVIEW]: '#FFCCCC',
  [ServicesStatusesEnum.DONE]: '#DFFFCB',
};

export const StatusTag = (props: StatusTagProps) => {
  const { text } = props;
  return (
    <Chip
      style={{ backgroundColor: STATUS_COLORS[text] || 'blue' }}
      label={<Typography variant={'button'} children={text} />}
    />
  );
};
