import React, { useState } from 'react';
import styled from 'styled-components';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { IssuesPieChart } from './issues-pie-chart';
import { IssuesProgressBars } from './issues-progress-bars';
import { SignalBarIcon } from './signal-bar-icon';
import { DonutIcon } from './donut-icon';

interface IssuesByElementProps {
  scanIssuesGroupingByElement: any[];
  issues: Issue[];
}

export const IssuesByElement = (props: IssuesByElementProps) => {
  const { scanIssuesGroupingByElement, issues } = props;
  const [showProgressBars, setShowProgressBars] = useState(true);

  const handleToggleProgressBars = () => {
    setShowProgressBars(!showProgressBars);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>Issues by elements</Title>
        <HeaderActions>
          <SignalBarIcon
            isActive={showProgressBars}
            handleClick={handleToggleProgressBars}
          />
          <DonutIcon
            isActive={!showProgressBars}
            handleClick={handleToggleProgressBars}
          />
        </HeaderActions>
      </HeaderWrapper>
      <ChartWrapper $isprogressbar={showProgressBars}>
        {showProgressBars ? (
          <IssuesProgressBars
            scanIssuesGroupingByElement={scanIssuesGroupingByElement}
            issues={issues}
          />
        ) : (
          <IssuesPieChart
            scanIssuesGroupingByElement={scanIssuesGroupingByElement}
            issues={issues}
          />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-shadow: 0px 4px 17px 0px #08256417;
  border-radius: 10px;
  padding-bottom: 20px;
  position: relative;
  background-color: #ffffff;
  width: 100%;
  max-width: 49%;
  height: 100%;

  .recharts-responsive-container {
    margin-top: 10px;

    @media screen and (max-width: 860px) {
      margin: auto;
    }
  }

  .line-chart-label {
    transform: rotate(-90deg);
    transform-origin: initial;
  }

  @media (pointer: none), (pointer: coarse) {
    max-width: 100%;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 18px;
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type ChartWrapperProps = { $isprogressbar: boolean };
const ChartWrapper = styled.div<ChartWrapperProps>`
  height: 290px;
  padding: ${(props) => (props.$isprogressbar ? '10px 20px' : '0')};
  overflow: hidden auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  @media (pointer: none), (pointer: coarse) {
    overflow: scroll hidden;
  }
`;
