import {
  CLEAR_COMPLIANCE,
  VERIFY_COMPLIANCE_LOADING,
  VERIFY_COMPLIANCE_SUCCESS,
  VERIFY_INSTALLATION,
  VERIFY_INSTALLATION_SUCCESS,
} from '../actionTypes';

const initialState = {
  compliance: [],
  config: '',
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_COMPLIANCE:
      return { ...state, compliance: [] };
    case VERIFY_COMPLIANCE_LOADING:
      return { ...state, loading: true };
    case VERIFY_COMPLIANCE_SUCCESS:
      return { ...state, compliance: action.value, loading: false };
    case VERIFY_INSTALLATION_SUCCESS:
      return { ...state, config: action.value, loading: false };
    default: {
      return state;
    }
  }
}
