import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import CheckIconSvg from '../../assets/svg/check-mark.svg';
import CloseIconSvg from '../../assets/svg/close-icon.svg';
import { LoadingSpinner } from './LoadingSpinner';
import { getAssetReactAppUrl } from '../../utils';

interface EditPromptFormProps {
  gptLoading: boolean;
  editPrompt: (newPrompt: string) => void;
  usedPrompt: string;
  toggleEditPromptForm: () => void;
  isExtension?: boolean;
}

const getImageSrc = (imgPath: string, isExtension?: boolean): string => {
  return isExtension ? getAssetReactAppUrl(imgPath) : imgPath;
};

export const EditPromptForm = (props: EditPromptFormProps) => {
  const {
    gptLoading,
    editPrompt,
    usedPrompt,
    toggleEditPromptForm,
    isExtension,
  } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [prompt, setPrompt] = useState<string>(usedPrompt);

  const adjustTextareaSize = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.width = '100%';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(evt.target.value);
    adjustTextareaSize();
  };

  const handleSubmit = () => {
    editPrompt(prompt);
  };

  useEffect(() => {
    adjustTextareaSize();
    window.addEventListener('resize', adjustTextareaSize);
    return () => {
      window.removeEventListener('resize', adjustTextareaSize);
    };
  }, []);

  if (gptLoading) {
    return (
      <SpinnerDiv>
        <LoadingSpinner spinnerColor="#4ad493" />
      </SpinnerDiv>
    );
  }

  return (
    <FormWrapper isExtension={isExtension}>
      <TextAreaField
        ref={textareaRef}
        name="prompt"
        value={prompt}
        onChange={handleChange}
      />
      <ActionsDiv>
        <CloseIcon
          src={getImageSrc(CloseIconSvg, isExtension)}
          alt=""
          onClick={toggleEditPromptForm}
        />
        <CheckIcon
          src={getImageSrc(CheckIconSvg, isExtension)}
          alt=""
          onClick={handleSubmit}
        />
      </ActionsDiv>
    </FormWrapper>
  );
};

const SpinnerDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FormWrapper = styled.form<{ isExtension?: boolean }>`
  border: 2px solid #4ad493;
  width: ${(p) => (p.isExtension ? 'auto' : '100%')};
  padding: 13px 14px 8px 14px;
  height: fit-content;
  border-radius: 8px;
`;

const TextAreaField = styled.textarea`
  border: none;
  resize: none;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
`;

const ActionsDiv = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
`;

const CheckIcon = styled.img`
  cursor: pointer;
`;

const CloseIcon = styled.img`
  cursor: pointer;
  width: 16px;
  margin-right: 7px;
`;
