import { useAnalytics } from '@equally-ai-front/common/src';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { useAutomaticScan } from '@equally-ai-front/common/src/hooks/use-automatic-scan';
import {
  removeLeadingSlash,
  removeSlashAtUrlEnd,
} from '@equally-ai-front/common/src/lib/domains';
import { scanRecommendationsActions } from '@equally-ai-front/common/src/redux/developer-slice';
import {
  selectLoadingScanRecommendations,
  selectScanRecommendationsError,
} from '@equally-ai-front/common/src/redux/developer-slice/selector';
import {
  getScanHistory,
  scanHistory,
} from '@equally-ai-front/common/src/redux/historySlice';
import { settingsActions } from '@equally-ai-front/common/src/redux/settings-slice';
import { getWidgetDomains } from '@equally-ai-front/common/src/redux/user-products-slice/user-products';
import {
  DevDomain,
  DomainSummary,
} from '@equally-ai-front/common/src/types/domains';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import { BodyContainer } from '../../components/flowy-components/body-container';
import { setApiError, setApiSuccessMessage } from '../../store/actions';
import {
  resetDashboardState,
  setDomain,
  setScannedUrls,
} from '../../store/dashboard-slice/dashboard';
import { Dashboard } from './components/dashboard';
import { DomainInformationSettings } from './components/domain-information-settings';
import { RootState } from '../../store';
import { formatExpiration } from '../../lib/date';
import { NewScanInfo, Scan } from '@equally-ai-front/common/src/types/issues';
import { isScanLoading } from '../../lib/scans';
import { getUrlWithoutHttpPrefixAndWWW } from '@equally-ai-front/common';

// import { MainContentHeader } from './components/main-content-header';

export const getCurrentDevDomain = (
  devDomains: DevDomain[],
  domainId: string | undefined,
) => {
  return (devDomains || []).find(
    (item: any) => item.id === parseInt(domainId || '0', 10),
  );
};

export const DashboardContainer = () => {
  const { domainId } = useParams();
  const location = useLocation();
  // const prevDomainIdRef = useRef<string | undefined>(undefined);
  const loadingRecommendations = useSelector(selectLoadingScanRecommendations);
  const domains: DomainSummary[] = useSelector(
    (state: any) => state.userProducts.domains,
  );
  const [searchParams] = useSearchParams();
  const { currentBusiness, scannedUrls, currentDevDomain } = useSelector(
    (state: RootState) => ({
      currentBusiness: state.business.currentBusiness,
      scannedUrls: state.dashboard.scannedUrls,
      currentDevDomain: state.dashboard.currentDevDomain,
    }),
  );
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const wcagReport: ReportData = {
    reportGroups: {
      basic: [],
      advanced: [],
      potential: [],
      all: [],
    },
    list: {
      basic: [],
      advanced: [],
      potential: [],
      all: [],
    },
    reportList: [],
    pdfReport: [],
    websiteUrl: '',
    websitePath: '',
    percentages: {
      basic: 0,
      advanced: 0,
      potential: 0,
    },
  };
  const scanRecommendationError = useSelector(selectScanRecommendationsError);
  const analytics = useAnalytics();
  const [showFreeTrialBanner, setShowFreeTrialBanner] = useState(false);
  const navigate = useNavigate();
  const closeFreeTrialBanner = () => {
    setShowFreeTrialBanner(false);
  };
  const pageUrl = searchParams.get('page_url');

  const isOnboardingDashboard = useMemo(
    () => searchParams.get('dashboard_onboarding') === 'true',
    [searchParams],
  );

  const onAuditError = (message: string) => {
    dispatch(setApiError(message));
    dispatch(scanRecommendationsActions.setDashboardActiveUrlIdx(0));
  };
  const getScansHistory = useCallback(async () => {
    if (!currentDevDomain || !currentBusiness) {
      return;
    }
    const {
      // @ts-ignore
      payload: { error, isSuccess },
    } = await dispatch(
      // @ts-ignore
      getScanHistory({
        businessId: currentBusiness.id,
        websiteIds: [currentDevDomain.id],
      }),
    );
    if (!isSuccess) {
      dispatch(setApiError(error));
    }
  }, [currentBusiness, currentDevDomain, dispatch]);
  useEffect(() => {
    if (!currentDevDomain || !currentBusiness) {
      return;
    }

    if (formatExpiration(currentDevDomain.expiration) === 'Expired') {
      navigate('/');
      return;
    }

    // if (devDomain.plan_id === 0) {
    //   setShowFreeTrialBanner(true);
    // }
    void getScansHistory();
  }, [currentDevDomain, dispatch, currentBusiness, navigate, getScansHistory]);
  const { performAudit, scans } = useAutomaticScan({
    onError: onAuditError,
  });

  useEffect(() => {
    return () => {
      dispatch(resetDashboardState());
      dispatch(scanHistory.setIssues([]));
      dispatch(scanHistory.setScanHistory([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (scans.length === 0) {
      return;
    }
    const scansMap: Record<string, Scan> = {};
    for (const scan of scans) {
      const key = pageUrl ? 'request_id' : 'url';
      const value = scan[key];

      if (!scansMap[value]) {
        scansMap[value] = scan;
      }

      if (
        scan[key] === value &&
        scan.created_at >= scansMap[value].created_at
      ) {
        scansMap[value] = scan;
      }
    }

    const newScans: Scan[] = scans
      // .filter((scan) => isScanLoading(scan))
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
    // dispatch(setScannedUrls(newScans));
    const currentScans = Object.values(scansMap);
    const completedScanStatuses = ['SUCCESS', 'FAILED'];

    dispatch(
      setScannedUrls(
        currentScans.filter(
          (scannedUrl) => !completedScanStatuses.includes(scannedUrl.status),
        ),
      ),
    );

    const updateScanHistoryOnScanCompletion = () => {
      if (!currentBusiness || !currentDevDomain) return;
      for (const currentScan of currentScans) {
        if (completedScanStatuses.includes(currentScan.status)) {
          dispatch(
            getScanHistory({
              businessId: currentBusiness.id,
              websiteIds: [currentDevDomain.id],
            }),
          );
          return;
        }
      }
    };
    updateScanHistoryOnScanCompletion();
  }, [dispatch, scans, pageUrl]);

  useEffect(() => {
    if (!domains) {
      return;
    }

    const totalDomains = domains.length;
    if (totalDomains === 0 && currentBusiness) {
      dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
      return;
    }

    if (totalDomains > 0) {
      const [widgetDomain] = domains.filter(
        (domain) => domain.domain && domain.name === currentDevDomain?.name,
      );

      if (widgetDomain && widgetDomain.domain) {
        dispatch(setDomain(widgetDomain.domain));
      }
    }
  }, [dispatch, domains, currentBusiness, currentDevDomain]);

  const onAudit = useCallback(
    (urlPathname: string, cb?: () => void, scanDetails?: NewScanInfo) => {
      if (!currentDevDomain || !currentBusiness) {
        return;
      }
      const executeAudit = async () => {
        const inputUrls = urlPathname
          .split(';')
          .map((url) => url.trim())
          .filter((url) => url.length > 0);
        if (inputUrls.length === 0) {
          return;
        }

        const urlsToScan = [];
        for (const inputUrl of inputUrls) {
          const newUrl = removeSlashAtUrlEnd(
            `${currentDevDomain.name}/${removeLeadingSlash(inputUrl)}`,
          );
          const isDomainPathCurrentlyScanning = scannedUrls.find((scan) => {
            const { domain } = scan;
            return (
              domain === getUrlWithoutHttpPrefixAndWWW(newUrl) &&
              isScanLoading(scan)
            );
          });
          if (!isDomainPathCurrentlyScanning) {
            urlsToScan.push(newUrl);
          }
          analytics.track('Add Page Template Clicked', { url: newUrl });
          if (
            !newUrl.toLowerCase().includes(currentDevDomain.name.toLowerCase())
          ) {
            analytics.track('Flowy Report Requested', {
              url: newUrl,
              status: 'failed',
              reason: 'You are unauthorized to scan this domain',
            });
            dispatch(setApiError('You are unauthorized to scan this domain'));
            return;
          }
        }

        const { isSuccess } = await performAudit(
          urlsToScan.join(';'),
          currentBusiness.id,
          currentDevDomain.id,
          scanDetails,
        );
        if (!isSuccess) {
          dispatch(setApiError('Failed to perform audit'));
          return;
        }

        dispatch(setApiSuccessMessage('Audit has been initiated'));
        dispatch(
          getScanHistory({
            businessId: currentBusiness.id,
            websiteIds: [currentDevDomain.id],
          }),
        );
        cb && cb();
        if (pageUrl) {
          navigate(
            `/${currentBusiness.slug}/developers/${currentDevDomain.id}/flowy/scans`,
          );
        }
      };
      void executeAudit();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      performAudit,
      settingsActions,
      dispatch,
      currentBusiness,
      currentDevDomain,
      location,
      scannedUrls,
      pageUrl,
    ],
  );

  useEffect(() => {
    if (!currentDevDomain || !currentBusiness) {
      return;
    }

    if (currentDevDomain.plan_id === 0) {
      setShowFreeTrialBanner(true);
    }
  }, [currentDevDomain, dispatch, currentBusiness]);

  useEffect(() => {
    if (scanRecommendationError) {
      dispatch(setApiError(scanRecommendationError));
      dispatch(scanRecommendationsActions.setError(''));
    }
  }, [scanRecommendationError, dispatch]);

  return (
    <>
      <BodyContainer>
        <DashboardPageContent>
          {currentDevDomain && (
            <>
              {isOnboardingDashboard ? (
                <DomainInformationSettings
                  devDomain={currentDevDomain}
                  isOnboardingDashboard={isOnboardingDashboard}
                />
              ) : (
                <Dashboard
                  onAudit={onAudit}
                  wcagReport={wcagReport}
                  devDomain={currentDevDomain}
                  loadingRecommendations={loadingRecommendations}
                  showFreeTrialBanner={showFreeTrialBanner}
                  isOnboardingDashboard={isOnboardingDashboard}
                  closeFreeTrialBanner={closeFreeTrialBanner}
                />
              )}
            </>
          )}
          <LoadingViewWrapper isLoading={loadingRecommendations}>
            <LoadingView open={loadingRecommendations} />
          </LoadingViewWrapper>
        </DashboardPageContent>
      </BodyContainer>
    </>
  );
};

const DashboardPageContent = styled.div`
  position: relative;
  display: flex;
`;

type LoadingViewWrapperProps = { isLoading: boolean };
const LoadingViewWrapper = styled.div<LoadingViewWrapperProps>`
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  display: ${(p) => (!p.isLoading ? 'none' : '')};
`;
