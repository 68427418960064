import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { settingsActions } from '@equally-ai-front/common/src/redux/settings-slice';
import {
  selectLoadingDevDomain,
  selectLoadingSettings,
  selectSettingsError,
  selectSettingsMessage,
} from '@equally-ai-front/common/src/redux/settings-slice/selectors';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DomainInformationForm } from '../../../components/flowy-components/domain-information-form';
import { InstallExtensionModal } from '../../../components/install-extension-step/install-extension-modal';
import {
  getDevDomains,
  setApiError,
  setApiSuccessMessage,
} from '../../../store/actions';

interface DomainInformationSettingsProps {
  devDomain: DevDomain;
  isOnboardingDashboard: boolean;
}

export const DomainInformationSettings = ({
  devDomain,
  isOnboardingDashboard,
}: DomainInformationSettingsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const loadingSettings = useSelector(selectLoadingSettings);
  const loading = useSelector(selectLoadingDevDomain);
  const settingsMessageFromApi = useSelector(selectSettingsMessage);
  const settingsError = useSelector(selectSettingsError);
  const [showInstallationModal, setShowInstallationModal] = useState(false);

  const handleNavigateToIssuesTab = useCallback(() => {
    if (!currentBusiness) {
      return;
    }

    navigate(
      `/${currentBusiness.slug}/developers/${devDomain.id}/flowy/overview`,
    );
  }, [currentBusiness, devDomain, navigate]);

  const openInstallationModal = () => setShowInstallationModal(true);

  const closeInstallationModal = () => {
    setShowInstallationModal(false);
    handleNavigateToIssuesTab();
  };

  useEffect(() => {
    if (settingsMessageFromApi) {
      dispatch(setApiSuccessMessage(settingsMessageFromApi));
      dispatch(getDevDomains(currentBusiness.id));
      dispatch(settingsActions.setMessage(''));

      isOnboardingDashboard
        ? openInstallationModal()
        : handleNavigateToIssuesTab();
    }
  }, [
    settingsMessageFromApi,
    currentBusiness,
    isOnboardingDashboard,
    dispatch,
    handleNavigateToIssuesTab,
  ]);

  useEffect(() => {
    if (settingsError !== '') {
      dispatch(setApiError(settingsError));
      dispatch(settingsActions.setError(''));
    }
  }, [settingsError, dispatch]);

  return (
    <Wrapper isOnboardingDashboard={isOnboardingDashboard}>
      <DomainInformationForm
        devDomain={devDomain}
        handleNavigateToIssuesTab={handleNavigateToIssuesTab}
        isOnboardingDashboard={isOnboardingDashboard}
        openInstallationModal={openInstallationModal}
      />
      <InstallExtensionModal
        open={showInstallationModal}
        handleClose={closeInstallationModal}
      />
      <LoadingView
        open={loadingSettings || loading}
        loadingText="Loading Domain Information..."
      />
    </Wrapper>
  );
};

interface WrapperProps {
  isOnboardingDashboard?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${(p) =>
    p.isOnboardingDashboard
      ? `
      display: flex;
      justify-content: center;
      height: calc(100vh - 80px);
      padding: 3vh 3vw;
      width: 100%;
    `
      : ''}
`;
