import { SET_TOKEN } from '../actionTypes';
import { parseJwt } from '@equally-ai/auth-service';
const token = localStorage.getItem('accessToken') || '';

const initialState = {
  user: token ? parseJwt(token) : {},
  token: token,
};

export default function (state = initialState, action) {
  const urlParams = new URLSearchParams(window.location.search);
  const isSignup = urlParams.get('signup');
  switch (action.type) {
    case SET_TOKEN:
      // registration with google tracking
      if (isSignup && isSignup === 'true') {
        // eslint-disable-next-line no-undef
        // dataLayer.push({
        //   event: 'login',
        //   loginType: 'registrationWithGoogle',
        // });
        // eslint-disable-next-line no-undef
        tap('trial', state.user.preferred_username);
      }
      // login with google tracking
      if (isSignup && isSignup === 'false') {
        // eslint-disable-next-line no-undef
        // dataLayer.push({
        //   event: 'login',
        //   loginType: 'loginWithGoogle',
        // });
      }
      return { ...state, user: parseJwt(action.value), token: action.value };
    default: {
      return state;
    }
  }
}
