import styled from '@emotion/styled';
import { Container as MuiContainer } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Container = ({ children, ...props }) => {
  return (
    <OutterContainer component="section" maxWidth={false} {...props}>
      <InnerContainer>{children}</InnerContainer>
    </OutterContainer>
  );
};

export default Container;

Container.propTypes = {
  children: PropTypes.any.isRequired,
};

/** Styled Components */

const InnerContainer = styled(MuiContainer)`
  && {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
`;

const OutterContainer = styled(MuiContainer)`
  && {
    overflow: hidden;

    padding-left: 16px;
    padding-right: 16px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-left: 80px;
      padding-right: 80px;
      display: flex;
      justify-content: center;
    }
  }
`;
