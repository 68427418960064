import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import styled from 'styled-components';

interface SliderControlSvgProps {
  strokeColor: string;
  isLeftControl?: boolean;
}

interface CarouselSlideProps {
  activeSlideIndex: number;
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
  children: ReactNode;
}

const SliderControlSvg = ({
  strokeColor,
  isLeftControl,
}: SliderControlSvgProps) => {
  return (
    <svg
      width="14"
      height="23"
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={isLeftControl ? 'M12 21L2 11.5L12 2' : 'M2 2L12 11.5L2 21'}
        stroke={strokeColor}
        strokeWidth="2.58466"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CarouselSlide = ({
  activeSlideIndex,
  setActiveSlideIndex,
  children,
}: CarouselSlideProps) => {
  const [forwardBtnActive, setForwardBtnActive] = useState<boolean>(true);
  const forwardBtnElem = document.getElementById(
    'forwardBtnId',
  ) as HTMLButtonElement;
  const forwardBtnRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          setForwardBtnActive(!forwardBtnActive);
        }
      }
    });

    if (forwardBtnElem) {
      forwardBtnRef.current = forwardBtnElem;
      const targetNode = forwardBtnRef.current;
      const observerConfig = { attributes: true, value: true };
      observer.observe(targetNode, observerConfig);
    }

    return () => {
      observer.disconnect();
    };
  }, [forwardBtnElem, forwardBtnActive]);

  return (
    <Wrapper isForwardBtnActive={forwardBtnActive}>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToScroll={1}
        forwardBtnProps={{
          datatype: 'forward-btn',
          id: 'forwardBtnId',
          children: <SliderControlSvg strokeColor="#454284" />,
        }}
        backwardBtnProps={{
          datatype: 'backward-btn',
          children: <SliderControlSvg strokeColor="#454284" isLeftControl />,
        }}
        speed={400}
        easing="linear"
        infinite={false}
        activeSlideProps={{
          datatype: 'slide-active',
        }}
      >
        {children}
      </ReactSimplyCarousel>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isForwardBtnActive: boolean }>`
  > div {
    justify-content: space-between !important;
    position: relative;

    button {
      border: none;
      cursor: pointer;
      background-color: transparent;
      position: absolute;
      top: 0;
      bottom: 0;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    button[datatype='forward-btn'] {
      right: -25px;
    }

    button[datatype='backward-btn'] {
      left: -25px;
    }

    button[disabled] {
      opacity: 0.5;
      cursor: not-allowed !important;

      svg path {
        stroke: #e0e0e0;
      }
    }

    > div {
      max-width: 100% !important;
      margin: 0 auto;

      > div {
        margin-left: ${(p) => (!p.isForwardBtnActive ? '10px' : '')};
      }
    }
  }
`;
