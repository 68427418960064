import scanRecommendationsSaga from '@equally-ai-front/common/src/redux/developer-slice/saga';
import settingsSaga from '@equally-ai-front/common/src/redux/settings-slice/saga';
import axios from 'axios';
import { put, select, spawn, takeEvery } from 'redux-saga/effects';
import { makeHash } from '../common/helpers';
import {
  API_ACCESS_STATEMENT,
  API_ACTIVE_SUBSCRIPTIONS_ENDPOINT,
  API_ADD_DEV_DOMAIN_ENDPOINT,
  API_ADD_DOMAIN_ENDPOINT,
  API_ADD_DOMAIN_KEY_ENDPOINT,
  API_ADD_DOMAIN_TO_PLAN,
  API_ADD_SUBSCRIPTION,
  API_ADD_TRANSACTION,
  API_BILLING_PROFILE,
  API_CANCEL_SUB_ENDPOINT,
  API_COMPLIANCE_ENDPOINT,
  API_DELETE_DOMAIN_ENDPOINT,
  API_DELETE_DOMAIN_KEY_ENDPOINT,
  API_DEV_DOMAINS_ENDPOINT,
  API_DOMAINS_ENDPOINT,
  API_GET_ALL_PLANS,
  API_GET_INACTIVE_PLANS,
  API_GET_PLANS_TYPE,
  API_GET_PRICES,
  API_INACTIVE_SUBSCRIPTIONS_ENDPOINT,
  API_TEAM_INVITATIONS,
  API_UPDATE_SUBSCRIPTION,
  FEEDBACK_FORM,
  HTTP_CLIENT_ENDPOINT,
  QUERY_PARAM_APIKEY,
  QUERY_PARAM_DOMAINS_PAGE,
  QUERY_PARAM_PROMO,
  WIDGET_HTTP_ENDPOINT,
} from '../common/magicValues';
import { navigateToLoginURL } from '../common/navigate';
import {
  addDomainsToPlansSuccess,
  addSubSuccess,
  addTransactionSuccess,
  createDomainKeySuccess,
  deleteDomainKeySuccess,
  deleteDomainSuccess,
  getAccessSuccess,
  getActivePlansSuccess,
  getActiveSubsSuccess,
  getAllPlans,
  getAllPlansSuccess,
  getAppearanceSuccess,
  getBillingProfile,
  getBillingProfileSuccess,
  getDevDomainsSuccess,
  getDomainKeyssSuccess,
  getDomainsSuccess,
  getInactivePlansSuccess,
  getInactiveSubsSuccess,
  getPricesSuccess,
  getSampleCodeSuccess,
  sendInviteSuccess,
  setApiError,
  setApiSuccessMessage,
  setDevDomainMsg,
  updateSubSuccess,
  verifyComplianceLoading,
  verifyComplianceSuccess,
  verifyInstallationSuccess,
} from './actions';
import {
  ADD_DEV_DOMAIN,
  ADD_DOMAIN,
  ADD_DOMAIN_KEY,
  ADD_DOMAIN_TO_PLANS_REQUEST,
  ADD_SUBSCRIPTION,
  ADD_TRANSACTION_REQUEST,
  CANCEL_SUBSCRIPTION,
  DELETE_DOMAIN,
  DELETE_DOMAIN_KEY,
  GET_ACCESS,
  GET_ACTIVE_PLANS,
  GET_ACTIVE_SUBSCRIPTIONS,
  GET_ALL_PLANS,
  GET_API_SAMPLE_CODE,
  GET_API_SAMPLE_CODE_REDESIGN,
  GET_APPEARANCE,
  GET_BILLING_PROFILE_REQUEST,
  GET_DEV_DOMAINS,
  GET_DOMAINS,
  GET_INACTIVE_PLANS,
  GET_INACTIVE_SUBSCRIPTIONS,
  GET_PRICES_REQUEST,
  SEND_INVITE,
  SET_TOKEN,
  UPDATE_ACCESS,
  UPDATE_APPEARANCE,
  UPDATE_BILLING_PROFILE_REQUEST,
  UPDATE_SUBSCRIPTION,
  VERIFY_COMPLIANCE,
  VERIFY_INSTALLATION,
} from './actionTypes';

async function callApiGet(endpoint, action, userToken, body = '') {
  axios.defaults.baseURL = endpoint;
  axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  try {
    return await axios.get(action + body);
  } catch (error) {
    if (error.message === 'Request failed with status code 401') {
      navigateToLoginURL();
    }

    return {
      errorMessage: error.response?.data.message
        ? error.response?.data.message
        : error.message,
    };
  }
}

async function callWidgetEndpoint(apiKey) {
  // axios.defaults.baseURL = `${WIDGET_HTTP_ENDPOINT}?${QUERY_PARAM_APIKEY}=${apiKey}`;

  try {
    return await axios.get(
      `${WIDGET_HTTP_ENDPOINT}?${QUERY_PARAM_APIKEY}=${apiKey}`,
    );
  } catch (error) {
    if (error.message === 'Request failed with status code 401') {
      navigateToLoginURL();
    }
    return {
      errorMessage:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    };
  }
}

async function callApiPost(endpoint, action, userToken, body) {
  axios.defaults.baseURL = endpoint;
  axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  try {
    return await axios.post(action, body);
  } catch (error) {
    if (error.message === 'Request failed with status code 401') {
      navigateToLoginURL();
    }
    return {
      errorMessage: error.response
        ? error.response.data.message
        : error.message,
    };
  }
}

function* callApi(endpoint, action, userToken, method, body) {
  let res;

  if (method === 'POST') {
    res = yield callApiPost(endpoint, action, userToken, body);
  }
  if (method === 'GET') {
    res = yield callApiGet(endpoint, action, userToken, body);
  }

  if (res) {
    if (res.errorMessage) {
      if (res.errorMessage.indexOf('desc = ') > -1) {
        yield put(setApiError(res.errorMessage.split('desc = ')[1]));
      } else {
        yield put(setApiError(res.errorMessage));
      }

      return null;
    }

    return res;
  }

  return null;
}

function* getActiveSubscriptions(userToken) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    API_ACTIVE_SUBSCRIPTIONS_ENDPOINT,
    userToken,
    'GET',
  );

  yield put(
    response && response.status === 200
      ? getActiveSubsSuccess(response.data.message)
      : getActiveSubsSuccess([]),
  );
}

function* getInactiveSubscriptions(userToken) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    API_INACTIVE_SUBSCRIPTIONS_ENDPOINT,
    userToken,
    'GET',
  );

  yield put(
    response && response.status === 200
      ? getInactiveSubsSuccess(response.data.message)
      : getInactiveSubsSuccess([]),
  );
}

function* getSampleCode(userToken, domain, key, businessId) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    `/api/v1/admin/a/${businessId}/samplecode`,
    userToken,
    'POST',
    {
      domain: domain,
      key: key,
    },
  );

  yield put(
    response && response.status === 200
      ? getSampleCodeSuccess({
          domain: domain,
          key: key,
          code: response.data.message,
        })
      : getSampleCodeSuccess({
          domain: domain,
          key: key,
          code: '',
        }),
  );
}

function* getSampleCodeInOneGo(userToken, domain, businessId) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    `/api/v1/admin/a/${businessId}/domainkeys`,
    userToken,
    'POST',
    {
      domain,
    },
  );

  let result = [];

  if (response && response.status === 200) {
    result = response.data.message;
  }

  yield put(getDomainKeyssSuccess(result));

  if (result.length) {
    yield getSampleCode(userToken, domain, result[0].key, businessId);
  }
}

function* getAccessHandler(userToken, domain) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    API_ACCESS_STATEMENT + domain,
    userToken,
    'GET',
  );

  let result = {};

  if (response && response.status === 200) {
    result = response.data;
  }

  yield put(getAccessSuccess(result));
}

function* updateAccessHandler(userToken, domain, value) {
  yield callApi(
    HTTP_CLIENT_ENDPOINT,
    API_ACCESS_STATEMENT + domain,
    userToken,
    'POST',
    value,
  );
}

function* getAppearanceHandler(userToken, value) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    `/api/v1/admin/a/${value.businessId}/widget-site-settings/${value.domain}`,
    userToken,
    'GET',
  );

  let result = {};

  if (response && response.status === 200) {
    result = response.data;
  }

  yield put(getAppearanceSuccess(result));
}

function* updateAppearanceHandler(
  userToken,
  businessId,
  domain,
  value,
  successMessage,
) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    `/api/v1/admin/a/${businessId}/widget-site-settings/${domain}`,
    userToken,
    'POST',
    value,
  );

  if (response?.data?.code === 200) {
    yield put(setApiSuccessMessage(successMessage));
    yield put(getAppearanceSuccess(value));
  }
}

function* getUserPlans(userToken, businessId) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    `${API_GET_ALL_PLANS}/${businessId}`,
    userToken,
    'GET',
  );

  let result = [];

  if (response && response.status === 200) {
    result = response.data.message;
  }

  yield put(getAllPlansSuccess(result));
}

function* getActivePlans(userToken, value) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    `${API_GET_PLANS_TYPE}/${value.businessId}?type=${value.type || ''}`,
    userToken,
    'GET',
  );

  let result = [];

  if (response && response.status === 200) {
    result = response.data.message;
  }

  yield put(getActivePlansSuccess(result));
}

function* getInactivePlans(userToken) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    API_GET_INACTIVE_PLANS,
    userToken,
    'GET',
  );

  let result = [];

  if (response && response.status === 200) {
    result = response.data.message;
  }

  yield put(getInactivePlansSuccess(result));
}

function* addDomainsToPlans(userToken, value) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    API_ADD_DOMAIN_TO_PLAN,
    userToken,
    'POST',
    {
      subscription_id: value.transanctionID,
      type: value.type,
      sub_type: '',
      domain: value.domain,
      service_url: '',
      is_active: true,
      description: '',
    },
  );

  if (response && response.status === 200) {
    yield put(addDomainsToPlansSuccess());
    yield put(setApiSuccessMessage(`Domain Added successfully`));
    yield put(getAllPlans());
  }
}

function* sendInvite(userToken, userDetails) {
  const response = yield callApi(
    HTTP_CLIENT_ENDPOINT,
    API_TEAM_INVITATIONS,
    userToken,
    'POST',
    {
      member_name: userDetails.name,
      email: userDetails.email,
      role: userDetails.role,
    },
  );

  if (response && response.status === 200) {
    yield put(sendInviteSuccess());
    yield put(
      setApiSuccessMessage(`Invite sent successfully to ${userDetails.email}`),
    );
  }
}

function* requestsHandler(action) {
  const state = yield select();
  const userToken = state.user.token;
  const { domains } = state.domains;

  let response;
  let values = domains || [];

  switch (action.type) {
    case SET_TOKEN:
      // eslint-disable-next-line no-undef
      localStorage.setItem('accessToken', action.value);
      break;
    case GET_DOMAINS:
    case GET_ACTIVE_SUBSCRIPTIONS:
      yield getActiveSubscriptions(userToken);
      break;
    case VERIFY_INSTALLATION:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        `/api/v1/admin/a/${action.businessId}/domainkeys`,
        userToken,
        'POST',
        {
          domain: action.value,
        },
      );

      if (response && response.status === 200) {
        if (
          response.data &&
          response.data.message &&
          response.data.message.length
        ) {
          yield put(getDomainKeyssSuccess(response.data.message));

          yield getSampleCode(
            userToken,
            action.value,
            response.data.message[0].key,
            action.businessId,
          );

          const res = yield callWidgetEndpoint(response.data.message[0].key);

          if (res && (res.errorMessage || !res.data.widgetConfiguration)) {
            yield put(
              verifyInstallationSuccess(
                'It looks like you haven’t added the installation code to your website',
              ),
            );
          } else {
            const configs = Object.values(res.data.widgetConfiguration);

            let readinessCounter = 0;
            for (let i = 0; i < configs.length; i += 1) {
              if (Array.isArray(configs[i])) {
                readinessCounter += 1;
              }
            }
            // at least 5 of 7 config sections are ready
            if (readinessCounter < 5) {
              yield put(
                verifyInstallationSuccess(
                  'Your website is being setup – Please allow up to 48 hours for the installation process to take effect.',
                ),
              );
            } else {
              yield put(
                verifyInstallationSuccess(
                  'Your website is now complaint – Your website is now compliant to ADA & WCAG standards.',
                ),
              );
            }
          }
        } else {
          yield put(
            verifyInstallationSuccess(
              'It looks like you haven’t added the installation code to your website',
            ),
          );
        }
      } else {
        yield put(
          verifyInstallationSuccess(
            'It looks like you haven’t added the installation code to your website',
          ),
        );
      }
      break;
    case GET_INACTIVE_SUBSCRIPTIONS:
      yield getInactiveSubscriptions(userToken);
      break;
    case VERIFY_COMPLIANCE:
      yield put(verifyComplianceLoading());
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_COMPLIANCE_ENDPOINT,
        userToken,
        'POST',
        {
          url: action.value,
        },
      );

      if (response && response.status === 200) {
        yield put(verifyComplianceSuccess(response.data));
        if (response.data && !response.data.length) {
          yield put(setApiSuccessMessage('No errors or warnings found'));
        }
      } else {
        yield put(verifyComplianceSuccess([]));
      }
      break;
    case ADD_DOMAIN:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_ADD_DOMAIN_ENDPOINT,
        userToken,
        'POST',
        {
          domains: action?.value?.domains || [],
          type: action?.value?.type,
          active: true,
          expires: 1583066596,
        },
      );

      if (response && response.status === 200) {
        response = yield callApi(
          HTTP_CLIENT_ENDPOINT,
          API_DOMAINS_ENDPOINT,
          userToken,
          'GET',
        );

        if (response && response.status === 200) {
          const domain = response.data.message.find(
            (record) => record.url === action.value,
          );

          action.callback(domain);
        }

        yield put(
          response && response.status === 200
            ? getDomainsSuccess(response.data.message)
            : getDomainsSuccess([]),
        );

        const newHash = makeHash(20);

        response = yield callApi(
          HTTP_CLIENT_ENDPOINT,
          API_ADD_DOMAIN_KEY_ENDPOINT,
          userToken,
          'POST',
          {
            domain: action.value,
            key: newHash,
          },
        );

        if (response && response.status === 200) {
          response = yield callApi(
            HTTP_CLIENT_ENDPOINT,
            `/api/v1/admin/a/${action.businessId}/samplecode`,
            userToken,
            'POST',
            {
              domain: action.value,
              key: newHash,
            },
          );

          yield put(
            response && response.status === 200
              ? getSampleCodeSuccess({
                  domain: action.domain,
                  key: action.key,
                  code: response.data.message,
                })
              : getSampleCodeSuccess({
                  domain: action.domain,
                  key: action.key,
                  code: '',
                }),
          );

          yield put(setApiSuccessMessage('Domain successfully added!'));
          yield getSampleCodeInOneGo(
            userToken,
            action.value,
            action.businessId,
          );
        }
      }
      break;
    case DELETE_DOMAIN:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_DELETE_DOMAIN_ENDPOINT,
        userToken,
        'POST',
        {
          domain: action.value,
        },
      );

      if (response && response.status === 200) {
        yield put(deleteDomainSuccess(action.value));
        yield put(setApiSuccessMessage('Domain successfully deleted!'));
      }
      break;
    case GET_API_SAMPLE_CODE:
      yield getSampleCode(
        userToken,
        action.domain,
        action.key,
        action.businessId,
      );
      break;
    case GET_API_SAMPLE_CODE_REDESIGN:
      yield getSampleCodeInOneGo(userToken, action.value, action.businessId);
      break;
    case ADD_DOMAIN_KEY:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_ADD_DOMAIN_KEY_ENDPOINT,
        userToken,
        'POST',
        {
          domain: action.domain,
          key: action.key,
        },
      );

      if (response && response.status === 200) {
        yield put(createDomainKeySuccess(action.key));
        yield put(setApiSuccessMessage('API key successfully added!'));
      }
      break;
    case DELETE_DOMAIN_KEY:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_DELETE_DOMAIN_KEY_ENDPOINT,
        userToken,
        'POST',
        {
          domain: action.domain,
          key: action.key,
        },
      );

      if (response && response.status === 200) {
        yield put(deleteDomainKeySuccess(action.key));
        yield put(setApiSuccessMessage('API key successfully deleted!'));
      }
      break;
    case ADD_SUBSCRIPTION:
      // eslint-disable-next-line no-case-declarations
      const addSub = { ...action.value, payment_currency: 'USD' };
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_ADD_SUBSCRIPTION,
        userToken,
        'POST',
        addSub,
      );

      if (response && response.status === 200) {
        yield put(addSubSuccess(addSub));
        yield getActiveSubscriptions(userToken);
        yield getInactiveSubscriptions(userToken);
        yield put(setApiSuccessMessage('Subscription successfully deleted!'));
      }
      break;
    case UPDATE_SUBSCRIPTION:
      // eslint-disable-next-line no-case-declarations
      const updSub = { ...action.value, payment_currency: 'USD' };
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_UPDATE_SUBSCRIPTION,
        userToken,
        'POST',
        updSub,
      );

      if (response && response.status === 200) {
        yield put(updateSubSuccess(updSub));
        yield getActiveSubscriptions(userToken);
        yield getInactiveSubscriptions(userToken);
        yield put(setApiSuccessMessage('Subscription successfully updated!'));
      }
      break;
    case GET_PRICES_REQUEST:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_GET_PRICES,
        userToken,
        'GET',
      );

      if (response && response.status === 200) {
        // eslint-disable-next-line no-case-declarations
        let res = response.data.Prices;
        if (action.value) {
          const addResp = yield callApi(
            HTTP_CLIENT_ENDPOINT,
            API_GET_PRICES,
            userToken,
            'GET',
            `?${QUERY_PARAM_PROMO}=${action.value}`,
          );
          res = [
            ...addResp.data.Prices.map((p) => {
              return { ...p, promo: true };
            }),
            ...res,
          ];
        }

        yield put(getPricesSuccess(res));
      } else {
        yield put(getPricesSuccess([]));
      }
      break;
    case GET_BILLING_PROFILE_REQUEST:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_BILLING_PROFILE,
        userToken,
        'GET',
      );
      if (response && response.status === 200) {
        yield put(getBillingProfileSuccess(response.data.message));
      }
      break;
    case UPDATE_BILLING_PROFILE_REQUEST:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_BILLING_PROFILE,
        userToken,
        'POST',
        action.value,
      );
      if (response && response.status === 200) {
        yield put(getBillingProfile());
        yield put(
          setApiSuccessMessage('Billing profile successfully updated!'),
        );
      }
      break;
    case ADD_TRANSACTION_REQUEST:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_ADD_TRANSACTION,
        userToken,
        'POST',
        action.value,
      );
      if (response && response.status === 200) {
        yield put(addTransactionSuccess(response.data));
        yield new Promise((resolve) =>
          setTimeout(() => {
            resolve();
          }, 10000),
        );
        yield getActiveSubscriptions(userToken);
        yield getInactiveSubscriptions(userToken);
      }
      break;
    case CANCEL_SUBSCRIPTION:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_CANCEL_SUB_ENDPOINT,
        userToken,
        'POST',
        action.value,
      );
      if (response && response.status === 200) {
        yield new Promise((resolve) =>
          setTimeout(() => {
            resolve();
          }, 5000),
        );
        yield getActiveSubscriptions(userToken);
        yield getInactiveSubscriptions(userToken);
        // feedback form
        window.open(FEEDBACK_FORM, '_blank');
      }
      break;
    case GET_ACCESS:
      yield getAccessHandler(userToken, action.value);
      break;
    case UPDATE_ACCESS:
      yield updateAccessHandler(userToken, action.domain, action.value);
      break;
    case GET_APPEARANCE:
      yield getAppearanceHandler(userToken, action.value);
      break;
    case UPDATE_APPEARANCE:
      yield updateAppearanceHandler(
        userToken,
        action.businessId,
        action.domain,
        action.value,
        action.successMessage,
      );
      break;
    case SEND_INVITE:
      yield sendInvite(userToken, action.userInfo);
      break;
    case GET_ALL_PLANS:
      yield getUserPlans(userToken, action.businessId);
      break;
    case GET_ACTIVE_PLANS:
      yield getActivePlans(userToken, action.value);
      break;
    case GET_INACTIVE_PLANS:
      yield getInactivePlans(userToken, action.businessId);
      break;
    case ADD_DOMAIN_TO_PLANS_REQUEST:
      yield addDomainsToPlans(userToken, action.value);
      break;
    case GET_DEV_DOMAINS:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        `/api/v1/admin/a/${action.businessId}/devdomains`,
        userToken,
        'GET',
      );

      yield put(
        response && response.status === 200
          ? getDevDomainsSuccess(response.data.message)
          : getDevDomainsSuccess([]),
      );
      break;
    case ADD_DEV_DOMAIN:
      response = yield callApi(
        HTTP_CLIENT_ENDPOINT,
        API_ADD_DEV_DOMAIN_ENDPOINT,
        userToken,
        'POST',
        {
          domain: action.value.domain,
          active: true,
          expires: 1583066596,
        },
      );

      if (action.value.lastDomain && response && response.status === 200) {
        response = yield callApi(
          HTTP_CLIENT_ENDPOINT,
          API_DEV_DOMAINS_ENDPOINT,
          userToken,
          'GET',
        );

        yield put(
          response && response.status === 200
            ? getDevDomainsSuccess(response.data.message)
            : getDevDomainsSuccess([]),
        );
        yield put(setDevDomainMsg('Selected domains added'));

        if (response && response.status === 200) {
          yield put(setApiSuccessMessage('Domain successfully added!'));
        }
      } else if (response === null) {
        yield put(setDevDomainMsg('Some error occurred'));
      }
      break;
    default:
      break;
  }
}

function* handleActions() {
  yield takeEvery('*', requestsHandler);
}

function* preloadData() {
  yield true;
}

export default function* saga() {
  yield spawn(preloadData);
  yield spawn(handleActions);
  yield spawn(settingsSaga);
  yield spawn(scanRecommendationsSaga);
}
