import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import MuiModal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styled from 'styled-components';
import Close from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';

export interface ModalProps {
  showModal: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  title?: string;
  container?: Element | (() => Element | null) | null;
  [key: string]: string | boolean | any | undefined;
}

export const Modal: React.FC<ModalProps> = ({
  showModal,
  handleClose,
  children,
  title,
  container,
  ...otherProps
}) => {
  return (
    <>
      <MuiModal
        open={showModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        container={container}
        {...otherProps}
      >
        <Fade in={showModal}>
          <Wrapper>
            <ModalContent>
              <Box display="flex" alignItems="center">
                <Typography variant="h6">{title || ''}</Typography>
                <CloseIconButton
                  onClick={handleClose}
                  aria-label="Close modal"
                  type="button"
                >
                  <Close />
                </CloseIconButton>
              </Box>
              {children}
            </ModalContent>
          </Wrapper>
        </Fade>
      </MuiModal>
    </>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 628px;
  border-radius: 12px;
  box-shadow:
    0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);

  @media screen and (max-width: 700px) {
    width: 90%;
  }
`;

const ModalContent = styled.div`
  position: relative;
  padding: 50px 30px 30px;
  max-height: 60vh;
  overflow-y: auto;
`;

const CloseIconButton = styled.button`
  position: fixed;
  cursor: pointer;
  right: 20px;
  top: 15px;
  background-color: transparent;
  border: none;
`;
