import React, { useMemo, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import {
  MainHeadersContainer,
  MainHeader,
} from '../../components/flowy-components/components/common';
import { Title } from './components/styled-components';
import {
  FlowyFeatureSelection,
  FlowyFeatureSelectionProps,
} from './components/flowy-feature-selection';
import { FlowyKnowledgeBase } from './components/flowy-knowledge-base';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { CreateScanButton } from '../dashboard-container/components/create-scan-button';
import { useNavigate } from 'react-router-dom';
import { NewScanInfo } from '@equally-ai-front/common/src/types/issues';
import { PreferencesAPI } from '@equally-ai-front/common/src/api/preferences-api';

interface DomainSummaryProps {
  name: string;
  value: string | number;
}
interface OverviewContainerProps {
  devDomain: DevDomain;
  onAudit: (url: string, cb?: () => void, scanDetails?: NewScanInfo) => void;
}

export const OverviewContainer = (props: OverviewContainerProps) => {
  const { devDomain, onAudit } = props;
  const navigate = useNavigate();
  const createScanButtonRef = useRef<HTMLButtonElement | null>(null);
  const {
    loadingDomains,
    loadingHistory,
    history,
    totalIssuesSummary,
    currentBusiness,
  } = useSelector((state: RootState) => ({
    loadingDomains: state.domains.loading,
    loadingHistory: state.scanHistory.loading,
    history: state.scanHistory.history,
    totalIssuesSummary: state.scanHistory.totalIssuesSummary,
    currentBusiness: state.business.currentBusiness,
  }));
  const [websiteSettings, setWebsiteSettings] = useState<any | undefined>({});

  const DOMAIN_SUMMARY: DomainSummaryProps[] = useMemo(() => {
    const { errors, warnings, notices } = totalIssuesSummary;
    const pageCount = history.length;
    let averageIssuesPerPage;
    if (history.length === 0) {
      averageIssuesPerPage = 'N/A';
    } else {
      averageIssuesPerPage = Math.round(
        (errors + warnings + notices) / pageCount,
      );
    }

    return [
      {
        name: 'Pages',
        value: pageCount === 0 ? 'N/A' : pageCount,
      },
      {
        name: 'Avg issues per page',
        value: averageIssuesPerPage,
      },
    ];
  }, [history, totalIssuesSummary]);

  const FLOWY_FEATURE_SELECTIONS: FlowyFeatureSelectionProps[] = useMemo(() => {
    const { language, platform, template } = devDomain;
    if (!currentBusiness) return [];
    return [
      {
        isSelectionCompleted: !!(language && platform && template),
        title: 'Tell us more about your website',
        subTitle: 'Help us give you better recommendations',
        handleClick: () => {
          navigate('../settings');
        },
      },
      {
        isSelectionCompleted: history.length > 0,
        title: 'Create your first scan',
        subTitle: `Check any url's issues and recommendations`,
        handleClick: () => {
          createScanButtonRef.current && createScanButtonRef.current.click();
        },
      },
      /* {
        isSelectionCompleted: false,
        title: 'Connect widget or your task manager',
        subTitle: 'Start resolving issues immediately',
        handleClick: () => {},
      }, */
      {
        isSelectionCompleted: !!websiteSettings?.is_scheduled_scan_active,
        title: 'Set schedule scanning',
        subTitle: 'Create schedule and alert settings',
        handleClick: () => {
          navigate(
            `/${currentBusiness.slug}/compliance-manager/settings/schedule-scans`,
          );
        },
      },
    ];
  }, [devDomain, history, websiteSettings, currentBusiness, navigate]);

  useEffect(() => {
    const fetchWebsiteSettings = async () => {
      const response = await PreferencesAPI.getWebsiteSettings({
        domainId: devDomain.id.toString(),
        businessId: devDomain.business_id.toString(),
      });
      const { data, isSuccess } = response;
      if (isSuccess && data) {
        setWebsiteSettings(data);
      }
    };

    void fetchWebsiteSettings();
  }, [devDomain]);

  return (
    <Wrapper>
      <OverviewHeader>
        <Box flexDirection={'row'} display={'flex'}>
          <MainHeader
            style={{ fontWeight: 500, paddingRight: '5px' }}
          >{`Summary for `}</MainHeader>
          <MainHeader style={{ fontWeight: 300 }}>{devDomain.name}</MainHeader>
        </Box>

        <DomainSummaryWrapper>
          {DOMAIN_SUMMARY.map(({ value, name }) => (
            <DomainSummary key={name}>
              <DomainSummaryHeader>{name}</DomainSummaryHeader>
              <DomainSummaryText>{value}</DomainSummaryText>
            </DomainSummary>
          ))}
        </DomainSummaryWrapper>
      </OverviewHeader>
      <OverviewContent>
        <OverviewTitle>Welcome to Flowy</OverviewTitle>
        <FlowyFeatureSelections>
          {FLOWY_FEATURE_SELECTIONS.map((selection) => (
            <FlowyFeatureSelection key={selection.title} {...selection} />
          ))}
        </FlowyFeatureSelections>
        <FlowyKnowledgeBase />
      </OverviewContent>
      <LoadingView open={loadingDomains || loadingHistory} />
      <CreateScanButtonWrapper>
        <CreateScanButton
          onAudit={onAudit}
          devDomain={devDomain}
          ref={createScanButtonRef}
        />
      </CreateScanButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)``;

const OverviewHeader = styled(MainHeadersContainer)`
  border-bottom: 1.5px solid #e0e0e0;
`;

const DomainSummaryWrapper = styled.div`
  @media screen and (min-width: 1300px) {
    padding: 0 50px;
  }

  margin: 16px 0;
  display: flex;
  padding: 0 30px;

  @media screen and (max-width: 1100px) {
    padding: 0 20px;
  }

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
  }
`;

const DomainSummary = styled.div`
  @media screen and (min-width: 1300px) {
    width: 250px;
    height: 120px;
  }
  margin-right: 20px;

  width: 30%;
  height: 100px;
  border: 1.5px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (pointer: none), (pointer: coarse) {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const DomainSummaryHeader = styled.h3`
  color: #9ea6b4;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  line-height: 15px;
  height: 25px;
  display: flex;
  align-items: center;
  margin-top: 0;
`;

const DomainSummaryText = styled.p`
  font-size: 38px;
  color: #000a14;
  font-weight: 700;
  margin-top: 5px;
  line-height: 38px;
  margin: 0;
`;

const OverviewContent = styled.div``;

const OverviewTitle = styled(Title)`
  margin-bottom: 5px;
`;

const FlowyFeatureSelections = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const CreateScanButtonWrapper = styled.div`
  display: none;
`;
