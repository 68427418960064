import {
  CustomLiText,
  DomainCustomLi,
  DomainCustomUL,
  PagesListContainer,
} from '../common/pages-list-container';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatExpiration } from '../../../lib/date';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { useNavigate } from 'react-router-dom';
import { getComplianceManagerLinks, NavBarLink } from '../consts';
import { isUserPermitted } from '../../../lib/permissions';
import { getHasActiveDevDomains } from '../../../utils/domain.utils';
interface ComplianceManagerViewProps {
  isOnMobile: boolean;
}
export const ComplianceManagerView = (props: ComplianceManagerViewProps) => {
  const domains = useSelector((state: RootState) => state.userProducts.domains);
  const analytics = useAnalytics();
  const domainsWithTimeLeft = (domains || []).map((domain: any) => ({
    ...domain,
    is_expired:
      formatExpiration(
        domain.domain?.expiration || domain.dev_domain?.expiration,
      ) === 'Expired',
  }));
  const currentBusiness = useSelector(
    (state: RootState) => state.business.currentBusiness,
  );

  const { isOnMobile } = props;
  const visibleDevDomains: any[] = [...domainsWithTimeLeft]
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((devDomain) => devDomain.dev_domain_id);
  const navigate = useNavigate();

  const launchFlowy = (domainId: any, domainName: string) => {
    analytics.track('Flowy Website Card Option Clicked', {
      id: domainId,
      url: domainName,
    });
    navigate(`/${currentBusiness?.slug}/developers/${domainId}/flowy/overview`);
  };

  return (
    <>
      {visibleDevDomains.length > 0 && (
        <PagesListContainer ismobile={isOnMobile ? isOnMobile.toString() : ''}>
          <Title>Domains</Title>
          <DomainCustomUL
            isWebsiteUrlPresent
            ismobile={isOnMobile ? isOnMobile.toString() : ''}
            role="list"
          >
            {visibleDevDomains
              .sort(
                (devDomainA, devDomainB) =>
                  devDomainA.is_expired - devDomainB.is_expired,
              )
              .map((devDomain: any, ddidx) => {
                const { name, dev_domain_id, is_expired } = devDomain;
                return (
                  <DomainCustomLi
                    role="listitem"
                    tabIndex={0}
                    key={`scan-group-${ddidx}`}
                    ismobile={isOnMobile ? isOnMobile.toString() : ''}
                    onClick={() => {
                      !is_expired && launchFlowy(dev_domain_id, name);
                    }}
                    isexpired={is_expired.toString()}
                    onKeyDown={(evt) => {
                      if (evt.key === 'Enter' || evt.key === ' ') {
                        launchFlowy(dev_domain_id, name);
                      }
                    }}
                  >
                    <CustomLiText>{name}</CustomLiText>
                    {is_expired && <ExpiredTag>Expired</ExpiredTag>}
                  </DomainCustomLi>
                );
              })}
          </DomainCustomUL>
        </PagesListContainer>
      )}
    </>
  );
};

const ExpiredTag = styled.span`
  background-color: #bdbdbd;
  color: #000000;
  position: absolute;
  font-size: 12px;
  right: -80px;
  top: 8px;
  border-radius: 8px;
  padding: 4px 10px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 13px;
  background-color: #f1f3f5;
  color: #50565f;
  border-radius: 8px;
  padding: 5px 8px;
  width: fit-content;
`;
