export const newBusinessFormFields: Record<string, any> = {
  name: {
    key: 'name',
    displayName: 'Name',
    isMandatory: true,
    regexRule: null,
    validate: null,
    type: 'text',
  },
  size: {
    key: 'size',
    displayName: 'Size',
    isMandatory: true,
    regexRule: null,
    validate: null,
    type: 'text',
  },
  isPartner: {
    key: 'is_partner',
    displayName: 'Is Partner',
    isMandatory: true,
    regexRule: null,
    validate: null,
    type: 'text',
  },
};
export const newMemberFormFields = {
  name: {
    key: 'name',
    displayName: 'Name',
    isMandatory: true,
    regexRule: null,
    validate: null,
    type: 'text',
  },
  role: {
    key: 'role',
    displayName: 'Role',
    isMandatory: true,
    regexRule: null,
    validate: null,
    type: 'text',
  },
};
