import { useMemo } from 'react';

import { useQuery } from '../../../hooks/useQuery';

import { QUERY_PARAM_AUDIT } from '../contants';

import { getURLDetails } from '../helpers';

const useQueryAuditURL = () => {
  const queryAuditURL = useQuery().get(QUERY_PARAM_AUDIT);
  const details = useMemo(() => getURLDetails(queryAuditURL), [queryAuditURL]);
  return [queryAuditURL, details];
};

export default useQueryAuditURL;
