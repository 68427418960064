import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import Close from '@mui/icons-material/Close';
import ArrowForward from '@mui/icons-material/KeyboardBackspace';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { PROMO_URL } from '../../../common/magicValues';
import {
  HeaderColorType,
  StickyHeader,
} from '../../../components/sticky-header/sticky-header';
import { formatExpiration } from '../../../lib/date';

interface FreeTrialBannerProps {
  devDomain: DevDomain;
  closeFreeTrialBanner: () => void;
}

export const FreeTrialBanner = ({
  devDomain,
  closeFreeTrialBanner,
}: FreeTrialBannerProps) => {
  const expirationTimeLeft = useMemo(() => {
    const expiration = formatExpiration(devDomain.expiration);
    if (expiration === 'Expired') {
      return 'Your domain is now expired.';
    }

    return expiration;
  }, [devDomain]);

  return (
    <Wrapper>
      <BannerContent>
        <StickyHeader
          variant={HeaderColorType.GREY}
          style={{ padding: '10px 0', height: '50px' }}
          title={''}
          // @ts-ignore
          subText={
            <BannerText>
              <BannerText>
                You are on a free trial, which includes all features for&nbsp;
                {expirationTimeLeft}. Why wait ?
              </BannerText>
              <UpgradePlan
                href={`${PROMO_URL}/pricing`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <UpgradeText>Upgrade now</UpgradeText>
                <ArrowForward
                  style={{ fill: '#3ba978', transform: 'rotate(180deg)' }}
                />
              </UpgradePlan>
            </BannerText>
          }
          handleCloseStickyPlanMessage={closeFreeTrialBanner}
        />
        {/* <BannerText></BannerText> */}
        {/* <BannerCloseIcon */}
        {/*  onClick={closeFreeTrialBanner} */}
        {/*  aria-label="Close Free Trial Banner" */}
        {/*  type="button" */}
        {/* > */}
        {/*  <Close style={{ fill: '#000000' }} /> */}
        {/* </BannerCloseIcon> */}
      </BannerContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  color: #000000;
  width: 95%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    margin: -15px 0 0 60px;
    width: 80%;
  }
`;

const BannerContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  //height: 60px;
  flex-wrap: wrap;
  //padding-right: 20px;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const BannerText = styled.span`
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const UpgradePlan = styled.a`
  display: inline-flex;
  margin-left: 4px;
  cursor: pointer;
`;

const UpgradeText = styled.span`
  color: #3ba978;
  display: inline-block;
  margin-right: 3px;
`;
