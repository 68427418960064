import {
  GET_BILLING_PROFILE_SUCCESS,
  UPDATE_BILLING_PROFILE_SUCCESS,
} from '../actionTypes';

const initialState = {
  billingProfile: {
    full_name: '',
    street_address: '',
    city: '',
    zip: '',
    country: '',
    vat_number: '',
  },
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BILLING_PROFILE_SUCCESS:
      return { ...state, billingProfile: action.value };
    case UPDATE_BILLING_PROFILE_SUCCESS:
      return { ...state, billingProfile: action.value };
    default: {
      return state;
    }
  }
}
