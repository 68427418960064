import { Card, Typography } from '@mui/material';
import React from 'react';
import { CardDescription, FieldContainer } from '../components/common';
import { serviceFields } from '../consts';

interface OrderCardProps {
  service: any;
}

export const OrderCard = (props: OrderCardProps) => {
  const { service } = props;

  return (
    <Card elevation={1} style={{ border: '1px solid #F5F7FA' }}>
      <CardDescription>
        {Object.keys(serviceFields).map((key) => {
          const Component =
            serviceFields[key]?.getComponent &&
            serviceFields[key]?.getComponent({ text: service[key] });
          return (
            <FieldContainer key={key}>
              <div>{Component || <Typography children={service[key]} />}</div>
            </FieldContainer>
          );
        })}
      </CardDescription>
    </Card>
  );
};
