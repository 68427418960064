import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    on: {
      darkHigh: string;
      darkMedium: string;
      darkLow: string;
      lightHigh: string;
      lightMedium: string;
      lightLow: string;
      errorHigh: string;
      successHigh: string;
      warningHigh: string;
    };
    shades: {
      shade900: string;
      shade800: string;
      shade700: string;
      shade300: string;
      shade200: string;
      shade100: string;
      shade50: string;
    };
    status: {
      error: string;
      success: string;
      warning: string;
    };
  }

  interface PaletteOptions {
    on?: {
      darkHigh?: string;
      darkMedium?: string;
      darkLow?: string;
      lightHigh?: string;
      lightMedium?: string;
      lightLow?: string;
      errorHigh?: string;
      successHigh?: string;
      warningHigh?: string;
    };
    shades?: {
      shade900?: string;
      shade800?: string;
      shade700?: string;
      shade300?: string;
      shade200?: string;
      shade100?: string;
      shade50?: string;
    };
    status?: {
      error?: string;
      success?: string;
      warning?: string;
    };
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: { main: '#454284', dark: '#3e3c77', light: '#6966b2' },
    secondary: { main: '#C2F4D7' },
    warning: { main: '#F2DF87' },
    background: {
      default: '#fff',
    },
    on: {
      darkHigh: '#000A14',
      darkMedium: 'rgba(0, 10, 20, 0.85)',
      darkLow: 'rgba(0, 10, 20, 0.65)',
      lightHigh: '#FFFFFF',
      lightMedium: 'rgba(255, 255, 255, 0.85)',
      lightLow: 'rgba(255, 255, 255, 0.65)',
      errorHigh: '#661F20',
      successHigh: '#1E5643',
      warningHigh: '#6F321B',
    },
    shades: {
      shade900: '#1F2933',
      shade800: '#323F4B',
      shade700: '#3E4C59',
      shade300: '#9AA5B1',
      shade200: '#CBD2D9',
      shade100: '#E4E7EB',
      shade50: '#F5F7FA',
    },
    status: {
      error: '#FCB1B1',
      success: '#B6ECD8',
      warning: '#F2DF87',
    },
  },
  typography: {
    fontFamily: ['DM Sans', 'Desktop/Roboto Regular 2', 'sans-serif'].join(','),
  },
});
