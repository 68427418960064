import styled from '@emotion/styled';
import { TextField as MuiTextField, Theme } from '@mui/material';

export const FormTextfield = styled(MuiTextField)<{ theme: Theme }>`
  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.palette.on.darkMedium};
  }

  .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.on.darkHigh};
  }

  &:hover .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiFormLabel-root {
    &.Mui-focused {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .MuiOutlinedInput-root {
    height: 48px;
    background-color: #ffffff;
    padding-right: 0;
    position: relative;

    & input {
      height: 48px;
      padding: 0 14px;
      border-radius: 8px;
      overflow: hidden;
    }

    & .MuiInputAdornment-positionStart {
      margin: 0;
    }

    & fieldset {
      border: 2px solid #e4e7eb;
      box-sizing: border-box;
      border-radius: 8px;
    }

    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const FormTextfieldLight = styled(FormTextfield)`
  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.on.lightMedium};
  }

  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.palette.on.lightMedium};
  }

  .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.on.lightMedium};
  }

  && fieldset {
    border: 2px solid rgba(255, 255, 255, 0.24);
  }
`;
