import firebase from 'firebase/app';
import {
  confirmNewPassword,
  initiateForgotPassword,
  loginUserToPool,
  logout,
  registerUserToPool,
  resendConfirmationCode,
  resetPassword,
  sendVerificationCode,
  verifyEmail,
} from './cognito.utils';
import { RegisterPayload } from '../types';
import { firebaseAuth } from './firebase.utils';

const codeToMessageMap: { [key: string]: string } = {
  'auth/too-many-requests':
    'Too many failed login attempts. Please try again later.',
  'auth/user-disabled': 'Account is disabled. Please contact support.',
  'custom-auth/email-not-verified': 'Your email is not verified!',
  'auth/user-not-found':
    "Couldn't find your Equally AI account. If you signed up with a Google account, click Login with Google.",
  'auth/email-already-in-use': 'Email address already in use.',
  UserNotFoundException:
    "Couldn't find your Equally AI account. If you signed up with a Google account, click Login with Google.",
  UsernameExistsException: 'User already exists',
  LimitExceededException:
    'You have exceeded the limit of requests. Please try again later.',
  NotAuthorizedException_CONFIRMED: 'Email already confirmed, please login',
  InvalidParameterException: 'Phone number is invalid',
};

export class AuthUtils {
  static async register({
    email,
    password,
    phoneNumber,
    displayName,
    userId,
    businessName,
  }: RegisterPayload) {
    return await registerUserToPool({
      email,
      password,
      displayName,
      phoneNumber,
      userId,
      businessName,
    });
  }

  static async onLoginWithBase(email: string, password: string) {
    return firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  static async onLoginWithEmail(email: string, password: string) {
    try {
      let cognitoUserResponse = await loginUserToPool(email, password);
      // @ts-ignore
      if (!cognitoUserResponse?.user) {
        const firebaseUser = (await AuthUtils.onLoginWithBase(email, password))
          ?.user;

        if (firebaseUser) {
          cognitoUserResponse = await registerUserToPool({
            email: firebaseUser.email || '',
            displayName: firebaseUser.displayName || '',
            password,
            userId: firebaseUser.uid,
            businessName: '',
          });
        }
        cognitoUserResponse = await loginUserToPool(email, password);
      }

      if (cognitoUserResponse?.emailVerified !== undefined) {
        if (!cognitoUserResponse?.emailVerified) {
          return cognitoUserResponse;
        }
      }

      return cognitoUserResponse;
    } catch (error) {
      throw error;
    }
  }

  static async loginWithGoogle() {
    return firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  static async resendVerificationEmail(email: string) {
    return await resendConfirmationCode(email);
  }

  static async resetPassword(oldPassword: string, newPassword: string) {
    return await resetPassword(oldPassword, newPassword);
  }
  static async confirmNewPassword(
    email: string,
    oldPassword: string,
    newPassword: string,
  ) {
    return await confirmNewPassword(email, oldPassword, newPassword);
  }

  static async initiateForgotPassword(email: string, callback: () => void) {
    return await initiateForgotPassword(email, callback);
  }
  static async sendVerificationCode(email: string, callback: () => void) {
    return await sendVerificationCode(email);
  }
  static async verifyEmail(email: string, code: string) {
    return await verifyEmail(email, code);
  }

  static logout() {
    logout();
  }

  static getErrorMessage(code: string) {
    return codeToMessageMap[code] || 'Oops, something went wrong';
  }
}

export function parseJwt(token: string) {
  const accessToken = token.split('.');
  const base64Url = accessToken[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    // eslint-disable-next-line no-undef
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export const checkIfTokenHasExpired = (expiryTime: number): boolean => {
  const expiryDate = new Date(expiryTime * 1000);
  const currentDate = new Date();

  return currentDate > expiryDate;
};
