import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { formatExpiration } from '../lib/date';

export const formatDomainInputUrl = (
  devDomain: DevDomain,
  value: string,
): string => {
  const DOMAIN_INPUT_URL_REGEX = new RegExp(
    `^(?:https?:\\/\\/)?(?:www\\.)?${devDomain.name}(?:\\/(.+))?$`,
  );

  if (value.includes(devDomain.name)) {
    const matchResult = DOMAIN_INPUT_URL_REGEX.exec(value);
    const formattedPathname =
      matchResult && matchResult[1] !== undefined ? matchResult[1] : '';
    return formattedPathname.replace(/^\/+/, '');
  }

  return value.replace(/^\/+/, '');
};

export const getHasActiveDevDomains = (domains: any[]): boolean => {
  if (domains?.length === 0) return false;
  return !!domains?.find((domain) => domain?.is_developer);
};

export const getDomainHTMLInstallationCode = (
  apiKey: string,
  widgetUrl: string,
) => {
  return `
    <script defer>document.addEventListener('load', function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s); js.id = id;js.src = "${widgetUrl}";fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'equallyWidget'));!window.EQUALLY_AI_API_KEY&&(window.EQUALLY_AI_API_KEY="${apiKey}",intervalId=setInterval(function(){window.EquallyAi&&(clearInterval(intervalId),window.EquallyAi=new EquallyAi)},500));</script>
  `;
};
