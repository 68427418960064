import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface CopyIconProps {
  action: () => void;
  iconSrc: string;
  checkMarkIcon?: string;
  altText: string;
  ariaLabel: string;
}
const CopyButton = (props: CopyIconProps) => {
  const { action, iconSrc, checkMarkIcon, altText, ariaLabel } = props;
  const [isCopied, setIsCopied] = useState(false);
  const toggleCopiedCodeState = async () => {
    action();
    setIsCopied(true);
  };
  useEffect(() => {
    if (!isCopied) return;
    const toggleMode = async () => {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setIsCopied(false);
    };
    void toggleMode();
  }, [isCopied]);
  return (
    <StyledButton onClick={toggleCopiedCodeState} aria-label={ariaLabel}>
      <img
        src={isCopied && checkMarkIcon ? checkMarkIcon : iconSrc}
        alt={altText}
      />
    </StyledButton>
  );
};

const StyledButton = styled.button<{ hasExtraIcon?: boolean }>`
  border: none;
  background-color: #4ad493;
  width: 32px;
  height: 32px;
  margin-right: ${(p) => (p.hasExtraIcon ? '10px' : '0')};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CopyButton;
