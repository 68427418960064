import firebase from 'firebase/app';
import 'firebase/auth';
import { FIREBASE_CONFIG } from '../magicValues';

export const app = firebase.initializeApp(FIREBASE_CONFIG);
const firebaseAuth = firebase.auth(app);

function verifyEmail(code: string) {
  return firebaseAuth.applyActionCode(code);
}

function resetPassword(code: string, newPassword: string) {
  return firebaseAuth.confirmPasswordReset(code, newPassword);
}

export { firebase, firebaseAuth, verifyEmail, resetPassword };
