import React, { useMemo } from 'react';
import { Chart } from 'react-google-charts';
// import {
//   CartesianGrid,
//   Line,
//   LineChart,
//   ResponsiveContainer,
//   Tooltip,
//   XAxis,
//   YAxis,
// } from 'recharts';
import { capitalizeFirstLetter } from '@equally-ai-front/common/src/helpers';
import styled from 'styled-components';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
import { Card, HeaderTitle } from '../../components/css-components';
import { PerformanceSummary } from './performance-summary';

const calculatePercentage = (
  currentValue: number,
  totalValue: number,
): string => {
  const percentage = (currentValue / totalValue) * 100;

  return percentage.toString().split('.').length > 1
    ? percentage.toFixed(1)
    : percentage.toString();
};

// const accessibilityScoreProgressData = [
//   {
//     name: '03/23',
//     issues: 100,
//   },
//   {
//     name: '04/23',
//     issues: 150,
//   },
//   {
//     name: '05/23',
//     issues: 200,
//   },
//   {
//     name: '06/23',
//     issues: 160,
//   },
//   {
//     name: '07/23',
//     issues: 300,
//   },
//   {
//     name: '08/23',
//     issues: 220,
//   },
// ];

// const colors = ['#135335', '#22915D', '#4AD493', '#2CBA77', '#83E2B6'];
const colors = ['#454284', '#4A5284', '#596391', '#6B739D', '#7C7BA8'];

interface IssuesChartSectionProps {
  scanIssuesGroupingByElement: any[];
}

const options = {
  title: '',
  colors,
  pieSliceText: 'percentage',
  pieSliceTextStyle: {
    color: '#ffffff',
    fontSize: 8,
  },
  chartArea: {
    width: '70%',
    height: '70%',
  },
  sliceVisibilityThreshold: 0.0001,
};

export const IssuesChartSection = ({
  scanIssuesGroupingByElement,
}: IssuesChartSectionProps) => {
  const { allIssues } = useAppSelector((state) => state.issues);

  const pieChartDataForIssuesGroupedByElement = useMemo(() => {
    return [
      ['Issues Element Group Name', 'Number of Issues'],
      ...scanIssuesGroupingByElement.map(([issuesElementGroupName, issues]) => [
        `${capitalizeFirstLetter(
          issuesElementGroupName,
        )} - ${calculatePercentage(issues.length, allIssues.length)}%`,
        issues.length,
      ]),
    ];
  }, [scanIssuesGroupingByElement, allIssues]);

  return (
    <Wrapper>
      {/* <IssuesCard>
        <CustomHeaderTitle>Accessibility Score Progress</CustomHeaderTitle>
        <ResponsiveContainer
          className="line-graph-wrapper"
          width={CHART_WIDTH}
          height="80%"
        >
          <LineChart
            width={CHART_WIDTH}
            height={CHART_HEIGHT}
            data={accessibilityScoreProgressData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              label={
                <text
                  offset="5"
                  x="25"
                  y="103.5"
                  fill="#808080"
                  transform="rotate(-90, 25, 103.5)"
                  textAnchor="start"
                >
                  <tspan x="-25" dy="0.355em">
                    Number of Issues
                  </tspan>
                </text>
              }
            />
            <Tooltip />
            <Line type="monotone" dataKey="issues" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </IssuesCard> */}
      <CustomHeaderTitle>Type of Issues</CustomHeaderTitle>
      <IssuesCard>
        {allIssues.length > 0 ? (
          <>
            <PieChartWrapper
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Chart
                chartType="PieChart"
                data={pieChartDataForIssuesGroupedByElement}
                options={options}
                width="100%"
                height={370}
              />
            </PieChartWrapper>
            <PerformanceSummary
              scanIssuesGroupingByElement={scanIssuesGroupingByElement}
            />
          </>
        ) : (
          <NoDataText>No Data</NoDataText>
        )}
      </IssuesCard>
    </Wrapper>
  );
};

const Wrapper = styled(Card)`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .recharts-responsive-container {
    margin-top: 10px;

    @media screen and (max-width: 860px) {
      margin: auto;
    }
  }

  .line-chart-label {
    transform: rotate(-90deg);
    transform-origin: initial;
  }
`;

const IssuesCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 40px 0 0;
  position: relative;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
  }
`;

const CustomHeaderTitle = styled(HeaderTitle)`
  padding: 17px;
`;

const PieChartWrapper = styled.div`
  width: 48%;

  @media (pointer: none), (pointer: coarse) {
    width: 100%;
  }
`;

const NoDataText = styled.p`
  width: 100%;
  text-align: center;
  margin: 10px 0 20px;
  font-weight: 500;
`;
