import BusinessAPI from '@equally-ai-front/common/src/api/business';
import { getBusinessInvitations } from '@equally-ai-front/common/src/redux/business-slice/business';
import { PersonalDetails } from '@equally-ai-front/common/src/redux/slice/types';
import { NewTeamMember } from '@equally-ai-front/common/src/types/business';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../index';
import { setApiError, setApiSuccessMessage } from '../store/actions';

interface useAddTeamMemberProps {
  onComplete: () => void;
  personalDetails: PersonalDetails;
}

export const ROLES = ['Viewer', 'Editor', 'Admin'];
export const useAddTeamMember = ({
  personalDetails,
  onComplete,
}: useAddTeamMemberProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const [newMembers, setNewMembers] = useState<NewTeamMember[]>([
    { role: ROLES[0], email: '' },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const updateTeamMember = (
    index: number,
    key: 'email' | 'role',
    value: string,
  ) => {
    const updatedMembers = [...newMembers];
    updatedMembers[index][key] = value;
    setNewMembers(updatedMembers);
  };
  const handleAddMember = () => {
    setNewMembers([...newMembers, { role: ROLES[0], email: '' }]);
  };

  const isEmailValid = (member: NewTeamMember): boolean => {
    const { email } = member;
    return email.trim().length > 0 && email !== personalDetails.email;
  };

  const handleSubmit = async () => {
    for (const member of newMembers) {
      const { email, role } = member;
      if (!isEmailValid(member)) {
        return;
      }

      setIsLoading(true);
      // eslint-disable-next-line no-await-in-loop
      const { error, isSuccess } = await BusinessAPI.addTeamMember({
        email: email.trim(),
        role,
        businessId: currentBusiness.id,
      });

      if (!isSuccess) {
        dispatch(setApiError(error));
        setIsLoading(false);
      }

      if (isSuccess) {
        dispatch(setApiSuccessMessage('Invite sent!'));
        dispatch(getBusinessInvitations({ businessId: currentBusiness.id }));
        setIsLoading(false);
      }
    }
    onComplete();
  };

  return {
    handleSubmit,
    updateTeamMember,
    newMembers,
    handleAddMember,
    isEmailValid,
    isLoading,
  };
};
