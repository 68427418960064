import groupBy from 'lodash/groupBy';

import {
  AUDIT_STATUS_ERROR,
  AUDIT_STATUS_SUCCESS,
  AUDIT_STATUS_WARNING,
  TYPE_CODE_ERROR,
  TYPE_CODE_WARNING,
} from '../../contants';
import TEMPLATE_WCAG2AA_BY_ELEMENTS from './groupAuditResults';
import TEMPLATE_WCAG2AA from './wcag2aa.json';

export const getWCAG2AATemplate = () => {
  return JSON.parse(JSON.stringify(TEMPLATE_WCAG2AA));
};

export const checkStatus = (issueList = []) => {
  let reportStatus = AUDIT_STATUS_SUCCESS;

  for (let i = 0; i < issueList.length; i += 1) {
    const issue = issueList[i];

    if (issue.typeCode === TYPE_CODE_ERROR) {
      reportStatus = AUDIT_STATUS_ERROR;
      break;
    }

    if (issue.typeCode === TYPE_CODE_WARNING) {
      reportStatus = AUDIT_STATUS_WARNING;
    }
  }

  return reportStatus;
};

export const buildWCAGReport = (url, issueListt = []) => {
  const issueList = issueListt;
  const issueByKey = groupBy(issueList, 'code');
  const template = getWCAG2AATemplate();

  const reportStatus = checkStatus(issueList);

  const reportList = template.map((guideline) => {
    let successCriteriaCount = 0;
    let failureCriteriaCount = 0;

    const successcriteria = guideline.successcriteria.map((criteria) => {
      const allIssue = criteria.rules
        .flatMap((rule) => {
          return issueByKey[rule.code];
        })
        .filter(Boolean);

      const criteriaStatus = checkStatus(allIssue);
      if (criteriaStatus === AUDIT_STATUS_ERROR) {
        failureCriteriaCount += 1;
      } else {
        successCriteriaCount += 1;
      }

      return {
        ...criteria,
        status: criteriaStatus,
      };
    });

    return {
      ...guideline,
      successcriteria: successcriteria,
      successCount: successCriteriaCount,
      failureCount: failureCriteriaCount,
    };
  });

  const groupTemplateArray = {
    clickables: [],
    forms: [],
    sensory: [],
    semantics: [],
    tables: [],
    objects: [],
    orientation: [],
    ruby: [],
    readability: [],
    graphics: [],
    images: [],
    applet: [],
    media: [],
    title: [],
    document: [],
    general: [],
  };

  // Get my templates as an array of keys and values
  const TEMPLATES_BY_ELEM_ARRAY = Object.entries(TEMPLATE_WCAG2AA_BY_ELEMENTS);

  // Get grouped issues as an array of keys and values
  const issueByKeyArr = Object.entries(issueByKey);

  // Loop over template groupings and separate the group name from the expected guideline etc.
  TEMPLATES_BY_ELEM_ARRAY.forEach((templateByElem) => {
    const groupName = templateByElem[0];
    const groupValueArr = templateByElem[1];

    // Loop over the expected guideline in template grouping and check if any of the grouped issue has identical guideline and get the success and failure count. If it does push it into array containing the expected grouping
    groupValueArr.forEach((groupValue) => {
      issueByKeyArr.forEach((issue) => {
        const issueWCAG2AAGuideline = issue[0];
        const issueWCAG2AAGuidelineValues = issue[1];
        let successCriteriaCount = 0;
        let failureCriteriaCount = 0;

        issueWCAG2AAGuidelineValues.forEach((issueWCAG2AAGuidelineValue) => {
          if (issueWCAG2AAGuidelineValue.type === 'error') {
            failureCriteriaCount += 1;
          } else {
            successCriteriaCount += 1;
          }
        });

        if (issueWCAG2AAGuideline === groupValue.principle) {
          groupTemplateArray[groupName].push({
            apiResult: issueWCAG2AAGuidelineValues,
            extraInformation: {
              ...groupValue,
              successCriteriaCount,
              failureCriteriaCount,
            },
          });
        }
      });
    });
  });

  return {
    url,
    list: reportList,
    listByElements: groupTemplateArray,
    status: reportStatus,
  };
};
