export const HTTP_CLIENT_REDIRECT = process.env.REACT_APP_HTTP_CLIENT_REDIRECT;
export const PROMO_URL = process.env.REACT_APP_PROMO_URL;
export const HTTP_CLIENT_ENDPOINT = process.env.REACT_APP_HTTP_CLIENT_ENDPOINT;
export const HTTP_CLIENT_DEVTOOLS_URL =
  process.env.REACT_APP_HTTP_CLIENT_DEVTOOLS_URL;
export const WIDGET_HTTP_ENDPOINT = process.env.REACT_APP_WIDGET_CONFIG_URL;
export const PAYPAY_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const INTERCOM_WEB_IDENTIFICATION_KEY =
  process.env.REACT_APP_INTERCOM_WEB_IDENTIFICATION_KEY;
export const PROMO_POST_INSTALL_URL =
  process.env.REACT_APP_PROMO_POST_INSTALL_URL;

export const API_COMPLIANCE_ENDPOINT = '/api/v1/admin/a/compliance';
export const API_DOMAINS_ENDPOINT = '/api/v1/admin/a/domains';
export const API_ADD_DOMAIN_ENDPOINT = '/api/v1/admin/a/domain';
export const API_DELETE_DOMAIN_ENDPOINT = '/api/v1/admin/a/domaindelete';
export const API_ADD_DOMAIN_KEY_ENDPOINT = '/api/v1/admin/a/domainkey';
export const API_DELETE_DOMAIN_KEY_ENDPOINT = '/api/v1/admin/a/domainkeydelete';
export const API_ACTIVE_SUBSCRIPTIONS_ENDPOINT =
  '/api/v1/admin/a/subscriptions/active';
export const API_INACTIVE_SUBSCRIPTIONS_ENDPOINT =
  '/api/v1/admin/a/subscriptions/inactive';
export const API_UPDATE_SUBSCRIPTION = '/api/v1/admin/a/subscriptions/update';
export const API_GET_PRICES = '/api/v1/admin/u/pricing';
export const API_ADD_TRANSACTION = '/api/v1/paypal/onapprove';
export const API_ADD_SUBSCRIPTION = '/api/v1/admin/a/subscriptions/add';
export const API_BILLING_PROFILE = '/api/v1/admin/a/billingprofile';
export const API_CANCEL_SUB_ENDPOINT = '/api/v1/admin/a/subscriptions/cancel';
export const API_TEAM_MEMBERS = '/api/v1/admin/a/team-members';
export const API_TEAM_INVITATIONS = '/api/v1/admin/a/invitations';

export const API_DOMAIN_APPEARANCE = '/api/v1/admin/a/widget-site-settings/';
export const API_ACCESS_STATEMENT = '/api/v1/admin/a/accessibility-statement/';
export const API_PERSONAL_DETAILS = '/api/v1/admin/a/personal-details';

export const API_DEV_DOMAINS_ENDPOINT = '/api/v1/admin/a/devdomains';
export const API_ADD_DEV_DOMAIN_ENDPOINT = '/api/v1/admin/a/devdomainadd';

export const API_GET_ALL_PLANS = '/api/v1/admin/a/ml/plans';
export const API_GET_PLANS_TYPE = '/api/v1/admin/a/ml/plans';
export const API_GET_INACTIVE_PLANS =
  '/api/v1/admin/a/ml/subscriptions/inactive';
export const API_ADD_DOMAIN_TO_PLAN =
  '/api/v1/admin/a/ml/subscriptions/records';
export const API_SUBSCRIPTION_RECORDS =
  '/api/v1/admin/a/ml/subscriptions/records/all';

export const PLAN_EXPIRY_MESSAGE =
  'Your plan has expired! Choose a plan to ensure your website stays compliant.';
export const FLOWY_PLAN_EXPIRY_MESSAGE =
  'Your plan has expired! Select a plan to save your data and continue your accessibility journey';

export const FREE_TRIAL_MESSAGE =
  'Your website is currently on a 7-day free trial. Select a plan to ensure compliance.';
export const FLOWY_FREE_TRIAL_MESSAGE =
  'You are on a free trial, which includes all features for 7 day. Why wait ? Choose a plan now';

export const WIDGET_NOT_ADDED =
  'It looks like you haven’t added the installation code to your website.';
export const WIDGET_NOT_ADDED_TIP =
  'If the accessibility interface appears on your website, then the installation was successful. No worries; it will be detected shortly.';
export const WIDGET_NOT_SETUP =
  'Your website is being setup – Please allow up to 48 hours for the installation process to take effect.';
export const WIDGET_SETUP_FINISHED =
  'Your website is now compliant – Your website is now compliant to ADA & WCAG standards.';

export const QUERY_PARAM_AUDIT = 'audit';
export const QUERY_PARAM_PROMO = 'promo';
export const QUERY_PARAM_APIKEY = 'apiKey';
export const QUERY_PARAM_REDIRECT_TOKEN = 'redirect_token';
export const QUERY_PARAM_REDIRECT_TO_SIGN_UP = 'signup';
export const QUERY_PARAM_REDIRECT_AUDIT = 'redirect_audit';
export const QUERY_PARAM_STATEMENT_DOMAIN = 'domain';
export const QUERY_PARAM_STATEMENT_LANGUAGE = 'lang';
export const QUERY_PARAM_VERIFICATION_MODE = 'mode';
export const QUERY_PARAM_DOMAINS_PAGE = 'page';
export const QUERY_PARAM_PRODUCT_TYPE = 'product_type';
export const QUERY_PARAM_TAPFILIATE_CODE = 'ref';
export const QUERY_PARAM_SPECIAL_OFFER = 'specialCodeOffer';
export const QUERY_PARAM_GOOGLE_SIGNUP = 'with_google';
export const QUERY_PARAM_REDIRECT_EXTENSION = 'fromextension';

export const FEEDBACK_FORM = 'https://forms.gle/gUmb2UwvcQ58Htvu7';
export const DOCS_URL = process.env.REACT_APP_DOCS_URL || '';
export const ORDER_AUDIT = process.env.REACT_APP_ORDER_AUDIT || '';
export const FLOWY_EARLY_ACCESS =
  process.env.REACT_APP_FLOWY_EARLY_ACCESS || '';
export const SCHEDULE_A_MEETING = process.env.REACT_APP_SCHEDULE_A_MEETING;

// Sentry
export const SENTRY_API = process.env.REACT_APP_SENTRY_API;
export const SENTRY_SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE;
export const ENV = process.env.REACT_APP_ENV;

// Segement
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY;

// plans
export const INACTIVE_PLANS_OPTION = 'inactive';
export const ALL_PLANS_OPTION = 'all';
export const ACTIVE_PLANS_OPTION = 'active';

export const GRAPH_QL_ENDPOINT = process.env.REACT_APP_GRAPH_QL_ENDPOINT;
export const GRAPH_QL_API_KEY = process.env.REACT_APP_GRAPH_QL_API_KEY;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const CELLO_PRODUCT_ID = process.env.REACT_APP_CELLO_PRODUCT_ID;
