import { currentBusinessUpdated } from '@equally-ai-front/common/src/redux/business-slice/business';
import { Business } from '@equally-ai-front/common/src/types/business';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AriaLazy, Developers, Statement } from '../components';
import { Services } from '../components/redesign/Services';
import { WidgetContainer } from '../containers/widget-container/widget-container';
import useBusinessRedirect from '../hooks/use-business-redirect';
import { isUserPermitted } from '../lib/permissions';
import { BusinessSettingsRoutes } from './business-settings-container/business-settings-routes';
import { ComplianceManagerRedirect } from './compliance-manager-redirect';
import { BusinessFormContainer } from './on-boarding-container/on-boarding-container';
import { ProtectedRoute } from './protected-route';
import { PlansProvider } from '../providers/plans-context';
import { getHasActiveDevDomains } from '../utils/domain.utils';
import { RootState } from '../store';

interface BusinessRedirectProps {}

interface RoutesWithPermissionsConfig {
  condition: boolean;
  path: string;
  element: React.ReactNode;
}

export const BusinessRedirect = (props: BusinessRedirectProps) => {
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const domains = useSelector((state: RootState) => state.userProducts.domains);

  const { businessSlug } = useParams();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const businesses = useSelector((state: any) => state.business.businesses);
  const navigate = useNavigate();
  const [showBusinessForm, setShowBusinessForm] = useState(false);
  const dispatch = useDispatch();

  const closeBusinessFormModal = () => {
    setShowBusinessForm(false);
  };

  useBusinessRedirect({ currentBusiness, businessSlug, personalDetails });
  useEffect(() => {
    if (!businesses || businesses?.length === 0) {
      return;
    }
    let newSlug = null;
    if (currentBusiness) {
      newSlug = currentBusiness.slug;
    } else if (currentBusiness?.slug !== businessSlug || !currentBusiness) {
      const existingBusiness =
        businesses.find(
          (business: Business) => business.slug === businessSlug,
        ) || businesses[0];
      newSlug = existingBusiness.slug;
      dispatch(currentBusinessUpdated(existingBusiness));
    }

    if (newSlug && newSlug !== businessSlug) {
      navigate(`/${newSlug}`);
      console.log('settings brand slug app redirect');
    }
  }, [currentBusiness, businesses, dispatch, navigate, businessSlug]);

  // useEffect(() => {
  //   if (!businessSlug) {
  //     return;
  //   }
  //   const existingBusiness = businesses.find(
  //     (business: Business) => business.slug === businessSlug,
  //   );
  //
  //   dispatch(currentBusinessUpdated(existingBusiness.slug));
  // }, [businesses, businessSlug, dispatch]);

  const routesWithPermissions: RoutesWithPermissionsConfig[] = useMemo(() => {
    if (!domains || !currentBusiness || !personalDetails) return [];

    const hasActiveDevDomains = getHasActiveDevDomains(domains || []);
    return [
      {
        path: `developers/:domainId/flowy/*`,
        element: <Developers />,
        condition: isUserPermitted(
          currentBusiness?.id,
          ['view.flowy_page'],
          personalDetails,
        ),
      },
      {
        path: `compliance-manager/*`,
        element: <ComplianceManagerRedirect />,
        condition:
          isUserPermitted(
            currentBusiness?.id,
            ['view.compliance_page'],
            personalDetails,
          ) && hasActiveDevDomains,
      },
      {
        path: `settings/*`,
        element: <BusinessSettingsRoutes />,
        condition: isUserPermitted(
          currentBusiness?.id,
          ['view.business_settings'],
          personalDetails,
        ),
      },
    ];
  }, [currentBusiness, domains, personalDetails]);

  return (
    <PlansProvider businessId={currentBusiness?.id}>
      <Routes>
        <Route index element={<WidgetContainer />} />
        <Route path={`services`} element={<Services />} />
        <Route path={`statement`} element={<Statement />} />
        {/* eslint-disable-next-line react/prop-types */}
        <Route path={`audit`} element={<AriaLazy />} />
        <Route
          path={`on-boarding/*`}
          element={
            <ProtectedRoute>
              <BusinessFormContainer />
            </ProtectedRoute>
          }
        />
        {routesWithPermissions.map(({ condition, path, element }) => (
          <Route
            key={path}
            path={path}
            element={condition ? element : <Navigate to="/" replace />}
          />
        ))}
      </Routes>
    </PlansProvider>
  );
};
