import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { ScanSettingSteps } from '@equally-ai-front/common/src/types/audit-settings';
import { OutlinedInput } from '@mui/material';
import { Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled as MuiStyled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import React from 'react';
import styled from 'styled-components';
import {
  ArrowBox,
  ArrowIcon,
  Header,
  HeaderText,
  TitleAndIcon,
} from './scan-step';
import { Button, Input } from './scan-step-action';
import { ScanStepActions } from './scan-step-actions';

interface ScanStepContentProps {
  accordionId: string;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
  step: ScanSettingSteps;
  steps: ScanSettingSteps[];
  setSteps: React.Dispatch<React.SetStateAction<ScanSettingSteps[]>>;
  handleChange: (
    evt: React.ChangeEvent<HTMLInputElement>,
    id: string,
    prop: string,
  ) => void;
  handleRemoveStep: (stepId: string) => void;
  isAuthenticationStep: boolean;
}

export const ScanStepContent = ({
  accordionId,
  accordionState,
  handleAccordionToggle,
  step,
  steps,
  setSteps,
  handleChange,
  handleRemoveStep,
  isAuthenticationStep,
}: ScanStepContentProps) => {
  return (
    <>
      <AccessibilityAccordion
        title={step.stepName}
        accordionId={accordionId}
        accordionState={accordionState}
        handleToggleAccordion={() => handleAccordionToggle(accordionId)}
        accordionHeader={
          <Header>
            <TitleAndIcon>
              <ArrowBox>
                <ArrowIcon
                  src="/chevron-down-black.svg"
                  alt="arrow down icon"
                  shouldRotate={accordionState[accordionId]}
                />
              </ArrowBox>
              <Box ml={'12px'} />
              <HeaderText onClick={(evt) => evt.stopPropagation()}>
                {isAuthenticationStep ? (
                  step.stepName
                ) : (
                  <StepNameInput
                    name="value"
                    type="text"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(evt, step.id, 'stepName')
                    }
                    onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                      if (evt.code === 'Space') {
                        evt.preventDefault();
                        setSteps((prevSteps) =>
                          prevSteps.map((prevStep) =>
                            prevStep.id === step.id
                              ? {
                                  ...prevStep,
                                  stepName: prevStep.stepName + ' ',
                                }
                              : prevStep,
                          ),
                        );
                      }
                    }}
                    placeholder="Enter step name"
                    value={step.stepName}
                  />
                )}
              </HeaderText>
            </TitleAndIcon>
            <RemoveButton
              onClick={() => handleRemoveStep(step.id)}
              type="button"
            >
              Remove
            </RemoveButton>
          </Header>
        }
      >
        <FormWrapper>
          <FormControlLabel
            sx={{ marginLeft: 0 }}
            control={
              <MuiSwitch
                checked={step.is_mandatory}
                onChange={(evt) => handleChange(evt, step.id, '')}
                name="is_mandatory"
              />
            }
            label="Activate on all scans"
            labelPlacement="start"
          />
          {isAuthenticationStep && (
            <LoginInputWrapper>
              <LoginInputLabel>Login page url</LoginInputLabel>
              <LoginInput
                value={step.login_page_url}
                name="login page url"
                onChange={(evt) => handleChange(evt, step.id, 'login_page_url')}
              />
            </LoginInputWrapper>
          )}
        </FormWrapper>
        <ScanStepsWrapper>
          <ScanStepActions
            step={step}
            steps={steps}
            setSteps={setSteps}
            accordionState={accordionState}
            handleAccordionToggle={handleAccordionToggle}
          />
        </ScanStepsWrapper>
      </AccessibilityAccordion>
    </>
  );
};

const ScanStepsWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 20px 16px;
  border: 2px solid #e4e7eb;
  border-radius: 14px;
  padding: 5% 20px;
`;

const StepNameInput = MuiStyled(OutlinedInput)({
  '&.MuiOutlinedInput-root input': {
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: 15,
    fontWeight: 500,
  },
  '& fieldset': {
    border: 'none',
  },
  '&:hover fieldset': {
    border: 'none',
  },
});

const FormWrapper = styled.div`
  width: calc(100% - 32px);
  margin: -8px 16px 10px;
`;

const LoginInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const LoginInputLabel = styled.label`
  margin-right: 20px;
`;

const LoginInput = styled(Input)`
  margin-top: 0;
  height: 40px;
  width: 100%;
  max-width: 50%;
`;

const MuiSwitch = MuiStyled(Switch)({
  '& .MuiSwitch-track': {
    backgroundColor: '#a5a9ae',
  },
});

const AddNewStepButton = styled(Button)`
  margin: 25px 0 7%;
  display: block;
  padding: 10px 20px;
`;

const RemoveButton = styled(AddNewStepButton)`
  margin: 0 0 0 auto;
`;
