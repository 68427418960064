import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import styled from '@emotion/styled';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  CartAPI,
  CartStore,
  PlanTemplateItem,
} from '@equally-ai/cart-checkout';
import { useCart } from '../../../../../providers/cart-provider';

import PaypalButton from '../paypal-button';
import CartItem from '../cart-Item';

import {
  OutlinedButton,
  ContainedButton,
} from '../../../../../components/common/ActionButton';
import { useDispatch } from 'react-redux';
import {
  setApiError,
  setApiSuccessMessage,
} from '../../../../../store/actions';
import { GenericInput } from '../../../../../components/accessibility-components/generic-input';
import { PrimaryButton } from '../../../../../components/redesign/Controls';
import { ArrowBack } from '@mui/icons-material';
import { Business } from '@equally-ai-front/common/src/types/business';

export interface CheckoutState {
  total: number | null;
  discountAmount?: number;
  isCouponValid?: boolean;
  planID: string | null;
  checkoutID: number | null;
  isServiceOnly?: boolean;
  isLoading: boolean;
}

interface CheckoutModalProps {
  onClose: () => void;
  onComplete: () => void;
  currentBusiness: Business;
  domainId?: number;
}

const attachToCurrency = (amount: number) => {
  return `$${amount.toLocaleString()}`;
};

const getPlanTemplateQuantityOrQuantity = (
  planTemplate: PlanTemplateItem,
  quantity: number,
) => {
  return quantity >= planTemplate.domain_size
    ? quantity
    : planTemplate.domain_size;
};

const Summary = ({
  onClose,
  onComplete,
  currentBusiness,
  domainId,
}: CheckoutModalProps) => {
  const {
    store: { cart },
    actions,
  } = useCart();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [checkout, setCheckout] = useState<CheckoutState>({
    total: null,
    planID: null,
    checkoutID: null,
    isLoading: false,
  });
  const [coupon, setCoupon] = useState('');

  const handleCartCheckout = async () => {
    if (!cart) {
      return;
    }
    setCheckout({ ...checkout, isLoading: true });
    const deSerializeQueryParams = (str: URLSearchParams) => {
      return str.get('coupon_name') || undefined;
    };

    const queryString: string | undefined =
      deSerializeQueryParams(searchParams);
    try {
      const { data: checkoutCart } = await CartAPI.checkout(
        cart.id,
        queryString || '',
      );
      const isServicesOnly = (newCheckout: { cart?: CartStore }) => {
        return (
          newCheckout?.cart?.cart_items?.length === 1 &&
          newCheckout?.cart?.cart_items?.[0].plan_template.product_type ===
            'service'
        );
      };

      setCheckout({
        total: checkoutCart.total_price,
        discountAmount: checkoutCart.cart?.discount_amount,
        isCouponValid: checkoutCart.cart?.is_coupon_valid,
        planID: checkoutCart.plans[0].external_id,
        checkoutID: checkoutCart.id,
        isServiceOnly: isServicesOnly(checkoutCart),
        isLoading: false,
      });
    } catch (error) {
      setCheckout({ ...checkout, isLoading: false });
      dispatch(setApiError('Unable to Checkout, Try Again Later'));
    }
  };

  const handleExitCheckout = () => {
    if (checkout.planID) {
      actions.copyCartOnExitCheckout();
    }

    setCheckout({
      total: null,
      planID: null,
      checkoutID: null,
      isLoading: false,
    });

    setCoupon('');

    onClose();
  };

  const handleCouponApply = async () => {
    const { data, isSuccess } = await CartAPI.getAuthCart(coupon);
    if (!isSuccess) {
      return;
    }

    if (data?.is_coupon_valid) {
      dispatch(setApiSuccessMessage('Coupon applied successfully.'));
      actions.updateCartState(data);
      const url = new URL(window.location.href);
      url.searchParams.set('coupon_name', coupon);

      setSearchParams({ coupon_name: coupon });
    } else {
      dispatch(setApiError('Unable to apply coupon, Try Again Later'));
    }
  };

  const handleRemoveItem = (itemId: number) => {
    actions.removeFromCart(itemId);
    if ((cart?.cart_items || []).length <= 1) {
      onClose();
    }
  };
  return (
    <ContentWrapper>
      <PlansButton onClick={handleExitCheckout} color="primary">
        <ArrowBack style={{ marginRight: '5px' }} />
        <span>Plans</span>
      </PlansButton>
      <ModalContent>
        {cart?.cart_items.map((item) => (
          <CartItem
            quantity={getPlanTemplateQuantityOrQuantity(
              item.plan_template,
              item.quantity,
            )}
            trialDuration={item.plan_template.trial_duration}
            trialDurationUnit={item.plan_template.trial_duration_unit}
            unit={item.plan_template.duration_unit}
            total={item.plan_template.price}
            title={item.plan_template.product_type}
            name={item.plan_template.name}
            key={item.id}
            itemId={item.id}
            removeItem={() => handleRemoveItem(item.id)}
            displayRemoveButton
          />
        ))}
      </ModalContent>
      <Box mt="32px">
        <PricesListContainer>
          {cart?.discount_amount || checkout.discountAmount ? (
            <TotalPriceContainer>
              <Typography style={{ opacity: 0.6 }} variant={'body1'}>
                Discount
              </Typography>
              <Price>
                <DiscountTag
                  discountText={attachToCurrency(
                    cart?.discount_amount || checkout.discountAmount || 0,
                  ).toLocaleString()}
                />
              </Price>
            </TotalPriceContainer>
          ) : undefined}
          <Spacer />
          <TotalPriceContainer>
            <Typography variant={'body1'} style={{ fontSize: '20px' }}>
              Total
            </Typography>
            <Price>${checkout.total ?? cart?.total_price} </Price>
          </TotalPriceContainer>
        </PricesListContainer>
        {!checkout.planID && (
          <>
            <CouponContainer>
              <GenericInput
                ariaProps={{ 'aria-label': 'Enter coupon code' }}
                label="Enter Coupon Code"
                id="coupon"
                type="text"
                value={coupon}
                variant="outlined"
                onChange={(e) => setCoupon(e.target.value)}
              />

              <OutlinedButton
                onClick={handleCouponApply}
                disabled={!coupon}
                style={{ marginLeft: '10px', textTransform: 'initial' }}
              >
                Apply Coupon
              </OutlinedButton>
            </CouponContainer>

            <ContainedButton
              onClick={handleCartCheckout}
              fullWidth
              style={{ textTransform: 'initial' }}
            >
              Select a payment method
            </ContainedButton>
          </>
        )}

        {checkout.planID && (
          <PaypalButton
            closeModal={onComplete}
            planId={checkout.planID}
            domainId={domainId}
            checkout={checkout}
            businessId={currentBusiness.id}
          />
        )}
      </Box>
      {checkout.isLoading && (
        <SpinnerContainer>
          <CircularProgress />
        </SpinnerContainer>
      )}
    </ContentWrapper>
  );
};

const CouponContainer = styled.div`
  border-top: 1px solid #aaa;
  padding-top: 20px;
  margin-bottom: 20px;
  display: flex;

  & > div {
    width: 70%;
    margin-right: auto;
  }
`;

const PlansButton = styled(PrimaryButton)`
  display: flex;
  justify-content: flex-start;
  width: max-content;
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  max-height: 95vh;
  min-height: 500px;
  padding: 0 48px;
  display: flex;
  flex-direction: column;
  min-width: 45%;

  @media screen and (max-width: 600px) {
    padding: 0 12px;
    min-height: 100%;
  }
`;

const TotalPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Spacer = styled.div`
  margin: 1vh 0;
`;
const PricesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  z-index: 20000;
  background-color: rgba(225, 225, 225, 0.6);
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Price = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 90%;

  & > sub {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: line-through;

    vertical-align: bottom;
  }
`;

const ModalContent = styled.div`
  color: rgba(0, 10, 20, 0.85);

  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  font-size: 16px;

  margin-top: 22px;
`;

const DiscountContainer = styled.div``;

interface DiscountTagProps {
  discountText: string;
}

// eslint-disable-next-line react/prop-types
const DiscountTag = ({ discountText }: DiscountTagProps) => (
  <DiscountContainer>
    <Typography variant="body1">-{discountText}</Typography>
  </DiscountContainer>
);

export default Summary;
