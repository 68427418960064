import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const TabInfo = ({ title, description }) => {
  return (
    <div style={{ padding: '24px 0 0 32px' }}>
      <Typography variant="h4" component="h2">
        {title}
      </Typography>
      <Typography variant="h6" component="p">
        {description}
      </Typography>
    </div>
  );
};

TabInfo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
