import reduce from 'lodash/reduce';
import unionBy from 'lodash/unionBy';
import * as Yup from 'yup';

import {
  AUDIT_STATUS_ERROR,
  AUDIT_STATUS_SUCCESS,
  AUDIT_STATUS_WARNING,
} from '../contants';

import { getURLDetails } from './utils';

export const getColorForAuditStatus = (status, theme) => {
  switch (status) {
    case AUDIT_STATUS_SUCCESS:
      return theme.palette.status.success;
    case AUDIT_STATUS_WARNING:
      return theme.palette.status.warning;
    case AUDIT_STATUS_ERROR:
      return theme.palette.status.error;
    default:
      return theme.palette.shades.shade100;
  }
};

export const buildAuditHistoryList = (urStatus) => {
  const reducedHistory = reduce(
    urStatus,
    (result, status, url) => {
      const resultRef = result;
      const details = getURLDetails(url);
      const group = resultRef[details.hostname] ?? [];
      group.push({
        key: url,
        path: details.pathname,
        status: status,
        url: url,
      });
      resultRef[details.hostname] = group;
      return resultRef;
    },
    {},
  );

  const domainsList = Object.keys(reducedHistory);

  const historyObject = reduce(
    domainsList,
    (result, key) => {
      const resultRef = result;
      const pathGroup = reducedHistory[key] ?? [];

      resultRef[key] = unionBy(pathGroup, 'path');
      return resultRef;
    },
    {},
  );

  return historyObject;
};

export * from './utils';

export const HTTP_REGEX = /^https?:\/\/.*$/i;

export const URLValidator = Yup.string()
  .trim()
  .matches(HTTP_REGEX, 'Website URL should start with http(s)://')
  .url('Enter valid website URL');

export const isValidURL = (url) => {
  return URLValidator.isValidSync(url);
};
