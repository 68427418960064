import { AccordionState } from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { ScanSettingSteps } from '@equally-ai-front/common/src/types/audit-settings';
import React from 'react';
import styled from 'styled-components';

import { ScanStepAction } from './scan-step-action';

interface ScanStepActionsProps {
  step: ScanSettingSteps;
  steps: ScanSettingSteps[];
  setSteps: React.Dispatch<React.SetStateAction<ScanSettingSteps[]>>;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
}

export const ScanStepActions = ({
  steps,
  step,
  setSteps,
  accordionState,
  handleAccordionToggle,
}: ScanStepActionsProps) => {
  const handleAddAnotherAction = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps].map((prevStep) => {
        if (prevStep.id === step.id) {
          return {
            ...prevStep,
            actions: [
              ...prevStep.actions,
              {
                operation: '',
                type: '',
                key: '',
                value: '',
              },
            ],
          };
        }

        return prevStep;
      });
      return updatedSteps;
    });
  };

  return (
    <Wrapper onSubmit={handleAddAnotherAction}>
      {step.actions.map((stepAction, idx) => (
        <ScanStepAction
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          index={idx}
          step={step}
          action={stepAction}
          setSteps={setSteps}
          accordionState={accordionState}
          handleAccordionToggle={handleAccordionToggle}
        />
      ))}
      <Button aria-label="Add another scan step action" type="submit">
        Add another action
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.form``;

const Button = styled.button`
  border: 2px solid #cbd2d9;
  font-weight: 500;
  font-size: 14px;
  color: #000a14;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
`;
