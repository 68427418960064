import React, { useContext, useState, createContext, useMemo } from 'react';
import {
  SortOption,
  usePagination,
} from '@equally-ai-front/common/src/hooks/use-pagination';
import { getDaysLeft } from '../lib/date';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

type DomainGroup = 'all' | 'archived';

interface WebsitesTableContextType {
  visibleDomains: any[];
  handlePageChange: (selectedPage: { selected: number }) => void;
  pageCount: number;
  currentPage: number;
  searchTerm: string;
  handleSearch: (query: string) => void;
  handleSort: (key: string) => void;
  sortOptions: SortOption;
  domainGroup: DomainGroup;
  setDomainGroup: React.Dispatch<React.SetStateAction<DomainGroup>>;
}

const WebsitesTableContext = createContext<WebsitesTableContextType>({
  visibleDomains: [],
  pageCount: 0,
  currentPage: 0,
  searchTerm: '',
  sortOptions: {},
  handlePageChange: ({ selected }) => {},
  handleSearch: (query) => {},
  handleSort: (key) => {},
  domainGroup: 'all',
  setDomainGroup: () => {},
});

interface WebsitesTableProviderProps {
  children: React.ReactNode;
}

const DOMAINS_PER_PAGE = 20;
export const WebsitesTableProvider = ({
  children,
}: WebsitesTableProviderProps) => {
  const domains = useSelector((state: RootState) => state.userProducts.domains);

  const [domainGroup, setDomainGroup] = useState<DomainGroup>('all');

  const allDomains = useMemo(() => {
    const domainsWithTimeLeft = (domains || []).map((domain: any) => ({
      ...domain,
      time_left: getDaysLeft(
        domain.domain?.expiration || domain.dev_domain?.expiration,
      ),
    }));

    const visibleDomainsInitial: any[] = [...domainsWithTimeLeft].sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    const filterArchieved = domainGroup === 'archived';

    if (!filterArchieved) {
      return visibleDomainsInitial.filter(
        (domain) => !domain.domain?.archived && !domain.dev_domain?.archived,
      );
    }

    const archievedDomains = visibleDomainsInitial.filter(
      (domain) => domain.domain?.archived || domain.dev_domain?.archived,
    );

    if (archievedDomains.length === 0) {
      setDomainGroup('all');
    }

    return archievedDomains;
  }, [domains, domainGroup]);

  const {
    pageCount,
    currentData: visibleDomains,
    handlePageChange,
    currentPage,
    searchTerm,
    handleSearch,
    handleSort,
    sortOptions,
  } = usePagination(allDomains, DOMAINS_PER_PAGE, 'name');

  return (
    <WebsitesTableContext.Provider
      value={{
        visibleDomains,
        pageCount,
        handlePageChange,
        currentPage,
        searchTerm,
        handleSearch,
        handleSort,
        sortOptions,
        domainGroup,
        setDomainGroup,
      }}
    >
      {children}
    </WebsitesTableContext.Provider>
  );
};

export const useWebsitesTable = () => {
  const context = useContext(WebsitesTableContext);
  if (context === undefined) {
    throw new Error(
      'useWebsitesTable must be used within a WebsitesTableProvider',
    );
  }
  return context;
};
