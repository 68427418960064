import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdminWidgetConfigType,
  generateAdminDomainConfigRoute,
  LandmarkConfig,
  TabIndexConfig,
  WidgetConfigurations,
} from '../../helpers';
import { updateHostTempScreenState } from './utils';
import { useInjectReducer } from 'redux-injectors';
import { RootState } from '../RootState';
import { AdminWidgetConfigAPI } from '../../api/admin-widget-config';
import DomainsAPI from '../../api/domains';
import { generateConfigTemplate, getEmptyConfig } from '../../lib';

export interface SendComment {
  selector: string;
  comment: string;
  severity?: string;
}

export interface HostTempScreenState {
  selector: string;
  tagName: string;
  attributes: Record<WidgetConfigurations, Record<string, any>>;
}

export interface BulkEditModeState {
  hostScreenState: Record<string, HostTempScreenState>;
  hostTempScreenState: Record<string, HostTempScreenState>;
  sendComments: SendComment[];
  selectedSelectors: Record<string, boolean>;
  isLoading: boolean;
  isDraftAppeardInMain: boolean;
  isDraftAppeardInEditor: boolean;
  drafts: Record<string, HostTempScreenState>;
}

export const InitialState = {
  hostScreenState: {},
  hostTempScreenState: {},
  sendComments: [],
  selectedSelectors: {},
  isLoading: false,
  isDraftAppeardInMain: false,
  isDraftAppeardInEditor: false,
  drafts: {},
};
export const selectScreen = (state: RootState) => state?.screen || InitialState;

interface UpdateConfigAttribute {
  isSuccess: boolean;
  duplicate: any;
}

export const publishConfig = createAsyncThunk(
  'screen/publishConfig',
  async (
    actions: { domainID: string; isAdmin: boolean },
    { getState, rejectWithValue },
  ) => {
    const { domainID, isAdmin } = actions;

    const state: any = getState();
    const { hostScreenState, hostTempScreenState, selectedSelectors } =
      state.screen;
    const newHostScreenState = { ...hostScreenState };
    const newTempHostScreenState = { ...hostTempScreenState };
    const allElements: HostTempScreenState[] = [];
    const possibleElements = Array.from(
      new Set([
        ...Object.values(hostScreenState),
        ...Object.values(hostTempScreenState),
      ]),
    );

    possibleElements.forEach((element: any) => {
      if (selectedSelectors[element.selector]) {
        allElements.push(element);
      }
    });
    const config = getEmptyConfig();

    for (const element of allElements) {
      Object.keys(element.attributes).map((configType) => {
        const widgetConfigType = configType as WidgetConfigurations;
        config[widgetConfigType] = [
          ...config[widgetConfigType],
          ...generateConfigTemplate(
            element.attributes[widgetConfigType],
            widgetConfigType,
            element.selector,
          ),
        ];
      });
      newHostScreenState[element.selector] = element;
      delete newTempHostScreenState[element.selector];
    }

    const checkDuplicate = (configData: any[], newConfig: any[]) => {
      for (let i = 0; i < newConfig.length; i++) {
        for (let j = 0; j < configData.length; j++) {
          if (
            newConfig[i].selector === configData[j].selector &&
            newConfig[i].attribute === configData[j].attribute
          ) {
            return newConfig[i];
          }
        }
      }
      return null;
    };

    const updateAttribute = async (
      domainID: string,
      attribute: AdminWidgetConfigType,
      newConfig: TabIndexConfig[] | LandmarkConfig[] | any[],
    ): Promise<UpdateConfigAttribute> => {
      let configData: any[] = [];
      const endpoint = generateAdminDomainConfigRoute(domainID, attribute);

      let response = isAdmin
        ? await AdminWidgetConfigAPI.getConfig(endpoint)
        : await DomainsAPI.getConfig(endpoint);

      if (!response.isSuccess) {
        return {
          isSuccess: false,
          duplicate: null,
        };
      }

      configData = response?.data || [];

      const duplicateFound = checkDuplicate(configData, newConfig);
      if (duplicateFound) {
        return {
          isSuccess: false,
          duplicate: duplicateFound,
        };
      }

      configData = [...configData, ...newConfig];

      const { isSuccess } = isAdmin
        ? await AdminWidgetConfigAPI.saveConfig(endpoint, configData)
        : await DomainsAPI.saveConfig(endpoint, configData);

      return {
        isSuccess,
        duplicate: duplicateFound,
      };
    };

    const updateConfig = async (): Promise<UpdateConfigAttribute> => {
      for (const configType of Object.keys(config)) {
        if (config[configType as WidgetConfigurations].length > 0) {
          const { isSuccess, duplicate } = await updateAttribute(
            domainID.toString() || '',
            configType as WidgetConfigurations,
            config[configType as WidgetConfigurations],
          );
          if (!isSuccess) {
            return { isSuccess, duplicate };
          }
        }
      }
      return { isSuccess: true, duplicate: null };
    };
    const { isSuccess, duplicate } = await updateConfig();
    if (isSuccess) {
      return {
        hostScreenState: newHostScreenState,
        hostTempScreenState: newTempHostScreenState,
        isSuccess,
        duplicate,
      };
    } else {
      return rejectWithValue({
        isSuccess,
        duplicate,
      });
    }
  },
);
const screenSlice = createSlice({
  name: 'screen',
  initialState: InitialState,
  extraReducers: {
    [publishConfig.pending.type]: (state) => {
      state.isLoading = true;
    },
    [publishConfig.fulfilled.type]: (state, action) => {
      state.hostScreenState = action.payload.hostScreenState;
      state.hostTempScreenState = action.payload.hostTempScreenState;
      state.isLoading = false;
    },
    [publishConfig.rejected.type]: (state, action) => {
      state.isLoading = false;
    },
  },
  reducers: {
    selectedSelectorsUpdated: (
      state: BulkEditModeState,
      action: PayloadAction<Record<string, boolean>>,
    ) => {
      state.selectedSelectors = action.payload;
    },
    screenStateUpdated: (
      state,
      action: PayloadAction<Record<string, HostTempScreenState>>,
    ) => {
      state.hostScreenState = {
        ...state.hostScreenState,
        ...action.payload,
      };
    },
    tempScreenStateUpdated: (
      state: BulkEditModeState,
      action: PayloadAction<Record<string, HostTempScreenState>>,
    ) => {
      const newState = updateHostTempScreenState(
        state.hostTempScreenState,
        action.payload,
      );

      const newSelectedSelectors: Record<string, boolean> = {
        ...state.selectedSelectors,
      };
      for (const selectorConfig of Object.values(newState)) {
        if (state.selectedSelectors[selectorConfig.selector]) {
          continue;
        }

        newSelectedSelectors[selectorConfig.selector] = true;
      }
      state.hostTempScreenState = newState;
      state.selectedSelectors = newSelectedSelectors;
    },
    overrideTempScreenState: (
      state,
      action: PayloadAction<Record<string, HostTempScreenState>>,
    ) => {
      state.hostTempScreenState = action.payload;
      state.selectedSelectors = {};
    },
    updateTempScreenStateAfterAttributeDelete: (
      state,
      action: PayloadAction<Record<string, HostTempScreenState>>,
    ) => {
      state.hostTempScreenState = action.payload;
    },
    sendComment: (state, action: PayloadAction<SendComment>) => {
      // @ts-ignore
      state.sendComments.push(action.payload);
    },
    setDraftAppeardInEditor: (state, action: PayloadAction<boolean>) => {
      state.isDraftAppeardInEditor = action.payload;
    },
    setDraftAppeardInMain: (state, action: PayloadAction<boolean>) => {
      state.isDraftAppeardInMain = action.payload;
    },
  },
});

export const {
  selectedSelectorsUpdated,
  screenStateUpdated,
  tempScreenStateUpdated,
  overrideTempScreenState,
  sendComment,
  setDraftAppeardInEditor,
  setDraftAppeardInMain,
  updateTempScreenStateAfterAttributeDelete,
} = screenSlice.actions;

// export default screenSlice.reducer;
export const useScreenSlice = () => {
  useInjectReducer({ key: screenSlice.name, reducer: screenSlice.reducer });
  return {
    publishConfig,
    selectedSelectorsUpdated,
    screenStateUpdated,
    tempScreenStateUpdated,
    overrideTempScreenState,
    updateTempScreenStateAfterAttributeDelete,
    sendComment,
    selectScreen,
    setDraftAppeardInEditor,
    setDraftAppeardInMain,
  };
};
export default screenSlice.reducer;
