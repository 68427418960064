import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';
import { ChromeExtensionState } from './types';

export const initialState: ChromeExtensionState = {
  port: undefined,
  tab: undefined,
};

const ChromeSlice = createSlice({
  name: 'chrome',
  initialState,
  reducers: {
    setChromePort(state, action: PayloadAction<ChromeExtensionState>) {
      state.port = action.payload.port;
    },
    setChromeTab(state, action: PayloadAction<ChromeExtensionState>) {
      state.tab = action.payload.tab;
    },
  },
});

export const { actions: chromeActions } = ChromeSlice;
export const useChromeSlice = () => {
  useInjectReducer({ key: ChromeSlice.name, reducer: ChromeSlice.reducer });
  return { actions: ChromeSlice.actions };
};
