import React, { useCallback } from 'react';
import { Box, Input } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import {
  ActionButton,
  StyledLink,
  Wrapper,
  PageContent,
  Heading,
} from './styled';

import { GenericInput } from '../../../components/accessibility-components/generic-input';
import { resetPassowordFormValidationSchema } from '../../../lib/validators';
import { useAuth } from '../../../providers/auth-context';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

const EMAIL_INITIAL_VALUE = {
  email: '',
};
const PASSWORD_INITIAL_VALUE = {
  newPasswordVerification: '',
  newPassword: '',
};

type ResetPasswordEmailFormValues = {
  email: string;
};
type ResetPasswordFormValues = {
  newPasswordVerification: string;
  newPassword: string;
};

interface ResetPasswordProps {
  onEmailSubmit: (email: string) => void;
}

export const ResetPassword = (props: ResetPasswordProps) => {
  const { onEmailSubmit } = props;
  const { handleSubmitForgetPassword, initiateForgotPassword } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const navigate = useNavigate();
  const [code, setCode] = React.useState('');
  const onSubmitEmail = useCallback(
    async (
      values: ResetPasswordEmailFormValues,
      { setSubmitting, resetForm }: FormikHelpers<ResetPasswordEmailFormValues>,
    ) => {
      const userEmail = values.email.toLowerCase();
      await initiateForgotPassword(userEmail, () => onEmailSubmit(userEmail));
      setSubmitting(false);
      resetForm();
    },
    [initiateForgotPassword],
  );
  const onSubmitPasswords = useCallback(
    async (
      values: ResetPasswordFormValues,
      { setSubmitting, resetForm }: FormikHelpers<ResetPasswordFormValues>,
    ) => {
      if (!code || !email) {
        return;
      }
      await handleSubmitForgetPassword(email, code, values.newPassword);
      setSubmitting(false);
      resetForm();
    },
    [handleSubmitForgetPassword, email, code],
  );
  const handleCodeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newCode = e.target.value;
    setCode(newCode);

    if (!email || newCode.length !== 6) {
      return;
    }
    navigate({
      pathname: 'submit',
      search: `?email=${encodeURIComponent(email)}`,
    });
  };

  const formikEmail = useFormik({
    initialValues: EMAIL_INITIAL_VALUE,
    validationSchema: resetPassowordFormValidationSchema,
    onSubmit: onSubmitEmail,
  });
  const formikPassword = useFormik({
    initialValues: PASSWORD_INITIAL_VALUE,
    validationSchema: resetPassowordFormValidationSchema,
    onSubmit: onSubmitPasswords,
  });

  return (
    <Wrapper>
      <PageContent>
        <Heading>Reset Your Password</Heading>
        <p>If you signed up with Google, you can&#39;t reset your password.</p>

        <Box mt="10px" />
        <Routes>
          <Route
            index
            element={
              <>
                <p>Enter your email to get verfication code</p>
                <form>
                  <GenericInput
                    required
                    aria-required
                    InputLabelProps={{ shrink: true }}
                    ariaProps={{}}
                    id="resetPassword"
                    name="email"
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    value={formikEmail.values.email}
                    onChange={formikEmail.handleChange}
                    error={
                      formikEmail.touched.email &&
                      Boolean(formikEmail.errors.email)
                    }
                    helperText={
                      formikEmail.touched.email
                        ? formikEmail.errors.email
                        : undefined
                    }
                    sx={{
                      '& input': {
                        textTransform: 'lowercase',
                      },
                    }}
                  />
                </form>
                <Box mt="10px" />
                <StyledLink to="/login">Back to Login</StyledLink>
                <Box mt="50px" />
                <ActionButton
                  aria-label="Send email"
                  onClick={formikEmail.submitForm}
                  disabled={formikEmail.isSubmitting}
                  fullWidth
                >
                  Send email
                </ActionButton>
              </>
            }
          />
          <Route
            path={'verify'}
            element={
              <GenericInput
                required
                aria-required
                InputLabelProps={{ shrink: true }}
                ariaProps={{}}
                id="restPasswordVerificationCode"
                name="verification-code"
                label="Verification code"
                type="text"
                variant="outlined"
                fullWidth
                value={code}
                onChange={handleCodeChange}
              />
            }
          ></Route>
          <Route
            path={'submit'}
            element={
              <>
                <form>
                  <GenericInput
                    required
                    aria-required
                    InputLabelProps={{ shrink: true }}
                    ariaProps={{}}
                    id="new_resetPassword"
                    name="newPassword"
                    label="New password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={formikPassword.values.newPassword}
                    onChange={formikPassword.handleChange}
                    error={
                      formikPassword.touched.newPassword &&
                      Boolean(formikPassword.errors.newPassword)
                    }
                    helperText={
                      formikPassword.touched.newPassword
                        ? formikPassword.errors.newPassword
                        : undefined
                    }
                  />
                  <Box mt="10px" />
                  <GenericInput
                    required
                    aria-required
                    InputLabelProps={{ shrink: true }}
                    ariaProps={{}}
                    id="newPasswordVerification"
                    name="newPasswordVerification"
                    label="Password Verification"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={formikPassword.values.newPasswordVerification}
                    onChange={formikPassword.handleChange}
                    error={
                      formikPassword.touched.newPasswordVerification &&
                      Boolean(formikPassword.errors.newPasswordVerification)
                    }
                    helperText={
                      formikPassword.touched.newPasswordVerification
                        ? formikPassword.errors.newPasswordVerification
                        : undefined
                    }
                  />
                </form>
                <Box mt="10px" />
                <StyledLink to="/login">Back to Login</StyledLink>
                <Box mt="50px" />
                <ActionButton
                  aria-label="Reset Password"
                  onClick={formikPassword.submitForm}
                  disabled={formikPassword.isSubmitting}
                  fullWidth
                >
                  Reset Password
                </ActionButton>
              </>
            }
          />
        </Routes>
      </PageContent>
    </Wrapper>
  );
};

export default ResetPassword;
