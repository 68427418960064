import styled from '@emotion/styled';
import React, { useCallback, useRef } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { AuditForm } from './audit-form';

interface AuditInputFormProps {
  label: string;
  onAudit: (url: string) => Promise<void>;
  loading: boolean;
  handleAuditUrlChange: (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  auditUrl: string;
}

export const AuditInputForm = (props: AuditInputFormProps) => {
  const { label, onAudit, loading, handleAuditUrlChange, auditUrl } = props;
  const inputRef: any = useRef();

  const onClick = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.submitForm();
  }, []);

  return (
    <AuditForm
      ref={inputRef}
      label={label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchButton
              type="submit"
              aria-label="start webpage audit"
              onClick={onClick}
              edge="end"
            >
              <SearchIcon />
            </SearchButton>
          </InputAdornment>
        ),
      }}
      lightTheme
      onAudit={onAudit}
      loading={loading}
      handleAuditUrlChange={handleAuditUrlChange}
      auditUrl={auditUrl}
    />
  );
};

const SearchButton = styled(IconButton)`
  background-color: #e4e7eb;
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  margin-right: 0;
  right: 12px;
`;
