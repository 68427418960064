import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import DomainsAPI from '../../api/domains';
import ServicesAPI from '../../api/services';
import { UserProductsState } from './types';

export const initialState: UserProductsState = {
  loading: false,
  error: '',
  message: '',
  services: [],
  domains: undefined,
  devDomains: [],
};
export const getServices = createAsyncThunk(
  `userProducts/getServices`,
  async (actions: any, { rejectWithValue }) => {
    const { types, planIds } = actions;
    const { error, data, isSuccess } = await ServicesAPI.getServices(
      types,
      planIds,
    );
    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);
export const getWidgetDomains = createAsyncThunk(
  `userProducts/getWidgetDomains`,
  async (actions: any, { rejectWithValue }) => {
    const { businessId } = actions;
    const { error, data, isSuccess } =
      await DomainsAPI.getBusinessDomains(businessId);
    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);
export const getFlowyDomains = createAsyncThunk(
  `userProducts/getFlowyDomains`,
  async (actions: any, { rejectWithValue }) => {
    const { types, planIds } = actions;
    const { error, data, isSuccess } = await ServicesAPI.getServices(
      types,
      planIds,
    );
    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);

const slice = createSlice({
  name: 'userProducts',
  initialState,
  reducers: {
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },

    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getServices.pending.type]: (state) => {
      state.loading = true;
    },
    [getServices.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.services = action.payload;
    },
    [getServices.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getWidgetDomains.pending.type]: (state) => {
      state.loading = true;
    },
    [getWidgetDomains.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.domains = action.payload;
    },
    [getWidgetDomains.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getFlowyDomains.pending.type]: (state) => {
      state.loading = true;
    },
    [getFlowyDomains.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.devDomains = action.payload;
    },
    [getFlowyDomains.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { setMessage, setError, setLoading } = slice.actions;
export default slice.reducer;
