import { generateUniqueId } from '@equally-ai-front/common/src/lib';
import {
  getAuditSettings,
  saveAuditSettings,
  setAuditSettings,
} from '@equally-ai-front/common/src/redux/audit-settings-slice/audit-settings';
import {
  AuditSettingsPayload,
  AuditSettingsSteps,
  ScanSettingStepAction,
  ScanSettingSteps,
} from '@equally-ai-front/common/src/types/audit-settings';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { Box } from '@mui/material';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  FormContainer,
  FormContent,
  InfoContent,
  MainHeader,
  MainHeadersContainer,
} from '../../components/flowy-components/components/common';
import { elementComponentTypes } from '../../components/flowy-components/constants';
import { PrimaryButton } from '../../components/redesign/Controls';
import { ScanSteps } from '../../components/scan-steps/scan-steps';
import { setApiError, setApiSuccessMessage } from '../../store/actions';

interface ScanStepsContainerProps {
  devDomain: DevDomain;
}

const defaultScanStep: ScanSettingSteps = {
  stepName: '',
  actions: [
    {
      operation: '',
      type: '',
      key: '',
      value: '',
      error: '',
    },
  ],
  is_mandatory: false,
  id: '',
};

const authenticationScanStep: ScanSettingSteps = {
  stepName: 'Authentication',
  actions: [
    {
      operation: '',
      type: '',
      key: '',
      value: '',
      error: '',
    },
  ],
  is_mandatory: false,
  id: '',
  login_page_url: '',
};

const isValidCSSSelector = (selector: string) => {
  try {
    document.querySelector(selector);
    return true;
  } catch (e) {
    return false;
  }
};

const validateStepActionSelector = (actions: ScanSettingStepAction[]) => {
  for (const action of actions) {
    const isValidSelector = isValidCSSSelector(action?.key || '');

    if (!isValidSelector && elementComponentTypes.includes(action.type)) {
      action.error = 'Invalid css selector';
      return false;
    }

    if (!elementComponentTypes.includes(action.type)) {
      action.error = '';
    }
  }
  return true;
};

const validateSteps = (
  steps: ScanSettingSteps[],
): { isAllValid: boolean; updatedData: ScanSettingSteps[] } => {
  let allValid = true;

  for (const step of steps) {
    const validationResult = validateStepActionSelector(step.actions);
    allValid = allValid && validationResult;
  }

  return { isAllValid: allValid, updatedData: steps };
};

const formatStepsActionsToSaveAuditSettings = (
  steps: ScanSettingSteps[],
): AuditSettingsSteps => {
  const formattedSteps: AuditSettingsSteps = {};

  steps.forEach((step) => {
    formattedSteps[step.stepName] = {
      actions: step.actions.map((action) => ({
        operation: action.operation.split('-')[0],
        type: action.type,
        key: action.key,
        value: action.value,
      })),
      is_mandatory: step.is_mandatory,
    };

    if (step.login_page_url) {
      formattedSteps[step.stepName].login_page_url = step.login_page_url;
    }
  });

  return formattedSteps;
};

const formatIncomingActionsFromAuditSettings = (
  data: AuditSettingsSteps,
): ScanSettingSteps[] => {
  const auditSettingsSteps: ScanSettingSteps[] = Object.entries(data).map(
    ([stepName, details]) => ({
      ...details,
      stepName,
      id: generateUniqueId(),
      actions: details.actions.map((stepAction: ScanSettingStepAction) => {
        const { operation, type, value } = stepAction;
        let formattedOperation = operation;

        if (operation === 'wait' && type === 'url') {
          formattedOperation = 'wait-for-url-to-be';
        }

        if (operation === 'wait' && elementComponentTypes.includes(type)) {
          formattedOperation = 'wait-for-element';
        }

        if (operation === 'navigate') {
          formattedOperation = 'navigate-to';
        }

        if (operation === 'set') {
          formattedOperation = 'set-field';
        }

        if (operation === 'click' && elementComponentTypes.includes(type)) {
          formattedOperation = 'click-an-element';
        }

        if (operation === 'click' && type === 'checkbox' && value === 'true') {
          formattedOperation = 'click-checkbox-check';
        }

        if (operation === 'click' && type === 'checkbox' && value === 'false') {
          formattedOperation = 'click-checkbox-uncheck';
        }

        return {
          ...stepAction,
          operation: formattedOperation,
          error: '',
        };
      }),
    }),
  );

  return auditSettingsSteps;
};

export type ScanStepsRefs = Record<string, HTMLDivElement | null>;

export const ScanStepsContainer = (props: ScanStepsContainerProps) => {
  const { devDomain } = props;
  const dispatch = useDispatch();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const { auditSettings, loading } = useSelector((state: any) => ({
    auditSettings: state.auditSettings.auditSettings,
    loading: state.auditSettings.loading,
  }));
  const [steps, setSteps] = useState<ScanSettingSteps[]>([]);
  const [isAuthStepsModified, setIsAuthStepsModified] = useState(false);
  const [isAppStepsModified, setIsAppStepsModified] = useState(false);
  const [scanStepSaved, setScanStepSaved] = useState(false);

  const initialStepsData: Record<'authSteps' | 'appSteps', ScanSettingSteps[]> =
    useMemo(() => {
      const defaultStepsData = {
        authSteps: [],
        appSteps: [],
      };

      if (!loading && !auditSettings) {
        return defaultStepsData;
      }

      if (!loading && auditSettings.steps) {
        const formattedAuditSettingsSteps =
          formatIncomingActionsFromAuditSettings(auditSettings.steps);
        setSteps(formattedAuditSettingsSteps);
        const authSteps = formattedAuditSettingsSteps.filter(
          (step) => step.stepName === 'Authentication',
        );
        const appSteps = formattedAuditSettingsSteps.filter(
          (step) => step.stepName !== 'Authentication',
        );
        return {
          authSteps,
          appSteps,
        };
      }

      return defaultStepsData;
    }, [auditSettings, loading]);

  const handleAddNewStep = (
    isAuthenticationStep: boolean,
    handleAccordionToggle: (accordionId: string) => void,
  ) => {
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      const id = generateUniqueId();

      if (isAuthenticationStep) {
        updatedSteps.unshift({
          ...authenticationScanStep,
          id,
        });
      } else {
        updatedSteps.push({ ...defaultScanStep, id });
      }

      handleAccordionToggle(id);
      return updatedSteps;
    });
  };

  const saveScanActions = async () => {
    const { isAllValid, updatedData } = validateSteps(steps);

    if (!isAllValid) {
      dispatch(
        setApiError(
          'You have some invalid selectors please check again and retry',
        ),
      );
      setSteps(updatedData);
      return;
    }

    const formattedSteps = formatStepsActionsToSaveAuditSettings(steps);
    const payloadData: AuditSettingsPayload = {
      user_id: personalDetails.id,
      website_id: devDomain.id,
      url: `https://${devDomain.name}`,
      steps: formattedSteps,
      user_agents: '',
      platform: '',
    };

    if (auditSettings) {
      payloadData.id = auditSettings.id;
    }

    const {
      payload: { isSuccess },
    } = await dispatch(
      // @ts-ignore
      saveAuditSettings({
        businessId: currentBusiness.id,
        payloadData,
      }),
    );

    if (isSuccess) {
      dispatch(setApiSuccessMessage('Audit settings saved successfully'));
      setScanStepSaved(true);
    } else {
      dispatch(setApiError("Couldn't save audit settings"));
    }
  };

  const fetchAuditSettings = useCallback(async () => {
    const {
      payload: { isSuccess },
    } = await dispatch(
      // @ts-ignore
      getAuditSettings({
        businessId: currentBusiness.id,
        websiteId: devDomain.id,
      }),
    );

    if (!isSuccess) {
      dispatch(setApiError("Couldn't fetch audit settings"));
    }
  }, [currentBusiness, devDomain, dispatch]);

  const allSteps = useMemo(() => {
    const authSteps = steps.filter(
      (step) => step.stepName === 'Authentication',
    );
    const appSteps = steps.filter((step) => step.stepName !== 'Authentication');

    return {
      authSteps,
      appSteps,
    };
  }, [steps]);

  useEffect(() => {
    if (
      initialStepsData.authSteps.length === 0 &&
      allSteps.authSteps.length === 0
    ) {
      setIsAuthStepsModified(false);
      return;
    }

    const hasAuthStepsBeenModified = !isEqual(
      initialStepsData.authSteps,
      allSteps.authSteps,
    );
    setIsAuthStepsModified(hasAuthStepsBeenModified);
  }, [allSteps, initialStepsData]);

  useEffect(() => {
    if (
      initialStepsData.appSteps.length === 0 &&
      allSteps.appSteps.length === 0
    ) {
      setIsAppStepsModified(false);
      return;
    }

    const hasAppStepsBeenModified = !isEqual(
      initialStepsData.appSteps,
      allSteps.appSteps,
    );
    setIsAppStepsModified(hasAppStepsBeenModified);
  }, [allSteps, initialStepsData]);

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }

    void fetchAuditSettings();
  }, [currentBusiness, devDomain.id, fetchAuditSettings]);

  useEffect(() => {
    return () => {
      dispatch(setAuditSettings(null));
    };
  }, [dispatch]);

  return (
    <Container>
      <MainHeaderWrapper>
        <HeaderTextContainer>
          <MainHeader>Scan actions and steps</MainHeader>
          <InfoContent>
            Create actions and scans to test more complex user flows.
          </InfoContent>
        </HeaderTextContainer>
      </MainHeaderWrapper>
      <FormContainer>
        <FormContent>
          <ScanSteps
            steps={steps}
            setSteps={setSteps}
            handleAddNewStep={handleAddNewStep}
            isAuthStepsModified={isAuthStepsModified}
            isAppStepsModified={isAppStepsModified}
            saveScanActions={saveScanActions}
            scanStepSaved={scanStepSaved}
            setScanStepSaved={setScanStepSaved}
          />
        </FormContent>
      </FormContainer>
    </Container>
  );
};

const Container = styled(Box)``;

const HeaderTextContainer = styled.div`
  width: 100%;
`;

const SaveButton = styled(PrimaryButton)`
  font-weight: 500;
  padding: 0px 15px;
`;

const MainHeaderWrapper = styled(MainHeadersContainer)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`;
