import React from 'react';
import * as reactCSV from 'react-csv';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styled as MuiStyled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select';
import { CSVDataProps, EXPORT_TYPES, ExportTypesOption } from '../export';

const PRIMARY_COLOR = '#454284';

interface ReportActionsProps {
  exportType: EXPORT_TYPES;
  exportTypesOptions: ExportTypesOption[];
  handleExportTypeChange: (event: SelectChangeEvent) => void;
  loading: boolean;
  loadingPdf: boolean;
  downloadLinkRef: React.RefObject<
    reactCSV.CSVLink &
      HTMLAnchorElement & {
        link: HTMLAnchorElement;
      }
  >;
  exportDataOnPageAsCsv: () => Promise<void>;
  handlePdfDownload: () => Promise<void>;
  csvData: CSVDataProps;
  isReportTypeSelected: boolean;
}

export const ReportActions = (props: ReportActionsProps) => {
  const {
    exportType,
    exportTypesOptions,
    handleExportTypeChange,
    loading,
    loadingPdf,
    downloadLinkRef,
    exportDataOnPageAsCsv,
    handlePdfDownload,
    csvData,
    isReportTypeSelected,
  } = props;

  return (
    <ExportActionWrapper>
      <CustomFormControl
        sx={{ minWidth: 200 }}
        size="medium"
        disabled={!isReportTypeSelected}
      >
        <InputLabel className="input-label" id="select-export-type">
          Select export type
        </InputLabel>
        <Select
          className="select-label"
          labelId="select-export-type"
          id="select-export-type"
          value={exportType}
          label="Select export type"
          onChange={handleExportTypeChange}
        >
          {exportTypesOptions.map(({ label, value: optionValue }) => (
            <MenuItem key={`${label}-${exportType}`} value={optionValue}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
      <ExportButtonContainer>
        <ExportButton
          onClick={
            exportType === 'CSV'
              ? exportDataOnPageAsCsv
              : exportType === 'PDF'
                ? handlePdfDownload
                : undefined
          }
          disabled={
            loading || loadingPdf || exportType === '' || !isReportTypeSelected
          }
        >
          Download
        </ExportButton>
        <reactCSV.CSVLink
          ref={downloadLinkRef}
          data={csvData.data}
          headers={csvData.headers}
          filename={`EQUALLY_AI_${csvData?.data[0]?.domain_url}-issues-export.csv`}
          hidden
        >
          Download CSV
        </reactCSV.CSVLink>
      </ExportButtonContainer>
    </ExportActionWrapper>
  );
};

const ExportActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 7.5vh;
  margin: 50px 0 25px;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    height: fit-content;
  }
`;

const CustomFormControl = MuiStyled(FormControl)(() => ({
  [`&.${selectClasses.outlined}`]: {
    borderColor: '#454284',
    borderWidth: '1.5px',
  },
  '& fieldset': {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  '& .input-label, & .select-label': {
    fontSize: '13px',
  },
  '& .select-label': {
    // height: '7.5vh',

    '@media screen and (max-width: 768px)': {
      height: 'fit-content',
    },
  },
}));

const ExportButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-top: 20px;

    button {
      height: fit-content;
    }
  }

  @media screen and (max-width: 468px) {
    justify-content: space-between;

    button {
      width: 48%;
      padding: 16px 12px;
      height: 50px;
    }
  }
`;

const ExportButton = MuiStyled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: PRIMARY_COLOR,
  color: '#ffffff',
  border: 0,
  fontSize: 14,
  borderRadius: 8,
  boxShadow: '0px 2px 3px #dcdcdc',
  cursor: 'pointer',
  opacity: 1,

  '&:hover': {
    backgroundColor: '#3b3871',
  },

  '&.Mui-disabled': {
    color: '#ffffff',
    opacity: '0.5',
    pointerEvents: 'none',
  },
}));
