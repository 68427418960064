import React, { useState } from 'react';

type UsePopupReturn = {
  isOpen: boolean;
  showPopup: () => void;
  hidePopup: () => void;
  withApproval: (onApprove: () => void) => void;
  withCancel: (onCancel: () => void) => void;
};

function usePopup(): UsePopupReturn {
  const [isOpen, setIsOpen] = useState(false);

  const showPopup = () => setIsOpen(true);
  const hidePopup = () => setIsOpen(false);

  const withApproval = (onApprove: () => void) => {
    onApprove();
    hidePopup();
  };

  const withCancel = (onCancel: () => void) => {
    onCancel();
    hidePopup();
  };

  return {
    isOpen,
    showPopup,
    hidePopup,
    withApproval,
    withCancel,
  };
}

export default usePopup;
