export type TabIndexConfig = {
  id: string;
  index: string;
  selector: string;
};

export type LandmarkConfig = {
  id: string;
  role: string;
  selector: string;
};

export type CustomAttributesConfig = {
  id: string;
  attribute: string;
  selector: string;
  value: string;
};

export type MoreRolesConfig = {
  id: string;
  role: string;
  selector: string;
};

export type metaKey =
  | 'accessibilityAPI'
  | 'accessibilityControl'
  | 'accessibilityFeature'
  | 'accessibilityHazard'
  | 'accessModeSufficient';

export type MetaData = {
  accessibilityAPI: string[];
  accessibilityHazard: string[];
  accessModeSufficient: string[];
  accessibilityControl: string[];
  accessibilityFeature: string[];
};

export interface DynamicElementsConfig {
  id: string;
  role: string;
  selector: string;
  politeness: string;
}

export interface ElementRelationshipConfig {
  id: string;
  selector1: string;
  selector2: string;
  relation: string;
}
