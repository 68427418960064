import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Divider as MuiDivider } from '@mui/material';
import { Box } from '@mui/material';
import { ColumnOption, IssueCreationType } from './popup';
import { JiraTaskIssues } from './jira-task-issues';
import { generateUniqueId } from '@equally-ai-front/common/src/lib';
import { AdditionalInfoFields } from './additional-info-fields';
import {
  TaskIssue,
  TaskAdditionalInfo,
} from '@equally-ai-front/common/src/types/business';
import { styled as MuiStyled } from '@mui/material/styles';
import { OutlinedInput } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { JiraTaskIssue } from './jira-task-issue';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

interface JiraTasksProps {
  columns: ColumnOption[];
  issueCreationType: IssueCreationType;
  selectedIssues: TaskIssue[];
  selectedDevToolsIssues: DevtoolsSolution[];
  accordionState: AccordionState;
  handleToggleAccordion: (id: string) => void;
  tasksAdditionalInfo: TaskAdditionalInfo[];
  setTasksAdditionalInfo: React.Dispatch<
    React.SetStateAction<TaskAdditionalInfo[]>
  >;
}

const TASK_ID = generateUniqueId();
export const JiraTasks = (props: JiraTasksProps) => {
  const {
    columns,
    issueCreationType,
    selectedIssues,
    selectedDevToolsIssues,
    accordionState,
    handleToggleAccordion,
    tasksAdditionalInfo,
    setTasksAdditionalInfo,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = selectedIssues.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const currentIssue = selectedIssues[activeStep];
  const devToolsIssue = selectedDevToolsIssues.find(
    ({ id }) => id === currentIssue.id,
  );

  const taskAdditionalInfo = tasksAdditionalInfo[0];

  const handleChangeTaskAdditionalInfo = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = evt.target;
    setTasksAdditionalInfo((prev) =>
      prev.map((current) => {
        return { ...current, [name]: value };
      }),
    );
  };

  if (!devToolsIssue) return <></>;

  return (
    <Wrapper>
      <Container>
        <InputWrapper sx={{ flexGrow: 1 }}>
          <JiraTaskInput
            id="summary"
            name="summary"
            value={taskAdditionalInfo.summary}
            type="text"
            onChange={handleChangeTaskAdditionalInfo}
            onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
              if (evt.code === 'Space') {
                evt.preventDefault();
                setTasksAdditionalInfo((prev) =>
                  prev.map((current) => {
                    return {
                      ...current,
                      summary: current.summary + ' ',
                    };
                  }),
                );
              }
            }}
            placeholder="Enter task name"
          />
        </InputWrapper>
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
          {maxSteps > 1 && (
            <MobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft />
                </Button>
              }
            />
          )}
          <Divider />
          <JiraTaskIssue
            index={activeStep}
            columns={columns}
            issueCreationType={issueCreationType}
            devToolsIssue={devToolsIssue}
            tasksAdditionalInfo={tasksAdditionalInfo}
            setTasksAdditionalInfo={setTasksAdditionalInfo}
            isGrouped
          />
        </Box>
        <AdditionalInfoFieldsWrapper>
          <AdditionalInfoFields
            index={0}
            issueCreationType={issueCreationType}
            tasksAdditionalInfo={tasksAdditionalInfo}
            setTasksAdditionalInfo={setTasksAdditionalInfo}
          />
        </AdditionalInfoFieldsWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
`;

const Container = styled.div`
  overflow: hidden;
  border: 2px solid #d2d7df;
  border-radius: 8px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  > div > h3 {
    padding: 15px;
  }
`;

const InputWrapper = MuiStyled(Box)`
  width: 100%;
  max-width: 86%;
  padding: 15px 0;
  margin: auto;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
    align-items: initial;
  }
`;

export const HeaderText = styled.p`
  font-weight: 600;
  font-size: 15px;
  margin-left: 12px;

  @media (pointer: none), (pointer: coarse) {
    font-size: 12px;
    margin-left: 16px;
  }
`;

export const ArrowIconWrapper = styled.div`
  border: 2px solid #e4e7eb;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 410px) {
    width: 37px;
  }
`;

interface ArrowIconProps {
  shouldrotate: boolean;
}

const ArrowIcon = styled.img<ArrowIconProps>`
  transform: ${(props) => (props.shouldrotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

export const Divider = styled(MuiDivider)`
  height: 1px;
`;

const TitleAndIcon = styled.div`
  display: flex;
  align-items: center;
`;

const AdditionalInfoFieldsWrapper = styled.div``;

const JiraTaskInput = MuiStyled(OutlinedInput)({
  '&.MuiOutlinedInput-root': {
    width: '100%',
  },
  '&.MuiOutlinedInput-root input': {
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: 15,
    fontWeight: 500,
  },
  '& fieldset': {
    border: 'none',
  },
  '&:hover fieldset': {
    border: 'none',
  },
});
