import React, { useEffect, useMemo } from 'react';
import { usePlans } from '../../providers/plans-context';
import { showPlans } from '@equally-ai/cart-checkout/src/utils/plans.utils';
import { Box, Typography } from '@mui/material';
import { StyledTabs, StyledTab } from '../../components/common/styled';
import { DocumentTemplatesTab } from './components/tabs/document-templates-tab';
import { DocumentsTab } from './components/tabs/documents-tab';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';

enum ComplianceDocumentsTabValue {
  TEMPLATES = 'templates',
  DOCUMENTS = 'documents',
}

export const ComplianceDocumentsContainer = () => {
  const { activePlans } = usePlans();
  const navigate = useNavigate();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const { pathname } = useLocation();

  const currentTabValue = useMemo(() => {
    const pathSegments = pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];

    return !lastSegment.startsWith('compliance-documents')
      ? pathSegments[pathSegments.length - 1]
      : ComplianceDocumentsTabValue.TEMPLATES;
  }, [pathname]);

  const isLoading = useMemo(() => {
    return !currentBusiness || !activePlans;
  }, [currentBusiness, activePlans]);

  const handleRouteChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: ComplianceDocumentsTabValue,
  ) => {
    if (value === ComplianceDocumentsTabValue.TEMPLATES) {
      navigate(`/${currentBusiness.slug}/compliance-documents`);
    } else {
      navigate(`/${currentBusiness.slug}/compliance-documents/${value}`);
    }
  };

  useEffect(() => {
    if (!currentBusiness || !activePlans) {
      return;
    }
    const shouldShowSubscriptionsPage = showPlans(activePlans || []);
    if (shouldShowSubscriptionsPage) {
      navigate(
        `/${currentBusiness?.slug}/business-settings/plans?show_plan=true`,
      );
    }
  }, [activePlans, currentBusiness]);

  return (
    <Box>
      <Typography fontSize="30px" fontWeight={700} mb="30px">
        Compliance Documents
      </Typography>
      <Box>
        <Box mb="10px">
          <StyledTabs onChange={handleRouteChange} value={currentTabValue}>
            <StyledTab
              key={ComplianceDocumentsTabValue.TEMPLATES}
              value={ComplianceDocumentsTabValue.TEMPLATES}
              label={`Templates`}
              disableRipple
              tabIndex={0}
            />
            <StyledTab
              value={ComplianceDocumentsTabValue.DOCUMENTS}
              key={ComplianceDocumentsTabValue.DOCUMENTS}
              label={`Documents`}
              disableRipple
              tabIndex={0}
            />
          </StyledTabs>
        </Box>
        <Routes>
          <Route index element={<DocumentTemplatesTab />} />
          <Route
            path={ComplianceDocumentsTabValue.DOCUMENTS}
            element={<DocumentsTab />}
          />
          <Route path="*" element={<Navigate to="." replace />} />
        </Routes>
      </Box>
      <LoadingView open={isLoading} />
    </Box>
  );
};
