import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select';
import { styled as MuiStyled } from '@mui/material/styles';
import React, { Dispatch, SetStateAction } from 'react';

export interface FilterOption {
  value: string;
  label: string;
}

interface FilterSelectProps {
  id: string;
  options: FilterOption[];
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
  formInputLabel: string;
  disabled: boolean;
  marginRight?: string;
}

const customMargin = { margin: '0 0 15px 0' };

export const FilterSelect = (props: FilterSelectProps) => {
  const {
    id,
    options,
    value,
    setValue,
    formInputLabel,
    disabled,
    marginRight,
  } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  return (
    <CustomFormControl
      style={{ ...customMargin, marginRight }}
      sx={{ m: 1, minWidth: 120 }}
      size="small"
      disabled={disabled}
    >
      <InputLabel className="input-label" id={id}>
        {formInputLabel}
      </InputLabel>
      <Select
        className="select-label"
        labelId={id}
        id={id}
        value={value}
        label={formInputLabel}
        onChange={handleChange}
      >
        {options.map(({ label, value: optionValue }) => (
          <MenuItem key={`${label}-${value}`} value={optionValue}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </CustomFormControl>
  );
};

const CustomFormControl = MuiStyled(FormControl)(() => ({
  [`&.${selectClasses.outlined}`]: {
    borderColor: '#454284',
    borderWidth: '1.5px',
  },
  '& fieldset': {
    border: '1px solid rgb(216, 216, 216)',
  },
  '& .input-label, & .select-label': {
    fontSize: '13px',
  },
}));
