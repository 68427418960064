import React /* , { useState } */ from 'react';
// import { DateRange } from 'react-day-picker';
import styled from 'styled-components';
import { Title } from '../../components/css-components';
// import { CustomDateRangePicker } from '../../components/custom-date-range-picker';
import { MOBILE_PX } from '../../utils/constants';
import { WebsiteGroup } from './website-group';
import { IWebsiteInfo } from './website-list';

interface OverviewWebsiteDateFilterProps {
  title: string;
  selectedWebsites: IWebsiteInfo[];
  setSelectedWebsites: React.Dispatch<React.SetStateAction<IWebsiteInfo[]>>;
}

export const OverviewWebsiteDateFilter = (
  props: OverviewWebsiteDateFilterProps,
) => {
  const { title, selectedWebsites, setSelectedWebsites } = props;
  // const [dates, setDates] = useState<DateRange | undefined>();

  return (
    <Wrapper>
      <HeaderSection>
        <TitleAndWebsiteGrouping>
          <TitleWrapper>
            <CustomTitle>{title}</CustomTitle>
            <WebsiteGroup
              selectedWebsites={selectedWebsites}
              setSelectedWebsites={setSelectedWebsites}
            />
          </TitleWrapper>
        </TitleAndWebsiteGrouping>
        {/* <CustomDateRangePicker dateRange={dates} setDateRange={setDates} /> */}
      </HeaderSection>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (max-width: ${MOBILE_PX}px) {
    flex-direction: column;
  }
`;

const CustomTitle = styled(Title)`
  margin: 0 20px 0 0;

  @media (pointer: none), (pointer: coarse) {
    margin-bottom: 20px;
  }
`;

const TitleAndWebsiteGrouping = styled.div`
  display: flex;

  @media screen and (max-width: ${MOBILE_PX}px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;

  @media (pointer: none), (pointer: coarse) {
    margin-top: 10px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
  }
`;
