import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ScanRecommendationsState,
  GetScanRecommendationsArgType,
  ScanRecommendation,
  ManualRecommendations,
  DomainPage,
} from './types';

export const initialState: ScanRecommendationsState = {
  scanRecommendations: [],
  domainPages: [],
  dashboardActiveUrlIdx: 0,
  loading: false,
  confidenceLevel: '',
  error: '',
  startTour: false,
  productTourIndex: 0,
  message: '',
  dismissedIssues: null,
  manualRecommendations: [],
  scanStepReached: 0,
};

const slice = createSlice({
  name: 'scanRecommendations',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    getScanRecommendations(state, payload: GetScanRecommendationsArgType) {
      state.loading = true;
    },

    getScanRecommendationsSuccessful(
      state,
      action: PayloadAction<ScanRecommendation[]>,
    ) {
      state.scanRecommendations = action.payload ?? [];
      state.loading = false;
    },

    setConfidenceLevel(state, action: PayloadAction<string>) {
      state.confidenceLevel = action.payload;
    },

    setStartTour(state, action: PayloadAction<boolean>) {
      state.startTour = action.payload;
    },

    setProductTourIndex(state, action: PayloadAction<number>) {
      state.productTourIndex = action.payload;
    },

    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },

    getManualRecommendations(state) {
      state.loading = true;
    },

    getManualRecommendationsSuccessful(
      state,
      action: PayloadAction<ManualRecommendations[]>,
    ) {
      state.manualRecommendations = action.payload ?? [];
      state.loading = false;
    },

    setScanStepReached(state, action: PayloadAction<number>) {
      state.scanStepReached = action.payload;
    },

    getDomainPages(state, action: PayloadAction<{ domainUrl: string }>) {
      state.loading = true;
    },

    setDomainPages(state, action: PayloadAction<DomainPage[]>) {
      state.loading = false;
      state.domainPages = action.payload;
    },

    setDashboardActiveUrlIdx(state, action: PayloadAction<number>) {
      state.dashboardActiveUrlIdx = action.payload;
    },
  },
});

export const { actions: scanRecommendationsActions } = slice;
export default slice.reducer;
