import Button from '@mui/material/Button';
import styled from 'styled-components';

export const CenterBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconButton = styled(Button)`
  border: 2px solid;
  border-color: rgba(255, 255, 255, 0.32);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  width: auto;
  height: auto;
  min-width: auto;
  color: inherit;
  & .MuiButton-label {
    color: inherit;
  }
`;

interface PillProps {
  bgColor: string;
  color: string;
  hidePill?: boolean;
}

export const Pill = styled.span<PillProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  width: 48px;
  height: 26px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 32px;
  visibility: ${(props) => (props.hidePill ? 'hidden' : '')};

  @media screen and (max-width: 610px) {
    font-size: 11px;
    width: 38px;
    border-radius: 15px;
    height: 22px;
  }
`;

export const PillNoRadius = styled.span<PillProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  width: fit-content;
  height: 26px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 5px;
  text-transform: capitalize;

  @media screen and (max-width: 610px) {
    font-size: 11px;
    height: 22px;
  }
`;

export const HistoryPill = styled.span<PillProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  width: fit-content;
  height: 26px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 32px;
  text-transform: capitalize;
`;

export const Label = styled.label`
  position: absolute;
  font-size: 12px;
  top: -8px;
  display: flex;
  align-items: center;
  left: 10px;
  background-color: #ffffff;
  padding: 0 4px;
`;

export const InputDiv = styled.div`
  display: flex;
  align-items: center;
  height: 60%;
  position: relative;
  width: 100%;
  margin: auto;
  border: 2px solid #e4e7eb;
  border-radius: 8px;

  input {
    border: none;
    height: 100%;
    padding: 10px;
    width: 100%;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    }
  }
`;

export const ModalContentWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
  margin-right: -15px;
  padding-right: 20px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }
`;

export const CustomGreenButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px 12px 16px;
  height: 48px;
  background-color: #4ad493;
  border-radius: 12px;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    background: #98eabe;
  }

  span {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const AbsoluteCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TabText = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  margin-right: 6px;

  @media screen and (max-width: 970px) and (min-width: 900px) {
    margin-right: 0;
  }
`;

export const ConfirmationTabText = styled(TabText)`
  margin-right: 0;
  height: 26px;
  display: flex;
  align-items: center;
`;

export const CodeHeader = styled.h5`
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 10, 20, 0.85);
`;

interface CustomLiProps {
  bulletColor: string;
  isActive: boolean;
}

export const CustomLi = styled.li<CustomLiProps>`
  color: ${(props) => props.bulletColor};
  margin-bottom: 5%;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.32);
  }

  span {
    color: #000a14;
    word-wrap: break-word;
    ${(props) => props.isActive && 'font-weight: 600'};
    font-size: 15px;
  }
`;

interface ButtonWithIconDivProps {
  isDisabled?: boolean;
}

export const ButtonWithIconDiv = styled.div<ButtonWithIconDivProps>`
  margin-top: 12px;
  opacity: ${(props) => (props.isDisabled ? '0.4' : '')};
  margin-right: 26px;

  &:last-child {
    margin-right: 0;
  }

  :nth-child(4n) {
    margin-left: 26px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 560px) {
    width: 45%;
    margin-right: 0;
  }
`;

export const TabPanel = styled.div`
  width: 95%;
  margin: auto;
  margin-top: 5%;
  padding: 10px 20px 5%;
`;
