import { useState } from 'react';

import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { buildDevtoolsReport } from '@equally-ai-front/common/src/helpers/templates';
import { ScanTotals } from '@equally-ai-front/common/src/types/scans';
import {
  calculateAccessibilityScore,
  getIssuesCategoriesCount,
} from '@equally-ai-front/common/src/lib';
import { CustomReportFields } from '../components/exports/export';
import { PdfReport } from '../components/pdf-report/pdf-report';

export const usePdfReport = () => {
  const [loadingPdf, setLoadingPdf] = useState(false);

  const handleDownloadReportPdf = async (
    websiteUrl: string,
    pdfReport: DevtoolsSolution[],
    statuses: string[],
    sources: string[],
    columns: string[],
    customReportFields: CustomReportFields | null,
    devDomainName = '',
    extendedAudit = true,
  ): Promise<{ message: string; isSuccess: boolean }> => {
    setLoadingPdf(true);

    try {
      let filteredPdfData = pdfReport;
      if (statuses.length > 0) {
        const statusList = statuses.map((status) => status.toLowerCase());
        filteredPdfData = filteredPdfData.filter(({ status }) =>
          statusList.includes(status.toLowerCase()),
        );
      }
      if (sources.length > 0) {
        filteredPdfData = filteredPdfData.filter(({ source }) =>
          sources.includes(source),
        );
      }
      if (filteredPdfData.length === 0) {
        return {
          message: 'No data matches selected filter!',
          isSuccess: false,
        };
      }

      const report = buildDevtoolsReport(websiteUrl, pdfReport);
      await new Promise((resolve) => setTimeout(resolve, 100));
      const issuesCategoriesCount = getIssuesCategoriesCount(
        report.reportGroups.all,
      );
      const scanTotals: ScanTotals = {
        totalErrors: report.list.basic.length,
        totalNotices: report.list.potential.length,
        totalWarnings: report.list.advanced.length,
      };
      const accessibilityScore = calculateAccessibilityScore([scanTotals]) || 0;

      const [{ domain_url }] = pdfReport;
      const scannedUrl =
        domain_url !== 'header' && domain_url !== 'footer'
          ? domain_url
          : devDomainName;
      const blob = await pdf(
        PdfReport({
          pdfReport: filteredPdfData,
          issuesCategoriesCount,
          accessibilityScore,
          devDomainName,
          customReportFields,
          columns,
          extendedAudit,
        }),
      ).toBlob();
      saveAs(blob, `${scannedUrl.replace(/\//g, '_')}.pdf`);
      return {
        message: 'Successfully downloaded pdf!',
        isSuccess: true,
      };
    } catch (error) {
      console.error('Error generating PDF:', error);
      return {
        message: 'Error generating PDF',
        isSuccess: false,
      };
    } finally {
      setLoadingPdf(false);
    }
  };

  return {
    loadingPdf,
    handleDownloadReportPdf,
  };
};
