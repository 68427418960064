import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from '../../../public/error-icon.svg';

export enum HeaderColorType {
  DANGER = 'danger',
  WARNING = 'warning',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  GREY = 'grey',
}

// eslint-disable-next-line no-undef
const HeaderColor: Record<HeaderColorType, string> = {
  danger: '#ff9999',
  warning: '#f2df87',
  primary: '#99d3ff',
  success: '#adead2',
  grey: '#f1f3f5',
};

interface StickyHeaderProps {
  variant: HeaderColorType;
  title: string;
  subText?: string | Element;
  handleCloseStickyPlanMessage?: () => void;
  style?: React.CSSProperties;
}

export const StickyHeader: React.FC<StickyHeaderProps> = ({
  variant,
  title,
  handleCloseStickyPlanMessage,
  subText,
  style,
}) => {
  return (
    <StickyHeaderContainer variant={variant} style={style}>
      <IconContainer onClick={handleCloseStickyPlanMessage}>
        <ErrorIcon />
      </IconContainer>
      <HeaderContainer>
        <StickyHeaderTypography>{title}</StickyHeaderTypography>
        {/** @ts-ignore * */}
        {subText && <StickyHeaderTypography>{subText}</StickyHeaderTypography>}
      </HeaderContainer>
    </StickyHeaderContainer>
  );
};

const StickyHeaderContainer = styled.div<{ variant: HeaderColorType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: ${(props) => HeaderColor[props.variant]};
  box-sizing: border-box;
  border-radius: 12px;
  height: 100px;
  padding: 20px 8px;

  @media screen and (max-width: 610px) {
    height: auto;
    max-height: fit-content;
  }
`;
const StickyHeaderTypography = styled.h1`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

const IconContainer = styled.div`
  width: 32px;
  margin: 0 8px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
