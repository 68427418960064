import React, { useEffect } from 'react';
import { useAuth } from '../../providers/auth-context';

export const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    void logout();
  }, [logout]);

  return <>Logging out...</>;
};
