import {
  ADD_DOMAIN_TO_PLANS_REQUEST,
  ADD_DOMAIN_TO_PLANS_SUCCESS,
  GET_ACTIVE_PLANS_SUCCESS,
  GET_ALL_PLANS_SUCCESS,
  GET_ALL_SUBSCRIPTION_RECORD_SUCCESS,
  GET_INACTIVE_PLANS_SUCCESS,
  GET_PLANS,
} from '../actionTypes';

const initialState = {
  allPlans: [],
  activePlans: [],
  inactivePlans: [],
  record: [],
  allRecords: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PLANS:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        allPlans: action.value,
        loading: false,
      };
    case GET_ACTIVE_PLANS_SUCCESS:
      return {
        ...state,
        activePlans: action.value,
        loading: false,
      };
    case GET_INACTIVE_PLANS_SUCCESS:
      return {
        ...state,
        inactivePlans: action.value,
      };
    case ADD_DOMAIN_TO_PLANS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_DOMAIN_TO_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_SUBSCRIPTION_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        allRecords: action.value,
      };
    default:
      return state;
  }
}
