import React from 'react';
import styled from 'styled-components';
import {
  FormFieldValidationErrors,
  FormFieldName,
  JiraSettingsFormFields,
} from '../jira-integration-settings';

interface FormFieldProps {
  formFieldName: FormFieldName;
  jiraFormFields: JiraSettingsFormFields;
  errors: FormFieldValidationErrors;
  isInputsDisabled: boolean;
  handleJiraFormFieldChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormField = (props: FormFieldProps) => {
  const {
    formFieldName,
    jiraFormFields,
    errors,
    isInputsDisabled,
    handleJiraFormFieldChange,
  } = props;

  return (
    <Wrapper>
      <IconWrapper>
        {errors[formFieldName] === '' ? (
          <Icon src="/blue-check-circle.svg" />
        ) : (
          <IconCircle />
        )}
      </IconWrapper>
      <FormInput
        id={formFieldName}
        name={formFieldName}
        value={jiraFormFields[formFieldName]}
        onChange={handleJiraFormFieldChange}
        disabled={isInputsDisabled}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 10.5px;
  left: 10px;
`;

const Icon = styled.img``;

const IconCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 1.5px solid #0e87ac;
  border-radius: 50%;
`;

const FormInput = styled.input`
  background-color: #ffffff;
  height: 41px;
  border: 1.5px solid #e4e7eb;
  width: 100%;
  border-radius: 8px;
  display: block;
  padding: 12px;
  padding-left: 38px;

  &:focus {
    border: 1.5px solid #e4e7eb;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    -webkit-text-fill-color: inherit !important;
  }

  &:disabled,
  &:-webkit-autofill:disabled {
    color: rgb(170, 170, 170) !important;
    -webkit-text-fill-color: rgb(170, 170, 170) !important;
  }
`;
