import { Grid } from '@mui/material';
import React from 'react';
import { ImpactLevelBadge } from '@equally-ai-front/common/src/components/impact-level-badge';
import {
  HIGH_IMPACT_MESSAGE,
  LOW_IMPACT_MESSAGE,
  MEDIUM_IMPACT_MESSAGE,
} from '@equally-ai-front/common/src/utils/constants';
import styled from 'styled-components';

interface FormatScanTotalIssuesProps {
  notice: string | number;
  warning: string | number;
  error: string | number;
}

export const FormatScanTotalIssues = ({
  notice,
  warning,
  error,
}: FormatScanTotalIssuesProps) => {
  return (
    <StyledContainer container>
      <StyledGridItem item>
        <ImpactLevelBadge
          alt=""
          impactNumber={+error}
          iconSrc="/info-circle.svg"
          backgroundColor="#FF9999"
          color="#661F20"
          toolTipMessage={HIGH_IMPACT_MESSAGE}
        />
      </StyledGridItem>
      <StyledGridItem item>
        <ImpactLevelBadge
          alt=""
          impactNumber={+warning}
          iconSrc="/info-circle.svg"
          backgroundColor="#F2DF87"
          color="#6F321B"
          toolTipMessage={MEDIUM_IMPACT_MESSAGE}
        />
      </StyledGridItem>
      <StyledGridItem item>
        <ImpactLevelBadge
          alt=""
          impactNumber={+notice}
          iconSrc="/info-circle.svg"
          backgroundColor="#AEEAD3"
          color="#1E5643"
          toolTipMessage={LOW_IMPACT_MESSAGE}
        />
      </StyledGridItem>
    </StyledContainer>
  );
};

const StyledContainer = styled(Grid)`
  @media screen and (max-width: 600px) {
    margin-top: 15px;
    justify-content: flex-end;
    flex-direction: column;
    width: 55%;
    margin-left: auto;
  }
`;

const StyledGridItem = styled(Grid)`
  display: flex;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 960px) {
    > div {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 600px) {
    margin: 0;
  }
`;
