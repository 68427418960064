import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../providers/auth-context';

interface ProtectedRouteProps {
  redirectTo?: string;
  children: ReactNode;
}

export const ProtectedRoute = ({
  redirectTo = '/login',
  children,
}: ProtectedRouteProps) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to={redirectTo} replace />;
  }

  return <>{children}</>;
};
