import axios from 'axios';

type ImageFormat = 'jpeg' | 'png' | 'gif';
const getImageFormatFromMimeType = (mimeType: string): ImageFormat => {
  switch (mimeType) {
    case 'image/jpeg':
      return 'jpeg';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    default:
      return 'jpeg';
  }
};

export class ExportSettings {
  static async getImageDataFromUrl(imageUrl: string) {
    let result;
    let error;

    try {
      result = await axios.get(imageUrl, {
        responseType: 'arraybuffer',
      });
    } catch (error) {
      console.error('Error getting logo image: ', error);
      error = 'Error fetching image';
    }
    const mimeType = result?.headers['content-type'];
    const imageFormat = getImageFormatFromMimeType(mimeType);

    return {
      isSuccess: result?.status === 200,
      data: {
        imageData: result?.data,
        imageFormat,
      },
      error,
    };
  }
}
