import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import React from 'react';
import { FieldMap, GridRow } from '../../../components/generic-grid/grid-row';
import { SortOption } from '@equally-ai-front/common/src/hooks/use-pagination';
import { GridSortIcon } from '../../../components/generic-grid/grid-sort-icon';

const headerMap: FieldMap[] = [
  {
    key: 'index',
    size: {
      sm: 1,
      xs: 1,
      md: 1,
    },
    hideOnMobile: true,
  },
  {
    key: 'name',
    size: {
      sm: 2,
      xs: 6,
      md: 2,
    },
    withAction: true,
  },
  {
    key: 'time_left',
    size: {
      sm: 2,
      xs: 5,
      md: 2,
    },
    withAction: true,
  },
  {
    key: 'status',
    hideOnMobile: true,
    size: {
      sm: 1,
      xs: 2,
      md: 1,
    },
  },
  {
    key: 'plan',
    hideOnMobile: true,
    size: {
      sm: 1,
      xs: 3,
      md: 1,
    },
  },
  {
    key: 'type',
    size: {
      sm: 2,
      xs: 9,
      md: 2,
    },
    hideOnMobile: true,
  },
];

const headerRowData = {
  index: '#',
  name: 'domain',
  time_left: 'time left',
  status: 'status',
  plan: 'plan type',
  type: 'type',
  action: '',
};

interface WidgetTableHeaderProps {
  setSort: (key: string) => void;
  currentSortOption: SortOption;
}

export const WidgetTableHeader = ({
  setSort,
  currentSortOption,
}: WidgetTableHeaderProps) => {
  const isMobile = useMediaQuery(`(max-width: 600px)`);
  const padding = isMobile ? '5px 15px' : '10px';

  const currentSortKey = Object.keys(currentSortOption)[0];
  const currentSortOrder = Object.values(currentSortOption)[0] || 'desc';

  const getHeaderMap = (): FieldMap[] => {
    const mapWithSort = headerMap.map((map) => ({
      ...map,
      onClick: map.withAction ? setSort : undefined,
      icon: map.withAction ? (
        <GridSortIcon
          isActive={currentSortKey === map.key}
          type={currentSortKey === map.key ? currentSortOrder : 'desc'}
        />
      ) : undefined,
    }));

    if (isMobile) {
      mapWithSort[2].align = 'right';
    }

    return mapWithSort;
  };

  return (
    <GridRow
      rowData={headerRowData}
      rowMap={getHeaderMap()}
      variant="heading"
      rowPadding={padding}
    />
  );
};
