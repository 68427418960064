import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { isHexColorLight } from '../../../common/helpers';
import { ICONS } from '../../../common/icons';
import SelectedIcon from '../../AccessibilityIcon/SelectedIcon';
import { DropdownContainer } from './styled';

const IconsDropdown = ({
  selectedIcon,
  width,
  height,
  actionColor,
  onSelect,
}) => {
  const iconColor = isHexColorLight(actionColor) ? '#000000' : '#ffffff';

  return (
    <DropdownContainer>
      <div className="select-icon-button icon-select selected-icon">
        <SelectedIcon
          iconId={selectedIcon}
          width={width}
          height={height}
          fill={actionColor}
          stroke={iconColor}
        />
      </div>
      {ICONS.map((icon) => {
        if (selectedIcon !== icon.iconId) {
          return (
            <>
              {icon.iconId === 'icon-plus' ? (
                <Tooltip title="Add Custom Anchor" placement="top" arrow>
                  <div
                    key={icon.iconId}
                    className="select-icon-button icon-select"
                    onClick={() => onSelect(icon.iconId)}
                    onKeyPress={() => onSelect(icon.iconId)}
                    role="button"
                    tabIndex={0}
                    value={icon.iconId}
                  >
                    <SelectedIcon
                      iconId={icon.iconId}
                      width={width}
                      height={height}
                      fill={actionColor}
                      stroke={iconColor}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div
                  key={icon.iconId}
                  className="select-icon-button icon-select"
                  onClick={() => onSelect(icon.iconId)}
                  onKeyPress={() => onSelect(icon.iconId)}
                  role="button"
                  tabIndex={0}
                  value={icon.iconId}
                >
                  <SelectedIcon
                    iconId={icon.iconId}
                    width={width}
                    height={height}
                    fill={actionColor}
                    stroke={iconColor}
                  />
                </div>
              )}
            </>
          );
        }
        return null;
      })}
    </DropdownContainer>
  );
};

IconsDropdown.propTypes = {
  selectedIcon: PropTypes.string.isRequired,
  actionColor: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default IconsDropdown;
