import { WidgetConfigurations } from '../helpers';
import {
  ABSTRUCT_ROLES_AREA,
  ARIA_ATTRIBUTE_STATE,
  ARIA_ATTRIBUTE_STRINGS,
  ARIA_ATTRIBUTE_STATE_STRING,
  LANDMARKS_ROLES,
  LIST_OF_RELATIONS,
  COMPOSITE_USER_INTERFACE_WIDGETS_AREA,
  DOCUMENT_STRUCTURE_AREA,
  CONTRAST,
  OTHERS,
  IMAGE,
  WIDGET_ROLE_AREA,
  AriaAttributes,
} from './consts';

export const DEFAULT_FAVORITES_ATTRIBUTES = [
  'tabindex',
  'aria-label',
  'aria-hidden',
  'aria-labelledby',
  'title',
  'aria-controls',
];

export const ATTRIBUTES_TO_CONFIG: Record<WidgetConfigurations, string[]> = {
  [WidgetConfigurations.IMPROVED_NAVIGATION]: ['tabindex'],
  [WidgetConfigurations.CUSTOM_ATTRIBUTES]: [
    ...ARIA_ATTRIBUTE_STATE,
    ...ARIA_ATTRIBUTE_STRINGS,
    ...ARIA_ATTRIBUTE_STATE_STRING,
    ...LIST_OF_RELATIONS,
    ...CONTRAST,
    ...IMAGE,
    ...OTHERS,
  ],
  // values
  [WidgetConfigurations.LANDMARKS]: [...LANDMARKS_ROLES],
  // values
  [WidgetConfigurations.MORE_ROLES]: [
    ...ABSTRUCT_ROLES_AREA,
    ...COMPOSITE_USER_INTERFACE_WIDGETS_AREA,
    ...DOCUMENT_STRUCTURE_AREA,
    ...WIDGET_ROLE_AREA,
  ],
  [WidgetConfigurations.META_DATA]: [],
  [WidgetConfigurations.ELEMENTS_RELATIONSHIP]: [],
  [WidgetConfigurations.DYNAMIC_ELEMENTS]: [],
  [WidgetConfigurations.MENU_AND_SUBMENU]: [],
};

export const getAttributeConfig = (attribute: string): WidgetConfigurations => {
  let configType: WidgetConfigurations = WidgetConfigurations.CUSTOM_ATTRIBUTES;

  Object.keys(ATTRIBUTES_TO_CONFIG).forEach((type) => {
    const item = ATTRIBUTES_TO_CONFIG[
      type as keyof typeof ATTRIBUTES_TO_CONFIG
    ].find((attr) => attr === attribute);
    if (item) {
      configType = type as WidgetConfigurations;
      return;
    }
  });

  return configType;
};

enum AriaOptionsAttributes {
  ariaLive = 'aria-live',
  role = 'role',
  ariaPressed = 'aria-pressed',
}

export enum AriaAttributeEnum {
  Input = 'input',
  Select = 'select',
  Boolean = 'boolean',
}

interface AriaAttributeOptions {
  inputType: AriaAttributeEnum;
  values: string[];
}

const ariaAttributeToOptions: Record<
  AriaOptionsAttributes,
  AriaAttributeOptions
> = {
  [AriaOptionsAttributes.ariaLive]: {
    inputType: AriaAttributeEnum.Select,
    values: ['off', 'polite', 'assertive'],
  },
  [AriaOptionsAttributes.role]: {
    inputType: AriaAttributeEnum.Select,
    values: ATTRIBUTES_TO_CONFIG[WidgetConfigurations.MORE_ROLES],
  },
  [AriaOptionsAttributes.ariaPressed]: {
    inputType: AriaAttributeEnum.Select,
    values: ['true', 'false', 'mixed', 'undefined'],
  },
};

export type AriaAttributeCombined = AriaAttributes & AriaOptionsAttributes;

export const getAriaAttributeOptions = (
  attribute: AriaAttributeCombined,
  widgetConfigurations?: WidgetConfigurations,
): AriaAttributeOptions => {
  if (!attribute) {
    return { inputType: AriaAttributeEnum.Input, values: [] };
  }
  if (widgetConfigurations && attribute === 'role') {
    return {
      inputType: AriaAttributeEnum.Select,
      values: ATTRIBUTES_TO_CONFIG[widgetConfigurations],
    };
  }

  if (ariaAttributeToOptions[attribute]) {
    const { values, inputType } = ariaAttributeToOptions[attribute];

    return { inputType, values };
  }

  if (ARIA_ATTRIBUTE_STATE.includes(attribute)) {
    return { inputType: AriaAttributeEnum.Boolean, values: ['false', 'true'] };
  }

  if (
    WidgetConfigurations.IMPROVED_NAVIGATION === getAttributeConfig(attribute)
  ) {
    return {
      inputType: AriaAttributeEnum.Select,
      values: ['0', '-1'],
    };
  }

  return { inputType: AriaAttributeEnum.Input, values: [] };
};

export const isSelectInputField = (inputType: AriaAttributeEnum) =>
  [AriaAttributeEnum.Boolean, AriaAttributeEnum.Select].includes(inputType);

export const attributesExplanation = {
  'aria-hidden':
    'Indicates whether an element is visible or hidden to assistive technologies.',
  'aria-pressed':
    'Indicates whether a toggle button is in a pressed or not-pressed state for accessibility.',
  'aria-expanded':
    'Indicates whether an element, such as a menu or list, is expanded or collapsed.',
  'aria-selected': 'Indicates whether an element is selected.',
  'aria-current':
    'Indicates the current item within a set of related elements.',
  'aria-invalid': 'Indicates that an entered value or state is invalid.',
  'aria-modal': 'Indicates whether an element is a modal dialog.',
  'aria-readonly': 'Indicates whether an element is read-only.',
  'aria-required': 'Indicates that an element is required to be filled out.',
  'aria-haspopup':
    'Indicates whether an element has a popup context menu or sub-level menu.',
  'aria-atomic':
    'Indicates whether an element updates all of its children or only parts.',
  'aria-checked': 'Indicates the current state of checkboxes or radio buttons.',
  'data-equally-click':
    'Custom data attribute to denote specific click behaviors.',
  'aria-label':
    'Text alternative for elements when no visible label is present.',
  'aria-placeholder': 'Describes the placeholder text in input elements.',
  'aria-level': 'Indicates the nesting level of an element within a structure.',
  'aria-errormessage': 'Points to the element that provides an error message.',
  'aria-labelledby':
    'Identifies the element that serves as the label for the current element.',
  'aria-describedby': 'Identifies elements that describe the current element.',
  'aria-live':
    'Indicates whether updates to an element should be announced to screen readers.',
  'aria-activedescendant':
    'Identifies the currently active element within a container of elements.',
  'aria-colcount': 'Indicates the number of columns in a table or grid.',
  'aria-colindex': "Indicates an element's position in a table column or grid.",
  'aria-colspan': 'Indicates the number of columns an element spans.',
  'aria-controls': 'Identifies elements controlled by the current element.',
  'aria-details':
    'Identifies elements that provide additional information or context.',
  'aria-flowto':
    'Indicates where focus should go after an element receives focus.',
  'aria-owns': 'Identifies elements that are owned by the current element.',
  'aria-posinset':
    "Indicates an element's position in a set of related elements.",
  'aria-rowcount': 'Indicates the number of rows in a table or grid.',
  'aria-rowindex': "Indicates an element's position in a table row or grid.",
  'aria-rowspan': 'Indicates the number of rows an element spans.',
  'aria-setsize': 'Indicates the number of items in a set of related elements.',
  'data-equally-controls':
    'Custom data attribute to specify controlled elements.',
  'data-equally-submenu':
    'Custom data attribute for submenu-related functionality.',
  color: 'Specifies the text color.',
  backgroundColor: 'Specifies the background color.',
  borderColor: 'Specifies the border color.',
  'data-equally-custom-focus':
    'Custom data attribute for defining specific focus behavior.',
  alt: 'Alternative text for images.',
  lang: "Specifies the language of the element's content.",
  title: 'Specifies extra information about an element.',
  dir: 'Specifies the direction of the text.',
  role: 'Specifies the role of an element in accessibility.',
  tabindex:
    "Specifies whether an element can be focused on using keyboard navigation and determines its order in the focus chain. A positive value indicates it's focusable, 0 includes it in the natural tab order, and -1 removes it from the tab order but allows programmatic focus.",
};
