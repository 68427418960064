import styled from '@emotion/styled';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Box, CircularProgress, Container, Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  QUERY_PARAM_STATEMENT_DOMAIN,
  QUERY_PARAM_STATEMENT_LANGUAGE,
} from '../../common/magicValues';
import { useQuery } from '../../hooks/useQuery';

import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import axios from 'axios';
import { ReactComponent as DownloadIcon } from '../../img/DownloadIcon.svg';
import { ReactComponent as LogoSmall } from '../../img/LogoDark.svg';
import { OutlinedButton } from '../common/ActionButton';
import { TypographyH1 } from '../common/styled';
import Underline from '../common/Underline';
import StatementPDF from './statement-pdf';

export const Statement = () => {
  const urlDomain = useQuery().get(QUERY_PARAM_STATEMENT_DOMAIN);
  const lang = useQuery().get(QUERY_PARAM_STATEMENT_LANGUAGE);
  const analytics = useAnalytics();
  const [statement, setStatement] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const rtl = lang === 'he' || lang === 'ar';
  const getStatement = useCallback(async () => {
    try {
      const userlang = lang || 'en';
      const response = axios.get(`/locales/${userlang}.json`, {
        baseURL: window.location.origin,
      });
      setStatement((await response).data);
    } catch (error) {
      setStatement(undefined);
    } finally {
      setIsLoading(false);
    }
  }, [lang]);

  useEffect(() => {
    void getStatement();
  }, [getStatement]);

  useEffect(() => {
    analytics.page({
      name: 'Accessibility Statement Page',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.zE?.('webWidget', 'hide');

    const timeoutID = setTimeout(() => {
      window.zE?.('webWidget', 'hide');
    }, 1000);

    return () => {
      clearTimeout(timeoutID.current);
      window.zE?.('webWidget', 'show');
    };
  }, []);

  // TODO: Move to helpers
  const formattedDomain = useMemo(() => {
    if (!urlDomain) {
      return undefined;
    }

    let domain = urlDomain;
    if (urlDomain && !urlDomain.startsWith('http')) {
      domain = `http://${urlDomain}`;
    }

    try {
      const url = new URL(domain);
      return url.hostname;
    } catch {
      return urlDomain;
    }
  }, [urlDomain]);

  const formattedDate = useMemo(
    () =>
      new Date().toLocaleDateString(undefined, {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
      }),
    [],
  );

  const doPrint = useCallback(() => window.print(), []);

  return (
    <Main>
      <Container maxWidth="sm">
        <HeaderContent>
          <HeaderText>
            <Underline space={4}>Accessibility statement</Underline>
          </HeaderText>

          <Box mt="26px" />
          <Meta>
            <Grid container wrap="nowrap" spacing={3} alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>Issued by</Grid>
                  <Grid item>
                    <LogoMark width="94" height="34" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>{formattedDate}</Grid>
              {formattedDomain && <Grid item>{formattedDomain}</Grid>}
            </Grid>
          </Meta>
          <Box mt="24px" />
          <DownloadSection>
            {statement && (
              <DownloadButton
                document={
                  <StatementPDF
                    statement={statement}
                    domain={formattedDomain}
                    rtl={rtl}
                    lang={lang}
                  />
                }
                fileName={`Accessibility_Statement_for_${formattedDomain.split('.').join('_')}.pdf`}
              >
                <DownloadIcon />{' '}
                <span style={{ marginLeft: '5px' }}>Download</span>
              </DownloadButton>
            )}
          </DownloadSection>
        </HeaderContent>
      </Container>
      <Background>
        <Container maxWidth="sm">
          {isLoading && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '60vh',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {statement && (
            <StatementContent>
              <div dir={rtl ? 'rtl' : 'ltr'}>
                <div>
                  <div>
                    <SectioTitle>{statement.introduction}</SectioTitle>
                    <SectionText>
                      <p>{statement.introduction_text.p1}</p>
                      <p>{statement.introduction_text.p2}</p>
                      <p>{statement.introduction_text.p3}</p>
                      <p>{statement.introduction_text.p4}</p>
                    </SectionText>

                    <SectioTitle>{statement.disclaimer}</SectioTitle>
                    <SectionText>
                      <p>{statement.disclaimer_text.p1}</p>
                    </SectionText>

                    <SectioTitle>
                      {statement.accessibility_assistant}
                    </SectioTitle>
                    <SectionText>
                      <p>{statement.accessibility_assistant_text.p1}</p>
                      <p>{statement.accessibility_assistant_text.p2}</p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.blind}
                        </span>
                        {statement.accessibility_assistant_text.blind_text}
                      </p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.impaired}
                        </span>
                        {statement.accessibility_assistant_text.impaired_text}
                      </p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.seizure}
                        </span>
                        {statement.accessibility_assistant_text.seizure_text}
                      </p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.motor}
                        </span>
                        {statement.accessibility_assistant_text.motor_text}
                      </p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.focus}
                        </span>
                        {statement.accessibility_assistant_text.focus_text}
                      </p>
                      <PageBreak />
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.dyslexia}
                        </span>
                        {statement.accessibility_assistant_text.dyslexia_text}
                      </p>
                      <p className="leading-[22px] font-[600] uppercase text-[22px] my-4">
                        {statement.accessibility_assistant_text.other}
                      </p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.color}
                        </span>
                        {statement.accessibility_assistant_text.color_text}
                      </p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.text}
                        </span>
                        {statement.accessibility_assistant_text.text_text}
                      </p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.highlight}
                        </span>
                        {statement.accessibility_assistant_text.highlight_text}
                      </p>
                      <p>
                        <span className="font-[600]">
                          {statement.accessibility_assistant_text.zoom}
                        </span>
                        {statement.accessibility_assistant_text.zoom_text}
                      </p>

                      <p className="leading-[22px] font-[600] uppercase text-[22px] my-4">
                        {statement.support}
                      </p>
                      <p>{statement.support_text.p1}</p>
                      <p>{statement.support_text.p2}</p>
                      <p>{statement.support_text.p3}</p>

                      <p className="text-[20px] font-[600] my-3">
                        {statement.support_sub_header}
                      </p>
                      {statement.support_list?.blind && (
                        <ul className="list-disc pl-6">
                          <li>{statement.support_list.blind}</li>
                          <li>{statement.support_list.impaired}</li>
                          <li>{statement.support_list.seizure}</li>
                          <li>{statement.support_list.dyslexia}</li>
                          <li>{statement.support_list.motor}</li>
                          <li>{statement.support_list.cognitive}</li>
                        </ul>
                      )}
                    </SectionText>

                    <SectionText>
                      <p>{statement.closing}</p>
                      <p>{statement.closing_text?.p1}</p>
                    </SectionText>
                  </div>
                </div>
              </div>
            </StatementContent>
          )}
        </Container>
      </Background>
    </Main>
  );
};

/** Styled Components */

const Main = styled.div`
  @media print {
    @page {
      size: auto;
      margin: 0;
    }
  }
`;

const DownloadSection = styled.div`
  @media print {
    display: none;
  }
`;

const HeaderContent = styled.div`
  padding-top: 48px;
  padding-bottom: 64px;

  @media print {
    padding-bottom: initial;
  }
`;

const StatementContent = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;

  @media print {
    padding: initial;
  }
`;

const HeaderText = styled(TypographyH1)`
  font-weight: 500;
  font-size: 34px;
  line-height: 120%;

  color: #000a14;
`;

const Meta = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  color: rgba(0, 10, 20, 0.85);
`;

const DownloadButton = styled(PDFDownloadLink)`
  text-transform: none;
  padding: 6px 16px;
  border-radius: 8px;
  border: 2px solid #454284;
  color: #454284;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const SectioTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;

  color: #000a14;
`;

const SectionText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;

  color: rgba(0, 10, 20, 0.65);

  p {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  & ul > li {
    margin-bottom: 24px;
  }
`;

const PageBreak = styled.div`
  @media print {
    page-break-after: always;
  }
`;

const Background = styled.div`
  background: #f5f7fa;
  @media print {
    background: none;
  }
`;

const LogoMark = styled(LogoSmall)`
  margin-top: 6px;
`;
