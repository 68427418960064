import React from 'react';
import styled from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import { OverViewPage } from '../containers/overview-page/overview-page';
import { IssuesPage } from '../containers/issues-page/issues-page';
import { SettingsPage } from '../containers/settings-page/settings-page';

export const ComplianceManagerRoutes = () => {
  return (
    <Wrapper>
      <Routes>
        <Route path="overview" element={<OverViewPage />} />
        <Route path="issues" element={<IssuesPage />} />
        <Route path="settings/:settingsType" element={<SettingsPage />} />
      </Routes>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
`;
