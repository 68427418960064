import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Autocomplete,
  Checkbox,
  TextField,
  Typography,
  Radio,
  radioClasses,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Select, { selectClasses, SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { styled as MuiStyled } from '@mui/material/styles';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { JiraIssues } from './jira-issues';
import { SelectOption, ColumnOption, JiraTaskFormFields } from './popup';
import { RootState } from '../../store';
import { PrimaryButton } from '../redesign/Controls';
import { TaskIssue } from '@equally-ai-front/common/src/types/business';

interface StepOneFormFieldsProps {
  columns: ColumnOption[];
  projectOptions: SelectOption[];
  issueTypeOptions: SelectOption[];
  formFields: JiraTaskFormFields;
  handleFormFieldChange: (
    evt:
      | SelectChangeEvent
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setFormFields: React.Dispatch<React.SetStateAction<JiraTaskFormFields>>;
  selectedIssues: TaskIssue[];
  selectedDevToolsIssues: DevtoolsSolution[];
  handleNextStep: () => void;
}

export const StepOneFormFields = (props: StepOneFormFieldsProps) => {
  const {
    columns,
    projectOptions,
    issueTypeOptions,
    formFields,
    handleFormFieldChange,
    setFormFields,
    selectedIssues,
    selectedDevToolsIssues,
    handleNextStep,
  } = props;
  const user = useSelector((state: RootState) => state.user.user);

  return (
    <div>
      <Section>
        <HeaderFieldLabel>Created by {user.email}</HeaderFieldLabel>
        <HeaderFieldsWrapper>
          <SelectWrapper style={{ marginRight: '15px' }}>
            <CustomFormControl sx={{ width: '100%' }} size="small">
              <InputLabel className="input-label" id="select-projectId">
                Projects
              </InputLabel>
              <Select
                className="select-label"
                labelId="select-projectId"
                id="select-projectId"
                value={formFields.projectId}
                name="projectId"
                label="Projects"
                required
                onChange={handleFormFieldChange}
              >
                {projectOptions.map(({ label, value: optionValue }) => (
                  <MenuItem
                    key={`${label}-${formFields.projectId}`}
                    value={optionValue}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </CustomFormControl>
          </SelectWrapper>
          <SelectWrapper>
            <CustomFormControl sx={{ width: '100%' }} size="small">
              <InputLabel className="input-label" id="select-issue-type">
                Issue type
              </InputLabel>
              <Select
                className="select-label"
                labelId="select-issue-type"
                id="select-issue-type"
                value={formFields.projectIssueTypeId}
                name="projectIssueTypeId"
                label="projectIssueTypeId"
                disabled={formFields.projectId === ''}
                required
                onChange={handleFormFieldChange}
              >
                {issueTypeOptions.length > 0 ? (
                  issueTypeOptions.map(({ label, value: optionValue }) => (
                    <MenuItem
                      key={`${label}-${formFields.projectIssueTypeId}`}
                      value={optionValue}
                    >
                      {label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Issue Type</MenuItem>
                )}
              </Select>
            </CustomFormControl>
          </SelectWrapper>
        </HeaderFieldsWrapper>
        <FormFieldWrapper>
          <FormFieldLabel>
            Select the columns you wish to include in the tasks:
          </FormFieldLabel>
          <Autocomplete
            multiple
            id="columns"
            key="columns"
            options={columns}
            getOptionLabel={(option: ColumnOption) => option.label}
            disableCloseOnSelect
            value={formFields.columns}
            onChange={(event, newValue) => {
              setFormFields({ ...formFields, columns: newValue });
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select columns" />
            )}
            sx={{
              marginBottom: '25px',
              '& .MuiChip-label': {
                paddingLeft: '10px',
                paddingRight: '10px',
              },
              '& .MuiAutocomplete-tag': {
                margin: '1px 1px',
                fontSize: '12px',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                border: 'none',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
            }}
            renderTags={(value, getTagProps) =>
              value.map((columnOption: ColumnOption, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    variant="outlined"
                    label={columnOption.label}
                    key={key}
                    {...tagProps}
                  />
                );
              })
            }
            renderOption={(renderOptionProps, option, { selected }) => (
              <li {...renderOptionProps}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
          />
        </FormFieldWrapper>
        {selectedDevToolsIssues.length > 1 && (
          <FormControl component="fieldset">
            <CheckBoxGroupWrapper
              value={formFields.issueCreationType}
              name="issueCreationType"
              onChange={handleFormFieldChange}
            >
              <CustomFormControlLabel
                value="create_issues"
                control={
                  <CustomCheckBox
                    icon={<CustomCheckBoxOutlineBlankIcon />}
                    checkedIcon={
                      <CustomCheckBoxIcon sx={{ width: 20, height: 20 }} />
                    }
                  />
                }
                label={`Create ${selectedDevToolsIssues.length} issues`}
              />
              <CustomFormControlLabel
                value="group_issues"
                control={
                  <CustomCheckBox
                    icon={<CustomCheckBoxOutlineBlankIcon />}
                    checkedIcon={
                      <CustomCheckBoxIcon sx={{ width: 20, height: 20 }} />
                    }
                  />
                }
                label={`Group (${selectedDevToolsIssues.length}) issues in 1 task`}
              />
            </CheckBoxGroupWrapper>
          </FormControl>
        )}
        <IssuesHeader>Issues</IssuesHeader>
        <JiraIssues
          selectedIssues={selectedIssues}
          selectedDevToolsIssues={selectedDevToolsIssues}
        />
      </Section>
      <ButtonWrapper>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={handleNextStep}
        >
          Next
        </Button>
      </ButtonWrapper>
    </div>
  );
};

const FormFieldWrapper = styled.div``;

const HeaderFieldsWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 10px;
`;

const HeaderFieldLabel = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: block;
  word-break: break-all;
`;

const Section = styled.div`
  height: 50vh;
  overflow: hidden scroll;
  padding: 0 41px;
`;

const IssuesHeader = styled(Typography)`
  font-weight: 500;
`;

const SelectWrapper = styled.div`
  width: 31%;
`;

const CustomFormControl = MuiStyled(FormControl)(() => ({
  [`&.${selectClasses.outlined}`]: {
    borderColor: '#454284',
    borderWidth: '1.5px',
  },
  '& fieldset': {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  '& .input-label, & .select-label': {
    fontSize: '13px',
  },
}));

const FormFieldLabel = styled(Typography)`
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  line-height: 14px;
  font-weight: 500;
  align-items: center;
  color: #000000;
  margin-bottom: 15px;
`;

const CheckBoxGroupWrapper = MuiStyled(RadioGroup)(() => ({
  [`&.MuiRadioGroup-root`]: {
    flexDirection: 'row',
  },
}));

const CustomFormControlLabel = MuiStyled(FormControlLabel)(() => ({
  [`& .MuiFormControlLabel-label`]: {
    fontSize: 14,
    fontWeight: 500,
    color: '#000000',
  },
}));

const CustomCheckBox = MuiStyled(Radio)(() => ({
  [`&.MuiRadio-root`]: {
    paddingRight: 4,
  },
}));

const CustomCheckBoxOutlineBlankIcon = MuiStyled(CheckBoxOutlineBlankIcon)(
  () => ({
    [`&.MuiSvgIcon-root`]: {
      width: 20,
      height: 20,
    },
  }),
);

const CustomCheckBoxIcon = MuiStyled(CheckBoxIcon)(() => ({
  [`&.MuiSvgIcon-root`]: {
    width: 20,
    height: 20,
  },
}));

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  justify-content: flex-end;
`;

const Button = styled(PrimaryButton)`
  width: 100px;
  border-radius: 12px;
`;
