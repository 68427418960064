import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React from 'react';
import { MainText, SubText, TableRow } from './table';
import { DocumentLink } from '../document-link';
import { ComplianceDocument } from '@equally-ai-front/common/src/types/compliance-document';
import moment from 'moment';

interface DocumentTemplateRowProps {
  complianceDocument: ComplianceDocument;
  onTemplateClick: (templateId: number, type: string) => void;
}

export const DocumentTemplateRow = (props: DocumentTemplateRowProps) => {
  const { complianceDocument, onTemplateClick } = props;

  const handleClick = () => {
    onTemplateClick(complianceDocument.template_id, complianceDocument.type);
  };

  return (
    <TableRow>
      <Box display="flex" flexDirection="column" gap="5px">
        <DocTypeAndLastModified>
          <ChipContainer>
            <Chip>{complianceDocument.type}</Chip>
          </ChipContainer>
          <SubText fontSize="12px">
            {`Last modified: ${moment(complianceDocument.updated_at).format('DD/MM/YYYY')}`}
          </SubText>
        </DocTypeAndLastModified>
        <MainText fontSize="20px">{complianceDocument.title}</MainText>
        <SubText fontSize="14px">{complianceDocument.description}</SubText>
      </Box>
      <Box>
        <DocumentLink link={complianceDocument.link} onClick={handleClick} />
      </Box>
    </TableRow>
  );
};

const DocTypeAndLastModified = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ChipContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Chip = styled(Box)`
  background-color: #f7fafc;
  color: #1e293b;
  border-radius: 10px;
  width: fit-content;
  height: 20px;
  font-size: 12px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: capitalize;
`;
