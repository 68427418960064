import { takeLatest, put, delay, call, all } from 'redux-saga/effects';
import { settingsActions } from './index';
import { DevDomain, FetchScanSettingsArg } from './types';
import { ResponseGenerator, apiCall } from '../../utils/axios';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchScanSettings(data: PayloadAction<FetchScanSettingsArg>) {
  const { websiteId } = data.payload;
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') ?? '';
    const response: ResponseGenerator = yield call(
      apiCall,
      'GET',
      `/api/v1/admin/a/audit-settings?website_id=${websiteId}`,
      {},
      token,
      '',
    );

    if (response.data?.code === 200) {
      yield put(
        settingsActions.getScanSettingsSuccessful(response.data?.message),
      );
    } else {
      yield put(settingsActions.setError(response.data?.message));
    }
    yield put(settingsActions.setLoading(false));
  } catch (error: any) {
    yield put(
      settingsActions.setError(
        error?.message || 'An error occured while trying to get scan settings',
      ),
    );
    yield put(settingsActions.setLoading(false));
  }
}

function* createScanSettings(data: any) {
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') ?? '';
    const response: ResponseGenerator = yield call(
      apiCall,
      'POST',
      `/api/v1/admin/a/audit-settings/create`,
      data.payload,
      token,
      '',
    );

    if (response.data?.code === 200) {
      yield put(
        settingsActions.getScanSettings({ websiteId: data.payload.website_id }),
      );
      yield put(
        settingsActions.createScanSettingsSuccessful(response.data?.message),
      );
    } else {
      yield put(settingsActions.setError(response.data?.message));
    }
    yield put(settingsActions.setLoading(false));
  } catch (error: any) {
    yield put(
      settingsActions.setError(
        error?.message ||
          'An error occured while trying to create scan setting.',
      ),
    );
    yield put(settingsActions.setLoading(false));
  }
}

function* updateScanSettings(data: any) {
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') ?? '';
    const response: ResponseGenerator = yield call(
      apiCall,
      'POST',
      `/api/v1/admin/a/audit-settings/update`,
      data.payload,
      token,
      '',
    );

    if (response.data?.code === 200) {
      yield put(
        settingsActions.getScanSettings({ websiteId: data.payload.website_id }),
      );
      yield put(
        settingsActions.updateScanSettingsSuccessful(response.data?.message),
      );
    } else {
      yield put(settingsActions.setError(response.data?.message));
    }
    yield put(settingsActions.setLoading(false));
  } catch (error: any) {
    yield put(
      settingsActions.setError(
        error?.message ||
          'An error occured while trying to update scan setting.',
      ),
    );
    yield put(settingsActions.setLoading(false));
  }
}

function* registerScan(data: any) {
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') ?? '';
    yield call(
      apiCall,
      'POST',
      `/api/v1/admin/a/compliance`,
      data.payload,
      token,
      '',
    );
  } catch (error: any) {
    console.error(error);
    yield put(
      settingsActions.setError(
        error?.message || 'An error occured while trying to register scan.',
      ),
    );
    yield put(settingsActions.setLoading(false));
  }
}

function* fetchDevDomains() {
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') ?? '';
    const websiteDomain = localStorage.getItem('websiteDomain') ?? '';
    const response: ResponseGenerator = yield call(
      apiCall,
      'GET',
      `/api/v1/admin/a/devdomains`,
      {},
      token,
      '',
    );

    if (response.data?.code === 200) {
      const devDomain = response.data?.message?.filter((domain: DevDomain) =>
        domain.url.includes(websiteDomain),
      )[0];

      if (devDomain) {
        yield put(settingsActions.setDevDomain(devDomain));
      } else {
        yield put(
          settingsActions.setError(
            `You are unauthorized to access this domain: ${websiteDomain}`,
          ),
        );
      }
    } else {
      yield put(settingsActions.setError(response.data?.message));
    }
    yield put(settingsActions.setLoadingDevDomain(false));
  } catch (error: any) {
    yield put(
      settingsActions.setError(
        error?.message ||
          'An error occured while trying to get general settings',
      ),
    );
    yield put(settingsActions.setLoadingDevDomain(false));
  }
}

function* updateDevDomain(data: any) {
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') ?? '';
    const response: ResponseGenerator = yield call(
      apiCall,
      'POST',
      `/api/v1/admin/a/devdomain/update`,
      data.payload,
      token,
      '',
    );

    if (response.data?.code === 200) {
      yield put(
        settingsActions.createScanSettingsSuccessful(response.data?.message),
      );
    } else {
      yield put(settingsActions.setError(response.data?.message));
    }
    yield put(settingsActions.setLoadingDevDomain(false));
  } catch (error: any) {
    yield put(
      settingsActions.setError(
        error?.message ||
          'An error occured while trying to update the general setting.',
      ),
    );
    yield put(settingsActions.setLoadingDevDomain(false));
  }
}

export default function* settingsSaga() {
  yield all([
    takeLatest(settingsActions.getScanSettings.type, fetchScanSettings),
    takeLatest(settingsActions.createScanSettings.type, createScanSettings),
    takeLatest(settingsActions.updateScanSettings.type, updateScanSettings),
    takeLatest(settingsActions.registerScan.type, registerScan),
    takeLatest(settingsActions.getDevDomains.type, fetchDevDomains),
    takeLatest(settingsActions.updateDevDomain.type, updateDevDomain),
  ]);
}
