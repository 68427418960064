import { Box, TableContainer, Typography } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { ComplianceDocument } from '@equally-ai-front/common/src/types/compliance-document';

interface TableProps {
  complianceDocuments: ComplianceDocument[];
  children: React.ReactNode;
}

export const Table = (props: TableProps) => {
  const { complianceDocuments, children } = props;

  return (
    <Wrapper>
      {complianceDocuments.length > 0 ? (
        <>{children}</>
      ) : (
        <TableRow>
          <SubText textAlign="center" width="100%">
            No document found
          </SubText>
        </TableRow>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(TableContainer)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TableRow = styled(Box)`
  border: 1px solid #e8edf3;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const MainText = styled(Typography)`
  color: #1e293b;
  font-weight: 600;
`;

export const SubText = styled(Typography)`
  color: #64748b;
`;
