import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { ScanSettingSteps } from '@equally-ai-front/common/src/types/audit-settings';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ScanStep } from './scan-step';

interface ScanStepsProps {
  steps: ScanSettingSteps[];
  setSteps: React.Dispatch<React.SetStateAction<ScanSettingSteps[]>>;
  handleAddNewStep: (
    isAuthenticationStep: boolean,
    handleAccordionToggle: (accordionId: string) => void,
  ) => void;
  isAuthStepsModified: boolean;
  isAppStepsModified: boolean;
  saveScanActions: () => Promise<void>;
  scanStepSaved: boolean;
  setScanStepSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScanSteps = (props: ScanStepsProps) => {
  const {
    steps,
    setSteps,
    handleAddNewStep,
    isAuthStepsModified,
    isAppStepsModified,
    saveScanActions,
    scanStepSaved,
    setScanStepSaved,
  } = props;
  const { loading } = useSelector((state: any) => ({
    auditSettings: state.auditSettings.auditSettings,
    loading: state.auditSettings.loading,
  }));

  return (
    <Wrapper>
      <ScanStep
        steps={steps}
        setSteps={setSteps}
        handleAddNewStep={handleAddNewStep}
        isAuthStepsModified={isAuthStepsModified}
        isAppStepsModified={isAppStepsModified}
        saveScanActions={saveScanActions}
        scanStepSaved={scanStepSaved}
        setScanStepSaved={setScanStepSaved}
      />
      <LoadingView open={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
