import React, { useMemo } from 'react';
import {
  FieldMap,
  GridRow,
} from '../../../../components/generic-grid/grid-row';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../../store';
import { isScanLoading } from '../../../../lib/scans';
import { Scan } from '@equally-ai-front/common/src/types/issues';
import { sortScans } from '@equally-ai-front/common/src/lib/scans';

interface ScanTableProps {
  data: Scan[];
  headingData: Record<string, string>;
  headingMap: FieldMap[];
  dataMap: FieldMap[];
  isGroupScanTable?: boolean;
}

export const ScansTable = ({
  data,
  dataMap,
  headingData,
  headingMap,
  isGroupScanTable,
}: ScanTableProps) => {
  const navigate = useNavigate();
  const { scannedUrls } = useSelector((state: RootState) => ({
    scannedUrls: state.dashboard.scannedUrls,
  }));
  const [searchParams] = useSearchParams();
  const pageUrl = searchParams.get('page_url');
  const getPageResult = async (rowData: any) => {
    if (!pageUrl || isScanLoading(rowData)) {
      return;
    }
    navigate(`../issues/${rowData.id}`);
  };

  const visibleScans: any[] = useMemo(() => {
    if (isGroupScanTable) {
      return sortScans(data, 'desc');
    }
    const newVisibleScansMap: Record<string, Scan> = {};
    const key = pageUrl ? 'id' : 'url';
    for (const scan of data) {
      const currentVisibleScansMapKey = scan[key];
      // @ts-ignore
      newVisibleScansMap[currentVisibleScansMapKey] = scan;
    }

    for (const scannedUrl of scannedUrls) {
      const currentVisibleScansMapKey = scannedUrl[key];
      if (pageUrl && scannedUrl.url !== pageUrl) {
        // eslint-disable-next-line no-continue
        continue;
      }

      newVisibleScansMap[currentVisibleScansMapKey] = scannedUrl;
    }
    // @ts-ignore
    return sortScans(Object.values(newVisibleScansMap), 'desc');
  }, [data, scannedUrls, pageUrl, isGroupScanTable]);
  return (
    <>
      {visibleScans.length > 0 && (
        <TableWrapper>
          <GridRow
            rowData={headingData}
            rowMap={headingMap}
            variant="heading"
            rowPadding="10px"
            emptyRow={{
              xs: 3,
              sm: 3,
              md: 3,
            }}
          />
          <Box mt="5px" />
          {visibleScans.map((item) => {
            const scanItem = scannedUrls.find(
              ({ group_id }) => group_id === item.id,
            );
            return (
              <React.Fragment key={`div-${item.id}`}>
                <GridRow
                  key={item.id}
                  rowData={{ ...item }}
                  rowMap={dataMap}
                  rowPadding="10px"
                  variant="dashboard-sub-heading"
                  isLoading={isScanLoading(scanItem || item)}
                  handleRowClick={() =>
                    getPageResult({ ...item, url: item.domain || item.url })
                  }
                />
                <Box mt="5px" />
              </React.Fragment>
            );
          })}
        </TableWrapper>
      )}
    </>
  );
};

const TableWrapper = styled.div``;
