import { ScanData } from '@equally-ai-front/common/src/types/scans';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { /* MouseEvent, */ useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
// import ActionsButton from '../../components/actions-button';
import { HeaderTitle } from '../../components/css-components';
import { MuiPopover, PopoverOption } from '../../components/mui-popover';

interface PerformanceSummaryProps {
  scanIssuesGroupingByElement: any[];
}

const getLastScan = (data: ScanData[]): ScanData | null => {
  if (data.length === 0) {
    return null;
  }

  const mostRecentScan = data.reduce((prev, current) => {
    const prevDate = new Date(prev.updatedAt);
    const currentDate = new Date(current.updatedAt);
    return currentDate > prevDate ? current : prev;
  });
  return mostRecentScan;
};

export const PerformanceSummary = ({
  scanIssuesGroupingByElement,
}: PerformanceSummaryProps) => {
  const { totalScansData } = useAppSelector((state) => state.overview);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // const handleOpenActionPopover = (event: MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleCloseActionPopover = () => {
    setAnchorEl(null);
  };

  const editWidget = () => {
    handleCloseActionPopover();
  };

  const deleteWidget = () => {
    handleCloseActionPopover();
  };

  const popoverOptions: PopoverOption[] = [
    {
      id: nanoid(),
      handleOptionClick: editWidget,
      children: <span>Edit Widget</span>,
    },
    {
      id: nanoid(),
      handleOptionClick: deleteWidget,
      children: <span>Delete Widget</span>,
    },
  ];

  const issuesGroupingByElementData = scanIssuesGroupingByElement.map(
    ([issuesElementGroupName, issues]) => ({
      name: issuesElementGroupName,
      uv: issues.length,
    }),
  );

  const lastScanData = useMemo(
    () => getLastScan(totalScansData?.scans || []),
    [totalScansData],
  );

  return (
    <Wrapper>
      {/* <HeaderWrapper>
        <CustomHeaderTitle>Type of Issues</CustomHeaderTitle>
        <ActionsButton handleClick={handleOpenActionPopover} />
        <MuiPopover
          anchorEl={anchorEl}
          handleClose={handleCloseActionPopover}
          options={popoverOptions}
          popoverWidth="150px"
        />
      </HeaderWrapper> */}
      <BarChartWrapper>
        <BarChart
          width={400}
          height={250}
          data={issuesGroupingByElementData}
          style={{ fontSize: 12 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="uv" fill="#454284" barSize={30} />
        </BarChart>
      </BarChartWrapper>
      {lastScanData && (
        <FooterSection>
          <FooterSectionText>
            Last Scan:
            {moment(lastScanData.updatedAt).format('DD MMM YYYY hh:mma')}
          </FooterSectionText>
          {/* <FooterSectionSubText>+28.11%</FooterSectionSubText> */}
        </FooterSection>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (pointer: none), (pointer: coarse) {
    width: 100%;
    margin-bottom: 30px;
    margin-top: -10px;
  }
`;

const BarChartWrapper = styled.div`
  margin-top: 10px;
  overflow: auto hidden;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
`;

const FooterSection = styled.div`
  display: flex;
  position: absolute;
  left: 20px;
  bottom: 10px;

  @media (pointer: none), (pointer: coarse) {
    bottom: 0;
  }
`;

const FooterSectionText = styled.p`
  color: #000a14;
  margin-right: 5px;
`;

// const FooterSectionSubText = styled.span`
//   font-weight: 600;
//   font-size: 16px;
//   color: #3bc17d;
// `;
