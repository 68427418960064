import React from 'react';
import { StatusTag } from './order-card/components/status-tag';

export interface Service {
  type: string;
  description: string;
  createdAt: string;
  status: string;
}

export type ServiceKey = keyof Service;

export const SERVICES_TYPES = [
  'docs',
  'pdf',
  'images',
  'video',
  'audio',
  'other',
  'flowy',
];

export const SERVICES_DESCRIPTION = {
  docs: 'Ensuring seamless readability of documents for everyone',
  flowy: 'Helping you finalize your flowy workflow',
  pdf: 'Promoting accessibility in PDF content for universal use.',
  images: 'Improving image comprehension with alt text and captions.',
  video: 'Integrating captions and sign language for video inclusivity.',
  audio: 'Incorporating transcripts and subtitles for audio files.',
  other: 'Customizable accessibility solutions for diverse digital content.',
};

export const SERVICES = {
  docs: {
    name: 'Document',
    description: 'Ensuring seamless readability of documents for everyone',
  },
  pdf: {
    name: 'PDF',
    description: 'Promoting accessibility in PDF content for universal use.',
  },
  images: {
    name: 'Image',
    description: 'Improving image comprehension with alt text and captions.',
  },
  video: {
    name: 'Video',
    description:
      'Integrating captions and sign language for video inclusivity.',
  },
  audio: {
    name: 'Audio',
    description: 'Incorporating transcripts and subtitles for audio files.',
  },
  other: {
    name: 'Other',
    description:
      'Customizable accessibility solutions for diverse digital content.',
  },
  flowy: {
    name: 'Flowy',
    description: 'Helping you finalize your flowy workflow.',
  },
};

export const serviceFields: Record<string, any> = {
  status: {
    key: 'status',
    fieldName: 'Status',
    getComponent: (props: any) => <StatusTag {...props} />,
  },
  type: {
    key: 'type',
    fieldName: 'Type',
  },
  description: {
    key: 'description',
    fieldName: 'Description',
  },
  createdAt: {
    key: 'created_at',
    fieldName: 'Create Date',
  },
};
