import { Service, ServicesTypesEnum } from '../types/services';
import { api as axios, ApiResponse } from '../utils/axios';
import {
  HTTP_GOOGLE_API,
  HTTP_FIREBASE_CONFIG_API_KEY,
} from '../utils/magicValues';

class ServicesAPI {
  static async getServices(
    types?: ServicesTypesEnum[],
    planIds?: number[],
  ): Promise<ApiResponse<Service[]>> {
    let result;
    let error;
    try {
      result = await axios.get('/api/v1/admin/a/services', {
        params: { types: types?.join(','), plans_ids: planIds?.join(',') },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (err) {
      console.error('Error getting services', err);
      error = err;
    }
    const { data } = result || {};
    return {
      data: (data?.message || []) as Service[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getNewGoogleAccessToken(
    refresh_token: string,
  ): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.post(
        `${HTTP_GOOGLE_API}/token?key=${HTTP_FIREBASE_CONFIG_API_KEY}`,
        { grant_type: 'refresh_token', refresh_token },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
    } catch (err) {
      console.error('Error getting refresh token', err);
      error = err;
    }
    const { data } = result || {};
    return {
      data: data,
      isSuccess: Object.keys(data).length > 0,
      error: error,
    };
  }
}

export default ServicesAPI;
