export const getURLDetails = (urlString) => {
  try {
    const url = new URL(urlString);
    return {
      hostname: url.hostname,
      pathname: url.pathname,
    };
  } catch {
    return {
      hostname: null,
      pathname: null,
    };
  }
};

export const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
