import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from '../../../../../common/magicValues';

const client = axios.create({
  baseURL: HTTP_CLIENT_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default client;
