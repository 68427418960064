import { settingsActions } from '@equally-ai-front/common/src/redux/settings-slice';
import { ScanSetting } from '@equally-ai-front/common/src/redux/settings-slice/types';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { InputDiv, Label, TabPanel } from './styled-components';

interface ScanSettingsProps {
  pageSettings: ScanSetting;
  setPageSettings: Dispatch<SetStateAction<ScanSetting>>;
  devDomain: DevDomain;
}

export const ScanSettings = ({
  pageSettings,
  setPageSettings,
  devDomain,
}: ScanSettingsProps) => {
  const dispatch = useDispatch();

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;

    setPageSettings({
      ...pageSettings,
      [name]: value,
    });
  };

  const saveScanSetting = () => {
    const dataToSend = {
      id: pageSettings.id,
      url: pageSettings.url,
      actionsDescription: pageSettings.actionsDescription,
      byPass: pageSettings.byPass,
      customHeaders: pageSettings.customHeader,
      userAgents: pageSettings.userAgent,
      username: pageSettings.username,
      password: pageSettings.password,
      website_id: devDomain.id,
    };

    if (pageSettings.id === 0) {
      dispatch(settingsActions.createScanSettings(dataToSend));
    } else {
      dispatch(settingsActions.updateScanSettings(dataToSend));
    }
  };

  return (
    <TabPanel
      role="tabpanel"
      id="tab2"
      aria-labelledby="scan-settings-tab"
      tabIndex={0}
    >
      <InfoContent>
        You can set custom parameters for our scanner to use when assessing your
        website. This is helpful if you work in an environment that isn’t
        publicly available to everyone.
      </InfoContent>
      <form onSubmit={(evt) => evt.preventDefault()}>
        <FormInputDiv>
          <InputDiv>
            <Label htmlFor="userAgent">User Agent</Label>
            <input
              aria-label="user agent field"
              id="userAgent"
              type="text"
              name="userAgent"
              value={pageSettings.userAgent}
              onChange={handleChange}
            />
          </InputDiv>
          <InputInfo>e.g. Mozilla/5.0 (compatible; Googlebot/2.1)</InputInfo>
        </FormInputDiv>
        <FormInputDiv>
          <InputDiv>
            <Label htmlFor="customHeader">Custom Header</Label>
            <input
              aria-label="custom header field"
              id="customHeader"
              type="text"
              name="customHeader"
              value={pageSettings.customHeader}
              onChange={handleChange}
            />
          </InputDiv>
          <InputInfo>e.g. header-key:header-value</InputInfo>
        </FormInputDiv>
        <FormInputDiv>
          <InputDiv>
            <Label htmlFor="authPromptName">Auth Prompt Username</Label>
            <input
              id="authPromptName"
              aria-label="Authentication prompt username field"
              type="text"
              name="username"
              value={pageSettings.username}
              onChange={handleChange}
            />
          </InputDiv>
          <InputInfo>For authorization:... header prompts</InputInfo>
        </FormInputDiv>
        <FormInputDiv>
          <InputDiv>
            <Label htmlFor="authPromptPw">Auth Prompt Password</Label>
            <input
              id="authPromptPw"
              aria-label="Authentication prompt password field"
              type="text"
              name="password"
              value={pageSettings.password}
              onChange={handleChange}
            />
          </InputDiv>
          <InputInfo>For authorization:... header prompts</InputInfo>
        </FormInputDiv>
        <SaveButton aria-label="save settings" onClick={saveScanSetting}>
          Save
        </SaveButton>
      </form>
    </TabPanel>
  );
};

const InfoContent = styled.p`
  font-weight: 300;
  font-size: 16px;
  color: rgba(0, 10, 20, 0.65);
  margin-bottom: 5%;
`;

const FormInputDiv = styled.div`
  margin-bottom: 7%;
`;

export const InputInfo = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: rgba(0, 10, 20, 0.65);
`;

const SaveButton = styled.button`
  border: none;
  color: #000a14;
  background-color: #4ad493;
  cursor: pointer;
  padding: 12px 24px;
  position: absolute;
  bottom: -70px;
  left: 0;
  border-radius: 10px;
  font-weight: 500;
`;
