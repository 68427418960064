import { PAYMENT_PROVIDER_ID } from '../types';

export const isExpired = (expirationDate: string) => {
  const checkDate = new Date(expirationDate).getTime();

  const currentDate = new Date().getTime();

  return checkDate < currentDate;
};

const isPlanActive = (plan: any) => {
  return !isExpired(plan.expiration) && plan.is_active;
};

export const isPlansAvailableWithCredits = (plansData: any[]) => {
  return (
    plansData.length > 0 &&
    plansData.filter((plan) => plan.quantity > 0 && isPlanActive(plan)).length >
      0
  );
};

export const availablePlans = (plansData: any[]) =>
  plansData.filter(
    (plan) =>
      !isExpired(plan.expiration) && plan.quantity > 0 && plan.is_active,
  );

export const isAttachPlanEligible = (plans: any[], type: string) => {
  return (
    plans.length > 0 &&
    plans.filter(
      (plan) =>
        isPlanActive(plan) && plan.quantity > 0 && plan.plan_type === type,
    ).length > 0
  );
};

export const isDetachPlanEligible = (plans: any[]) => {
  return (
    plans.length > 0 &&
    plans.filter((plan) => isPlanActive(plan) && plan.quantity >= 0).length > 0
  );
};

export const findAvailablePlan = (plans: any[], productType: string) => {
  const plan = plans.find(
    (planData: any) =>
      planData.is_active &&
      planData.quantity > 0 &&
      planData.plan_type === productType &&
      !isExpired(planData.expiration),
  );

  return plan;
};

export const showPlans = (plans: any[]) =>
  plans.every(
    (plan) => isExpired(plan.expiration) || isPayPalPlan(plan.provider_id),
  );
export const isAnyStripePlan = (plans: any[]) => {
  return plans.some((plan) => isStripePlan(plan.provider_id));
};

export const isPayPalPlan = (providerId: number) =>
  providerId === PAYMENT_PROVIDER_ID.PAYPAL;

export const isStripePlan = (providerId: number) =>
  providerId === PAYMENT_PROVIDER_ID.STRIPE;

export const isDomainWithoutPlan = (domain: any) => {
  return (
    domain.plan_id === 0 ||
    domain.plan_id === null ||
    domain.plan_id === undefined
  );
};
