import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '../hooks/useQuery';
import { getActiveSubs, setToken } from '../store/actions';
import { Compliance } from './compliance/Compliance';
import { TabInfo } from './TabInfo';

import { QUERY_PARAM_REDIRECT_TOKEN } from '../common/magicValues';

export const Home = () => {
  const dispatch = useDispatch();
  const redirectToken = useQuery().get(QUERY_PARAM_REDIRECT_TOKEN);

  useEffect(() => {
    if (redirectToken) {
      dispatch(setToken(redirectToken));
    } else {
      dispatch(getActiveSubs());
    }
  }, [dispatch, redirectToken]);

  return (
    <>
      <TabInfo
        title="Equally AI dashboard"
        description="Validate website for AODA, ADA, Section 508, WCAG 2.1 & Compliance"
      />
      <Compliance />
    </>
  );
};
