import { styled as MuiStyled } from '@mui/material/styles';
import { Box } from '@mui/material';
import React from 'react';

interface PageContentContainerProps {
  children: React.ReactNode;
}

export const PageContentContainer = (props: PageContentContainerProps) => {
  const { children } = props;

  return (
    <OutterContainer>
      <InnerContainer>{children}</InnerContainer>
    </OutterContainer>
  );
};

const OutterContainer = MuiStyled(Box)(() => ({
  width: '100%',
  height: '100%',
}));

const InnerContainer = MuiStyled(Box)(() => ({
  width: '100%',
  padding: 0,
  maxWidth: 'calc(100% - 82px)',
  marginLeft: '41px',
}));
