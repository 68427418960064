import styled from '@emotion/styled';
import React from 'react';
// import { useSelector } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';
import { PasswordReset, PersonalDetails } from '../components';

export const SettingsRedirect = () => {
  return (
    <PageContainer>
      <Sidebar />
      <Routes>
        <Route index element={<PersonalDetails />} />
        <Route path={`password-reset`} element={<PasswordReset />} />
      </Routes>
    </PageContainer>
  );
};
const SidebarWrapper = styled.div`
  width: 250px;
  height: 100%;
  background: white;
  padding: 20px;
  margin: 0 20px;
  color: black;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
`;
const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const SidebarList = styled.ul`
  list-style: none;
  padding: 0;
`;
const SidebarListItem = styled.li`
  margin-bottom: 20px;
`;
const SidebarLink = styled(Link)`
  text-decoration: none;
`;
const Sidebar = () => {
  return (
    <SidebarWrapper>
      <SidebarList>
        <SidebarListItem>
          {' '}
          <SidebarLink
            to="/settings"
            className="header-navigation-popover-item"
          >
            Personal Details
          </SidebarLink>
        </SidebarListItem>
        <SidebarListItem>
          <SidebarLink
            to="/on-boarding"
            className="header-navigation-popover-item"
          >
            Create new business
          </SidebarLink>
        </SidebarListItem>
        <SidebarListItem>
          <SidebarLink
            to="/settings/password-reset"
            className="header-navigation-popover-item"
          >
            Password Reset
          </SidebarLink>
        </SidebarListItem>
        {/* <SidebarListItem> */}
        {/*  {currentBusiness && ( */}
        {/*    <SidebarLink */}
        {/*      to={`../${currentBusiness.slug}/team`} */}
        {/*      className="header-navigation-popover-item" */}
        {/*    > */}
        {/*      Teams */}
        {/*    </SidebarLink> */}
        {/*  )} */}
        {/* </SidebarListItem> */}
      </SidebarList>
    </SidebarWrapper>
  );
};
export default Sidebar;
