import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Issue } from '@equally-ai-front/common/src/types/issues';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import theme from '../../../common/materialTheme';
import AuditView from './AuditView';
import { Box } from '@mui/material';

interface AuditReportProps {
  handleAudit: (
    url: string,
    cb?: (url: string, issues: Issue[]) => void,
  ) => void;
  loading: boolean;
  report: ReportData;
}
const AuditReport = (props: AuditReportProps) => {
  const { handleAudit, loading, report } = props;
  return (
    <Container>
      <AuditView handleAudit={handleAudit} loading={loading} report={report} />
    </Container>
  );
};

export default AuditReport;

/** Styled Components */

const Container = styled(Box)`
  padding-bottom: 64px;
  ${({ theme: any }) => theme.breakpoints.up('md')} {
    padding-bottom: 96px;
  }
`;
