import { takeLatest, put, all, call } from 'redux-saga/effects';
import { authActions } from './index';
import { ResponseGenerator, apiCall } from '../../utils/axios';
import { API_PERSONAL_DETAILS } from '../../utils/magicValues';

function* fetchPersonalDetails(data: { payload: string; type: string }) {
  try {
    const response: ResponseGenerator = yield call(
      apiCall,
      'GET',
      API_PERSONAL_DETAILS,
      {},
      data.payload,
      '',
    );
    if (response.data?.code === 200) {
      yield put(authActions.setPersonalDetails(response.data?.message));
    } else {
      yield put(authActions.setError(response.data?.message));
    }
    yield put(authActions.setLoading(false));
  } catch (error: any) {
    yield put(authActions.setError(error?.message || 'Validation error.'));
    yield put(authActions.setLoading(false));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(authActions.getPersonalDetails.type, fetchPersonalDetails),
  ]);
}
