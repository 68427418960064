import styled from '@emotion/styled';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import {
  createBusiness,
  currentBusinessUpdated,
} from '@equally-ai-front/common/src/redux/business-slice/business';
import {
  Business,
  NewBusiness,
  NewMember,
} from '@equally-ai-front/common/src/types/business';
import {
  Checkbox,
  CircularProgress,
  Autocomplete,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setApiSuccessMessage } from '../../store/actions';
import { formValidator } from '../../utils/form.utils';
import { GenericInput } from '../accessibility-components/generic-input';
import { TypographyH2, TypographyH3 } from '../common/styled';
import { PrimaryButton } from '../redesign/Controls';
import { DropdownSelect } from './components/dropdown-select';
import { newBusinessFormFields, newMemberFormFields } from './consts';
import { getPersonalDetails } from '../../common/helpers';
import { setPersonalDetails } from '../../store/actions';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
      width: '100%',
      background: 'rgba(245, 247, 250, 0.7)',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  select: {
    marginRight: '16px',
  },
}));
const roles = [
  {
    value: 'Marketer',
    label: 'Marketer',
  },
  {
    value: 'Entrepreneur',
    label: 'Entrepreneur',
  },
  {
    value: 'Web Designer',
    label: 'Web Designer',
  },
  {
    value: 'Product Designer',
    label: 'Product Designer',
  },
  {
    value: 'Developer',
    label: 'Developer',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
const sizesMap = [
  {
    value: 10,
    label: '1-10 employees',
  },
  {
    value: 50,
    label: '11-50 employees',
  },
  {
    value: 200,
    label: '51-200 employees',
  },
  {
    value: 500,
    label: '201-500 employees',
  },
  {
    value: 1000,
    label: '501-1000 employees',
  },
  {
    value: 5000,
    label: '1001-5000 employees',
  },
  {
    value: 10000,
    label: '5001-10,000 employees',
  },
];

interface BusinessFormProps {}

const extractUpperBoundAndConvertToNumber = (value: string) => {
  let size = 0;
  if (value?.includes('-')) {
    size = Number(value?.split('-')[1].replaceAll(',', ''));
  } else {
    size = Number(value?.split('+')[0].replaceAll(',', ''));
  }
  return size;
};

const NEW_BUSINESS_INITIAL_STATE = {
  name: '',
  size: 0,
  isPartner: false,
  autoAssignDomain: false,
};

export const BusinessForm = (props: BusinessFormProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const business = useSelector((state: any) => state.business);
  const { loading } = business;
  const analytics = useAnalytics();
  const [newBusiness, setNewBusiness] = useState<NewBusiness>(
    NEW_BUSINESS_INITIAL_STATE,
  );
  const [createBusinessLoading, setCreateBusinessLoading] = useState(false);
  const [memeberRole, setMemberRole] = useState({ value: '', label: '' });
  const [companySize, setCompanySize] = useState({ value: '', label: '' });
  const [formErrors, setFormErrors] = useState({
    name: '',
    role: '',
    size: '',
  });
  const handleBusinessChange = (evt: any) => {
    const { name, value } = evt.target;
    if (name === 'size') {
      setNewBusiness({
        ...newBusiness,
        [name]: value,
      });
    } else {
      setNewBusiness({
        ...newBusiness,
        [name]: value,
      });
    }
  };

  const onInputBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const element: HTMLInputElement = evt.target;
    const inputName = element.name;
    const inputValue = element.value;

    if (!inputValue) {
      setFormErrors({ ...formErrors, [inputName]: '*Required' });
      return;
    }

    setFormErrors({ ...formErrors, [inputName]: '' });
  };

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    const newBussinessValue = {
      ...newBusiness,
      size: companySize.value,
    };

    const newMember = { name: user.name, role: memeberRole.value };

    const businessErrors = formValidator.validate(
      newBusinessFormFields,
      newBussinessValue,
    );
    const memberErrors = formValidator.validate(newMemberFormFields, newMember);

    if (
      Object.keys(businessErrors).length > 0 ||
      Object.keys(memberErrors).length > 0
    ) {
      setFormErrors({ ...formErrors, ...memberErrors, ...businessErrors });
      return;
    }

    setCreateBusinessLoading(true);
    const createNewBusiness = async () => {
      const {
        payload: { data: newCreatedBusiness, isSuccess, error },
      } = await dispatch(
        // @ts-ignore
        createBusiness({
          // @ts-ignore
          newBusiness: newBussinessValue,
          newMember,
        }),
      );

      if (!isSuccess) {
        setCreateBusinessLoading(false);
        return;
      }

      analytics.track('New Business Created', {
        id: newCreatedBusiness.id,
        name: newCreatedBusiness.name,
        slug: newCreatedBusiness.slug,
        is_special_domain: personalDetails.is_special_domain,
        is_test_account: personalDetails.is_test_account,
        is_partner: newCreatedBusiness.is_partner,
        by_user_id: user.id,
      });
      analytics.group(newCreatedBusiness.id, {
        group_id: newCreatedBusiness.id,
        business_id: newCreatedBusiness.id,
        name: newCreatedBusiness.name,
        special_domain: newCreatedBusiness.special_domain,
        is_special_domain: newCreatedBusiness.is_special_domain,
        is_test_account: newCreatedBusiness.is_test_account,
        is_partner: newCreatedBusiness.is_partner,
        user_id: user.id,
        user_email: personalDetails?.email,
      });
      const accessToken = localStorage.getItem('accessToken');
      // update personal details
      if (accessToken) {
        const personalDetailsResponse = await getPersonalDetails(accessToken);
        dispatch(setPersonalDetails(personalDetailsResponse.message));
      }

      dispatch(currentBusinessUpdated(newCreatedBusiness));
      navigate(`/${newCreatedBusiness.slug}/?from_onboarding=true`);
      dispatch(setApiSuccessMessage('Business created successfully'));
      setCreateBusinessLoading(false);
    };
    void createNewBusiness();
  };

  if (loading || createBusinessLoading) {
    return (
      <div className="add-website-progress">
        <CircularProgress size={100} />
      </div>
    );
  }
  return (
    <FormWrapper>
      <FormInputDiv customMt={personalDetails.isSpecialDomain} fixedHeight>
        <InputDiv>
          <InputLabel htmlFor="outlined-selectSubject-native-simple">
            Role
          </InputLabel>
          <Autocomplete
            options={roles}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                aria-label="Role"
                variant="outlined"
                name="role"
                onBlur={onInputBlur}
                aria-invalid={formErrors.role ? 'false' : 'true'}
                aria-errormessage="role-error"
                helperText={formErrors.role ? 'Role is required' : ''}
                error={formErrors.role !== ''}
                FormHelperTextProps={{ id: 'role-error' }}
              />
            )}
            onChange={(event: any, newValue: any) => {
              setMemberRole(newValue);
            }}
            value={memeberRole}
          />
        </InputDiv>
      </FormInputDiv>
      <FormInputDiv fixedHeight>
        <InputDiv>
          <InputLabel>Business Name</InputLabel>
          <GenericInput
            value={newBusiness.name}
            name="name"
            className={classes.input}
            onChange={handleBusinessChange}
            ariaProps={{
              'aria-label': 'Business Name',
              'aria-invalid': formErrors.name ? 'false' : 'true',
              'aria-errormessage': 'business-name-error',
            }}
            required
            onBlur={onInputBlur}
            helperText={formErrors.name ? 'Business name is required' : ''}
            error={formErrors.name !== ''}
          />
        </InputDiv>
      </FormInputDiv>
      <FormInputDiv customMb fixedHeight>
        <InputDiv>
          <InputLabel htmlFor="outlined-selectSubject-native-simple">
            Company Size
          </InputLabel>
          <Autocomplete
            options={sizesMap}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                aria-label="Company Size"
                variant="outlined"
                name="size"
                onBlur={onInputBlur}
                aria-invalid={formErrors.size ? 'false' : 'true'}
                aria-errormessage="company-size-error"
                helperText={formErrors.size ? 'Company Size is required' : ''}
                error={formErrors.size !== ''}
                FormHelperTextProps={{ id: 'company-size-error' }}
              />
            )}
            onChange={(event: any, newValue: any) => {
              setCompanySize(newValue);
            }}
            value={companySize}
          />
        </InputDiv>
      </FormInputDiv>
      <ContinueDiv>
        <Continue color="primary" variant="contained" onClick={handleSubmit}>
          Continue
        </Continue>
      </ContinueDiv>
    </FormWrapper>
  );
};
const FormWrapper = styled.form`
  width: 100%;
`;
const FormInputDiv = styled.div<{
  customMargin?: boolean;
  customMb?: boolean;
  customMt?: boolean;
  fixedHeight?: boolean;
}>`
  display: flex;
  align-items: center;
  margin: ${(props) => (props.customMargin ? '1vh 0' : '5px 0')};
  margin-bottom: ${(props) => (props.customMb ? '1vh' : '5px')};

  height: ${(props) => (props.fixedHeight ? '98px' : 'auto')};
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;
const InputLabel = styled(TypographyH3)`
  color: #000a14;
  font-size: 14px;
`;
const CustomCheckBox = styled(Checkbox)`
  padding: 0;
  margin-right: 3px;
`;

const CheckBoxLabel = styled.span``;

const ContinueDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;
const Continue = styled(PrimaryButton)`
  width: 100%;
`;
