import { DismissIssuePayload } from '@equally-ai-front/common/src/redux/developer-slice/types';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import axios from 'axios';

export interface FetchTotalScansPayload {
  businessId: number;
  scanIds: number[];
}
class IssuesAPI {
  static async getAllIssues(
    payload: FetchTotalScansPayload,
  ): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.get(
        `${process.env.REACT_APP_HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${
          payload.businessId
        }/scans/issues?scan_ids=${payload.scanIds.join(',')}`,
      );
    } catch (err) {
      const apiError: any = err;
      console.error('Error fetching issues', err);
      error = apiError;
    }
    const { data } = result || {};
    return {
      data: data?.message || [],
      isSuccess: data?.code === 200,
      error: error?.response?.data?.message || 'Error fetching all issues.',
    };
  }

  static async dismissIssue(
    payload: DismissIssuePayload,
  ): Promise<ApiResponse<any>> {
    const { businessId, issueId, selector, domainUrl } = payload;
    let result;

    try {
      result = await axios.post(
        `/api/v1/admin/a/${businessId}/compliance/dismissed-issues?issue_id=${issueId}`,
        {
          selector,
          domain_url: domainUrl,
          id: issueId,
        },
      );
    } catch (err) {
      console.error('Could not dismiss issue, please try again later.', err);
    }

    const { data } = result || {};
    return {
      data: data?.message || [],
      isSuccess: data?.code === 200,
      error:
        data?.code !== 200 &&
        'Could not dismiss issue, please try again later.',
    };
  }
}

export { IssuesAPI };
