import React from 'react';
import { FilterField } from '@equally-ai-front/common/src/components/filters/types';

export interface SelectFilterOption {
  label: string;
  value: string;
}

export const countryFilterOptions: SelectFilterOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'USA',
    value: 'USA',
  },
  {
    label: 'Italy',
    value: 'Italy',
  },
  {
    label: 'Israel',
    value: 'Israel',
  },
  {
    label: 'EU Member States',
    value: 'EU Member States',
  },
];

export const docTypeFilterOptions: SelectFilterOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Statement',
    value: 'statement',
  },
];

export const complianceDocumentsTableFilterFields: Record<string, FilterField> =
  {
    type: {
      key: 'type',
      displayName: 'Doc Type',
      filterType: 'string',
    },
    country: {
      key: 'country',
      displayName: 'Country',
      filterType: 'string',
    },
    domain: {
      key: 'domain',
      displayName: 'Domain',
      filterType: 'string',
    },
  };
