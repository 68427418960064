import { User } from '../contexts/AuthContext';

export enum AuthActionTypes {
  AUTHENTICATE = 'AUTHENTICATE',
  USER_DETAIL = 'USER_DETAIL',
  IS_ADMIN = 'IS_ADMIN',
}

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  isAdmin?: boolean;
}

export interface AuthAction {
  type: AuthActionTypes;
  payload: AuthState;
}
