import Popover from '@mui/material/Popover';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as FlagSvg } from '../assets/svg/flag.svg';

interface TableActionPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
}

export const TableActionPopover = (props: TableActionPopoverProps) => {
  const { anchorEl, handleClose } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'table-action-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <PopoverWrapper>
        <Option onClick={handleClose}>
          <FlagSvg />
          <span>Flag It</span>
        </Option>
      </PopoverWrapper>
    </Popover>
  );
};

const PopoverWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  width: 100px;
  padding: 10px 0;
`;

const Option = styled.div`
  width: 85%;
  margin: 5px auto;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f5f7fa;
  }
`;
