import React from 'react';
import styled from 'styled-components';

interface ScanReportLayoutProps {
  children: any[];
  emptyContent?: React.ReactNode;
}

export const ScanReportLayout = ({
  children,
  emptyContent,
}: ScanReportLayoutProps) => {
  return (
    <Wrapper>
      {children.length > 0
        ? children.map((child, idx) => (
            <div key={`scan-report-element-${idx}`}>{child}</div>
          ))
        : emptyContent || 'No content'}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 100px;
`;
