import { PersonalDetails } from '@equally-ai-front/common/src/redux/slice/types';
import { Business } from '@equally-ai-front/common/src/types/business';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from './useQuery';

interface UseBusinessRedirectProps {
  currentBusiness: Business;
  businessSlug: string | undefined;
  personalDetails: PersonalDetails;
}
const useBusinessRedirect = ({
  currentBusiness,
  businessSlug,
  personalDetails,
}: UseBusinessRedirectProps) => {
  const navigate = useNavigate();
  const { pageSlug } = useParams();
  const query = useQuery();
  const showBusinessForm = query.get('showBusinessForm');
  useEffect(() => {
    if (showBusinessForm) {
      return;
    }
    if (
      !currentBusiness ||
      currentBusiness?.slug !== businessSlug ||
      !personalDetails
    ) {
      return;
    }

    // const existingBusiness = businesses.find(
    //   (business: Business) => business.slug === businessSlug,
    // );
    // dispatch(currentBusinessUpdated(existingBusiness || businesses[0]));

    // if (!pageSlug) {
    // navigate(`/${businessSlug}`);
    // }
    // else {
    //   navigate(`/${businessSlug}/${pageSlug}`);
    // }
  }, [
    businessSlug,
    currentBusiness,
    personalDetails,
    pageSlug,
    navigate,
    showBusinessForm,
  ]);
};
export default useBusinessRedirect;
