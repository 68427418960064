import React, { useState, forwardRef } from 'react';

import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDialog } from '../../../components/redesign/FreeTrialModal/StartFreeTrialModal';
import { PrimaryButton } from '../../../components/redesign/Controls';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { SetupScan } from './setup-scan';

import { NewScanInfo } from '@equally-ai-front/common/src/types/issues';

interface CreateScanPopupProps {
  onAudit: (url: string, cb?: () => void, scanDetails?: NewScanInfo) => void;
  devDomain: DevDomain;
}

export const CreateScanButton = forwardRef<
  HTMLButtonElement,
  CreateScanPopupProps
>((props: CreateScanPopupProps, ref) => {
  const { onAudit, devDomain } = props;
  const [openPopUp, setOpenPopUp] = useState(false);

  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleOpenPopUp = () => {
    setOpenPopUp(true);
  };

  const handleClosePopUp = () => {
    setOpenPopUp(false);
  };

  return (
    <>
      <ScanButton
        ref={ref}
        color="primary"
        variant="contained"
        onClick={handleOpenPopUp}
      >
        Add new scan
      </ScanButton>
      <StyledDialog
        open={openPopUp}
        onClose={handleClosePopUp}
        fullScreen={isMobile}
      >
        <CloseButton
          aria-label="close"
          onClick={handleClosePopUp}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </CloseButton>

        <SetupScan
          handleClose={handleClosePopUp}
          devDomain={devDomain}
          handleAudit={onAudit}
        />
      </StyledDialog>
    </>
  );
});

const CloseButton = styled(IconButton)`
  &:focus {
    border: 2px solid #3e3c77;
  }
`;

const ScanButton = styled(PrimaryButton)`
  height: 43px;
`;
