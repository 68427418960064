import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/redesign/Controls';
import { ModalPanel } from '../../../components/redesign/ModalPanel';
import AddTeamMemberForm from '../../../components/redesign/Teams/add-team-member-form';
import { useAddTeamMember } from '../../../hooks/use-add-team-member';
import {
  HeaderContainer,
  HeadingTypography,
  StepContainer,
  StepContent,
  SubHeaderTypography,
} from './common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

interface TeamMemberStepProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const TeamMemberStep = (props: TeamMemberStepProps) => {
  const { isOpen, handleClose } = props;
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );

  const {
    handleAddMember,
    handleSubmit,
    newMembers,
    updateTeamMember,
    isEmailValid,
    isLoading,
  } = useAddTeamMember({ onComplete: handleClose, personalDetails });
  return (
    <ModalPanel
      title="Invite your team members"
      open={isOpen}
      handleClose={handleClose}
      modalWidth="600px"
      modalBodyStyle={{
        margin: '13px 0',
        padding: 0,
        height: '80vh',
      }}
    >
      {isLoading ? (
        <div className="add-website-progress">
          <CircularProgress size={100} />
        </div>
      ) : (
        <StyledTeamMemberContainer>
          <SubHeaderTypography>
            {`Collaborate with your team members by inviting them to your business.`}
          </SubHeaderTypography>
          <StepContent>
            <AddTeamMemberForm
              isEmailValid={isEmailValid}
              newMembers={newMembers}
              onNewMemberChange={updateTeamMember}
              onAddMemberRow={handleAddMember}
            />
          </StepContent>
          <ButtonsContainer>
            <PrimaryButton
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Invite
            </PrimaryButton>
          </ButtonsContainer>
        </StyledTeamMemberContainer>
      )}
    </ModalPanel>
  );
};

const StyledTeamMemberContainer = styled(StepContainer)`
  max-width: 550px;
  margin: auto;

  @media screen and (max-width: 600px) {
    max-width: 90%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 2vh 1vw;
  gap: 20px;
  justify-content: flex-end;
  width: 100%;

  & > button {
    padding: 12px 24px;
  }
`;
