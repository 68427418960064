import Popover from '@mui/material/Popover';
import { nanoid } from 'nanoid';
import React from 'react';
import styled from 'styled-components';

export interface PopoverOption {
  handleOptionClick: () => void;
  children: React.ReactNode;
  id: string;
}

interface MuiPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  options: PopoverOption[];
  popoverWidth: string;
}

export const MuiPopover = (props: MuiPopoverProps) => {
  const { anchorEl, handleClose, options, popoverWidth } = props;

  const open = Boolean(anchorEl);
  const id = open ? nanoid() : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <PopoverWrapper width={popoverWidth}>
        {options.map(({ id, handleOptionClick, children }) => (
          <Option key={id} onClick={handleOptionClick}>
            {children}
          </Option>
        ))}
      </PopoverWrapper>
    </Popover>
  );
};

const PopoverWrapper = styled.div<{ width: string }>`
  background-color: #ffffff;
  border-radius: 4px;
  width: ${(p) => p.width};
  padding: 10px;
`;

const Option = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
  height: fit-content;
  font-size: 15px;
  padding: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: #f5f7fa;
  }
`;
