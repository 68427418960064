import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';

class ExtensionVersioning {
  static async checkExtensionVersion(version: string) {
    let result;
    let error;

    try {
      result = await axios.get(
        HTTP_CLIENT_ENDPOINT +
          `/api/v1/admin/a/extension-status?extension_version=${version}`,
      );
    } catch (err) {
      console.error('Error fetching version', err);
      error = err;
    }

    const { data } = result || {};

    return {
      data: data?.data || null,
      isSuccess: data?.code === 200,
      error: error || 'Error fetching version.',
    };
  }
}

export default ExtensionVersioning;
