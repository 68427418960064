export enum ScanType {
  automated = 'Automated',
  manual = 'Manual',
}

export interface ScanData {
  id: number;
  userId: number;
  websiteId: number;
  url: string;
  notice: number;
  warning: number;
  error: number;
  createdAt: string;
  updatedAt: string;
  type: ScanType.automated | ScanType.manual;
}

export interface TotalScansData {
  scans: ScanData[];
  totalErrors: number;
  totalNotices: number;
  totalWarnings: number;
  totalScan: number;
}

export interface ScanTotals {
  totalErrors: number;
  totalNotices: number;
  totalWarnings: number;
}

export interface LatestDomainScansSummary {
  created_at: string;
  is_first_shown: boolean;
  plan_id: number | null;
  scan: ScansSummary | undefined;
}

export interface ScansSummary {
  url: string;
  previous_totals: {
    errors: number;
    warnings: number;
    notices: number;
  } | null;
  created_at: string;
  totals: {
    errors: number;
    warnings: number;
    notices: number;
  };
  updated_at: string;
  request_id: string;
}

export interface LatestScanTotals {
  currentTotals: ScanTotals;
  previousTotals: ScanTotals;
}

export interface AverageScoreAndTotals extends LatestScanTotals {
  accessibilityScoreAverage: number;
  previousAccessibilityScoreAverage: number;
}
