import { GET_ALL_INVITES_SUCCESS } from '../actionTypes';

const initialState = {
  invitations: [],
  team: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_INVITES_SUCCESS:
      return {
        ...state,
        invitations: action.value,
      };
    default:
      return state;
  }
}
