import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { QUERY_PARAM_SPECIAL_OFFER } from '../common/magicValues';
import { useQuery } from '../hooks/useQuery';
import { routes } from '../routes';
import { Notifications } from './Notifications';
import { EQUALLY_COOKIE_KEY, upsertCookie } from '../utils/cookie.utils';
const Main = () => {
  const router = useRoutes(routes);
  const user = useSelector((state) => state.user.user);

  const personalDetails = useSelector(
    (state) => state.personalDetails.personalDetails,
  );

  const analytics = useAnalytics();
  const specialCodeOfferParam = useQuery().get(QUERY_PARAM_SPECIAL_OFFER);
  const dispatch = useDispatch();

  const identifyUser = useCallback(() => {
    if (!user.preferred_username) {
      return;
    }
    analytics.identify(user.preferred_username, {
      email: user.email,
      name: user.name,
    });
    analytics.page({
      name: 'User Panel Main Page',
    });
  }, [user.preferred_username, user.email, user.name, analytics]);

  const newUserEvent = useCallback(() => {
    if (!personalDetails) {
      return;
    }

    if (personalDetails?.newAccount) {
      analytics.track('User Signed Up - FE', {
        user_id: personalDetails.id,
        is_special_domain: personalDetails.isSpecialDomain,
        is_test_user: personalDetails.testAccount,
        email: personalDetails.email,
        name: personalDetails.name,
      });
    }
  }, [analytics, personalDetails]);

  useEffect(() => {
    identifyUser();
  }, [identifyUser]);

  useEffect(() => {
    newUserEvent();
  }, [newUserEvent]);

  useEffect(() => {
    if (specialCodeOfferParam) {
      upsertCookie(
        EQUALLY_COOKIE_KEY,
        {
          special_code_offer: specialCodeOfferParam,
        },
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {!isMobile ? (
        <Header isAuthenticated={isAuthenticated} />
      ) : (
        <MobileNavigation isAuthenticated={isAuthenticated} />
      )} */}
      <main className="main">{router}</main>

      <Notifications />
    </>
  );
};

export default Main;
