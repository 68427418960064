import axios from 'axios';
import Cookies from 'js-cookie';
import { COGNITO_CLIENT_ID } from '../common/magicValues';
import { checkIfTokenHasExpired, parseJwt } from '@equally-ai/auth-service';
import { cognitoDir } from '@equally-ai/auth-service';
import qs from 'qs';

export function getAccessToken() {
  return Cookies.get('accessToken');
}

export function isAuthenticated() {
  const token = getAccessToken();

  if (!token || token.split('.').length !== 3) {
    return false;
  }

  return true;
}

function redirectToLogin() {
  const pathname = window.location.pathname;
  const authPaths = ['/login', '/logout', '/signup'];

  if (!authPaths.includes(pathname)) {
    window.location.assign('/logout');
  }
}

export const interceptors = {
  requestInterceptor: null,
  responseInterceptor: null,
  isIntercepting: false,
};

export function interceptRequestsAndResponses() {
  if (!interceptors.isIntercepting) {
    interceptors.requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        // Re-checking the authentication on every ajax request in case the token expired
        if (!isAuthenticated()) {
          redirectToLogin();
          throw new axios.Cancel('User is not permitted for this action');
        }

        // s3 requires only one way of authentication and its not using Authorization header,
        // so we must remove it when we upload thumbnails.
        if (
          config.method !== 'OPTIONS' &&
          !config.url.includes('s3.amazonaws.com') &&
          !config.url.includes('segment.io') &&
          !config.url.includes('graph.facebook.com') &&
          !config.url.includes('connect.facebook.net') &&
          !config.url.includes('/graphql')
        ) {
          let token = getAccessToken();
          const isExpired = checkIfTokenHasExpired(parseJwt(token).exp);

          if (isExpired) {
            const refreshToken = Cookies.get('refreshToken');
            const {
              idToken,
              isRefreshed,
              refreshToken: sessionRefreshToken,
            } = await cognitoDir.refreshSession(refreshToken);

            if (isRefreshed) {
              token = idToken;
              Cookies.set('accessToken', idToken);
              Cookies.set('refreshToken', sessionRefreshToken);
            }
          }
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            Authorization: `Bearer ${token}`,
            'equally-auth-provider': 'cognito',
            'equally-client-id': COGNITO_CLIENT_ID,
          };
          // eslint-disable-next-line no-param-reassign
          config.requestStartTime = Date.now();
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    interceptors.responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (axios.isCancel(error)) {
          return Promise.reject();
        }

        // if (error.response) {
        //   if (error.response.status === 403) {
        //     store.dispatch(handleUnAuthorized());
        //   }
        //   if (error.response.status === 401) {
        //     handleUnAuthenticated && store.dispatch(handleUnAuthenticated());
        //   }
        //   if (error.response.status === 500) {
        //     store.dispatch(updateSessionDirty());
        //   }
        // }

        return Promise.reject(error);
      },
    );

    interceptors.isIntercepting = true;
  }
}
