import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';

interface ScrollContextType {
  scrollToSection: (id: string) => void;
  registerSection: (
    id: string,
    element: HTMLDivElement | null,
    onClick: () => void,
  ) => void;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

interface ScrollProviderProps {
  children: React.ReactNode;
}

export const ScrollProvider = (props: ScrollProviderProps) => {
  const { children } = props;
  const [targetId, setTargetId] = useState<string | null>(null);
  const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const onClickHandlers = useRef<Record<string, () => void>>({});

  const scrollToSection = (id: string) => {
    setTargetId(id);
  };

  useEffect(() => {
    if (targetId && sectionRefs.current[targetId]) {
      const section = sectionRefs.current[targetId];
      const onClick = onClickHandlers.current[targetId];
      section?.scrollIntoView({ behavior: 'smooth' });
      setTargetId(null);

      setTimeout(() => {
        if (onClick) {
          onClick();
        }
      }, 1000);
    }
  }, [targetId]);

  const registerSection = (
    id: string,
    element: HTMLDivElement | null,
    onClick: () => void,
  ) => {
    sectionRefs.current[id] = element;
    onClickHandlers.current[id] = onClick;
  };

  return (
    <ScrollContext.Provider value={{ scrollToSection, registerSection }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
