import { ScanSettingStepAction } from '@equally-ai-front/common/src/types/audit-settings';
import React from 'react';
import { ErrorSpan, Input } from '../scan-step-action';

interface InputAttributes {
  className: string;
  placeHolder: string;
  ariaLabel: string;
}

interface ActionSelectorInputsProps {
  handleChange: (
    evt: React.ChangeEvent<HTMLInputElement>,
    stepId: string,
    actionIndex: number,
  ) => void;
  stepId: string;
  index: number;
  action: ScanSettingStepAction;
  firstInput: InputAttributes;
  secondInput?: InputAttributes;
}

export const ActionSelectorInputs = ({
  handleChange,
  stepId,
  index,
  action,
  firstInput,
  secondInput,
}: ActionSelectorInputsProps) => {
  return (
    <>
      <Input
        aria-label={firstInput.ariaLabel}
        name="key"
        type="text"
        className={firstInput.className}
        onChange={(evt) => handleChange(evt, stepId, index)}
        placeholder={firstInput.placeHolder}
        value={action.key}
        required
      />
      <ErrorSpan>{action.error}</ErrorSpan>
      {secondInput && (
        <Input
          aria-label={secondInput.ariaLabel}
          name="value"
          type="text"
          onChange={(evt) => handleChange(evt, stepId, index)}
          placeholder={secondInput.placeHolder}
          value={action.value}
          required
        />
      )}
    </>
  );
};
