// export function base64Decode(token:string) {
//   // Create a new buffer from the input string
//   return {
//     data: JSON.parse(Buffer.from(token.split(".")[1], "base64").toString()),
//     token: token.split(".")[1],
//   };
// }

// export function encodeBase64(data: string) {
//   const jsonData = JSON.stringify(data);
//   return Buffer.from(jsonData).toString("base64");
// }

import { REACT_APP_TYPE } from './magicValues';

enum ReactAppsTypes {
  CHROME_EXTENSION = 'CHROME_EXTENSION',
  WEB_APP = 'web-app',
}

export function getAssetReactAppUrl(path: string): string {
  return REACT_APP_TYPE === ReactAppsTypes.CHROME_EXTENSION
    ? chrome.runtime.getURL(path)
    : path;
}
