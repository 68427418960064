import React from 'react';
import styled from 'styled-components';
import Zoom from '@mui/material/Zoom';
import { CustomToolTip } from '../custom-tooltip/CustomToolTip';
import CopyButton from '../copy-button';

export interface HeaderIconProp {
  action: any;
  iconSrc: string;
  isActive: boolean;
  disable: boolean;
  toolTipMsg?: string;
}

export interface CodeButtonProp {
  action: any;
  iconSrc: string;
  isCopied?: boolean;
  checkMarkIcon?: string;
}

interface CodeBlockProps {
  codeDescriptionTitle: string;
  headerIcon1: HeaderIconProp | null;
  headerIcon2: HeaderIconProp | null;
  codeContent: any;
  copyIcon: CodeButtonProp;
  codeIcon?: CodeButtonProp;
  isExtension?: boolean;
  hideMt?: boolean;
}

export const CodeBlock = (props: CodeBlockProps) => {
  const {
    codeDescriptionTitle,
    headerIcon1,
    headerIcon2,
    codeContent,
    copyIcon,
    codeIcon,
    isExtension,
    hideMt,
  } = props;

  return (
    <Wrapper>
      <Header>
        <TitleHeader isExtension={isExtension} hideMt={hideMt}>
          {codeDescriptionTitle}
        </TitleHeader>
        <HeaderActionDiv>
          {headerIcon1 && (
            <CustomToolTip
              title={headerIcon1.toolTipMsg}
              placement="top"
              TransitionComponent={Zoom}
              arrow
            >
              <HeaderIcon
                isactive={headerIcon1.isActive}
                disabled={headerIcon1.disable}
                onClick={headerIcon1.action}
                src={headerIcon1.iconSrc}
                alt=""
              />
            </CustomToolTip>
          )}
          {headerIcon2 && (
            <CustomToolTip
              title={headerIcon2.toolTipMsg}
              placement="top"
              TransitionComponent={Zoom}
              arrow
            >
              <HeaderIcon
                isactive={headerIcon2.isActive}
                disabled={headerIcon2.disable}
                onClick={headerIcon2.action}
                src={headerIcon2.iconSrc}
                alt=""
              />
            </CustomToolTip>
          )}
        </HeaderActionDiv>
      </Header>
      <GptResult>
        <GptBlock
          hasExtraIcon={Boolean(codeIcon)}
          isExtension={Boolean(isExtension)}
        >
          <GptBlockContent>{codeContent}</GptBlockContent>
        </GptBlock>
        <CodeBtnDiv hasExtraIcon={Boolean(codeIcon)}>
          {codeIcon && (
            <CodeBlockBtn onClick={codeIcon.action} hasExtraIcon>
              {codeIcon.iconSrc !== '' && <img src={codeIcon.iconSrc} alt="" />}
            </CodeBlockBtn>
          )}
          <CopyButton
            altText={'copy icon'}
            ariaLabel={'Copy code'}
            iconSrc={copyIcon.iconSrc}
            checkMarkIcon={copyIcon.checkMarkIcon}
            action={copyIcon.action}
          />
        </CodeBtnDiv>
      </GptResult>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 7%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleHeader = styled.h5<{ isExtension?: boolean; hideMt?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 10, 20, 0.85);
  margin: ${(p) => (p.isExtension ? '0' : '')};
  margin-top: ${(p) => (p.hideMt ? '0' : p.isExtension ? '20px' : '')};
  margin-bottom: 1.5vh;
`;

const HeaderActionDiv = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderIcon = styled.img<{ disabled: boolean; isactive: boolean }>`
  width: 20px;
  margin-right: 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.4' : '')};
  height: 25px;
`;

const GptResult = styled.div`
  background-color: #000a14;
  color: #ffffff;
  font-size: 13px;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

const GptBlock = styled.div<{ hasExtraIcon: boolean; isExtension: boolean }>`
  width: ${(p) =>
    p.isExtension
      ? 'calc(88% - 20px)'
      : p.hasExtraIcon
        ? 'calc(100% - 90px)'
        : '88%'};
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  font-family: monospace;
  max-width: ${(p) => (p.isExtension ? '220px' : '')};
  padding-bottom: ${(p) => (!p.isExtension ? '5px' : '')};
  overflow-x: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }
`;

const GptBlockContent = styled.pre`
  width: 100%;
  height: fit-content;
`;

const CodeBtnDiv = styled.div<{ hasExtraIcon: boolean }>`
  width: ${(p) => (p.hasExtraIcon ? '74px' : 'fit-content')};
  display: flex;
`;

const CodeBlockBtn = styled.button<{ hasExtraIcon?: boolean }>`
  border: none;
  background-color: #4ad493;
  width: 32px;
  height: 32px;
  margin-right: ${(p) => (p.hasExtraIcon ? '10px' : '0')};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
