import { MuiSelectOptionProps } from '@equally-ai-front/common/src/components/custom-mui-select/MuiSelect';

export const CONTAINER_WIDTH: string = '85%';
export const MOBILE_PX: number = 900;

export const WCAG_COMPLIANCE_TYPES: MuiSelectOptionProps[] = [
  {
    value: 'WCAG 2.1 AAA',
    label: 'WCAG 2.1 AAA',
  },
  {
    value: 'WCAG 2.1 AA',
    label: 'WCAG 2.1 AA (Recommend)',
  },
  {
    value: 'WCAG 2.1 A',
    label: 'WCAG 2.1 A',
  },
  {
    value: 'WCAG 2.0 AAA',
    label: 'WCAG 2.0 AAA',
  },
  {
    value: 'WCAG 2.0 AA',
    label: 'WCAG 2.0 AA',
  },
  {
    value: 'WCAG 2.0 A',
    label: 'WCAG 2.0 A',
  },
];

export const WEEK_OR_MONTH: MuiSelectOptionProps[] = [
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
];

export const DAYS: MuiSelectOptionProps[] = [
  {
    value: 'sunday',
    label: 'Sunday',
  },
  {
    value: 'monday',
    label: 'Monday',
  },
  {
    value: 'tuesday',
    label: 'Tuesday',
  },
  {
    value: 'wednesday',
    label: 'Wednesday',
  },
  {
    value: 'thursday',
    label: 'Thursday',
  },
  {
    value: 'friday',
    label: 'Friday',
  },
  {
    value: 'saturday',
    label: 'Saturday',
  },
];

export type BrowserNotification = {
  id: number;
  label: string;
  name: string;
};

export type BrowserNotificationState = {
  siteNotices: boolean;
  ignoredRules: boolean;
  wcag21Failures: boolean;
  levelAACompliance: boolean;
  overallScore: boolean;
  siteWarnings: boolean;
  pagesScanned: boolean;
  contrastErrors: boolean;
  levelAAACompliance: boolean;
  accessibilityScore: boolean;
  siteErrors: boolean;
  grade: boolean;
  levelACompliance: boolean;
  progressReport: boolean;
  pieChart: boolean;
  pendingIssues: boolean;
  fixedIssues: boolean;
  performanceSummary: boolean;
  monitorSettings: boolean;
};

export const BROWSER_NOTIFICATION_LIST: BrowserNotification[] = [
  {
    id: 1,
    label: 'Site Notices',
    name: 'siteNotices',
  },
  {
    id: 2,
    label: 'Ignored Rules',
    name: 'ignoredRules',
  },
  {
    id: 3,
    label: 'WCAG 2.1 Failures',
    name: 'wcag21Failures',
  },
  {
    id: 4,
    label: 'Level AA Compliance',
    name: 'levelAACompliance',
  },
  {
    id: 5,
    label: 'Overall Score',
    name: 'overallScore',
  },
  {
    id: 6,
    label: 'Site Warnings',
    name: 'siteWarnings',
  },
  {
    id: 7,
    label: 'Pages Scanned',
    name: 'pagesScanned',
  },
  {
    id: 8,
    label: 'Contrast Errors',
    name: 'contrastErrors',
  },
  {
    id: 9,
    label: 'Level AAA Compliance',
    name: 'levelAAACompliance',
  },
  {
    id: 10,
    label: 'Accessibility Score',
    name: 'accessibilityScore',
  },
  {
    id: 11,
    label: 'Site Errors',
    name: 'siteErrors',
  },
  {
    id: 12,
    label: 'Grade',
    name: 'grade',
  },
  {
    id: 13,
    label: 'Level A Compliance',
    name: 'levelACompliance',
  },
  {
    id: 14,
    label: 'Progress Report',
    name: 'progressReport',
  },
  {
    id: 15,
    label: 'Pie Chart',
    name: 'pieChart',
  },
  {
    id: 16,
    label: 'Pending Issues',
    name: 'pendingIssues',
  },
  {
    id: 17,
    label: 'Fixed Issues',
    name: 'fixedIssues',
  },
  {
    id: 18,
    label: 'Performance Summary',
    name: 'performanceSummary',
  },
  {
    id: 19,
    label: 'Monitor Settings',
    name: 'monitorSettings',
  },
];

export const BENCH_MARK_REPORT_OPTIONS: MuiSelectOptionProps[] = [
  {
    value: 'first niche',
    label: 'First Niche',
  },
  {
    value: 'second niche',
    label: 'Second Niche',
  },
  {
    value: 'third niche',
    label: 'Third Niche',
  },
  {
    value: 'fourth niche',
    label: 'Fourth Niche',
  },
];

export const UNAVAILABLE_CODE_SNIPPET =
  'Code currently unavailable. Apologies for the inconvenience.';

export const TABLE_PAGE_LIMIT = 10;
