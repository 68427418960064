import React, { useState, createContext, useContext } from 'react';

interface NotificationProviderProps {
  children: React.ReactNode;
  createNotification: (text: string, variant: 'success' | 'error') => void;
}

export const NotificationProvider = ({
  children,
  createNotification,
}: NotificationProviderProps) => {
  const [autoHide] = useState(true);

  return (
    <NotificationContext.Provider
      value={{
        createNotification,
        autoHide,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export interface NotificationContextProps {
  createNotification: (text: string, variant: 'success' | 'error') => void;
  autoHide: boolean;
}

export const NotificationContext = createContext<NotificationContextProps>({
  createNotification(text: string, variant: 'success' | 'error'): void {},
  autoHide: true,
});

export const useNotification = () => {
  const { createNotification, autoHide } = useContext(NotificationContext);
  const setErrorMessage = (text: string) => {
    createNotification(text, 'error');
  };
  const setSuccessMessage = (text: string) => {
    createNotification(text, 'success');
  };

  return {
    setErrorMessage,
    setSuccessMessage,
    autoHide,
  };
};
