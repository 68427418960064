import styled from '@emotion/styled';
import {
  getBusinessInvitations,
  getTeamMembers,
} from '@equally-ai-front/common/src/redux/business-slice/business';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { useAddTeamMember } from '../../../hooks/use-add-team-member';
import Container from '../../common/Container';
import LoadingView from '../../common/LoadingView';
import { Divider, TypographyH1, TypographyH2 } from '../../common/styled';
import { PrimaryButton } from '../Controls';
import { ModalPanel } from '../ModalPanel';
import AddTeamMemberForm from './add-team-member-form';
import { InvitationsTable } from './invitations-table';
import TeamMemberTable from './TeamMemberTable';
import { Box, Checkbox } from '@mui/material';
import { SmallPill } from '../../common/Pill';

export const Teams = () => {
  const [addMemberModal, setAddMemberModal] = useState(false);

  const team = useSelector((state) => state.business.teamMembers);
  const invitations = useSelector((state) => state.business.invitations);
  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );
  const personalDetails = useSelector(
    (state) => state.personalDetails.personalDetails,
  );
  const isTeamLoading = useSelector((state) => state.business.loading);
  const dispatch = useDispatch();
  const handleCloseModal = () => setAddMemberModal(false);
  const handleOpenModal = () => setAddMemberModal(true);
  const {
    handleAddMember,
    handleSubmit,
    newMembers,
    updateTeamMember,
    isEmailValid,
  } = useAddTeamMember({ onComplete: handleCloseModal, personalDetails });

  const fetchTeamMembers = useCallback(() => {
    dispatch(getTeamMembers({ businessId: currentBusiness.id }));
  }, [dispatch, currentBusiness]);

  const fetchInvitations = useCallback(() => {
    dispatch(getBusinessInvitations({ businessId: currentBusiness.id }));
  }, [dispatch, currentBusiness]);

  useEffect(() => {
    if (!currentBusiness?.id) {
      return;
    }
    fetchTeamMembers();
    fetchInvitations();
  }, [fetchTeamMembers, currentBusiness, fetchInvitations]);

  const analytics = useAnalytics();

  useEffect(() => {
    analytics.page({
      name: 'Teams',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <LoadingView open={isTeamLoading} />
      <BussinessDetailsBox>
        <MainHeadingTypography>Bussiness Details</MainHeadingTypography>
        <Divider />
        <div>
          <p>
            Name:{' '}
            <span style={{ fontWeight: 600 }}>{currentBusiness.name}</span>
          </p>
          <Box display="flex" gap="10px">
            <Pill>
              Slug: <span>{currentBusiness.slug}</span>
            </Pill>
            <Pill>
              Size: <span>{currentBusiness.size}</span>
            </Pill>
          </Box>
        </div>
      </BussinessDetailsBox>
      <div className="header-manage-websites settings-header">
        <HeadingTypography>Team Members</HeadingTypography>
        <AddButton
          color="primary"
          variant="contained"
          onClick={handleOpenModal}
        >
          &#43; Add Team Member
        </AddButton>
      </div>
      {!team.length && (
        <div className="websites-list-empty">
          <div className="websites-list-empty-text">
            You’ve not added any team member
          </div>
          <PrimaryButton
            color="primary"
            variant="contained"
            onClick={handleOpenModal}
          >
            &#43; Add Team Member
          </PrimaryButton>
        </div>
      )}
      {team.length > 0 && (
        <TeamMemberTable onDeleteTeamMember={fetchTeamMembers} />
      )}
      {invitations.length > 0 && <InvitationsTable invitations={invitations} />}
      <ModalPanel
        title="Add Team Member"
        open={addMemberModal}
        handleClose={handleCloseModal}
      >
        <AddTeamMemberForm
          isEmailValid={isEmailValid}
          newMembers={newMembers}
          onAddMemberRow={handleAddMember}
          onNewMemberChange={updateTeamMember}
        />
        <ButtonsContainer>
          <PrimaryButton
            color="primary"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Cancel
          </PrimaryButton>

          <PrimaryButton
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Send Invite
          </PrimaryButton>
        </ButtonsContainer>
      </ModalPanel>
    </Container>
  );
};

const BussinessDetailsBox = styled(Box)`
  border-radius: 8px;
  background-color: #ebf1f9;
  margin-bottom: 30px;
  padding: 18px 32px;
`;

const Pill = styled(SmallPill)`
  background-color: #fff;

  span {
    font-weight: 600;
  }
`;

const MainHeadingTypography = styled(TypographyH1)`
  font-size: 32px;
  margin-right: 5px;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const HeadingTypography = styled(TypographyH2)`
  font-size: 32px;
  margin-right: 5px;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const AddButton = styled(PrimaryButton)`
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 2vh 1vw;
  gap: 20px;
  justify-content: flex-end;
  width: 100%;

  & > button {
    padding: 12px 24px;
  }
`;
