import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterField, IsSatisfiedFunc, ItemFilter } from '../types';
import { isSatisfied } from '../lib/filters';

interface UseFilterProps {
  filterOptions: {
    [key: string]: FilterField;
  };
  data: any[];
}

export const useFilter = ({ filterOptions, data }: UseFilterProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<ItemFilter[]>([]);

  const [filters, setFilters] = useState<Record<string, ItemFilter>>({});
  const [visibleData, setVisibleData] = useState<any[]>([]);

  const addFilter = useCallback(
    (filter: ItemFilter) => {
      setFilters((prevFilters) => {
        return { ...prevFilters, [filter.key]: filter };
      });
    },
    [filters],
  );
  const allFilterOptions: Record<string, ItemFilter> = useMemo(() => {
    const allFilters: { [key: string]: ItemFilter } = {};

    if (Object.entries(filterOptions).length == 0) {
      return allFilters;
    }

    for (const fieldKey in filterOptions) {
      const field = filterOptions[fieldKey];
      allFilters[field.key] = {
        key: field.key,
        displayName: field.displayName,
        value: null,
        filterType: field.filterType,
      };

      if (field.customType) {
        allFilters[field.key].customType = field.customType;
      }
    }
    return allFilters;
  }, [filterOptions]);

  useEffect(() => {
    if (data.length == 0) {
      return;
    }

    if (Object.keys(filters).length === 0) {
      setVisibleData(data);
      return;
    }
    handleFilterItems(Object.values(filters), isSatisfied);
  }, [data, filters]);

  const handleFilterItems = (
    filters: ItemFilter[],
    isSatisfied: IsSatisfiedFunc,
  ) => {
    const visibleItems = Object.values(data);

    if (Object.keys(filters).length === 0) {
      setVisibleData(data);
      return;
    }

    const filtered = visibleItems.filter((item) => {
      const set1 = new Set();

      for (const filterKey in filters) {
        const satisfied = isSatisfied(item, filters[filterKey]);
        set1.add(satisfied);
      }
      return set1.size === 1 && set1.has(true);
    });
    setVisibleData(filtered);
  };
  // const updateSelectedOptionValue = (
  //   value: string,
  //   key: string,
  //   filterCondition?: boolean,
  //   value2?: boolean
  // ) => {
  //   const updatedFilterType = Object.values(filters).map(filter => {
  //     let optionToReturn = filter;
  //     if (filter.key === key) {
  //       optionToReturn = {
  //         ...optionToReturn,
  //         [filterCondition
  //           ? "filterCondition"
  //           : value2
  //           ? "value2"
  //           : "value"]: value
  //       };
  //     }
  //
  //     return optionToReturn;
  //   });
  //   setFilters(updatedFilterType);
  // };

  const removeFilterType = (key: string) => {
    const newFilters = { ...filters };
    delete newFilters[key];
    setFilters(newFilters);
    // handleFilterItems(filteredOptions, isSatisfied);
    // setActiveFilters(updatedMuiOptions);
  };
  const options: ItemFilter[] = useMemo(() => {
    const value = Object.values(allFilterOptions).map((item) => item);
    setFilteredOptions(
      value.filter((option) => option.filterType !== undefined),
    );
    return value;
  }, [allFilterOptions]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSearchTerm(value);

    const filtered = options.filter((option) =>
      option.displayName.toLowerCase().includes(value.toLowerCase()),
    );

    value === '' ? setFilteredOptions(options) : setFilteredOptions(filtered);
  };

  return {
    allFilterOptions,
    options,
    handleFilterItems,
    searchTerm,
    addFilter,
    setSearchTerm,
    visibleData,
    filters,
    removeFilterType,
    filteredOptions,
    setFilteredOptions,
    handleSearchInputChange,
  };
};
