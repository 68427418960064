import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';

interface IssuesSummaryProps {
  error: number;
  warning: number;
  notice: number;
}

export const IssuesSummary = (props: IssuesSummaryProps) => {
  const { error, warning, notice } = props;

  return (
    <Badges>
      <Badge $bgcolor="#FF9999" $color="#661F20">
        {error}
      </Badge>
      <Badge $bgcolor="#F2DF87" $color="#6F321B">
        {warning}
      </Badge>
      <Badge $bgcolor="#AEEAD3" $color="#1E5643">
        {notice}
      </Badge>
    </Badges>
  );
};

const Badges = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 5px;
`;

type BadgeProps = { $bgcolor: string; $color: string };
const Badge = styled(Box)<BadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.$bgcolor};
  color: ${(props) => props.$color};
  height: 25px;
  padding: 4px 12px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;
