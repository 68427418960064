import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const PrimaryButton = styled(Button)`
  && {
    font-weight: 500;
    height: 48px;
    font-size: 16px;
    line-height: 150%;
    text-transform: none;
    border-radius: 8px;

  &:disabled {
      color: rgba(0, 10, 20, 0.65);
      background-color: #9AA5B1;
  },
  }
`;
