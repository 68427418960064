import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { PrimaryButton } from '../../redesign/Controls';
import { DrawerPanel } from '../../redesign/DrawerPanel';
import { ModalPanel } from '../../redesign/ModalPanel';
import {
  ProjectStatusPill,
  ProjectTypePill,
} from '../../redesign/Services/WebsiteCard/styled';
import {
  ButtonContainer,
  ControlButton,
  GroupContainer,
  InputGroup,
  NumberInput,
  PaymentDetails,
  ProjectDetails,
} from './styled';

const UpgradePlan = ({ plan, open, handleClose }) => {
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const Modal = isMobile ? DrawerPanel : ModalPanel;

  const [upgradeQuantity, setUpgradeQuantity] = useState(0);

  const increaseQuantity = () => {
    setUpgradeQuantity(Number(upgradeQuantity) + 1);
  };

  const decreaseQuantity = () => {
    if (Number(upgradeQuantity) >= 1) {
      setUpgradeQuantity(Number(upgradeQuantity) - 1);
    }
  };

  const handleQuantityIncrease = (e) => {
    const { value } = e.target;
    if (
      isNumber(Number(value)) &&
      value !== 'e' &&
      value !== '-' &&
      value !== '+'
    ) {
      setUpgradeQuantity(value);
    }
  };

  const handleInputKeydown = (evt) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };

  const totalQuantity = plan.quantity + plan.used_credit;

  return (
    <Modal title="Upgrade Plan" open={open} handleClose={handleClose}>
      <div>
        <ProjectDetails>
          <h3>
            {plan.plan_template.name} - $
            {plan.plan_template.price * totalQuantity}
          </h3>
          <div>
            <ProjectTypePill>
              Available Credit - {plan.quantity}/{totalQuantity}
            </ProjectTypePill>
            <ProjectStatusPill>{plan.plan_type}</ProjectStatusPill>
          </div>
        </ProjectDetails>
        <PaymentDetails>
          <h5>Add Credit to Plan</h5>
          <GroupContainer>
            <InputGroup>
              <ControlButton onClick={decreaseQuantity}>-</ControlButton>
              <div>
                <NumberInput
                  type="number"
                  className="upgrade-plan-quantity"
                  value={upgradeQuantity}
                  onChange={handleQuantityIncrease}
                  onKeyDown={handleInputKeydown}
                />
              </div>
              <ControlButton onClick={increaseQuantity}>+</ControlButton>
            </InputGroup>
          </GroupContainer>
          <ButtonContainer>
            <PrimaryButton color="primary" variant="contained">
              Upgrade Plan
            </PrimaryButton>
          </ButtonContainer>
        </PaymentDetails>
      </div>
    </Modal>
  );
};

UpgradePlan.propTypes = {
  plan: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UpgradePlan;
