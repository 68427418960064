import React, { useState } from 'react';
import { TextField, Box, Popover, Button } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { HexColorPicker } from 'react-colorful';
import { CustomReportFields } from '../export';

interface CustomReportFormProps {
  customReportFields: CustomReportFields;
  setCustomReportFields: (
    value: React.SetStateAction<CustomReportFields>,
  ) => void;
}

export const CustomReportForm = (props: CustomReportFormProps) => {
  const { customReportFields, setCustomReportFields } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCustomReportFieldChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = evt.target;
    setCustomReportFields({
      ...customReportFields,
      [name]: value,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'color-selector-popover' : undefined;

  return (
    <Wrapper>
      <Box sx={{ width: '100%' }}>
        <InputLabel htmlFor="fullName">Full Name</InputLabel>
        <MuiInput
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          name="fullName"
          id="fullName"
          value={customReportFields.fullName}
          onChange={handleCustomReportFieldChange}
          inputProps={{ 'aria-label': 'Full Name' }}
        />
      </Box>
      <LogoColorInputWrapper>
        <Box sx={{ width: '100%', maxWidth: '70%' }}>
          <InputLabel htmlFor="logo">Logo</InputLabel>
          <MuiInput
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="logo"
            name="logo"
            value={customReportFields.logo}
            onChange={handleCustomReportFieldChange}
            inputProps={{ 'aria-label': 'Logo' }}
          />
        </Box>
        <Box sx={{ width: '100%', maxWidth: '25%' }}>
          <InputLabel>Theme Color</InputLabel>
          <MuiButtonWrapper>
            <MuiButton
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              themecolor={customReportFields.themeColor}
            ></MuiButton>
          </MuiButtonWrapper>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <ColorPickerWrapper>
              <HexColorPicker
                color={customReportFields.themeColor}
                onChange={(color) =>
                  setCustomReportFields({
                    ...customReportFields,
                    themeColor: color,
                  })
                }
              />
            </ColorPickerWrapper>
          </Popover>
        </Box>
      </LogoColorInputWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const InputLabel = styled.label`
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 5px;
`;

const MuiInput = MuiStyled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    '& .MuiOutlinedInput-input': {
      height: 45,
      padding: '0 14px',
    },
  },
});

const MuiButtonWrapper = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
`;

const MuiButton = MuiStyled(Button)((props: { themecolor: string }) => ({
  border: 'none',
  width: '100%',
  maxWidth: '80%',
  height: '100%',
  maxHeight: '70%',
  margin: 'auto',
  padding: 0,
  borderRadius: 0,
  backgroundColor: props.themecolor,

  '&:hover': {
    backgroundColor: props.themecolor,
  },
}));

const LogoColorInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const ColorPickerWrapper = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;

  .react-colorful {
    width: 100%;
    height: 100%;
  }

  .react-colorful__saturation-pointer {
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }

  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 16px;
    border-radius: 3px;
  }
`;
