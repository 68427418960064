import React, { useCallback, useEffect, useReducer } from 'react';
import UsersApi from '../api/users';
import {
  AuthActionTypes,
  authReducerInitialState,
  createReducer,
} from '../index';
import {
  PROMO_POST_INSTALL_URL,
  USER_PANEL_AUTH_URL,
} from '../utils/magicValues';
import Cookies from 'js-cookie';
import ExtensionVersioning from '../api/extension-versioning';

export interface User {
  name: string;
  user_id: string;
  email: string;
  exp: number;
}

interface AuthProviderProps {
  // token?: string | null;
  unauthRedirect?: string;
  children: any;
  onLogout?: (...args: string[]) => void;
  onLogin: (token: string) => void;
  setSession: (accessToken: string, refreshToken: string) => Promise<void>;
  setRefreshTokenSession?: (refreshToken: string) => Promise<void>;
  getTokenFromStorage: () => Promise<string>;
  getAuthTokensFromStorage: () => Promise<Record<string, string>>;
  getNewAccessTokenUsingRefreshToken: (
    refreshToken: string,
  ) => Promise<string | undefined>;
  checkIsValidToken: (token: string) => boolean;
}

export type AuthType = {
  isAuthenticated: boolean;
  user: User | null;
  isAdmin?: boolean;
  verifyAuthentication: (...args: any) => void;
  logout: (callback?: () => void) => void;
  checkExtensionVersion: (version: string) => Promise<any>;
};

export const AuthContext = React.createContext<AuthType>({} as AuthType);
export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider = (props: AuthProviderProps) => {
  // extract URL[redirect_token] if exist hence take it from localStorage[getItem]

  // authentication properties to be exposed to the outside world.
  const [state, dispatch] = useReducer(createReducer, authReducerInitialState);
  const { isAuthenticated, user, isAdmin } = state;
  const {
    children,
    onLogout,
    onLogin,
    setRefreshTokenSession,
    getTokenFromStorage,
    getAuthTokensFromStorage,
    setSession,
    getNewAccessTokenUsingRefreshToken,
    checkIsValidToken,
  } = props;
  const currentUrl = window.location.href;

  useEffect(() => {
    chrome.runtime.onMessage.addListener(
      async (request, sender, sendResponse) => {
        if (request.type === 'redirected') {
          // const tokens = await getAuthTokensFromStorage();
          const accessToken = Cookies.get('eq-ext-accessToken');

          if (!accessToken) {
            return;
          }
          onLogin(accessToken);
        }
      },
    );
  }, []);

  useEffect(() => {
    void chrome.runtime.sendMessage({
      type: 'auth-context-init',
      payload: { url: currentUrl },
    });
  }, []);

  const verifyAuthentication = async () => {
    let token;
    let refreshToken;

    if (!USER_PANEL_AUTH_URL) {
      return;
    }

    if (currentUrl.includes(USER_PANEL_AUTH_URL)) {
      token = await getAuthFromUrl();
    } else if (currentUrl.includes(PROMO_POST_INSTALL_URL || '')) {
      token = Cookies.get('eq-ext-accessToken');
      refreshToken = Cookies.get('eq-ext-refreshToken');
    }
    if (!token) {
      const authTokens = await getAuthTokensFromStorage();
      token = authTokens?.accessToken;
      refreshToken = authTokens?.refreshToken;
    }

    if (!token || !refreshToken) {
      logout();
      return;
    }

    const isTokenValid = checkIsValidToken(token);

    if (!isTokenValid && setRefreshTokenSession) {
      await setRefreshTokenSession(refreshToken);
      const newAccessToken =
        await getNewAccessTokenUsingRefreshToken(refreshToken);

      if (!newAccessToken) {
        logout();
        return;
      }

      await login(newAccessToken, refreshToken);
      return;
    }

    await login(token, refreshToken);
  };
  const getAuthFromUrl = async (): Promise<string | undefined> => {
    const paramsString = currentUrl?.split('?')[1];
    if (!paramsString) {
      return;
    }
    const searchParams = new URLSearchParams(paramsString);
    const token = searchParams.get('redirect_token');

    if (!token) {
      return;
    }
    return token;
  };

  const checkIsAdmin = async (): Promise<void> => {
    const token = await getTokenFromStorage();
    const result = await UsersApi.isAdmin(token);

    dispatch({
      type: AuthActionTypes.IS_ADMIN,
      payload: {
        ...state,
        isAdmin: result.isAdmin,
      },
    });
  };

  // useEffect(() => {
  //   if (isAuthenticated || !currentUrl.includes("my.dev.equally.ai")) {
  //     return;
  //   }
  //   const setAuth = async () => {
  //     const token = await getAuthFromUrl();
  //     if (!token) {
  //       return;
  //     }
  //     await login(token);
  //   };
  //
  //   void setAuth();
  // }, [isAuthenticated]);
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const isAdmin = async () => {
      await checkIsAdmin();
    };

    void isAdmin();
  }, [isAuthenticated]);

  const logout = () => {
    dispatch({
      type: AuthActionTypes.AUTHENTICATE,
      payload: { user: null, isAuthenticated: false, isAdmin: false },
    });
    onLogout && onLogout();
  };

  const login = async (accessToken: string, refreshToken: string) => {
    await setSession(accessToken, refreshToken);

    onLogin && onLogin(accessToken);

    dispatch({
      type: AuthActionTypes.AUTHENTICATE,
      payload: { isAuthenticated: true, user: user },
    });
  };

  const checkExtensionVersion = useCallback(async (version: string) => {
    return await ExtensionVersioning.checkExtensionVersion(version);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        verifyAuthentication,
        checkExtensionVersion,
        logout,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
