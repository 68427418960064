import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';

import AuditAPI from '../helpers/api/audit';

import { delay, isValidURL } from '../helpers';

export const ERROR = {
  InvalidURL: Symbol('Invalud URL'),
  AuditFailed: Symbol('Audit Failed'),
  AuditCanceled: Symbol('Audit Canceled'),
};

const MAX_PROGRESS = 90;
const ARTIFICIAL_PROGRESS_TIMEOUT = 200;
const ARTIFICIAL_PROGRESS_COMPLETE_TIMEOUT = 500;

const buildResult = (data, error) => ({
  data,
  error,
});

export default function useAuditAPI() {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const cancelSource = useRef(null);
  const timerRef = useRef(null);

  const onComplete = useCallback(() => {
    setProgress(100);
    clearInterval(timerRef.current);
  }, []);

  const onStart = useCallback(() => {
    timerRef.current = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === MAX_PROGRESS) {
          clearInterval(timerRef.current);
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, MAX_PROGRESS);
      });
    }, ARTIFICIAL_PROGRESS_TIMEOUT);
  }, []);

  const performCancel = useCallback(() => {
    cancelSource.current?.cancel();
    cancelSource.current = null;
  }, []);

  const performCleanUp = useCallback(() => {
    setError(null);
    setData(null);
    setLoading(false);
    setProgress(0);
  }, []);

  const performFetch = useCallback(
    async (url) => {
      if (loading) {
        performCancel();
      }

      performCleanUp();

      if (!isValidURL(url)) {
        setError(ERROR.InvalidURL);
        return buildResult(null, ERROR.InvalidURL);
      }

      /** Start Loading */
      setLoading(true);

      const cancelToken = AuditAPI.generateCancelToken();
      cancelSource.current = cancelToken;

      try {
        const auditRequestPromise = AuditAPI.checkCompliance({
          url,
          cancelToken,
        });

        onStart();

        const { data: responseData } = await auditRequestPromise;

        onComplete();
        /** Hack for updates */
        await delay(ARTIFICIAL_PROGRESS_COMPLETE_TIMEOUT);

        setData(responseData);

        return buildResult(responseData, null);
      } catch (thrown) {
        const isCanceled = axios.isCancel(thrown);

        const err = isCanceled ? ERROR.AuditCanceled : ERROR.AuditFailed;
        setError(err);

        return buildResult(null, err);
      } finally {
        setLoading(false);
        /** Clean cancel token */
        cancelSource.current = null;
      }
    },
    [loading, onComplete, onStart, performCancel, performCleanUp],
  );

  return {
    data,
    loading,
    progress,
    error,
    performFetch,
    performCancel,
  };
}
