import styled from '@emotion/styled';
import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import {
  AUDIT_STATUS_ERROR,
  AUDIT_STATUS_SUCCESS,
  AUDIT_STATUS_WARNING,
} from '@equally-ai-front/common/src/utils/constants';
import { Box, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { PillNoRadius } from '../scan-report/scan-report-section-item';

interface IssueReportSectionItemProps {
  title: string;
  level: string;
  status: string | undefined;
  accordionId: string;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
  children: React.ReactNode;
}

export const IssueReportSectionItem = (props: IssueReportSectionItemProps) => {
  const {
    title,
    status,
    level,
    accordionId,
    accordionState,
    handleAccordionToggle,
    children,
  } = props;
  const isMobileDevice = useMediaQuery('(pointer:none), (pointer:coarse)');
  const handleToggleAccordion = () => {
    handleAccordionToggle(accordionId);
  };

  return (
    <Container>
      <AccessibilityAccordion
        title={title}
        accordionId={accordionId}
        accordionState={accordionState}
        handleToggleAccordion={handleToggleAccordion}
        accordionHeader={
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm>
                <CustomGridContent container spacing={isMobileDevice ? 2 : 1}>
                  <LabelDiv>
                    <Grid item>
                      <IconBox>
                        {status === AUDIT_STATUS_SUCCESS && (
                          <SuccessIcon>
                            <img src="/icon-check.svg" alt="success icon" />
                          </SuccessIcon>
                        )}
                        {status === AUDIT_STATUS_WARNING && (
                          <WarningIcon>
                            <img src="/icon-check.svg" alt="warning icon" />
                          </WarningIcon>
                        )}
                        {status === AUDIT_STATUS_ERROR && (
                          <ErrorIcon>
                            <img src="/icon-close.svg" alt="error icon" />
                          </ErrorIcon>
                        )}
                      </IconBox>
                    </Grid>

                    <HiddenLarge>
                      <Grid item xs style={{ alignSelf: 'center' }}>
                        <HeaderText>
                          <TitleTypography>{title}</TitleTypography>
                        </HeaderText>
                      </Grid>
                    </HiddenLarge>
                  </LabelDiv>

                  {level && (
                    <Grid item>
                      <Box mt="3px" />
                      <CenterBlock>
                        <PillNoRadius
                          bgColor="#E4E7EB"
                          color="rgba(0, 10, 20, 0.65)"
                        >
                          {`Level ${level}`}
                        </PillNoRadius>
                      </CenterBlock>
                    </Grid>
                  )}
                  <HiddenSmall>
                    <Grid item xs={12}>
                      <HeaderText>
                        <TitleTypography>{title}</TitleTypography>
                      </HeaderText>
                    </Grid>
                  </HiddenSmall>
                </CustomGridContent>
              </Grid>
            </Grid>
          </>
        }
      >
        {children}
      </AccessibilityAccordion>
    </Container>
  );
};

/** Styled Components */

const Container = styled.div`
  overflow: hidden;
`;

const CustomGridContent = styled(Grid)`
  justify-content: space-between !important;
`;

const LabelDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 70%;
`;

const CenterBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessIcon = styled(Icon)`
  background-color: #aeead3;
  color: #1e5643;
`;

const WarningIcon = styled(Icon)`
  background-color: #f2df87;
  color: #6f321b;
`;

const ErrorIcon = styled(Icon)`
  background-color: #ff9999;
  color: #661f20;
`;

const HeaderText = styled.div`
  flex: 1;
`;

const TitleTypography = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
`;

const IconBox = styled.div`
  width: 32px;
  height: 32px;
`;

const HiddenLarge = styled.div`
  margin-left: 15px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const HiddenSmall = styled.div`
  margin-left: 10px;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;
