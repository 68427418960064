import { Button, Typography } from '@mui/material';
import React from 'react';

type PopupComponentProps = {
  isOpen: boolean;
  onApprove: () => void;
  onCancel: () => void;
};

const PopupComponent: React.FC<PopupComponentProps> = ({
  isOpen,
  onApprove,
  onCancel,
}) => {
  if (!isOpen) return null;

  return (
    <div className="popup">
      <Typography>Do you want to proceed?</Typography>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onApprove}>Yes</Button>
    </div>
  );
};
export default PopupComponent;
