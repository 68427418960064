import { formatExpiration } from './date';

export const getTotalCredits = (plans) => {
  let totalCredits = 0;
  plans.forEach((plan) => {
    totalCredits += plan.credits;
  });
  return totalCredits;
};

const isPlanActive = (plan) => {
  return formatExpiration(plan.expiration) !== 'Expired' && plan.is_active;
};

export const isPlansAvailable = (plansData) => {
  return (
    plansData.length > 0 &&
    plansData.filter((plan) => plan.quantity > 0 && isPlanActive(plan)).length >
      0
  );
};

export const availablePlans = (plansData) =>
  plansData.filter(
    (plan) =>
      formatExpiration(plan.expiration) !== 'Expired' &&
      plan.quantity > 0 &&
      plan.is_active,
  );

export const isAttachPlanEligible = (plans, type) => {
  return (
    plans.length > 0 &&
    plans.filter(
      (plan) =>
        isPlanActive(plan) && plan.quantity > 0 && plan.plan_type === type,
    ).length > 0
  );
};

export const isDetachPlanEligible = (plans) => {
  return (
    plans.length > 0 &&
    plans.filter((plan) => isPlanActive(plan) && plan.quantity >= 0).length > 0
  );
};
