export const convertSnakeCaseToCamelCase = (value: string): string => {
  return value.replace(/_([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
};

export const convertKeysFromSnakeCaseToCamelCase = (data: any): any => {
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      return data.map((item) => convertKeysFromSnakeCaseToCamelCase(item));
    }
    const newObj: { [key: string]: any } = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const camelKey = convertSnakeCaseToCamelCase(key);
        newObj[camelKey] = convertKeysFromSnakeCaseToCamelCase(data[key]);
      }
    }
    return newObj;
  }
  // If the data is not an object, return it as is (e.g., strings, numbers, etc.)
  return data;
};
