import axios from 'axios';
import { ApiResponse } from '@equally-ai-front/axios-api';
import graphqlClient from '@equally-ai-front/common/src/utils/graphql.util';

export class CelloService {
  static async getToken({
    businessId,
  }: {
    businessId: number;
  }): Promise<ApiResponse<any>> {
    let result;
    let error;
    const endpoint = `api/billing/v2/a/business/${businessId}/cello/token`;
    try {
      result = await axios.post(endpoint);
    } catch (err: any) {
      error = 'Something went wrong!';
    }
    const data = result?.data || undefined;
    return {
      isSuccess: result?.status === 200,
      data,
      error,
    };
  }

  static async signUp({
    celloUcc,
    slug,
  }: {
    celloUcc: string;
    slug: string;
  }): Promise<ApiResponse<any>> {
    const query = `
      mutation celloSignUp($payload: Request!) {
        celloSignUp(payload: $payload) {
          data
          message
          statusCode
        }
      }
    `;

    const result = await graphqlClient.execute({
      functionName: 'celloSignUp',
      query,
      payload: {
        data: JSON.stringify({
          slug,
          cello_ucc: celloUcc,
        }),
      },
    });

    const { data, isSuccess } = result;
    if (!isSuccess) {
      return {
        data: null,
        isSuccess,
        error: 'Unable to get domain pages, try again later.',
      };
    }

    return {
      data,
      isSuccess,
      error: null,
    };
  }
}
