import { WidgetConfigurations } from '../helpers';

export const makeHash = function (length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `flowy___${result}`;
};

export const addElementRelationship = (
  currentElement: HTMLElement,
  relationshipElement: HTMLElement,
  attribute: string,
) => {
  let relationshipElementID = relationshipElement?.id;

  if (!relationshipElementID) {
    const hashId = makeHash(5);

    relationshipElement.setAttribute('id', hashId);
    relationshipElementID = hashId;
  }

  currentElement.setAttribute(attribute, relationshipElementID);
};

export const getEmptyConfig = (): Record<WidgetConfigurations, any[]> => {
  return {
    [WidgetConfigurations.CUSTOM_ATTRIBUTES]: [],
    [WidgetConfigurations.DYNAMIC_ELEMENTS]: [],
    [WidgetConfigurations.IMPROVED_NAVIGATION]: [],
    [WidgetConfigurations.MORE_ROLES]: [],
    [WidgetConfigurations.ELEMENTS_RELATIONSHIP]: [],
    [WidgetConfigurations.LANDMARKS]: [],
    [WidgetConfigurations.MENU_AND_SUBMENU]: [],
    [WidgetConfigurations.META_DATA]: [],
  };
};

export const generateConfigTemplate = (
  attr: Record<string, any>,
  configType: WidgetConfigurations,
  selector: string,
) => {
  if (Object.keys(attr).length === 0) {
    return [];
  }

  switch (configType) {
    case WidgetConfigurations.IMPROVED_NAVIGATION:
      return [
        {
          selector,
          index: attr.tabindex,
        },
      ];
    case WidgetConfigurations.CUSTOM_ATTRIBUTES:
      return Object.keys(attr).map((attribute) => ({
        selector,
        attribute,
        value: attr[attribute],
      }));
    case WidgetConfigurations.LANDMARKS:
      return [
        {
          selector,
          role: attr.role,
        },
      ];
    case WidgetConfigurations.MORE_ROLES:
      return [
        {
          selector,
          role: attr.role,
        },
      ];

    default:
      return [];
  }
};
