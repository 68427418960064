import React from 'react';
import { ColumnOption, IssueCreationType } from './popup';
import { AccordionState } from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import styled from 'styled-components';
import {
  TaskIssue,
  TaskAdditionalInfo,
} from '@equally-ai-front/common/src/types/business';
import { JiraTaskStepper } from './jira-task-stepper';

interface JiraTaskIssuesProps {
  columns: ColumnOption[];
  issueCreationType: IssueCreationType;
  selectedIssues: TaskIssue[];
  selectedDevToolsIssues: DevtoolsSolution[];
  accordionState: AccordionState;
  handleToggleAccordion: (id: string) => void;
  isGrouped: boolean;
  tasksAdditionalInfo: TaskAdditionalInfo[];
  setTasksAdditionalInfo: React.Dispatch<
    React.SetStateAction<TaskAdditionalInfo[]>
  >;
}

export const JiraTaskIssues = (props: JiraTaskIssuesProps) => {
  const {
    columns,
    issueCreationType,
    selectedIssues,
    selectedDevToolsIssues,
    accordionState,
    handleToggleAccordion,
    isGrouped,
    tasksAdditionalInfo,
    setTasksAdditionalInfo,
  } = props;

  return (
    <Wrapper isgrouped={isGrouped.toString()}>
      <JiraTaskStepper
        columns={columns}
        issueCreationType={issueCreationType}
        selectedIssues={selectedIssues}
        selectedDevToolsIssues={selectedDevToolsIssues}
        accordionState={accordionState}
        handleToggleAccordion={handleToggleAccordion}
        isGrouped={isGrouped}
        tasksAdditionalInfo={tasksAdditionalInfo}
        setTasksAdditionalInfo={setTasksAdditionalInfo}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isgrouped: string }>`
  padding: ${(props) => (props.isgrouped === 'true' ? '20px' : '0')};
`;
