import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setApiSuccessMessage } from '../../store/actions';
import { IconButton } from './Controls';

import Drupal from '../../img/Drupal.png';
import Duda from '../../img/Duda.png';
import GTM from '../../img/GTM.png';
import Magento from '../../img/Magento.png';
import Shopify from '../../img/Shopify.png';
import Webflow from '../../img/Webflow.png';
import Weebly from '../../img/Weebly.png';
import Wix from '../../img/Wix.png';
import Wordpress from '../../img/Wordpress.png';
import CopyWidgetKeyAndCode from './copy-key-and-code';
import { DomainInstallationNextStep } from './domain-installation-next-step';
import { Box, Typography } from '@mui/material';
import { useAnalytics } from '@equally-ai-front/common/src';
import { SCHEDULE_A_MEETING } from '../../common/magicValues';

const DomainInstallationCode = ({
  domain,
  code,
  apiKey,
  handleInviteTeamMember,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const sendInstructionsClick = () => {
    const instructions = `Hello,

Please paste the code below right before the end of your sites body.
${code}

Or use one of the integration guides to implement Equally AI
https://equally.ai/integrations

Thank you`;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(instructions);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = instructions;
      textArea.style.widgetConfigSitePosition = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      dispatch(setApiSuccessMessage('Instructions copied to clipboard!'));
      document.execCommand('copy');
      textArea.remove();
    }

    dispatch(setApiSuccessMessage('Instructions copied to clipboard!'));
    analytics.track('Installation Pop Up Option Clicked', {
      option: 'copy-instructions-button',
      url: domain,
    });
  };

  const integrationButton = (icon, link, text, style = undefined) => (
    <Link
      to={`${process.env.REACT_APP_HTTP_INTEGRATIONS_ENDPOINT}/${link}`}
      target="_blank"
      className="website-integration-button"
      style={style}
      onClick={() => {
        analytics.track('Installation Pop Up Option Clicked', {
          option: 'integration-help',
          integration: link || 'all-integrations',
          url: domain,
        });
      }}
    >
      <>
        {icon && <img alt={`${text} integration`} src={icon} />}
        {text}
      </>
    </Link>
  );

  const domainInstallationNextStepOptions = [
    {
      label: 'Invite Team Member',
      value: 'invite-team-member',
      handleClick: handleInviteTeamMember,
    },
    {
      label: 'Copy instructions',
      value: 'copy-instructions',
      handleClick: sendInstructionsClick,
    },
  ];

  const handleScheduleMeetingEvent = () => {
    analytics.track('Installation Pop Up Option Clicked', {
      option: 'schedule-a-meeting',
      url: domain,
    });
  };

  return (
    <>
      <div className="modal-panel-sub-title">
        Paste the code below right before the end of your sites body, the URL
        must match your domain so the widget will be displayed instantly:
      </div>
      <CopyWidgetKeyAndCode code={code} domain={domain} apiKey={apiKey} />
      <div
        className="modal-panel-send-instructions"
        style={{ marginBottom: '5px' }}
      >
        <div>
          <div>What&apos;s next?</div>
          <div className="modal-panel-send-instructions-line-2">
            Please allow up to 48 working hours for an email confirmation that
            the process is complete.
          </div>
        </div>
      </div>
      <div className="modal-panel-send-instructions">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            /* flexDirection: 'column', */ width: '100%',
          }}
        >
          <div>
            <div>Need help?</div>
            <div className="modal-panel-send-instructions-line-2">
              <InstructionLabel variant="body1">
                Send these instructions to your website
              </InstructionLabel>
              <InstructionLabel variant="body1">
                admin or &nbsp;
                <StyledLink
                  href={SCHEDULE_A_MEETING}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline' }}
                  onClick={handleScheduleMeetingEvent}
                >
                  Schedule a meeting
                </StyledLink>
              </InstructionLabel>
            </div>
          </div>

          <StyledIconButton
            variant="outlined"
            color="primary"
            disabled={!code}
            onClick={sendInstructionsClick}
          >
            <div className="website-card-controls-btn-text-no-icon">
              Copy instructions
            </div>
          </StyledIconButton>
          {/* <DomainInstallationNextStep
            handleInviteTeamMember={handleInviteTeamMember}
            domainInstallationNextStepOptions={
              domainInstallationNextStepOptions
            }
          /> */}
        </Box>
      </div>
      <div className="website-integration-buttons-line">Or</div>
      <div className="website-integration-buttons-line">
        {integrationButton(Wordpress, 'wordpress', 'Wordpress')}
        {integrationButton(Wix, 'wix', 'Wix')}
        {integrationButton(GTM, 'tagmanager', 'GTM')}
      </div>
      <div className="website-integration-buttons-line">
        {integrationButton(Weebly, 'weebly', 'Weebly')}
        {integrationButton(Shopify, 'shopify', 'Shopify')}
        {integrationButton(Drupal, 'drupal', 'Drupal')}
      </div>
      <div className="website-integration-buttons-line">
        {integrationButton(Magento, 'magento', 'Magento')}
        {integrationButton(Webflow, 'webflow', 'Webflow')}
        {integrationButton(Duda, 'duda', 'Duda')}
      </div>

      {integrationButton(undefined, '', 'All Integrations', {
        width: '95%',
        margin: '20px auto',
      })}
    </>
  );
};

export default DomainInstallationCode;

DomainInstallationCode.propTypes = {
  code: PropTypes.any.isRequired,
  apiKey: PropTypes.any.isRequired,
  domain: PropTypes.string.isRequired,
};

const StyledIconButton = styled(IconButton)`
  width: 160px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  @media (pointer: none), (pointer: coarse) {
    height: 40px;
    width: 100%;
  }
`;

const InstructionLabel = styled(Typography)`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 10, 20, 0.85);
`;

const StyledLink = styled.a`
  color: #7097fa;
`;
