import React from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { DomainRegEx } from '@equally-ai/audit-ui/src/utils/audit-form-validation-schema';
import { createJiraSettings } from '@equally-ai-front/common/src/redux/business-slice/business';
import {
  Business,
  JiraSettings,
} from '@equally-ai-front/common/src/types/business';
import { setApiError } from '../../../store/actions';
import Container from '../../common/Container';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../../redesign/Controls';
import { FormField } from './form-field';
import {
  JiraSettingsFormFields,
  FormFieldValidationErrors,
  FormFieldName,
} from '../jira-integration-settings';

const validationSchema = Yup.object({
  userEmail: Yup.string()
    .email('Invalid email address!')
    .required('Email is required!'),
  apiKey: Yup.string().required('Api key is required!'),
  boardUrl: Yup.string()
    .trim()
    .test(
      'url',
      'Enter a valid website URL',
      (value) => !!value && DomainRegEx.test(value),
    )
    .required('Board URL is required'),
});

interface JiraFieldProps {
  name: FormFieldName;
  label: string;
}

const JIRA_FORM_FIELDS: JiraFieldProps[] = [
  {
    name: 'userEmail',
    label: 'Email',
  },
  {
    name: 'apiKey',
    label: 'API key',
  },
  {
    name: 'boardUrl',
    label: 'Board URL',
  },
];

interface JiraSettingsFormProps {
  jiraFormFields: JiraSettingsFormFields;
  setJiraFormFields: React.Dispatch<
    React.SetStateAction<JiraSettingsFormFields>
  >;
  formFieldErrors: FormFieldValidationErrors;
  setFormFieldErrors: React.Dispatch<
    React.SetStateAction<FormFieldValidationErrors>
  >;
  currentBusiness: Business;
  isInputsDisabled: boolean;
  setIsInputsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  handleDisableInputs: (data: JiraSettings) => void;
  header: React.ReactNode;
}

export const JiraSettingsForm = (props: JiraSettingsFormProps) => {
  const {
    jiraFormFields,
    setJiraFormFields,
    formFieldErrors,
    setFormFieldErrors,
    currentBusiness,
    isInputsDisabled,
    setIsInputsDisabled,
    handleDisableInputs,
    header,
  } = props;
  const dispatch = useDispatch();

  const validateFormField = async (fieldName: string, value: string) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFormFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' }));
    } catch (error) {
      if (error instanceof ValidationError) {
        setFormFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    }
  };

  const handleJiraFormFieldChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = evt.target;
    setJiraFormFields({
      ...jiraFormFields,
      [name]: value,
    });
    validateFormField(name, value);
  };

  const handleEnableInputs = () => {
    setJiraFormFields({ ...jiraFormFields, apiKey: '' });
    validateFormField('apiKey', '');
    setIsInputsDisabled(false);
  };

  const handleSaveJiraSettings = async () => {
    if (!currentBusiness.id) return;
    try {
      await validationSchema.validate(jiraFormFields, { abortEarly: false });
      const { payload } = await dispatch(
        // @ts-ignore
        createJiraSettings({
          businessId: currentBusiness.id,
          ...jiraFormFields,
        }),
      );
      if (!payload) return;
      const { isSuccess, error, data } = payload;

      if (isSuccess && data) {
        handleDisableInputs(data);
      } else {
        dispatch(setApiError(error));
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        const validationErrors: FormFieldValidationErrors = {};
        if (error.inner.length > 0 && error.inner[0].path) {
          const { path, message } = error.inner[0];
          validationErrors[path] = message;
          dispatch(setApiError(message));
        }
        setFormFieldErrors({ ...formFieldErrors, ...validationErrors });
      }
    }
  };

  const isJiraFormIncomplete =
    !jiraFormFields.userEmail ||
    !jiraFormFields.apiKey ||
    !jiraFormFields.boardUrl;
  const handleFormButtonClick = isInputsDisabled
    ? handleEnableInputs
    : handleSaveJiraSettings;
  const buttonText = isInputsDisabled ? 'Edit' : 'Submit';

  return (
    <>
      <Wrapper>
        {header}
        <JiraForm>
          <JiraFormBox>
            <JiraFormHeader>
              <JiraFormHeaderLogo>
                <JiraLogo src="/jira-logo.svg" alt="Jira logo" />
              </JiraFormHeaderLogo>
              <JiraFormSubmitButton
                color="primary"
                variant="contained"
                type="button"
                onClick={handleFormButtonClick}
                disabled={isJiraFormIncomplete}
              >
                {buttonText}
              </JiraFormSubmitButton>
            </JiraFormHeader>
            {JIRA_FORM_FIELDS.map(({ name, label }, index) => (
              <JiraFormField key={`${name}-${index}`}>
                <JiraFormLabel htmlFor={name}>{label}</JiraFormLabel>
                <FormField
                  formFieldName={name}
                  jiraFormFields={jiraFormFields}
                  errors={formFieldErrors}
                  isInputsDisabled={isInputsDisabled}
                  handleJiraFormFieldChange={handleJiraFormFieldChange}
                />
              </JiraFormField>
            ))}
          </JiraFormBox>
        </JiraForm>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const JiraForm = styled.form`
  margin-top: 35px;
`;

const JiraFormBox = styled.div`
  border-radius: 20px;
  padding: 25px 60px 60px;
  border: 1.5px solid rgb(14, 135, 172, 0.6);
  width: 100%;
  max-width: 520px;
`;

const JiraFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const JiraFormHeaderLogo = styled.div``;

const JiraLogo = styled.img``;

const JiraFormField = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const JiraFormLabel = styled.label`
  font-weight: 300;
  display: inline-block;
  margin: 0px 0px 5px 10px;
`;

const JiraFormSubmitButton = styled(PrimaryButton)`
  height: 48px;
  margin: 0;
`;
