import React from 'react';
import styled from 'styled-components';
import { Title } from './styled-components';
import { TypographyH5 } from '../../../components/common/styled';

export interface FlowyFeatureSelectionProps {
  isSelectionCompleted: boolean;
  title: string;
  subTitle: string;
  handleClick: () => void;
}

export const FlowyFeatureSelection = (props: FlowyFeatureSelectionProps) => {
  const { isSelectionCompleted, title, subTitle, handleClick } = props;

  return (
    <Wrapper onClick={handleClick}>
      <IconWrapper>
        {isSelectionCompleted ? (
          <Icon src="/blue-check-circle.svg" />
        ) : (
          <IconCircle />
        )}
      </IconWrapper>
      <Content>
        <FlowySelectionTitle>{title}</FlowySelectionTitle>
        <SubTitle>{subTitle}</SubTitle>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  border: 1.5px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Icon = styled.img``;

const IconCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 1.5px solid #0e87ac;
  border-radius: 50%;
`;

const Content = styled.div``;

const FlowySelectionTitle = styled(Title)`
  margin-bottom: 2px;
`;

const SubTitle = styled(TypographyH5)`
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 10, 20, 1);
`;
