import { AriaContainer } from '@equally-ai/audit-ui/src/containers/aria-container';
import { Box, CircularProgress } from '@mui/material';
import React, { Suspense, useCallback, useState } from 'react';
import { CenterBlock } from '../common/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import useMessage from '../../hooks/useMessage';
import { useAutomaticScan } from '@equally-ai-front/common/src/hooks/use-automatic-scan';
import { useDispatch, useSelector } from 'react-redux';
import { setApiError } from '../../store/actions';
import DomainsAPI from '@equally-ai-front/common/src/api/domains';

const AriaLazy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { error, success } = useMessage();
  const currentBusiness = useSelector(
    (state) => state.business?.currentBusiness,
  );
  const [loadingDomainInfo, setLoadingDomainInfo] = useState(false);

  const onAuditError = useCallback(
    (err) => {
      dispatch(setApiError(err));
    },
    [dispatch],
  );

  const {
    performSingleAudit,
    isLoading: loading,
    scans,
    getIssues,
  } = useAutomaticScan({
    onError: onAuditError,
  });
  const startFreeTrial = () => {
    const businessSlug = location.pathname.split('/')[1];
    navigate(`/${businessSlug}?start_free_trial=true`);
  };

  const createNotification = (message, variant) => {
    if (variant === 'error') {
      error(message);
    } else {
      success(message);
    }
  };

  const getDomainInfo = async (domainUrl) => {
    setLoadingDomainInfo(true);
    const {
      data: responseData,
      isSuccess,
      error,
    } = await DomainsAPI.getDomainInfo(currentBusiness?.id, domainUrl);
    setLoadingDomainInfo(false);
    if (!isSuccess) {
      console.error(error);
      return;
    }
    return responseData.data;
  };

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <AriaContainer
        performAudit={performSingleAudit}
        allowNonBusiness
        loading={loading}
        getIssues={getIssues}
        scans={scans}
        onStartFreeTrialClick={startFreeTrial}
        createNotification={createNotification}
        isUserPanel
        getDomainInfo={getDomainInfo}
      />
    </Suspense>
  );
};

export default AriaLazy;

const LoadingIndicator = () => (
  <Box marginY={4}>
    <CenterBlock>
      <CircularProgress />
    </CenterBlock>
  </Box>
);
