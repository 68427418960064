import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';
import { ApiResponse } from '../utils/axios';
import { AuditSettings, AuditSettingsPayload } from '../types/audit-settings';

export class AuditSettingsAPI {
  static async getSettings(
    businessId: number,
    websiteId: number,
  ): Promise<ApiResponse<AuditSettings>> {
    let response;
    let error;
    try {
      response = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/audit-settings/${websiteId}`,
      );
    } catch (err) {
      error = err;
    }

    const { data, status } = response || {};
    return {
      data: data?.data,
      isSuccess: status === 200,
      error: data?.message || error,
    };
  }

  static async saveAuditSettings(payload: {
    businessId: number;
    payloadData: AuditSettingsPayload;
  }): Promise<ApiResponse<AuditSettings>> {
    const { businessId, payloadData } = payload;
    let response;
    let error;
    try {
      response = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/audit-settings/${payloadData.website_id}`,
        payloadData,
      );
    } catch (err) {
      error = err;
    }

    const { data, status } = response || {};

    return {
      data: data?.data,
      isSuccess: status === 200,
      error: data?.message || error,
    };
  }
}
