import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';

type Alignment = 'top' | 'bottom' | 'center';

interface CheckmarkItemProps {
  children: React.ReactNode;
  align?: Alignment;
}

export const CheckmarkItem = ({
  children,
  align,
  ...props
}: CheckmarkItemProps) => {
  return (
    <CheckmarkContainer align={align || 'center'} {...props}>
      <CheckmarkIconDark />
      <Box ml="16px" />
      <span>{children}</span>
    </CheckmarkContainer>
  );
};

/** Styled Components */

const handleAlign = (align: Alignment) => {
  switch (align) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    default:
      return 'center';
  }
};

interface ContainerProps {
  align: Alignment;
}

const CheckmarkContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => handleAlign(align)};

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`;

const CheckmarkIconDark = styled(CheckRounded)`
  border-color: #cbd2d9;
`;
