/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { nanoid } from 'nanoid';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import styled from '@emotion/styled';
import { Settings } from '@mui/icons-material';
import { isHexColorLight } from '../../common/helpers';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import SelectedIcon from '../AccessibilityIcon/SelectedIcon';
import ErrorMessage from '../common/ErrorMessage';
import IconsDropdown from '../common/IconsDropdown/IconsDropdown';
import { PrimaryButton } from './Controls';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  selectIconContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      fontSize: '12px',
      marginBottom: '5px',
    },
  },
  iconContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: '100%',
  },
  select: {
    width: '100%',
  },
}));

const CustomiseAppearance = ({
  domain,
  setCustomAppearanceOpened,
  appearance,
  handleUpdateAppearance,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const colorRegex = /^#([0-9a-f]{3}){1,2}$/i;

  const [showIconDropdown, setShowIconDropdown] = useState(false);
  const anchorRef = useRef(null);
  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );
  const [appearanceState, setAppearanceState] = useState({
    widgetConfigSitePosition: {
      alignment: 'bottom-right',
      horizontalMargin: '20px',
      verticalMargin: '20px',
    },
    widgetConfigMobilePosition: {
      alignment: 'bottom-right',
      horizontalMargin: '20px',
      verticalMargin: '20px',
    },
    widgetConfigSiteColoring: {
      headerColor: '#000000',
      actionColor: '#ffffff',
    },
    widgetConfigSiteSize: { diameterDesktop: '47px', diameterMobile: '31px' },
    widgetConfigSiteAdditional: {
      enableAriaAttributes: true,
      widgetActivationAnchor: 'icon-human',
      defaultLanguage: 'en',
      enableSkipLinks: false,
      enableSafeMode: false,
      customAnchorSelector: '',
      enableKeyboardNavigation: true,
    },
  });
  const loading = useSelector((state) => state.domains.loading);

  const desktopScale =
    parseInt(appearanceState.widgetConfigSiteSize.diameterDesktop, 10) / 60;
  const mobileScale =
    parseInt(appearanceState.widgetConfigSiteSize.diameterMobile, 10) / 60;

  useEffect(() => {
    setAppearanceState(appearance);
  }, [appearance]);

  const getIconSPositionAndAlignment = (position, scale) => {
    const humanStyles = {};
    const circleStyles = {};

    if (position.alignment.indexOf('right') >= 0) {
      humanStyles.right = position.horizontalMargin;
      humanStyles.marginRight = `${17 * scale}px`;
      circleStyles.right = position.horizontalMargin;
    } else {
      humanStyles.left = position.horizontalMargin;
      humanStyles.marginLeft = `${17 * scale}px`;
      circleStyles.left = position.horizontalMargin;
    }

    if (position.alignment.indexOf('top') >= 0) {
      humanStyles.top = position.verticalMargin;
      humanStyles.marginTop = `${15 * scale}px`;
      circleStyles.top = position.verticalMargin;
    } else {
      humanStyles.bottom = position.verticalMargin;
      humanStyles.marginBottom = `${15 * scale}px`;
      circleStyles.bottom = position.verticalMargin;
    }

    return {
      humanStyles,
      circleStyles,
    };
  };

  const handleIconPositionChange = (e) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigSitePosition: {
        ...appearanceState.widgetConfigSitePosition,
        alignment: e.target.value,
      },
    });
  };
  const handleHorizontalPosition = (e) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigSitePosition: {
        ...appearanceState.widgetConfigSitePosition,
        horizontalMargin: e.target.value,
      },
    });
  };
  const handleVerticalPosition = (e) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigSitePosition: {
        ...appearanceState.widgetConfigSitePosition,
        verticalMargin: e.target.value,
      },
    });
  };
  const handleMobileIconPositionChange = (e) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigMobilePosition: {
        ...appearanceState.widgetConfigMobilePosition,
        alignment: e.target.value,
      },
    });
  };
  const handleMobileHorizontalPosition = (e) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigMobilePosition: {
        ...appearanceState.widgetConfigMobilePosition,
        horizontalMargin: e.target.value,
      },
    });
  };
  const handleMobileVerticalPosition = (e) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigMobilePosition: {
        ...appearanceState.widgetConfigMobilePosition,
        verticalMargin: e.target.value,
      },
    });
  };

  const handleIconActionColor = (e) => {
    const { value } = e.target;

    setAppearanceState({
      ...appearanceState,
      widgetConfigSiteColoring: {
        ...appearanceState.widgetConfigSiteColoring,
        actionColor: value.trimEnd(),
        backgroundColor: value.trimEnd(),
      },
    });
  };

  const handleIconHeaderColor = (e) => {
    const { value } = e.target;

    setAppearanceState({
      ...appearanceState,
      widgetConfigSiteColoring: {
        ...appearanceState.widgetConfigSiteColoring,
        headerColor: value.trimEnd(),
        humanColor: value.trimEnd(),
      },
    });
  };

  const handleIconRadius = (e) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigSiteSize: {
        ...appearanceState.widgetConfigSiteSize,
        diameterDesktop: e.target.value,
      },
    });
    setShowIconDropdown(false);
  };
  const handleMobileIconRadius = (e) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigSiteSize: {
        ...appearanceState.widgetConfigSiteSize,
        diameterMobile: e.target.value,
      },
    });
  };

  const handleIconDropdown = () =>
    setShowIconDropdown((prevState) => !prevState);

  const handleAnchor = (id) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigSiteAdditional: {
        ...appearanceState.widgetConfigSiteAdditional,
        widgetActivationAnchor: id,
      },
    });
  };

  const handleCloseDropdown = (e) => {
    const clickedEl = e.target;
    const dropdownClicked = clickedEl === anchorRef.current;
    if (showIconDropdown && !dropdownClicked) {
      setShowIconDropdown(false);
    }
  };

  const desktopStyles = getIconSPositionAndAlignment(
    appearanceState.widgetConfigSitePosition,
    desktopScale,
  );
  const mobileStyles = getIconSPositionAndAlignment(
    appearanceState.widgetConfigMobilePosition,
    mobileScale,
  );

  const saveAppearance = () => {
    handleUpdateAppearance(currentBusiness.id, domain, appearanceState, false);
    setCustomAppearanceOpened(false);
  };

  const checkValidColorCodes = () => {
    return (
      colorRegex.test(appearanceState.widgetConfigSiteColoring.actionColor) &&
      colorRegex.test(appearanceState.widgetConfigSiteColoring.headerColor)
    );
  };

  const isColorCodeValid = checkValidColorCodes();

  const iconStrokeColor = isHexColorLight(
    appearanceState.widgetConfigSiteColoring.actionColor,
  )
    ? '#000000'
    : '#ffffff';

  const isFormValid = isColorCodeValid; // && accentColorContrastRatio;

  if (loading) {
    return (
      <LoadingDiv>
        <CircularProgress />
      </LoadingDiv>
    );
  }

  return (
    <>
      <div
        className="customize-website-container"
        onClick={handleCloseDropdown}
      >
        <div className="customize-website-header">
          Desktop icon customization
        </div>
        <div className="customize-website-content">
          <div className="customize-website-content-fields">
            <div className="customize-website-content-fields-line">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Icon Position
                </InputLabel>
                <Select
                  native
                  value={appearanceState.widgetConfigSitePosition.alignment}
                  onChange={handleIconPositionChange}
                  label="Icon Position"
                  className={classes.select}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option value="top-left">Top-Left</option>
                  <option value="top-right">Top-Right</option>
                  <option value="bottom-left">Bottom-Left</option>
                  <option value="bottom-right">Bottom-Right</option>
                </Select>
              </FormControl>
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Horizontal Margin"
                variant="outlined"
                className={classes.input}
                onChange={handleHorizontalPosition}
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  appearanceState.widgetConfigSitePosition.horizontalMargin
                }
              />
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Vertical Margin"
                variant="outlined"
                className={classes.input}
                onChange={handleVerticalPosition}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearanceState.widgetConfigSitePosition.verticalMargin}
              />
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                type="color"
                label="Action Color"
                variant="outlined"
                className={classes.input}
                onChange={handleIconActionColor}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearanceState.widgetConfigSiteColoring.actionColor}
                error={
                  !colorRegex.test(
                    appearanceState.widgetConfigSiteColoring.actionColor,
                  )
                }
              />
              {!colorRegex.test(
                appearanceState.widgetConfigSiteColoring.actionColor,
              ) && <ErrorMessage message="Please enter a valid color code" />}
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                type="color"
                label="Header Color"
                variant="outlined"
                className={classes.input}
                onChange={handleIconHeaderColor}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearanceState.widgetConfigSiteColoring.headerColor}
                error={
                  !colorRegex.test(
                    appearanceState.widgetConfigSiteColoring.headerColor,
                  )
                }
              />
              {!colorRegex.test(
                appearanceState.widgetConfigSiteColoring.headerColor,
              ) && <ErrorMessage message="Please enter a valid color code" />}
            </div>
            {/* <div className="customize-website-content-fields-line">
              <TextField
                type="color"
                label="Accent Color"
                variant="outlined"
                className={classes.input}
                onChange={handleAccentColor}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearanceState.widgetConfigSiteColoring.accentColor}
              />
              {!isAccentColorCodeValid && (
                <ErrorMessage message="Please enter a valid color code" />
              )}
              {accentColorContrastRatio !== null &&
                !accentColorContrastRatio && (
                  <ErrorMessage message="Not enough contrast between colors to meet WCAG AA Requirement" />
                )}
            </div> */}
            <div className="customize-website-content-fields-line">
              <TextField
                label="Icon Radius"
                variant="outlined"
                className={classes.input}
                onChange={handleIconRadius}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearanceState.widgetConfigSiteSize.diameterDesktop}
              />
            </div>
            <div className="customize-website-content-fields-line">
              <div className="activation-anchor" ref={anchorRef}>
                {appearanceState.widgetConfigSiteAdditional
                  .widgetActivationAnchor === 'icon-plus' ? (
                  <CustomSelectorDiv>
                    <TextField
                      label="Custom Anchor Selector"
                      variant="outlined"
                      className={classes.input}
                      onChange={(evt) => {
                        setAppearanceState({
                          ...appearanceState,
                          widgetConfigSiteAdditional: {
                            ...appearanceState.widgetConfigSiteAdditional,
                            customAnchorSelector: evt.target.value,
                          },
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        appearanceState.widgetConfigSiteAdditional
                          .customAnchorSelector
                      }
                    />
                    <SettingsDiv>
                      <Tooltip
                        title="Select Activation Anchor"
                        placement="top"
                        arrow
                      >
                        <Settings
                          onClick={() => {
                            handleAnchor('icon-human');
                            handleIconDropdown();
                          }}
                        />
                      </Tooltip>
                    </SettingsDiv>
                  </CustomSelectorDiv>
                ) : (
                  <>
                    <h6 className="select-icon-label">Activation Anchor</h6>
                    <div
                      role="button"
                      className="select-icon-button"
                      aria-label="Activation Anchor"
                      onClick={handleIconDropdown}
                      onKeyPress={handleIconDropdown}
                      tabIndex={0}
                    >
                      <SelectedIcon
                        iconId={
                          appearanceState.widgetConfigSiteAdditional
                            .widgetActivationAnchor
                        }
                        width={45}
                        height={45}
                        fill={
                          appearanceState.widgetConfigSiteColoring.actionColor
                        }
                        stroke={iconStrokeColor}
                      />

                      {showIconDropdown && (
                        <IconsDropdown
                          selectedIcon={
                            appearanceState.widgetConfigSiteAdditional
                              .widgetActivationAnchor
                          }
                          width={45}
                          height={45}
                          actionColor={
                            appearanceState.widgetConfigSiteColoring.actionColor
                          }
                          onSelect={handleAnchor}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {!isMobile && (
            <div className="customize-website-content-view">
              <div
                className="customize-website-content-view-circle"
                style={desktopStyles.circleStyles}
              >
                <SelectedIcon
                  iconId={
                    appearanceState.widgetConfigSiteAdditional
                      .widgetActivationAnchor
                  }
                  width={appearanceState.widgetConfigSiteSize.diameterDesktop}
                  height={appearanceState.widgetConfigSiteSize.diameterDesktop}
                  fill={appearanceState.widgetConfigSiteColoring.actionColor}
                  stroke={iconStrokeColor}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="customize-website-container"
        onClick={handleCloseDropdown}
      >
        <div className="customize-website-header">
          Mobile icon customization
        </div>
        <div className="customize-website-content">
          <div className="customize-website-content-fields">
            <div className="customize-website-content-fields-line">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Icon Position
                </InputLabel>
                <Select
                  native
                  value={appearanceState.widgetConfigMobilePosition.alignment}
                  onChange={handleMobileIconPositionChange}
                  label="Icon Position"
                  className={classes.select}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option value="top-left">Top-Left</option>
                  <option value="top-right">Top-Right</option>
                  <option value="bottom-left">Bottom-Left</option>
                  <option value="bottom-right">Bottom-Right</option>
                  <option value="none">None</option>
                </Select>
              </FormControl>
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Horizontal Margin"
                variant="outlined"
                className={classes.input}
                onChange={handleMobileHorizontalPosition}
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  appearanceState.widgetConfigMobilePosition.horizontalMargin
                }
              />
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Vertical Margin"
                variant="outlined"
                className={classes.input}
                onChange={handleMobileVerticalPosition}
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  appearanceState.widgetConfigMobilePosition.verticalMargin
                }
              />
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Icon Radius"
                variant="outlined"
                className={classes.input}
                onChange={handleMobileIconRadius}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearanceState.widgetConfigSiteSize.diameterMobile}
              />
            </div>
          </div>
          {!isMobile && (
            <div
              className="customize-website-content-view"
              style={{ width: '50%' }}
            >
              {appearanceState.widgetConfigMobilePosition.alignment !==
                'none' && (
                <>
                  <div
                    className="customize-website-content-view-circle"
                    style={mobileStyles.circleStyles}
                  >
                    <SelectedIcon
                      iconId={
                        appearanceState.widgetConfigSiteAdditional
                          .widgetActivationAnchor
                      }
                      width={
                        appearanceState.widgetConfigSiteSize.diameterMobile
                      }
                      height={
                        appearanceState.widgetConfigSiteSize.diameterMobile
                      }
                      fill={
                        appearanceState.widgetConfigSiteColoring.actionColor
                      }
                      stroke={iconStrokeColor}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="add-website-fields">
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={saveAppearance}
          style={{ width: '83px' }}
          disabled={!isFormValid}
        >
          Save
        </PrimaryButton>
      </div>
    </>
  );
};

export default CustomiseAppearance;

CustomiseAppearance.propTypes = {
  domain: PropTypes.string.isRequired,
  setCustomAppearanceOpened: PropTypes.func.isRequired,
  handleUpdateAppearance: PropTypes.func.isRequired,
  appearance: PropTypes.object.isRequired,
};

const CustomSelectorDiv = styled.div`
  position: relative;
`;

const SettingsDiv = styled.div`
  position: absolute;
  top: 10px;
  right: -30px;
  cursor: pointer;
  z-index: 50;
`;

const LoadingDiv = styled.div`
  height: 100%;
  min-height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
