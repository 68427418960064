import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React from 'react';
import { AuditComplianceType } from '../accessibility-summary/audit-compliance-info';
import { HighRiskAccessibilitySummary } from '../accessibility-summary/high-risk-accessibility-summary';
import styled from 'styled-components';
import { LowRiskAccessibilitySummary } from '../accessibility-summary/low-risk-accessibility-summary';

interface AuditReportSummaryProps {
  report: ReportData;
  auditComplianceType: AuditComplianceType;
  onStartFreeTrialClick: () => void;
}

export const AuditReportSummary = (props: AuditReportSummaryProps) => {
  const { report, auditComplianceType, onStartFreeTrialClick } = props;
  return (
    <Wrapper>
      {auditComplianceType === 'high-risk' && (
        <HighRiskAccessibilitySummary
          report={report}
          onStartFreeTrialClick={onStartFreeTrialClick}
        />
      )}

      {auditComplianceType === 'low-risk' && (
        <LowRiskAccessibilitySummary report={report} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 912px;

  @media screen and (max-width: 1140px) {
    max-width: 100%;
  }
`;
