import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled as MuiStyled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isUserPermitted } from '../../../lib/permissions';

export const NavigationBar = () => {
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Wrapper>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          ml: 0,
          display: { sm: 'none' },
          position: 'absolute',
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        <MenuIcon />
      </IconButton>
      <SidebarWrapper className={`${mobileOpen ? 'visible' : ''}`}>
        <SidebarList>
          <SidebarListItem>
            <SidebarTitle variant="h2">
              {personalDetails?.name || ''}
            </SidebarTitle>
            <SidebarTitle variant="h2">
              {currentBusiness?.name || ''}
            </SidebarTitle>
          </SidebarListItem>
          <SidebarListItem>
            <SidebarTitle variant="h2">Business</SidebarTitle>
            {currentBusiness && (
              <>
                <SidebarLink
                  to={`/${currentBusiness?.slug}/settings/team`}
                  className="header-navigation-popover-item"
                  onClick={handleDrawerToggle}
                >
                  Team
                </SidebarLink>
                <SidebarLink
                  to={`/${currentBusiness?.slug}/settings/plans`}
                  className="header-navigation-popover-item"
                  onClick={handleDrawerToggle}
                >
                  Billing & Payments
                </SidebarLink>
              </>
            )}
          </SidebarListItem>
          {/* <SidebarListItem> */}
          {/*  <SidebarTitle variant="h2">Widget</SidebarTitle> */}
          {/* </SidebarListItem> */}
          {/* <SidebarListItem> */}
          {/*  <SidebarTitle variant="h2">Extension</SidebarTitle> */}
          {/* </SidebarListItem> */}
        </SidebarList>
      </SidebarWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (max-width: 600px) {
    .visible {
      left: 0;
      z-index: 4;
      margin-left: 0;
      margin-top: 120px;
    }
  }
`;

const SidebarWrapper = styled.div`
  width: 250px;
  height: 100%;
  background: white;
  padding: 20px;
  margin: 0 20px;
  color: black;
  border-radius: 10px;
  border: 1px solid #e5e5e5;

  @media (max-width: 600px) {
    position: fixed;
    top: 0;
    left: -200%;
    width: 100%;
    height: 100%;
    padding: 1rem;
    transition: left 0.3s ease;
  }
`;

const SidebarTitle = styled(Typography)({
  '&.MuiTypography-h2': {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
  },
});

const SidebarList = MuiStyled(List)`
  list-style: none;
  padding: 0;
`;

const SidebarListItem = MuiStyled(ListItem)`
  margin-bottom: 4px;
  flex-direction: column;
  align-items: flex-start;
`;

const SidebarLink = styled(Link)`
  text-decoration: none;
  display: block;
  margin-left: 15px;
`;
