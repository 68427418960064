import { useMemo, useState } from 'react';

export enum OrderOptionsEnum {
  ASC = 'asc',
  DESC = 'desc',
}
export type SortOption = Record<string, OrderOptionsEnum>;

interface UsePaginationResult<T> {
  pageCount: number;
  currentPage: number;
  currentData: T[];
  searchTerm: string;
  sortOptions: SortOption;
  handlePageChange: (selectedPage: { selected: number }) => void;
  handleSearch: (query: string) => void;
  handleSort: (key: string) => void;
}

export const usePagination = <T, K extends keyof T>(
  data: T[],
  PAGE_LIMIT: number,
  searchPropertyKey?: K,
  sortFields?: SortOption,
): UsePaginationResult<T> => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortOptions, setSortOptions] = useState<SortOption>(sortFields || {});

  const offset = currentPage * PAGE_LIMIT;

  const filteredData = useMemo(() => {
    const filtered =
      searchPropertyKey && searchTerm
        ? data.filter((item) => {
            const itemValue = item[searchPropertyKey];
            if (
              typeof itemValue === 'string' ||
              typeof itemValue === 'number'
            ) {
              return itemValue
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }
            return false;
          })
        : data;
    return filtered;
  }, [data, searchPropertyKey, searchTerm]);

  const pageCount = Math.ceil(filteredData.length / PAGE_LIMIT);

  const currentData = useMemo(() => {
    const sortedData = [...filteredData];

    for (const key in sortOptions) {
      if (sortOptions.hasOwnProperty(key) && sortOptions[key]) {
        sortedData.sort((a, b) => {
          const valueA = a[key as keyof T];
          const valueB = b[key as keyof T];

          if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
            return sortOptions[key] === OrderOptionsEnum.ASC
              ? Number(valueA) - Number(valueB)
              : Number(valueB) - Number(valueA);
          }

          const valueAString =
            typeof valueA === 'string' || typeof valueA === 'number'
              ? valueA.toString()
              : '';
          const valueBString =
            typeof valueB === 'string' || typeof valueB === 'number'
              ? valueB.toString()
              : '';

          return sortOptions[key] === OrderOptionsEnum.ASC
            ? valueAString.localeCompare(valueBString)
            : valueBString.localeCompare(valueAString);
        });
      }
    }
    return sortedData.slice(offset, offset + PAGE_LIMIT);
  }, [filteredData, offset, sortOptions]);

  const moveToTop = () => {
    window.scrollTo(0, 0);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    moveToTop();
  };

  const handleSearch = (query: string) => {
    setSearchTerm(query);
    setCurrentPage(0);
  };

  const handleSort = (key: string) => {
    setSortOptions((prevSortOptions) => {
      const currentSortOrder = prevSortOptions[key]
        ? prevSortOptions[key]
        : 'asc';
      const newSortOptions: SortOption = {
        [key]:
          currentSortOrder === OrderOptionsEnum.ASC || !currentSortOrder
            ? OrderOptionsEnum.DESC
            : OrderOptionsEnum.ASC,
      };
      return newSortOptions;
    });
  };

  return {
    pageCount,
    currentData,
    currentPage,
    searchTerm,
    sortOptions,
    handleSearch,
    handlePageChange,
    handleSort,
  };
};
