/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Card as MuiCard, Box, Typography } from '@mui/material';

import { PriceCardButton } from './pricing-card-button';
import { useCart } from '../../../../providers/cart-provider';

import { ContainedButton } from '../../../../components/common/ActionButton';
import { SuccessPillSmall } from '../../../../components/common/Pill';
import {
  BookDemoProductType,
  useBookADemo,
} from '@equally-ai-front/common/src/hooks/use-book-a-demo';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';

const attachToCurrency = (amount: number) => {
  return `$${amount.toLocaleString()}`;
};

const calculateAmountPerMonth = (amount: number, durationInMonths: number) => {
  return Math.ceil(amount / durationInMonths);
};

const calculateMonths = (duration: number, unit: string) => {
  if (unit.toLowerCase() === 'month') {
    return duration;
  }

  return duration * 12;
};
interface PriceCardProps {
  title: string;
  type: string;
  description?: React.ReactNode;
  durationLabel: string;
  duration: number;
  name?: string;
  priceID: number;
  price: number;
  similarPrices: any;
  position: number | string;
  selected?: boolean;
  setConfirmationModal: (value: boolean) => void;
  templateBenefits?: {
    pretext: string;
    features: any[];
  };
  showGetStartedLink?: boolean;
  isLimited?: boolean;
  isPopular?: boolean;
  ctaActionType?: 'cart' | 'demo';
  ctaText?: string;
  ctaLink?: string;
}

export const PriceCard = ({
  priceID,
  title,
  name,
  price,
  duration,
  type,
  description,
  durationLabel,
  selected,
  similarPrices,
  position,
  setConfirmationModal,
  templateBenefits,
  showGetStartedLink,
  isLimited,
  isPopular,
  ctaActionType,
  ctaText,
  ctaLink,
  ...props
}: PriceCardProps) => {
  const { handleBookDemo } = useBookADemo();
  const { currentBusiness } = useSelector((state: RootState) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const [isHighlighted, setHighlighted] = useState(false);

  const doHighlight = useCallback(() => setHighlighted(true), []);
  const doUnhighlight = useCallback(() => setHighlighted(false), []);
  const {
    store: { cart },
  } = useCart();

  const totalMonths = calculateMonths(duration, durationLabel);
  const pricePerMonth = calculateAmountPerMonth(price, totalMonths);

  const inCart =
    cart?.cart_items.find((item: any) => item.plan_template.name === name) !==
    undefined;
  // const isTemplateSelected = cart?.cart_items.find(
  //   (item) => item.plan_template.id === priceID
  // );

  const isCardSelected = inCart || selected || isHighlighted;

  const demoCtaText = ctaText || 'Book a demo';

  const button =
    ctaActionType === 'cart' && price > 0 ? (
      <PriceCardButton
        ctaLabel="Choose"
        id={priceID}
        isSelected={isCardSelected}
        type={type}
        duration={durationLabel}
        setConfirmationModal={setConfirmationModal}
      />
    ) : (
      <DemoButton
        onClick={() =>
          handleBookDemo({
            bookADemoLink:
              ctaLink || 'https://calendly.com/equally-ai/flowy-early-access',
            productType: type as BookDemoProductType,
            source: 'user panel',
            businessId: currentBusiness?.id,
          })
        }
      >
        {(ctaActionType === 'demo' || price === 0) && demoCtaText}
      </DemoButton>
    );
  // const isTemplateSelected = inCart && selected;
  return (
    <Card
      {...props}
      position={name === 'Flowy' ? 0 : -1}
      light={isCardSelected}
      onMouseEnter={doHighlight}
      onMouseLeave={doUnhighlight}
    >
      {isPopular && <PopularPill>Popular</PopularPill>}
      {isLimited && <PopularPill>For limited time🎉🎉</PopularPill>}
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Title light={isCardSelected}>{name}</Title>
          {!description || description === '' ? (
            <div style={{ minHeight: '48px' }} tabIndex={-1} />
          ) : (
            <Description light={isCardSelected}>{description}</Description>
          )}
          {price > 0 && totalMonths > 1 && (
            <>
              <PriceDetail light={isCardSelected}>
                Total cost {attachToCurrency(price)}
              </PriceDetail>
              <Box mt="10px" />
            </>
          )}
        </Box>
        <Offer light={isCardSelected}>
          {price === 0 ? 'Custom' : attachToCurrency(pricePerMonth)}
          {price > 0 && (
            <span style={{ fontSize: '14px', fontWeight: '300' }}>
              {' '}
              per month
            </span>
          )}
        </Offer>
      </Box>
      <ButtomContainer>{button}</ButtomContainer>
    </Card>
  );
};

/** Styled Components */

interface CardItemProps {
  light: boolean;
}

interface CardProps extends CardItemProps {
  position: number;
}

const Card = styled(MuiCard)<CardProps>`
  box-sizing: border-box;
  border-radius: 16px;
  text-align: center;
  background-color: ${({ theme, light }) => (light ? '#000a14' : '#f5f7fa')};
  margin-top: ${({ light }) => (light ? '0' : '10px')};
  padding: ${({ light }) => (light ? '25px' : '20px')} 12px;
  position: relative;
  overflow: initial;
  border: ${({ position, light }) =>
    position === 0 && !light ? '2px solid #454284' : 'none'};

  transition: all 0.3s ease;

  width: 100%;
`;

const TypographyHigh = styled(Typography)<CardItemProps>`
  color: ${({ theme, light }) => (light ? '#FFFFFF' : '#000A14')};
`;

const TypographyLow = styled(Typography)<CardItemProps>`
  color: ${({ theme, light }) =>
    light ? 'rgba(255, 255, 255, 0.908)' : 'rgba(0, 10, 20, 0.65)'};
`;

const Title = styled(TypographyLow)`
  text-transform: capitalize;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-bottom: 3px;
  font-weight: 500;
`;

const Offer = styled(TypographyHigh)`
  height: 54px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 90%;
  min-width: fit-content;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    padding: 5px;
  }
`;

const Description = styled(TypographyLow)`
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-overflow: ellipsis;
  margin-bottom: 8px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

const PriceDetail = styled(TypographyLow)`
  font-size: 14px;
  font-weight: 400;
  color: #5a6168;
  text-align: left;
`;

const ButtomContainer = styled.div`
  height: 40px;
  display: flex;
`;

const PopularPill = styled(SuccessPillSmall)`
  position: absolute;
  top: -11px;
  font-size: 12px !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #454284 !important;
  color: #fff !important;
  width: 145px;
`;

const DemoButton = styled(ContainedButton)`
  text-transform: initial;

  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;
