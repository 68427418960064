import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { formatExpiration } from '../lib/date';

export const formatDomainInputUrl = (
  devDomain: DevDomain,
  value: string,
): string => {
  const DOMAIN_INPUT_URL_REGEX = new RegExp(
    `^(?:https?:\\/\\/)?(?:www\\.)?${devDomain.name}(?:\\/(.+))?$`,
  );

  if (value.includes(devDomain.name)) {
    const matchResult = DOMAIN_INPUT_URL_REGEX.exec(value);
    const formattedPathname =
      matchResult && matchResult[1] !== undefined ? matchResult[1] : '';
    return formattedPathname.replace(/^\/+/, '');
  }

  return value.replace(/^\/+/, '');
};

export const getHasActiveDevDomains = (domains: any[]): boolean => {
  if (domains?.length === 0) return false;
  return !!domains?.find((domain) => domain?.is_developer);
};
