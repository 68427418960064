import { GET_PRICES_REQUEST, GET_PRICES_SUCCESS } from '../actionTypes';

const initialState = {
  prices: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRICES_REQUEST:
      return { ...state, loading: true };
    case GET_PRICES_SUCCESS:
      return {
        ...state,
        prices: action.value.map((p) => {
          const planParts = p.planName.split(' ');
          let duration = 0;
          if (planParts.length > 1) {
            // eslint-disable-next-line no-shadow
            const p = planParts[planParts.length - 1];
            if (p === '1yr') {
              duration = 12;
            } else if (p === '2yr') {
              duration = 24;
            }
          }
          return { ...p, duration };
        }),
        loading: false,
      };
    default: {
      return state;
    }
  }
}
