import Cookies, { CookieAttributes } from 'js-cookie';

export const EQUALLY_COOKIE_KEY = 'equally_attribution';

const getCookieDomain = (): CookieAttributes => {
  const domain = process.env.REACT_APP_PROMO_URL?.split('https://')[1];
  const isLocalHost = window.location.host.startsWith('localhost:');
  return {
    domain: isLocalHost ? 'localhost' : `.${domain}`,
    path: '/',
    secure: !isLocalHost,
    sameSite: 'Lax',
  };
};

export const saveAuthTokenCookies = (
  values: Record<string, string>,
  isCrossDomain = true,
) => {
  let config = {};

  if (isCrossDomain) {
    config = getCookieDomain();
  }

  Object.entries(values).map(([key, value]) => {
    Cookies.set(key, value, config);
  });
};

export const setCookie = (
  key: string,
  value: Record<string, any>,
  isCrossDomain: boolean,
) => {
  let config = {};

  if (isCrossDomain) {
    config = getCookieDomain();
  }

  Cookies.set(key, JSON.stringify(value), config);
};

export const deleteFromCookies = (key: string, isCrossDomain: boolean) => {
  const attribution = Cookies.get(EQUALLY_COOKIE_KEY);

  if (!attribution) {
    return;
  }

  const attributionObj = JSON.parse(attribution);

  if (attributionObj[key]) {
    delete attributionObj[key];
  }

  if (Object.keys(attributionObj).length === 0) {
    const cookieDomain = getCookieDomain();
    Cookies.remove(EQUALLY_COOKIE_KEY, {
      path: '/',
      domain: cookieDomain.domain,
    });
    return;
  }

  setCookie(EQUALLY_COOKIE_KEY, attributionObj, isCrossDomain);
};

export const upsertCookie = (
  key: string,
  value: Record<string, any>,
  isCrossDomain: boolean,
) => {
  const cookieObj = Cookies.get(key);

  if (!cookieObj) {
    setCookie(key, value, isCrossDomain);
    return;
  }

  const parsedObj = JSON.parse(cookieObj);
  setCookie(key, { ...parsedObj, ...value }, isCrossDomain);
};
