import React from 'react';
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';

interface CustomCircularProgressProps {
  value: number;
  text?: string;
  textColor?: string;
  textSize?: string;
  pathColor?: string;
  trailColor?: string;
  strokeWidth?: number;
  showHalfCircle?: boolean;
  squarePath?: boolean;
}

export const CustomCircularProgress = ({
  value,
  text,
  textColor,
  textSize,
  pathColor,
  trailColor,
  strokeWidth,
  showHalfCircle,
  squarePath,
}: CustomCircularProgressProps) => {
  return (
    <>
      {showHalfCircle ? (
        <CircularProgressbarWithChildren
          value={value}
          strokeWidth={strokeWidth}
          circleRatio={showHalfCircle ? 0.5 : 1}
          styles={buildStyles({
            textColor,
            textSize,
            pathColor,
            trailColor,
            rotation: showHalfCircle ? -0.25 : 0,
            strokeLinecap: squarePath ? 'butt' : 'round',
          })}
        >
          <TextContent>{text}</TextContent>
        </CircularProgressbarWithChildren>
      ) : (
        <CircularProgressbar
          value={value}
          text={text}
          strokeWidth={strokeWidth}
          circleRatio={showHalfCircle ? 0.5 : 1}
          styles={buildStyles({
            textColor,
            textSize,
            pathColor,
            trailColor,
            rotation: showHalfCircle ? -0.25 : 0,
            strokeLinecap: squarePath ? 'butt' : '',
          })}
        />
      )}
    </>
  );
};

const TextContent = styled.p`
  color: #0d1526;
  font-size: 20px;
  font-weight: 500;
  margin-top: -20px;
`;
