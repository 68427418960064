import styled from '@emotion/styled';
import { Grid, GridSize } from '@mui/material';
import React from 'react';
import { GridItem, Variant } from './grid-item';

export type Size = {
  xs: GridSize;
  sm: GridSize;
  md: GridSize;
};

export type FieldMap = {
  key: string;
  size: Size;
  align?: 'left' | 'center' | 'right';
  format?: (
    row: { [key: string]: string | number },
    key: string,
    isLoading?: boolean,
  ) => string | React.ReactNode;
  hideOnMobile?: boolean;
  onClick?: (key: string) => void;
  withAction?: boolean;
  icon?: React.ReactNode;
};

interface GridItemProps {
  rowMap: FieldMap[];
  rowData: { [key: string]: string | number };
  variant: Variant;
  emptyRow?: Size;
  children?: React.ReactNode;
  disableborder?: boolean;
  rowPadding?: string;
  isLoading?: boolean;
  handleRowClick?: () => void;
}
export const GridRow = ({
  rowMap,
  rowData,
  variant,
  children,
  emptyRow,
  disableborder,
  rowPadding,
  isLoading,
  handleRowClick,
}: GridItemProps) => {
  return (
    <Container
      container
      alignItems="center"
      disableborder={disableborder}
      style={{ padding: rowPadding || '10px' }}
      variant={variant}
      onClick={handleRowClick}
    >
      {emptyRow && <GridItem label="" size={emptyRow} variant={variant} />}
      {rowMap.map((item, index) => (
        <GridItem
          size={item.size}
          label={
            item.format
              ? item.format(rowData, item.key, isLoading)
              : `${rowData[item.key]}`
          }
          align={item.align}
          variant={variant}
          hideOnMobile={item.hideOnMobile}
          key={`domains-table-row-${index}`}
          onClick={item.onClick}
          itemKey={item.key}
          icon={item.icon}
        />
      ))}
      {children}
    </Container>
  );
};

interface ContainerProps {
  disableborder?: boolean;
  variant: Variant;
}

const Container = styled(Grid)<ContainerProps>`
  border: ${(props) => (props.disableborder ? 'none' : '1px solid #e0e0e0')};
  position: relative;
  border-radius: 4px;
  min-height: ${(props) =>
    props.variant === 'dashboard-sub-heading' ? '13vh' : ''};

  @media screen and (max-width: 600px) {
    justify-content: space-between;
  }
`;
