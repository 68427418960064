import auditSettings from '@equally-ai-front/common/src/redux/audit-settings-slice/audit-settings';
import business from '@equally-ai-front/common/src/redux/business-slice/business';
import scanRecommendations from '@equally-ai-front/common/src/redux/developer-slice';
import domainsReducer from '@equally-ai-front/common/src/redux/domains-slice/domains-slice';
import { history } from '@equally-ai-front/common/src/redux/historySlice';
import newPlans from '@equally-ai-front/common/src/redux/plans-slice/plans';
import screenSlice from '@equally-ai-front/common/src/redux/screen-slice/screen-slice';
import services from '@equally-ai-front/common/src/redux/services-slice/services';
import { settings } from '@equally-ai-front/common/src/redux/settings-slice';
import userProducts from '@equally-ai-front/common/src/redux/user-products-slice/user-products';
import complianceDocuments from '@equally-ai-front/common/src/redux/compliance-documents-slice/compliance-documents';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import issuesReducer from '../components/compliance-manager/store/slices/issues-slice';
import overviewReducer from '../components/compliance-manager/store/slices/overview-slice';
import progressReducer from '../components/compliance-manager/store/slices/progress-slice';
import dashboard from './dashboard-slice/dashboard';
import billingProfile from './reducers/billingProfile';
import compliance from './reducers/compliance';
import domains from './reducers/domains';
import navigation from './reducers/navigation';
import personalDetails from './reducers/personalDetails';
import plans from './reducers/plans';
import prices from './reducers/prices';
import subscriptions from './reducers/subscriptions';
import team from './reducers/team';
import user from './reducers/user';
import saga from './saga';

export default function createReduxStore() {
  const reducers = combineReducers({
    user,
    navigation,
    domains,
    compliance,
    subscriptions,
    prices,
    billingProfile,
    team,
    personalDetails,
    plans,
    newPlans,
    services,
    userProducts,
    issues: issuesReducer,
    progress: progressReducer,
    devDomains: domainsReducer,
    overview: overviewReducer,
    business,
    scanRecommendations,
    scanHistory: history,
    settings,
    dashboard: dashboard,
    auditSettings: auditSettings,
    screen: screenSlice,
    complianceDocuments,
  });
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: reducers, // use your combined reducer
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
  });

  sagaMiddleware.run(saga);

  return store;
}

const store = createReduxStore();

// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
