import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { TableHeader } from './table-header';
import { TableBody } from './table-body';
import { CustomReportFields } from '../../exports/export';

const styles = StyleSheet.create({
  pdfReportTable: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
});

interface PdfReportTableProps {
  pdfReport: DevtoolsSolution[];
  customReportFields: CustomReportFields | null;
  isColorShadeOfWhiteOrGrey: boolean;
  columns: string[];
}

export const Table = (props: PdfReportTableProps) => {
  const { pdfReport, customReportFields, isColorShadeOfWhiteOrGrey, columns } =
    props;
  return (
    <View style={styles.pdfReportTable}>
      <TableHeader isColorShadeOfWhiteOrGrey={isColorShadeOfWhiteOrGrey} />
      <TableBody
        pdfReport={pdfReport}
        customReportFields={customReportFields}
        isColorShadeOfWhiteOrGrey={isColorShadeOfWhiteOrGrey}
        columns={columns}
      />
    </View>
  );
};
