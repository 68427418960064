import { IssueType, ReportData } from '../utils/devToolsTypeConfig';

export const getReportSelectors = (
  report: ReportData,
): Record<IssueType, string[]> => {
  let issueTypeToSelectors: Record<IssueType, string[]> = {
    basic: [],
    advanced: [],
    potential: [],
    all: [],
  };
  const { list } = report;

  for (const issueType in list) {
    const issues = list[issueType as IssueType];
    issueTypeToSelectors[issueType as IssueType] = issues.map(
      (issue) => issue.selector,
    );
  }
  return issueTypeToSelectors;
};
