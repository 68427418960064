import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

interface PaginationProps {
  pageCount: number;
  handlePageChange: (selectedPage: { selected: number }) => void;
}

export const Pagination = ({
  pageCount,
  handlePageChange,
}: PaginationProps) => {
  return (
    <>
      {pageCount > 1 && (
        <Wrapper>
          <PaginationStyle>
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={2}
              breakLabel="..."
              onPageChange={handlePageChange}
              containerClassName="container"
              breakClassName="break-page"
              nextLabel={<Label>Next</Label>}
              previousLabel={<Label>Prev</Label>}
              pageClassName="page"
              disabledClassName="disabled"
              activeClassName="active"
              disableInitialCallback
            />
          </PaginationStyle>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Label = styled.span`
  font-size: 13px;
  font-weight: 600;
`;

const PaginationStyle = styled.div`
  width: 500px;
  display: flex;
  justify-content: flex-end;

  .container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
  }

  .page {
    min-width: 28px;
    min-height: 26px;
    margin: 8px 3px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4e7eb;
    cursor: pointer;

    a {
      width: 100%;
      min-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      background-color: #454284;
      color: #fff;
      border: none;
    }
  }

  .disabled,
  .disabled a {
    cursor: not-allowed !important;
    color: #cccccc !important;
  }

  .previous,
  .next,
  .break-page {
    content: '';
    cursor: pointer;
    background: transparent;
    color: #000a14;
    margin: 0px 8px;
  }
`;
