import React, { useRef, useState } from 'react';

//create a return type
export type ConfettiHookReturnType = {
  isConfettiActive: boolean;
  setIsConfettiActive: (isActive: boolean) => void;
  confettiWidth: number;
  confettiX: number;
  isConfettiUsed: React.MutableRefObject<boolean>;
};
export const useConfetti = (): ConfettiHookReturnType => {
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const confettiWidth = 600;
  const confettiX = (window.innerWidth - confettiWidth) / 2;
  const isConfettiUsed = useRef(false);

  return {
    isConfettiActive,
    setIsConfettiActive,
    confettiWidth,
    confettiX,
    isConfettiUsed,
  };
};
