import React, { useCallback } from 'react';
import { Menu, Button, Typography, Box, Divider } from '@mui/material';
import styled from 'styled-components';

import { useAnchorEl } from '../../hooks/use-anchor-el';
import { ItemFilter } from './types';
import { FilterOptions } from './custom-select/filter-options';
import { FilterConditionSelect } from './custom-select/filter-condition-select';
import ArrowLeft from '../../assets/svg/chevron-left-black.svg';
import { ButtonWithIcon } from '../button/ButtonWithIcon';

interface MultiFilterSelectProps {
  searchTerm?: string;
  options: any[];
  filterValueOptions: any[];
  handleFilterOperatorChange: (
    operator: string,
    filterValue?: string | number,
    isSatisfied?: (obj: any) => boolean,
    isPreset?: boolean,
  ) => void;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  handleOptionClick: (filterKey: string | undefined) => void;
  handleSearchInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  isFilterFulfilled: () => boolean;
  currentFilter?: ItemFilter;
  handleFilterValueChange: (key: string, value: string) => void;
  handleFilterSecondValueChange: (key: string, value: string) => void;
  addFilter: (filter: ItemFilter) => void;
  filterType?: 'preset' | 'custom';
  headerButtonComponent?: React.ElementType;
}

export const MultiFilterSelect = ({
  // searchTerm,
  // setSearchTerm,
  // handleSearchInputChange,
  addFilter,
  filterValueOptions,
  options,
  handleOptionClick,
  handleFilterOperatorChange,
  handleFilterValueChange,
  handleFilterSecondValueChange,
  isFilterFulfilled,
  currentFilter,
  filterType,
  headerButtonComponent,
}: MultiFilterSelectProps) => {
  const { anchorEl, handleClose, handleClick } = useAnchorEl();
  // const [isOpen, setIsOpen] = React.useState(false);

  const revertFilter = useCallback(() => {
    handleOptionClick(undefined);
    handleClose();
  }, [currentFilter]);

  const handleApplyFilter = () => {
    if (!currentFilter) {
      return;
    }
    const isFiltered = isFilterFulfilled();

    if (!isFiltered) {
      return;
    }
    addFilter(currentFilter);
    handleOptionClick(undefined);
    handleClose();
  };

  const FilterButton = headerButtonComponent || Button;

  return (
    <>
      <FilterButton onClick={!Boolean(anchorEl) ? handleClick : revertFilter}>
        Filters
      </FilterButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={revertFilter}>
        {!(currentFilter?.key && currentFilter?.filterType) ? (
          <FilterOptions
            filteredOptions={options}
            handleOptionClick={handleOptionClick}
          />
        ) : (
          <InputWrapper onClick={(e) => e.stopPropagation()}>
            <Box flexDirection={'row'} alignItems={'center'} display={'flex'}>
              <ButtonWithIcon
                iconSrc={ArrowLeft}
                iconAltText="Back"
                backgroundColor="transparent"
                borderStyle="none"
                onClickAction={revertFilter}
                buttonText=""
                iconWidth="10px"
                hoverColor="#F5F7FA"
              />

              <FilterHeader
                variant={'h1'}
                children={currentFilter.displayName}
              />
            </Box>
            <Divider />
            <Box flexDirection={'column'}>
              <FilterConditionSelect
                currentFilter={currentFilter}
                filterValueOptions={filterValueOptions}
                handleFilterOperatorChange={handleFilterOperatorChange}
                handleFilterValueChange={handleFilterValueChange}
                handleFilterSecondValueChange={handleFilterSecondValueChange}
                filterOptionType={filterType}
              />
            </Box>
            <ButtonContainer>
              <ApplyBtn
                disabled={!isFilterFulfilled()}
                onClick={handleApplyFilter}
              >
                Apply
              </ApplyBtn>
            </ButtonContainer>
          </InputWrapper>
        )}
      </Menu>
    </>
  );
};

const ApplyBtn = styled(Button)`
  border: none;
  height: fit-content;
  border-radius: 6px;
  cursor: pointer;
`;

const InputWrapper = styled(Box)`
  width: 300px;
`;
const ButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
// const StyledMenu = styled(Menu)`
//   display: flex;
//   flex-direction: column;
// `;
const FilterHeader = styled(Typography)`
  display: flex;
  justify-content: center;

  &.MuiTypography-h1 {
    font-size: 16px;
    font-weight: 600;
  }
`;
