import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';
import { ApiResponse } from '../utils/axios';
import {
  FeedbackSuggestionApiPayload,
  GptApiPayload,
  GptError,
  GptResponse,
} from '../types/chat-gpt';

class ChatGPT {
  static async sendPrompt(payload: GptApiPayload): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.post(
        HTTP_CLIENT_ENDPOINT +
          `/api/v1/admin/a/${payload?.businessId}/widget/accessibility/regenerate`,
        payload,
      );
    } catch (err) {
      console.error('Error posting data', err);
      error = {
        message: 'Error getting recommendation',
        gptType: '',
      };
    }
    const { data } = result || {};

    return {
      data: (data?.data || []) as GptResponse,
      isSuccess: data?.code === 200,
      error: error as unknown as GptError,
    };
  }

  static async feedbackPrompt(
    payload: FeedbackSuggestionApiPayload,
  ): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.post(
        HTTP_CLIENT_ENDPOINT + `/api/v1/admin/a/widget/accessibility/feedbacks`,
        payload,
      );
    } catch (err) {
      console.error('Error posting data', err);
      error = err;
    }
    const { data } = result || {};

    return {
      data: data?.success,
      isSuccess: data?.success,
      error: error as GptError,
    };
  }
}

export default ChatGPT;
