import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled as muiStyled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SidePanelContent } from './side-panel-content';
import { ComplianceManagerRoutes } from './compliance-manager-routes';

const drawerWidth = 350;

const Main = muiStyled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth - 40}px`,
  paddingTop: 0,
  width: `calc(100% - ${drawerWidth + 50}px)`,
  height: `calc(100vh - 80px)`,

  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margin: '0 25px',
  }),

  [theme.breakpoints.down(768)]: {
    padding: 0,
    margin: '30px auto 80px auto',
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = muiStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 'fit-content',
  right: 'initial',
  backgroundColor: 'transparent',
  borderRadius: 10,
  zIndex: 9,
  top: 80,
  left: 10,
  display: 'none',

  '& button:focus': {
    border: '3px solid #000000',
  },

  '@media (pointer: none), (pointer: coarse)': {
    display: 'block',
  },

  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomDrawer = muiStyled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ open }) => ({
  display: 'block',

  '@media (pointer: none), (pointer: coarse)': {
    display: `${open ? 'block' : 'none'}`,
  },
}));

const CustomIconButton = muiStyled(IconButton)(() => ({
  display: 'none',

  '@media (pointer: none), (pointer: coarse)': {
    display: 'block',
    position: 'absolute',
    right: 0,
    zIndex: 20,
  },
}));

export const ComplianceManagerNav = () => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      {/* <CustomBox> */}
      {/* <CssBaseline />
      <AppBar position="absolute" open>
        <Toolbar
          style={{
            padding: 0,
            height: 40,
            minHeight: 20,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpenDrawer}
            edge="start"
            sx={{ margin: 0, ...(openDrawer && { display: 'none' }) }}
          >
            <MenuIcon sx={{ color: '#e4e7eb' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <CustomDrawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            position: 'absolute',
            zIndex: 1,
          },
        }}
        variant="permanent"
        anchor="left"
        open={openDrawer}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <List sx={{ paddingTop: 0 }}>
          {openDrawer && (
            <CustomIconButton onClick={handleCloseDrawer}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </CustomIconButton>
          )}
          <SidePanelContent handleCloseDrawer={handleCloseDrawer} />
        </List>
      </CustomDrawer> */}
      {/* <Main open> */}
      <>
        <ComplianceManagerRoutes />
      </>
      {/* </Main> */}
      {/* </CustomBox> */}
    </>
  );
};

const CustomBox = muiStyled(Box)`
  display: flex;
  width: 100%;
  overflow-x: auto;

  .MuiDrawer-docked .MuiDrawer-paper {
    padding: 5px;
    height: calc(100vh - 80px);
    background-color: #f1f3f5;
  }

  @media (pointer: none), (pointer: coarse) {
    margin-top: 10px;

    .MuiDrawer-docked {
      z-index: 2;
      position: fixed;
      height: calc(100% - 20px);
      width: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff;
    }

    .MuiDrawer-docked .MuiDrawer-paper {
      z-index: 2;
      height: calc(100vh - 80px);
      margin: 80px 0 0 0;
      width: 100%;
    }
  }
`;
