import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { PreferencesPage } from '../../components/compliance-manager/containers/preferences-page/preferences-page';
import { BusinessGptKey } from '../../components/gpt-key/business-gpt-key';
import { Teams } from '../../components/redesign/Teams';
import { isUserPermitted } from '../../lib/permissions';
import PlansContainer from '../plans-container';
import { NavigationBar } from './components/navigation-bar';
import { JiraIntegrationSettings } from '../../components/jira-integration-settings/jira-integration-settings';

export const BusinessSettingsRoutes = () => {
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  return (
    <PageContainer>
      <NavigationBar />
      <Routes>
        <Route index element={<Teams />} />
        <Route path={`team`} element={<Teams />} />
        <Route path={`plans`} element={<PlansContainer />} />
      </Routes>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
