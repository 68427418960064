import React from 'react';
import styled from 'styled-components';

export const CreateJiraSettingsHeader = () => {
  return (
    <Wrapper>
      <HeaderText1>
        Create jira settings, to manage the lifecycle of your issues
      </HeaderText1>
    </Wrapper>
  );
};
const Wrapper = styled.div``;

const HeaderText1 = styled.h1`
  font-weight: 400;
  font-size: 19px;
  line-height: 120%;
  color: #000a14;
  margin-bottom: 11px;
`;
