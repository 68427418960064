import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@mui/material';

const FormTextfield = styled(MuiTextField)`
  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.palette.on.darkMedium};
  }

  .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.on.darkHigh};
  }

  &:hover .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiFormLabel-root {
    &.Mui-focused {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .MuiOutlinedInput-root {
    height: 48px;

    & input {
      height: 48px;
      padding: 0 14px;
      border-radius: 8px;
      overflow: hidden;
    }

    & fieldset {
      border: 2px solid #e4e7eb;
      box-sizing: border-box;
      border-radius: 8px;
    }

    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export default FormTextfield;

export const FormTextfieldLight = styled(FormTextfield)`
  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.on.lightMedium};
  }

  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.palette.on.lightMedium};
  }

  .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.on.lightMedium};
  }

  && fieldset {
    border: 2px solid rgba(255, 255, 255, 0.24);
  }
`;
