import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from './magicValues';
export interface ApiResponse<T> {
  data: T | null;
  error: any;
  isSuccess: boolean;
}
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

const errorMessage = 'Something went wrong, please try again later';

// Create an Axios Client with defaults

const client = axios.create({
  baseURL: HTTP_CLIENT_ENDPOINT,
});

const api = axios.create({
  baseURL: HTTP_CLIENT_ENDPOINT,
  headers: { 'Content-Type': 'application/json' },
});

client.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        // it means user is unauthenticated, might want to log out the user here
      }
    }
    return Promise.reject(onError(err));
  },
);

api.interceptors.request.use((config) => {
  // const contentType = "";
  if (!config.method) {
    console.log('method is not defined');
  }
  if (!config.headers) {
    console.error('header is not defined');
    return config;
  }
  const token = localStorage.getItem('devToolsToken') ?? '';
  // setRequestHeaders(api, token, "application/json");
  if (token !== '') {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  // if (contentType !== "") {
  config.headers['Content-Type'] = 'application/json';
  // }
  return config;
});

export const onError = function (error: {
  config: any;
  response: { status: any; data: any; headers?: any };
  message: string;
}) {
  console.error('Request Failed:', error.config);
  let message: string = '';
  let statusCode: number;

  if (error.response) {
    message = error.response.data?.message ?? error.message ?? errorMessage;
    statusCode = error.response.status;
    // Request was made but server responded with something
    // other than 2xx
    console.error('Status:', error.response.status);
    console.error('Data:', error.response.data);
    console.error('Headers:', error.response.headers);
  } else {
    statusCode = 405;
    message = error.message || errorMessage;
    // Something else happened while setting up the request
    // triggered the error
    console.error('Error Message:', error.message);
  }
  return { message, statusCode };
};

// const setRequestHeaders = (client: any, token: string, contentType: string) => {
//   if (token !== "") {
//     client.defaults.headers["Authorization"] = `Bearer ${token}`;
//   }
//
//   if (contentType !== "") {
//     client.defaults.headers["Content-Type"] = contentType;
//   }
// };

export const apiCall = function (
  method: string,
  route: string,
  body = {},
  token = '',
  contentType = '',
) {
  const onSuccess = function (response: any) {
    console.debug('Request Successful!', response);
    return response;
  };

  if (token !== '') {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  if (contentType !== '') {
    axios.defaults.headers.common['Content-Type'] = contentType;
  }

  return axios({
    method,
    url: HTTP_CLIENT_ENDPOINT + route,
    data: body,
  })
    .then(onSuccess)
    .catch((err) => Promise.reject(onError(err)));
};

// Create an Axios Instance for sending Form data
const instance = axios.create({
  baseURL: HTTP_CLIENT_ENDPOINT,
  headers: { 'Content-Type': 'multipart/form-data' },
});

instance.interceptors.response.use(
  (response) => response,
  function (error) {
    return Promise.reject(error);
  },
);

export { api };
export default client;
