import { FormControl, InputLabel, Select, TextField, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LANGUAGES } from '../../common/languages';
import { isValidURL } from '../Aria/helpers';
import Switch from '../common/SwitchToggle/SwitchToggle';
import { PrimaryButton } from './Controls';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginTop: '10px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& fieldset': {
      top: '-4px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  selectIconContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      fontSize: '12px',
      marginBottom: '5px',
    },
  },
  iconContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: '100%',
  },
  switchGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '12px',
    border: '2px solid #E4E7EB',
    padding: '8px 16px',
    margin: '10px 0',
    maxHeight: 'fit-content',

    '& label': {
      height: 'max-content',
    },
    '& h6': {
      fontSize: '16px',
      fontWeight: '400',
      margin: 0,
    },

    '& p': {
      marginTop: '5px',
      fontSize: '14px',
      fontWeight: 300,
    },

    '& span': {
      fontWeight: 400,
    },

    '& input:not(:checked) + .MuiSwitch-thumb': {
      border: '1px solid #747272',
    },
  },
  select: {
    marginRight: '16px',
  },
  inputGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const SiteSettings = ({
  closeSettings,
  domain,
  appearance,
  handleUpdateAppearance,
}) => {
  const classes = useStyles();
  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );
  const [appearanceState, setAppearanceState] = useState({
    widgetConfigSitePosition: {
      alignment: 'bottom-right',
      horizontalMargin: '20px',
      verticalMargin: '20px',
    },
    widgetConfigMobilePosition: {
      alignment: 'bottom-right',
      horizontalMargin: '20px',
      verticalMargin: '20px',
    },
    widgetConfigSiteColoring: {
      backgroundColor: '#000000',
      humanColor: '#FFFFFF',
      accentColor: '#2FCA82',
    },
    widgetConfigSiteSize: { diameterDesktop: '47px', diameterMobile: '31px' },
    widgetConfigSiteAdditional: {
      enableAriaAttributes: true,
      widgetActivationAnchor: 'icon-human',
      defaultLanguage: 'en',
      enableSkipLinks: false,
      enableSafeMode: false,
      enableKeyboardNavigation: true,
      applyContrastFixes: false,
      allowDisableOffScreen: false,
      accessibilityStatementLink: '',
    },
  });

  const [enableAccessibiltyStatement, setEnableAccessibiltyStatement] =
    useState(false);

  useEffect(() => {
    setAppearanceState(appearance);
    if (appearance.widgetConfigSiteAdditional.accessibilityStatementLink) {
      setEnableAccessibiltyStatement(true);
    }
  }, [appearance]);

  const handleFormChange = (key, value) => {
    setAppearanceState({
      ...appearanceState,
      widgetConfigSiteAdditional: {
        ...appearanceState.widgetConfigSiteAdditional,
        [key]: value,
      },
    });
  };

  const validateAccessibilityStatementLinkValid = () => {
    let url =
      appearanceState.widgetConfigSiteAdditional.accessibilityStatementLink;
    if (!url.startsWith('http')) {
      url = `https://${url}`;
    }
    return isValidURL(url);
  };

  const isAccessibilityStatementLinkValid =
    validateAccessibilityStatementLinkValid();

  const disableSubmit =
    enableAccessibiltyStatement && !isAccessibilityStatementLinkValid;

  const toggleEnableAccessibilityStatementLink = (value) => {
    if (!value) {
      handleFormChange(
        'accessibilityStatementLink',
        appearance.widgetConfigSiteAdditional.accessibilityStatementLink,
      );
    }

    setEnableAccessibiltyStatement(value);
  };

  const onSaveSettingsState = () => {
    if (disableSubmit) {
      return;
    }

    handleUpdateAppearance(currentBusiness.id, domain, appearanceState, true);
    closeSettings();
  };

  return (
    <>
      <div>
        <TextField
          label="Website URL"
          disabled
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={domain}
          className={classes.input}
        />
      </div>
      <Box mt="10px" />
      <div className="customize-website-container">
        <div className="customize-website-header">Basic</div>
        <Box mt="10px" />
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Default Language
              </InputLabel>
              <Select
                native
                label="Default Language"
                inputProps={{
                  name: 'age',
                  id: 'outlined-age-native-simple',
                }}
                onChange={(e) =>
                  handleFormChange('defaultLanguage', e.target.value)
                }
                value={
                  appearanceState.widgetConfigSiteAdditional.defaultLanguage
                }
              >
                {LANGUAGES.map((lang) => (
                  <option value={lang.code} key={nanoid()}>
                    {lang.lang}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <div className={classes.switchGroup}>
              {/* eslint-disable-next-line ,jsx-a11y/label-has-associated-control */}
              <label htmlFor="safe-mode">
                <h6>Safe Mode</h6>
                <p>
                  To test the Equally widget on your site, append{' '}
                  <span>?testequally=1</span> to your URLs. For example:{' '}
                  <span>https://mydomain.com?testequally=1</span>.
                </p>
              </label>
              <Switch
                inputProps={{
                  name: 'safe-mode',
                  id: 'safe-mode',
                }}
                disableRipple
                onChange={() =>
                  handleFormChange(
                    'enableSafeMode',
                    !appearanceState.widgetConfigSiteAdditional.enableSafeMode,
                  )
                }
                checked={
                  appearanceState.widgetConfigSiteAdditional.enableSafeMode
                }
              />
            </div>
          </div>
        </div>
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <div className={classes.switchGroup} style={{ flexWrap: 'wrap' }}>
              <div className={classes.inputGroup}>
                {/* eslint-disable-next-line ,jsx-a11y/label-has-associated-control */}
                <label htmlFor="enable-accessibility-statement">
                  <h6>Custom Accessibility Statement</h6>
                  <p>Craft and include your own accessibility statements.</p>
                </label>
                <Switch
                  inputProps={{
                    name: 'enable-accessibility-statement',
                    id: 'enable-accessibility-statement',
                  }}
                  disableRipple
                  onChange={(e) =>
                    toggleEnableAccessibilityStatementLink(e.target.checked)
                  }
                  checked={enableAccessibiltyStatement}
                />
              </div>
              {enableAccessibiltyStatement && (
                <div>
                  <TextField
                    label="Accessibility Statement URL"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={
                      appearanceState.widgetConfigSiteAdditional
                        .accessibilityStatementLink
                    }
                    onChange={(e) =>
                      handleFormChange(
                        'accessibilityStatementLink',
                        e.target.value,
                      )
                    }
                    style={{ marginTop: '18px' }}
                    className={classes.input}
                  />
                  {appearanceState.widgetConfigSiteAdditional
                    .accessibilityStatementLink &&
                    !isAccessibilityStatementLinkValid && (
                      <Typography
                        variant="subtitle2"
                        color="darkred"
                        fontSize="12px"
                      >
                        Please enter a valid url
                      </Typography>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="customize-website-container">
        <div className="customize-website-header">Advanced</div>
        <Box mt="10px" />
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <div className={classes.switchGroup}>
              {/* eslint-disable-next-line ,jsx-a11y/label-has-associated-control */}
              <label htmlFor="skip-links">
                <h6>Skip Links</h6>
                <p>
                  Skip links become visible only with keyboard navigation.
                  Disable this feature if you already have similar
                  functionality.
                </p>
              </label>
              <Switch
                inputProps={{
                  name: 'skip-links',
                  id: 'skip-links',
                }}
                disableRipple
                onChange={() =>
                  handleFormChange(
                    'enableSkipLinks',
                    !appearanceState.widgetConfigSiteAdditional.enableSkipLinks,
                  )
                }
                checked={
                  appearanceState.widgetConfigSiteAdditional.enableSkipLinks
                }
              />
            </div>
          </div>
        </div>
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <div className={classes.switchGroup}>
              {/* eslint-disable-next-line ,jsx-a11y/label-has-associated-control */}
              <label htmlFor="aria-attributes">
                <h6>ARIA Attributes</h6>
                <p>
                  ARIA attributes improve site accessibility. Disable this
                  feature to keep your existing accessibility settings.
                </p>
              </label>
              <Switch
                inputProps={{
                  name: 'aria-attributes',
                  id: 'aria-attributes',
                }}
                disableRipple
                onChange={() =>
                  handleFormChange(
                    'enableAriaAttributes',
                    !appearanceState.widgetConfigSiteAdditional
                      .enableAriaAttributes,
                  )
                }
                checked={
                  appearanceState.widgetConfigSiteAdditional
                    .enableAriaAttributes
                }
              />
            </div>
          </div>
        </div>
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <div className={classes.switchGroup}>
              {/* eslint-disable-next-line ,jsx-a11y/label-has-associated-control */}
              <label htmlFor="keyboard-navigation">
                <h6>Keyboard Navigation</h6>
                <p>
                  Adds visible focus indicators while tabbing through your web
                  page.
                </p>
              </label>
              <Switch
                inputProps={{
                  name: 'keyboard-navigation',
                  id: 'keyboard-navigation',
                }}
                disableRipple
                onChange={() =>
                  handleFormChange(
                    'enableKeyboardNavigation',
                    !appearanceState.widgetConfigSiteAdditional
                      .enableKeyboardNavigation,
                  )
                }
                checked={
                  appearanceState.widgetConfigSiteAdditional
                    .enableKeyboardNavigation
                }
              />
            </div>
          </div>
        </div>
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <div className={classes.switchGroup}>
              {/* eslint-disable-next-line ,jsx-a11y/label-has-associated-control */}
              <label htmlFor="contrast-fixes">
                <h6>Contrast</h6>
                <p>Ensure contrast compliance.</p>
              </label>
              <Switch
                inputProps={{
                  name: 'contrast-fixes',
                  id: 'contrast-fixes',
                }}
                disableRipple
                onChange={() =>
                  handleFormChange(
                    'applyContrastFixes',
                    !appearanceState.widgetConfigSiteAdditional
                      .applyContrastFixes,
                  )
                }
                checked={
                  appearanceState.widgetConfigSiteAdditional.applyContrastFixes
                }
              />
            </div>
          </div>
        </div>
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <div className={classes.switchGroup}>
              {/* eslint-disable-next-line ,jsx-a11y/label-has-associated-control */}
              <label htmlFor="disable-offscreen">
                <h6>Offscreen Elements</h6>
                <p>
                  Adds aria-hidden on elements that are beyond the current
                  display boundaries.
                </p>
              </label>
              <Switch
                inputProps={{
                  name: 'disable-offscreen',
                  id: 'disable-offscreen',
                }}
                disableRipple
                onChange={() =>
                  handleFormChange(
                    'allowDisableOffScreen',
                    !appearanceState.widgetConfigSiteAdditional
                      .allowDisableOffScreen,
                  )
                }
                checked={
                  appearanceState.widgetConfigSiteAdditional
                    .allowDisableOffScreen
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="add-website-fields">
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={onSaveSettingsState}
          style={{ width: '83px' }}
          disabled={disableSubmit}
        >
          Save
        </PrimaryButton>
      </div>
    </>
  );
};

export default SiteSettings;

SiteSettings.propTypes = {
  domain: PropTypes.string.isRequired,
  closeSettings: PropTypes.func.isRequired,
  appearance: PropTypes.object.isRequired,
};
