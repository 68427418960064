import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const TabPanel = ({ children, value, index }) => {
  return value === index && <Box style={{ marginTop: '8px' }}>{children}</Box>;
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
