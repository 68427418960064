import styled from '@emotion/styled';

import { SmallPill } from '../../../common/Pill';
import { PrimaryButton } from '../../Controls';

export const Card = styled.div`
  border: 2px solid #e4e7eb;
  border-radius: 12px;
  margin: 0px 40px 40px 0px;
  width: 380px;
  margin: 0px 40px 40px 0px;
  overflow: hidden;

  & > div {
    padding: 32px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 350px;
    margin: 0px 20px 40px 0px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;

    & > div {
      padding: 24px;
    }
  }
`;

export const ActionButton = styled(PrimaryButton)`
  width: 100%;
  padding: 6px 16px 6px 12px;
  height: 36px;
  justify-content: flex-start;
  margin-top: 30px;
  border: 2px solid #c2f4d7;

  svg {
    margin-right: 5px;
  }
`;

export const TextHeading = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000a14;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  margin-bottom: 8px;
`;

export const ProjectTypePill = styled(SmallPill)`
  background-color: #99d3ff;
  color: #194a55;
  margin-right: 10px;
`;

export const ProjectStatusPill = styled(SmallPill)`
  background-color: #f2df87;
  text-transform: uppercase;
`;

export const ProjectQoute = styled.div`
  background-color: #f5f7fa;
`;
