import styled from '@emotion/styled';
import { PrimaryButton } from '../../Controls';

export const FormHeader = styled.div`
  overflow: hidden;
  max-height: 350px;

  @media screen and (max-width: 600px) {
    margin: 10px 0;
  }
`;

export const FormContainer = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    padding: 10px 15px;
  }
`;

export const SubtitleText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 20px auto;
`;

export const ActionButton = styled(PrimaryButton)`
  margin-top: auto;

  @media screen and (max-width: 600px) {
    margin-top: 10px;
  }
`;

export const ModalContentContainer = styled.div`
  min-height: 450px;
  max-width: 550px;
  width: 100%;
  display: flex;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    max-width: 100%;
    height: 100%;
  }
`;
