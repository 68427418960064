import {
  PlanWithPlanTemplate,
  Subscription,
} from '../types/plans-and-subscriptions';

const isPlanSubscriptionExpired = (expirationDate: string): boolean => {
  return new Date(expirationDate) < new Date();
};

const isAnyPlanActive = (plans: PlanWithPlanTemplate[]): boolean => {
  for (const plan of plans) {
    if (plan.user_id && !isPlanSubscriptionExpired(plan.expiration)) {
      return true;
    }
  }
  return false;
};

export const handleIsReviewRequired = (data: any, value?: string): boolean => {
  const plans: PlanWithPlanTemplate[] = data?.plans || [];
  const subscription: Subscription = data?.subscription || null;
  if (plans.length === 0 || !subscription) return false;

  let shouldReviewNow = false;
  if (
    subscription.user_id &&
    isPlanSubscriptionExpired(subscription.expiration) &&
    !isAnyPlanActive(plans) &&
    !subscription.cancelled_at &&
    !subscription.suspended_at
  ) {
    shouldReviewNow = true;
  }

  if (value && value.toLowerCase() === 'false') {
    return !shouldReviewNow;
  }
  return shouldReviewNow;
};
