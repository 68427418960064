import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
  label: string;
  percentage: string;
  bgColor: string;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { label, percentage, bgColor } = props;

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Progress>
        <BarWrapper>
          <Bar $percentage={percentage} $bgcolor={bgColor} />
        </BarWrapper>
        <Percentage>{percentage}</Percentage>
      </Progress>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.p`
  font-weight: 500;
  width: 100%;
  max-width: 35%;
  font-size: 15px;
`;

const Progress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 55%;
`;

const BarWrapper = styled.div`
  width: 100%;
  max-width: 70%;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 10px;
`;

type BarProps = { $percentage: string; $bgcolor: string };
const Bar = styled.div<BarProps>`
  height: 100%;
  border-radius: 10px;
  width: 100%;
  max-width: ${(props) => props.$percentage};
  background-color: ${(props) => props.$bgcolor};
`;

const Percentage = styled.p`
  font-size: 14px;
  opacity: 0.7;
  width: 100%;
  max-width: 21%;

  @media screen and (min-width: 1400px) {
    max-width: 11%;
  }
`;
