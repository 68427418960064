import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../RootState';
import { initialState } from './index';

const selector = (state: RootState) => state?.settings || initialState;

export const selectSettings = createSelector(
  [selector],
  (setting) => setting.settings,
);

export const selectLoadingSettings = createSelector(
  [selector],
  (setting) => setting.loading,
);

export const selectSettingsError = createSelector(
  [selector],
  (setting) => setting.error,
);

export const selectSettingsMessage = createSelector(
  [selector],
  (setting) => setting.message,
);

export const selectDevDomain = createSelector(
  [selector],
  (setting) => setting.devDomain,
);

export const selectLoadingDevDomain = createSelector(
  [selector],
  (setting) => setting.loadingDevDomain,
);

export const selectProjectTrackingTags = createSelector(
  [selector],
  (setting) => setting.projectTrackingTags,
);

export const selectPipeLineTags = createSelector(
  [selector],
  (setting) => setting.pipeLineTags,
);

export const selectPlatformTags = createSelector(
  [selector],
  (setting) => setting.platformTags,
);
