import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { PrimaryButton } from './Controls';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentItem: {
    maxWidth: '560px',
    marginBottom: '64px',
  },
  input: {
    height: '48px',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
  inputCvc: {
    height: '48px',
    maxWidth: '105px',
    marginRight: '24px',
    marginBottom: '28px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

export const BillingInformation = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  return (
    <div
      className={classes.content}
      style={
        isMobile
          ? {
              alignItems: 'flex-start',
              padding: '64px 24px',
              marginBottom: '47px',
            }
          : {}
      }
    >
      <div
        className={classes.contentItem}
        style={
          isMobile
            ? {
                width: '100%',
              }
            : {}
        }
      >
        <div className="header-page">
          <div className="header-page-text">Billing information</div>
        </div>
        <div className="content-page-form">
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Full name / company name"
            defaultValue=""
            variant="outlined"
            className={classes.input}
          />
          <TextField
            className={classes.input}
            label="Street address"
            defaultValue=""
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className={classes.input}
            label="City"
            defaultValue=""
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className={classes.input}
            label="Country"
            defaultValue=""
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className={classes.input}
            label="VAT number (if you have one)"
            defaultValue=""
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <PrimaryButton
            color="primary"
            style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
            variant="contained"
          >
            Save Billing Information
          </PrimaryButton>
        </div>
      </div>
      <div className={classes.contentItem}>
        <div className="header-page">
          <div className="header-page-text">Credit/Debit Card Details</div>
        </div>
        <div className="content-page-form">
          <TextField
            label="Card number"
            defaultValue=""
            variant="outlined"
            className={classes.input}
            InputLabelProps={{ shrink: true }}
          />
          <div>
            <TextField
              className={classes.inputCvc}
              label="Expiry date"
              placeholder="MM/YYYY"
              defaultValue=""
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
            <TextField
              className={classes.inputCvc}
              label="CVC"
              type="password"
              defaultValue=""
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </div>
          <PrimaryButton
            color="primary"
            style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
            variant="contained"
          >
            Save Credit/Debit Card
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
