import styled from '@emotion/styled';

export const DropdownContainer = styled.div`
  position: absolute;
  top: -50%;
  left: 0;
  z-index: 30;
  background-color: #fff;
  width: max-content;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 2px 8px 25px rgba(0, 0, 0, 0.35);
`;
