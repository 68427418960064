import axios from 'axios';
import { Issue, Scan } from '../types/issues';
import { ApiResponse } from '../utils/axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';

export class ComplianceService {
  static async export(
    websiteId: number,
    businessId: number,
    domainUrl?: string | undefined,
    scanId?: number | undefined,
    statuses?: string | undefined,
    sources?: string | undefined,
    component?: string | undefined,
  ): Promise<ApiResponse<Issue[]>> {
    let result;
    let error;
    try {
      const queryParams = new URLSearchParams();
      queryParams.append('website_id', String(websiteId));

      if (domainUrl) {
        queryParams.append('domain_url', String(domainUrl));
      }

      if (scanId) {
        queryParams.append('scan_id', String(scanId));
      }
      if (component) {
        queryParams.append('component', String(component));
      }

      if (statuses) {
        queryParams.append('statuses', String(statuses));
      }

      if (sources) {
        queryParams.append('sources', String(sources));
      }

      let apiUrl = `/api/v1/admin/a/${businessId}/compliance/scanned-issues?${queryParams.toString()}`;

      result = await axios.get(HTTP_CLIENT_ENDPOINT + apiUrl);
    } catch (err) {
      console.error('Error getting domains', err);
      error = err;
    }

    const { data } = result || {};

    return {
      data: (data?.data || []) as Issue[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getScan(businessId: number, scanId: number) {
    let result;
    let error;
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/scans/${scanId}`,
      );
    } catch (err) {
      console.error('Error getting scan', err);
      error = err;
    }

    const { data } = result || {};

    return {
      data: data?.data,
      isSuccess: data?.code === 200,
      error: error,
    };
  }
  static async getLatestScan(
    businessId: number,
    websiteId: number,
    url: string,
  ): Promise<ApiResponse<{ scan: Scan; issues: Issue[] }>> {
    let result;
    let error;
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/scan/${websiteId}?domain_url=${url}`,
      );
    } catch (err) {
      console.error('Error getting scan', err);
      error = err;
    }

    const { data } = result || {};

    return {
      data: data?.data,
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getScanRecommendations(businessId: number, scanId: number) {
    let result;
    let error;
    // /api/v1/admin/a/{business_id}/compliance/{scan_id}/recommendations
    try {
      result = await axios.get(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/compliance/${scanId}/recommendations`,
      );
    } catch (err) {
      console.error('Error getting scan recommendations', err);
      error = err;
    }

    const { data } = result || {};

    return {
      data: data?.data,
      isSuccess: data?.code === 200,
      error: error,
    };
  }
}
