import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { Box, Divider } from '@mui/material';
import React from 'react';
import { ActionButtons } from '../../../components/common/Tables/action-buttons';
import { GridAccordionRow } from '../../../components/generic-grid/grid-accordion-row';
import {
  FieldMap,
  GridRow,
  Size,
} from '../../../components/generic-grid/grid-row';
import { formatExpiration } from '../../../lib/date';
import { usePlans } from '../../../providers/plans-context';
import {
  isAttachPlanEligible,
  isDetachPlanEligible,
} from '@equally-ai/cart-checkout/src/utils/plans.utils';

interface WidgetTableBodyProps {
  data: any[];
  handleManageModal: (
    event: React.MouseEvent<HTMLElement>,
    domain: any,
  ) => void;
  launchFlowy: (id: number, url: string) => void;
  currentPage: number;
}

const eclipseWord = (word: string, maxLength: number) => {
  if (word.length > maxLength) {
    return `${word.substring(0, maxLength)}...`;
  }
  return word;
};

const accordionMap: FieldMap[] = [
  {
    key: 'index',
    size: {
      sm: 0.5,
      xs: 1,
      md: 1,
    },
    hideOnMobile: true,
  },
  {
    key: 'url',
    size: {
      sm: 2,
      xs: 6,
      md: 3,
    },
    format: (row) => eclipseWord(String(row.url), 20),
  },
];

const detailsMap: FieldMap[] = [
  {
    key: 'expires',
    size: {
      sm: 2,
      xs: 6,
      md: 1.5,
    },
    format: (row) => formatExpiration(row.expires),
  },
  {
    key: 'plan_id',
    size: {
      sm: 2,
      xs: 3,
      md: 1.5,
    },
    hideOnMobile: true,
    format: (row) => (
      <strong style={{ fontWeight: 500 }}>
        {row.plan_id ? `Plan` : 'Free Trial'}
      </strong>
    ),
  },
  {
    key: 'type',
    size: {
      sm: 1.5,
      xs: 6,
      md: 1.5,
    },
    format: (row: any) => (
      <strong style={{ fontWeight: 500, textTransform: 'capitalize' }}>
        {row.type === 'developer' ? 'dashboard' : row.type}
      </strong>
    ),
  },
];

const emptyRow: Size = {
  sm: 3,
  xs: 6,
  md: 3,
};

export const WidgetTableBody = ({
  data,
  handleManageModal,
  launchFlowy,
  currentPage,
}: WidgetTableBodyProps) => {
  const isMobile = useMediaQuery(`(max-width: 600px)`);
  const { activePlans: plansData } = usePlans();

  const formatActionButton = (row: any) => {
    const buttonProps = {
      showQuickActions: true,
      handleSelectPlan: handleManageModal,
      actionLabel: 'Manage',
      allowAttach: isAttachPlanEligible(plansData || [], row.type),
      allowDetach: isDetachPlanEligible(plansData || []),
      flowyAction: (e: any, domain: any) => launchFlowy(domain.id, domain.url),
      showFlowyAction: (domain: any) =>
        domain.type === 'developer' || domain.type === 'flowy',
    };

    return <ActionButtons actionButtonProp={buttonProps} row={row} />;
  };

  const actionFormatMap: FieldMap = {
    key: 'actions',
    size: {
      sm: 4,
      xs: 6,
      md: 3,
    },
    format: formatActionButton,
  };

  if (isMobile) {
    detailsMap[0].align = 'right';
  } else {
    detailsMap[0].align = 'left';
  }

  const padding = isMobile ? '5px 15px' : '0 10px';

  return (
    <>
      <Box mt="10px" />
      {data.map((domain, idx) => {
        // const detailsData: any[] = getDomainSummary(domain);
        const pageNumber = currentPage + 1;
        const rowIndex = `${currentPage > 0 ? pageNumber : ''}${idx + 1}`;
        return (
          <React.Fragment key={`${domain.url}-main`}>
            {domain.is_developer ? (
              <GridAccordionRow
                accordionMap={[...accordionMap, ...detailsMap, actionFormatMap]}
                rowData={{ ...domain, index: rowIndex }}
                variant="sub-heading"
                rowPadding={padding}
                key={`${domain.id}-sub-heading`}
              >
                {domain.toSpliced(0, 1).map((domainItem) => (
                  <React.Fragment key={`${domainItem.id}-${domainItem.url}`}>
                    <Divider />
                    <GridRow
                      disableborder
                      emptyRow={emptyRow}
                      rowData={domainItem}
                      rowMap={[...detailsMap, actionFormatMap]}
                      rowPadding={padding}
                      variant="sub-heading"
                    />
                  </React.Fragment>
                ))}
              </GridAccordionRow>
            ) : (
              <React.Fragment key={`${domain.id}-${domain.url}`}>
                <GridRow
                  rowData={{ ...domain, index: rowIndex }}
                  rowMap={[...accordionMap, ...detailsMap, actionFormatMap]}
                  variant="sub-heading"
                  rowPadding={padding}
                />
                <Box mb="10px" />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
