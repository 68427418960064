import styled from '@emotion/styled';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import {
  createGptKey,
  getGptKey,
} from '@equally-ai-front/common/src/redux/business-slice/business';
import { Box, IconButton, Alert } from '@mui/material';
import { CheckCircle, Close, Edit } from '@mui/icons-material';
import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericInput } from '../accessibility-components/generic-input';
import { ContainedButton } from '../common/ActionButton';

export const BusinessGptKey = () => {
  const [newGptKey, setNewGptKey] = useState('');
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const { currentBusiness, isLoading, isKeyExisting } = useSelector(
    (state: any) => ({
      currentBusiness: state.business.currentBusiness,
      isKeyExisting: state.business.currentBusinessGptKeyExist,
      isLoading: state.business.loading,
    }),
  );
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const handleChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => setNewGptKey(evt.target.value);

  const fetchKeys = useCallback(async () => {
    if (!currentBusiness.id) {
      return;
    }
    dispatch(getGptKey({ businessId: currentBusiness.id }));
  }, [dispatch, currentBusiness]);

  const onAddKey = () => {
    if (!newGptKey) {
      return;
    }
    setNewGptKey('');
    setIsInputEnabled(false);
    dispatch(createGptKey({ businessId: currentBusiness.id, key: newGptKey }));
  };

  useEffect(() => {
    fetchKeys();
  }, [fetchKeys]);

  const enableInputEdit = () => setIsInputEnabled(true);
  const disableInputEdit = () => setIsInputEnabled(false);

  const value =
    isKeyExisting && !isInputEnabled ? 'XXX-XXX-XXX-XXX' : newGptKey;

  let actionButton = isKeyExisting ? (
    <Box display="flex" alignItems="center" marginRight="5px">
      <CheckCircle style={{ fill: '#8eaf55' }} height="14px" width="14px" />
      <IconButton onClick={enableInputEdit} aria-label="Edit key">
        <Edit height="14px" width="14px" />
      </IconButton>
    </Box>
  ) : null;

  if (isInputEnabled) {
    actionButton = (
      <Box display="flex" alignItems="center" marginRight="5px">
        <IconButton onClick={disableInputEdit} aria-label="Cancel">
          <Close height="14px" width="14px" />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Title>GPT key</Title>
      <StyledAlert variant="outlined" severity="error">
        To get unlimited GPT requests, use your GPT key.
      </StyledAlert>
      <Box mt="40px" display="flex" flexDirection="column" maxWidth="500px">
        <Box display="flex" alignItems="center">
          <GenericInput
            value={value}
            onChange={handleChange}
            ariaProps={{ 'aria-label': 'Gpt Key' }}
            label="Gpt Key"
            placeholder="GPT key"
            disabled={isKeyExisting && !isInputEnabled}
            fullWidth
          />
          {actionButton}
        </Box>
        {(!isKeyExisting || isInputEnabled) && (
          <ActionButton onClick={onAddKey} disabled={!newGptKey}>
            Add Key
          </ActionButton>
        )}
      </Box>
      <LoadingView open={isLoading} loadingText="Getting your key" />
    </>
  );
};

const Title = styled.h1`
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  color: #000a14;
  margin-bottom: 10px;
  margin-top: 0;
`;

const StyledAlert = styled(Alert)`
  border: none;
  color: #6c7277;
  padding: 0 5px;

  & svg {
    fill: #6c7277;
  }
`;

const ActionButton = styled(ContainedButton)`
  text-transform: capitalize;
  margin-top: 30px;
`;
