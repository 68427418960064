import React from 'react';
import styled from 'styled-components';
import NoContentIcon from '../../assets/no-content-icon.svg';
import { Typography } from '@mui/material';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';

const text = 'Ready to Make Your Website Accessible?';
const formatText = (value: string): string => {
  return value[0] + value.slice(1).toLowerCase();
};
export const EmptyAudit = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Wrapper>
      <ImageContainer>
        <ImageIcon src={NoContentIcon} alt="no content" />
      </ImageContainer>
      <Text>{isMobile ? formatText(text) : text}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1.5px solid #e4e4e4;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px;
  padding: 50px 0 60px;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  @media screen and (max-width: 768px) {
    margin-left: -25px;
  }
`;

const ImageIcon = styled.img``;

const Text = styled(Typography)`
  color: #a09c9c;
  margin-top: 20px;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 450px) {
    width: 100%;
    max-width: 60%;
    margin: 20px auto 0 auto;
  }
`;
