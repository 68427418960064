import {
  IssueReportItem,
  SuccessCriteria,
  SuccessCriteriaRules,
  WCAGTemplate,
  // AuditComplianceResponse,
  DevtoolsGroup,
  DevtoolsSolution,
  GroupingByElementTemplate,
  Pa11yResponseDataType,
  PdfReport,
  ReportData,
  WcagReportListT,
} from '../../utils/devToolsTypeConfig';
import { Issue } from '../../types/issues';
import { solutions } from '../../utils/solutionsTemplate';
import groupBy from 'lodash/groupBy';
import TEMPLATE_WCAG2AA from './wcag2aa.json';
import {
  PA11Y_TYPE_ERROR,
  PA11Y_TYPE_WARNING,
  PA11Y_TYPE_NOTICE,
} from '../../utils/constants';
import { getURLDetails } from '../utils';
import { addDomainToUrl } from '../../utils';
import { Dictionary } from 'lodash';
import {
  WcagIssueCounts,
  WcagIssuesGroupingByStatus,
  calculateIssuesAndCountBasedOnStatus,
} from '../../lib';

export const getWCAG2AATemplate = () => {
  return JSON.parse(JSON.stringify(TEMPLATE_WCAG2AA));
};

const findDevToolsSolution = (pa11yItemResponse: Pa11yResponseDataType) => {
  let resultPrinciple: string = pa11yItemResponse.code;
  const lastFourCharacters: string = pa11yItemResponse.code
    .slice(-4)
    .toLowerCase();
  const lastFiveCharacters: string = pa11yItemResponse.code
    .slice(-5)
    .toLowerCase();

  if (
    pa11yItemResponse.code.includes(
      'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.',
    )
  ) {
    if (lastFourCharacters === 'name') {
      resultPrinciple =
        'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.[NodeName].Name';
    }

    if (lastFiveCharacters === 'value') {
      resultPrinciple =
        'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.[NodeName].value';
    }
  } else if (
    pa11yItemResponse.code.includes(
      'WCAG2AA.Principle1.Guideline1_3.1_3_1.H49.',
    ) &&
    pa11yItemResponse.code !==
      'WCAG2AA.Principle1.Guideline1_3.1_3_1.H49.AlignAttr'
  ) {
    resultPrinciple = 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H49.[NodeName]';
  }

  const devToolsSolutionTemplate = solutions.filter(
    (solution) =>
      solution.principle === resultPrinciple ||
      solution.principle.includes(resultPrinciple),
  )[0];

  if (devToolsSolutionTemplate) {
    return {
      ...devToolsSolutionTemplate,
      ...pa11yItemResponse,
      // id: nanoid(),
      extraIssueInfo: [],
    };
  } else {
    return {
      ...pa11yItemResponse,
      extraIssueInfo: [],
      principle: pa11yItemResponse.code,
      level: '',
      title: 'General',
      groupLabel: 'general',
      issueDescription: pa11yItemResponse.message,
      howToFixTheIssue: [],
      exampleCode: [],
      suggestedCodeFixTemplate: null,
      confidenceLevel: '80%',
      relatedWCAGLink: '',
    };
  }
};

const buildWCAGReport = (groupedData: DevtoolsSolution[]): WcagReportListT => {
  const wcagReportList: WcagReportListT = [];
  const groupingByElementTemplate: GroupingByElementTemplate = {
    clickables: [],
    forms: [],
    sensory: [],
    semantics: [],
    tables: [],
    objects: [],
    orientation: [],
    ruby: [],
    readability: [],
    graphics: [],
    images: [],
    applet: [],
    media: [],
    title: [],
    document: [],
    general: [],
  };

  groupedData.forEach((data, idx) => {
    switch (data.groupLabel) {
      case 'clickables':
        groupingByElementTemplate.clickables.push(data);
        break;
      case 'forms':
        groupingByElementTemplate.forms.push(data);
        break;
      case 'sensory':
        groupingByElementTemplate.sensory.push(data);
        break;
      case 'semantics':
        groupingByElementTemplate.semantics.push(data);
        break;
      case 'tables':
        groupingByElementTemplate.tables.push(data);
        break;
      case 'objects':
        groupingByElementTemplate.objects.push(data);
        break;
      case 'orientation':
        groupingByElementTemplate.orientation.push(data);
        break;
      case 'ruby':
        groupingByElementTemplate.ruby.push(data);
        break;
      case 'readability':
        groupingByElementTemplate.readability.push(data);
        break;
      case 'graphics':
        groupingByElementTemplate.graphics.push(data);
        break;
      case 'images':
        groupingByElementTemplate.images.push(data);
        break;
      case 'applet':
        groupingByElementTemplate.applet.push(data);
        break;
      case 'media':
        groupingByElementTemplate.media.push(data);
        break;
      case 'title':
        groupingByElementTemplate.title.push(data);
        break;
      case 'document':
        groupingByElementTemplate.document.push(data);
        break;
      case 'general':
        groupingByElementTemplate.general.push(data);
        break;
      default:
    }
  });

  if (Object.entries(groupingByElementTemplate).length > 0) {
    const reportDataObjectArray = Object.entries(groupingByElementTemplate);
    const filtered = reportDataObjectArray.filter(
      (listObject) => listObject[1]?.length > 0,
    );

    wcagReportList.push(...filtered);
  }
  return wcagReportList;
};

const calculatePercentage = (value: number, totalValue: number): number => {
  const percentage = (value / totalValue) * 100;
  const roundTo2Dp = percentage.toFixed(1);
  return Number(roundTo2Dp);
};

export const buildDevtoolsReport = (url: string, data: any): ReportData => {
  const reportGroups: DevtoolsGroup = {
    basic: [],
    advanced: [],
    potential: [],
    all: [],
  };
  const WcagCriteriaLevel = 'AA';

  let basicArray: DevtoolsSolution[] = [];
  let advancedArray: DevtoolsSolution[] = [];
  let potentialArray: DevtoolsSolution[] = [];

  data.forEach((issue: any) => {
    const solution: any = findDevToolsSolution(issue);
    if (!solution) {
      return;
    }

    switch (issue.type) {
      case PA11Y_TYPE_ERROR:
        basicArray.push(solution);
        break;
      case PA11Y_TYPE_WARNING:
        advancedArray.push(solution);
        break;
      case PA11Y_TYPE_NOTICE:
        potentialArray.push(solution);
        break;

      default:
        break;
    }
  });

  const substituteError = basicArray || [];
  const substituteNotice = potentialArray || [];
  const substituteWarning = advancedArray || [];

  const groupedByCode = groupBy(
    [...substituteError, ...substituteNotice, ...substituteWarning],
    'code',
  );
  // Get grouped issues as an array of keys and values
  const issueByKeyArr: [string, Issue[]][] = Object.entries(groupedByCode);

  const pdfReport: PdfReport[] = [];

  // Loop over the expected guideline in template grouping and check if any of the grouped issue has identical guideline and get the success and failure count. If it does push it into array containing the expected grouping

  solutions.forEach((groupValue) => {
    issueByKeyArr.forEach((issue) => {
      const issueWCAG2AAGuideline = issue[0];
      const issueWCAG2AAGuidelineValues: Issue[] = issue[1];
      let successCriteriaCount = 0;
      let failureCriteriaCount = 0;

      issueWCAG2AAGuidelineValues.forEach((issueWCAG2AAGuidelineValue) => {
        if (issueWCAG2AAGuidelineValue.type === 'error') {
          failureCriteriaCount += 1;
        } else {
          successCriteriaCount += 1;
        }
      });

      if (issueWCAG2AAGuideline === groupValue.principle) {
        const result: PdfReport = {
          groupedIssues: issueWCAG2AAGuidelineValues,
          extraInformation: {
            ...groupValue,
            successCriteriaCount,
            failureCriteriaCount,
          },
        };
        pdfReport.push(result);
      }
    });
  });

  const allIssues: Issue[] = [
    basicArray || [],
    potentialArray || [],
    advancedArray || [],
  ].reduce((acc, arr) => acc.concat(arr), []);

  const issueByKey = groupBy(allIssues, 'principle');
  const template = getWCAG2AATemplate();

  const findIssueThatContainsCode = (
    issuesObject: Dictionary<Issue[]>,
    code: string,
  ) => {
    for (const key in issuesObject) {
      if (key.includes(code)) {
        return issuesObject[key];
      }
    }
    return null;
  };

  const reportList: IssueReportItem[] = template.map(
    (guideline: WCAGTemplate) => {
      const wcagIssueCounts: WcagIssueCounts = {
        error: 0,
        warning: 0,
        notice: 0,
      };
      const wcagIssuesGroupingByStatus: WcagIssuesGroupingByStatus = {
        error: [],
        warning: [],
        notice: [],
      };
      let foundRuleCode: string[] = [];

      const successcriteria = guideline.successcriteria.map(
        (criteria: SuccessCriteria) => {
          const allIssue = criteria.rules
            .flatMap((rule: SuccessCriteriaRules) => {
              if (foundRuleCode.includes(rule.code)) {
                return;
              }

              const issueMatchedWithRule =
                issueByKey[rule.code] ||
                findIssueThatContainsCode(issueByKey, rule.code);
              foundRuleCode.push(rule.code);
              return issueMatchedWithRule;
            })
            .filter(Boolean);

          const criteriaStatus = allIssue.length === 0 && 'not-found';

          allIssue.forEach((issue) => {
            issue &&
              calculateIssuesAndCountBasedOnStatus(
                issue,
                wcagIssueCounts,
                wcagIssuesGroupingByStatus,
              );
          });

          return {
            ...criteria,
            level: WcagCriteriaLevel,
            status: criteriaStatus,
          };
        },
      );

      const { notice, warning, error } = wcagIssueCounts;
      const {
        notice: noticeIssues,
        warning: warningIssues,
        error: criticalIssues,
      } = wcagIssuesGroupingByStatus;

      return {
        ...guideline,
        successcriteria: successcriteria,
        noticeIssueCount: notice,
        warningIssueCount: warning,
        criticalIssueCount: error,
        noticeIssues,
        warningIssues,
        criticalIssues,
      };
    },
  );

  const allIssuesArray = [...basicArray, ...advancedArray, ...potentialArray];

  reportGroups.basic = buildWCAGReport(basicArray);
  reportGroups.advanced = buildWCAGReport(advancedArray);
  reportGroups.potential = buildWCAGReport(potentialArray);
  reportGroups.all = buildWCAGReport(allIssuesArray);

  const basicIssues: number = basicArray.length;
  const advancedIssues: number = advancedArray.length;
  const potentialIssues: number = potentialArray.length;
  const totalIssues = basicIssues + advancedIssues + potentialIssues;

  const basicPercent = calculatePercentage(basicIssues, totalIssues);
  const advancedPercent = calculatePercentage(advancedIssues, totalIssues);
  const potentialPercent = calculatePercentage(potentialIssues, totalIssues);

  const fullUrlWithProtocol = addDomainToUrl(url);
  const { pathname, hostname } = getURLDetails(fullUrlWithProtocol);

  return {
    reportGroups,
    list: {
      basic: basicArray,
      advanced: advancedArray,
      potential: potentialArray,
      all: [...basicArray, ...advancedArray, ...potentialArray],
    },
    reportList,
    pdfReport,
    websitePath: pathname || '',
    websiteUrl: hostname || '',
    percentages: {
      basic: basicPercent,
      advanced: advancedPercent,
      potential: potentialPercent,
    },
  };
};
