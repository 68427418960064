import styled from '@emotion/styled';
import { getBusinesses } from '@equally-ai-front/common/src/redux/business-slice/business';
import { Business } from '@equally-ai-front/common/src/types/business';
import { Paper } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { BusinessStep } from './components/business-step';
import { TeamMemberStep } from './components/team-member-step';

export const BusinessFormContainer = () => {
  const dispatch = useDispatch();
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }
    // @ts-ignore
    dispatch(getBusinesses());
  }, [currentBusiness, dispatch]);

  return (
    <PageContainer>
      <PageContent>
        <StyledPaper>
          <Routes>
            <Route index element={<BusinessStep />} />
          </Routes>
        </StyledPaper>
      </PageContent>
    </PageContainer>
  );
};

const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  max-width: 600px;
  padding: 3vh 3vw;
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //justify-content: center;
`;
const PageContent = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
