import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Box, CircularProgress, Typography } from '@mui/material';
import { buildDevtoolsReport } from '@equally-ai-front/common/src/helpers/templates';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
import { IssuesCount } from './issues-count';
import { IssuesChart } from './issues-chart';
import { PrimaryButton } from '@equally-ai/audit-ui/src/components/styled-components';
import { DashboardExportPopUp } from '../dashboard-export-popup';

interface GroupScansSummaryProps {
  scans: any[];
}

export const GroupScansSummary = (props: GroupScansSummaryProps) => {
  const { scans } = props;
  const { allIssues, loading } = useAppSelector((state) => state.issues);
  const { history } = useAppSelector((state) => state.scanHistory);
  const currentBusiness = useAppSelector(
    (state) => state.business.currentBusiness,
  );
  const currentDevDomain = useAppSelector(
    (state) => state.dashboard.currentDevDomain,
  );
  const [openExportPopUp, setOpenExportPopUp] = useState(false);

  const handleOpenExportPopUp = () => {
    setOpenExportPopUp(true);
  };

  const handleCloseExportPopUp = () => {
    setOpenExportPopUp(false);
  };

  const scanIssuesGroupingByElement = useMemo(() => {
    if (allIssues.length === 0) return [];
    return buildDevtoolsReport('', allIssues).reportGroups.all;
  }, [allIssues]);

  const pdfReport = useMemo(() => {
    if (allIssues.length === 0) return [];
    const [{ domain_url: domainUrl }] = allIssues;
    const { list } = buildDevtoolsReport(domainUrl, allIssues);
    return list.all;
  }, [allIssues]);

  if (loading) {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '20vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Wrapper>
      <IssuesCount issues={allIssues} />
      <IssuesChart
        scanIssuesGroupingByElement={scanIssuesGroupingByElement}
        issues={allIssues}
        history={history}
      />
      <HeaderWrapper>
        <Title>Scans ({scans.length})</Title>
        <ExportButton
          color="primary"
          variant="contained"
          onClick={handleOpenExportPopUp}
        >
          Export
        </ExportButton>
      </HeaderWrapper>
      {currentDevDomain && (
        <DashboardExportPopUp
          openExportPopUp={openExportPopUp}
          handleCloseExportPopUp={handleCloseExportPopUp}
          currentBusinessId={currentBusiness.id}
          currentDevDomainId={currentDevDomain.id}
          pdfReport={pdfReport}
          hostCurrentFullUrl={pdfReport?.[0]?.domain_url}
          devDomainName={currentDevDomain.url}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 80px;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Typography)`
  color: #b2b5b8;
`;

const ExportButton = styled(PrimaryButton)``;
