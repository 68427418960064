import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../hooks/use-app-dispach-selector';
import useMessage from '../../../../hooks/useMessage';
import SearchSvg from '../../assets/svg/search.svg';
import { Button, SearchInputWithIcon } from '../../components/css-components';
import { MuiCheckbox, MuiCheckboxRef } from '../../components/mui-checkbox';
import { fetchTotalScans } from '../../store/slices/overview-slice';

export interface IWebsiteInfo {
  id: number;
  isChecked: boolean;
  name: string;
}

interface WebsiteItemProp extends IWebsiteInfo {
  handleCheckboxChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const WebsiteItem = ({
  id,
  isChecked,
  name,
  handleCheckboxChange,
}: WebsiteItemProp) => {
  const checkboxRef = useRef<MuiCheckboxRef>(null);

  return (
    <Website key={id}>
      <MuiCheckbox
        isChecked={isChecked}
        checkboxName={name}
        handleCheckboxChange={handleCheckboxChange}
        ref={checkboxRef}
      />
      <WebsiteText
        onClick={() => {
          if (checkboxRef) {
            checkboxRef.current?.click();
          }
        }}
      >
        {name}
      </WebsiteText>
    </Website>
  );
};

interface WebsiteListProps {
  handleClose: () => void;
  selectedWebsites: IWebsiteInfo[];
  setSelectedWebsites: React.Dispatch<React.SetStateAction<IWebsiteInfo[]>>;
}

export const WebsiteList = (props: WebsiteListProps) => {
  const { handleClose, selectedWebsites, setSelectedWebsites } = props;
  const dispatch = useAppDispatch();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const { devDomains } = useAppSelector((state) => state.domains);
  const { websiteIds } = useAppSelector((state) => state.overview);
  const message = useMessage();
  const [searchValue, setSearchValue] = useState<string>('');
  const [websites, setWebsites] = useState<IWebsiteInfo[]>([]);

  const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setSearchValue(value);
  };

  const renderedWebsites = useMemo(() => {
    return searchValue
      ? websites.filter(({ name }) =>
          name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : websites;
  }, [searchValue, websites]);

  const handleCheckboxChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name } = evt.target;
    const updatedWebsites = websites.map((website) =>
      website.name === name
        ? {
            ...website,
            isChecked: !website.isChecked,
          }
        : website,
    );
    setWebsites(updatedWebsites);
    setSelectedWebsites(updatedWebsites.filter(({ isChecked }) => isChecked));
  };

  const getTotalScans = async () => {
    const allWebsitesIds = websites
      .filter((website) => website.isChecked)
      .map((devDomain) => devDomain.id);

    if (allWebsitesIds.length === 0) {
      return;
    }

    const response: any = (
      await dispatch(
        fetchTotalScans({
          businessId: currentBusiness?.id,
          websiteIds: allWebsitesIds,
        }),
      )
    ).payload;
    if (!response.isSuccess) {
      message.error(response.error);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (devDomains.length > 0) {
      const allWebsites: IWebsiteInfo[] = devDomains.map(
        ({ id, name }: any) => ({
          id,
          isChecked: websiteIds.includes(id),
          name,
        }),
      );

      setWebsites(allWebsites);
    }
  }, [devDomains, websiteIds]);

  return (
    <Wrapper>
      <SearchInputWithIconWrapper>
        <Input
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={handleInputChange}
        />
        <SearchIcon src={SearchSvg} alt="search icon" />
      </SearchInputWithIconWrapper>
      <WebsitesWrapper>
        {renderedWebsites.map((website) => (
          <WebsiteItem
            key={website.id}
            handleCheckboxChange={handleCheckboxChange}
            {...website}
          />
        ))}
      </WebsitesWrapper>
      <CustomButtonWrapper>
        <CustomButton onClick={getTotalScans}>Select Website(s)</CustomButton>
      </CustomButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 16px;
`;

const SearchInputWithIconWrapper = styled(SearchInputWithIcon)`
  border: 1px solid #cbd2d9;
  border-radius: 8px;
  height: 30px;
`;

const Input = styled.input`
  background-color: #f5f7fa;
`;

const SearchIcon = styled.img`
  top: 6px;
  right: 15px;
`;

const WebsitesWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Website = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 0;
  }
`;

const WebsiteText = styled.p`
  color: #000a14;
  font-weight: 300;
  cursor: pointer;
`;

const CustomButtonWrapper = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;

  &::before {
    content: '';
    height: 15px;
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    top: -15px;
  }
`;

const CustomButton = styled(Button)`
  padding: 10px;
  margin-bottom: 10px;
`;
