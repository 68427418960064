import { AttributesChip } from '@equally-ai-front/common/src/components/attribute-chip/attributes-chip';
import { ButtonWithIcon } from '@equally-ai-front/common/src/components/button/ButtonWithIcon';
import { CustomToolTip } from '@equally-ai-front/common/src/components/custom-tooltip/CustomToolTip';
import { SavedGptSuggestions } from '@equally-ai-front/common/src/components/gpt-solutions/SavedGptSuggestions';
import { WidgetConfigurations } from '@equally-ai-front/common/src/helpers';
import { parseIssueRecommendation } from '@equally-ai-front/common/src/lib';
import { selectConfidenceLevel } from '@equally-ai-front/common/src/redux/developer-slice/selector';
import {
  FeedbackSuggestionApiPayload,
  GptApiPayload,
  GptResponse,
  GptType,
} from '@equally-ai-front/common/src/types/chat-gpt';
import { Domain } from '@equally-ai-front/common/src/types/domains';
import {
  buildUnknownUrl,
  getDomainNameAndSuffix,
} from '@equally-ai-front/common/src/utils';
import { ScanReportIssueDetails } from '@equally-ai/audit-ui/src/components/scan-report/scan-report-issue-details';
import { ScanReportItemDetailsProps } from '@equally-ai/audit-ui/src/components/scan-report/scan-report-section-item';
import { Zoom } from '@mui/material';
import { ThunkDispatch } from '@reduxjs/toolkit';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import useQuickFix from '../../../hooks/use-quick-fix';
import { ReactComponent as RecommendationCheck } from '../../../img/icons/recommendation-check.svg';
import { setApiError, setApiSuccessMessage } from '../../../store/actions';
import {
  getGPTSuggestion,
  sendGptFeedback,
  updateCustomPrompts,
} from '../../../store/dashboard-slice/dashboard';
import { ItemDetailsHeader } from '../item-details-header';
import { ButtonWithIconDiv } from '../styled-components';

export interface GptLikedState {
  likedState: boolean;
  setLikedState: Dispatch<SetStateAction<boolean>>;
}

export interface GptDisLikedState {
  disLikedState: boolean;
  setDisLikedState: Dispatch<SetStateAction<boolean>>;
}

export const TIME_OUT_COPY_FUNCTION: number = 3000;

export const ScanReportItemDetails = ({
  activeItemId,
  issueId,
  devToolsSolution,
  wcagReport,
  issueType,
  gptButtonsId,
  copyButtonId,
  dismissButtonId,
  devDomain,
}: ScanReportItemDetailsProps) => {
  const { selector, recommendation } = devToolsSolution;
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const currentWidgetDomain: Domain | undefined = useSelector(
    (state: any) => state.dashboard.domain,
  );

  const { addToConfig } = useQuickFix({
    domainId: currentWidgetDomain?.id,
  });

  const confidenceLevel = useSelector(selectConfidenceLevel);
  const savedSuggestions =
    useSelector((state: any) => state.dashboard.gptSuggestion) || {};
  const loadingGpt = useSelector((state: any) => state.dashboard.isGptLoading);
  const savedPrompts = useSelector(
    (state: any) => state.dashboard.customPrompts,
  );
  const issueDivSectionRef = useRef<HTMLDivElement>(null);
  const gptList: GptType[] = ['magic', 'creative', 'guide', 'explain'];
  const [showDefaultImg, setShowDefaultImg] = useState<boolean>(true);

  const getSuccessMessage = (type: GptType) => {
    let successMessage;

    switch (type) {
      case 'magic':
        successMessage = 'Ta-da! Improved Code fix found.';
        break;
      case 'guide':
        successMessage = 'Ta-da! Guide found.';
        break;
      case 'creative':
        successMessage = 'Ta-da! Missing attributes found.';
        break;
      case 'explain':
        successMessage = 'Ta-da! Improved explanation found.';
        break;

      default:
        successMessage = 'Ta-da! New Suggestion';
        break;
    }

    return successMessage;
  };

  const notifyOnComplete = (
    isSuccess: boolean,
    type: GptType,
    responseMessage?: string,
  ) => {
    if (isSuccess) {
      dispatch(setApiSuccessMessage(getSuccessMessage(type)));
      return;
    }

    dispatch(setApiError(responseMessage));
  };

  const fetchGPTSuggestion = (gptType: GptType, gptDetails: GptApiPayload) => {
    dispatch(
      getGPTSuggestion({
        ...gptDetails,
        gptType,
        businessId: currentBusiness.id,
        issue_id: devToolsSolution.business_id,
        notify: notifyOnComplete,
      }),
    );
  };

  const savePrompt = (prompt: string, gptType: GptType) => {
    dispatch(updateCustomPrompts({ prompt, gptType }));
  };

  const sendPromptFeedback = (feedback: FeedbackSuggestionApiPayload) => {
    dispatch(sendGptFeedback(feedback));
  };

  const getGptTitle = (type: GptType): string => {
    let gptTitle = '';

    if (type === 'magic') {
      gptTitle = 'Magical Suggested Fix';
    } else if (type === 'creative') {
      gptTitle = 'Creative Code Fix';
    } else if (type === 'guide') {
      gptTitle = 'Guide - Step by Step';
    } else if (type === 'explain') {
      gptTitle = 'Better Explanation';
    }

    return gptTitle;
  };

  const parsedRecommendation = useMemo<Record<
    string,
    Record<WidgetConfigurations, Record<string, any>>
  > | null>(() => parseIssueRecommendation(recommendation), [recommendation]);

  const handleQuickFix = async () => {
    addToConfig([devToolsSolution]);
  };

  const getGptSuggestions = (type: GptType): GptResponse[] => {
    let suggestions: GptResponse[] = [];

    if (
      savedSuggestions[devToolsSolution.id] &&
      savedSuggestions[devToolsSolution.id][type]
    ) {
      suggestions = [
        {
          gptType: type,
          suggestion:
            savedSuggestions[devToolsSolution.id][type]?.response || '',
          usedPrompt: '',
        },
      ];
    }

    return suggestions;
  };

  const [imageData, setImageData] = useState({
    width: 0,
    height: 0,
    url: '',
  });

  const getImageUrl = () => {
    if (!devDomain?.name) return;

    const imageSelector = encodeURIComponent(selector.split(' ').join(''));
    const { hostname, pathname } = buildUnknownUrl(devDomain?.name);
    const [domainName, suffix] = getDomainNameAndSuffix(hostname);
    const imageUrl = `${process.env.REACT_APP_BUCKET_NAME}/${domainName}.${suffix}/${hostname}/developers/${devDomain.id}${pathname}/${imageSelector}.png`;

    const image = new Image();
    image.src = imageUrl;
    setImageData({
      width: image.width,
      height: image.height,
      url: imageUrl,
    });
  };

  const setSuccessMessage = (message: string) => {
    dispatch(setApiSuccessMessage(message));
  };

  useEffect(() => {
    if (issueId === activeItemId && selector) {
      getImageUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueId, activeItemId, selector]);

  return (
    <Container>
      {devDomain && !wcagReport.isDismissedReport && (
        <ItemDetailsHeader
          wcagReport={wcagReport}
          devToolsSolution={devToolsSolution}
          issueType={issueType}
          issueDivSectionRef={issueDivSectionRef}
          gptButtonsId={gptButtonsId}
          copyButtonId={copyButtonId}
          dismissButtonId={dismissButtonId}
          getGptSuggestion={fetchGPTSuggestion}
          devDomain={devDomain}
        />
      )}
      <IssueDivSection ref={issueDivSectionRef}>
        {gptList.map((gptItem) => (
          <SavedGptSuggestions
            key={gptItem}
            suggestionsTitle={getGptTitle(gptItem)}
            suggestions={getGptSuggestions(gptItem)}
            devToolsSolution={devToolsSolution}
            gptType={gptItem}
            getGptSuggestion={fetchGPTSuggestion}
            loadingGpt={loadingGpt}
            savePrompt={savePrompt}
            usedPrompt={savedPrompts[gptItem] || ''}
            sendFeedback={sendPromptFeedback}
          />
        ))}
        {confidenceLevel && (
          <SectionDivContainer>
            <SectionDiv>
              <TitleHeader>Confidence Level: {confidenceLevel}</TitleHeader>
            </SectionDiv>
          </SectionDivContainer>
        )}
        {parsedRecommendation && (
          <SectionDivContainer>
            <SectionDiv>
              <RecommendationHeader>
                <TitleHeader>
                  <span>Recommendation</span>

                  <RecommendationCheck />
                </TitleHeader>
                {currentWidgetDomain && (
                  <CustomToolTip
                    title="Apply recommneded fixes"
                    placement="top"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <ButtonContainer>
                      <ButtonWithIcon
                        buttonText="Quick Fix"
                        iconSrc=""
                        iconAltText=""
                        iconWidth="16px"
                        backgroundColor="transparent"
                        hoverColor="#ecfcf2"
                        borderRadius="8px"
                        height="30px"
                        borderStyle="1.5px solid #cbd2d9"
                        onClickAction={handleQuickFix}
                      />
                    </ButtonContainer>
                  </CustomToolTip>
                )}
              </RecommendationHeader>
              <BodyContent>
                Here are recommended attributes that can be used to fix this
                issue
              </BodyContent>
              <ChipContainer>
                {Object.entries(parsedRecommendation.attributes).map(
                  // eslint-disable-next-line no-unused-vars
                  ([_, object]) => {
                    if (Object.keys(object).length) {
                      return Object.entries(object).map(([key, value]) => {
                        return (
                          <AttributesChip
                            key={`column-${key}`}
                            attribute={`${key.replace('-', ' ')}: ${value}`}
                            value={value}
                          />
                        );
                      });
                    }
                    return null;
                  },
                )}
              </ChipContainer>
            </SectionDiv>
          </SectionDivContainer>
        )}
        <ScreenshotDiv imgLoaded={!showDefaultImg}>
          {/* {showDefaultImg && <p>Loading Image...</p>} */}
          <Screenshot
            src={imageData.url}
            onLoad={() => setShowDefaultImg(false)}
            onError={() => setShowDefaultImg(true)}
            alt=""
          />
        </ScreenshotDiv>
        <ScanReportIssueDetails
          devToolsSolution={devToolsSolution}
          activeItemId={activeItemId}
          issueId={issueId}
          setSuccessMessage={setSuccessMessage}
          showSelectorBox
        />
      </IssueDivSection>
    </Container>
  );
};

const Container = styled.div``;

const IssueDivSection = styled.div`
  padding-bottom: 0;
  display: flex;
  position: relative;
  flex-direction: column;

  @media screen and (max-width: 650px) {
    padding: 30px;
    padding-bottom: 0;
  }

  @media (pointer: none), (pointer: coarse) {
    padding: 0;
  }
`;

const TitleHeader = styled.h5`
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 10, 20, 0.85);
  margin-bottom: 2%;

  @media screen and (max-width: 650px) {
    margin-bottom: 2%;
  }
`;

const BodyContent = styled.div`
  font-size: 13px;
  color: rgba(0, 10, 20, 0.85);
`;

const SectionDiv = styled.div`
  padding: 16px 32px 3%;
`;

const SectionDivContainer = styled.div`
  &:after {
    content: '';
    border-bottom: 1.5px solid #e4e7eb;
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
  }
`;

const ButtonContainer = styled(ButtonWithIconDiv)`
  margin-top: 0;

  @media (pointer: none), (pointer: coarse) {
    width: 100%;
  }
`;

interface ScreenshotProps {
  imgLoaded: boolean;
}

const ScreenshotDiv = styled.div<ScreenshotProps>`
  margin-top: 4%;
  margin-bottom: 4%;
  display: ${(props) => (props.imgLoaded ? '' : 'none')};

  p {
    margin-bottom: 5%;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #cbd2d9;
    border-radius: 5px;
    text-align: center;
  }
`;

const Screenshot = styled.img`
  width: 100%;
`;

export const CodeBlock = styled.div`
  background-color: #000a14;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 2.5% 0;
`;

export const CodeContent = styled.div`
  color: #ffffff;
  width: 88%;
  word-wrap: break-word;
`;

export const CopyButton = styled.button`
  border: none;
  background-color: #4ad493;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChipContainer = styled.div`
  padding: 10px 0 3%;
  display: flex;
  flex-wrap: wrap;
`;

const RecommendationHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
    margin-bottom: 2vh;
  }

  & h5 {
    display: flex;
    align-items: center;
  }

  & svg {
    fill: #00afef;
    margin-left: 5px;
    height: 16px;
    width: 16px;
  }
`;
