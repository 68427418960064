import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface MuiInputProps {
  label: string;
  value: string | number;
  name: string;
  handleChange: (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  type: string;
  required?: boolean;
  disabled?: boolean;
}

export const CustomTextField: React.ComponentType<TextFieldProps> = styled(
  TextField,
)<TextFieldProps>({
  '& label.Mui-focused': {
    color: '#000A14',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 10px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -5px) scale(0.75)',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#e4e7eb',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1.5px solid #e4e7eb',
      borderRadius: '5px',
    },
    '&:hover fieldset': {
      borderColor: '#e4e7eb',
    },
    '&.Mui-focused fieldset': {
      border: '1.5px solid #e4e7eb',
    },
  },
});

export const MuiInput = (props: MuiInputProps) => {
  const { label, value, name, handleChange, type, required, disabled } = props;

  return (
    <CustomTextField
      variant="outlined"
      type={type}
      name={name}
      value={value}
      onChange={handleChange}
      label={label}
      required={required}
      disabled={disabled}
      sx={{
        '& .MuiOutlinedInput-root': {
          height: '40px',
        },
      }}
    />
  );
};
