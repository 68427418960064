import React from 'react';

const TabelHeader = (name, unit, price, quantity, total) => {
  return (
    <div className="invoice-total-panel-line">
      <div className="invoice-total-panel-line-name">{name}</div>
      <div className="invoice-total-panel-line-column">{unit}</div>
      <div className="invoice-total-panel-line-column">{price}</div>
      <div className="invoice-total-panel-line-column">{quantity}</div>
      <div className="invoice-total-panel-header-column-end">{total}</div>
    </div>
  );
};

const TabelLine = (name, unit, price, quantity, total) => {
  return (
    <div className="invoice-total-panel-line">
      <div className="invoice-total-panel-line-name">{name}</div>
      <div className="invoice-total-panel-line-column">{unit}</div>
      <div className="invoice-total-panel-line-column">{price}</div>
      <div className="invoice-total-panel-line-column">{quantity}</div>
      <div className="invoice-total-panel-line-column-end">{total}</div>
    </div>
  );
};

const TotalLine = (name, unit, price, quantity, total) => {
  return (
    <div className="invoice-total-panel-line-sum">
      <div className="invoice-total-panel-line-name">{name}</div>
      <div className="invoice-total-panel-line-column">{unit}</div>
      <div className="invoice-total-panel-line-column">{price}</div>
      <div className="invoice-total-panel-line-column">{quantity}</div>
      <div className="invoice-total-panel-header-column-end">{total}</div>
    </div>
  );
};

export const Invoice = () => {
  return (
    <div>
      <div className="invoice-details-container">
        <div className="invoice-details">
          <div className="invoice-date">Jul 19, 2020</div>
          <div className="invoice-details-header">
            <div className="invoice-details-header-label">INVOICE</div>
            <div className="invoice-details-header-value">P9-004</div>
          </div>
          <div className="invoice-details-header">
            <div className="invoice-details-date-label">INVOICE DATE</div>
            <div className="invoice-details-date-value">Jul 19, 2020</div>
          </div>
          <div className="invoice-details-header">
            <div className="invoice-details-date-label">DUE DATE</div>
            <div className="invoice-details-date-value">Aug 24, 2020</div>
          </div>
          <div className="invoice-details-customer-name">John Doe</div>
          <div className="invoice-details-address">
            9301 Wood Street Philadelphia, PA 19111
          </div>
          <div className="invoice-details-address">+55 552 455 87</div>
          <div className="invoice-company-url">
            <a className="invoice-company-url" href="mailto:johndoe@mail.com">
              johndoe@mail.com
            </a>
          </div>
        </div>
        <div className="invoice-company-details">
          <img
            src="logo.png"
            alt="company logo"
            className="invoice-company-logo"
          />
          <div className="invoice-divider" />
          <div>
            <div className="invoice-company-name">Equally.ai</div>
            <div className="invoice-company-address">
              2810 Country Club Road Cranford, NA 07016
            </div>
            <div className="invoice-company-address">+66 123 455 87</div>
            <div>
              <a className="invoice-company-url" href="mailto:hello@equally.ai">
                hello@equally.ai
              </a>
            </div>
            <div>
              <a className="invoice-company-url" href="http://equally.ai">
                www.equally.ai
              </a>
            </div>
          </div>
        </div>
      </div>
      {TabelHeader('SERVICE', 'UNIT', 'UNIT PRICE', 'QUANTITY', 'TOTAL')}
      <div className="invoice-total-panel-divider" />
      {TabelLine('Prototype & Design', 'Hour', '$12.00', '240', '$2,880.00')}
      <div className="invoice-total-panel-divider" />
      {TabelLine('Coding', 'Hour', '$10.50', '350', '$3,675.00')}
      <div className="invoice-table-divider" />
      {TabelLine('SUBTOTAL', '', '', '', '$6.555.00')}
      <div className="invoice-total-panel-divider" />
      {TabelLine('TAX', '', '', '', '$200.00')}
      <div className="invoice-total-panel-divider" />
      {TabelLine('DISCOUNT', '', '', '', '$1.200.00')}
      <div className="invoice-total-panel-divider" />
      {TotalLine('TOTAL', '', '', '', '$5,155.00')}
    </div>
  );
};
