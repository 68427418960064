import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

interface IssueColumnsTableProps {
  issues: DevtoolsSolution[];
  columns: string[];
  isColorShadeOfWhiteOrGrey: boolean;
}

const truncate = (value: string, length: number) => {
  return value.length > length ? `${value.substring(0, length)}...` : value;
};

const COLUMN_WIDTH: Record<number, string> = {
  1: '100%',
  2: '50%',
  3: '33.3%',
  4: '25%',
  5: '20%',
  6: '16.6%',
};

export const IssueColumnsTable = (props: IssueColumnsTableProps) => {
  const { issues, columns, isColorShadeOfWhiteOrGrey } = props;
  const borderColor = '#e3e8fb';
  const styles = StyleSheet.create({
    container: {
      margin: '30px auto',
      width: '100%',
      maxWidth: '95%',
    },
    issueSelectorContainer: {
      width: '100%',
    },
    issueSelector: {
      fontSize: 10,
      fontFamily: 'DM Sans',
      color: isColorShadeOfWhiteOrGrey ? '#ffffff' : '#222529',
      textAlign: 'left',
      fontWeight: 'bold',
      padding: '10px',
    },
    issueRow: {
      width: '100%',
      borderTop: `2px solid ${borderColor}`,
      padding: '5px 0',
    },
    issueColumnContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '10px',
      width: '100%',
    },
    issueColumn: {
      fontSize: 10,
      fontFamily: 'DM Sans',
      width: '100%',
      padding: '10px',
    },
  });

  return (
    <View style={styles.container}>
      {issues.map((issue) => {
        const maxLength = columns.length > 5 ? 15 : 25;

        return (
          <View style={styles.issueRow}>
            <View style={styles.issueSelectorContainer}>
              <Text style={styles.issueSelector}>{issue.selector}</Text>
            </View>
            <View style={styles.issueColumnContainer}>
              {columns.map((column) => {
                let columnData = '';
                const isCustomWidth = column === 'message' || column === 'code';
                if (column === 'recommendation') {
                  const recommendation = JSON.parse(
                    issue.recommendation || '{}',
                  );
                  let data: string[] = [];
                  let text: string = '';

                  Object.entries(recommendation.attributes || {}).forEach(
                    ([configType, attributes]) => {
                      Object.entries(
                        attributes as { [key: string]: string },
                      ).forEach(([attribute, value]) => {
                        data = [...data, `${attribute}: ${value}`];
                      });
                    },
                  );

                  text = recommendation.text
                    ? `Additional information (${recommendation.text})`
                    : '';

                  if (data.length > 0) {
                    columnData = `Attributes (` + data.join(', ') + `)\n`;
                  }

                  columnData += text;
                } else {
                  // @ts-ignore
                  columnData = issue[column];
                }

                return (
                  <Text
                    style={{
                      ...styles.issueColumn,
                      maxWidth: isCustomWidth
                        ? '20%'
                        : COLUMN_WIDTH[columns.length],
                    }}
                  >
                    {truncate(columnData, isCustomWidth ? 50 : maxLength)}
                  </Text>
                );
              })}
            </View>
          </View>
        );
      })}
    </View>
  );
};
