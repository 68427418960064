import PropTypes from 'prop-types';
import React from 'react';

import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    zIndex: '4000000 !important',
    '& .MuiDrawer-paperAnchorBottom': {
      height: '100%',
      bottom: '20px',
    },
    '& .MuiDrawer-paper': {
      height: '100%',
      maxHeight: '95%',
      maxWidth: '95%',
      borderRadius: '8px',
      margin: '0 auto',
    },
  },
  paper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    height: '100% !important',
    paddingTop: '10px',
    '&:focus': {
      outline: 'none',
    },
  },
}));

export const DrawerPanel = ({
  open,
  handleClose,
  children,
  title,
  anchor,
  subTitle,
  notification,
}) => {
  const classes = useStyles();
  const [showNotificaiton, setShowNotificaiton] = React.useState(true);

  return (
    <Drawer
      open={open}
      anchor={anchor}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="modal-panel-title"
      aria-describedby="modal-panel-description"
    >
      <>
        <div className={classes.paper}>
          <div className="modal-panel">
            <div className="modal-header">
              <div>
                <h2 id="modal-panel-title" className="modal-panel-title">
                  {title}
                </h2>
                <img
                  className={`modal-panel-title-underline ${
                    subTitle ? 'modal-panel-title-underline-with-sub' : ''
                  }`}
                  src="underline.png"
                  alt="header underline"
                />
              </div>
              <button
                onClick={handleClose}
                type="button"
                className="modal-close-button"
              >
                <img
                  className="modal-close-icon"
                  src="close.png"
                  alt="close modal"
                />
              </button>
            </div>
            {notification && showNotificaiton && (
              <div className="modal-panel-notification">
                <div className="modal-panel-notification-icon">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    className="modal-panel-notification-icon-close"
                    src="close.png"
                    alt="close modal"
                    onClick={() => setShowNotificaiton(false)}
                  />
                </div>
                <div className="modal-panel-notification-text">
                  {notification}
                </div>
              </div>
            )}
            {subTitle && (
              <div className="modal-panel-sub-title">{subTitle}</div>
            )}
            {children}
          </div>
        </div>
      </>
    </Drawer>
  );
};

DrawerPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  subTitle: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  notification: PropTypes.string,
};
