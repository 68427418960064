import { Box, MenuItem, TextField, Typography, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { Table } from '../table/table';
import { ReactComponent as SearchIcon } from '../../../../img/icons/search.svg';
import {
  complianceDocumentsTableFilterFields,
  countryFilterOptions,
  docTypeFilterOptions,
} from '../table/consts';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import {
  FilterCondition,
  ItemFilter,
} from '@equally-ai-front/common/src/components/filters/types';
import { styled as MuiStyled } from '@mui/material/styles';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { DocumentTemplateRow } from '../table/document-template-row';
import { COMPLIANCE_DOCUMENT_TEMPLATES } from '@equally-ai-front/common/src/redux/compliance-documents-slice/compliance-documents';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export const DocumentTemplatesTab = () => {
  const analytics = useAnalytics();
  const { currentBusiness } = useSelector((state: RootState) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const { visibleData, filters, removeFilterType, addFilter } = useFilter({
    filterOptions: complianceDocumentsTableFilterFields,
    data: COMPLIANCE_DOCUMENT_TEMPLATES,
  });

  const { currentData, searchTerm, handleSearch } = usePagination(
    visibleData,
    10,
    'title',
  );

  const handleFilterSelect = (filter: ItemFilter) => {
    const { key, displayName, filterType, value, filterCondition } = filter;

    if (value === 'all') {
      removeFilterType(key);
      return;
    }
    addFilter({
      key,
      displayName,
      value,
      filterType,
      filterCondition,
    });
  };

  const handleTemplateClick = (templateId: number, type: string) => {
    analytics.track('Document Template Clicked', {
      business_id: currentBusiness.id,
      template_id: templateId,
      type,
    });
  };

  useEffect(() => {
    analytics.page({
      name: 'Compliance Documents Templates Page',
    });
  }, []);

  return (
    <Wrapper>
      <TableHeader>
        <Title>Templates</Title>
        <FilterWrapper>
          <SearchInputWrapper>
            <StyledSearchIcon aria-hidden="true" />
            <SearchInput
              type="text"
              value={searchTerm}
              onChange={(evt) => handleSearch(evt.target.value)}
              placeholder="Search templates..."
              variant="outlined"
            />
          </SearchInputWrapper>
          <SelectWrapper>
            <StyledSelect
              labelId="type"
              id="type"
              value={filters?.type?.value || 'all'}
              name="type"
              required
              onChange={(evt) =>
                handleFilterSelect({
                  key: 'type',
                  displayName: 'Doc Type',
                  value: evt.target.value,
                  filterType: 'string',
                  filterCondition: FilterCondition.EqualTo,
                })
              }
            >
              {docTypeFilterOptions.map(
                ({ label, value: optionValue }, idx) => (
                  <MenuItem key={`${label}-${idx}`} value={optionValue}>
                    {label}
                  </MenuItem>
                ),
              )}
            </StyledSelect>
          </SelectWrapper>
          <SelectWrapper>
            <StyledSelect
              labelId="country"
              id="country"
              value={filters?.country?.value || 'all'}
              name="country"
              required
              onChange={(evt) =>
                handleFilterSelect({
                  key: 'country',
                  displayName: 'Country',
                  value: evt.target.value,
                  filterType: 'string',
                  filterCondition: FilterCondition.EqualTo,
                })
              }
            >
              {countryFilterOptions.map(
                ({ label, value: optionValue }, idx) => (
                  <MenuItem key={`${label}-${idx}`} value={optionValue}>
                    {label}
                  </MenuItem>
                ),
              )}
            </StyledSelect>
          </SelectWrapper>
        </FilterWrapper>
      </TableHeader>
      <Table complianceDocuments={currentData}>
        {currentData.map((domainComplianceDoc, idx) => (
          <DocumentTemplateRow
            key={`${domainComplianceDoc.title}-${idx}`}
            complianceDocument={domainComplianceDoc}
            onTemplateClick={handleTemplateClick}
          />
        ))}
      </Table>
    </Wrapper>
  );
};

export const Wrapper = MuiStyled(Box)`
    padding: 24px;
    background-color: #F9FAFB;
    border: 1px solid #E4E7EB;
    border-radius: 8px;
`;

export const TableHeader = MuiStyled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: 600px) {
        gap: 10px;
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Title = MuiStyled(Typography)`
    font-size: 24px;
    font-weight: 700;
`;

const FilterWrapper = MuiStyled(Box)`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        width: 100%;
    }
`;

const SearchInputWrapper = MuiStyled(Box)`
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #E2E8F0;
    padding: 0 10px;
    border-radius: 6px;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

const StyledSearchIcon = MuiStyled(SearchIcon)`
    flex-shrink: 0;
`;

const SearchInput = MuiStyled(TextField)(({ theme }) => ({
  width: '100%',

  ['.MuiOutlinedInput-root']: {
    width: '180px',
    height: '45px',
    borderRadius: '6px',
    backgroundColor: '#ffffff',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  input: {
    paddingLeft: '8px',
  },

  fieldset: {
    border: 'none',
  },
}));

export const SelectWrapper = MuiStyled(Box)`
    width: 180px;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const StyledSelect = MuiStyled(Select)(({ theme }) => ({
  width: '180px',
  height: '45px',
  borderRadius: '6px',
  backgroundColor: '#ffffff',

  fieldset: {
    borderColor: '#E2E8F0',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
