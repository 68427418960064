import styled from 'styled-components';
type StyleComponentProps = { ismobile: string };
export const PagesListContainer = styled.div<StyleComponentProps>`
  overflow-y: auto;
  width: 100%;
  margin: auto;
  margin-top: ${(p) => (p.ismobile ? '15px' : '')};

  /* width */

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }

  @media screen and (max-width: 800px) {
    max-width: 80%;
  }
`;

export const PageListTitle = styled.p`
  font-weight: 500;
  font-size: 13px;
  background-color: #f1f3f5;
  color: #50565f;
  border-radius: 8px;
  padding: 5px 8px;
  width: fit-content;
  margin: 0 0 5px 0;
`;

type CustomUlProps = { isWebsiteUrlPresent: boolean; ismobile: string };

export const DomainCustomUL = styled.ul<CustomUlProps>`
  margin-top: ${(p) => (p.isWebsiteUrlPresent ? '' : '20%')};
  margin-left: ${(p) => (p.ismobile ? '62px' : '23px')};
  padding: 0;
`;

type CustomLiProps = {
  bulletColor?: string;
  ismobile: string;
  isexpired?: string;
  editing?: string;
};

export const DomainCustomLi = styled.li<CustomLiProps>`
  margin-bottom: ${(p) => (p.ismobile ? '10px' : '5px')};
  cursor: ${(props) =>
    props.isexpired === 'true' ? 'not-allowed' : 'pointer'};
  width: ${(p) => (p.editing === 'true' ? '100%' : 'fit-content')};
  min-width: 20px;
  list-style: outside;
  position: relative;

  &::marker {
    color: ${(p) => p.bulletColor};
    font-size: 25px;
  }
`;

type CustomLiTextProps = {
  isActive?: boolean;
};

export const CustomLiText = styled.span<CustomLiTextProps>`
  color: #000a14;
  word-wrap: break-word;
  ${(p) => p.isActive && 'font-weight: 600'};
  font-size: 15px;
  vertical-align: text-bottom;
  margin-left: -3px;
`;
