import { useEffect } from 'react';

import useMessage from '../../../hooks/useMessage';
import { useAudit } from '../hooks/useAudit';
import { ERROR } from '../hooks/useAuditAPI';

const AriaErrorView = () => {
  const message = useMessage();
  const { error } = useAudit();

  useEffect(() => {
    if (error === ERROR.AuditFailed) {
      message.error('Audit Failed.');
    }

    if (error === ERROR.InvalidURL) {
      message.error('Invalid Website URL.');
    }
  }, [message, error]);

  return null;
};

export default AriaErrorView;
