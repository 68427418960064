export const LANGUAGES = [
  {
    code: 'en',
    lang: 'English',
  },
  {
    code: 'es',
    lang: 'Español',
  },
  {
    code: 'de',
    lang: 'Deutsch',
  },
  {
    code: 'pt',
    lang: 'Português',
  },
  {
    code: 'bg',
    lang: 'Български',
  },
  {
    code: 'ru',
    lang: 'Русский',
  },
  {
    code: 'se',
    lang: 'Svenska',
  },
  {
    code: 'it',
    lang: 'Italiano',
  },
  {
    code: 'fr',
    lang: 'Français',
  },
  {
    code: 'he',
    lang: 'עברית',
  },
  {
    code: 'cz',
    lang: 'Čeština',
  },
  {
    code: 'dk',
    lang: 'Dansk',
  },
  {
    code: 'fi',
    lang: 'Suomi',
  },
  {
    code: 'gr',
    lang: 'Ελληνικά',
  },
  {
    code: 'hr',
    lang: 'Hrvatski',
  },
  {
    code: 'lt',
    lang: 'lietuvių',
  },
  {
    code: 'lv',
    lang: 'Latviešu',
  },
  {
    code: 'mt',
    lang: 'Maltese',
  },
  {
    code: 'nl',
    lang: 'Nederlands',
  },
  {
    code: 'pl',
    lang: 'Polski',
  },
  {
    code: 'ro',
    lang: 'Română',
  },
  {
    code: 'sl',
    lang: 'Sloveinian',
  },
  {
    code: 'sk',
    lang: 'Slovak',
  },
  {
    code: 'ar',
    lang: 'Arabic',
  },
  {
    code: 'ja',
    lang: 'Japanese',
  },
  {
    code: 'zh',
    lang: 'Chinese',
  },
  {
    code: 'ko',
    lang: 'Korean',
  },
  {
    code: 'sq',
    lang: 'Albanian',
  },
  {
    code: 'mk',
    lang: 'Macedonian',
  },
];
