export interface GptExtraDetails {
  platform: string;
  hasElementor: boolean;
  hasVisualEditor: boolean;
  template: string;
  language: string;
}

export interface GptDetails {
  pageName: string;
  pageSelector?: string;
  domain: string;
  principle: string;
  context?: string;
  extraDetails: GptExtraDetails;
}

export interface GptApiPayload {
  prompt?: string;
  context?: string;
  principle: string;
  page_name: string;
  page_selector: string;
  context_type: string;
  foreground_color?: string;
  background_color?: string;
  suggest?: string;
  compliance_target?: string;
  domain: string;
  extra_details: {
    template: string;
    has_elementor: boolean;
    has_visual_editor: boolean;
    platform: string;
    language: string;
  };
  businessId?: number;
  dev_domain_id?: number;
}

export type GptType =
  | 'magic'
  | 'guide'
  | 'creative'
  | 'explain'
  | 'screen-reader'
  | 'find-missing-attribute'
  | 'better-contrast';

export interface GptResponse {
  gptType: GptType;
  suggestion: string;
  usedPrompt: string;
}

export enum PromptFeedbackEnum {
  like = 'like',
  dislike = 'dislike',
}

export interface FeedbackSuggestionApiPayload {
  prompt: string;
  WebsiteType: string;
  PromptFeedback: PromptFeedbackEnum;
  ElementSelector: string;
}
export interface GptSuggestions {
  gptType: GptType;
  suggestions: GptResponse[];
  isLoading: boolean;
  suggestionFeedback: [];
}

export interface FeedbackSuggestionDetails {
  prompt: string;
  websiteType: string;
  elementSelector: string;
  userId: string;
  promptFeedback: PromptFeedbackEnum;
}

export interface GptError {
  message: string;
  type: GptType | '';
}

// the next interfaces (GptExtraDetails,isGptLoading) is the one we should use around the code until we clear out all usages of snake case.
export interface GptExtraDetails {
  platform: string;
  hasElementor: boolean;
  hasVisualEditor: boolean;
  template: string;
  language: string;
}

export interface isGptLoading {
  isLoading: boolean;
}
