import React from 'react';
import { Grid } from '@mui/material';

interface IssueReportLayoutProps {
  children: React.ReactNode;
}

export const IssueReportLayout = ({ children }: IssueReportLayoutProps) => {
  return <Grid container>{children}</Grid>;
};
