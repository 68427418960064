import { useState, useEffect, useCallback } from 'react';
import { set, merge, difference } from 'lodash-es';
import DomainsAPI from '@equally-ai-front/common/src/api/domains';
import { APPEARANCE_DEFAULT_SETTINGS } from '../lib/domains';
import { setApiError, setApiSuccessMessage } from '../store/actions';
import { useDispatch } from 'react-redux';

const REQUIRED_KEYS = [
  'widgetConfigSitePosition',
  'widgetConfigMobilePosition',
  'widgetConfigSiteColoring',
  'widgetConfigSiteSize',
  'widgetConfigSiteAdditional',
];

const isValidConfig = (config: typeof APPEARANCE_DEFAULT_SETTINGS) => {
  const configKeys = Object.keys(config);

  const missingKeys = difference(REQUIRED_KEYS, configKeys);

  return missingKeys.length === 0;
};

const useAppearanceState = ({
  domainName,
  slug,
}: {
  domainName: string;
  slug: string;
}) => {
  const [appearanceState, setAppearanceState] = useState({
    ...APPEARANCE_DEFAULT_SETTINGS,
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getAppearanceSettings = useCallback(
    (domainName: string, slug: string) => {
      const get = async () => {
        setIsLoading(true);
        const { data, error } = await DomainsAPI.getDomainAppearanceSettings(
          slug,
          domainName,
        );

        if (error) {
          setIsLoading(false);
          return;
        }

        // merge to keep missing fields from default settings
        const appearanceData = merge(
          {},
          { ...APPEARANCE_DEFAULT_SETTINGS },
          data,
        );

        setAppearanceState(appearanceData);
        setIsLoading(false);
      };
      void get();
    },
    [domainName, slug],
  );

  useEffect(() => {
    if (!domainName || !slug) {
      return;
    }
    void getAppearanceSettings(domainName, slug);
  }, [getAppearanceSettings]);

  const updateAppearance = useCallback(
    (path: string | Record<string, any>, value: string) => {
      setAppearanceState((prevState) => {
        const newState = { ...prevState };
        if (typeof path === 'object') {
          Object.entries(path).forEach(([keyPath, val]) => {
            set(newState, keyPath, val);
          });
          return newState;
        }

        return set(newState, path, value);
      });
    },
    [],
  );

  const handleSaveAppearance = useCallback(
    async (isSettings: boolean) => {
      if (!domainName || !slug) {
        return;
      }
      setIsLoading(true);

      const { data, error } = await DomainsAPI.updateDomainAppearanceSettings(
        slug,
        domainName,
        appearanceState,
      );

      if (error || !isValidConfig(data)) {
        const message = isSettings
          ? 'Could not save settings'
          : 'Could not save appearance settings';

        dispatch(setApiError(`${message} for ${domainName}`));
        setIsLoading(false);
        return;
      }
      const message = isSettings
        ? 'Settings saved'
        : 'Appearance Settings saved';

      dispatch(setApiSuccessMessage(`${message} for ${domainName}`));
      setAppearanceState(data);

      setIsLoading(false);
    },
    [dispatch, appearanceState],
  );

  return {
    appearanceState,
    updateAppearance,
    handleSaveAppearance,
    isLoading,
  };
};

export default useAppearanceState;
