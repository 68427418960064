import axios from 'axios';
import APIClient from './client';

class Audit {
  static generateCancelToken = () => axios.CancelToken.source().token;

  /** onProgress value 0 - 100 */
  static async checkCompliance({ url, onProgress, cancelToken }) {
    const config = {
      onDownloadProgress: function (progressEvent) {
        onProgress?.(progressEvent.loaded, progressEvent.total);
      },
    };

    const result = await APIClient.request({
      ...config,
      method: 'post',
      url: '/api/v1/admin/u/compliance',
      data: { url },
      cancelToken,
    });

    return result;
  }
}

export default Audit;
