import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { PayPalButtons as PayPalButton } from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTransaction, setApiSuccessMessage } from '../../store/actions';

export const PayPalButtons = ({
  domain,
  closeModal,
  planPrice,
  onSuccess,
  // eslint-disable-next-line camelcase,react/prop-types
  plan_id,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const user = useSelector((state) => state.user.user);

  const onError = (err) => {
    console.error('error from the onError callback', err);
    analytics.track('Payment Status Updated', {
      status: 'error-processing',
      planId: plan_id,
      price: planPrice,
      domain,
    });
  };

  const createSubscription = async (data, actions) => {
    const response = await actions.subscription.create({
      plan_id,
    });
    return response;
  };

  const onApprove = async (data) => {
    dispatch(
      addTransaction({
        transaction_id: data.subscriptionID,
        domain,
      }),
    );

    // eslint-disable-next-line no-undef
    tap('conversion', data.subscriptionID.toString(), planPrice, {
      customer_id: user.preferred_username,
    });
    // eslint-disable-next-line no-undef
    // dataLayer.push({
    //   event: 'payment',
    //   domain: domain,
    // });

    dispatch(setApiSuccessMessage('Payment Successful'));
    analytics.track('Payment Status Updated', {
      status: 'success',
      paymentId: data.subscriptionID,
      domain,
      price: planPrice,
    });

    closeModal();
    setTimeout(() => {
      onSuccess();
    }, 500);
  };

  return (
    <PayPalButton
      onApprove={onApprove}
      createSubscription={createSubscription}
      onError={onError}
      style={{ layout: 'horizontal' }}
    />
  );
};

PayPalButtons.propTypes = {
  domain: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  planPrice: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
