import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { PrimaryButton } from './Controls';
import { useAuth } from '../../providers/auth-context';
import { setApiError } from '../../store/actions';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

export const PasswordReset = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const user = useSelector((state) => state.user.user);
  const analytics = useAnalytics();
  const { resetPassword } = useAuth();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  useEffect(() => {
    analytics.page({
      name: 'Password Reset Page',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetPassword = async () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      dispatch(setApiError('Please fill all fields'));
      return;
    }

    if (newPassword !== confirmPassword) {
      dispatch(setApiError('Passwords do not match'));
      return;
    }
    try {
      await resetPassword(oldPassword, newPassword);
    } catch (error) {}
  };
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',

        '@media screen and (max-width: 800px)': {
          maxWidth: '100%',
        },
      }}
    >
      <div className="header-page-text">Password reset</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '30px',
        }}
      >
        <TextField
          className={classes.input}
          label="Email"
          disabled
          defaultValue={user.email}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
        <TextField
          className={classes.input}
          label="Old password"
          type="password"
          autoComplete="old-password"
          onChange={handleOldPasswordChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          className={classes.input}
          label="New password"
          type="password"
          autoComplete="new-password"
          variant="outlined"
          onChange={handleNewPasswordChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          className={classes.input}
          label="Confirm new password"
          onChange={handleConfirmPasswordChange}
          type="password"
          autoComplete="new-password"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <PrimaryButton
          color="primary"
          style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
          variant="contained"
          onClick={handleResetPassword}
        >
          Update Password
        </PrimaryButton>
      </Box>
    </Box>
  );
};
