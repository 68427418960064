import { ButtonWithIcon } from '@equally-ai-front/common/src/components/button/ButtonWithIcon';
import { CustomToolTip } from '@equally-ai-front/common/src/components/custom-tooltip/CustomToolTip';
import { scanRecommendationsActions } from '@equally-ai-front/common/src/redux/developer-slice';
import {
  GptApiPayload,
  GptType,
} from '@equally-ai-front/common/src/types/chat-gpt';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import {
  CREATIVE_TOOLTIP_MESSAGE,
  EXPLAIN_TOOLTIP_MESSAGE,
  GUIDE_TOOLTIP_MESSAGE,
  MAGIC_TOOLTIP_MESSAGE,
} from '@equally-ai-front/common/src/utils/constants';
import {
  // DevtoolsGroup,
  DevtoolsSolution,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import Zoom from '@mui/material/Zoom';
import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setApiError, setApiSuccessMessage } from '../../store/actions';
import { getGPTSuggestion } from '../../store/dashboard-slice/dashboard';
import { ButtonWithIconDiv } from './styled-components';

interface GptActionsProps {
  gptButtonsId: string;
  loadingGpt: boolean;
  ongoingGptType: string;
  productTourIndex: number;
  currentAuditedPageName: string;
  devToolsSolution: DevtoolsSolution;
  setOngoingGptType: React.Dispatch<React.SetStateAction<GptType | ''>>;
  devDomain: DevDomain;
  getGptSuggestion: (gptType: GptType, gptDetails: GptApiPayload) => void;
}

export const GptActions = (props: GptActionsProps) => {
  const {
    gptButtonsId,
    productTourIndex,
    ongoingGptType,
    setOngoingGptType,
    devDomain,
    currentAuditedPageName,
    devToolsSolution,
  } = props;
  const codeInfoForGpt = {
    page_name: currentAuditedPageName,
    page_selector: devToolsSolution.selector,
    context: devToolsSolution.context,
    principle: devToolsSolution.principle,
    domain: `https://${devDomain.name}`,
  };
  const loadingGpt = useSelector((state: any) => state.dashboard.isGptLoading);
  const magicButtonRef = createRef<HTMLButtonElement>();
  const creativeButtonRef = createRef<HTMLButtonElement>();
  const guideButtonRef = createRef<HTMLButtonElement>();
  const explainButtonRef = createRef<HTMLButtonElement>();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );

  const getSuccessMessage = (type: GptType) => {
    let successMessage;

    switch (type) {
      case 'magic':
        successMessage = 'Ta-da! Improved Code fix found.';
        break;
      case 'guide':
        successMessage = 'Ta-da! Guide found.';
        break;
      case 'creative':
        successMessage = 'Ta-da! Missing attributes found.';
        break;
      case 'explain':
        successMessage = 'Ta-da! Improved explanation found.';
        break;

      default:
        successMessage = 'Ta-da! New Suggestion';
        break;
    }

    return successMessage;
  };

  const notifyOnComplete = (
    isSuccess: boolean,
    type: GptType,
    message?: string,
  ) => {
    if (isSuccess) {
      dispatch(setApiSuccessMessage(getSuccessMessage(type)));
      return;
    }

    dispatch(setApiError(message));
  };
  const gptMagicAction = () => {
    if (productTourIndex === 11) {
      dispatch(scanRecommendationsActions.setProductTourIndex(12));
    } else {
      const gptDetails: GptApiPayload = {
        ...codeInfoForGpt,
        context_type: 'magic',
        extra_details: {
          platform: '',
          has_elementor: false,
          has_visual_editor: false,
          template: '',
          language: devDomain.language,
        },
        businessId: currentBusiness?.id,
      };
      dispatch(
        getGPTSuggestion({
          ...gptDetails,
          gptType: 'magic',
          issue_id: devToolsSolution.id,
          notify: notifyOnComplete,
        }),
      );
      setOngoingGptType('magic');
    }
  };

  const gptCreativeAction = () => {
    if (productTourIndex === 13) {
      dispatch(scanRecommendationsActions.setProductTourIndex(14));
    } else {
      const gptDetails: GptApiPayload = {
        ...codeInfoForGpt,
        context_type: 'creative',
        extra_details: {
          platform: '',
          has_elementor: false,
          has_visual_editor: false,
          template: '',
          language: '',
        },
        businessId: currentBusiness?.id,
      };
      dispatch(
        getGPTSuggestion({
          ...gptDetails,
          gptType: 'creative',
          issue_id: devToolsSolution.id,
          notify: notifyOnComplete,
        }),
      );
      setOngoingGptType('creative');
    }
  };

  const gptGuideAction = () => {
    if (productTourIndex === 15) {
      dispatch(scanRecommendationsActions.setProductTourIndex(16));
    } else {
      const gptDetails: GptApiPayload = {
        ...codeInfoForGpt,
        context_type: 'guide',
        extra_details: {
          platform: devDomain.platform,
          has_elementor: devDomain.hasElementor,
          has_visual_editor: devDomain.hasVisualEditor,
          template: devDomain.template,
          language: '',
        },
        businessId: currentBusiness?.id,
      };
      setOngoingGptType('guide');
      dispatch(
        getGPTSuggestion({
          ...gptDetails,
          gptType: 'guide',
          issue_id: devToolsSolution.id,
          notify: notifyOnComplete,
        }),
      );
    }
  };

  const gptExplainAction = () => {
    if (productTourIndex === 17) {
      dispatch(scanRecommendationsActions.setProductTourIndex(18));
    } else {
      const gptDetails: GptApiPayload = {
        ...codeInfoForGpt,
        context_type: 'explain',
        extra_details: {
          platform: '',
          has_elementor: false,
          has_visual_editor: false,
          template: '',
          language: '',
        },
        businessId: currentBusiness?.id,
      };
      setOngoingGptType('explain');
      dispatch(
        getGPTSuggestion({
          ...gptDetails,
          gptType: 'explain',
          issue_id: devToolsSolution.id,
          notify: notifyOnComplete,
        }),
      );
    }
  };
  return (
    <GptButtonsDiv id={gptButtonsId}>
      <CustomToolTip
        title={MAGIC_TOOLTIP_MESSAGE}
        placement="top"
        TransitionComponent={Zoom}
        arrow
      >
        <ButtonWithIconDiv id="gptMagicButton" isDisabled={loadingGpt}>
          <ButtonWithIcon
            buttonText={ongoingGptType === 'magic' ? 'Thinking...' : 'Magic'}
            iconSrc="/magic-wand.svg"
            iconAltText="Magic Wand"
            backgroundColor="transparent"
            iconWidth="16px"
            hoverColor="#ECFCF2"
            borderRadius="8px"
            height="30px"
            borderStyle="1.5px solid #CBD2D9"
            onClickAction={!loadingGpt ? gptMagicAction : undefined}
            isGptBtn
            ref={magicButtonRef}
          />
        </ButtonWithIconDiv>
      </CustomToolTip>
      <CustomToolTip
        title={CREATIVE_TOOLTIP_MESSAGE}
        placement="top"
        TransitionComponent={Zoom}
        arrow
      >
        <ButtonWithIconDiv id="gptCreativeButton" isDisabled={loadingGpt}>
          <ButtonWithIcon
            buttonText={
              ongoingGptType === 'creative' ? 'Thinking...' : 'Creative'
            }
            iconSrc="/pencil-simple.svg"
            iconAltText="pencil icon"
            backgroundColor="transparent"
            iconWidth="16px"
            hoverColor="#ECFCF2"
            borderRadius="8px"
            height="30px"
            borderStyle="1.5px solid #CBD2D9"
            onClickAction={!loadingGpt ? gptCreativeAction : undefined}
            isGptBtn
            ref={creativeButtonRef}
          />
        </ButtonWithIconDiv>
      </CustomToolTip>
      <ButtonSeparator />
      <CustomToolTip
        title={GUIDE_TOOLTIP_MESSAGE}
        placement="top"
        TransitionComponent={Zoom}
        arrow
      >
        <ButtonWithIconDiv id="gptGuideButton" isDisabled={loadingGpt}>
          <ButtonWithIcon
            buttonText={ongoingGptType === 'guide' ? 'Thinking...' : 'Guide'}
            iconSrc="/book-open.svg"
            iconAltText="guide icon"
            backgroundColor="transparent"
            iconWidth="16px"
            hoverColor="#ecfcf2"
            borderRadius="8px"
            height="30px"
            borderStyle="1.5px solid #cbd2d9"
            onClickAction={!loadingGpt ? gptGuideAction : undefined}
            isGptBtn
            ref={guideButtonRef}
          />
        </ButtonWithIconDiv>
      </CustomToolTip>
      <CustomToolTip
        title={EXPLAIN_TOOLTIP_MESSAGE}
        placement="top"
        TransitionComponent={Zoom}
        arrow
      >
        <ButtonWithIconDiv id="gptExplainButton" isDisabled={loadingGpt}>
          <ButtonWithIcon
            buttonText={
              ongoingGptType === 'explain' ? 'Thinking...' : 'Explain'
            }
            iconSrc="/lightbulb.svg"
            iconAltText="light bulb icon"
            backgroundColor="transparent"
            iconWidth="16px"
            hoverColor="#ecfcf2"
            borderRadius="8px"
            height="30px"
            borderStyle="1.5px solid #cbd2d9"
            onClickAction={!loadingGpt ? gptExplainAction : undefined}
            isGptBtn
            ref={explainButtonRef}
          />
        </ButtonWithIconDiv>
      </CustomToolTip>
      <ButtonSeparator style={{ marginRight: 26 }} />
    </GptButtonsDiv>
  );
};

const GptButtonsDiv = styled.div`
  display: flex;

  @media screen and (max-width: 1030px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 768px) {
    width: fit-content;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ButtonSeparator = styled.span`
  background-color: #e0e0e0;
  width: 1px;
  height: 30px;
  margin-top: 12px;

  @media screen and (max-width: 1030px) {
    display: none;
  }
`;
