import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled as MuiStyled } from '@mui/material/styles';

export interface MuiSelectOptionProps {
  value: string | number;
  label: string | number;
}

interface MuiSelectProps {
  options: MuiSelectOptionProps[];
  selectLabel: string;
  value: string;
  name: string;
  handleChange: (event: SelectChangeEvent, fieldName?: string) => void;
  fullWidth?: boolean;
  required?: boolean;
}

export const MuiSelect: React.FC<MuiSelectProps> = ({
  options,
  selectLabel,
  value,
  name,
  handleChange,
  fullWidth,
  required,
}) => {
  return (
    <CustomFormControl fullWidth={fullWidth}>
      <InputLabel id="select-label">{selectLabel}</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={value}
        name={name}
        label={selectLabel}
        onChange={(evt) => handleChange(evt, name)}
        required={required}
      >
        {options.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </CustomFormControl>
  );
};

const CustomFormControl = MuiStyled(FormControl)({
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 10px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -5px) scale(0.75)',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: 5,
    height: 40,

    '& .MuiSelect-outlined': {
      minHeight: 'unset',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid #e4e7eb',
    },
  },
});
