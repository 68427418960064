import { requestAdminPanelEndpointConfig } from '../helpers';
import { ApiResponse } from '../utils/axios';
import axios from 'axios';
import { ADMIN_HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';

class AdminWidgetConfigAPI {
  static async getConfig(endpoint: string): Promise<ApiResponse<any>> {
    const config = requestAdminPanelEndpointConfig();
    let result;
    let error;
    try {
      result = await axios.get(ADMIN_HTTP_CLIENT_ENDPOINT + endpoint, config);
    } catch (e) {
      console.error('Error getting domains configs', e);
      error = e;
    }
    const { data, status } = result || {};

    return {
      data: (Array.isArray(data) ? data : []) as any,
      isSuccess: status === 200,
      error: error,
    };
  }

  static async saveConfig(
    endpoint: string,
    widgetConfig: any,
  ): Promise<ApiResponse<any>> {
    const config = requestAdminPanelEndpointConfig();
    let result;
    let error;
    try {
      result = await axios.post(
        ADMIN_HTTP_CLIENT_ENDPOINT + endpoint,
        widgetConfig,
        config,
      );
    } catch (e: any) {
      console.error('Error getting domains configs', e);
      error = e;
    }
    // return get(data, "message");
    const { data } = result || {};
    return {
      data: widgetConfig,
      isSuccess: data?.success ?? false,
      error: error,
    };
  }

  static async sendIntegrationCompleted(domainID: number) {
    const config = requestAdminPanelEndpointConfig();
    let result;
    let error;
    try {
      result = await axios.post(
        ADMIN_HTTP_CLIENT_ENDPOINT + 'integration-complete',
        { id: domainID },
        config,
      );
    } catch (e: any) {
      console.error('Error getting domains configs', e);
      error = e?.response?.data?.message ?? e?.message;
    }
    const { data } = result || {};
    return {
      data: data?.message || {},
      isSuccess: data?.code === 200,
      error,
    };
  }
}

export { AdminWidgetConfigAPI };
