import styled from '@emotion/styled';
import { Business } from '@equally-ai-front/common/src/types/business';
import React from 'react';
import { useSelector } from 'react-redux';
import { BusinessForm } from '../../../components/business-form/business-form';
import {
  TypographyH2,
  TypographyText2,
} from '../../../components/common/styled';
import {
  HeaderContainer,
  HeadingTypography,
  StepContainer,
  StepContent,
} from './common';
import { PrimaryButton } from '../../../components/redesign/Controls';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface BusinessStepProps {}

export const BusinessStep = (props: BusinessStepProps) => {
  const user = useSelector((state: any) => state.user.user);

  const navigate = useNavigate();

  const handleBackNavigation = () => {
    navigate('/');
  };

  return (
    <StepContainer>
      <BackButton color="primary" variant="text" onClick={handleBackNavigation}>
        <ArrowBack /> Back
      </BackButton>
      <HeaderContainer>
        <HeadingTypography>Register your business:</HeadingTypography>
      </HeaderContainer>

      <SubHeaderContainer>
        <SubHeading>
          {`We'll help you get started based on your response.`}
        </SubHeading>
      </SubHeaderContainer>

      <StepContent>
        <Subtitle>Hey, {user.name}</Subtitle>
        <BusinessForm />
      </StepContent>
    </StepContainer>
  );
};

const SubHeading = styled(TypographyH2)`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
`;
const SubHeaderContainer = styled.div`
  margin: 5px 0;
`;

const Subtitle = styled(TypographyText2)`
  font-weight: 400;
`;

const BackButton = styled(PrimaryButton)`
  align-self: flex-start;
`;
