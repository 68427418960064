import React from 'react';
import { ReactComponent as PartiallyCompliantSVG } from '../../assets/partially-compliant.svg';
import DesktopSkeleton from '../../assets/desktop-audit-view-skeleton.svg';
import MobileSkeleton from '../../assets/mobile-audit-view-skeleton.svg';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { ScanImage } from './scan-image';
import DownloadIcon from '../../assets/download.svg';
import {
  Container,
  IssueSummaryWrapper,
  IssueSummary,
  IssueSummaryContent,
  SkeletonImage,
  SkeletonImageWrapper,
  TypographyContainer,
  IssueSummaryContentWrapper,
  PdfDownloadButtonContainer,
  ScanHeaderText,
  ScanHeader,
  ScanText,
} from './styles';
import { PdfDownloadButton } from '../PdfDownloadButton';
import { EXTENDED_AUDIT } from '../aria-audit/consts';

interface PartiallyCompliantProps {
  loading: boolean;
  issues: DevtoolsSolution[];
  scanImageUrl: string;
}

export const styleConsts = {
  COLOR_SUCCESS_4: '',
  COLOR_SUCCESS_HOVER: '',
  COLOR_TEXT_DARK_1: '#ffffff',
};

export const PartiallyCompliant = (props: PartiallyCompliantProps) => {
  const { loading, issues, scanImageUrl } = props;
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  return (
    <Container>
      {loading && (
        <SkeletonImageWrapper className="skeleton skeleton-text">
          <SkeletonImage
            src={isMobile ? MobileSkeleton : DesktopSkeleton}
            alt=""
          />
        </SkeletonImageWrapper>
      )}

      {issues.length > 0 && !loading && (
        <IssueSummaryWrapper>
          <IssueSummary>
            <ScanImage scanImageUrl={scanImageUrl} />

            <IssueSummaryContentWrapper>
              <IssueSummaryContent>
                <TypographyContainer>
                  <ScanHeader>
                    <PartiallyCompliantSVG />
                    <ScanHeaderText variant="h4">
                      Compliance Alert
                    </ScanHeaderText>
                  </ScanHeader>
                  <ScanText>
                    We've identified key areas you need to improve your website
                    to ensure it is fully accessible to users and compliant with
                    legal standards.
                  </ScanText>
                </TypographyContainer>
              </IssueSummaryContent>

              <PdfDownloadButtonContainer>
                <PdfDownloadButton
                  pdfReport={issues}
                  websiteUrl={issues[0].domain_url}
                  styleConsts={styleConsts}
                  iconSrc={DownloadIcon}
                  iconAltText="Download icon"
                  backgroundColor="#454284"
                  borderStyle="none"
                  buttonText="Get the Report"
                  extendedAudit={EXTENDED_AUDIT}
                />
              </PdfDownloadButtonContainer>
            </IssueSummaryContentWrapper>
          </IssueSummary>
        </IssueSummaryWrapper>
      )}
    </Container>
  );
};
