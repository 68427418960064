import styled from '@emotion/styled';
import {
  deleteInvitation,
  getBusinessInvitations,
} from '@equally-ai-front/common/src/redux/business-slice/business';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../index';
import { setApiSuccessMessage } from '../../../store/actions';
import { TypographyH5 } from '../../common/styled';
import PlansTable from '../../common/Tables/PlansTable';
import { getColumns } from './TeamMemberTable';

interface InvitationsTableProps {
  invitations: any[];
}

export const InvitationsTable = ({ invitations }: InvitationsTableProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );

  const handleDeleteInvitation = async (_: any, data: any) => {
    const { payload: isSuccess }: any = await dispatch(
      deleteInvitation({
        businessId: currentBusiness.id,
        invitationId: data.id,
      }),
    );

    if (isSuccess) {
      dispatch(setApiSuccessMessage('Invitation deleted!'));
      dispatch(getBusinessInvitations({ businessId: currentBusiness.id }));
    }
  };

  const shouldHideActionButton = (tableRowData: any) => {
    if (
      currentBusiness.user_id === tableRowData.user_id ||
      personalDetails.id === tableRowData.user_id
    ) {
      return false;
    }

    return true;
  };

  return (
    <div>
      {invitations.length > 0 && (
        <div>
          <InvitesHeading>Pending Invites</InvitesHeading>
          <PlansTable
            currentIndex={1}
            data={invitations}
            handleSelectPlan={() => {}}
            selectedDomains={[]}
            setSelectedDomains={() => {}}
            showActionsButtons
            actionButtonProp={{
              showQuickActions: false,
              hideActionButton: shouldHideActionButton,
              handleSelectPlan: handleDeleteInvitation,
              actionLabel: 'DELETE',
              actionButtonStyle: {
                backgroundColor: 'transparent',
                color: 'red',
              },
            }}
            columns={getColumns('invite')}
          />
        </div>
      )}
    </div>
  );
};

const InvitesHeading = styled(TypographyH5)`
  font-size: 15px;
  padding: 3px 6px;
  font-weight: 500;
  color: ${({ theme }: any) => theme.palette.primary.main};
  margin-top: 25px;
`;
