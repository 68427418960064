import { WidgetConfigurations } from '../helpers';
import { isNullOrUndefined, capitalizeFirstLetter } from '../utils/helpers';
import { Issue, Scan } from '../types/issues';

enum IssueSeverityEnum {
  notice = 'Low',
  warning = 'Medium',
  error = 'High',
}

export const IssueSeverityEnumLookup: Record<string, IssueSeverityEnum> = {
  notice: IssueSeverityEnum.notice,
  warning: IssueSeverityEnum.warning,
  error: IssueSeverityEnum.error,
};

export const getIssueGroupName = (title: string): string => {
  switch (title) {
    case 'tables':
      return 'Table Data: Cells and Columns';
    case 'clickables':
      return 'Clickable Elements: Links and Buttons';
    case 'title':
      return 'Headings: Structured and Visual Headings.';
    case 'graphics':
      return 'Graphics: Icons, Images, and Backgrounds';
    default:
      return capitalizeFirstLetter(title);
  }
};

export const generateUniqueId = (): string => {
  const timestamp = Date.now().toString(36);
  const random = Math.random().toString(36).slice(2, 7);
  return timestamp + random;
};

export const isRecommendationEmpty = (recommendation: any) => {
  return recommendation === '{}' || isNullOrUndefined(recommendation);
};

export const parseIssueRecommendation = (
  recommendation: string,
): Record<string, Record<WidgetConfigurations, Record<string, any>>> | null => {
  let recommendationObj;

  if (recommendation === '{}') {
    return null;
  }

  try {
    recommendationObj = JSON.parse(recommendation);
  } catch (error) {
    recommendationObj = null;
  }

  return recommendationObj;
};

export const filterReportByRecommendation = (
  issues: Issue[],
  reportType: 'html' | 'wcag' | 'recommendation',
): Issue[] => {
  if (reportType !== 'recommendation') {
    return issues;
  }
  return issues.filter((issue) => !isRecommendationEmpty(issue.recommendation));
};

export const onSortByRecommendation = (issue1: Issue, issue2: Issue) => {
  const issue1Recommendation = isRecommendationEmpty(issue1.recommendation);
  const issue2Recommendation = isRecommendationEmpty(issue2.recommendation);

  if (!issue1Recommendation || issue2Recommendation) {
    return -1; // a comes first
  }

  if (issue1Recommendation && !issue2Recommendation) {
    return 1; // b comes first
  }
  return 0; // maintain order
};

export const getCurrentScanIssueSummary = (
  currentScan: Scan,
  issues: Issue[],
): Scan => {
  const issuesTypes: Record<string, number> = {
    notice: 0,
    error: 0,
    warning: 0,
  };
  issues.forEach(({ type }) => {
    issuesTypes[type] = (issuesTypes[type] || 0) + 1;
  });
  return { ...currentScan, ...issuesTypes };
};
