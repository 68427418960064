import styled from '@emotion/styled';
import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface TextLinkProps {
  to: string;
  target: string;
  rel: string;
  onClick: () => void;
  children: React.ReactNode;
  [x: string]: any;
}

const TextLink = (props: TextLinkProps) => {
  const { to, children } = props;
  const linkProps = {
    ...props,
    ...(to ? { component: RouterLink } : {}),
  };

  return (
    <StyledLink {...linkProps} color="secondary">
      {children}
    </StyledLink>
  );
};

TextLink.defaultProps = {
  to: null,
  target: '',
  rel: '',
  onClick: () => {},
};

export default TextLink;

/** Styled Components */

const StyledLink = styled(Link)`
  transition: border 0.3s ease-out;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  line-height: initial;
  color: inherit;

  display: inline-block;

  &:hover {
    text-decoration: none;
    border-bottom: 2px solid #6966b2;
  }
`;
