import { Issue } from '@equally-ai-front/common/src/types/issues';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/index';
import { FetchTotalScansPayload, IssuesAPI } from '../api/issues-api';

interface IssueState {
  allIssues: Issue[];
  loading: boolean;
}

const initialState: IssueState = {
  allIssues: [],
  loading: false,
};

export const fetchAllIssues = createAsyncThunk(
  'all-issues/fetch',
  async (payload: FetchTotalScansPayload): Promise<ApiResponse<Issue[]>> => {
    let result;
    let apiError;

    try {
      result = await IssuesAPI.getAllIssues(payload);
    } catch (err) {
      apiError = 'Error fetching all issues.';
    }

    const { data, error, isSuccess } = result || {};
    return {
      data,
      error: error || apiError,
      isSuccess: isSuccess || false,
    };
  },
);

export const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    resetIssues: (state) => {
      return {
        ...state,
        allIssues: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllIssues.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchAllIssues.fulfilled, (state, action) => {
        if (state.loading && action.payload.data) {
          const issuesSortedByMostRecent = action.payload.data.sort(
            (firstIssue, secondIssue) => {
              const firstIssueTimeStamp = new Date(
                firstIssue.updated_at,
              ).getTime();
              const secondIssueTimeStamp = new Date(
                secondIssue.updated_at,
              ).getTime();
              return secondIssueTimeStamp - firstIssueTimeStamp;
            },
          );

          return {
            ...state,
            loading: false,
            allIssues: issuesSortedByMostRecent,
          };
        }
        return state;
      })
      .addCase(fetchAllIssues.rejected, (state) => {
        if (state.loading) {
          return { ...state, loading: true };
        }
        return state;
      });
  },
});

export const selectIssues = (state: RootState) => state.issues;
export const { resetIssues } = issuesSlice.actions;
export default issuesSlice.reducer;
