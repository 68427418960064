import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Issue, Scan } from '@equally-ai-front/common/src/types/issues';
import { IssuesByElement } from './issues-by-element';
import { IssuesByWcag } from './issues-by-wcag';

interface IssuesChartProps {
  scanIssuesGroupingByElement: any[];
  issues: Issue[];
  history: Scan[];
}

export const IssuesChart = (props: IssuesChartProps) => {
  const { scanIssuesGroupingByElement, issues, history } = props;

  return (
    <Wrapper>
      <>
        {issues.length > 0 ? (
          <ChartsWrapper>
            <IssuesByElement
              scanIssuesGroupingByElement={scanIssuesGroupingByElement}
              issues={issues}
            />
            <IssuesByWcag issues={issues} />
          </ChartsWrapper>
        ) : (
          <NoDataText>No Data</NoDataText>
        )}
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 330px;
  overflow: hidden;
  box-shadow: 0px 4px 17px 0px #08256417;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
    box-shadow: none;
    overflow: initial;
    height: fit-content;
  }
`;

const NoDataText = styled.p`
  width: 100%;
  text-align: center;
  margin: 10px 0 20px;
  font-weight: 500;
`;
