import React from 'react';
import styled from '@emotion/styled';

import { Tabs as MuiTabs, Tab as MuiTab, useMediaQuery } from '@mui/material';

function a11yProps(key: string) {
  return {
    id: `pricingswitch-tab-${key}`,
    'aria-controls': `pricingswitch-tabpanel-${key}`,
  };
}

export type TabType = {
  key: 'oneYear' | 'oneMonth';
  label: string;
  badge?: string;
  value: number;
};

interface PriceTabsProps {
  currentTabIdx: number;
  tabs: TabType[];
  onTabChange: (_: any, newValue: number) => void;
  pricesLength: {
    oneYear: number;
    oneMonth: number;
  };
}

const PriceTabs = ({
  currentTabIdx,
  onTabChange,
  tabs,
  pricesLength,
}: PriceTabsProps) => {
  const orientation = 'horizontal';
  const isSm = useMediaQuery('(max-width: 600px)');

  return (
    <Tabs
      value={currentTabIdx}
      onChange={onTabChange}
      orientation={orientation}
      centered
    >
      {tabs.map((tab) => {
        if (pricesLength[tab.key] === 0) {
          return null;
        }

        return (
          <Tab
            key={tab.key}
            {...a11yProps(tab.key)}
            value={tab.value}
            label={
              <TabContent>
                {tab.label}
                {tab.badge && !isSm && (
                  <>
                    &nbsp;
                    <Badge>
                      <BadgeText>{tab.badge}</BadgeText>
                    </Badge>
                  </>
                )}
              </TabContent>
            }
          />
        );
      })}
    </Tabs>
  );
};

export default PriceTabs;

/** Styled Components */

const Tabs = styled(MuiTabs)`
  background: #f5f7fa;
  border-radius: 24px;

  @media screen and (max-width: 600px) {
    padding: 0;

    & .MuiTab-root {
      width: fit-content;
    }
  }

  & .MuiTab-root {
    max-width: initial;
    width: 150px;
  }

  & .MuiTabs-scroller {
    z-index: 0;
  }

  & .MuiTabs-indicator {
    background: #ffffff;

    border: 2px solid #cbd2d9;
    box-sizing: border-box;
    border-radius: 24px;

    height: inherit;
    width: inherit;

    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }

  & .MuiTabs-flexContainer {
    flex-direction: row;
  }

  width: 100%;
  @media screen and (min-width: 600px) {
    border-radius: 48px;
    width: initial;
  }

  @media screen and (max-width: 600px) {
    & .MuiTab-root {
      flex: 1;
      flex-direction: row;
    }
  }
`;

const Tab = styled(MuiTab)`
  /* min-width: 200px; */

  @media screen and (max-width: 600px) {
    max-width: 40%;
  }
`;

const Badge = styled.span`
  background: #454284;
  border-radius: 8px;
  color: #fff;
  padding: 4px 8px;
`;

const BadgeText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;

  text-transform: uppercase;
`;

const TabContent = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000a14;
  text-transform: initial;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;
