import {
  CustomTextField,
  MuiInput,
} from '@equally-ai-front/common/src/components/custom-mui-input/MuiInput';
import { IssueSeverity } from '@equally-ai-front/common/src/types/issues';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { styled as MuiStyled } from '@mui/material/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MuiRadioInput, RadioInput } from '../../components/mui-radio-input';
// import { NotificationFrequency } from './notification-frequency';
import { GenericInput } from '../../../accessibility-components/generic-input';
import { NotificationSettings, WebsiteSettings } from './preferences-page';

const criticalIssueRadioInputs: RadioInput[] = [
  {
    id: '1',
    label: 'Instant',
    value: 'instant',
  },
  {
    id: '2',
    label: 'Daily',
    value: 'daily',
  },
  {
    id: '3',
    label: 'Weekly',
    value: 'weekly',
  },
  {
    id: '4',
    label: 'Monthly',
    value: 'monthly',
  },
  {
    id: '5',
    label: 'Never',
    value: 'never',
  },
];

interface SelectedNotifications {
  value: string;
  label: string;
}

const notificationTypeOptions = [
  { label: 'Potential Issues', value: 'potential_issues' },
  { label: 'Manual Issues', value: 'manual_issues' },
  { label: 'Order Messages', value: 'order_messages' },
  { label: 'My Account', value: 'my_account' },
  { label: 'Security Updates', value: 'security_updates' },
  { label: 'Notification Sound', value: 'notification_sound' },
];

interface NotificationProps {
  websiteSettings: WebsiteSettings;
  onSave: (notificationSettings: NotificationSettings) => void;
}

export const Notification = (props: NotificationProps) => {
  const { websiteSettings, onSave } = props;
  const [email, setEmail] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState<
    SelectedNotifications[]
  >([]);
  const [notificationFreq, setNotificationFreq] = useState<
    Record<'email' | 'browser', Record<IssueSeverity, string>>
  >({
    email: { error: '', warning: '', notice: '' },
    browser: { error: '', warning: '', notice: '' },
  });
  const isEmailValid = (newEmail: string) => {
    const re = /\S+@\S+\.\S+/;
    return newEmail.split(';').every((e) => re.test(e));
  };
  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };
  const handleSave = () => {
    if (!isEditMode) {
      handleEditClick();
      return;
    }

    if (!isEmailValid(email)) {
      return;
    }

    onSave({
      email_recipients: email.split(';'),
      ...notificationFreq,
    });
  };

  // const [dashboardNotifications, setDashboardNotifications] = useState<
  //   SelectedNotifications[]
  // >([]);

  useEffect(() => {
    if (websiteSettings) {
      setNotificationFreq({
        email: websiteSettings.notificationSettings.email,
        browser: websiteSettings.notificationSettings.browser,
      });
      setEmail(websiteSettings.notificationSettings.email_recipients.join(';'));
    }
  }, [websiteSettings]);

  const handleEmailNotificationChange = (
    event: ChangeEvent<{}>,
    newValue: SelectedNotifications[],
  ) => {
    setEmailNotifications(newValue);
  };

  // const handleDashboardNotificationChange = (
  //   event: ChangeEvent<{}>,
  //   newValue: SelectedNotifications[],
  // ) => {
  //   setDashboardNotifications(newValue);
  // };

  const handleRadioInputChange = (
    evt: ChangeEvent<HTMLInputElement>,
    option: IssueSeverity,
  ) => {
    const { value } = evt.target;
    const newNotificationFreq = {
      ...notificationFreq,
      email: { ...notificationFreq.email, [option]: value },
    };
    setNotificationFreq(newNotificationFreq);
  };

  const getOptionHeader = (option: IssueSeverity) => {
    switch (option) {
      case 'error':
        return 'Critical';
      case 'warning':
        return 'Potential';
      case 'notice':
        return 'Best Practice';
      default:
        return '';
    }
  };

  return (
    <Wrapper>
      <HeaderContainer>
        <NotificationsHeader>
          <NotificationTitle variant="h2">Notifications</NotificationTitle>
          <NotificationsInfo>
            {/* <InfoOutlinedIcon sx={{ fontSize: 12 }} /> */}
            <NotificationsInfoText
              style={{ fontWeight: 400, fontSize: '14px' }}
            >
              Select when and how you would like to get notified on errors
            </NotificationsInfoText>
          </NotificationsInfo>
        </NotificationsHeader>
        <Button onClick={handleSave} variant="contained" color="primary">
          {isEditMode ? 'Save' : 'Edit'}
        </Button>
      </HeaderContainer>
      <Spacer />
      <Spacer />
      <NotificationsInfo>
        <InfoOutlinedIcon sx={{ fontSize: 12 }} />
        <NotificationsInfoText>
          Enter email addresses to receive scan notifications; separate
          multiples with &quot;;&quot;.
        </NotificationsInfoText>
      </NotificationsInfo>
      <FormFieldWrapper>
        <GenericInput
          placeholder="Email"
          error={email !== '' && !isEmailValid(email)}
          ariaProps={{
            'aria-label': 'email',
            'aria-invalid': `${!isEmailValid(email)}`,
            'aria-errormessage': `${
              email !== '' && !isEmailValid(email) ? 'email is invalid.' : ''
            }`,
          }}
          value={email}
          helperText={
            email !== '' && !isEmailValid(email) ? 'email is invalid.' : ''
          }
          name="email"
          onChange={(evt) => setEmail(evt.target.value)}
          type="email"
          disabled={!isEditMode}
        />
      </FormFieldWrapper>
      <EmailNotificationsContainer>
        {/* <NotificationType variant="body1">Email notifications</NotificationType> */}
        {/* {isEditMode ? ( */}
        {/*  <FormFieldWrapper> */}
        {/*    <Autocomplete */}
        {/*      multiple */}
        {/*      id="email-multi-select" */}
        {/*      options={notificationTypeOptions} */}
        {/*      onChange={handleEmailNotificationChange} */}
        {/*      value={emailNotifications} */}
        {/*      getOptionLabel={(option) => option.label} */}
        {/*      renderInput={(params) => <CustomTextField {...params} />} */}
        {/*    /> */}
        {/*  </FormFieldWrapper> */}
        {/* ) : ( */}
        {/*  <ChipsWrapper> */}
        {/*    {emailNotifications.length > 0 && */}
        {/*      emailNotifications.map(({ value, label }) => ( */}
        {/*        <MuiChip key={value} label={label} /> */}
        {/*      ))} */}
        {/*  </ChipsWrapper> */}
        {/* )} */}
      </EmailNotificationsContainer>
      {/* <DashboardNotifications> */}
      {/*  <NotificationsHeader> */}
      {/*    <NotificationType variant="body1" gutterBottom> */}
      {/*      Dashboard notifications */}
      {/*    </NotificationType> */}
      {/*  </NotificationsHeader> */}
      {/*  {isEditMode ? ( */}
      {/*    <FormFieldWrapper> */}
      {/*      <Autocomplete */}
      {/*        multiple */}
      {/*        id="dashboard-multi-select" */}
      {/*        options={notificationTypeOptions} */}
      {/*        onChange={handleDashboardNotificationChange} */}
      {/*        value={dashboardNotifications} */}
      {/*        getOptionLabel={(option) => option.label} */}
      {/*        renderInput={(params) => <CustomTextField {...params} />} */}
      {/*      /> */}
      {/*    </FormFieldWrapper> */}
      {/*  ) : ( */}
      {/*    <ChipsWrapper> */}
      {/*      {dashboardNotifications.length > 0 && */}
      {/*        dashboardNotifications.map(({ value, label }) => ( */}
      {/*          <MuiChip key={value} label={label} /> */}
      {/*        ))} */}
      {/*    </ChipsWrapper> */}
      {/*  )} */}
      {/* </DashboardNotifications> */}
      <Spacer />
      {Object.keys(
        websiteSettings.notificationSettings.email,
        // @ts-ignore
      ).map((option: IssueSeverity, index: number) => {
        return (
          <CriticalIssuesFrequency key={`issue-severity-${index}`}>
            <Title variant="h2">{getOptionHeader(option)}</Title>
            {/* <NotificationsInfo> */}
            {/*  <InfoOutlinedIcon sx={{ fontSize: 12 }} /> */}
            {/*  <NotificationsInfoText> */}
            {/*    Select when and how you would like to get notified on errors. */}
            {/*  </NotificationsInfoText> */}
            {/* </NotificationsInfo> */}
            <CriticalIssuesInner>
              <MuiRadioInput
                isDisabled={!isEditMode}
                radioList={criticalIssueRadioInputs}
                radioInputName={`${option}-radio-button`}
                radioInputValue={notificationFreq.email[option]}
                handleRadioInputChange={(event) =>
                  handleRadioInputChange(event, option)
                }
              />
            </CriticalIssuesInner>
          </CriticalIssuesFrequency>
        );
      })}
      {/* <NotificationFrequency websiteSettings={websiteSettings} onSave={onSave}/> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 45px;
`;

const NotificationTitle = MuiStyled(Typography)({
  '&.MuiTypography-h2': {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
  },
});

const NotificationType = MuiStyled(Typography)({
  '&.MuiTypography-body1': {
    fontSize: 12,
    fontWeight: 500,
  },
});

const NotificationsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NotificationsInfo = styled.p`
  font-size: 12px;
  color: rgba(0, 10, 20, 0.85);
  display: flex;
  align-items: center;
`;

const NotificationsInfoText = styled.span`
  margin-left: 3px;
`;

const EmailNotificationsContainer = styled.div`
  margin: 10px 0 20px;
`;

const DashboardNotifications = styled.div``;

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 20px;
`;

const MuiChip = MuiStyled(Chip)({
  '&.MuiChip-root': {
    margin: '10px 10px 0 0',
  },
});

const FormFieldWrapper = styled.div`
  max-width: 300px;

  .MuiFormControl-root {
    width: 100%;
  }
`;
const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const Spacer = styled.div`
  height: 20px;
`;
const CriticalIssuesFrequency = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
`;
const CriticalIssuesInner = styled.div`
  display: flex;
  margin-top: 10px;
`;
const Title = MuiStyled(Typography)({
  '&.MuiTypography-h2': {
    fontSize: 16,
    fontWeight: 500,
  },
});
