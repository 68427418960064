import styled from '@emotion/styled';
import { getServices } from '@equally-ai-front/common/src/redux/services-slice/services';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALL_PLANS_OPTION } from '../../../common/magicValues';
import { getActivePlans } from '../../../store/actions';
import Container from '../../common/Container';
import { HeadingTypography } from '../../common/heading';
import { PrimaryButton } from '../Controls';
import { ModalPanel } from '../ModalPanel';
import { SERVICES, SERVICES_TYPES } from './consts';
import NewProject from './NewProject/NewProject';
import { ServiceAccordion } from './service-type-card/service-accordion';
import { usePlans } from '../../../providers/plans-context';

export const Services = () => {
  const [selectedPlan, setSelectedPlan] = useState(ALL_PLANS_OPTION);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subTypeToPlansMap, setSubTypeToPlansMap] = useState({});
  const servicesToPlanMap = {};
  const { activePlans: plansData } = usePlans();
  const services = useSelector((state) => state.services.services);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActivePlans('service'));
  }, [dispatch]);
  useEffect(() => {
    if (!plansData || plansData.length === 0) {
      return;
    }
    const subTypes = plansData.map((plan) => plan.plan_sub_type);
    const plansIds = plansData.map((plan) => plan.id);
    dispatch(
      getServices({
        types: Array.from(new Set(subTypes)),
        planIds: Array.from(new Set(plansIds)),
      }),
    );
  }, [plansData, dispatch]);

  useEffect(() => {
    if (services.length === 0) {
      return;
    }
    // dispatch(getAllSubscriptionRecord(Object.keys(subTypeToPlansMap)));
  }, [dispatch, plansData, subTypeToPlansMap]);

  useEffect(() => {
    if (!plansData) return;
    const newSubTypeToPlansMap = {};
    const subTypeToServicesMap = {};

    if (services.length >= 0) {
      services.forEach((service) => {
        if (!subTypeToServicesMap[service.type]) {
          subTypeToServicesMap[service.type] = [];
        }
        subTypeToServicesMap[service.type] = [
          ...subTypeToServicesMap[service.type],
          service,
        ];
      });
    }

    plansData.forEach((plan) => {
      const { plan_sub_type: subType } = plan;
      if (!newSubTypeToPlansMap[subType]) {
        newSubTypeToPlansMap[subType] = {
          credits: 0,
          remainedCredits: 0,
          plans: [],
          services: [],
        };
      }
      newSubTypeToPlansMap[subType] = {
        credits: newSubTypeToPlansMap[subType].credits + plan.quantity,
        remainedCredits:
          newSubTypeToPlansMap[subType].remainedCredits +
          (plan.used_credit || 0),
        plans: newSubTypeToPlansMap[subType].plans.push(plan),
        services: subTypeToServicesMap[subType],
      };
    });

    setSubTypeToPlansMap(newSubTypeToPlansMap);
  }, [plansData, services]);

  const handleSelectPlan = (e) => {
    const { value } = e.target;
    setSelectedPlan(value);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const serviceUrls = useSelector((state) => state.domains.serviceUrls);

  const serviceOptions = [
    ...new Set(plansData.map((plan) => plan.plan_sub_type)),
  ];
  const cards = SERVICES_TYPES.map((serviceName) => {
    const serviceDetails = SERVICES[serviceName];
    return (
      <ServiceTypeCardContainer>
        {subTypeToPlansMap && (
          <ServiceAccordion
            name={serviceDetails.name}
            services={subTypeToPlansMap?.[serviceName]?.services || []}
            credits={subTypeToPlansMap?.[serviceName]?.credits || 0}
            remainedCredits={
              subTypeToPlansMap?.[serviceName]?.remainedCredits || 0
            }
            description={serviceDetails.description}
          />
        )}
      </ServiceTypeCardContainer>
    );
  });

  return (
    <Container>
      <div className="header-manage-websites">
        <div className="header-manage-websites-text">
          <HeadingTypography>Services</HeadingTypography>
        </div>
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={toggleModal}
        >
          <span aria-hidden="true" style={{ marginRight: '5px' }}>
            +
          </span>
          Start new project
        </PrimaryButton>
      </div>

      <ModalPanel
        open={isModalOpen}
        handleClose={toggleModal}
        title="Start Project"
      >
        <NewProject options={serviceOptions} />
      </ModalPanel>
      <div>{cards}</div>
    </Container>
  );
};

const ServiceTypeCardContainer = styled.div`
  margin: 1vh 0;
`;
