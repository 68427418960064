import React, { memo } from 'react';
import styled from 'styled-components';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { usePdfReport } from '../../hooks';

interface PdfDownloadButtonProps {
  buttonText: string;
  iconSrc: string;
  iconAltText: string;
  backgroundColor: string;
  borderStyle: string;
  // onClickAction: () => void;
  pdfReport: DevtoolsSolution[];
  websiteUrl: string;
  styleConsts: Record<string, string>;
  extendedAudit?: boolean;
}

export const PdfDownloadButton = memo((props: PdfDownloadButtonProps) => {
  const {
    buttonText,
    iconSrc,
    iconAltText,
    backgroundColor,
    borderStyle,
    // onClickAction,
    pdfReport,
    websiteUrl,
    styleConsts,
    extendedAudit,
  } = props;
  const { loadingPdf, handleDownloadReportPdf } = usePdfReport();

  return (
    <Wrapper>
      <Button
        colorSuccess={styleConsts.COLOR_SUCCESS_4}
        bgColor={backgroundColor}
        borderStyle={borderStyle}
        colorSuccessHover={styleConsts.COLOR_SUCCESS_HOVER}
        onClick={() =>
          handleDownloadReportPdf(
            websiteUrl,
            pdfReport,
            [],
            [],
            [],
            null,
            '',
            extendedAudit,
          )
        }
      >
        <ButtonIcon src={iconSrc} alt={iconAltText} />
        <ButtonText color={styleConsts.COLOR_TEXT_DARK_1}>
          {buttonText}
        </ButtonText>
      </Button>
      <LoadingView open={loadingPdf} loadingText="Loading Report PDF..." />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

const Button = styled.button<{
  bgColor: string;
  borderStyle: string;
  isLoading?: boolean;
  colorSuccessHover: string;
  colorSuccess: string;
}>`
  border: none;
  border: ${(props) => props.borderStyle};
  padding: 6px 16px 6px 12px;
  border-radius: 8px;
  display: ${(props) => (props.isLoading ? 'none' : 'flex')};
  align-items: center;
  height: 36px;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};

  &:hover {
    background-color: ${(props) =>
      props.borderStyle !== 'none' ? '' : props.colorSuccessHover};
    border-color: ${(props) =>
      props.borderStyle !== 'none' && props.colorSuccess};
  }
`;

const ButtonIcon = styled.img`
  width: fit-content;
`;

const ButtonText = styled.span<{ color: string }>`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.color};
  margin-left: 5px;
`;
