// import { ButtonWithIcon } from '@equally-ai-front/common/src/components/button/ButtonWithIcon';
// import {
//   addDomainToUrl,
//   combineWebsiteUrlAndPath,
// } from '@equally-ai-front/common/src/utils';
import { formatUTCDate } from '@equally-ai-front/common/src/helpers';
import { Scan } from '@equally-ai-front/common/src/types/issues';
import {
  HIGH_IMPACT_MESSAGE,
  LOW_IMPACT_MESSAGE,
  MEDIUM_IMPACT_MESSAGE,
} from '@equally-ai-front/common/src/utils/constants';
import { getPageName } from '@equally-ai-front/common/src/lib/domains';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ImpactLevelBadge } from '@equally-ai-front/common/src/components/impact-level-badge';
import { PrimaryButton } from '../../../components/redesign/Controls';
import { Business } from '@equally-ai-front/common/src/types/business';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { DashboardExportPopUp } from './dashboard-export-popup';
import { Typography } from '@mui/material';

interface DashboardHeaderProps {
  pdfReport: DevtoolsSolution[];
  websiteUrl: string;
  currentBusiness: Business;
  currentDevDomain: DevDomain;
  currentScan: Scan;
}

export const DashboardHeader = ({
  pdfReport,
  currentBusiness,
  currentDevDomain,
  currentScan,
}: DashboardHeaderProps) => {
  const [openExportPopUp, setOpenExportPopUp] = useState(false);

  const handleOpenExportPopUp = () => {
    setOpenExportPopUp(true);
  };

  const handleCloseExportPopUp = () => {
    setOpenExportPopUp(false);
  };

  return (
    <Wrapper>
      <div>
        <Header>
          <HeadingText>{getPageName(currentScan)}</HeadingText>
          <ExportButton
            color="primary"
            variant="contained"
            onClick={handleOpenExportPopUp}
          >
            Export
          </ExportButton>
        </Header>
        <DateScanned>
          <span className="label">Last scan: &nbsp;</span>
          <span>{currentScan && formatUTCDate(currentScan?.updated_at)}</span>
        </DateScanned>
        {/* <div>
          <ButtonWithIcon
            buttonText="Re-Scan"
            iconSrc="/refresh.svg"
            iconAltText="refresh icon"
            backgroundColor="#4AD493"
            borderStyle="none"
            hoverColor="#4AD493"
            onClickAction={rescan}
          />
        </div> */}
      </div>
      <ImpactLevels>
        <ImpactLevelRow>
          <ImpactLevelBadge
            alt=""
            impactNumber={currentScan.error}
            iconSrc="/info-circle-red.svg"
            backgroundColor="#FF9999"
            color="#661F20"
            toolTipMessage={HIGH_IMPACT_MESSAGE}
          >
            <ImpactLevelLabel>Critical</ImpactLevelLabel>
          </ImpactLevelBadge>
        </ImpactLevelRow>
        <ImpactLevelRow>
          <ImpactLevelBadge
            alt=""
            impactNumber={currentScan.warning}
            iconSrc="/info-circle-red.svg"
            backgroundColor="#F2DF87"
            color="#6F321B"
            toolTipMessage={MEDIUM_IMPACT_MESSAGE}
          >
            <ImpactLevelLabel>Potential</ImpactLevelLabel>
          </ImpactLevelBadge>
        </ImpactLevelRow>
        <ImpactLevelRow>
          <ImpactLevelBadge
            alt=""
            impactNumber={currentScan.notice}
            iconSrc="/info-circle.svg"
            backgroundColor="#AEEAD3"
            color="#1E5643"
            toolTipMessage={LOW_IMPACT_MESSAGE}
          >
            <ImpactLevelLabel>Best Practice</ImpactLevelLabel>
          </ImpactLevelBadge>
        </ImpactLevelRow>
      </ImpactLevels>
      <DashboardExportPopUp
        openExportPopUp={openExportPopUp}
        handleCloseExportPopUp={handleCloseExportPopUp}
        currentBusinessId={currentBusiness.id}
        currentDevDomainId={currentDevDomain.id}
        pdfReport={pdfReport}
        hostCurrentFullUrl={pdfReport?.[0]?.domain_url}
        devDomainName={currentDevDomain.name}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
  }
`;

const HeadingText = styled(Typography)`
  font-weight: 500;
  font-size: 24px;
  text-transform: capitalize;

  @media screen and (max-width: 768px) {
    width: 150px;
    white-space: nowrap;
  }

  @media (pointer: none), (pointer: coarse) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

const DateScanned = styled.p`
  margin-top: 10px;
  color: rgba(0, 10, 20, 0.65);
  font-size: 13px;

  .label {
    font-weight: 500;
  }
`;

const ImpactLevels = styled.div`
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const ImpactLevelRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ImpactLevelLabel = styled.span`
  display: inline-block;
  font-weight: 500;
  color: #000a14;
  font-size: 16px;
  margin-right: 4px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`;

const ExportButton = styled(PrimaryButton)`
  height: 36px;
  background-color: transparent;
  border: 2px solid #cbd2d9;
  padding: 6px 16px 6px 12px;
  border-radius: 8px;
  color: #000a14;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    border-color: #454284;
    box-shadow: none;
  }
`;
