import {
  InfoContent,
  MainHeadersContainer,
} from '../../../components/flowy-components/components/common';
import { CreateScanButton } from './create-scan-button';
import { ScansTable } from './scan-table/scans-table';
import {
  groupScanHistoryDataMap,
  rowData,
  rowMap,
  scanHistoryDataMap,
  groupRowMap,
  groupRowData,
} from '../const';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { NewScanInfo } from '@equally-ai-front/common/src/types/issues';
import { Box } from '@mui/material';
import {
  StyledTab,
  StyledTabs,
} from '@equally-ai-front/common/src/components/styled-tabs/styled-tabs';

interface PageAndGroupScanTableProps {
  onAudit: (url: string, cb?: () => void, scanDetails?: NewScanInfo) => void;
}

export const PageAndGroupScanTable = (props: PageAndGroupScanTableProps) => {
  const { onAudit } = props;
  const { history, scanGroups } = useSelector((state: RootState) => ({
    history: state.scanHistory.history,
    scanGroups: state.scanHistory.scanGroups,
  }));

  const { currentDevDomain } = useSelector((state: RootState) => ({
    currentDevDomain: state.dashboard.currentDevDomain,
  }));

  const [currentTab, setCurrentTab] = useState(0);

  const onTabChange = (_: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const getScansById = useCallback(
    (groupId: number) => history.filter((scan) => scan.group_id === groupId),
    [history],
  );

  const tableData = useMemo(() => {
    if (currentTab === 0) {
      const groups = Object.values(scanGroups);
      return groups.map((group: any) => ({
        ...group,
        scans: getScansById(group.id),
      }));
    }

    return history;
  }, [history, currentTab, getScansById, scanGroups]);

  return (
    <>
      <EmptyStateWrapper>
        <EmptyStateContainer>
          <MainHeadersContainer>
            <CustomInfoContent>Scan a page to start</CustomInfoContent>
          </MainHeadersContainer>
          <WebsiteInputContainer>
            <EmptyContent>
              {currentDevDomain && (
                <CreateScanButton
                  onAudit={onAudit}
                  devDomain={currentDevDomain}
                />
              )}
            </EmptyContent>
          </WebsiteInputContainer>
        </EmptyStateContainer>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <StyledTabs
            value={currentTab}
            onChange={onTabChange}
            orientation="horizontal"
            centered
          >
            <StyledTab
              label="Groups"
              style={{ textTransform: 'capitalize' }}
              value={0}
            />
            <StyledTab
              label="Pages"
              style={{ textTransform: 'capitalize' }}
              value={1}
            />
          </StyledTabs>
        </Box>
      </EmptyStateWrapper>

      <ScansTable
        data={tableData}
        headingData={currentTab === 0 ? groupRowData : rowData}
        headingMap={currentTab === 0 ? groupRowMap : rowMap}
        dataMap={
          currentTab === 0 ? groupScanHistoryDataMap : scanHistoryDataMap
        }
        isGroupScanTable={currentTab === 0}
      />
    </>
  );
};

const WebsiteInputContainer = styled.div``;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
`;

const CustomInfoContent = styled(InfoContent)`
  font-weight: 400;
  font-size: 20px;
  color: #000000;
`;

const EmptyContent = styled.div`
  //margin-top: 3%;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //padding: 10px;
  //height: 20vh;
  //background-color: #ffffff;
`;

const EmptyStateWrapper = styled.div`
  height: 100%;
  margin-bottom: 30px;
`;
