import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { nanoid } from 'nanoid';
import Proptypes from 'prop-types';
import React from 'react';
import { PrimaryMobileButton } from '../../redesign/Controls';

const columns = [
  {
    id: 'index',
    label: '#',
    minWidth: 100,
  },
  {
    id: 'plan_type',
    label: 'Type',
    minWidth: 100,
    format: (value) => value,
  },
  {
    id: 'plan_template',
    label: 'Name',
    minWidth: 100,
    format: (value) => value.name,
  },
  {
    id: 'plan_template',
    label: 'Amount',
    minWidth: 70,
    format: (value) => `${value.price} USD`,
  },
  {
    id: 'expiration',
    label: 'Expiration Date',
    minWidth: 70,
    format: (value) => {
      const isBefore = moment(value).isBefore(moment());
      if (isBefore) {
        return 'Expired';
      }
      const dateString = moment(value).format('YYYY-MM-DD HH:mm:ss');
      return dateString;
    },
  },
  {
    id: 'quantity',
    label: 'Remained Credits',
    minWidth: 70,
    // format: (value) => {
    //   return `${value}`.toLocaleString();
    // },
  },
  {
    id: 'is_active',
    label: 'Active',
    minWidth: 70,
    format: (value) => {
      return `${value}`.toLocaleString();
    },
  },
  {
    id: 'actions',
    label: '',
    minWidth: 170,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '30px 10px',
  },
  container: {
    maxHeight: '85vh',
    boxShadow: 'none',
  },
  pill: {
    textTransform: 'uppercase',
    margin: '4px',
    display: 'inline-block',
  },
  tableRow: {
    padding: '7px',
    minHeight: '50px',
  },
});

const PlansTable = ({ data, handleSelectPlan, onCancelPlan }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={nanoid()}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    textTransform: 'uppercase',
                    fontWeight: 700,
                    fontSize: '14px',
                    color: '#898CA6',
                  }}
                  className={classes.tableRow}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((plan, index) => {
                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={plan.subscription_id}
                    >
                      {columns.map((column) => {
                        const rValue =
                          column.id === 'index' ? index + 1 : plan[column.id];
                        const value =
                          // eslint-disable-next-line no-nested-ternary
                          column.id === 'actions' &&
                          !moment(plan.expiration).isBefore(moment()) ? (
                            <>
                              {/* <PrimaryMobileButton */}
                              {/*  onClick={() => */}
                              {/*    handleSelectPlan(plan.subscription_id) */}
                              {/*  } */}
                              {/*  variant="contained" */}
                              {/*  color="primary" */}
                              {/* > */}
                              {/*  Upgrade */}
                              {/* </PrimaryMobileButton> */}
                              <PrimaryMobileButton
                                onClick={() => onCancelPlan(plan)}
                                variant="outlined"
                                style={{ marginLeft: '10px' }}
                              >
                                Cancel
                              </PrimaryMobileButton>
                            </>
                          ) : (
                            rValue
                          );
                        return (
                          <TableCell
                            key={nanoid()}
                            align={column.align}
                            style={{ textTransform: 'capitalize' }}
                            className={classes.tableRow}
                          >
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PlansTable;

PlansTable.propTypes = {
  data: Proptypes.array.isRequired,
  handleSelectPlan: Proptypes.func.isRequired,
};
