import { settingsActions } from '@equally-ai-front/common/src/redux/settings-slice';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import Close from '@mui/icons-material/Close';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { PrimaryButton } from '../redesign/Controls';
import {
  FormContainer,
  FormContent,
  FormContentContainer,
  InfoContent,
  MainHeader,
  MainHeadersContainer,
} from './components/common';
import { InputInfo } from './scan-settings';
import { InputDiv, Label } from './styled-components';

interface SettingsChips {
  platform: string;
  template: string;
  projectTracking: string;
  pipeLine: string;
}

interface DomainInformationFormProps {
  devDomain: DevDomain;
  handleNavigateToIssuesTab: () => void;
  openInstallationModal: () => void;
  isOnboardingDashboard: boolean;
}

export const DomainInformationForm = ({
  devDomain,
  handleNavigateToIssuesTab,
  isOnboardingDashboard,
  openInstallationModal,
}: DomainInformationFormProps) => {
  const dispatch = useDispatch();

  const [domainInformation, setDomainInformation] = useState<DevDomain | null>(
    null,
  );
  const [isFormFieldsModifiedOrFilled, setIsFormFieldsModifiedOrFilled] =
    useState(false);

  const [tags, setTags] = useState({
    projectTracking: '',
    pipeLine: '',
  });

  const [settingsChips, setSettingsChips] = useState<SettingsChips>({
    platform: '',
    template: '',
    projectTracking: '',
    pipeLine: '',
  });

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!domainInformation) {
      return;
    }

    const { name, value, type } = evt.target;
    let inputValue: boolean | string = value;

    if (type === 'radio') {
      inputValue = value === 'true';
    }

    setDomainInformation({
      ...domainInformation,
      [name]: inputValue,
    });
  };

  const handleKeyDownChange = (evt: any) => {
    const { key } = evt;
    const { name, value } = evt.target;

    if (key === 'Enter') {
      setSettingsChips({
        ...settingsChips,
        [name]: value,
      });
    }
  };

  const clearSettingsChip = (settingsName: string) => {
    setSettingsChips({
      ...settingsChips,
      [settingsName]: '',
    });

    if (domainInformation) {
      setDomainInformation({
        ...domainInformation,
        [settingsName]: '',
      });
    }
    setTags({
      ...tags,
      [settingsName]: '',
    });
  };

  const saveScanSetting = () => {
    if (!devDomain || !domainInformation) {
      return;
    }

    const dataToSend = {
      id: devDomain.id,
      domain: devDomain.url,
      platform: domainInformation.platform,
      hasElementor: domainInformation.has_elementor,
      hasVisualEditor: domainInformation.has_visual_editor,
      template: domainInformation.template,
      language: domainInformation.language,
    };

    dispatch(
      settingsActions.updateDevDomain({
        ...dataToSend,
        websiteId: devDomain.id,
      }),
    );
  };

  useEffect(() => {
    if (!domainInformation) {
      return;
    }

    if (!isEqual(devDomain, domainInformation)) {
      setIsFormFieldsModifiedOrFilled(true);
      return;
    }

    setIsFormFieldsModifiedOrFilled(false);
  }, [devDomain, domainInformation]);

  useEffect(() => {
    if (!devDomain) {
      return;
    }

    setDomainInformation(devDomain);
    setSettingsChips({
      ...settingsChips,
      platform: devDomain.platform,
      template: devDomain.template,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devDomain]);

  return (
    <Wrapper isOnboardingDashboard={isOnboardingDashboard}>
      <MainHeadersContainer>
        <MainHeaderContent isOnboardingDashboard={isOnboardingDashboard}>
          Enhance {devDomain.url} scans with customized suggestions
        </MainHeaderContent>
        <InfoContentText isOnboardingDashboard={isOnboardingDashboard}>
          Specify your frontend stack and template for more accurate results
        </InfoContentText>
      </MainHeadersContainer>
      <FormContainer>
        <FormContentContainer>
          <FormContent>
            <FormDiv>
              <InputDiv>
                <Label>
                  Set your frontend stack or CMS for better recommendations
                </Label>
                {settingsChips.platform !== '' ? (
                  <Chip
                    bgColor="#AEEAD3"
                    color="#1E5643"
                    onClick={() => clearSettingsChip('platform')}
                  >
                    {domainInformation?.platform || ''}
                    <CloseChip />
                  </Chip>
                ) : (
                  <input
                    name="platform"
                    value={domainInformation?.platform || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDownChange}
                  />
                )}
              </InputDiv>
            </FormDiv>
            <FormDiv>
              <InputDiv>
                <Label>What language is your website in?</Label>
                <input
                  name="language"
                  value={domainInformation?.language || ''}
                  onChange={handleChange}
                />
              </InputDiv>
            </FormDiv>
            {/* {domainInformation.platform.toLowerCase() === 'wordpress' && (
        <FormDiv isRadioForm>
          <InputDiv>
            <Label>Do you use Elementor on your WordPress?</Label>
            <RadioDiv>
              <input
                id="hasElementor"
                type="radio"
                name="hasElementor"
                value="true"
                onChange={handleChange}
                checked={domainInformation?.hasElementor}
              />
              <label htmlFor="hasElementor">Yes</label>
            </RadioDiv>
            <RadioDiv>
              <input
                id="hasElementor"
                type="radio"
                name="hasElementor"
                value="false"
                onChange={handleChange}
                checked={!domainInformation?.hasElementor}
              />
              <label htmlFor="hasElementor">No</label>
            </RadioDiv>
          </InputDiv>
        </FormDiv>
      )} */}
            <FormDiv isRadioForm>
              <InputDiv>
                <Label>Do you edit your website with a Visual Editor?</Label>
                <RadioDiv>
                  <input
                    id="hasVisualEditor"
                    type="radio"
                    name="hasVisualEditor"
                    value="true"
                    onChange={handleChange}
                    checked={domainInformation?.has_visual_editor}
                  />
                  <span>Yes</span>
                </RadioDiv>
                <RadioDiv>
                  <input
                    id="hasVisualEditor"
                    type="radio"
                    name="hasVisualEditor"
                    value="false"
                    onChange={handleChange}
                    checked={!domainInformation?.has_visual_editor}
                  />
                  <span>No</span>
                </RadioDiv>
              </InputDiv>
            </FormDiv>
            <FormDiv lastFormDiv>
              <InputDiv>
                <Label htmlFor="template">
                  What theme or template does your website use?
                </Label>
                {settingsChips.template !== '' ? (
                  <Chip
                    bgColor="#FF9999"
                    color="#661F20"
                    onClick={() => clearSettingsChip('template')}
                  >
                    {domainInformation?.template || ''}
                    <CloseChip />
                  </Chip>
                ) : (
                  <input
                    id="template"
                    type="text"
                    onChange={handleChange}
                    name="template"
                    value={domainInformation?.template || ''}
                    onKeyDown={handleKeyDownChange}
                  />
                )}
              </InputDiv>
              <InputInfo>e.g. Astra, Divi etc.</InputInfo>
            </FormDiv>
            {/* <FormDiv>
        <InputDiv>
          <Label htmlFor="projectTracking">
            Project & Issue Tracking Software
          </Label>
          {settingsChips.projectTracking !== '' ? (
            <Chip
              bgColor="#F2DF87"
              color="#6F321B"
              onClick={() => clearSettingsChip('projectTracking')}
            >
              {tags.projectTracking}
              <CloseChip />
            </Chip>
          ) : (
            <input
              id="projectTracking"
              type="text"
              name="projectTracking"
              value={tags.projectTracking}
              onChange={handleTagChange}
              onKeyDown={handleKeyDownChange}
            />
          )}
        </InputDiv>
        <InputInfo>e.g. Jira, Asana, Trello etc.</InputInfo>
      </FormDiv>
      <FormDiv lastFormDiv>
        <InputDiv>
          <Label htmlFor="pipeLine">Pipeline</Label>
          {settingsChips.pipeLine !== '' ? (
            <Chip
              bgColor="#99D3FF"
              color="#194A55"
              onClick={() => clearSettingsChip('pipeLine')}
            >
              {tags.pipeLine}
              <CloseChip />
            </Chip>
          ) : (
            <input
              id="pipeLine"
              type="text"
              name="pipeLine"
              value={tags.pipeLine}
              onChange={handleTagChange}
              onKeyDown={handleKeyDownChange}
            />
          )}
        </InputDiv>
        <InputInfo>e.g. Git, Bitbucket etc.</InputInfo>
      </FormDiv> */}
          </FormContent>
        </FormContentContainer>
      </FormContainer>

      {isOnboardingDashboard ? (
        <OnboardingDashboardBtnWrapper>
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              isOnboardingDashboard
                ? openInstallationModal()
                : handleNavigateToIssuesTab()
            }
          >
            Skip for now
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveScanSetting}
            disabled={!isFormFieldsModifiedOrFilled}
          >
            Submit
          </Button>
        </OnboardingDashboardBtnWrapper>
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={saveScanSetting}
          disabled={!isFormFieldsModifiedOrFilled}
        >
          Save
        </Button>
      )}
    </Wrapper>
  );
};

interface DomainInformationStyleProps {
  isOnboardingDashboard?: boolean;
}

const Wrapper = styled.div<DomainInformationStyleProps>`
  .rah-input-wrapper {
    width: 100%;
    padding: 10px;

    input {
      padding: 0;
    }
  }

  ${(p) =>
    p.isOnboardingDashboard
      ? `
        border: 2px solid #e4e7eb;
        border-radius: 8px;
        padding: 15px 30px;
        width: 100%;
        max-width: 600px;
        min-height: 500px;
      `
      : ''}
`;

const OnboardingDashboardBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(PrimaryButton)`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  opacity: ${(props) => (props.disabled ? '0.4' : '')};
  padding: 12px 24px;
  margin-top: 30px;

  &:first-of-type {
    margin-right: 20px;
  }
`;

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  input {
    display: inline-block;
    margin-right: 5px;
  }
`;

interface FormDivProps {
  lastFormDiv?: boolean;
  isRadioForm?: boolean;
}

const FormDiv = styled.div<FormDivProps>`
  max-width: 750px;
  margin-bottom: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.lastFormDiv ? '0' : '2vh'};

  > div {
    padding: ${(props) => (props.isRadioForm ? '5px 0 0 0' : '')};
    // height: ${(props) => (props.isRadioForm ? '45px' : '100%')};
    height: 45px;
  }
`;

interface ChipProps {
  bgColor: string;
  color: string;
}

const Chip = styled.div<ChipProps>`
  margin: 15px 0 10px 5px;
  height: 30px;
  padding: 10px;
  padding-right: 30px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 200px;
  text-align: center;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  position: relative;
`;

const CloseChip = styled(Close)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  font-size: 15px !important;
  top: 2px;
`;

const MainHeaderContent = styled(MainHeader)<DomainInformationStyleProps>`
  ${(p) =>
    p.isOnboardingDashboard
      ? `
      font-size: 20px;
      margin: 0 auto 5px;
    `
      : ''}
`;

const InfoContentText = styled(InfoContent)<DomainInformationStyleProps>`
  ${(p) =>
    p.isOnboardingDashboard
      ? `
      font-size: 15px;
      font-weight: 400;
    `
      : ''}
`;
