import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  current,
} from '@reduxjs/toolkit';
import { ApiResponse } from '../../utils/axios';
import { OverviewAPI } from '../../api/overview-api';
import { TotalScansData } from '../../types/scans';
// import { useInjectReducer, useInjectSaga } from "redux-injectors";
// import historySaga from "./saga";
import { ScanHistoryState } from './types';
import { Issue, Scan } from '../../types/issues';
import { getUrlWithoutHttpPrefixAndWWW } from '../../utils/helpers';
import { ReportData } from '../../utils/devToolsTypeConfig';

export const initialState: ScanHistoryState = {
  history: [],
  issues: [],
  loading: false,
  error: '',
  message: '',
  totalIssuesSummary: {
    errors: 0,
    warnings: 0,
    notices: 0,
  },
  scanGroups: {},
  visibleWcagReport: null,
};

export const getScanHistory = createAsyncThunk(
  'get/scan-history',
  async (payload: {
    businessId: number;
    websiteIds: number[];
  }): Promise<ApiResponse<TotalScansData>> => {
    let result;
    let apiError;
    const { businessId, websiteIds } = payload;

    try {
      result = await OverviewAPI.getTotalScans(businessId, websiteIds);
    } catch (err) {
      console.error(err);
      apiError = "Couldn't get total scans";
    }

    const { data, error, isSuccess } = result || {};
    return {
      data,
      error: error || apiError,
      isSuccess: isSuccess || false,
    };
  },
);

const slice = createSlice({
  name: 'scanHistory',
  initialState,
  reducers: {
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },

    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setIssues(state, action: PayloadAction<Issue[]>) {
      state.issues = [...action.payload];
    },
    setScanGroups(state, action: PayloadAction<Record<string, any>>) {
      state.scanGroups = action.payload;
    },
    setScanAdded(state, action: PayloadAction<Scan>) {
      const newScanHistory = current(state).history.filter(
        (s: Scan) =>
          s.url !== getUrlWithoutHttpPrefixAndWWW(action.payload.url) ||
          s.type !== action.payload.type,
      );
      state.history = [action.payload, ...newScanHistory];
    },
    setScanHistory(state, action: PayloadAction<Scan[]>) {
      state.history = action.payload;
    },
    setVisibleWcagReport(state, action: PayloadAction<ReportData | null>) {
      state.visibleWcagReport = action.payload;
    },
  },
  extraReducers: {
    [getScanHistory.pending.type]: (state) => {
      state.loading = true;
    },
    [getScanHistory.fulfilled.type]: (state, action) => {
      if (action.payload?.data) {
        const {
          scans,
          scan_group_id_to_scan_group,
          total_errors,
          total_warnings,
          total_notices,
        } = action.payload?.data;
        state.history = scans || [];
        state.scanGroups = scan_group_id_to_scan_group || {};
        state.totalIssuesSummary = {
          errors: total_errors,
          warnings: total_warnings,
          notices: total_notices,
        };
      }
      state.loading = false;
      state.error = '';
    },
    [getScanHistory.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.message = "Couldn't process your request.";
    },
  },
});

export const { actions: scanHistory } = slice;
// export const useScanHistorySlice = () => {
//   useInjectReducer({ key: slice.name, reducer: slice.reducer });
//   useInjectSaga({ key: slice.name, saga: historySaga });
//   return { actions: slice.actions };
// };
export const history = slice.reducer;
