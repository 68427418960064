import PropTypes from 'prop-types';
import React from 'react';
import { PrimaryButton } from '../../redesign/Controls';

const EmptyDomainState = ({ action, withPlan, actionButton }) => {
  return (
    <div className="websites-list-empty" style={{ marginTop: '20px' }}>
      <div className="websites-list-empty-text">
        {withPlan
          ? 'Add a website to start'
          : 'Start a 7-day trial. No credit card required'}
      </div>
      <PrimaryButton
        color="primary"
        variant="contained"
        onClick={action}
        aria-haspopup="dialog"
      >
        <span aria-hidden="true" style={{ marginRight: '5px' }}>
          +
        </span>
        {'Add new website'}
      </PrimaryButton>
    </div>
  );
};

EmptyDomainState.propTypes = {
  action: PropTypes.oneOfType([PropTypes.func, null]),
  withPlan: PropTypes.bool,
  actionButton: PropTypes.element,
};

EmptyDomainState.defaultProps = {
  action: null,
  withPlan: true,
  actionButton: undefined,
};

export default EmptyDomainState;
