import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';

import { AnalyticsProvider } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import {
  INTERCOM_WEB_IDENTIFICATION_KEY,
  SEGMENT_WRITE_KEY,
} from './common/magicValues';
import Main from './components/Main';
import { AuthProvider } from './providers/auth-context';
import NotificationProvider from './providers/NotificationProvider';
import PaypalProvider from './providers/PaypalProvider';
import ThemeProvider from './providers/ThemeProvider';
import CartContextProvider from './providers/cart-provider';
import { ScrollProvider } from '@equally-ai-front/common/src/contexts/scroll-provider';
import { WebsitesTableProvider } from './providers/websites-table-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5,
      retryDelay: 1000,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    },
  },
});
const App = ({ store }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider>
          <AnalyticsProvider
            intercomWebIdentificationKey={INTERCOM_WEB_IDENTIFICATION_KEY}
            segmentWriteKey={SEGMENT_WRITE_KEY}
          >
            <NotificationProvider>
              <PaypalProvider>
                <Router>
                  <AuthProvider>
                    <CartContextProvider>
                      <WebsitesTableProvider>
                        <ScrollProvider>
                          <Main />
                        </ScrollProvider>
                      </WebsitesTableProvider>
                    </CartContextProvider>
                  </AuthProvider>
                </Router>
              </PaypalProvider>
            </NotificationProvider>
          </AnalyticsProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
