import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import { Replay } from '@sentry/replay';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

import createReduxStore from './store';

import { interceptRequestsAndResponses } from './api/axios';
import { ENV, SENTRY_API, SENTRY_SAMPLE_RATE } from './common/magicValues';

const store = createReduxStore();
export type AppDispatch = typeof store.dispatch;

interceptRequestsAndResponses();

Sentry.setTag('Repository', 'user-panel-fe');

Sentry.init({
  dsn: SENTRY_API,
  environment: ENV,
  integrations: [new BrowserTracing(), new Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Number(SENTRY_SAMPLE_RATE),
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
// @ts-ignore
root.render(<App store={store} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
