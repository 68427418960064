export class formValidator {
  static validate(stepValidationErrorsMap: any, entity: any) {
    const stepErrors: Record<string, any> = {};
    Object.keys(stepValidationErrorsMap).forEach((fieldKey) => {
      const { isMandatory, regexRule, validate, displayName } =
        stepValidationErrorsMap[fieldKey];
      if (
        isMandatory &&
        (entity[fieldKey] === undefined || entity?.[fieldKey] === '')
      ) {
        stepErrors[fieldKey] = { error: '*Required' };
      }
      if (regexRule && regexRule.test(entity[fieldKey])) {
        stepErrors[fieldKey] = {
          error: `*Please make sure ${displayName} is correct.`,
        };
      }
      if (validate && !validate(entity[fieldKey])) {
        stepErrors[fieldKey] = {
          error: `*${displayName} is not a valid input.`,
        };
      }
    });
    return stepErrors;
  }
}
