import styled from '@emotion/styled';
import React from 'react';

interface DocumentLinkProps {
  link: string;
  onClick?: () => void;
}

export const DocumentLink = (props: DocumentLinkProps) => {
  const { link, onClick } = props;

  return (
    <Wrapper
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <img src="/download.svg" />
    </Wrapper>
  );
};

const Wrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  border: 1.5px solid #e8edf3;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 42px;
`;
