import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import {
  ThemeProvider as ThemeProviderMui,
  StyledEngineProvider,
} from '@mui/material/styles';

import theme from '../common/materialTheme';

const globalStyles = {
  body: {
    fontSize: '0.875rem',
    lineHeight: '1.43',
  },
};

const ThemeProvider = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProviderMui theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeProviderMui>
  </StyledEngineProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
