import React from 'react';
import styled from 'styled-components';
import { FLOWY_EARLY_ACCESS, ORDER_AUDIT } from '../../../common/magicValues';
import { PrimaryButton } from '../../../components/redesign/Controls';

export const EmptyManualScans = () => {
  return (
    <Wrapper>
      <Title>Looking for a manual audit?</Title>
      <ButtonWrapper>
        <Button color="primary" variant="contained" tabIndex={-1}>
          <ButtonLink
            href={FLOWY_EARLY_ACCESS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Add Flowy to Chrome
          </ButtonLink>
        </Button>
      </ButtonWrapper>
      <TextSeparator>or</TextSeparator>
      <ButtonWrapper>
        <Button color="primary" variant="contained" tabIndex={-1}>
          <ButtonLink
            href={ORDER_AUDIT}
            target="_blank"
            rel="noopener noreferrer"
          >
            Order an audit
          </ButtonLink>
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  margin: 0 auto 20px;
  padding: 20px;
  border-radius: 14px;
  border: 1px solid #e4e7eb;

  @media screen and (max-width: 800px) {
    max-width: 80%;
  }
`;

const Title = styled.h3`
  font-size: 17px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 25px auto;
`;

const Button = styled(PrimaryButton)`
  height: 35px;
  width: 100%;
  white-space: nowrap;
`;

const ButtonLink = styled.a`
  color: #ffffff;
  font-size: 15px;
`;

const TextSeparator = styled.p`
  display: flex;
  flex-direction: row;

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1.5px solid #bbc1c4;
    margin: auto;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
`;
