import React from 'react';
import styled from 'styled-components';
import { setApiError } from '../../../store/actions';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { AccessiblePopUp } from '@equally-ai-front/common/src/components/accessible-components/popup/AccessiblePopUp';
import { useDispatch } from 'react-redux';
import {
  SettingsExport,
  ExportTypesOption,
} from '@equally-ai/audit-ui/src/components/exports/export';

const EXPORT_TYPES_OPTIONS: ExportTypesOption[] = [
  {
    label: 'CSV',
    value: 'CSV',
  },
  {
    label: 'PDF',
    value: 'PDF',
  },
];

interface DashboardExportPopUpProps {
  openExportPopUp: boolean;
  handleCloseExportPopUp: (evt: any) => void;
  currentDevDomainId: number;
  currentBusinessId: number;
  pdfReport: DevtoolsSolution[];
  hostCurrentFullUrl: string;
  devDomainName: string;
  scanId?: number;
}

export const DashboardExportPopUp = (props: DashboardExportPopUpProps) => {
  const {
    openExportPopUp,
    handleCloseExportPopUp,
    currentDevDomainId,
    currentBusinessId,
    pdfReport,
    hostCurrentFullUrl,
    devDomainName,
    scanId,
  } = props;
  const dispatch = useDispatch();

  return (
    <AccessiblePopUp
      isOpen={openExportPopUp}
      handleToggle={handleCloseExportPopUp}
    >
      <ExportWrapper>
        <SettingsExport
          currentBusinessId={currentBusinessId}
          currentDevDomainId={currentDevDomainId}
          hostCurrentFullUrl={hostCurrentFullUrl}
          pdfReport={pdfReport}
          exportTypesOptions={EXPORT_TYPES_OPTIONS}
          errorNotification={(err) => dispatch(setApiError(err))}
          isExtension={false}
          scanId={scanId}
          devDomainName={devDomainName}
        />
      </ExportWrapper>
    </AccessiblePopUp>
  );
};

const ExportWrapper = styled.div`
  padding: 20px 40px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 468px) {
    padding: 20px 10px;
  }
`;
