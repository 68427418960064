import { Box, Typography } from '@mui/material';
import * as React from 'react';

export const UnderMaintenanceContainer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: '#1A1F44', // A deep space-like background
        color: '#fff',
        p: 2,
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: '1 1 auto',
          mb: { xs: 4, md: 0 },
        }}
      >
        <img
          src="Ilustration.png"
          alt="Astronaut floating in space"
          style={{
            maxWidth: '600px',
            width: '100%',
            height: 'auto',
          }}
        />
      </Box>

      {/* Text Section */}
      <Box
        sx={{
          flex: '1 1 auto',
          maxWidth: '600px',
          p: 2,
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{ mb: 2, fontWeight: 'bold' }}
        >
          Under Maintenance
        </Typography>

        <Typography
          variant="h4"
          component="h2"
          sx={{ fontSize: '1.3rem', lineHeight: 1.5 }}
        >
          <strong>
            Our accessibility widget will be available on your site at all time,
            to ensure you remain accessible to everyone.
          </strong>
        </Typography>

        <Typography
          variant="h4"
          component="h2"
          sx={{ fontSize: '1.2rem', lineHeight: 1.5 }}
        >
          We are working hard to make our product even better for you.
        </Typography>
        <Typography>
          <br />
          It will be back up and running very soon.
          <br />
          <em>Thank you for your patience and support!</em>
        </Typography>
      </Box>
    </Box>
  );
};
