import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

export const AuthLoader = () => {
  return (
    <Container>
      <CircularProgress size={80} />
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 333;
  background: rgba(0, 0, 0, 0.053);
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: relative;
    z-index: 1;
    background: #fff;
    height: 40px;
    padding-top: 10px;

    & > span {
      height: 40px !important;
      width: 40px !important;
    }
  }
`;
