import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Typography } from '@mui/material';
import { CSVLink } from 'react-csv';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { SelectChangeEvent } from '@mui/material/Select';
import { ColumnReportForm } from './components/column-report-form';
import { CustomReportForm } from './components/custom-report-form';
import { ReportActions } from './components/report-actions';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import {
  IssueSeverityEnum,
  IssueSeverityTitle,
} from '@equally-ai-front/common/src/types/issues';
import { isShadeOfWhiteOrGrey } from '@equally-ai-front/common/src/utils/helpers';
import { ExportSettings } from '@equally-ai-front/common/src/api/export-settings';
import { buildDevtoolsReport } from '@equally-ai-front/common/src/helpers/templates';
import { filterReportByRecommendation } from '@equally-ai-front/common/src/lib';
import { ComplianceService } from '@equally-ai-front/common/src/api';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { usePdfReport } from '../../hooks';

export type EXPORT_TYPES = 'CSV' | 'PDF' | '';

export interface ExportTypesOption {
  label: EXPORT_TYPES;
  value: EXPORT_TYPES;
}

export interface FieldProps {
  label: string;
  key: string;
  options?: FieldProps[];
  [key: string]: any;
}

export interface CSVDataProps {
  headers: FieldProps[];
  data: Record<string, any>[];
}

export interface ExportFormProps {
  columns: string[];
  statuses: string[];
  sources: string[];
  format: string;
  [key: string]: any;
}

interface SettingsExportProps {
  currentDevDomainId?: number;
  currentBusinessId: number;
  hostCurrentFullUrl: string;
  devDomainName?: string;
  pdfReport: DevtoolsSolution[];
  exportTypesOptions: ExportTypesOption[];
  errorNotification: (error: string) => void;
  isExtension: boolean;
  scanId?: number;
}

const headers: FieldProps[] = [
  {
    label: 'Severity',
    key: 'type',
  },
  {
    label: 'Type',
    key: 'source',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Description',
    key: 'message',
  },
  {
    label: 'WCAG',
    key: 'code',
  },
  {
    label: 'Recommendation',
    key: 'recommendation',
  },
];
export const autocompleteFields: FieldProps[] = [
  {
    label: 'Select the audit types you would like to export.',
    key: 'sources',
    options: [
      {
        label: 'Automated',
        key: 'Automated',
      },
      {
        label: 'Manual',
        key: 'Manual',
      },
    ],
  },
  {
    label: 'Select the statuses that will be included.',
    key: 'statuses',
    options: [
      {
        label: 'Dismissed',
        key: 'Dismissed',
      },
      {
        label: 'Pending',
        key: 'Pending',
      },
      {
        label: 'Resolved',
        key: 'Resolved',
      },
    ],
  },
  {
    label: 'Which columns should we export for you?',
    key: 'columns',
    options: headers,
  },
];

export type REPORT_TYPES = 'default' | 'custom' | '';
interface ReportType {
  id: number;
  title: string;
  type: REPORT_TYPES;
  content: React.ReactNode;
}

export interface CustomReportFields {
  fullName: string;
  logo: string;
  themeColor: string;
}

export const SettingsExport = (props: SettingsExportProps) => {
  const {
    currentDevDomainId,
    currentBusinessId,
    hostCurrentFullUrl,
    devDomainName,
    pdfReport,
    exportTypesOptions,
    errorNotification,
    isExtension,
    scanId,
  } = props;
  const downloadLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [exportType, setExportType] = useState<EXPORT_TYPES>('');
  const [csvData, setCsvData] = useState<CSVDataProps>({
    headers: headers,
    data: [],
  });
  const [reportType, setReportType] = useState<REPORT_TYPES>('');
  const [customReportFields, setCustomReportFields] = useState({
    fullName: '',
    logo: '',
    themeColor: '#222a66',
  });

  const handleSelectReportType = (currentReportType: REPORT_TYPES) => {
    setReportType(reportType !== currentReportType ? currentReportType : '');
  };

  const { loadingPdf, handleDownloadReportPdf } = usePdfReport();

  const formikInitialFields = autocompleteFields.reduce(
    (acc: any, field: FieldProps) => {
      acc[field.key] = field.options?.map((option: FieldProps) => option.key);
      return acc;
    },
    {},
  );

  const formik = useFormik<ExportFormProps>({
    initialValues: {
      columns: formikInitialFields['columns'],
      statuses: formikInitialFields['statuses'],
      sources: formikInitialFields['sources'],
      format: 'CSV',
    },
    onSubmit: (values) => {
      console.log('values = ', values);
    },
  });

  const handleExportTypeChange = (event: SelectChangeEvent) => {
    setExportType(event.target.value as EXPORT_TYPES);
  };

  const handlePdfDownload = useCallback(async () => {
    if (!currentDevDomainId && pdfReport.length === 0) {
      return;
    }
    const { statuses, sources, columns } = formik.values;
    const statusFields =
      statuses.length === 0 ? formik.initialValues.statuses : statuses;
    const sourceFields =
      sources.length === 0 ? formik.initialValues.sources : sources;

    if (pdfReport.length > 0) {
      const customFields = reportType === 'custom' ? customReportFields : null;
      const { message, isSuccess } = await handleDownloadReportPdf(
        hostCurrentFullUrl,
        pdfReport,
        statusFields,
        sourceFields,
        columns,
        customFields,
        null,
        false,
        devDomainName,
      );
      !isSuccess && errorNotification(message);
      return;
    }
    setLoading(true);
    const { isSuccess, data: issues } = await ComplianceService.export(
      Number(currentDevDomainId),
      currentBusinessId,
      undefined,
      scanId,
    );

    if (!isSuccess || !issues) {
      errorNotification('Error getting pdf data');
      setLoading(false);
      return;
    }

    if (issues.length === 0) {
      errorNotification('No issues to export for this page!');
      setLoading(false);
      return;
    }

    const filteredIssues = filterReportByRecommendation(issues, 'html');
    const issueReport = buildDevtoolsReport(hostCurrentFullUrl, filteredIssues);
    const customFields = reportType === 'custom' ? customReportFields : null;
    const { message, isSuccess: isPdfDownloadSuccess } =
      await handleDownloadReportPdf(
        hostCurrentFullUrl,
        issueReport.list.all,
        statusFields,
        sourceFields,
        columns,
        customFields,
        null,
        false,
        devDomainName,
      );
    setLoading(false);
    !isPdfDownloadSuccess && errorNotification(message);
  }, [currentDevDomainId, pdfReport, reportType, customReportFields, formik]);

  const handleDownloadCustomCsv = async (
    headers: FieldProps[],
    data: Record<string, any>[],
    customReportFields: CustomReportFields,
  ) => {
    const { fullName, logo, themeColor } = customReportFields;
    const isColorShadeOfWhiteOrGrey = isShadeOfWhiteOrGrey(themeColor);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Custom CSV Report 1');
    const columns = [];

    if (fullName) {
      columns.push({
        header: '',
        key: 'logo',
        width: 20,
      });
    }

    if (logo) {
      columns.push({ header: '', key: 'fullName', width: 20 });
    }

    worksheet.columns = [
      ...columns,
      ...headers.map(({ label, ...rest }) => ({
        ...rest,
        header: label,
      })),
    ];

    if (fullName) {
      const headerRow = worksheet.getRow(1);
      headerRow.getCell(!logo ? 1 : 2).value = fullName;
    }

    if (logo) {
      const {
        isSuccess,
        data: { imageData, imageFormat },
      } = await ExportSettings.getImageDataFromUrl(logo);

      if (!isSuccess) return;
      const logoId = workbook.addImage({
        buffer: imageData,
        extension: imageFormat,
      });
      worksheet.addImage(logoId, {
        tl: { col: 0, row: 0 },
        ext: { width: 100, height: 100 },
      });
    }

    data.forEach((item) => {
      worksheet.addRow({
        fullName: '',
        logo: '',
        ...item,
      });
    });

    const excelThemeColor = `FF${themeColor.substring(1)}`;
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.font = {
          color: { argb: excelThemeColor },
        };

        if (isColorShadeOfWhiteOrGrey) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF000000' },
          };
        }
        if (rowNumber === 1) {
          cell.font.bold = true;
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `EQUALLY_AI_${data[0]?.domain_url}-issues-export.xlsx`);
  };

  const exportDataOnPageAsCsv = useCallback(async () => {
    if (!currentDevDomainId && pdfReport.length === 0) {
      return;
    }

    const { statuses, sources, columns } = formik.values;
    let statusFields =
      statuses.length === 0 ? formik.initialValues.statuses : statuses;
    let sourceFields =
      sources.length === 0 ? formik.initialValues.sources : sources;
    let data: DevtoolsSolution[] = [];
    setLoading(true);
    if (pdfReport.length > 0) {
      data = pdfReport;
    } else if (currentDevDomainId) {
      const { data: issues, isSuccess } = await ComplianceService.export(
        currentDevDomainId,
        currentBusinessId,
        hostCurrentFullUrl,
        undefined,
        statusFields.join(',').toLowerCase(),
        sourceFields.join(','),
      );

      setLoading(false);
      if (!isSuccess) {
        errorNotification('Error exporting csv');
        return;
      }
      data = buildDevtoolsReport(hostCurrentFullUrl, issues).list.all;
    } else {
      errorNotification('Error exporting csv');
      setLoading(false);
      return;
    }

    if (statusFields.length > 0) {
      const statusList = statusFields.map((status) => status.toLowerCase());
      data = data.filter(({ status }) =>
        statusList.includes(status.toLowerCase()),
      );
    }

    if (sourceFields.length > 0) {
      data = data.filter(({ source }) => sourceFields.includes(source));
    }

    if (!data || data?.length === 0) {
      errorNotification('No issues to export for this page!');
      setLoading(false);
      return;
    }

    let filteredData: Record<string, any>[] = [];
    let allColumnfields =
      columns.length === 0 ? formik.initialValues.columns : columns;
    // const columnTypeMapping: Record<string, any[]> = {
    //   [exportType]: [],
    // };

    if (allColumnfields.length) {
      // We want to refine the raw data from the endpoint to suite our column selection.
      filteredData = data.map((result: DevtoolsSolution) => {
        const filteredItem: Record<string, any> = {};
        allColumnfields.forEach((column: string) => {
          let columnData: string = '';
          if (column === 'recommendation') {
            const recommendation = JSON.parse(result.recommendation || '{}');
            let data: string[] = [];
            let text: string = '';

            Object.entries(recommendation.attributes || {}).forEach(
              ([configType, attributes]) => {
                Object.entries(attributes as { [key: string]: string }).forEach(
                  ([attribute, value]) => {
                    data = [...data, `${attribute}: ${value}`];
                  },
                );
              },
            );

            text = recommendation.text
              ? `Additional information (${recommendation.text})`
              : '';

            if (data.length > 0) {
              columnData = `Attributes (` + data.join(', ') + `)\n`;
            }

            columnData += text;
          } else if (column === 'type') {
            columnData =
              IssueSeverityTitle[result[column] as IssueSeverityEnum];
          } else if (typeof (column as keyof DevtoolsSolution) === 'string') {
            columnData = (result[column as keyof DevtoolsSolution] ||
              '') as string;
          }
          filteredItem[column] = columnData;
        });

        // columnTypeMapping[exportType] = [...columnTypeMapping[exportType], filteredItem];
        return filteredItem;
      });

      csvData.headers = headers.filter((header: FieldProps) =>
        allColumnfields.includes(header.key),
      );
    }

    const customFields = reportType === 'custom' ? customReportFields : null;
    if (customFields) {
      await handleDownloadCustomCsv(
        csvData.headers,
        filteredData || [],
        customFields,
      );
    } else {
      setCsvData({
        headers: csvData.headers,
        data: filteredData || [],
      });
    }
    setLoading(false);
  }, [currentDevDomainId, currentBusinessId, formik]);

  useEffect(() => {
    if (!downloadLinkRef.current || csvData.data.length === 0) {
      return;
    }

    downloadLinkRef.current.link.click();
  }, [csvData, downloadLinkRef]);

  const REPORT_TYPE_LIST: ReportType[] = [
    {
      id: 1,
      title: `Select report's columns`,
      type: 'default',
      content: <ColumnReportForm formik={formik} />,
    },
    {
      id: 2,
      title: `Customize appearance`,
      type: 'custom',
      content: (
        <CustomReportForm
          customReportFields={customReportFields}
          setCustomReportFields={setCustomReportFields}
        />
      ),
    },
  ];

  return (
    <>
      <LoadingView
        open={loading || loadingPdf}
        loadingText={
          exportType === 'CSV'
            ? 'Woo!, Getting your CSV ready!'
            : 'Woo!, Getting your PDF ready!'
        }
      />
      <Wrapper isExtension={isExtension}>
        <Header>
          <Headline>Export settings</Headline>
        </Header>
        <Container>
          <form onSubmit={formik.handleSubmit}>
            {REPORT_TYPE_LIST.map(({ id, title, content, type }, index) => {
              const isExpanded = type === reportType;

              return (
                <ExpandableWrapper key={`${id}-${title}`}>
                  <ExpandableHeader
                    key={id}
                    onClick={() => handleSelectReportType(type)}
                    onKeyDown={(
                      evt: React.KeyboardEvent<HTMLButtonElement>,
                    ) => {
                      if (evt.key === 'Enter' || evt.key === 'Space') {
                        evt.preventDefault();
                        handleSelectReportType(type);
                      }
                    }}
                    type="button"
                    aria-expanded={isExpanded}
                  >
                    <ExpandableHeaderLabel>
                      <ArrowBox>
                        <ArrowIcon
                          src="/chevron-down-black.svg"
                          alt="arrow down icon"
                          shouldRotate={isExpanded}
                        />
                      </ArrowBox>
                      <ExpandableTitle>{title}</ExpandableTitle>
                    </ExpandableHeaderLabel>
                  </ExpandableHeader>
                  {reportType === type && <Container>{content}</Container>}
                  {index < REPORT_TYPE_LIST.length - 1 && <Separator />}
                </ExpandableWrapper>
              );
            })}
            <ReportActions
              exportType={exportType}
              exportTypesOptions={exportTypesOptions}
              handleExportTypeChange={handleExportTypeChange}
              loading={loading}
              loadingPdf={loadingPdf}
              downloadLinkRef={downloadLinkRef}
              exportDataOnPageAsCsv={exportDataOnPageAsCsv}
              handlePdfDownload={handlePdfDownload}
              csvData={csvData}
              isReportTypeSelected={reportType !== ''}
            />
          </form>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div<{ isExtension: boolean }>`
  @media screen and (min-width: 1300px) {
    max-width: 100%;
  }

  border: ${(p) => (p.isExtension ? '2px solid #e4e7eb' : '')};
  max-height: ${(p) => (p.isExtension ? '66vh' : '')};
  overflow-y: ${(p) => (p.isExtension ? 'scroll' : '')};
  border-radius: 12px;
  width: auto;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
`;

const Header = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Headline = styled(Typography)`
  font-weight: bolder;
  font-size: 20px;
  width: fit-content;
`;

const Container = styled.div`
  width: 100%;
  min-width: 40vw;
  margin-top: 20px;

  @media screen and (max-width: 450px) {
    max-width: 100%;
  }
`;

const ExpandableWrapper = styled.div``;

const ExpandableHeader = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  width: 100%;
`;

const ExpandableHeaderLabel = styled.div`
  display: flex;
  align-items: center;
`;

const ExpandableTitle = styled.p`
  font-size: 14px;
  margin-left: 10px;
`;

const Separator = styled.hr`
  width: 100%;
  height: 2px;
  background-color: #000a14;
  margin: 25px 0;
  opacity: 0.7;
`;

const ArrowBox = styled.div`
  border: 2px solid #e4e7eb;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 410px) {
    width: 37px;
  }
`;

const ArrowIcon = styled.img<{
  shouldRotate: boolean;
}>`
  transform: ${(props) => (props.shouldRotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;
