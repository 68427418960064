import { AccessibilityMenu } from '@equally-ai-front/common/src/components/accessible-components/menu/accessibility-menu';
import { DynamicMenuItemProps } from '@equally-ai-front/common/src/components/expandables/menus/types';
import { useAnchorElement } from '@equally-ai-front/common/src/hooks/use-anchor-element';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import style from 'styled-components';
import { MOBILE_PX } from '../utils/constants';
import { useMediaQuery } from '@mui/material';

export interface ComplianceManagerMenuProps {
  isOnMobile?: boolean;
  handleCloseMenu?: () => void;
}

export const ComplianceManagerMenu = ({
  handleCloseMenu,
}: ComplianceManagerMenuProps) => {
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const { anchorEl, handleClick, handleClose } = useAnchorElement();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const wrapperRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleCloseAccessibilityMenu = () => {
    handleClose();
    handleCloseMenu && handleCloseMenu();
  };

  const menuItems: DynamicMenuItemProps[] = [
    {
      label: 'Overview',
      name: 'Overview',
      linkTo: `/${currentBusiness?.slug}/compliance-manager/overview`,
      handleClicked: () => {
        navigate(`/${currentBusiness?.slug}/compliance-manager/overview`);
        handleCloseAccessibilityMenu();
      },
    },
    {
      label: 'Divider',
      name: 'divider',
      isDivider: true,
      handleClicked: () => {},
    },
    {
      label: 'Issues',
      name: 'Issues',
      linkTo: `/${currentBusiness?.slug}/compliance-manager/issues`,
      handleClicked: () => {
        navigate(`/${currentBusiness?.slug}/compliance-manager/issues`);
        handleCloseAccessibilityMenu();
      },
    },
  ];

  return (
    <Wrapper ref={wrapperRef}>
      <CustomBtn
        id="custom-menu-list"
        className="custom-menu-btn"
        aria-controls={open ? 'customized-menu-btn' : undefined}
        aria-haspopup="true"
        aria-expanded={open}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Compliance Manager
      </CustomBtn>
      <AccessibilityMenu
        anchorEl={anchorEl}
        handleClose={handleCloseAccessibilityMenu}
        menuItems={menuItems}
        container={wrapperRef.current}
        menuTopPosition={isMobile ? '130px' : undefined}
      />
    </Wrapper>
  );
};

const Wrapper = style.div`
  .MuiDivider-root {
    visibility: hidden;
    margin: 0;
  }
`;

type CustomBtnProps = { isonmobile?: string };
const CustomBtn = styled(Button)<CustomBtnProps>`
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  background-color: ${(p) => (p.isonmobile === 'yes' ? '#F5F7FA' : '#1f2730')};
  color: ${(p) => (p.isonmobile === 'yes' ? '#000a14' : '#ffffff')};
  border: ${(p) => (p.isonmobile === 'yes' ? ' 1px solid #CBD2D9' : 'none')};

  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }

  @media screen and (max-width: ${MOBILE_PX}px) {
    width: fit-content;
  }
`;
