import styled from '@emotion/styled';

/** Typography */

export const TypographyBig = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 110%;
`;

export const TypographySmall = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
`;

/** Pill */

export const SmallPill = styled(TypographySmall)`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 8px;
`;

export const GreyPillSmall = styled(SmallPill)`
  background-color: ${({ theme }) => theme.palette.shades.shade100};
  color: ${({ theme }) => theme.palette.on.darkLow};
`;

export const ErrorPillSmall = styled(SmallPill)`
  background-color: ${({ theme }) => theme.palette.status.error};
  color: ${({ theme }) => theme.palette.on.errorHigh};
`;

export const SuccessPillSmall = styled(SmallPill)`
  background-color: ${({ theme }) => theme.palette.status.success};
  color: ${({ theme }) => theme.palette.on.successHigh};
`;

export const WarningPillSmall = styled(SmallPill)`
  background-color: ${({ theme }) => theme.palette.status.warning};
  color: ${({ theme }) => theme.palette.on.warningHigh};
`;

export const BigPill = styled(TypographyBig)`
  box-sizing: border-box;
  border-radius: 24px;
  padding: 17px 32px;
`;

export const GreyPillBig = styled(BigPill)`
  background-color: ${({ theme }) => theme.palette.shades.shade100};
  color: ${({ theme }) => theme.palette.on.darkLow};
`;

export const ErrorPillBig = styled(BigPill)`
  background-color: ${({ theme }) => theme.palette.status.error};
  color: ${({ theme }) => theme.palette.on.errorHigh};
`;

export const SuccessPillBig = styled(BigPill)`
  background-color: ${({ theme }) => theme.palette.status.success};
  color: ${({ theme }) => theme.palette.on.successHigh};
`;

export const WarningPillBig = styled(BigPill)`
  background-color: ${({ theme }) => theme.palette.status.warning};
  color: ${({ theme }) => theme.palette.on.warningHigh};
`;
