import { deleteTeamMember } from '@equally-ai-front/common/src/redux/business-slice/business';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingView from '../../common/LoadingView';
import PlansTable from '../../common/Tables/PlansTable';

const transformTextIntoCapitalizeForm = (inputText) =>
  inputText.charAt(0).toUpperCase() + inputText.slice(1).toLowerCase();

export const getColumns = (type) => [
  {
    id: 'name',
    label: 'Name',
    minWidth: 120,
  },
  {
    id: 'email',
    label: 'email',
    minWidth: 120,
  },
  {
    id: 'status',
    label: 'status',
    minWidth: 120,
    format: (value) => (
      <strong style={{ fontWeight: 500 }}>
        {transformTextIntoCapitalizeForm(value)}
      </strong>
    ),
  },
  {
    id: 'role',
    label: 'role',
    minWidth: 100,
  },
  {
    id: 'actions',
    label: '',
    minWidth: type === 'invite' ? 155 : 120,
    align: 'center',
  },
];

const TeamMemberTable = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onDeleteTeamMember } = props;
  const dispatch = useDispatch();
  const team = useSelector((state) => state.business.teamMembers);
  const personalDetails = useSelector(
    (state) => state.personalDetails.personalDetails,
  );
  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );
  const handleDelete = async (_, member) => {
    await dispatch(
      deleteTeamMember({ id: member.id, businessId: currentBusiness.id }),
    );
    await onDeleteTeamMember();
  };

  const shouldHideActionButton = (data) => {
    if (
      currentBusiness.user_id === data.user_id ||
      personalDetails.id === data.user_id
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <LoadingView open={false} />
      <div className="team-table">
        {team.length > 0 && (
          <PlansTable
            currentIndex={1}
            data={team}
            handleSelectPlan={handleDelete}
            selectedDomains={[]}
            setSelectedDomains={() => {}}
            showActionsButtons
            actionButtonProp={{
              showQuickActions: false,
              hideActionButton: shouldHideActionButton,
              handleSelectPlan: handleDelete,
              actionLabel: 'Remove User',
            }}
            columns={getColumns('member')}
          />
        )}
      </div>
    </>
  );
};

export default TeamMemberTable;
