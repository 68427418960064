import styled from '@emotion/styled';
import PlansService from '@equally-ai-front/common/src/api/plans-service';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select as DropdownSelect,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { availablePlans } from '../../../lib/plans';
import {
  getActivePlans,
  setApiError,
  setApiSuccessMessage,
} from '../../../store/actions';
import { PrimaryButton } from '../../redesign/Controls';
import { ModalPanel } from '../../redesign/ModalPanel';
import Spinner from '../../Spinner/Spinner';
import { mapFunctionToType } from '../AddDomainModal/AddDomainModal';

const generateOptions = (data) =>
  data.map((domain) => ({
    value: domain.id,
    label: domain.name,
  }));
const generateProductTypeOptions = (data) =>
  data.map((productType) => ({
    value: productType,
    label: productType.toLocaleString(),
  }));

const AddMultipleDomainsToPlan = ({
  open,
  handleClose,
  plans,
  type,
  selectedWebsites,
  setSelectedWebsites,
  inActiveDomains,
}) => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    generateOptions(selectedWebsites),
  );
  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );
  const handleSelectPlan = (e) => setSelectedPlan(Number(e.target.value));

  const dispatch = useDispatch();

  const inActiveDomainsWithType = inActiveDomains.map((domain) => {
    if (domain.dev_domain_id) {
      return { ...domain, type: 'developer' };
    }

    if (domain.domain.allow_user_integration) {
      return { ...domain, type: 'flowy' };
    }

    return { ...domain, type: 'widget' };
  });

  let optionList = generateOptions(inActiveDomainsWithType);

  const handleSelect = (data) => {
    setSelectedOptions(data);

    const selected = data.map((item) =>
      inActiveDomains.find((dom) => dom.id === item.value),
    );
    setSelectedWebsites(selected);
  };
  const handleProductTypeSelect = (data) => {
    setSelectedProductType(data);
  };

  const onSubmit = async () => {
    const domainIDs = selectedWebsites.map((domain) => domain.id);
    setIsLoading(true);
    try {
      await PlansService.attachMultipleDomainsToPlan({
        planID: selectedPlan,
        type,
        domainIDs,
        businessID: currentBusiness.id,
      });
      setSelectedPlan('');
      dispatch(setApiSuccessMessage('Website(s) Added to Plan Successfully'));
      dispatch(mapFunctionToType[type]);
      dispatch(getActivePlans({ type, businessId: currentBusiness.id }));
      setIsLoading(false);
      handleSelect([]);
      handleClose();
    } catch (error) {
      setSelectedPlan('');
      setIsLoading(false);
      dispatch(setApiError('Something went wrong!'));
    }
  };

  const checkIfThereIsEnoughCredit = (id) => {
    if (!id) {
      return true;
    }

    const selectedPlanObj = plans.find((plan) => plan.id === id);

    return selectedPlanObj.quantity >= selectedOptions.length;
  };

  const checkIfThereIsActivePlan = useCallback(
    () => plans.length === 0,
    [plans],
  );

  if (selectedPlan) {
    const selectedPlanObj = plans.find((plan) => plan.id === selectedPlan);

    optionList = generateOptions(
      inActiveDomainsWithType.filter(
        (domain) => domain.type === selectedPlanObj.plan_type,
      ),
    );
  }

  const isDisabled =
    selectedOptions.length === 0 ||
    selectedPlan === '' ||
    isLoading ||
    !checkIfThereIsEnoughCredit(selectedPlan);

  return (
    <ModalPanel open={open} title="Select sites" handleClose={handleClose}>
      <Form>
        {/* <Select */}
        {/*  options={generateProductTypeOptions(['widget', 'developer', 'flowy'])} */}
        {/*  value={selectedProductType} */}
        {/*  placeholder="Select Product" */}
        {/*  onChange={handleProductTypeSelect} */}
        {/* /> */}
        <Control>
          <InputLabel id="choose-plan-label">Select Plan</InputLabel>
          <DropdownSelect
            labelId="choose-plan-label"
            id="choose-plan"
            value={selectedPlan}
            onChange={handleSelectPlan}
          >
            {availablePlans(plans).map((plan) => (
              <MenuItem
                value={plan.id}
                key={plan.id}
                style={{ textTransform: 'capitalize' }}
              >
                {plan.plan_type}, {plan.plan_template.name} - Available Credits:{' '}
                {plan.quantity}
              </MenuItem>
            ))}
          </DropdownSelect>
        </Control>
        <Select
          options={optionList}
          value={selectedOptions}
          isMulti
          placeholder="Select Websites"
          onChange={handleSelect}
          isDisabled={!selectedPlan}
        />
        <Box mt="20px" />

        {!checkIfThereIsEnoughCredit(selectedPlan) && (
          <ErrorMessage>
            There is not enough credit in selected plan
          </ErrorMessage>
        )}
        {!checkIfThereIsActivePlan() ? (
          <PrimaryButton
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={isDisabled}
          >
            Add To Plan
          </PrimaryButton>
        ) : (
          <>
            <ErrorMessage>You don&apos;t have an active license</ErrorMessage>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(`https://equally.ai/pricing`, '_blank')
              }
            >
              Buy a License
            </PrimaryButton>
          </>
        )}
        <Spinner show={isLoading} />
      </Form>
    </ModalPanel>
  );
};

AddMultipleDomainsToPlan.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  selectedWebsites: PropTypes.array.isRequired,
  setSelectedWebsites: PropTypes.func.isRequired,
  inActiveDomains: PropTypes.array.isRequired,
};

export default AddMultipleDomainsToPlan;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.on.warningHigh};
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
`;

const Control = styled(FormControl)`
  margin: 15px 0;
`;
