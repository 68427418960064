import styled from '@emotion/styled';
import { Button } from '@mui/material';
import React from 'react';
import { ReactComponent as GoogleIcon } from '../../../img/google.svg';
import { cognitoDir } from '@equally-ai/auth-service';
interface GoogleAuthButtonProps {
  fullWidth?: boolean;
  label: string;
}

export const GoogleAuthButton = ({
  fullWidth,
  label,
}: GoogleAuthButtonProps) => {
  const handleClick = async () => {
    window.location.href = cognitoDir.getGoogleUrl();
  };

  return (
    <LoginButton fullWidth={fullWidth} onClick={handleClick}>
      <GoogleIcon />
      <Label>{label}</Label>
    </LoginButton>
  );
};

const Label = styled.span`
  flex-grow: 1;
  text-align: center;
`;

const LoginButton = styled(Button)`
  white-space: nowrap;
  text-transform: initial;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  margin: 0 !important;
  box-shadow:
    0px 5px 10px rgba(0, 18, 38, 0.08),
    0px 3px 6px rgba(0, 18, 38, 0.04);
  border-radius: 12px;
  padding: 11px 21px;
  height: auto;
  color: #000a14;

  & > div > div:last-of-type {
    text-align: center !important;
    margin-right: ${({ fullWidth }) => (fullWidth ? '26px' : 'auto')};
  }

  &:hover {
    cursor: pointer;
  }

  /* Font */
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;

  @media screen and (max-width: 600px) {
    padding: 18px 32px;
    width: 100%;
  }
`;
