import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../RootState';
import { initialState } from './index';

const selector = (state: RootState) => state?.auth || initialState;

export const selectUser = createSelector([selector], (auth) => auth.user);

export const selectWebsiteDomain = createSelector(
  [selector],
  (auth) => auth.websiteDomain,
);

export const selectPersonalDetails = createSelector(
  [selector],
  (auth) => auth.personalDetails,
);

export const selectLoading = createSelector([selector], (auth) => auth.loading);

export const selectError = createSelector([selector], (auth) => auth.error);
