import React, { useState } from 'react';
import styled from 'styled-components';
import DefaultScanImage from '../../assets/default-scan-img.jpg';

interface ScanImageProps {
  scanImageUrl: string;
}

export const ScanImage = ({ scanImageUrl }: ScanImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isBrokenImage, setIsBrokenImage] = useState(false);
  const handleError = (evt: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setIsBrokenImage(true);
    evt.currentTarget.src = DefaultScanImage;
  };

  return (
    <ImageWrapper $isimageloaded={isImageLoaded} $isbrokenimage={isBrokenImage}>
      <Image
        src={scanImageUrl}
        onError={handleError}
        alt="scan image screenshot"
        onLoad={() => setIsImageLoaded(true)}
      />
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div<{
  $isimageloaded: boolean;
  $isbrokenimage: boolean;
}>`
  @media screen and (max-width: 1080px) {
    margin-top: 36px;
  }

  @media screen and (max-width: 900px) {
    display: ${(props) =>
      props.$isimageloaded && !props.$isbrokenimage ? 'flex' : 'none'};
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;

const Image = styled.img`
  width: 360px;
  height: 200px;
  border-radius: 3px;
  object-fit: cover;
  border: 2px solid #ffffff;

  @media screen and (max-width: 429px) {
    width: 100%;
  }
`;
