import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';

import { getWidgetDomains } from '@equally-ai-front/common/src/redux/user-products-slice/user-products';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ALL_PLANS_OPTION, PROMO_URL } from '../../common/magicValues';
import useAddDomainFormActions from '../../hooks/use-add-domain-form-actions';

import { isNullOrUndefined } from '@equally-ai-front/common/src/utils/helpers';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { DomainSummary } from '@equally-ai-front/common/src/types/domains';
import { Box, Tabs, Tab } from '@mui/material';
import AddMultipleDomainsToPlan from '../../components/common/AddMultipleDomainsToPlan/AddMultipleDomainsToPlan';
import { PlansAndRecords } from '../../components/common/DisplayRecordsAndPlans/plans-and-records';
import EmptyDomainState from '../../components/common/EmptyDomainState/EmptyDomainState';
import ManageWidgetCard from '../../components/common/manage-widget-card/manage-widget-card';
import { ProductType } from '../../components/common/product-type-chip';
import {
  PrimaryButton,
  PrimaryMobileButton,
} from '../../components/redesign/Controls';
import StartFreeTrial from '../../components/redesign/FreeTrialModal/StartFreeTrialModal';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { formatExpiration, getDaysLeft } from '../../lib/date';
import { isPlansAvailable } from '../../lib/plans';
import { WidgetTableBody } from './components/widget-table-body';
import { WidgetTableHeader } from './components/widget-table-header';
import { PricingContainer } from '../pricing-container/pricing-container';
import { usePlans } from '../../providers/plans-context';
import Cookies from 'js-cookie';
import {
  deleteFromCookies,
  EQUALLY_COOKIE_KEY,
} from '../../utils/cookie.utils';
import styled from '@emotion/styled';
import { TeamMemberStep } from '../on-boarding-container/components/team-member-step';
import { useWebsitesTable } from '../../providers/websites-table-context';

export const WidgetContainer = () => {
  const dispatch = useDispatch();
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const [selectedDomain, setSelectedDomain] = useState<Record<
    string,
    string | number | boolean
  > | null>(null);
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const [openManage, setOpenManage] = useState(false);

  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const productTypeRef = useRef<string | undefined>(undefined);
  const ActionButton = isMobile ? PrimaryMobileButton : PrimaryButton;
  const [showAddDomainsToPlansModal, setShowAddDomainsToPlansModal] =
    useState(false);
  const [selectedDomainsToPlan, setSelectedDomainsToPlan] = useState<any[]>([]);
  const [fromOnboardingParams, setFromOnboardingParams] = useSearchParams();
  const [fromAuditParams, setFromAuditParams] = useSearchParams();
  const [freeTrialModal, setFreeTrialModal] = useState(false);
  const { domains, loadingDomains } = useSelector((state: any) => ({
    domains: state.userProducts.domains,
    loadingDomains: state.userProducts.loading,
  }));
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const isNewWidgetDomain = useRef(false);

  const [selectedProductType, setSelectedProductType] = useState<
    undefined | ProductType
  >(undefined);
  const [freeTrialModalStep, setFreeTrialModalStep] = useState<0 | 1>(0);
  const [isInviteTeamMemberOpen, setIsInviteTeamMemberOpen] = useState(false);

  const handleCloseInviteTeamMemberModal = () => {
    setIsInviteTeamMemberOpen(false);
  };

  const handleCloseFreeTrialModal = () => {
    setFreeTrialModal(false);

    if (selectedProductType) {
      setSelectedProductType(undefined);
    }
  };

  // const [showStickyPlanMessage, setShowStickyPlanMessage] = useState(false);

  // const handleCloseStickyPlanMessage = () => {
  //   setShowStickyPlanMessage(false);
  // };

  const { activePlans: plansData, getActivePlans } = usePlans();

  const plansAvailability = isPlansAvailable(plansData);

  const launchFlowy = (
    domainId: any,
    domainName: string,
    isNewlyCreated?: boolean,
  ) => {
    analytics.track('Flowy Website Card Option Clicked', {
      id: domainId,
      url: domainName,
    });
    const flowyParams = isNewlyCreated ? '?dashboard_onboarding=true' : '';
    navigate(`developers/${domainId}/flowy/overview${flowyParams}`);
  };

  const handleDomainSuccess = (domain: any, type: string) => {
    // @ts-ignore
    dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
    if (['developer', 'flowy'].includes(type)) {
      return launchFlowy(domain.id, domain.name, true);
    }

    const expirationDate = new Date(domain.expires.seconds * 1000);

    const expirationISOString = expirationDate.toISOString();

    setSelectedDomain({ ...domain, type, expiration: expirationISOString });
    if (type === 'widget') {
      setOpenManage(true);
      isNewWidgetDomain.current = true;
    }

    if (plansAvailability) {
      getActivePlans('');
    }
  };

  const handleOpenFreeTrialModal = () => {
    setFreeTrialModal(true);
    setSelectedProductType('widget');
    setFreeTrialModalStep(1);
  };
  const { addDomains, domainsToAdd, handleNewDomainChange, isLoading } =
    useAddDomainFormActions({ businessId: currentBusiness?.id });

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }
    // @ts-ignore
    dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
  }, [currentBusiness, dispatch]);

  useEffect(() => {
    const attribution = JSON.parse(Cookies.get(EQUALLY_COOKIE_KEY) || '{}');

    const fromOnboarding =
      fromOnboardingParams.get('from_onboarding') === 'true';
    const fromAudit = fromAuditParams.get('start_free_trial') === 'true';

    if (fromOnboarding && !attribution?.from_pricing) {
      handleOpenFreeTrialModal();
      setFromOnboardingParams((prev) => {
        prev.delete('from_onboarding');
        return prev;
      });
    }

    if (fromAudit) {
      setFromAuditParams((prev) => {
        prev.set('start_free_trial', 'false');
        return prev;
      });
      handleOpenFreeTrialModal();
    }
  }, [fromOnboardingParams, fromAuditParams]);

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }

    const attribution = JSON.parse(Cookies.get(EQUALLY_COOKIE_KEY) || '{}');

    if (attribution?.from_pricing) {
      setIsPricingOpen(true);
      productTypeRef.current = 'widget';
      deleteFromCookies('from_pricing', true);
      return;
    }
  }, [currentBusiness]);

  const handleAddDomains = useCallback(async () => {
    if (isLoading || !personalDetails || !selectedProductType) {
      return;
    }
    const { isSuccess } = await addDomains(
      selectedProductType,
      undefined,
      undefined,
      personalDetails,
      handleDomainSuccess,
    );

    if (isSuccess) {
      setFreeTrialModal(false);
    }
  }, [addDomains, isLoading, personalDetails, selectedProductType]);

  const handleSelectProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedProductType(value as ProductType);
  };

  const { allDomainsLength, archieveDomainsLength } = useMemo(() => {
    if (!domains) {
      return {
        archieveDomainsLength: 0,
        allDomainsLength: 0,
      };
    }

    const archievedDomains = domains.filter(
      (domain: any) => domain.domain?.archived || domain.dev_domain?.archived,
    );

    return {
      archieveDomainsLength: archievedDomains.length,
      allDomainsLength: domains.length - archievedDomains.length,
    };
  }, [domains]);

  const {
    visibleDomains,
    pageCount,
    handlePageChange,
    currentPage,
    searchTerm,
    handleSearch,
    handleSort,
    sortOptions,
    domainGroup,
    setDomainGroup,
  } = useWebsitesTable();

  const openAddDomainsToPlansModal = () => setShowAddDomainsToPlansModal(true);

  const handleOpenManageModal = (
    event: React.MouseEvent<HTMLElement>,
    domain: any,
  ) => {
    event?.stopPropagation();
    setSelectedDomain(domain);
    setOpenManage(true);
  };

  const handleCloseManageModal = () => {
    setOpenManage(false);
    setSelectedDomain(null);
    if (isNewWidgetDomain.current) {
      isNewWidgetDomain.current = false;
    }
  };

  const isFlowyDomain = (domain: any) => {
    const domainSummary: DomainSummary = visibleDomains.find(
      (visibleDomain) => visibleDomain.name === domain.name,
    );

    if (isNullOrUndefined(domainSummary)) {
      return false;
    }

    return (
      !isNullOrUndefined(domainSummary.dev_domain) &&
      !isNullOrUndefined(domainSummary.dev_domain_id)
    );
  };

  const handleChoosePlan = (productType: string) => {
    productTypeRef.current = productType;
    setOpenManage(false);
    setIsPricingOpen(true);
  };

  const handleClosePricingModal = () => {
    productTypeRef.current = undefined;
    setIsPricingOpen(false);
  };

  const handleInviteTeamMember = () => {
    setOpenManage(false);
    setIsInviteTeamMemberOpen(true);
  };

  return (
    <>
      <PlansAndRecords
        type="widget"
        heading="Manage Websites"
        selectedPlan={ALL_PLANS_OPTION}
        searchValue={searchTerm}
        handleSearch={handleSearch}
        showActionButton={plansAvailability}
        attachPlanButtonLabel={plansAvailability ? 'Activate License' : ''}
        handleAttachPlanClick={
          plansAvailability
            ? openAddDomainsToPlansModal
            : () => {
                setIsPricingOpen(true);
              }
        }
        handleActionButton={handleOpenFreeTrialModal}
      >
        {/* {showStickyPlanMessage && (
            <StickyPlanContainer>
              <StickyHeader
                variant={HeaderColorType.DANGER}
                handleCloseStickyPlanMessage={handleCloseStickyPlanMessage}
                title="Your trial sites are about to be expired!"
                subText="Purchase a plan to ensure your website remains compliant."
              />
            </StickyPlanContainer>
          )} */}
        {!isLoading && domains?.length > 0 && (
          <StyledTabs
            onChange={(_, value) => setDomainGroup(value)}
            value={domainGroup}
          >
            <StyledTab
              value="all"
              label={`All (${allDomainsLength})`}
              disableRipple
              tabIndex={0}
            />

            <StyledTab
              disabled={archieveDomainsLength === 0}
              value="archived"
              label={`Archived (${archieveDomainsLength})`}
              disableRipple
              tabIndex={0}
            />
          </StyledTabs>
        )}
        {!isLoading && visibleDomains.length > 0 && (
          <Box>
            <WidgetTableHeader
              setSort={handleSort}
              currentSortOption={sortOptions}
            />
            <WidgetTableBody
              data={visibleDomains}
              handleManageModal={handleOpenManageModal}
              launchFlowy={launchFlowy}
              currentPage={currentPage}
            />
            <Pagination
              handlePageChange={handlePageChange}
              pageCount={pageCount}
            />
          </Box>
        )}
        {!isLoading && !loadingDomains && visibleDomains.length === 0 && (
          <EmptyDomainState
            withPlan={plansData.length > 0}
            action={handleOpenFreeTrialModal}
            actionButton={
              <ActionButton
                variant="contained"
                style={{
                  width: '160px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  backgroundColor: 'white',
                }}
                onClick={() => {
                  window.location.href = `${PROMO_URL}/pricing`;
                }}
              >
                Get Started
              </ActionButton>
            }
          />
        )}
        {selectedDomain && (
          <ManageWidgetCard
            open={openManage}
            handleClose={handleCloseManageModal}
            domain={selectedDomain}
            addDomainToActivateLicence={(domain) =>
              setSelectedDomainsToPlan([...selectedDomainsToPlan, domain])
            }
            showActivateModal={() => setShowAddDomainsToPlansModal(true)}
            showInstallExtension={isFlowyDomain(selectedDomain)}
            isNewDomain={isNewWidgetDomain.current}
            onChoosePlan={handleChoosePlan}
            handleInviteTeamMember={handleInviteTeamMember}
          />
        )}

        {freeTrialModal && (
          <StartFreeTrial
            addDomains={handleAddDomains}
            domainsToAdd={domainsToAdd}
            isLoading={isLoading}
            handleClose={handleCloseFreeTrialModal}
            open={freeTrialModal}
            handleInputChange={handleNewDomainChange}
            domains={domains}
            type={selectedProductType as ProductType}
            setProductType={handleSelectProduct}
            modalStep={freeTrialModalStep}
          />
        )}
        {showAddDomainsToPlansModal && (
          <AddMultipleDomainsToPlan
            handleClose={() => setShowAddDomainsToPlansModal(false)}
            open={showAddDomainsToPlansModal}
            plans={plansData}
            selectedWebsites={selectedDomainsToPlan.filter(
              (domain: any) =>
                !domain.plan_id ||
                formatExpiration(domain.expiration) === 'Expired',
            )}
            inActiveDomains={visibleDomains.filter(
              (domain: any) =>
                !domain.plan_id ||
                formatExpiration(domain.expiration) === 'Expired',
            )}
            setSelectedWebsites={setSelectedDomainsToPlan}
            type="widget"
          />
        )}
      </PlansAndRecords>
      {isPricingOpen && (
        <PricingContainer
          //@ts-ignore
          domainId={selectedDomain?.id}
          productType={productTypeRef.current}
          showModal={isPricingOpen}
          handleClose={handleClosePricingModal}
        />
      )}
      {isInviteTeamMemberOpen && (
        <TeamMemberStep
          isOpen={isInviteTeamMemberOpen}
          handleClose={handleCloseInviteTeamMemberModal}
        />
      )}
      <LoadingView
        open={isLoading || loadingDomains}
        loadingText="Getting your domain(s) ready!"
      />
    </>
  );
};

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    display: none;
  }
`;

const StyledTab = styled(Tab)`
  min-width: fit-content;
  text-transform: initial;

  &.Mui-selected {
    color: #454284;
  }

  &:hover {
    color: #22214d;
    background: transparent;
  }

  &:disabled {
    color: #9ba5b1;
    background: transparent;
  }

  &.Mui-focusVisible {
    border: solid 2px #1738f5;
  }
`;
