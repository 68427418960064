import { useFormik } from 'formik';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import { validationSchema } from '../../utils';
import { FIELD_AUDIT_URL } from '../../utils';
import { FormTextfield, FormTextfieldLight } from './form-text-field';
import { theme } from '../../utils/theme';
import styled from 'styled-components';
import { getUrlWithoutHttpPrefixAndWWW } from '@equally-ai-front/common/src';
import { useQuery } from '@equally-ai-front/common/src/hooks/useQuery';
import { QUERY_PARAM_AUDIT } from '@equally-ai-front/common/src/utils/magicValues';

const getInitialValues = () => ({
  [FIELD_AUDIT_URL]: '',
});

interface AuditFormProps {
  lightTheme: boolean;
  onAudit: (url: string) => Promise<void>;
  loading: boolean;
  handleAuditUrlChange: (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  auditUrl: string;
  [x: string]: any;
}

export const AuditForm = forwardRef(
  (
    {
      lightTheme,
      onAudit,
      loading,
      handleAuditUrlChange,
      auditUrl,
      ...props
    }: AuditFormProps,
    ref,
  ) => {
    const TextFieldComponent = lightTheme ? FormTextfield : FormTextfieldLight;
    const queryAuditURL = useQuery().get(QUERY_PARAM_AUDIT);
    useEffect(() => {
      if (!queryAuditURL) {
        return;
      }
      formik.values[FIELD_AUDIT_URL] =
        `https://${getUrlWithoutHttpPrefixAndWWW(queryAuditURL)}`;
      onSubmit(formik.values);
    }, [queryAuditURL]);

    useEffect(() => {
      if (!auditUrl) {
        return;
      }
      formik.values[FIELD_AUDIT_URL] =
        `https://${getUrlWithoutHttpPrefixAndWWW(auditUrl)}`;
    }, [auditUrl]);
    const onSubmit = useCallback(
      (values: any) => {
        const url = values[FIELD_AUDIT_URL];
        onAudit?.(`https://${getUrlWithoutHttpPrefixAndWWW(url)}`);
      },
      [onAudit],
    );

    const formik = useFormik({
      initialValues: getInitialValues(),
      validationSchema: validationSchema,
      onSubmit: onSubmit,
    });

    useImperativeHandle(ref, () => ({
      submitForm: formik.submitForm,
    }));

    return (
      <FormWrapper>
        <Form
          onSubmit={(evt: React.FormEvent<HTMLFormElement>) =>
            evt.preventDefault()
          }
        >
          <TextFieldComponent
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="url"
            name={FIELD_AUDIT_URL}
            label="Website URL"
            placeholder="https://example.com"
            type="url"
            variant="outlined"
            fullWidth
            value={formik.values[FIELD_AUDIT_URL] || auditUrl}
            onChange={(evt) => {
              formik.handleChange(evt);
              handleAuditUrlChange(evt);
            }}
            error={
              formik.touched[FIELD_AUDIT_URL] &&
              Boolean(formik.errors[FIELD_AUDIT_URL])
            }
            helperText={
              <>
                {formik.touched[FIELD_AUDIT_URL] &&
                  formik.errors[FIELD_AUDIT_URL]}
              </>
            }
            theme={theme}
            disabled={loading}
            {...props}
          />
        </Form>
      </FormWrapper>
    );
  },
);

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Form = styled.form`
  width: 100%;
  max-width: 54.82%;

  @media screen and (max-width: 1080px) {
    max-width: 67.56%;
  }
`;
