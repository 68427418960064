const groupings = {
  clickables: [
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.EmptyNoId',
      level: 'A',
      title: 'Completely empty anchor element (no text, no href, no ID).',
    },
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.NoHref',
      level: 'A',
      title: 'Anchor element with no href, but with an ID and text.',
    },
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.Placeholder',
      level: 'A',
      title: 'Link with text, but no href or ID.',
    },
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.NoContent',
      level: 'A',
      title: 'Link with a href attribute, but without link text.',
    },
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.Empty',
      level: 'A',
      title: 'Empty anchor element (no text, no href) but with an ID.',
    },
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.EmptyWithName',
      level: 'A',
      title:
        'Empty anchor element (no text, no href) but with a name attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_1.G1,G123,G124.NoSuchId',
      level: 'A',
      title:
        'Anchor (a) or area element with a href element pointing to an anchor in the same document.',
    },
    {
      principle:
        'WCAG2AA.Principle2.Guideline2_4.2_4_1.G1,G123,G124.NoSuchIdFragment2.4.2',
      level: 'A',
      title:
        'Anchor (a) or area element with a href element pointing to an ID in the same document',
    },
    {
      principle:
        'WCAG2AA.Principle2.Guideline2_4.2_4_4.H77,H78,H79,H80,H81,H33',
      level: 'A',
      title:
        'Anchor (A) elements in general, where they contain a title attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_4.H77,H78,H79,H80,H81',
      level: 'A',
      title: 'Anchor (A) elements in general (no title attribute).',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_9.H30',
      level: 'AAA',
      title: 'Anchor (A) elements in general.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_5.H83.3',
      level: 'AAA',
      title: 'Link elements that have a "_blank" target attribute.',
    },
  ],
  forms: [
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.InputText.Name',
      level: 'A',
      title:
        'Form control which does not have a valid accessibility API "name".',
    },
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.InputText.Value',
      level: 'A',
      title:
        'Form control which does not have a valid accessibility API "value".',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_3.3_3_1.G83,G84,G85',
      level: 'A',
      title: 'Form elements error identification.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_3.3_3_2.G131,G89,G184,H90',
      level: 'A',
      title: 'Form elements labels or instructions.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_3.3_3_3.G177',
      level: 'AA',
      title: 'Form elements error suggestion',
    },
    {
      principle:
        'WCAG2AA.Principle3.Guideline3_3.3_3_4.G98,G99,G155,G164,G168.LegalForms',
      level: 'AA',
      title: 'Form elements error Prevention (Legal, Financial, Data)',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_2.H32.2',
      level: 'A',
      title:
        'Forms which do not provide an ability on their interface to be submitted using the mouse.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H93',
      level: 'A',
      title: 'Multiple labels exist with the same "for" attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NonExistent',
      level: 'A',
      title:
        'Label with a "for" attribute that does not match any ID used in the document.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NonExistentFragment',
      level: 'A',
      title:
        'Label with a "for" attribute that does not match any ID used in the document fragment.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NotFormControl',
      level: 'A',
      title:
        'Label with a "for" attribute that matches an ID of an element that is not a form control (that should have a label).',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NoForAttr',
      level: 'A',
      title: 'Label without a "for" attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NoId',
      level: 'A',
      title: 'Form control without an ID.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H65.3',
      level: 'A',
      title:
        'Form control with no label, and has a title attribute (which it should have if no label) - but the title attribute is empty.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H65',
      level: 'A',
      title:
        'Form control with a validly specified title attribute (no label).',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.2',
      level: 'A',
      title:
        'Form control with an ID that can be used to associate a label with, but has no explicit label or title attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NoLabelAllowed',
      level: 'A',
      title:
        'Form controls which do not require a label, yet one has been provided.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.1.After',
      level: 'A',
      title:
        'Form control where the label should be placed after the element, but not.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.1.Before',
      level: 'A',
      title:
        'Form control where the label should be placed before the element, but not.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H71.3',
      level: 'A',
      title: 'Fieldset without legend element.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H85.2',
      level: 'A',
      title: 'Select element not using optgroups.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H71.2',
      level: 'A',
      title:
        'Radio or checkbox inputs with the same name attribute, signifying they are part of a group, but with no fieldset marking them up as such.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.FaultyValue',
      level: 'AA',
      title: 'Potential faulty value in autocomplete attribute.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Text',
      level: 'AA',
      title:
        'Invalid autocomplete value, element does not belong to Text control group.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Multiline',
      level: 'AA',
      title:
        'Invalid autocomplete value, element does not belong to Multiline control group.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Password',
      level: 'AA',
      title:
        'Invalid autocomplete value, element does not belong to Password control group.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Url',
      level: 'AA',
      title:
        'Invalid autocomplete value, element does not belong to Url control group.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Telephone',
      level: 'AA',
      title:
        'Invalid autocomplete value, element does not belong to Telephone control group.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Numeric',
      level: 'AA',
      title:
        'Invalid autocomplete value, element does not belong to Numeric control group.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Month',
      level: 'AA',
      title:
        'Invalid autocomplete value, element does not belong to Month control group.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Date',
      level: 'AA',
      title:
        'Invalid autocomplete value, element does not belong to Date control group.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.Purpose',
      level: 'AA',
      title: 'Input Purposes for User Interface Components section',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.MissingAutocomplete',
      level: 'AA',
      title: 'Missing autocomplete attribute',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_3.3_3_5.G71,G184,G193',
      level: 'AAA',
      title: 'Help availability in form elements',
    },
    {
      principle:
        'WCAG2AA.Principle3.Guideline3_3.3_3_6.G98,G99,G155,G164,G168.AllForms',
      level: 'AAA',
      title: 'Form elements Error Prevention (All)',
    },
  ],
  sensory: [
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_3.G96',
      level: 'A',
      title: 'Sensory Characteristics',
    },
  ],
  semantics: [
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_2.G57',
      level: 'A',
      title: 'Meaningful Sequence',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H48.1',
      level: 'A',
      title: 'Paragraph content appearing like a bulleted list in text.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H48.2',
      level: 'A',
      title: 'Paragraph content appearing like a numbered list in text.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.G141',
      level: 'A',
      title: 'Header elements when a skip of a heading level is detected',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H48',
      level: 'A',
      title: 'P or DIV element that has more than one link.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H49.[',
      level: 'A',
      title:
        'Element that is used for presentational purposes (by HTML 4`s standards).',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H49.AlignAttr',
      level: 'A',
      title: 'Element with an align attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H42',
      level: 'A',
      title:
        'Paragraph of text that appears to have bold or italic content (whether through b/i or strong/em) as its only child.',
    },
  ],
  tables: [
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H63.3',
      level: 'A',
      title: 'TH element with an invalid scope attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H63.2',
      level: 'A',
      title: 'TD element with a scope attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.ScopeAmbiguous',
      level: 'A',
      title:
        'Table with multiple levels of row or column headers, but using scope attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.IncorrectAttr',
      level: 'A',
      title:
        'TD element with a headers attribute which does not match the one expected from its relevant header cells (the IDs of the TH elements it is aligned with)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.HeadersRequired',
      level: 'A',
      title:
        'Table with multiple levels of row or column headers, not using headers attributes at all.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.MissingHeaderIds',
      level: 'A',
      title: 'Table where not all TH elements contain IDs.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.MissingHeadersAttrs',
      level: 'A',
      title:
        'Table where not all TD elements contain headers attributes (but some do).',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43,H63',
      level: 'A',
      title:
        'Table with a single levels of row AND column headers, not using headers or scope attributes at all.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H63.1',
      level: 'A',
      title: 'Table where some TH elements contain scope attributes',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H39,H73.4',
      level: 'A',
      title:
        'Table where the summary attribute and caption element match exactly (after trimming).',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H73.3.Check',
      level: 'A',
      title: 'Table with specified summary attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H73.3.NoSummary',
      level: 'A',
      title: 'Table without summary attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H39.3.Check',
      level: 'A',
      title: 'Table with specified caption element.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H39.3.NoCaption',
      level: 'A',
      title: 'Table without caption element.',
    },
  ],
  objects: [
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H53',
      level: 'A',
      title:
        'Object element (or group of nested object elements) that does not contain a text alternative at the deepest level.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G94,G92.Object',
      level: 'A',
      title:
        'Object elements (with a correctly specified text alternative) in general.',
    },
  ],
  orientation: [
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_4.RestrictView',
      level: 'AA',
      title: 'Orientation',
    },
  ],
  ruby: [
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_6.H62.1.HTML5',
      level: 'AAA',
      title: 'Ruby element without a child rt element.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_6.H62.1.XHTML11',
      level: 'AAA',
      title: 'Ruby element without a child rt element.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_6.H62.2',
      level: 'AAA',
      title: 'Ruby element without child rp elements.',
    },
  ],
  readability: [
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_5.G86,G103,G79,G153,G160',
      level: 'AAA',
      title: 'Reading level.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_5.Check',
      level: 'AAA',
      title: 'Target size',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_4.G142',
      level: 'AA',
      title: 'Resize text',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_5.G140,C22,C30.AALevel',
      level: 'AA',
      title: 'Images of text',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.G148,G156,G175',
      level: 'AAA',
      title: 'Selecting foreground and background colours for blocks of text.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.H87,C20',
      level: 'AAA',
      title: 'Width of a block of text',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.C19,G172,G169',
      level: 'AAA',
      title: 'Justification of blocks of text.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.G188,C21',
      level: 'AAA',
      title: 'Line spacing in blocks of text.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.H87,G146,C26',
      level: 'AAA',
      title: 'Resizing text without assistive technology.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_4.1_4_9.G140,C22,C30.NoException',
      level: 'AAA',
      title: 'Images of text(no exception).',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_12_C36,C35.Check',
      level: 'AA',
      title: 'Text spacing',
    },
  ],
  graphics: [
    {
      principle: 'WCAG2AA.Principle1.Guideline2_3.2_3_3.Check',
      level: 'AAA',
      title: 'Animation from Interactions',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_1.G14,G182',
      level: 'A',
      title: 'Use of colour',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G18',
      level: 'AA',
      title:
        'Elements with text with colours that have insufficient colour contrast of at least 4.5:1',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G145',
      level: 'AA',
      title:
        'Elements with text with colours that have insufficient colour contrast of at least 3:1',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G18',
      level: 'AA',
      title: 'Text placed in front of a background image',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G145',
      level: 'AA',
      title:
        'Text defined as large text placed in front of a background image.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.F24.BGColour',
      level: 'AA',
      title:
        'Elements with a background style or bgcolor attribute, without a corresponding foreground color style or attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.F24.FGColour',
      level: 'A',
      title:
        'Elements with a foreground color style or attribute, without a corresponding background color style or bgcolor attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_6.G17',
      level: 'AAA',
      title:
        'Elements with text with colours that have insufficient colour contrast of at least 7:1.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_6.G18',
      level: 'AAA',
      title:
        'Elements with text with colours that have insufficient colour contrast for "large text".',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_6.G17',
      level: 'AAA',
      title: 'Text placed in front of a background image.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_6.G18',
      level: 'AAA',
      title: '"Large text" placed in front of a background image',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_4.1_4_11_G195,G207,G18,G145,G174,F78.Check',
      level: 'AA',
      title: 'Non-text Contrast',
    },
  ],
  images: [
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H30.2',
      level: 'A',
      title:
        'Image elements with an absent or empty alt attribute, that is the only non-blank content of a link',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H37',
      level: 'A',
      title:
        'Image element (outside of a link) that is completely missing an alt attribute',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H67.1',
      level: 'A',
      title:
        'Image element (outside of a link) that is marked with an empty alt attribute (intending it to be ignored), but has a non-empty title attribute',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H67.2',
      level: 'A',
      title:
        'Image element (outside of a link) that is marked with an empty alt attribute, and its title attribute is empty or absent.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G94.Image',
      level: 'A',
      title: 'Images with alt text, in general.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H36',
      level: 'A',
      title: 'Image submit button that has a missing or empty alt attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G94.Button',
      level: 'A',
      title: 'Image submit buttons.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H24',
      level: 'A',
      title:
        'Area elements in an image map that have an empty or missing alt attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H24.2',
      level: 'A',
      title: 'Area elements with alt text, in general.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G73,G74',
      level: 'A',
      title: 'Images with alt text.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H2.EG5',
      level: 'A',
      title:
        'Image inside a link along with text content, where the alt attribute duplicates the text content (after trimming).',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H2.EG4',
      level: 'A',
      title:
        'Img element inside a link has empty or missing alt text when a link beside it contains link text.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H2.EG3',
      level: 'A',
      title:
        'Image inside a link, that is next to a link that has the same text content (after trimming).',
    },
  ],
  applet: [
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H35.3',
      level: 'A',
      title: 'Applet element without a body text alternative.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H35.2',
      level: 'A',
      title: 'Applet element without an altt attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G94,G92.Applet',
      level: 'A',
      title:
        'Applet elements (with correctly specified text alternatives) in general.',
    },
  ],
  media: [
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_1.H64.1',
      level: 'A',
      title: 'Iframe elements that have missing or empty title attribute.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_1.G159,G166',
      level: 'A',
      title: 'Elements which may contain video.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_2.G87,G93',
      level: 'A',
      title: 'Captions (Prerecorded)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_1.G158',
      level: 'A',
      title: 'Audio-only and Video-only (Prerecorded)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_3.G69,G78,G173,G8',
      level: 'A',
      title: 'Audio Description or Media Alternative (Prerecorded)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_4.G9,G87,G93',
      level: 'AA',
      title: 'Captions (Live)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_5.G78,G173,G8',
      level: 'AA',
      title: 'Audio Description (Prerecorded)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_6.G54,G81',
      level: 'AAA',
      title: 'Sign Language (Prerecorded)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_7.G8',
      level: 'AAA',
      title: 'Extended Audio Description (Prerecorded)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_8.G69,G159',
      level: 'AAA',
      title: 'Media Alternative (Prerecorded)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_9.G150,G151,G157',
      level: 'AAA',
      title: 'Audio-only (Live)',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_2.F23',
      level: 'A',
      title: 'Audio control',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_7.G56',
      level: 'AAA',
      title: 'Low or No Background Audio',
    },
  ],
  title: [
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H42.2',
      level: 'A',
      title: 'Heading tag found with no content',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_6.G130,G131',
      level: 'AA',
      title: 'Headings and labels',
    },
  ],
  document: [
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_2.H25.2',
      level: 'A',
      title: 'Title element should describe the document.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_2.H25.1.NoTitleEl',
      level: 'A',
      title: 'Document with a head section, but missing a title attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_2.H25.1.EmptyTitle',
      level: 'A',
      title: 'Title element that exists, but is empty.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_4.1_4_10_C32,C31,C33,C38,SCR34,G206.Zoom',
      level: 'AA',
      title:
        'Meta element with a name of "viewport" that defines scaling rules.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_1.F40.2',
      level: 'A',
      title:
        'Meta refresh element with a timeout greater than zero (with a URL to redirect to).',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_1.F41.2',
      level: 'A',
      title:
        'Meta refresh element with a timeout greater than zero (and no URL, indicating "refresh the current URL").',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_8.H59.1',
      level: 'AAA',
      title: 'Link element placed outside the head element of the document.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_8.H59.2a',
      level: 'AAA',
      title: 'Link element with missing or empty rel attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_8.H59.2b',
      level: 'AAA',
      title: 'Link element with missing or empty href attribute.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_1.H57.2',
      level: 'A',
      title: 'HTML element with no language attributes.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_1.H57.3.Lang',
      level: 'A',
      title: 'HTML element with a lang attribute',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_1.H57.3.XmlLang',
      level: 'A',
      title:
        'HTML element with an xml:lang attribute (as used by XHTML) that is not well-formed.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_2.H58',
      level: 'AA',
      title: 'Language of parts.',
    },
  ],
  general: [
    {
      principle:
        'WCAG2AA.Principle2.Guideline2_4.2_4_5.G125,G64,G63,G161,G126,G185',
      level: 'AA',
      title: 'Multiple ways',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_4.1_4_10_C32,C31,C33,C38,SCR34,G206.Scrolling',
      level: 'AA',
      title: 'Preformatted text i.e "pre" tag elements',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_6_ARIA11.Check',
      level: 'AAA',
      title: 'Identify purpose',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_4.1_4_10_C32,C31,C33,C38,SCR34,G206.Check',
      level: 'AA',
      title: 'Presentin content without loss of information.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline1_4.1_4_10_C32,C31,C33,C38,SCR34,G206.Fixed',
      level: 'AA',
      title: 'Elements with "position: fixed"',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_13_F95.Check',
      level: 'AA',
      title: 'Content on hover or focus.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.DblClick',
      level: 'A',
      title: 'Elements with an inline ondblclick attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseOver',
      level: 'A',
      title: 'Elements with an inline onmouseover attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseOut',
      level: 'A',
      title: 'Elements with an inline onmouseout attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseMove',
      level: 'A',
      title: 'Elements with an inline onmousemove attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseDown',
      level: 'A',
      title: 'Elements with an inline onmousedown attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseUp',
      level: 'A',
      title: 'Elements with an inline onmouseup attribute.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_2.F10',
      level: 'A',
      title: 'No keyboard trap',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_1.2_1_4.Check',
      level: 'A',
      title: 'Character Key Shortcuts',
    },
    {
      principle:
        'WCAG2AA.Principle2.Guideline2_2.2_2_2.SCR33,SCR22,G187,G152,G186,G191',
      level: 'A',
      title: 'Pause, stop, hide',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_2.F4',
      level: 'A',
      title: 'Elements that have a blink style (text-decoration: blink)',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_2.F47',
      level: 'A',
      title: 'Blink elements, in general.',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_3.G5',
      level: 'AAA',
      title: 'No timing',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_4.SCR14',
      level: 'AAA',
      title: 'Interruptions',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_5.G105,G181',
      level: 'AAA',
      title: 'Re-authenticating',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_2.2_2_6.Check',
      level: 'A',
      title: 'Timeouts',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_3.2_3_1.G19,G176',
      level: 'A',
      title: 'Three Flashes or Below Threshold',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_3.2_3_2.G19',
      level: 'AAA',
      title: 'Three flashes',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_1.H64.2',
      level: 'A',
      title: 'Title attribute of frame',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_1.G1,G123,G124,H69',
      level: 'A',
      title: 'Bypass blocks',
    },
    {
      principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_3.H4.2',
      level: 'A',
      title: 'Focus order.',
    },
    {
      principle:
        'WCAG2AA.Principle2.Guideline2_4.2_4_7.G149,G165,G195,C15,SCR31',
      level: 'AA',
      title: 'Focus visible',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_1.Check',
      level: 'A',
      title: 'Pointer gestures',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_2.SinglePointer_Check',
      level: 'A',
      title: 'Pointer cancellation',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_2.Mousedown_Check',
      level: 'A',
      title: 'All elements with an "onmousedown" event.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_2.Touchstart_Check',
      level: 'A',
      title: 'All elements with an "ontouchstart" event.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_3_F96.Check',
      level: 'A',
      title: 'Label in name.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_3_F96.AccessibleName',
      level: 'A',
      title:
        'Anchor (A), button, and input elements. Also applies to all elements with a label.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_4.Check',
      level: 'A',
      title: 'Motion actuation',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_4.Devicemotion',
      level: 'A',
      title: 'Elements with an "ondevicemotion" event.',
    },
    {
      principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_6.Check',
      level: 'AAA',
      title: 'Concurrent Input Mechanisms',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_2.H58.1.Lang',
      level: 'AA',
      title: 'Element (other than a HTML element) with a lang attribute',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_2.H58.1.XmlLang',
      level: 'AA',
      title:
        'Element (other than a HTML element) with an xml:lang attribute (as used by XHTML) that is not well-formed.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_3.H40,H54,H60,G62,G70',
      level: 'AAA',
      title: 'Unusual words.',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_1.G107',
      level: 'A',
      title: 'On focus',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_3.G61',
      level: 'AA',
      title: 'Consistent Navigation',
    },
    {
      principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_4.G197',
      level: 'AA',
      title: 'Consistent Identification',
    },
    {
      principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_1.F77',
      level: 'A',
      title: 'Second and subsequent sightings of an element ID.',
    },
    {
      principle:
        'WCAG2AA.Principle1.Guideline4_1.4_1_3_ARIA22,G199,ARIA19,G83,G84,G85,G139,G177,G194,ARIA23.Check',
      level: 'AA',
      title: 'Status messages',
    },
  ],
};

export default groupings;
