import React from 'react';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import styled from 'styled-components';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { formatExpiration, getDaysLeft } from '../../../lib/date';

interface SidePanelContentProps {
  handleCloseDrawer: () => void;
}

interface FlowyTab {
  label: string;
  to: string;
  icon: string;
}

type FlowyTabs = Record<string, FlowyTab>;

const TABS: FlowyTabs = {
  overview: {
    label: 'Overview',
    to: 'overview',
    icon: '/overview-icon.svg',
  },
  issues: {
    label: 'Issues',
    to: 'issues',
    icon: '/issues.svg',
  },
  settings: {
    label: 'Settings',
    to: 'settings/schedule-scans',
    icon: '/settings_accessibility.svg',
  },
};

export const SidePanelContent = (props: SidePanelContentProps) => {
  const { handleCloseDrawer } = props;
  const location = useLocation();
  const isOnMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const { domains } = useSelector((state: any) => ({
    domains: state.userProducts.domains,
  }));
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const getActiveTab = () => {
    const path = location.pathname.split('/')[3];
    switch (path) {
      case 'overview':
        return 0;
      case 'issues':
        return 1;
      case 'settings':
        return 2;
      default:
        return 0;
    }
  };

  const domainsWithTimeLeft = (domains || []).map((domain: any) => ({
    ...domain,
    is_expired:
      formatExpiration(
        domain.domain?.expiration || domain.dev_domain?.expiration,
      ) === 'Expired',
  }));

  const visibleDevDomains: any[] = [...domainsWithTimeLeft]
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((devDomain) => devDomain.dev_domain_id);

  const launchFlowy = (domainId: any, domainName: string) => {
    analytics.track('Flowy Website Card Option Clicked', {
      id: domainId,
      url: domainName,
    });
    navigate(`../developers/${domainId}/flowy/overview`);
  };

  return (
    <Wrapper>
      <Main>
        <FlowyTabWrapper value={getActiveTab()}>
          {Object.keys(TABS).map((tabKey, index) => {
            const { label, to, icon } = TABS[tabKey];
            return (
              <Tab
                sx={{ flexDirection: 'row', justifyContent: 'flex-start' }}
                tabIndex={0}
                key={`dashboard-tab-${to}`}
                aria-selected={getActiveTab() === index}
                component={NavLink}
                className="flowy-dashboard-link"
                to={to}
                label={<TabLabel>{label}</TabLabel>}
                icon={<TabIcon alt={`${label} icon`} src={icon} />}
                onClick={handleCloseDrawer}
              />
            );
          })}
        </FlowyTabWrapper>
        {visibleDevDomains.length > 0 && (
          <ListContainer ismobile={isOnMobile ? isOnMobile.toString() : ''}>
            <Title>Domains</Title>
            <CustomUL
              isWebsiteUrlPresent
              ismobile={isOnMobile ? isOnMobile.toString() : ''}
              role="list"
            >
              {visibleDevDomains
                .sort(
                  (devDomainA, devDomainB) =>
                    devDomainA.is_expired - devDomainB.is_expired,
                )
                .map((devDomain: any, ddidx) => {
                  // eslint-disable-next-line camelcase
                  const { name, dev_domain_id, is_expired } = devDomain;
                  return (
                    <CustomLi
                      role="listitem"
                      tabIndex={0}
                      key={`scan-group-${ddidx}`}
                      ismobile={isOnMobile ? isOnMobile.toString() : ''}
                      onClick={() => {
                        !is_expired && launchFlowy(dev_domain_id, name);
                      }}
                      isexpired={is_expired.toString()}
                      onKeyDown={(evt) => {
                        if (evt.key === 'Enter' || evt.key === ' ') {
                          launchFlowy(dev_domain_id, name);
                        }
                      }}
                    >
                      <CustomLiText>{name}</CustomLiText>
                      {is_expired && <ExpiredTag>Expired</ExpiredTag>}
                    </CustomLi>
                  );
                })}
            </CustomUL>
          </ListContainer>
        )}
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Main = styled.main``;

const FlowyTabWrapper = withStyles({
  root: {
    marginBottom: '10%',
    borderTop: '2px solid #e8e8e8',

    '& .MuiTabs-flexContainer': {
      flexDirection: 'column',

      '& .MuiTab-root': {
        maxWidth: '100%',
        borderBottom: '2px solid #e8e8e8',
        paddingLeft: '6%',

        '& .MuiTab-wrapper': {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          fontSize: 13,

          '& > *:first-of-type': {
            marginBottom: 0,
          },
        },

        '&.Mui-selected': {
          borderBottom: '2px solid #1890ff',
        },
      },
    },
  },
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);

const TabLabel = styled.span``;

const TabIcon = styled.img`
  && {
    margin-right: 15px;
    margin-bottom: 0;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 13px;
  background-color: #ffffff;
  color: #50565f;
  border-radius: 8px;
  padding: 5px 8px;
  width: fit-content;
`;

type StyleComponentProps = { ismobile: string };
const ListContainer = styled.div<StyleComponentProps>`
  overflow-y: auto;
  height: ${(p) => (p.ismobile ? '250px' : '100%')};
  width: 90%;
  margin: auto;
  margin-top: ${(p) => (p.ismobile ? '15px' : '10px')};

  /* width */

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }
`;

type CustomUlProps = { isWebsiteUrlPresent: boolean; ismobile: string };

const CustomUL = styled.ul<CustomUlProps>`
  margin-top: ${(p) => (p.isWebsiteUrlPresent ? '' : '20%')};
  margin-left: ${(p) => (p.ismobile ? '62px' : '23px')};
  padding: 0;
`;

type CustomLiProps = {
  bulletColor?: string;
  ismobile: string;
  isexpired: string;
  editing?: string;
};

const CustomLi = styled.li<CustomLiProps>`
  margin-bottom: ${(p) => (p.ismobile ? '10px' : '5px')};
  cursor: ${(props) =>
    props.isexpired === 'true' ? 'not-allowed' : 'pointer'};
  width: ${(p) => (p.editing === 'true' ? '100%' : 'fit-content')};
  min-width: 20px;
  list-style: outside;
  position: relative;

  &::marker {
    color: ${(p) => p.bulletColor};
    font-size: 25px;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`;

const CustomLiText = styled.span`
  color: #000a14;
  word-wrap: break-word;
  font-size: 15px;
  vertical-align: text-bottom;
  margin-left: -3px;
`;

const ExpiredTag = styled.span`
  background-color: #bdbdbd;
  color: #000000;
  position: absolute;
  font-size: 12px;
  right: -80px;
  top: 8px;
  border-radius: 8px;
  padding: 4px 10px;
`;
