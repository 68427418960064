import { useAnalytics } from '@equally-ai-front/common/src';
import { isNullOrUndefined } from '@equally-ai-front/common/src';
import { updateBusinessAndCurrentBusiness } from '@equally-ai-front/common/src/redux/business-slice/business';
import Cookies from 'js-cookie';
import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { parseJwt } from '@equally-ai/auth-service';
import { getPersonalDetails } from '../common/helpers';
import {
  PROMO_POST_INSTALL_URL,
  QUERY_PARAM_GOOGLE_SIGNUP,
  QUERY_PARAM_REDIRECT_EXTENSION,
  QUERY_PARAM_REDIRECT_TO_SIGN_UP,
} from '../common/magicValues';
import { useQuery } from '../hooks/useQuery';
import {
  setApiError,
  setApiSuccessMessage,
  setPersonalDetails,
  setToken,
} from '../store/actions';
import { AuthUtils } from '@equally-ai/auth-service';
import { cognitoDir } from '@equally-ai/auth-service';
import { checkIfTokenHasExpired } from '@equally-ai/auth-service';
import { navigateToExternalURL } from '../common/navigate';
import { saveAuthTokenCookies } from '../utils/cookie.utils';

interface AuthContextProps {
  children?: React.ReactNode;
}

export type SignupFormValues = {
  email: string;
  password: string;
  phoneNumber: string;
  businessName: string;
  name: string;
  checked: boolean;
};

export type AuthType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  logout: () => void;
  loginSuccess: (token: string) => void;
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  signUpUser: (values: SignupFormValues) => Promise<void>;
  verifyAuthentication: () => Promise<void>;
  handleSubmitForgetPassword: (
    email: string,
    code: string,
    newPassword: string,
  ) => Promise<void>;
  resetPassword: (oldPassword: string, newPassword: string) => Promise<void>;
  initiateForgotPassword: (
    email: string,
    callback: () => void,
  ) => Promise<void>;
  verifyEmail: (email: string, code: string) => Promise<boolean>;
};

const AuthContext = React.createContext({} as AuthType);

export const AuthProvider = ({ children }: AuthContextProps) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fromExtensionParam = useQuery().get(QUERY_PARAM_REDIRECT_EXTENSION);
  const continueWithGoogleParam = useQuery().get(QUERY_PARAM_GOOGLE_SIGNUP);

  const isSignUpQueryParam = useQuery().get(QUERY_PARAM_REDIRECT_TO_SIGN_UP);
  const isLoggedOutRef = useRef(false);
  const refreshSessionRef = useRef<NodeJS.Timer | null>(null);
  const shouldTrackUserRef = useRef<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const removeParam = (paramName: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.delete(paramName);

    setSearchParams(newSearchParams);
  };

  const onLogout = useCallback(() => {
    if (searchParams.get('code')) {
      return;
    }
    setIsAuth(false);
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    Cookies.remove('tokenId');
    dispatch(
      updateBusinessAndCurrentBusiness({
        businesses: undefined,
        currentBusiness: undefined,
      }),
    );
    AuthUtils.logout();
    shouldTrackUserRef.current = true;
    isLoggedOutRef.current = true;

    const urlParam = fromExtensionParam
      ? `?${QUERY_PARAM_REDIRECT_EXTENSION}=${encodeURIComponent(fromExtensionParam)}`
      : '';

    if (
      !isNullOrUndefined(isSignUpQueryParam) &&
      isSignUpQueryParam === 'true'
    ) {
      navigate(`/signup${urlParam}`);
    } else {
      navigate(`/login${urlParam}`);
    }
  }, [navigate, searchParams]);

  const refreshSession = useCallback(async () => {
    const refreshToken = Cookies.get('refreshToken');

    if (!refreshToken) {
      return;
    }

    const {
      idToken,
      refreshToken: sessionRefreshToken,
      isRefreshed,
    } = await cognitoDir.refreshSession(refreshToken);

    if (!isRefreshed) {
      refreshSessionRef.current && clearInterval(refreshSessionRef.current);
      onLogout();
    }

    if (idToken && sessionRefreshToken) {
      Cookies.set('accessToken', idToken);
      Cookies.set('refreshToken', sessionRefreshToken);

      if (fromExtensionParam) {
        onLoginFromExtension(idToken, sessionRefreshToken);
        return;
      }

      if (!isAuth) {
        setIsAuth(true);
        onLoginSuccess(idToken);
      }
    }
  }, [isAuth]);

  const trackUser = useCallback(
    async (token: string) => {
      const personalDetailsResponse = await getPersonalDetails(token);
      dispatch(setPersonalDetails(personalDetailsResponse.message));

      if (personalDetailsResponse.code === 200) {
        const { newAccount, isSpecialDomain, id, testAccount } =
          personalDetailsResponse.message;
        const user = parseJwt(token);
        analytics.alias(id);

        if (!newAccount) {
          analytics.track('User Signed In', {
            user_id: user.preferred_username,
            is_special_domain: isSpecialDomain,
            is_test_user: testAccount,
          });
        }
      }
    },
    [dispatch, analytics],
  );

  const onLoginSuccess = useCallback(
    async (token: string) => {
      try {
        dispatch(setToken(token));

        if (shouldTrackUserRef.current) {
          await trackUser(token);
          shouldTrackUserRef.current = false;
          setIsAuth(true);
        }
        if (!refreshSessionRef.current) {
          refreshSessionRef.current = setInterval(refreshSession, 600000);
        }
      } catch (error) {
        onLogout();
      }
    },
    [dispatch, trackUser],
  );

  const onLoginFromExtension = useCallback(
    (accessToken: string, refreshToken: string) => {
      saveAuthTokenCookies({
        'eq-ext-accessToken': accessToken,
        'eq-ext-refreshToken': refreshToken,
      });

      Cookies.remove(QUERY_PARAM_REDIRECT_EXTENSION);
      navigateToExternalURL(PROMO_POST_INSTALL_URL);
    },
    [],
  );

  const onLoginWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      setIsLoading(true);
      try {
        // @ts-ignore
        let result = await AuthUtils.onLoginWithEmail(email, password);
        let user;
        let idToken;
        let token;
        // @ts-ignore
        idToken = result.idToken;
        // @ts-ignore
        user = result.user;
        // @ts-ignore
        let emailVerified = result.emailVerified;

        if (!emailVerified) {
          navigate({
            pathname: '/email-verification',
            search: `?email=${encodeURIComponent(email)}`,
          });
          return;
        }
        if (!idToken && user) {
          result = await AuthUtils.onLoginWithEmail(email, password);
        }
        // @ts-ignore
        idToken = result.idToken;
        // @ts-ignore
        user = result.user;
        // @ts-ignore
        token = result.token;
        const refreshToken = result?.refreshToken;

        if (token && idToken) {
          const {
            email_verified: emailVerified,
            preferred_username: uid,
            email: userEmail,
            displayName,
          } = user;

          if (!emailVerified) {
            navigate('/email-verification');
            return;
          }

          const identifyActions: Record<string, any> = {
            email: userEmail,
            name: displayName,
          };

          const isFromExtension =
            Cookies.get(QUERY_PARAM_REDIRECT_EXTENSION) === 'true';

          if (isFromExtension) {
            identifyActions.extension_log_in_performed_at = new Date();
          }

          analytics.identify(uid, identifyActions);

          if (isFromExtension) {
            return onLoginFromExtension(idToken, refreshToken || '');
          }

          Cookies.set('accessToken', idToken);
          Cookies.set('refreshToken', refreshToken || '');

          await onLoginSuccess(idToken);
          dispatch(setApiSuccessMessage('Login Successful', true));
        }
      } catch (error) {
        dispatch(
          setApiError(
            // @ts-ignore
            error?.message ?? AuthUtils.getErrorMessage(error?.code || ''),
          ),
        );
      } finally {
        setIsLoading(false);
      }
    },
    [analytics, onLoginSuccess],
  );

  useEffect(() => {
    const code = searchParams.get('code');
    const errorDescription = searchParams.get('error_description');
    if (!code) {
      if (errorDescription) {
        dispatch(setApiError(errorDescription));
      }
      return;
    }

    setIsLoading(true);

    const isFromExtension =
      Cookies.get(QUERY_PARAM_REDIRECT_EXTENSION) === 'true';

    const updateSuccessLogin = async () => {
      const { data, isSuccess } =
        await cognitoDir.getTokensFromGoogleCode(code);

      removeParam('code');

      if (!data || !isSuccess) {
        dispatch(setApiError('Unable to login'));
        setIsLoading(false);
        onLogout();
        return;
      }

      const { accessToken, idToken, refreshToken } = data;

      if (isFromExtension) {
        return onLoginFromExtension(idToken, refreshToken);
      }

      Cookies.set('accessToken', idToken);
      Cookies.set('refreshToken', refreshToken);

      await cognitoDir.createUserSignInSession(
        idToken,
        accessToken,
        refreshToken,
      );

      await onLoginSuccess(idToken);
      setIsLoading(false);
    };
    void updateSuccessLogin();
  }, [searchParams, onLoginSuccess]);

  useEffect(() => {
    const isWithGoogle = continueWithGoogleParam === 'true';

    if (isWithGoogle) {
      window.location.href = cognitoDir.getGoogleUrl();
      return;
    }

    if (fromExtensionParam) {
      Cookies.set(QUERY_PARAM_REDIRECT_EXTENSION, fromExtensionParam);
      return;
    }

    Cookies.remove(QUERY_PARAM_REDIRECT_EXTENSION);
  }, []);

  // const onLoginWithGoogle = useCallback(async () => {
  //   try {
  //     // console.log(accessToken, idToken)
  //     // const response = await AuthUtils.loginWithGoogle();
  //     //
  //     // if (!response.user) {
  //     //   return;
  //     // }
  //     //
  //     // const token = await response.user?.getIdToken();
  //
  //     const identifyActions = {
  //       email: response.user.email,
  //       name: response.user.displayName,
  //     };
  //
  //     analytics.identify(response.user.uid, identifyActions);
  //     Cookies.set('accessToken', token);
  //     await onLoginSuccess(token);
  //     dispatch(setApiSuccessMessage('Login Successful', true));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [onLoginSuccess, analytics]);

  const handleSubmitForgetPassword = useCallback(
    async (email: string, oldPassword: string, newPassword: string) => {
      setIsLoading(true);
      try {
        await AuthUtils.confirmNewPassword(email, oldPassword, newPassword);

        dispatch(
          setApiSuccessMessage(
            'Your password has been reset successfully. Please login with your new password.',
            true,
          ),
        );
        navigate('/login');
      } catch (error) {
        dispatch(setApiError('Reset password error. Please try again later.'));
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const signUpNewUser = useCallback(
    async (values: SignupFormValues) => {
      setIsLoading(true);
      try {
        // @ts-ignore
        const result = await AuthUtils.register({
          email: values.email,
          password: values.password,
          displayName: values.name,
          phoneNumber:
            values.phoneNumber === '' ? undefined : values.phoneNumber,
          businessName: values.businessName,
        });
        // @ts-ignore
        const { user, userId } = result || {};
        if (user) {
          const { username: email } = user;

          analytics.identify(userId, {
            email: email,
            name: values.name,
          });

          navigate(`../email-verification?email=${encodeURIComponent(email)}`);
        }
      } catch (error) {
        // @ts-ignore
        if (error?.code === 'UserLambdaValidationException') {
          dispatch(
            setApiError(
              // @ts-ignore
              error?.message,
            ),
          );
        } else {
          dispatch(
            setApiError(
              // @ts-ignore
              error?.message ?? AuthUtils.getErrorMessage(error?.code || ''),
            ),
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [analytics, dispatch, navigate],
  );

  const verifyEmail = useCallback(async (email: string, code: string) => {
    setIsLoading(true);
    try {
      await AuthUtils.verifyEmail(email, code);
      return true;
    } catch (error) {
      dispatch(setApiError('Verification code is invalid. Please try again.'));
      return false;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const initiateForgotPassword = useCallback(
    async (email: string, callback: () => void) => {
      try {
        await AuthUtils.initiateForgotPassword(email, callback);
        dispatch(
          setApiSuccessMessage(
            'Please check your email to complete the password reset process',
            true,
          ),
        );
      } catch (error) {
        // @ts-ignore
        dispatch(setApiError(error.message));
      }
    },
    [],
  );
  const resetPassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      try {
        await AuthUtils.resetPassword(oldPassword, newPassword);

        dispatch(
          setApiSuccessMessage(
            'Please check your email to complete the password reset process',
            true,
          ),
        );
      } catch (error) {
        // @ts-ignore
        dispatch(setApiError(error.message));
      }
    },
    [],
  );

  const verifyAuthentication = useCallback(async () => {
    if (isAuth || searchParams.get('code')) {
      return;
    }
    const token = Cookies.get('accessToken');
    if (!token) {
      onLogout();
      return;
    }

    const user = parseJwt(token);
    const hasExpired = checkIfTokenHasExpired(user.exp);

    if (hasExpired) {
      // removing access token so interceptors don't refresh session
      Cookies.remove('accessToken');

      await refreshSession();
      return;
    }
    Cookies.set('accessToken', token);

    if (fromExtensionParam) {
      const { idToken, refreshToken } = await cognitoDir.getUserSession();

      if (idToken && refreshToken) {
        onLoginFromExtension(idToken, refreshToken);
        return;
      }
    }

    await onLoginSuccess(token);
  }, [onLoginSuccess, dispatch, isAuth, searchParams]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuth,
        loginSuccess: onLoginSuccess,
        logout: onLogout,
        loginWithEmailAndPassword: onLoginWithEmailAndPassword,
        signUpUser: signUpNewUser,
        verifyAuthentication,
        resetPassword,
        handleSubmitForgetPassword,
        initiateForgotPassword,
        verifyEmail,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
