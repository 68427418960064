export const APPEARANCE_DEFAULT_SETTINGS = {
  widgetConfigSitePosition: {
    alignment: 'bottom-right',
    horizontalMargin: '20px',
    verticalMargin: '20px',
  },
  widgetConfigMobilePosition: {
    alignment: 'bottom-right',
    horizontalMargin: '20px',
    verticalMargin: '20px',
  },
  widgetConfigSiteColoring: {
    headerColor: '#000000',
    actionColor: '#ffffff',
  },
  widgetConfigSiteSize: { diameterDesktop: '45px', diameterMobile: '30px' },
  widgetConfigSiteAdditional: {
    enableAriaAttributes: true,
    widgetActivationAnchor: 'icon-human',
    defaultLanguage: 'en',
    enableSkipLinks: false,
    enableSafeMode: false,
    enableKeyboardNavigation: true,
    applyContrastFixes: false,
    allowDisableOffScreen: false,
    accessibilityStatementLink: '',
  },
};

export const setDomainAppearanceSettings = (appearance: any) => {
  if (!appearance.widgetConfigSitePosition.alignment) {
    appearance.widgetConfigSitePosition.alignment = 'bottom-right';
  }
  if (!appearance.widgetConfigSitePosition.horizontalMargin) {
    appearance.widgetConfigSitePosition.horizontalMargin = '20px';
  }
  if (!appearance.widgetConfigSitePosition.verticalMargin) {
    appearance.widgetConfigSitePosition.verticalMargin = '20px';
  }
  if (!appearance.widgetConfigMobilePosition.alignment) {
    appearance.widgetConfigMobilePosition.alignment = 'bottom-right';
  }
  if (!appearance.widgetConfigMobilePosition.horizontalMargin) {
    appearance.widgetConfigMobilePosition.horizontalMargin = '20px';
  }
  if (!appearance.widgetConfigMobilePosition.verticalMargin) {
    appearance.widgetConfigMobilePosition.verticalMargin = '20px';
  }
  if (!appearance.widgetConfigSiteSize.diameterDesktop) {
    appearance.widgetConfigSiteSize.diameterDesktop = '45px';
  }
  if (!appearance.widgetConfigSiteSize.diameterMobile) {
    appearance.widgetConfigSiteSize.diameterMobile = '30px';
  }
  if (!appearance.widgetConfigSiteAdditional.widgetActivationAnchor) {
    appearance.widgetConfigSiteAdditional.widgetActivationAnchor = 'icon-human';
  }
  if (
    appearance.widgetConfigSiteAdditional.enableAriaAttributes === undefined
  ) {
    appearance.widgetConfigSiteAdditional.enableAriaAttributes = false;
  }
  if (
    appearance.widgetConfigSiteAdditional.enableKeyboardNavigation === undefined
  ) {
    appearance.widgetConfigSiteAdditional.enableKeyboardNavigation = false;
  }
  if (!appearance.widgetConfigSiteAdditional.defaultLanguage) {
    appearance.widgetConfigSiteAdditional.defaultLanguage = 'en';
  }
  if (appearance.widgetConfigSiteAdditional.enableSkipLinks === undefined) {
    appearance.widgetConfigSiteAdditional.enableSkipLinks = false;
  }
  if (appearance.widgetConfigSiteAdditional.enableSafeMode === undefined) {
    appearance.widgetConfigSiteAdditional.enableSafeMode = false;
  }
  if (appearance.widgetConfigSiteAdditional.applyContrastFixes === undefined) {
    appearance.widgetConfigSiteAdditional.applyContrastFixes = false;
  }
  if (
    appearance.widgetConfigSiteAdditional.allowDisableOffScreen === undefined
  ) {
    appearance.widgetConfigSiteAdditional.allowDisableOffScreen = false;
  }
  if (
    appearance.widgetConfigSiteAdditional.accessibilityStatementLink ===
    undefined
  ) {
    appearance.widgetConfigSiteAdditional.accessibilityStatementLink = '';
  }
  if (!appearance.widgetConfigSiteColoring.actionColor) {
    appearance.widgetConfigSiteColoring.actionColor = '#ffffff';
  }
  return appearance;
};
