import React from 'react';
import styled from 'styled-components';
import { Divider as MuiDivider } from '@mui/material';
import { ColumnOption, IssueCreationType } from './popup';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { isRecommendationEmpty } from '@equally-ai-front/common/src/lib';
import { TaskAdditionalInfo } from '@equally-ai-front/common/src/types/business';
import { AdditionalInfoFields } from './additional-info-fields';

interface JiraTaskIssueProps {
  columns: ColumnOption[];
  index: number;
  issueCreationType: IssueCreationType;
  devToolsIssue: DevtoolsSolution;
  tasksAdditionalInfo: TaskAdditionalInfo[];
  setTasksAdditionalInfo: React.Dispatch<
    React.SetStateAction<TaskAdditionalInfo[]>
  >;
  isGrouped: boolean;
}

export const JiraTaskIssue = (props: JiraTaskIssueProps) => {
  const {
    columns,
    index,
    issueCreationType,
    devToolsIssue,
    tasksAdditionalInfo,
    setTasksAdditionalInfo,
    isGrouped,
  } = props;

  return (
    <Wrapper>
      <IssueDetails>
        {columns.map(({ key, label }) => {
          let issueValue = devToolsIssue[key];

          if (key === 'recommendation') {
            issueValue = isRecommendationEmpty(
              typeof issueValue === 'string' ? issueValue : String(issueValue),
            )
              ? 'No recommendation'
              : issueValue;
          }

          return (
            <IssueItem key={`${key}-${label}`}>
              <IssueLabel>{label}</IssueLabel>
              <IssueValue>{issueValue}</IssueValue>
            </IssueItem>
          );
        })}
      </IssueDetails>
      <Divider />
      {!isGrouped && (
        <AdditionalInfoFields
          index={index}
          issueCreationType={issueCreationType}
          tasksAdditionalInfo={tasksAdditionalInfo}
          setTasksAdditionalInfo={setTasksAdditionalInfo}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const IssueDetails = styled.div`
  padding: 20px 55px;
`;

const IssueItem = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const IssueLabel = styled.p`
  font-weight: 600;
`;

const IssueValue = styled.p`
  overflow-wrap: break-word;
  font-size: 14px;
`;

const Divider = styled(MuiDivider)`
  height: 1px;
`;
