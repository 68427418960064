import { takeLatest, delay, call, all, put } from 'redux-saga/effects';
import { scanRecommendationsActions } from './index';
import { ResponseGenerator, apiCall } from '../../utils/axios';
import { GetScanRecommendationsArgType } from './types';

function* fetchScanRecommendations(data: GetScanRecommendationsArgType) {
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') ?? '';
    const response: ResponseGenerator = yield call(
      apiCall,
      'POST',
      `/api/v1/admin/a/domain/recommendation`,
      data.payload,
      token,
      '',
    );
    if (response.status === 200) {
      yield put(
        scanRecommendationsActions.getScanRecommendationsSuccessful(
          response.data.message,
        ),
      );
    } else {
      yield put(scanRecommendationsActions.setError(response.data.message));
    }
  } catch (error) {
    yield put(
      scanRecommendationsActions.setError(
        error?.message ||
          'An error occured while trying to get scan recommendations',
      ),
    );
  }
}

function* fetchManualRecommendations() {
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') as string;
    const domain = localStorage.getItem('websiteDomain') as string;
    const response: ResponseGenerator = yield call(
      apiCall,
      'GET',
      `/api/v1/admin/a/compliance/audits?domain=${domain}`,
      {},
      token,
      '',
    );

    if (response.status === 200) {
      yield put(
        scanRecommendationsActions.getManualRecommendationsSuccessful(
          JSON.parse(response.data.data),
        ),
      );
    } else {
      yield put(scanRecommendationsActions.setError(response.data.message));
    }
  } catch (error) {
    yield put(
      scanRecommendationsActions.setError(
        error?.message ||
          'An error occured while trying to get scan recommendations',
      ),
    );
  }
}

function* fetchDomainPages(data: {
  payload: {
    domainUrl: string;
  };
  type: string;
}) {
  yield delay(500);
  try {
    const token = localStorage.getItem('devToolsToken') ?? '';
    const response: ResponseGenerator = yield call(
      apiCall,
      'GET',
      `/api/v1/admin/a/compliance/pages?domain_url=${data.payload.domainUrl}`,
      {},
      token,
      '',
    );
    if (response.status === 200) {
      yield put(
        scanRecommendationsActions.setDomainPages(response.data.data ?? []),
      );
    } else {
      yield put(scanRecommendationsActions.setError(response.data.message));
    }
  } catch (error) {
    yield put(
      scanRecommendationsActions.setError(
        error?.message ||
          'An error occured while trying to get scan recommendations',
      ),
    );
  }
}

export default function* scanRecommendationsSaga() {
  yield all([
    takeLatest(
      scanRecommendationsActions.getScanRecommendations.type,
      fetchScanRecommendations,
    ),
  ]);
  yield all([
    takeLatest(
      scanRecommendationsActions.getManualRecommendations.type,
      fetchManualRecommendations,
    ),
  ]);
  yield all([
    takeLatest(
      scanRecommendationsActions.getDomainPages.type,
      fetchDomainPages,
    ),
  ]);
}
